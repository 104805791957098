import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";


let isInitialized = false;


export function initErrorCatcher () {
	Sentry.init({
		dsn: 'https://606ba66ddf914fe99fe02f8a0f9484be@sentry.io/1281388',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
	isInitialized = true;
}

export function identifyUser (user) {
	if ( isInitialized === false ) {
		return;
	}

	Sentry.setUser({ username: user.username, id: user.id });
}

export function reportError(error, extra) {
	if ( isInitialized === false ) {
		return;
	}

	Sentry.captureException(error);
}
