const baseCssClassName = 'overlay';

const THEMES = {
	DARK: 'dark',
	LIGHT: 'light',
	TRANSPARENT: 'transparent',
	WHITE: 'white',
};

const DEFAULT_THEME = THEMES.DARK;

const DEFAULT_SCROLL_PROPS = {
	horizontalEnabled: true,
	verticalEnabled: true,
	fullscreen: false,
};

export default {
	baseCssClassName,
	THEMES,
	DEFAULT_THEME,
	DEFAULT_SCROLL_PROPS,
};
