import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getService as getUploadImageService } from '../../services/upload-image';
import { get as getHelp } from '../../services/help';

import collectionsSelectors from '../../selectors/collectionsSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';

import CollectionPage from '../../components/Collection/CollectionPage';


class CollectionPageConnector extends PureComponent {
	static propTypes = {
		collection: PropTypes.object.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	_handleFileChange = (image) => {
		getUploadImageService().uploadImage(image);
	};

	_handleSuccess = ({ newImage }) => {
		const collectionId = this.props.collection.hashname;

		const help = getHelp();
		if ( help !== null ) {
			const newImageLocation = `${window.location.origin}/collections/${collectionId}/image/${newImage.hashname}/treatment_plan`;
			help.sendUploadedImageEvent(newImageLocation);
		}

		const imageId = this.context.router.route.match.params.imageId;
		if ( imageId ) {
			this.context.router.history.replace(`${newImage.hashname}`);
			return;
		}

		this.context.router.history.push(`/collections/${collectionId}/image/${newImage.hashname}`);
	};

	_handleOpenLastImage = ({ imageHashName }) => {
		this.context.router.history.push(`/collections/${this.props.collection.hashname}/image/${imageHashName}`);
	};

	render () {
		return (
			<CollectionPage
				{...this.props}
				onFileChange={this._handleFileChange}
				onSuccess={this._handleSuccess}
				onOpenLastImage={this._handleOpenLastImage}
			/>
		);
	}
}

export default connect(
	(state) => {
		return {
			collection: collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			}),
			lastImage: imagesCollectionsSelectors.selectLastOpenedImageInCollection(state),
		};
	},
	() => ({})
)(CollectionPageConnector);



