import teethConfig from '../../configs/teethConfig';
import teethMarkup from './teethMarkup';


/**
 * Returns a sorted top row of teeth.
 * 
 * @returns {Array.<string>}
 */
function getOrderedTopTeeth () {
	return teethConfig.TOP_TEETH_ORDER;
}

/**
 * Returns a sorted bottom row of teeth.
 * 
 * @returns {Array.<string>}
 */
function getOrderedBottomTeeth () {
	return teethConfig.BOTTOM_TEETH_ORDER;
}

/**
 * Returns a previous toothKey from top or bottom teeth row
 * @param {string} toothKey
 * @returns {null|string}
 */
function getPreviousToothKey (toothKey) {
	let teethRow = getOrderedTopTeeth();
	let index = teethRow.indexOf(toothKey);
	if (index === -1) {
		teethRow = getOrderedBottomTeeth();
		index = teethRow.indexOf(toothKey);
	}

	return (index > 0 ? teethRow[index - 1] : null);
}

/**
 * Returns a next toothKey from top or bottom teeth row
 * @param {string} toothKey
 * @returns {null|string}
 */
function getNextToothKey (toothKey) {
  let teethRow = getOrderedTopTeeth();
  let index = teethRow.indexOf(toothKey);
  if (index === -1) {
    teethRow = getOrderedBottomTeeth();
    index = teethRow.indexOf(toothKey);
  }

  return ((index < teethRow.length - 1) ? teethRow[index + 1] : null);
}

/**
 * Returns sorted top and bottom rows of teeth.
 * 
 * @returns {Array.<string>}
 */
function getTeeth () {
	return [
		...teethConfig.TOP_TEETH_ORDER,
		...teethConfig.BOTTOM_TEETH_ORDER,
	].sort();
}

/**
 * Returns svg.
 * 
 * @param {Object} options
 * @returns {string}
 */
function getSvg (options = {}) {
	const {
		width,
		height,
		params,
		content,
	} = options;

	return (`
		<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
			${content.replace(teethConfig.PARAMS_PLACEHOLDER, params.join(' '))}
		</svg>
	`);
}

/**
 * Returns a configured svg.
 * 
 * @param {Object} options
 * @returns {string}
 */
function getToothSvg (options = {}) {
	const {
		number,
		params,
		width,
		height,
	} = options;

	if ( typeof number !== 'string' ) {
		throw new Error('Argument "options.number" should be non empty string.');
	}
	
	if ( params && typeof params !== 'object' ) {
		throw new Error('Argument "options.params" should be an object.');
	}
	
	const tooth = teethMarkup.TEETH[number];
	
	if ( !tooth ) {
		throw new Error(`Wrong tooth number "${number}"`);
	}
	
	const preparedParams = [];
	const preparedWidth = ( width || tooth.width );
	const preparedHeight = ( height || tooth.height );

	if ( params ) {
		for (let p in params) {
			if ( !params.hasOwnProperty(p) ) {
				continue;
			}

			preparedParams.push(`${p}="${params[p]}"`);
		}
	}
	
	return getSvg({
		width: preparedWidth,
		height: preparedHeight,
		params: preparedParams,
		content: tooth.content,
	});
}

function sortTeeth (a, b) {
	if ( a === b ) {
		return 0;
	}

	if ( /^[a-z]+$/.test(a) && /^[a-z]+$/.test(b) ) {
		return ( a > b ? 1 : a === b ? 0 : -1 );
	}
	else if ( /^[a-z]+$/.test(a) && /^\d+$/.test(b) ) {
		return -1;
	}
	else if ( /^\d+$/.test(a) && /^\d+$/.test(b) ) {
		return ( Number(a) - Number(b) );
	}
	
	return 1;
}

function getLocalizedToothKey ({ toothKey, notationType }) {
	const config = teethConfig.teethToNotationMap[notationType];

	if ( config ) {
		return ( config[toothKey] || toothKey );
	}

	return toothKey;
}

/**
 * @param {string[]} teeth
 * @param {number[]} imageTeeth
 * @returns {number[]}
 */
function sortTeethMeta (teeth, imageTeeth) {
	const result = [];
	let remainTeeth = imageTeeth.length;

	for (let i = 0; i < teeth.length; i++) {
		if ( remainTeeth === 0 ) {
			break;
		}

		const toothKey = teeth[i];
		const toothIndex = imageTeeth.indexOf(Number(toothKey));
		if ( toothIndex !== -1 ) {
			result.push(imageTeeth[toothIndex]);
			--remainTeeth;
		}
	}

	return result;
}

function getTeethMetaTitle ({ teeth, notationType }) {
	let firstEntry = null;
	let prevEntry = null;
	let count = 0;
	const result = [];

	const getOrderedTeeth = getOrderedTopTeeth().concat(getOrderedBottomTeeth());

	const getIndex = (toothKey) => getOrderedTeeth.indexOf(String(toothKey));

	teeth.forEach((toothKey) => {
		const index = getIndex(toothKey);
		if ( firstEntry === null ) {
			firstEntry = index;
			prevEntry = index;
			++count;
		}
		else {
			if ( index - prevEntry === 1 ) {
				++count;
				prevEntry = index;
			}
			else {
				if ( count === 1 ) {
					result.push(getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType }));
				}
				else if ( count === 2 ) {
					result.push(`${getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType })}, ${getLocalizedToothKey({ toothKey: getOrderedTeeth[prevEntry], notationType })}`);
				}
				else {
					result.push(`${getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType })}-${getLocalizedToothKey({ toothKey: getOrderedTeeth[prevEntry], notationType })}`);
				}

				firstEntry = index;
				prevEntry = index;
				count = 1;
			}
		}
	});

	if ( firstEntry !== null ) {
		if ( count === 1 ) {
			result.push(getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType }));
		}
		else if ( count === 2 ) {
			result.push(`${getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType })}, ${getLocalizedToothKey({ toothKey: getOrderedTeeth[prevEntry], notationType })}`);
		}
		else {
			result.push(`${getLocalizedToothKey({ toothKey: getOrderedTeeth[firstEntry], notationType })}-${getLocalizedToothKey({ toothKey: getOrderedTeeth[prevEntry], notationType })}`);
		}
	}
	return result.join(', ');
}


export default {
	getOrderedTopTeeth,
	getOrderedBottomTeeth,
	getTeeth,
	getToothSvg,
	getLocalizedToothKey,
	sortTeeth,
	getPreviousToothKey,
	getNextToothKey,
	sortTeethMeta,
	getTeethMetaTitle,
};
