import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormCustomSelect from '../FormCustomSelect';


import './styles/ToothSelect.css';

function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if (items[i].value === value) {
			return items[i];
		}
	}

	return null;
}


const baseCssClassName = 'tooth-info-tooth-select';
const headerCssClassName = `${baseCssClassName}__header`;
const itemCssClassName = `${baseCssClassName}__item`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const requiredBoxCssClassName = `${baseCssClassName}__required-box`;
const selectCssClassName  = `${baseCssClassName}__select`;


class ToothSelect extends PureComponent {
	static propTypes = {
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
			label: PropTypes.string.isRequired,
			requiredBox: PropTypes.bool,
		})),

		headerClassName: PropTypes.string,

		disabled: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
	}

	_handleRenderHeader = ({ isOpened, indicatorElement }) => {
		const selectedItem = (this.props.value && this.props.items
			? getItem(this.props.value, this.props.items) || {}
			: {}
		);

		return (
			<div className={headerCssClassName}>
				<span>{selectedItem.label}</span>
				<div className={indicatorCssClassName}>{indicatorElement}</div>
			</div>
		);
	}

	_handleRenderItem = (item) => (
		<div className={itemCssClassName}>
			<span>{item.label}</span>
			{ item.requiredBox && (<div className={requiredBoxCssClassName} />)}
		</div>
	)

	render () {
		return (
			<FormCustomSelect
				items={this.props.items}
				value={this.props.value}
				disabled={this.props.disabled}
				className={selectCssClassName}
				onRenderHeader={this._handleRenderHeader}
				onRenderItem={this._handleRenderItem}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default ToothSelect;
