import mixpanel from 'mixpanel-browser';
import { v4 } from 'node-uuid';
import { getRuntimeConfig } from '../appUtils/runtimeConfig';
import { getStore } from '../services/store';
import commonUtils from "../appUtils/common";



let instance = null;
let ssid = null;

export function initMixPanel () {
	ssid = v4();
	mixpanel.init(getRuntimeConfig().mixpanel.token, { debug: true, persistence: 'localStorage', ignore_dnt: true });

	instance = mixpanel;
}

export function getMixPanel () {
	return instance;
}

/**
 * @param {string} name
 * @param {*} [data]
 */
export function trackEvent (name, data) {
	if ( instance === null ) {
		return;
	}

	const props = {
		'Session Id': ssid,
		'Collection Id': getStore().getState().editor.currentCollectionHashName,
		'User Name': getStore().getState().user.data.username,
	};

	const userApiParams = commonUtils.getUserApiParams();
	if ( Object.keys(userApiParams).length > 0 ) {
		props['DTR params'] = userApiParams;
	}

	mixpanel.track(name, props);
}

export function reset () {
	if ( instance === null ) {
		return;
	}

	mixpanel.reset();
	instance = null;
}
