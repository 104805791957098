export default {
	'labels.first_name': '名',
	'labels.last_name': '姓',
	'labels.country': '国家',
	'labels.clinic': '诊所',
	'labels.language': '语言',
	'labels.notation': '牙齿牙位标记法',
	'labels.report_specialist_email': '在报告中显示牙科专家邮箱地址',

	'buttons.change_email': '修改邮箱地址',
	'buttons.change_password': '修改密码',
	'buttons.upload_logo': `上传图标`,
};
