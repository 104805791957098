export class URLCache {
	_cache = {};

	/**
	 * @param {string} key
	 * @param {*} value
	 */
	setCache (key, value) {
		this._cache[key] = value;
	}

	/**
	 * @param {string} key
	 * @return {*}
	 */
	getCache (key) {
		return this._cache[key] || null;
	}

	/**
	 * @param {string} key
	 * @return {*}
	 */
	hasCache (key) {
		return this._cache.hasOwnProperty(key) === true;
	}

	/**
	 * @param {string} key
	 */
	removeCache (key) {
		delete this._cache[key];
	}

	cleanAll () {
		Object.values((url) => {
			if ( typeof url === 'string' ) {
				URL.revokeObjectURL(url);
			}
		})
		this._cache = {};
	}
}

export default new URLCache();
