import lodashGet from 'lodash/get';
import { getRuntimeConfig } from '../../appUtils/runtimeConfig';
import IntercomHelpMessenger from './intercom';
import CrispHelpMessenger from './crisp';
import ZendeskHelpMessenger from './zendesk';


const INTERCOM_APP_ID = 'g0vzvxyd'; // todo move to config / runtime config


/**
 *
 * @type {HelpMessenger|null}
 */
let instance = null;

export function init () {
	if ( instance !== null || lodashGet(getRuntimeConfig(), 'help.enabled', false) === false ) {
		return null;
	}


	const helpSystem = lodashGet(getRuntimeConfig(), 'help.system', null);
	switch (helpSystem) {
		case 'intercom':
			instance = new IntercomHelpMessenger({
				appId: INTERCOM_APP_ID,
			});
			break;

		case 'crisp':
			instance = new CrispHelpMessenger();
			break;

		case 'zendesk':
			instance = new ZendeskHelpMessenger();
			break;
	}

	return instance;
}

export function get () {
	return instance;
}
