import { getDictionary } from '../appUtils/locale';

const PARAMS_PLACEHOLDER = '{{params}}';

const TOP_TEETH_ORDER = [ '18','17','16','15','14','13','12','11','21','22','23','24','25','26','27','28' ];
const BOTTOM_TEETH_ORDER = [ '48','47','46','45','44','43','42','41','31','32','33','34','35','36','37','38' ];

const i18n = getDictionary('findings');

const DENTAL_NOTATION_SYSTEM = [
	{
		key: 'iso',
		name: 'ISO system',
	}, {
		key: 'na',
		name: 'Universal Numbering System',
	}
];

function getAdditionalTeeth () {
	return [
		{
			key: 'x',
			label: i18n('labels.x'),
		},
		{
			key: 'md',
			label: i18n('labels.md'),
		},
	];
}

const teethToNotationMap = {
	"na": {
		"18": "1",
		'17': "2",
		'16': "3",
		'15': "4",
		'14': "5",
		'13': "6",
		'12': "7",
		'11': "8",
		'21': "9",
		'22': "10",
		'23': "11",
		'24': "12",
		'25': "13",
		'26': "14",
		'27': "15",
		'28': "16",
		'48': "32",
		'47': "31",
		'46': "30",
		'45': "29",
		'44': "28",
		'43': "27",
		'42': "26",
		'41': "25",
		'31': "24",
		'32': "23",
		'33': "22",
		'34': "21",
		'35': "20",
		'36': "19",
		'37': "18",
		'38': "17",
	},
};


export default {
	PARAMS_PLACEHOLDER,
	TOP_TEETH_ORDER,
	BOTTOM_TEETH_ORDER,
	DENTAL_NOTATION_SYSTEM,
	getAdditionalTeeth,
	teethToNotationMap,
};
