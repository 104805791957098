export default {
	'hint.first': 'Загрузить снимок.',
	'hint.second': 'Поддерживаемые форматы файла',

	'buttons.back': 'Back',
	'buttons.open_registry': 'Открыть реестр',
	'buttons.save_changes': 'Сохранить изменения',
	'buttons.share_image': 'Поделиться снимком',
	'buttons.collections': 'Перейти в коллекции',

	'dialog.uploading_image_error.title': 'Ошибка загрузки снимка',
	'dialog.uploading_image_error.text': `
		Сервер вернул ошибку. Пожалуйста, попробуйте еще раз.<br/>

		Если ошибка остается, пожалуйста, отошлите письмо на <a href={'support@denti.ai'}>support@denti.ai</a> и прикрепите снимок, который некорректно обрабатывается
	`,
};
