export const toothSvgMap = {
	18: 'M7.4,32.5H7.2c-2.3-0.2-3.5-1.5-3.6-4c-0.1-1.8,0.5-2.8,0.9-3.4c0.2-0.3,0.5-0.6,0.7-0.8 	c0.6-0.5,0.9-0.8,0.8-1.9c-0.1-2.4-0.5-3.9-1.4-5.2c-0.3-0.6-0.6-1-0.9-1.6C2.6,13.1,1.5,8.2,2.2,8c1.4-0.5,2.4,1.9,2.4,1.9 	c2.7,4.7,4.9,7.2,5.6,7.7c0.6,0.5,1,0.7,1.2,0.7h0.1c0.4-0.2,0.8-2.7,0.3-5.6v-0.1c-0.6-3.5-0.7-5.2,0.2-5.6C12.2,7,12.3,7,12.5,7 	c1.9,0,3.7,4.5,3.9,7.4c0,0.6,0.1,1.3,0.1,1.9c0.1,2.7,0.2,5.4,1,6.8l0.3,0.5c1,1.7,1.9,3.4,1.3,5.6c-0.6,2.3-1.6,3.1-3.5,3.1h-0.2 	c-0.8,0-1.4-0.3-1.9-0.6c-0.4-0.2-0.7-0.4-1.3-0.5H12c-0.5,0-1.4,0.2-2.3,0.6C8.9,32.2,8,32.5,7.4,32.5',
	17: 'M33.2,32.3c-1,0-1.9-0.4-2.7-0.8c-0.4-0.2-0.7-0.3-1-0.4c-0.2,0-0.6,0.2-1,0.4c-0.6,0.3-1.4,0.7-2.2,0.7h-1 	c-1,0-1.9-0.2-3.1-1.4c-1.7-1.6-1.6-3.9-0.6-5.3c1.2-1.6,2-2.9,2.3-3.7c0.2-1-0.7-6.4-1.4-8.9c-0.9-3.1-1.3-4.7-0.4-5.5 	c0.2-0.2,0.5-0.2,0.8-0.2c0.9,0,1.9,0.7,2.3,1.8c1,2,1.9,4.7,2.2,6c0.4,1.5,1.5,3.9,2.1,4.3c0.5-0.8,0.6-4.9,0.6-7.3 	c0-0.4-0.1-0.9-0.2-1.3c-0.2-1.3-0.3-2.6,0.2-3.2c0.2-0.2,0.5-0.4,1-0.4c1.4,0,2.4,2.2,2.7,3.7c0.5,1.9,0.6,3.1,0.9,6.4 	c0.2,2.4,1.9,5.2,3,6.4c1.3,1.4,1.4,2.8,1.4,4c0,1.7-1.3,2.3-2.5,2.9c-0.2,0.1-0.6,0.4-0.9,0.7c-0.6,0.6-1.3,1.2-2.3,1.4 	C33.4,32.3,33.3,32.3,33.2,32.3',
	16: 'M43.5,32.9c-0.6-0.1-1.1-0.4-1.5-1c-0.9-1.3-1.1-3.5-0.8-4.9c0.4-1.9,1.4-2.9,3.1-4.7l0.6-0.6 	c1.2-1.2,0.9-6.2,0.7-8.9c0-0.6-0.1-1-0.1-1.4c0-0.3,0-0.7-0.1-1c-0.2-2.5-0.2-4.3,0.5-5c0.2-0.2,0.5-0.3,0.8-0.3 	c1.4,0.1,2.3,2.7,3.1,6.1l0.1,0.3c0.2,0.6,0.3,1.5,0.5,2.3c0.2,1.3,0.6,3.9,1.2,3.9c1,0,2.2-2.3,2.4-5.8c0.1-1.4-0.2-2.8-0.3-3.9 	c-0.2-1.4-0.5-2.7,0.5-3.1C54.3,5,54.5,5,54.7,5c1.5,0,2.6,3,3.1,4.7c0.2,0.8,0.8,5.6-0.6,9.5c-0.4,1-0.3,1.9-0.2,2.8 	c0.1,1,0.9,2.3,1.5,3.2c0.3,0.5,0.6,1,0.8,1.3c0.6,1.4,0.3,3.2-0.7,4.1c-0.6,0.6-1.5,1-2.5,1.5c-0.7,0.3-1.4,0.5-2.1,0.5 	c-0.7,0-1.4-0.2-1.9-0.6c-0.6-0.6-1-0.8-1.8-0.8h-0.4c-0.8,0.1-1.3,0.2-2.4,0.9c-1.1,0.6-2,0.8-3,0.8C44.1,32.9,43.8,32.9,43.5,32.9 	z',
	15: 'M69.5,32.5c-1.9,0-5.2-1.4-6.1-3.8c-1-2.6-0.1-5,1-6.3c0.2-0.2,0.3-0.3,0.4-0.5c0.6-0.6,0.6-0.6,0.2-2.3 	c-0.2-1.2,0-2,0.2-3.1c0.2-1,0.6-2.4,0.8-4.8c0.2-2.5-0.2-4.1-0.5-5.2c-0.2-1-0.5-2,0.5-2.5C66.2,4,66.3,4,66.4,4 	c1.1,0,3.1,2,4.7,5.6c1.3,3.2,2,6.6,2,10c0,0.4,0.2,1.4,0.7,2.3c0.1,0.2,0.3,0.4,0.6,0.6c1,1,2.6,2.9,2.2,5.2 	c-0.4,2.7-3.1,4.6-6.8,4.8H69.5L69.5,32.5z',
	14: 'M89.6,32.7c-1.4,0-3.5-0.6-5-1.5c-0.8-0.5-1.4-1.5-1.5-2.8c-0.2-1.6,0.2-3.1,1.2-4.1c1.1-1.2,1.4-3.9,1.6-8.5 	c0.1-2,0-4.3-0.1-6.4c-0.2-3.9-0.2-5.7,0.6-6.2C86.7,3.1,86.9,3,87.1,3c1.7,0,2.9,3,4.3,7.2c1,2.8,1.1,5.2,1.4,7.6 	c0.1,0.7,0.2,1.5,0.2,2.3c0.1,1,0.9,2.5,1.5,3.1l0.2,0.2c1.1,1,2.5,2.3,2.1,4.2c-0.8,4.3-6.4,4.9-7,5C89.9,32.7,89.6,32.7,89.6,32.7 	z',
	13: 'M110.5,32.9c-0.9,0-1.9-0.2-2.7-0.5c-1.4-0.6-4.6-2-4.8-4.3c-0.2-2,1.4-5.3,2.5-6.8c0.8-1,1.2-6.7,1.3-10V6.1 	c-0.1-3.3-0.3-5.7,1.4-6.1h0.3c0.8,0,2.8-0.4,4.7,13.8c0.6,4.3,1.3,7.5,2,8.5c0.2,0.3,0.5,0.6,0.7,1c0.8,1,1.8,2.3,1.8,4.1 	c0,2.2-2.1,3.4-4.2,4.5c-0.3,0.2-0.6,0.3-0.9,0.5C112,32.8,111.3,32.9,110.5,32.9',
	12: 'M131.4,33.2c-0.5,0-0.9,0-1.2-0.1h-0.6c-1,0-2.4-0.1-3.3-1c-0.6-0.6-1.3-1.5-1.3-2.7c0-0.6,0-1.2,0.1-1.8 	c0-2.3,0.5-3.1,1.7-5.1c1.5-2.3,1.9-9.3,1.7-13.4c-0.1-1-0.3-2.2-0.5-3.1c-0.4-1.8-0.6-3.2,0.2-3.9c0.2-0.1,0.3-0.2,0.5-0.2 	c1,0,2.3,1.8,3.8,5.3c1.3,2.9,1.4,8.8,1.6,11.9c0,0.6,0.1,1.1,0.1,1.5c0.1,0.7,0.3,1.2,0.6,1.9c0.6,1,1.4,2.4,1.4,5.6 	c0,1.7-0.4,2.9-1.2,3.8C134.2,32.8,133,33.2,131.4,33.2',
	11: 'M149.5,33c-2.8,0-4.5-0.5-5.5-1.4c-1-1-1-2.4-1-3.9c0.1-2.3,1.5-4.4,2.6-6c0.4-0.6,0.7-1.1,0.9-1.5 	c0.6-1.4,2.3-7.6,2.8-10c0.2-0.7,0.2-1.6,0.4-2.5c0.3-2.4,0.5-4.3,1.6-4.6h0.2l0.2,0.1c1,0.3,1.3,2,1.6,4.1l0.1,0.6 	c0.2,1.4,1,4,1.5,5.9c0.2,0.7,0.4,1.3,0.5,1.7c0.2,0.6,0.2,1.4,0.3,2.3c0.2,1.4,0.2,2.8,0.7,3.7c0.9,1.6,2.3,4.3,2.1,6.9 	c-0.2,3.1-1.5,4.5-8.1,4.7H149.5z',
	48: 'M0.1,80.9c-0.5-0.7,0.6-1.5,2-2.8c0.8-0.7,1.6-1.4,2.3-2.2c1.7-1.7,2-5.2,1.6-6.5c-0.2-0.5-0.5-0.9-0.8-1.2 	c-0.6-0.8-1.4-1.8-1.3-3.7C4.2,62.4,5.9,61,8.4,61c0.6,0,1.3,0.1,1.9,0.2c1,0.2,1.7,0.4,2.4,0.4c0.8,0,1.8-0.2,3.1-0.4 	c0.4-0.1,0.8-0.2,1.2-0.2c2.2,0,3.3,1.6,3.3,3.1c0.1,1.7-0.6,2.9-2,4.9c-0.3,0.5-0.8,2-1.2,3.5c-0.6,1.9-1,3.7-1.7,4.7 	c-0.2,0.5-2.7,4.4-4.2,4.4H11c-0.2-0.1-0.4-0.2-0.6-0.5c-0.4-0.7,0.1-2.3,1-4.7c0.5-1.2,0.7-4-0.2-3.5c-0.2,0.2-0.6,0.9-0.8,1.3 	c-0.2,0.4-0.8,1.2-1,1.6c-0.8,1.2-3.4,3.6-5.5,4.6C3.1,81.2,0.6,81.7,0.1,80.9',
	47: 'M23,86.1c-1,0-1.7-1.3-1.9-3.8c-0.3-3.5,0.6-7.2,2.6-10.2c1.2-1.9,1-3.3-0.5-4.5c-0.8-0.6-1.3-1.6-1.4-2.7 	c-0.1-1.2,0.4-2.3,1.1-3.1c1-1,2.8-1.7,4.5-1.7c0.6,0,1.2,0.1,1.6,0.3c0.6,0.2,1.3,0.4,2.3,0.4c1,0,2.1-0.2,3.5-0.4 	c0.4-0.1,0.7-0.1,1-0.1c2.3,0,3.9,1.4,4.1,3.5c0.1,1.4-0.8,2.7-1.6,3.8c-0.6,0.7-1,1.4-1.1,1.9c-0.1,0.4-0.1,1.2-0.1,2.1 	c0.1,2.3,0.1,5.4-1.4,8c-0.5,0.7-4.2,6-6.4,6H29c-0.3-0.1-0.6-0.2-0.7-0.6c-0.4-0.9,0.3-2.4,1.4-4.9c0.6-1.1,1.1-2.3,1.4-3.1 	c0.4-1.4,0.3-2.7-0.2-3.5c-0.3-0.5-0.7-0.8-1.3-0.8h-0.1c-1.2,0-3.3,3.1-4.3,6.3c-0.2,0.5-0.4,1.5-0.6,2.6c-0.7,3.7-1,4.3-1.5,4.5 	C23.2,86.1,23.1,86.1,23,86.1',
	46: 'M50.6,88.6c-0.6,0-1-0.4-1-1c0-0.6,0.2-1.2,0.4-2.2c0.3-1.1,0.7-2.7,1-4.7c0.5-3.8,0-6.5-1.1-6.8 	c-1.3,0-4.1,4.6-5.2,7.9c-0.1,0.2-0.2,0.6-0.3,0.9c-0.7,2.3-1.5,4.8-3,4.8c-0.5,0-0.8-0.2-1-0.4c-0.6-0.8-0.5-2.3,0.1-5.9 	c0.5-3.1,1.4-5.1,2.1-6.5c0.2-0.3,0.2-0.6,0.4-0.8c0.8-1.8,0.7-4.7-0.7-6.8c-0.8-1-1-2.1-0.8-3.1c0.3-1.3,1.4-2.3,3.2-3.2 	c0.6-0.3,1.3-0.5,1.9-0.5c1.2,0,2.1,0.6,2.8,1c0.3,0.2,0.6,0.4,0.8,0.4c0.2,0,0.3,0.1,0.5,0.1c0.6,0,1-0.3,1.6-0.7 	c0.6-0.4,1.1-0.9,2-1c0.2,0,0.3-0.1,0.6-0.1c1.9,0,3.9,2.2,4.3,4.1c0.4,1.9,0,2.6-1.3,4.3c-0.6,0.8-0.7,2.4-0.8,3.6 	c0,0.5-0.1,1-0.2,1.3c0,0.2-0.1,0.6-0.1,1.2c-0.2,2.4-0.5,6.8-2.3,10.9C53.6,87.2,51.8,88.6,50.6,88.6',
	45: 'M69.2,87.1c-0.2,0-0.3-0.1-0.5-0.2c-0.8-0.5-1-2.2-0.6-6.3c0.2-2.5-0.4-4.2-1.1-5.9c-0.4-1-0.7-1.9-0.9-2.9 	c-0.2-2-0.9-2.7-1.5-3.5c-0.4-0.5-0.9-1-1.4-1.9c-0.4-0.8-0.3-1.8,0.2-2.7c1-1.9,3.7-3.4,6.6-3.8h0.6c3.2,0,5.6,3.2,5.6,4.9 	c0,1.4-0.6,2.7-1.6,4.2c-0.6,0.9-0.8,3.5-1,6c-0.2,2.3-0.3,4.5-0.8,6.2C72,84.7,70.5,87.1,69.2,87.1',
	44: 'M89.1,88.6c-0.3,0-0.6-0.2-0.8-0.3c-0.5-0.6-0.7-1.7-0.6-3.7c0.2-3.1-0.3-6.3-0.8-9.2c-0.2-1.9-0.6-3.6-0.6-5.1 	c-0.1-1.2-0.8-1.9-1.6-2.7c-0.6-0.6-1.2-1.1-1.5-2c-0.2-0.6-0.1-1.4,0.4-2.2c1-1.7,3.6-3,6.4-3.4h0.6c2.3,0,3.8,1.5,4.4,2.4 	c0.7,0.9,1.4,2.3,1.2,2.9c-0.2,0.9-0.4,1.6-1.7,2.8c-0.6,0.6-1.4,5.6-1.9,8.6c-0.3,1.8-0.6,3.4-0.7,4.1c-0.2,0.6-0.3,1.4-0.6,2.4 	C90.5,87,90.1,88.6,89.1,88.6',
	43: 'M107.3,90.8c-0.1,0-0.2,0-0.2-0.1c-0.9-0.3-0.9-1.8-0.8-6c0-1.6,0.1-3.2,0-4.5c-0.3-4.9-0.3-7-0.3-8.5V71 	c0-0.6-0.5-1.4-1.4-2.2c-1-0.9-1.9-2.3-1.4-3.8c0.7-2.7,3-4,7.3-4.9c0.4-0.1,0.8-0.2,1.2-0.2c1.9,0,3.2,1.4,4,2.3 	c1,1.2,1.5,2.7,1.4,3.5c-0.1,1-0.6,1.9-1.4,3c-0.3,0.5-0.6,1-1,1.5c-0.4,0.7-0.5,2.1-0.6,3.7c-0.2,2.5-0.5,5.6-1.9,8.7 	C110.8,86.4,108.5,90.8,107.3,90.8',
	42: 'M129.4,88.9c-1.9-0.2-1.4-6.4-1.4-6.4c0.1-1-0.2-2.5-0.5-4.2c-0.3-1.8-0.6-3.7-0.7-5.4c0-1.9-0.6-3.1-1.4-4.5 	c-0.5-0.9-0.9-1.9-1.2-2.9c-0.2-1.1-0.4-2.3,0.2-3.1c0.6-1,2-1.5,4.7-1.9c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.2,1.1-0.2,1.9-0.2 	c0.4,0,0.9,0.1,1.4,0.2c0.8,0.2,1.4,0.6,1.7,1.4c0.6,1.5,0.2,3.9-1.1,5.7c-1.4,1.9-1.5,8-1.6,11.6c0,1-0.1,1.8-0.1,2.3 	C132.2,82.1,131.3,88.9,129.4,88.9',
	41: 'M149.7,89.8c-0.2,0-0.5-0.1-0.7-0.3c-0.8-0.8-0.9-3-0.8-4.2c0-1-0.2-2.1-0.4-3.3c-0.3-1.4-0.6-3.3-0.7-5.9 	c-0.2-4.6-0.6-6.4-1.4-9.3v-0.1c-0.5-1.9-0.9-3.2-0.2-4.3c0.7-1.1,2.3-1.8,5.4-2.3c0.4-0.1,0.8-0.1,1.2-0.1c1.6,0,2.5,0.6,3,1.3 	c0.8,1,1,2.3,0.8,3.2c-0.2,1.7-0.6,3.1-1.9,5.2c-1.2,1.9-1.4,9.7-1.4,12.6v0.2c-0.1,2.7-1.2,7.2-2.5,7.4 	C149.8,89.8,149.7,89.8,149.7,89.8',
	28: 'M313.8,32.5h0.2c2.3-0.2,3.5-1.5,3.6-4c0.1-1.8-0.5-2.8-0.9-3.4c-0.2-0.3-0.5-0.6-0.7-0.8 	c-0.6-0.5-0.9-0.8-0.8-1.9c0.1-2.4,0.5-3.9,1.4-5.2c0.3-0.6,0.6-1,0.9-1.6c1.1-2.5,2.2-7.4,1.4-7.6c-1.4-0.5-2.4,1.9-2.4,1.9 	c-2.7,4.7-4.9,7.2-5.6,7.7c-0.6,0.5-1,0.7-1.2,0.7h-0.1c-0.4-0.2-0.8-2.7-0.3-5.6v-0.1c0.6-3.5,0.7-5.2-0.2-5.6 	C309,7,308.9,7,308.7,7c-1.9,0-3.7,4.5-3.9,7.4c0,0.6-0.1,1.3-0.1,1.9c-0.1,2.7-0.2,5.4-1,6.8l-0.3,0.5c-1,1.7-1.9,3.4-1.3,5.6 	c0.6,2.3,1.6,3.1,3.5,3.1h0.2c0.8,0,1.4-0.3,1.9-0.6c0.4-0.2,0.7-0.4,1.3-0.5h0.1c0.5,0,1.4,0.2,2.3,0.6 	C312.3,32.2,313.2,32.5,313.8,32.5',
	27: 'M287.1,32.3c1,0,1.9-0.4,2.7-0.8c0.4-0.2,0.7-0.3,1-0.4c0.2,0,0.6,0.2,1,0.4c0.6,0.3,1.4,0.7,2.2,0.7h1 	c1,0,1.9-0.2,3.1-1.4c1.7-1.6,1.6-3.9,0.6-5.3c-1.2-1.6-2-2.9-2.3-3.7c-0.2-1,0.7-6.4,1.4-8.9c0.9-3.1,1.3-4.7,0.4-5.5 	c-0.2-0.2-0.5-0.2-0.8-0.2c-0.9,0-1.9,0.7-2.3,1.8c-1,2-1.9,4.7-2.2,6c-0.4,1.5-1.5,3.9-2.1,4.3c-0.5-0.8-0.6-4.9-0.6-7.3 	c0-0.4,0.1-0.9,0.2-1.3c0.2-1.3,0.3-2.6-0.2-3.2c-0.3-0.3-0.6-0.4-1-0.4c-1.4,0-2.4,2.2-2.7,3.7c-0.5,1.9-0.6,3.1-0.9,6.4 	c-0.2,2.4-1.9,5.2-3,6.4c-1.3,1.4-1.4,2.8-1.4,4c0,1.7,1.3,2.3,2.5,2.9c0.2,0.1,0.6,0.4,0.9,0.7c0.6,0.6,1.3,1.2,2.3,1.4 	C286.9,32.3,287,32.3,287.1,32.3',
	26: 'M276.1,32.9c0.3,0,0.6,0,1-0.1c0.6-0.1,1.1-0.4,1.5-1c0.9-1.3,1.1-3.5,0.8-4.9c-0.4-1.9-1.4-2.9-3.1-4.7 	l-0.6-0.6c-1.2-1.2-0.9-6.2-0.7-8.9c0-0.6,0.1-1,0.1-1.4c0-0.3,0-0.7,0.1-1c0.2-2.5,0.2-4.3-0.5-5c-0.2-0.2-0.5-0.3-0.8-0.3 	c-1.5,0.1-2.3,2.7-3.1,6.1l-0.1,0.3c-0.2,0.6-0.3,1.5-0.5,2.3c-0.2,1.3-0.6,3.9-1.2,3.9c-1,0-2.2-2.3-2.4-5.8 	c-0.1-1.4,0.2-2.8,0.3-3.9c0.2-1.4,0.5-2.7-0.5-3.1C266.2,5,266.1,5,265.9,5c-1.5,0-2.6,3-3.1,4.7c-0.2,0.8-0.8,5.6,0.6,9.5 	c0.4,1,0.3,1.9,0.2,2.8c-0.1,1-0.9,2.3-1.5,3.2c-0.3,0.5-0.6,1-0.8,1.3c-0.6,1.4-0.3,3.2,0.7,4.1c0.6,0.6,1.5,1,2.5,1.5 	c0.7,0.3,1.4,0.5,2.1,0.5c0.7,0,1.4-0.2,1.9-0.6c0.6-0.6,1-0.8,1.9-0.8h0.4c0.8,0.1,1.3,0.2,2.4,0.9 	C274.3,32.7,275.1,32.9,276.1,32.9',
	25: 'M250.1,32.5c1.9,0,5.2-1.4,6.1-3.8c1-2.6,0.1-5-1-6.3c-0.2-0.2-0.3-0.3-0.4-0.5c-0.6-0.6-0.6-0.6-0.2-2.3 	c0.2-1.2,0-2-0.2-3.1c-0.2-1-0.6-2.4-0.8-4.8c-0.2-2.5,0.2-4.1,0.5-5.2c0.2-1,0.5-2-0.5-2.5C253.4,4,253.3,4,253.2,4 	c-1.1,0-3.1,2-4.7,5.6c-1.3,3.2-2,6.6-2,10c0,0.4-0.2,1.4-0.7,2.3c-0.1,0.2-0.3,0.4-0.6,0.6c-1,1-2.6,2.9-2.2,5.2 	c0.4,2.7,3.1,4.6,6.8,4.8H250.1L250.1,32.5z',
	24: 'M230.4,32.7c1.4,0,3.5-0.6,5-1.5c0.8-0.5,1.4-1.5,1.5-2.8c0.2-1.6-0.2-3.1-1.2-4.1c-1.1-1.2-1.4-3.9-1.6-8.5 	c-0.1-2,0-4.3,0.1-6.4c0.2-3.9,0.2-5.7-0.6-6.2c-0.2-0.2-0.5-0.2-0.7-0.2c-1.7,0-2.9,3-4.3,7.2c-1,2.8-1.1,5.2-1.4,7.6 	c-0.1,0.7-0.2,1.5-0.2,2.3c-0.1,1-0.9,2.5-1.5,3.1l-0.2,0.2c-1.1,1-2.5,2.3-2.1,4.2c0.8,4.3,6.4,4.9,7,5L230.4,32.7L230.4,32.7z',
	23: 'M210.2,32.9c0.9,0,1.9-0.2,2.7-0.5c1.4-0.6,4.6-2,4.8-4.3c0.2-2-1.4-5.3-2.5-6.8c-0.8-1-1.2-6.7-1.3-10V6.1 	c0.1-3.3,0.3-5.7-1.4-6.1h-0.3c-0.8,0-2.8-0.4-4.7,13.8c-0.6,4.3-1.3,7.5-2,8.5l-0.7,1c-0.8,1-1.8,2.3-1.8,4.1 	c0,2.2,2.1,3.4,4.2,4.5c0.3,0.2,0.6,0.3,0.9,0.5C208.6,32.8,209.3,32.9,210.2,32.9',
	22: 'M189.7,33.2c0.5,0,0.9,0,1.2-0.1h0.6c1,0,2.4-0.1,3.3-1c0.6-0.6,1.5-1.5,1.5-2.7c0-0.6,0-1.2-0.1-1.8 	c0-2.3-0.7-3.1-1.9-5.1c-1.5-2.3-1.9-9.3-1.7-13.4c0.1-1,0.3-2.2,0.5-3.1c0.4-1.8,0.6-3.2-0.2-3.9c-0.2-0.1-0.3-0.2-0.5-0.2 	c-1,0-2.3,1.8-3.8,5.3c-1.3,2.9-1.4,8.8-1.6,11.9c0,0.6-0.1,1.1-0.1,1.5c-0.1,0.7-0.3,1.2-0.6,1.9c-0.6,1-1.4,2.4-1.4,5.6 	c0,1.7,0.4,2.9,1.2,3.8C186.9,32.8,188.1,33.2,189.7,33.2',
	21: 'M172,33c2.8,0,4.5-0.5,5.5-1.4c1-1,1-2.4,1-3.9c-0.1-2.3-1.5-4.4-2.6-6c-0.4-0.6-0.7-1.1-0.9-1.5 	c-0.6-1.4-2.3-7.6-2.8-10c-0.2-0.7-0.2-1.6-0.4-2.5c-0.3-2.4-0.5-4.3-1.6-4.6H170l-0.2,0.1c-1,0.3-1.3,2-1.6,4.1l-0.1,0.6 	c-0.2,1.4-1,4-1.5,5.9c-0.2,0.7-0.4,1.3-0.5,1.7c-0.2,0.6-0.2,1.4-0.3,2.3c-0.2,1.4-0.2,2.8-0.7,3.7c-0.9,1.6-2.3,4.3-2.1,6.9 	c0.2,3.1,1.5,4.5,8.1,4.7H172z',
	38: 'M320.2,80.9c0.5-0.7-0.6-1.5-2-2.8c-0.8-0.7-1.6-1.4-2.3-2.2c-1.7-1.7-2-5.2-1.6-6.5c0.2-0.4,0.4-0.8,0.8-1.2 	c0.6-0.8,1.4-1.8,1.3-3.7c-0.2-2.1-1.9-3.5-4.4-3.5c-0.6,0-1.3,0.1-1.9,0.2c-1,0.2-1.7,0.4-2.4,0.4c-0.8,0-1.8-0.2-3.1-0.4 	c-0.4-0.1-0.8-0.2-1.2-0.2c-2.2,0-3.3,1.6-3.3,3.1c-0.1,1.7,0.6,2.9,2,4.9c0.3,0.5,0.8,2,1.2,3.5c0.6,1.9,1,3.7,1.7,4.7 	c0.2,0.5,2.7,4.4,4.2,4.4h0.2c0.2-0.1,0.4-0.2,0.6-0.5c0.4-0.7-0.1-2.3-1-4.7c-0.5-1.2-0.7-4,0.2-3.5c0.2,0.2,0.6,0.9,0.8,1.3 	c0.2,0.4,0.8,1.2,1,1.6c0.8,1.2,3.4,3.6,5.5,4.6C317.2,81.2,319.7,81.7,320.2,80.9',
	37: 'M298,86.1c1,0,1.7-1.3,1.9-3.8c0.3-3.5-0.6-7.2-2.6-10.2c-1.2-1.9-1-3.3,0.5-4.5c0.8-0.6,1.3-1.6,1.4-2.7 	c0.1-1.2-0.4-2.3-1.1-3.1c-1-1-2.8-1.7-4.5-1.7c-0.6,0-1.2,0.1-1.6,0.3c-0.6,0.2-1.3,0.4-2.3,0.4s-2.1-0.2-3.5-0.4 	c-0.4-0.1-0.7-0.1-1-0.1c-2.3,0-3.9,1.4-4.1,3.5c-0.1,1.4,0.8,2.7,1.6,3.8c0.6,0.7,1,1.4,1.1,1.9c0.1,0.4,0.1,1.2,0.1,2.1 	c-0.1,2.3-0.1,5.4,1.4,8c0.5,0.7,4.2,6,6.4,6h0.3c0.3-0.1,0.6-0.2,0.7-0.6c0.4-0.9-0.3-2.4-1.5-4.9c-0.6-1.1-1.1-2.3-1.4-3.1 	c-0.4-1.4-0.3-2.7,0.2-3.5c0.3-0.5,0.7-0.8,1.3-0.8h0.1c1.2,0,3.3,3.1,4.3,6.3c0.2,0.5,0.4,1.5,0.6,2.6c0.7,3.7,1,4.3,1.5,4.5 	C297.8,86.1,297.9,86.1,298,86.1',
	36: 'M269.7,88.6c0.6,0,1-0.4,1-1c0-0.6-0.2-1.2-0.4-2.2c-0.4-1.5-0.8-3.1-1-4.7c-0.5-3.8,0-6.5,1.1-6.8 	c1.3,0,4.1,4.6,5.2,7.9c0.1,0.2,0.2,0.6,0.3,0.9c0.7,2.3,1.5,4.8,3,4.8c0.5,0,0.8-0.2,1-0.4c0.6-0.8,0.5-2.3-0.1-5.9 	c-0.5-3.1-1.4-5.1-2.1-6.5c-0.2-0.3-0.2-0.6-0.4-0.8c-0.8-1.8-0.7-4.7,0.7-6.8c0.8-1,1-2.1,0.8-3.1c-0.3-1.3-1.4-2.3-3.2-3.2 	c-0.6-0.3-1.3-0.5-1.9-0.5c-1.2,0-2.1,0.6-2.8,1c-0.3,0.2-0.6,0.4-0.8,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0-1-0.3-1.6-0.7 	s-1.1-0.9-2-1c-0.2,0-0.3-0.1-0.6-0.1c-1.9,0-3.9,2.2-4.3,4.1c-0.4,1.9,0,2.6,1.3,4.3c0.6,0.8,0.7,2.4,0.8,3.6c0,0.5,0.1,1,0.2,1.3 	c0,0.2,0.1,0.6,0.1,1.2c0.2,2.4,0.5,6.8,2.3,10.9C266.7,87.2,268.6,88.6,269.7,88.6',
	35: 'M251.1,87.1c0.2,0,0.3-0.1,0.5-0.2c0.8-0.5,1-2.2,0.6-6.3c-0.2-2.5,0.4-4.2,1.1-5.9c0.4-1,0.7-1.9,0.9-2.9 	c0.2-2,0.9-2.7,1.5-3.5c0.4-0.5,0.9-1,1.4-1.9c0.4-0.8,0.3-1.8-0.2-2.7c-1-1.9-3.7-3.4-6.6-3.8h-0.6c-3.2,0-5.6,3.2-5.6,4.9 	c0,1.4,0.6,2.7,1.6,4.2c0.6,0.9,0.8,3.5,1,6c0.2,2.3,0.3,4.5,0.8,6.2C248.3,84.7,249.8,87.1,251.1,87.1',
	34: 'M231,88.6c0.3,0,0.6-0.2,0.8-0.3c0.5-0.6,0.7-1.7,0.6-3.7c-0.2-3.1,0.3-6.3,0.8-9.2c0.2-1.9,0.6-3.6,0.6-5.1 	c0.1-1.2,0.8-1.9,1.6-2.7c0.6-0.6,1.2-1.1,1.5-2c0.2-0.6,0.1-1.4-0.4-2.2c-1-1.7-3.6-3-6.4-3.4h-0.6c-2.3,0-3.8,1.5-4.4,2.4 	c-0.7,0.9-1.4,2.3-1.2,2.9c0.2,0.9,0.4,1.6,1.7,2.8c0.6,0.6,1.4,5.6,1.9,8.6c0.3,1.8,0.6,3.4,0.7,4.1c0.2,0.6,0.3,1.4,0.6,2.4 	C229.6,87,230.1,88.6,231,88.6',
	33: 'M212.8,90.8c0.1,0,0.2,0,0.2-0.1c0.9-0.3,0.9-1.8,0.8-6c0-1.6-0.1-3.2,0-4.5c0.3-4.9,0.3-7,0.3-8.5V71 	c0-0.6,0.5-1.4,1.4-2.2c1-0.9,1.9-2.3,1.4-3.8c-0.7-2.7-3-4-7.3-4.9c-0.4-0.1-0.8-0.2-1.2-0.2c-1.9,0-3.2,1.4-4,2.3 	c-1,1.2-1.5,2.7-1.4,3.5c0.1,1,0.6,1.9,1.4,3c0.3,0.5,0.6,1,1,1.5c0.4,0.7,0.5,2.1,0.6,3.7c0.2,2.5,0.5,5.6,1.9,8.7 	C209.4,86.4,211.6,90.8,212.8,90.8',
	32: 'M190.8,88.9c1.9-0.2,1.4-6.4,1.4-6.4c-0.1-1,0.2-2.5,0.5-4.2c0.3-1.8,0.6-3.7,0.7-5.4c0-1.9,0.6-3.1,1.4-4.5 	c0.5-0.9,0.9-1.9,1.2-2.9c0.2-1.1,0.4-2.3-0.2-3.1c-0.6-1-2-1.5-4.7-1.9c-0.3-0.1-0.6-0.1-0.9-0.2c-0.6-0.2-1.1-0.2-1.9-0.2 	c-0.4,0-0.9,0.1-1.4,0.2c-0.8,0.2-1.4,0.6-1.7,1.4c-0.6,1.5-0.2,3.9,1.1,5.7c1.4,1.9,1.5,8,1.6,11.6c0,1,0.1,1.8,0.1,2.3 	C188.1,82.1,189,88.9,190.8,88.9',
	31: 'M171.2,89.8c0.2,0,0.5-0.1,0.7-0.3c0.8-0.8,0.9-3,0.8-4.2c0-1,0.2-2.1,0.4-3.3c0.3-1.4,0.6-3.3,0.7-5.9 	c0.2-4.6,0.6-6.4,1.4-9.3v-0.1c0.5-1.9,0.9-3.2,0.2-4.3c-0.7-1.1-2.3-1.8-5.4-2.3c-0.4-0.1-0.8-0.1-1.2-0.1c-1.6,0-2.5,0.6-3,1.3 	c-0.8,1-1,2.3-0.8,3.2c0.2,1.7,0.6,3.1,1.9,5.2c1.2,1.9,1.4,9.7,1.4,12.6v0.2c0.1,2.7,1.2,7.2,2.5,7.4 	C171,89.8,171.1,89.8,171.2,89.8',
};
