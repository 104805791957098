import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/StatsCard.css';


const baseCssClassName = 'stats-card';
const headerCssClassName = `${baseCssClassName}-header`;


export class StatsCardHeader extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
	}

	render () {
		return (
			<div className={headerCssClassName}>{this.props.children}</div>
		);
	}
}

const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';


export class StatsCard extends PureComponent {
	static propTypes = {
		theme: PropTypes.oneOf([ THEME_LIGHT, THEME_DARK ]),
		children: PropTypes.node.isRequired,
	}

	static defaultProps = {
		theme: THEME_LIGHT,
	}

	static THEME_LIGHT = THEME_LIGHT;
	static THEME_DARK = THEME_DARK;

	render () {
		return (
			<div
				className={classnames([
					baseCssClassName,
					`${baseCssClassName}__m-theme-${this.props.theme}`
				])}
			>
				{this.props.children}
			</div>
		);
	}
}
