export default {
	'grid.header.image_id': '图像标识号ID',
	'grid.header.image_date': '图像日期',
	'grid.header.patient_id': '病人身份标识号ID',
	'grid.header.birthday': '出生日期',
	'grid.header.gender': '性别',
	'grid.header.filename': '文件名',
	'grid.header.last_opening': '上次打开时间',
	'grid.header.last_editing': '上次编辑时间',
	'grid.header.image_type': '图像格式',
	'incorrect_patient_id': '只允许数字',
	'no_images': '您还未上传图像',
	'dialog.title': '注册表/资料库',
};
