import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';


const propTypes = {
	cellKey: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	contextTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
};

const contextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};


function HeaderCell (props, context) {
	return (
		<div
			className={classnames([
				`${context.gridCssClassName}__header-cell`,
				`${context.gridCssClassName}__header-cell__m-${props.cellKey}`,
			])}
			style={{ width: props.width }}
		>
			<div className={`${context.gridCssClassName}__header-cell-content`} title={props.contextTitle}>
				{props.children}
			</div>
		</div>
	);
}

HeaderCell.propTypes = propTypes;
HeaderCell.contextTypes = contextTypes;

export default HeaderCell;
