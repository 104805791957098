import mainConfig from '../configs/mainConfig';

import { getRuntimeConfig } from './runtimeConfig';

import { getStorageService } from '../services/storage';

import cookiesUtils from '../appUtils/cookiesUtils';


const SCROLL_SIZE_CACHE = {};


function getScrollSize (scrollCssClassName = mainConfig.MAIN_SCROLL_CSS_CLASS_NAME) {
	if ( SCROLL_SIZE_CACHE[scrollCssClassName] ) {
		return SCROLL_SIZE_CACHE[scrollCssClassName];
	}

	const container = document.createElement('DIV');
	const content = document.createElement('DIV');

	container.style.height = '100px';
	container.style.overflowY = 'scroll';
	container.style.position = 'absolute';
	container.style.left = '-9999px';
	content.style.height = '200px';
	container.className = scrollCssClassName;
	content.className = scrollCssClassName;

	container.appendChild(content);
	document.body.appendChild(container);

	SCROLL_SIZE_CACHE[scrollCssClassName] = container.offsetWidth - container.clientWidth;
	return SCROLL_SIZE_CACHE[scrollCssClassName];
}

/**
 * Compares two objects using shallow comparison.
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {boolean}
 */
function shallowCompare (a, b) {
	if ( Object.keys(a).length !== Object.keys(b).length ) {
		return false;
	}

	for (let p in a) {
		if ( !a.hasOwnProperty(p) ) {
			continue;
		}

		if ( a[p] !== b[p] ) {
			return false;
		}
	}

	return true;
}

function goToLandingPage () {
	window.location.href = getRuntimeConfig().landing_url;
}

function goToLoginPage () {
	window.location.href = getRuntimeConfig().login_redirect_url;
}

function goToLandingPageFreeTrial () {
	window.location.href = getRuntimeConfig().free_trial_url;
}

/**
 * @returns {boolean}
 */
function inIframe () {
	try {
		return window.self !== window.top;
	}
	catch (error) {
		return true;
	}
}

// convert a Unicode string to a string
function utfToBinary (str) {
	return Uint8Array.from(atob(str), c => c.charCodeAt(0));
}

/**
 * @return {Object}
 */
function getUserApiParams () {
	const apiUerParams = getStorageService().get('user_api_params');
	let apiUserParamsAsObj = {};
	if ( typeof apiUerParams === 'object' && apiUerParams !== null ) {
		apiUserParamsAsObj = apiUerParams;
	}
	else {
		// @todo Remove later
		const apiGetParamsAsString = cookiesUtils.read(getRuntimeConfig().api_params_cookie_name);
		if ( typeof apiGetParamsAsString !== 'undefined' && apiGetParamsAsString !== null && apiGetParamsAsString.length > 0 ) {
			try {
				const params = JSON.parse(apiGetParamsAsString)
				apiUserParamsAsObj = params;
				getStorageService().set('user_api_params', params);
				cookiesUtils.remove(getRuntimeConfig().api_params_cookie_name);
			}
			catch (error) {}
		}
	}

	return apiUserParamsAsObj;
}

/**
 * @return {string|null}
 */
function getUserApiParamsForUrl () {
	const apiParams = getUserApiParams();
	
	if ( Object.keys(apiParams).length > 0 ) {
		const params = Object.keys(apiParams).reduce((result, name) => {
			result.push(`${name}=${apiParams[name]}`);
			return result;
		}, []);

		return params.join('&');
	}
	
	return null;
}

/**
 * @param {string} url
 * @param {string} param
 * @param {string} value
 */
function addParamToUrl (url, param, value) {
	if ( value.length === 0 ) {
		return url;
	}

	if ( url.includes('?') === true ) {
		return url + '&' + param + '=' + value;
	}

	return url + '?' + param + '=' + value;
}

export default {
	getScrollSize,
	shallowCompare,
	goToLandingPage,
	goToLoginPage,
	goToLandingPageFreeTrial,
	inIframe,
	utfToBinary,
	getUserApiParams,
	getUserApiParamsForUrl,
	addParamToUrl,
};
