import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormCustomSelect from '../../../../components/FormCustomSelect';

import classnames from 'classnames';

import './styles/ResolverFindingsBadgeSelector.css';


function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if (items[i].value === value) {
			return items[i];
		}
	}

	return null;
}

const baseCssClassName = 'resolver-findings-badge-selector';
const headerCssClassName = `${baseCssClassName}__header`;
const headerContentCssClassName = `${baseCssClassName}__header-content`;
const headerValueCssClassName = `${baseCssClassName}__header-value`;
const itemCssClassName = `${baseCssClassName}__item`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const itemsCssClassName = `${baseCssClassName}__items`;


class DiagnosisSelect extends PureComponent {
	static propTypes = {
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
			label: PropTypes.string.isRequired,
			hotKey: PropTypes.string,
			requiredLabel: PropTypes.bool,
		})),
		initialOpen: PropTypes.bool,
		disabled: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		onInit: PropTypes.func,
	}

	componentDidMount () {
		if ( this.props.onInit ) {
			this.props.onInit();
		}
	}

	_initialOpen = this.props.initialOpen;

	_handleRenderItem = (item) => (
		<div className={classnames([
			itemCssClassName,
			this.props.className && `${this.props.className}-item`,
		])}>
			<span>{item.label}</span>
		</div>
	)

	_handleRenderHeader = ({ isOpened }) => {
		const selectedItem = (this.props.value && this.props.items
				? (getItem(this.props.value, this.props.items) || {})
				: {}
		);

		return (
			<div className={classnames(headerContentCssClassName, this.props.headerClassName)}>
				<span className={headerValueCssClassName}>{selectedItem.label || this.props.emptyValue}</span>
				<div
					className={classnames([
						indicatorCssClassName,
						isOpened === true && `${indicatorCssClassName}__m-opened`,
					])}
				>
					<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.15893 6L0.841071 6C0.0931635 6 -0.280791 5.09482 0.246939 4.56709L4.40587 0.408164C4.73439 0.0796432 5.26561 0.0796432 5.59064 0.408164L9.75306 4.56709C10.2808 5.09482 9.90684 6 9.15893 6Z" fill="white"/>
					</svg>
				</div>
			</div>
		);
	}

	render () {
		return (
			<FormCustomSelect
				items={this.props.items}
				value={this.props.value}
				disabled={this.props.disabled}
				initialOpen={this._initialOpen}
				className={this.props.className ? this.props.className : baseCssClassName}
				headerCssClassName={headerCssClassName}
				itemsCssClassName={itemsCssClassName}
				onRenderHeader={this._handleRenderHeader}
				onRenderItem={this._handleRenderItem}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default DiagnosisSelect;
