import React, { Component } from 'react';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import commonUtils from '../../appUtils/common';

import Logo from '../Logo';
import SignIn from '../SignIn';

import { getDictionary } from '../../appUtils/locale';

import './styles/GuestHeader.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'guest-header';
const logoCssClassName = `${baseCssClassName}-logo`;
const authCssClassName = `${baseCssClassName}-auth`;
const authButtonCssClassName = `${authCssClassName}__button`;
const signInMenuCssClassName = `${baseCssClassName}__sign-in-menu`;
const versionCssClassName = `${baseCssClassName}__version`;


class GuestHeader extends Component {
	constructor (props, context) {
		super(props, context);

		this._isMenuHovered = false;

		this.state = {
			isSignInMenuOpened: false,
		};
	}

	_handleSignInMenuClick = () => {
		this.setState({
			isSignInMenuOpened: true,
		});

		this._addCloseHandler();
	}

	_handleDocumentClick = () => {
		if ( this._isMenuHovered ) {
			return;
		}

		this._removeCloseHandler();
		this.setState({
			isSignInMenuOpened: false,
		});
	}

	_handleMenuMouseEnter = () => {
		this._isMenuHovered = true;
	}

	_handleMenuMouseLeave = () => {
		this._isMenuHovered = false;
	}

	_handleFreeTrialClick = () => {
		commonUtils.goToLandingPageFreeTrial();
	}

	_addCloseHandler () {
		window.document.addEventListener('click', this._handleDocumentClick);
	}

	_removeCloseHandler () {
		window.document.removeEventListener('click', this._handleDocumentClick);
	}
	
	render() {
		return (
			<div className={baseCssClassName}>
				<div className={logoCssClassName}>
					<Logo />
				</div>
				<div className={authCssClassName}>
					<div
						className={`${authButtonCssClassName} ${authButtonCssClassName}__m-sign-in`}
						onClick={this._handleSignInMenuClick}
					>{i18nShared('buttons.sign_in')}</div>
					<div
						className={`${authButtonCssClassName} ${authButtonCssClassName}__m-free-trial`}
						onClick={this._handleFreeTrialClick}
					>{i18nShared('buttons.free_trial')}</div>
					{ this.state.isSignInMenuOpened && (
						<div
							className={signInMenuCssClassName}
							onMouseEnter={this._handleMenuMouseEnter}
							onMouseLeave={this._handleMenuMouseLeave}>
							<SignIn />
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default GuestHeader;
