export default {
	'no_collections': 'No collections',
	'add_collection': 'Add collection',
	'edit_collection': 'Edit collection',

	'copy.title': 'Copy collection',

	'copy.labels.user': 'User',
	'copy.labels.collection_name': 'Collection name',
	'copy.labels.collection_description': 'Collection description',
	'copy.labels.with_annotations': 'With annotations',
	'copy.labels.randomize': 'Randomize images',

	'copy.buttons.copy': 'Copy',

	'search.placeholder': 'Collection name',
	'search.button': 'Search',

};
