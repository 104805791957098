import { PureComponent } from 'react';

import PropTypes from 'prop-types';


class GridProvider extends PureComponent {
	static propTypes = {
		cssClassName: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
	}
	
	static childContextTypes = {
		gridCssClassName: PropTypes.string.isRequired,
	}
	
	getChildContext () {
		return {
			gridCssClassName: this.props.cssClassName,
		};
	}

	render () {
		return this.props.children;
	}
}

export default GridProvider;
