import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { getDictionary } from '../../../../appUtils/locale';

import './styles/FmxViewerRotationButtons.css';


const i18n = getDictionary('toolbar'); // todo move to shared

const baseCssClassName = 'fmx-viewer-rotation-buttons';
const buttonCssClassName = `${baseCssClassName}__button`;

const POSITIONS = {
	TOP: 'top',
	DOWN: 'down',
};


export default function FmxViewerRotationButtons (props) {
	const baseRef = useRef(null);
	const [ position, setPosition ] = useState(POSITIONS.top);

	useEffect(() => {
		if ( baseRef.current && props.viewport ) {
			const baseElRect = baseRef.current.getBoundingClientRect();
			const viewportRect = props.viewport.getBoundingClientRect();

			if ( baseElRect.top >= viewportRect.top && baseElRect.bottom >= viewportRect.top ) {
				if ( position !== POSITIONS.TOP ) {
					setPosition(POSITIONS.TOP);
				}
			}
			else  {
				if ( position !== POSITIONS.DOWN ) {
					setPosition(POSITIONS.DOWN);
				}
			}
		}
	}, []);

	return (
		<div
			className={`${baseCssClassName} ${baseCssClassName}__m-position-${position}`}
			ref={baseRef}
		>
			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-left`}
				title={i18n('rotate.left')}
				onClick={props.onRotateLeft}
			/>

			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-right`}
				title={i18n('rotate.right')}
				onClick={props.onRotateRight}
			/>
		</div>
	);
}

FmxViewerRotationButtons.propTypes = {
	onRotateLeft: PropTypes.func,
	onRotateRight: PropTypes.func,
	viewport: PropTypes.object,
};
