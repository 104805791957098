import lodashGet from 'lodash/get';


/**
 * @implements {HelpMessenger}
 */
export default class ZendeskHelpMessenger {
	setup (options) {
		this._loadScript()
			.then(() => {
				const userData = this._getUserData(options.user);
				const message = {};

				if ( options.extra ) {
					if ( typeof options.extra.dtr_op === 'string' ) {
						message.operator = options.extra.dtr_op;
					}

					if ( typeof options.extra.dtr_provider === 'string' ) {
						message.provider = options.extra.dtr_provider;
					}
				}

				window.zE('webWidget', 'identify', this._getUserData(options.user));
				window.zE('webWidget', 'prefill', Object.keys(userData).reduce((result, key) => {
					result[key] = {
						value: userData[key],
						readOnly: true,
					};

					return result;
				}, {}));
				window.zE('webWidget:on', 'close', () => {
					this.close();
				});
				// window.zE('webWidget:on', 'open', () => {
				// 	const newMessage = {
				// 		...message,
				// 		path: window.location.href,
				// 	};
				// 	const collection = options.getCollection();
				//
				// 	if ( collection ) {
				// 		message.collection = collection.id;
				// 	}
				//
				// 	if ( Object.keys(newMessage).length > 0 ) {
				// 		const messageString = Object.keys(newMessage).reduce((result, key) => {
				// 			return result + `${key}: ${newMessage[key]}\n`;
				// 		}, '');
				// 		window.zE('webWidget', 'updateSettings', {
				// 			contactForm: {
				// 				fields: [
				// 					{ id: 'description', prefill: { '*': messageString + '\n' } }
				// 				],
				// 			},
				// 		});
				// 	}
				//
				//
				// });

				this.close();
			})
			.catch(() => {
				// do nothing now
			})
	}

	update (options= {}) {
	}

	teardown() {}

	/**
	 * @param {string} name
	 * @param {*} data
	 * @param {string} [color="green"]
	 */
	sendEvent (name, data, color = 'green') {
	}

	/**
	 * @param {string} imageLink
	 */
	sendAnalyzedEvent (imageLink) {
	}

	/**
	 * @param {string} imageLink
	 */
	sendUploadedImageEvent (imageLink) {
	}

	/**
	 * @param {Object} options
	 */
	updateUserData (options) {
		window.zE('webWidget', 'identify', this._getUserData(options));
	}

	open () {
		window.zE('webWidget', 'open');
		window.zE('webWidget', 'show');
	}

	close () {
		window.zE('webWidget', 'close');
		window.zE('webWidget', 'hide');
	}

	_getUserData (options) {
		const data = {};
		const userEmail = lodashGet(options, 'email', null);
		if ( userEmail !== null ) {
			data.email = userEmail;
		}

		data.name = `${lodashGet(options, 'first_name', '')} ${lodashGet(options, 'last_name', '')}`;

		const userOrganization =  lodashGet(options, 'clinic', null);
		if ( userOrganization !== null ) {
			data.organization = userOrganization;
		}

		return data;
	}

	/**
	 * @return {Promise}
	 * @private
	 */
	_loadScript () {
		return new Promise((resolve, reject) => {
			const script = window.document.createElement('SCRIPT');
			script.id = 'ze-snippet';
			script.src = 'https://static.zdassets.com/ekr/snippet.js?key=0b01853c-18a9-466c-a4d6-4666522ae2c3';
			script.addEventListener('load', () => {
				resolve();
			})
			window.document.getElementsByTagName('HEAD')[0].appendChild(script);
		});
	}
}
