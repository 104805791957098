import analyseTypes from '../actions/analyseTypes';


/**
 * @typedef {Object} AnalysisStoreState
 *
 * @property {Object<CollectionImageId,boolean>} analyzedImages
 * @property {Object<CollectionImageId,boolean>} imagesInAnalysis
 */


const initialState = {
	analyzedImages: {},
	imagesInAnalysis: {},
};

/**
 * @param {AnalysisStoreState} state
 * @param {ReduxAction} action
 * @return {AnalysisStoreState}
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case analyseTypes.ACTION_ANALYSIS__SET_ANALYZED: {
			return {
				...state,
				analyzedImages: {
					...state.analyzedImages,
					[action.payload]: true,
				},
			};
		}

		case analyseTypes.ACTION_ANALYSIS__UNSET_ANALYZED: {
			const nextState = {
				...state,
				analyzedImages: {
					...state.analyzedImages,
				},
			};

			delete nextState.analyzedImages[action.payload];

			return nextState;
		}

		case analyseTypes.ACTION_ANALYSIS__IMAGE_ANALYSIS_START: {
			return {
				...state,
				imagesInAnalysis: {
					...state.imagesInAnalysis,
					[action.payload]: true,
				},
			};
		}

		case analyseTypes.ACTION_ANALYSIS__IMAGE_ANALYSIS_END: {
			const nextState = {
				...state,
				imagesInAnalysis: {
					...state.imagesInAnalysis,
				},
			};

			delete nextState.imagesInAnalysis[action.payload];

			return nextState;
		}

		default:
			return state;
	}
}
