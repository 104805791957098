import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reportError } from '../../../appUtils/errors';

import './styles/Entry.css';


const baseCssClassName = 'app-entry';
const errorCssClassName = `${baseCssClassName}__error`;


export default class Entry extends Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
	}

	static contextTypes = {
		store: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this.state = {
			hasError: false,
		};
	}

	componentDidCatch (error, errorInfo) {
		reportError(error, errorInfo);
		this.setState({
			hasError: true,
		});
	}

	render () {
		if ( this.state.hasError ) {
			return (
				<div className={errorCssClassName}>An critical error occurred</div>
			);
		}

		return this.props.children;
	}
}

