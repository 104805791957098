import classnames from 'classnames';
import constants from './OverlayConstants';


/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.theme]
 * @param {boolean} [props.absolute]
 *
 * @return {string}
 */
function buildBaseClassName(props = {}) {
	return classnames(
		constants.baseCssClassName,
		`${constants.baseCssClassName}__m-theme-${props.theme || constants.DEFAULT_THEME}`,
		props.absolute && `${constants.baseCssClassName}__m-absolute`,
	);
}

/**
 * @param {OverlayScrollProps} [props]
 */
function buildScrollStyle (props = {}) {
	const style = {};
	
	if ( props.fullscreen ) {
		style.overflowY = 'scroll';
	}
	else if ( props.verticalEnabled ) {
		style.overflowY = 'auto';
	}
	
	if ( props.horizontalEnabled ) {
		style.overflowX = 'auto';
	}
	
	return style;
}


export default {
	buildBaseClassName,
	buildScrollStyle,
};
