import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sizeDetector from 'element-resize-detector';

import './styles/SizeDetector.css';


const baseCssClassName = 'size-detector';


export default class SizeDetector extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func,
	};

	static defaultProps = {
		onChange: () => {},
	};

	state = {
		width: 0,
		height: 0,
	};

	_sizeDetector = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	constructor (props) {
		super(props);

		this._sizeDetector = sizeDetector({
			strategy: 'scroll',
		});
	}

	componentDidMount() {
		this._sizeDetector.listenTo(this._baseEl, this._handleShrinkScroll);
	}

	componentWillUnmount () {
		if ( this._sizeDetector !== null ) {
			this._sizeDetector.removeListener(this._baseEl, this._handleShrinkScroll);
			this._sizeDetector = null;
		}
	}

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleRef = (element) => {
		if ( element === null || element === undefined || element === this._baseEl ) {
			return;
		}

		this._baseEl = element;
		this.setState({
			width: this._baseEl.offsetWidth,
			height: this._baseEl.offsetHeight,
		});
	}

	_handleShrinkScroll = () => {
		const width = this._baseEl.offsetWidth;
		const height = this._baseEl.offsetHeight;
		if (
			this.state.width !== width ||
			this.state.height !== height
		) {
			this.setState({
				width,
				height,
			});
			this.props.onChange();
		}
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				ref={this._handleRef}
			>
				{this.props.children({ width: this.state.width, height: this.state.height })}
			</div>
		);
	}
}
