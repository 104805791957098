export default {
	'labels.new_password': 'New password',
	'labels.confirmation': 'New password confirmation',
	'labels.current_password': 'Current password',

	'info': `
		You should enter your new password`,

	'dialog.title': 'Change password',
};
