import storeUtils from '../../appUtils/storeUtils';


let store = null;

export function getStore () {
	return store;
}

export function createStore(...args) {
	store = storeUtils.configureStore(...args);

	return store;
}
