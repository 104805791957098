import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import labelsUtils from '../../appUtils/labelsUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import mainConfig from '../../configs/mainConfig';

import FormCheckbox from '../FormCheckbox';
import DiagnosisSelect from '../DiagnosisSelect';
import LabelTags from '../LabelTags';
import { ControlledTooltip } from '../Tooltip';

import labelGetters from '../../modules/labels/selectors/labelGetters';

import { getDictionary } from '../../appUtils/locale';

import './styles/Label.css';


const i18n = getDictionary('tooth-info');
const i18nFindings = getDictionary('findings');

const baseCssClassName = 'label';
const mainClassName = `${baseCssClassName}-main`;
const nameCssClassName = `${baseCssClassName}-name`;
const measureValueCssClassName = `${baseCssClassName}-measure-value`;
const measureValueIndicatorCssClassName = `${measureValueCssClassName}-indicator`;
const measureValueIndicatorLineCssClassName = `${measureValueIndicatorCssClassName}-line`;
const confirmCssClassName = `${baseCssClassName}-confirm`;
const removeCssClassName = `${baseCssClassName}-remove`;
const removeButtonCssClassName = `${removeCssClassName}-button`;
const selectorCssClassName = `${baseCssClassName}-selector`;
const measureValueIndicatorWithPathologies = `${measureValueIndicatorCssClassName}__m-with-pathologies`;
const tagsCssClassName = `${baseCssClassName}__tags`;


class LabelView extends PureComponent {
	static propTypes = {
		label: PropTypes.object.isRequired,
		tags: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})),
		showControls: PropTypes.bool,
		showConfirmation: PropTypes.bool,
		showDelete: PropTypes.bool,
		isEditMode: PropTypes.bool,
		availableLabels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		availableChildrenLabels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,

		onSetConfirmed: PropTypes.func,
		onRemove: PropTypes.func,
		onNameClick: PropTypes.func,
		onNameOver: PropTypes.func,
		onNameOut: PropTypes.func,
		onLabelChange: PropTypes.func,
		onAddDiagnosis: PropTypes.func,
	};

	static defaultProps = {
		showConfirmation: true,
		showDelete: true,
	};

	_handleNameClick = () => {
		const {
			label,
			isEditMode,

			onNameClick,
		} = this.props;

		if ( isEditMode ) {
			return;
		}

		if ( onNameClick ) {
			onNameClick(label);
		}
	}

	_handleConfirmChange = () => {
		const {
			label,
			onSetConfirmed,
		} = this.props;

		if ( onSetConfirmed ) {
			onSetConfirmed({ labelId: labelGetters.getLabelId(label), value: !labelGetters.getLabelIsConfirmed(label) });
		}
	}
	
	_handleRemoveButtonClick = (event) => {
		const {
			label,

			onRemove,
		} = this.props;

		event.stopPropagation();

		if ( onRemove ) {
			onRemove({ labelId: labelGetters.getLabelId(label) });
		}
	}

	_handleMouseOver = () => {
		const {
			label,

			onNameOver,
		} = this.props;

		if ( onNameOver ) {
			onNameOver({ labelId: labelGetters.getLabelId(label) });
		}
	}

	_handleMouseOut = () => {
		const {
			onNameOut,
		} = this.props;

		if ( onNameOut ) {
			onNameOut();
		}
	}

	_handleLabelChange = (value) => {
		const {
			label,

			onLabelChange,
		} = this.props;

		if ( onLabelChange ) {
			onLabelChange({ labelId: labelGetters.getLabelId(label), newClassId: value });
		}
	}

	_handleChildLabelChange = (value) => {
		const {
			label,

			onAddDiagnosis,
		} = this.props;

		if ( onAddDiagnosis ) {
			onAddDiagnosis({
				classId: value,
				parentLabel: label,
				editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
			});
		}
	}

	_getAvailableLabels () {
		return this.props.availableLabels.map((labelData) => ({
			value: labelData.classId,
			label: labelData.localizedClassName,
			requiredLabel: labelData.requiredLabel,
		}));
	}
	
	_renderControls () {
		const {
			label,
			showControls,
			showConfirmation,
			showDelete
		} = this.props;

		if ( !showControls ) {
			return null;
		}

		return (
			<Fragment>
				{showConfirmation && (
					<div
						className={confirmCssClassName}
						onClick={(event) => event.stopPropagation()}
					>
						<FormCheckbox
							inputProps={{
								checked: labelGetters.getLabelIsConfirmed(label),
								title: i18n('confirm_finding'),
								onChange: this._handleConfirmChange,
							}}
						/>
					</div>
				)}
				{showDelete && (
					<div className={removeCssClassName}>
						<div
							className={removeButtonCssClassName}
							title={i18n('remove_finding')}
							onClick={this._handleRemoveButtonClick}
						/>
					</div>
				)}
			</Fragment>
		);
	}
	
	_renderIndicator () {
		const {
			label,
			isPathology,
		} = this.props;

		const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);

		if ( typeof measureOfConfidence !== 'number' || measureOfConfidence === 1 ) {
			return null;
		}

		return (
			<div
				className={classnames([
					measureValueIndicatorCssClassName,
					isPathology && measureValueIndicatorWithPathologies,
				])}
			>
				<div
					className={measureValueIndicatorLineCssClassName}
					style={{
						width: `${measureOfConfidence * 100}%`,
						backgroundColor: labelsUtils.getLabelColor(label),
					}}
				/>
			</div>
		);
	}

	_renderTags () {
		return (
			<div className={tagsCssClassName}>
				<LabelTags
					label={this.props.label}
					tags={this.props.tags}
					isEditMode={this.props.isEditMode}
				/>
			</div>
		);
	}

	render () {
		const {
			label,
			isEditMode,
		} = this.props;

		const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
		const name = labelsUtils.getLocalizedLabelName(label);

		return (
			<div 
				className={baseCssClassName}
				onClick={this._handleNameClick}
			>
				<div className={mainClassName}>
					{ !isEditMode && (
						<div
							className={nameCssClassName}
							onMouseOver={this._handleMouseOver}
							onMouseOut={this._handleMouseOut}
						>{name}</div>
					) }

					{ isEditMode && (
						<div className={selectorCssClassName}>
							<DiagnosisSelect
								items={this._getAvailableLabels()}
								value={labelGetters.getLabelClassId(label)}
								disabled={isSmallScreen()}
								onChange={this._handleLabelChange}
							/>
						</div>
					) }

					{ typeof measureOfConfidence === 'number' && measureOfConfidence !== 1 && (
						<div className={measureValueCssClassName}>
							<ControlledTooltip
								content={(
									<div style={{ width: 200 }}>{i18nFindings('confidence.tooltip')}</div>
								)}
							>{Math.floor(measureOfConfidence * 100)}%</ControlledTooltip>
						</div>
					) }
					
					{this._renderControls()}
				</div>
				{this._renderIndicator()}
				{this._renderTags()}
				{ isEditMode && this.props.availableChildrenLabels && this.props.availableChildrenLabels.length > 0 && (
					<div>
						<DiagnosisSelect
							items={this.props.availableChildrenLabels
								.map((childLabel) => ({
									value: childLabel.classId,
									label: childLabel.localizedClassName,
								}))}
							disabled={isSmallScreen()}
							onChange={this._handleChildLabelChange}
						/>
					</div>
				) }
			</div>
		);
	}
}

export default LabelView;
