export default {
	'messages.wait': '请耐心等候',
	'messages.image_wait': '图像正在上传，请耐心等候',
	'messages.loading_error': '上传时出错',
	'messages.error.with_retry': '图像正在上传，请耐心等候',
	'messages.success': '修改密码成功',

	'buttons.try_again': '请再次尝试',
	'buttons.change': '更改',
	'buttons.ok': '是的',
	'buttons.agree': '我同意',
	'buttons.back': '退后',
	'buttons.delete': '删除',
	'buttons.cancel': '取消',
	'buttons.copy': '复制',
	'buttons.close': '关闭',
	'buttons.save': '保存',
	'buttons.continue': '继续',
	'buttons.download': '下载',
	'buttons.sign_in': '登陆',
	'buttons.sign_up': '注册',
	'buttons.logout': '注销',
	'buttons.free_trial': '免费试用',
	'buttons.analyze': '进行分析',
	'buttons.report': '报告',

	'confirm.dialog.title': '确认',
	'confirm.buttons.yes': '是',
	'confirm.buttons.no': '否',

	'warning.only_digits': '只允许数字',

	'gender.female': '女性',
	'gender.male': '男性',
	'gender.unknown': '未知',

	'image_types.pan': '全景的',
	'image_types.xray': '口腔内X光',

};
