import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/FormCheckbox.css';


const baseCssClassName = 'form-checkbox';

const propTypes = {
	inputProps: PropTypes.object,
};


class FormCheckbox extends PureComponent {
	constructor(props, context) {
		super(props, context);

		this._inputEl = null;
	}

	_handleInputChange = (event) => {
		const {
			inputProps = {},
		} = this.props;

		if ( inputProps.onChange ) {
			inputProps.onChange(event);
		}
	}

	_handleInputRef = (el) => {
		this._inputEl = el;
	}

	_handleVisualClick = () => {
		const {
			inputProps = {},
		} = this.props;

		const {
			disabled = false,
		} = inputProps;

		if ( !disabled ) {
			this._inputEl.focus();
		}
	}

	render () {
		const {
			inputProps = {},
		} = this.props;

		const {
			disabled = false,
			checked = false,
			title,
		} = inputProps;

		const checkboxProps = {
			disabled,
			checked,
			...inputProps,
		};

		return (
			<div
				className={classnames([
					baseCssClassName,
					disabled && `${baseCssClassName}__m-disabled`,
				])}
				title={title}
			>
				<input
					{...checkboxProps}

					ref={this._handleInputRef}

					type={'checkbox'}
					className={`${baseCssClassName}__input`}

					onChange={this._handleInputChange}
				/>
				<div
					className={classnames([
						`${baseCssClassName}__visual`,
						inputProps.checked && `${baseCssClassName}__visual__m-checked`,
					])}
					onClick={this._handleVisualClick}
				/>
				{this.props.children}
			</div>
		);
	}
}

FormCheckbox.propTypes = propTypes;

export default FormCheckbox;

