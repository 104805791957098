const ACTION_LABELS__UPDATE_DATA = 'ACTION_LABELS__UPDATE_DATA';
const ACTION_LABELS__ADD_LABEL = 'ACTION_LABELS__ADD_LABEL';
const ACTION_LABELS__REMOVE_LABEL = 'ACTION_LABELS__REMOVE_LABEL';
const ACTION_LABELS__SET_CONFIRMED = 'ACTION_LABELS__SET_CONFIRMED';


export default {
	ACTION_LABELS__UPDATE_DATA,
	ACTION_LABELS__ADD_LABEL,
	ACTION_LABELS__REMOVE_LABEL,
	ACTION_LABELS__SET_CONFIRMED,
};
