import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import userActions from '../../actions/userActions';

import userSelectors from '../../selectors/userSelectors';

import PopupDialog, { getFooterCloseButton } from '../PopupDialog';

import FormLabel from '../FormLabel';
import FormInput from '../FormInput';

import Button from '../Button';

import Loading from '../Loading';

import { getDictionary } from '../../appUtils/locale';

import './styles/ChangePassword.css';

const i18n = getDictionary('change-password');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'change-password';
const formCssClassName = `${baseCssClassName}__form`;
const formMainCssClassName = `${baseCssClassName}__form-main`;
const formSecondaryCssClassName = `${baseCssClassName}__form-secondary`;
const messageCssClassName = `${baseCssClassName}__message`;
const messageTextCssClassName = `${baseCssClassName}__message-text`;


class ChangeEmail extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,

		onChangePassword: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}

	state = {
		wrongFields: [],
		fields: {
			newPassword: '',
			newPasswordConfirmation: '',
			currentPassword: '',
		}
	}

	_handleFieldChange = (event) => {
		this.setState({
			fields: {
				...this.state.fields,
				[event.target.name]: event.target.value,
			},
			wrongFields: this.state.wrongFields.filter((fieldName) => fieldName !== event.target.name ),
		});
	}

	_handleChangeButtonClick = () => {
		const {
			fields,
		} = this.state;

		const wrongFields = [];

		for ( let p in fields ) {
			if ( fields.hasOwnProperty(p) ) {
				if ( fields[p].length === 0 ) {
					wrongFields.push(p);
				}
			}
		}

		if ( fields.newPassword !== fields.newPasswordConfirmation ) {
			wrongFields.push('newPasswordConfirmation');
		}

		this.setState({
			wrongFields,
		});

		if ( wrongFields.length === 0 ) {
			this._changePassword();
		}
	}

	_handleChangeTryAgainButtonClick = () => {
		this._changePassword();
	}

	_changePassword () {
		const {
			fields,
		} = this.state;

		this.setState({
			isSaving: true,
			hasSavingError: false,
		});

		this.props.onChangePassword({
			data: {
				...fields,
			},
		})
			.then(() => {
				this.setState({
					isSaving: false,
					isDataDirty: false,
					isSaved: true,
				});
			})
			.catch(() => {
				this.setState({
					isSaving: false,
					hasSavingError: true,
				});
			});
	}

	_renderForm () {
		const {
			fields,
			wrongFields,
		} = this.state;

		return (
			<div className={formCssClassName}>
				<div className={formMainCssClassName}>
					<FormLabel
						element={
							(
								<FormInput
									name={'newPassword'}
									value={fields.newPassword}
									type={'password'}
									isInvalid={wrongFields.indexOf('newPassword') !== -1}
									onChange={this._handleFieldChange}
								/>
							)
						}
						text={i18n('labels.new_password')}
						textPosition={FormLabel.textPosition.TOP}
					/>
					<FormLabel
						element={
							(
								<FormInput
									name={'newPasswordConfirmation'}
									value={fields.newPasswordConfirmation}
									type={'password'}
									isInvalid={wrongFields.indexOf('newPasswordConfirmation') !== -1}
									onChange={this._handleFieldChange}
								/>
							)
						}
						text={i18n('labels.confirmation')}
						textPosition={FormLabel.textPosition.TOP}
					/>
					<FormLabel
						element={
							(
								<FormInput
									name={'currentPassword'}
									value={fields.currentPassword}
									type={'password'}
									isInvalid={wrongFields.indexOf('currentPassword') !== -1}
									onChange={this._handleFieldChange}
								/>
							)
						}
						text={i18n('labels.current_password')}
						textPosition={FormLabel.textPosition.TOP}
					/>
				</div>
				<div
					className={formSecondaryCssClassName}
					dangerouslySetInnerHTML={{
						__html: i18n('info'),
					}}
				/>
			</div>
		);
	}

	_renderContent () {
		if ( this.state.isSaving ) {
			return (
				<div className={messageCssClassName}>
					<Loading />
					<div
						className={messageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.wait'),
						}}
					/>
				</div>
			);
		}
		else if ( this.state.hasSavingError ) {
			return (
				<div className={messageCssClassName}>
					<div
						className={messageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.loading_error'),
						}}
					/>
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this._handleChangeTryAgainButtonClick}
					>{i18nShared('buttons.try_again')}</Button>
				</div>
			);
		}
		else if ( this.state.isSaved ) {
			return (
				<div className={messageCssClassName}>
					<div>{i18nShared('messages.success')}</div>
				</div>
			);
		}

		return this._renderForm();
	}

	render () {
		const {
			isSaved,
			isSaving,
			hasSavingError,
		} = this.state;

		return (
			<PopupDialog
				headerProps={{
					title: i18n('dialog.title'),
				}}
				content={() => (
					<div className={baseCssClassName}>
						{this._renderContent()}
					</div>
				)}
				footerProps={{
					buttons: (dialogInterface) => {
						return [
							( !isSaved && !isSaving && !hasSavingError ) && (
								<Button
									key={'save_button'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={this._handleChangeButtonClick}
								>{i18nShared('buttons.change')}</Button>
							),
							getFooterCloseButton(dialogInterface),
						].filter(Boolean);
					}
				}}
				popupProps={{
					onClose: this.props.onClose,
				}}
			/>
		);
	}
}

export default connect((state) => ({
	user: userSelectors.selectUserData(state),
}), (dispatch) => ({
	onChangePassword: (options) => dispatch(userActions.changePassword(options)),
}))(ChangeEmail);
