import labelGetters from '../modules/labels/selectors/labelGetters';

/**
 * @param {Label.classId} classId
 * @return {boolean}
 */
export function isBonelossLine (classId) {
	return /_line$/.test(classId) === true;
}

export function checkBoneLossLine ({ label, showBoneLossStages = false, allowBoneLossLines = [] }) {
	const classId = labelGetters.getLabelClassId(label);

	if ( allowBoneLossLines.includes(classId) === true ) {
		return showBoneLossStages === true;
	}

	return true;
}
