import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';
import classnames from 'classnames';

import './styles/Button.css';


const AVAILABLE_SIZES = {
	LARGE: 'large',
	MEDIUM: 'medium',
	SMALL: 'small',
};

const AVAILABLE_THEMES = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	WARN: 'warn',
};

const AVAILABLE_TYPES = {
	SUBMIT: 'submit',
	BUTTON: 'button',
	RESET: 'reset',
};

const baseCssClassName = 'button';


const propTypes = {
	block: PropTypes.bool,
	disabled: PropTypes.bool,
	size: PropTypes.oneOf(lodashValues(AVAILABLE_SIZES)),
	theme: PropTypes.oneOf(lodashValues(AVAILABLE_THEMES)),
	type: PropTypes.oneOf(lodashValues(AVAILABLE_TYPES)),
	href: PropTypes.string,
	download: PropTypes.bool,
	title: PropTypes.string,
	uppercase: PropTypes.bool,
	className: PropTypes.string,
	isPulsing: PropTypes.bool,
};

const defaultProps = {
	size: AVAILABLE_SIZES.SMALL,
	theme: AVAILABLE_THEMES.PRIMARY,
	type: AVAILABLE_TYPES.BUTTON,
	isPulsing: false,
};


class Button extends PureComponent {
	_handleLinkClick = (event) => {
		const {
			disabled,
			onClick,
		} = this.props;

		if ( disabled ) {
			event.preventDefault();
			return;
		}

		if ( onClick ) {
			onClick(event);
		}
	}

	_renderLink () {
		const {
			block,
			size,
			theme,
			uppercase,
			disabled,
			type,
			children,
			className,
			...rest
		} = this.props;

		if ( disabled ) {
			delete rest.onClick;
		}

		return (
			<a
				{...rest}
				onClick={this._handleLinkClick}
				className={classnames([
					baseCssClassName,
					size && `${baseCssClassName}__m-size-${size}`,
					theme && `${baseCssClassName}__m-theme-${theme}`,
					block && `${baseCssClassName}__m-block`,
					uppercase && `${baseCssClassName}__m-uppercase`,
					disabled && `${baseCssClassName}__m-disabled`,
					className,
				])}
				target={'_blank'}
			>{children}</a>
		);
	}

	_renderButton () {
		const {
			block,
			size,
			theme,
			uppercase,
			disabled,
			children,
			className,
			isPulsing,
			...rest
		} = this.props;

		return (
			<button
				{...rest}
				disabled={disabled}
				className={classnames([
					baseCssClassName,
					size && `${baseCssClassName}__m-size-${size}`,
					theme && `${baseCssClassName}__m-theme-${theme}`,
					block && `${baseCssClassName}__m-block`,
					uppercase && `${baseCssClassName}__m-uppercase`,
					disabled && `${baseCssClassName}__m-disabled`,
					isPulsing && `${baseCssClassName}__m-pulsing`,
					className,
				])}
			>{children}</button>
		);
	}

	render () {
		if ( typeof this.props.href !== 'string' ) {
			return this._renderButton();
		}

		return this._renderLink();
	}
}

Button.AVAILABLE_SIZES = AVAILABLE_SIZES;
Button.AVAILABLE_THEMES = AVAILABLE_THEMES;
Button.AVAILABLE_TYPES = AVAILABLE_TYPES;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
