import apiActionTypes from '../../../actions/apiActionTypes';
import imagesLabelsActionTypes from '../actions/imagesLabelsActionTypes';


/**
 * @typedef {Object<CollectionImageId,LabelId[]>} ImagesLabelsStoreState
 */


/**
 * @param {ImagesLabelsStoreState} state
 * @param {ReduxAction} action
 * @return {ImagesLabelsStoreState}
 */
export default function imagesLabelsReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.imagesLabels || state );

		case imagesLabelsActionTypes.ACTION_IMAGES_LABELS__ADD_LABEL: {
			const imageId = action.payload.imageId;

			return {
				...state,
				[imageId]: (state[imageId] || []).concat(action.payload.labelId),
			};
		}

		case imagesLabelsActionTypes.ACTION_IMAGES_LABELS__REMOVE_LABEL: {
			const imageId = action.payload.imageId;

			return {
				...state,
				[imageId]: state[imageId].filter((labelId) => labelId !== action.payload.labelId),
			};
		}

		default:
			return state;
	}
}
