import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashDebounce from 'lodash/debounce';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import commonUtils from '../../appUtils/common';
import { message } from '../../services/popup';
import { getStorageService } from '../../services/storage';
import { reset as resetMixPanel, trackEvent } from '../../integrations/mixpanel';
import userActions from '../../actions/userActions';

import userSelectors from '../../selectors/userSelectors';
import countriesSelectors from '../../selectors/countriesSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import mainConfig from '../../configs/mainConfig';
import teethConfig from '../../configs/teethConfig';

import imageUtils from '../../appUtils/imageUtils';

import Alignment from '../Alignment';
import Overlay from '../Overlay';
import Loading from '../Loading';

import UserInfo from '../UserInfo';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';

import Avatar from '../Avatar';

import ChangeEmail from '../ChangeEmail';
import ChangePassword from '../ChangePassword';

import ImageUploadingWrongExtension from '../ImageUploadingWrongExtension';

import { getDictionary, locale } from '../../appUtils/locale';

import './styles/UserSidebar.css';


const i18n = getDictionary('user-sidebar');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'user-sidebar';
const infoCssClassName = `${baseCssClassName}__info`;
const footerCssClassName = `${baseCssClassName}__footer`;
const footerControlsCssClassName = `${baseCssClassName}__footer-controls`;
const footerControlsButtonCssClassName = `${baseCssClassName}__footer-controls-button`;
const editorCssClassName = `${baseCssClassName}__editor`;
const formCssClassName = `${baseCssClassName}__form`;
const formMainCssClassName = `${baseCssClassName}__form-main`;
const formSecondaryCssClassName = `${baseCssClassName}__form-secondary`;
const formLinkCssClassName = `${baseCssClassName}__form-link`;
const overlayCssClassName = `${baseCssClassName}__overlay`;
const statusMessageCssClassName = `${baseCssClassName}__status-message`;
const statusMessageTextCssClassName = `${baseCssClassName}__status-message-text`;
const uploadClinicLogoCssClassName = `${baseCssClassName}__upload-clinic-logo`;
const emailLabelCssClassName = `${baseCssClassName}__email-label`;
const emailFieldCssClassName = `${baseCssClassName}__email-field`;

const REQUIRED_FIELDS = [ 'first_name', 'last_name', 'country', 'specialization', 'clinic' ];

const AVATAR_BACKGROUND_COLOR = '#2f313a';


class UserSidebar extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		isAuthenticated: PropTypes.bool.isRequired,
		countries: PropTypes.arrayOf(PropTypes.object).isRequired,
		availableFields: PropTypes.arrayOf(PropTypes.string),
		editableFields: PropTypes.arrayOf(PropTypes.string),
		currentCollectionId: PropTypes.string,
		onUpdateUserProfile: PropTypes.func.isRequired,
		onUploadClinicLogo: PropTypes.func.isRequired,
	};

	static defaultProps = {
		availableFields: [
			'first_name',
			'last_name',
			'country',
			'language',
			'notation_type',
			'clinic',
			'report_email',
			'change_email',
			'change_password',
		],
		editableFields: [
			'first_name',
			'last_name',
			'country',
			'language',
			'notation_type',
			'report_email',
			'change_email',
			'change_password',
		],
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		this.state = {
			isDataDirty: false,
			wrongFields: [],
			isSavingUserData: false,
			userData: {
				...props.user,
			},
			isChangeEmailModalOpened: false,
			isChangePasswordModalOpened: false,
			showUploadingWrongExtensionModal: false,
		};

		this._trackDebounced = lodashDebounce((name) => {
			trackEvent(`User info ${name} change`);
		}, 1000);
	}

	UNSAFE_componentWillReceiveProps (newProps) {
		if ( newProps.user.clinicLogo !== this.props.clinicLogo ) {
			this.setState({
				userData: {
					...this.state.userData,
					clinicLogo: newProps.user.clinicLogo,
				},
			});
		}
	}

	componentWillUnmount () {
		this.state = null;
	}

	_handleDataChanged = (event) => {
		this._trackDebounced(event.target.name);
		this.setState({
			isDataDirty: true,
			userData: {
				...this.state.userData,
				[event.target.name]: event.target.value,
			},
			wrongFields: this.state.wrongFields.filter((fieldName) => fieldName !== event.target.name ),
		});
	}

	_handleSaveButtonClick = () => {
		const {
			userData,
		} = this.state;

		const wrongFields = [];

		for ( let p in userData ) {
			if ( userData.hasOwnProperty(p) ) {
				if ( REQUIRED_FIELDS.indexOf(p) !== -1 && ( !userData[p] || userData[p].trim().length === 0 ) ) {
					wrongFields.push(p);
				}
			}
		}

		this.setState({
			wrongFields,
		});

		if ( wrongFields.length === 0 ) {
			trackEvent('User info save');
			this._saveUserData();
		}
	}

	_handleSaveTryAgainButtonClick = () => {
		this._saveUserData();
	}

	_handleChangeEmailLinkClick = (event) => {
		event.preventDefault();

		trackEvent('Change email click');

		this.setState({
			isChangeEmailModalOpened: true,
		});
	}

	_handleChangePasswordLinkClick = (event) => {
		event.preventDefault();

		trackEvent('Change password click');

		this.setState({
			isChangePasswordModalOpened: true,
		});
	}

	_handleUploadClinicLogoClick = () => {
		trackEvent('Clinic logo click');
	};

	_handleUploadClinicLogoInputChange = async (event) => {
		const inputFile = event.target;
		const image = inputFile.files && inputFile.files[0];

		if ( !image ) {
			return;
		}

		if ( !imageUtils.checkImageExtension(image, mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS) ) {
			this.setState({
				showUploadingWrongExtensionModal: true,
			});
			return;
		}

		this.setState({
			isSavingUserData: true,
		});

		try {
			await this.props.onUploadClinicLogo(image);

			trackEvent('Clinic logo change');
		}
		catch (error) {
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: i18n('errors.language'),
			});
		}

		inputFile.value = null;

		this.setState({
			isSavingUserData: false,
		});
	}

	_handleLogOutButtonClick = () => {
		imageUtils.checkHasUnsavedChanged(() => {
			if ( this.props.isAuthenticated === true && this.props.user.has_accepted_terms_of_use === false ) {
				this.props.onGoToLandingPage();
				return;
			}

			trackEvent('Logout');

			imageUtils.setFilteredClassesToStorage({});
			getStorageService().cleanAll();

			if ( getRuntimeConfig().mixpanel.enabled ) {
				resetMixPanel();
			}

			this.props.onLogOut()
				.finally(() => {
					commonUtils.goToLoginPage();
				});
		}, true);
	}

	_saveUserData () {
		const {
			user,
		} = this.props;

		this.setState({
			isSavingUserData: true,
			hasSavingUserDataError: false,
		});

		const shouldReloadPage = ( user.language !== this.state.userData.language );

		this.props.onUpdateUserProfile({
				data: this.state.userData,
			})
			.then(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSavingUserData: false,
					isDataDirty: false,
				});

				if ( shouldReloadPage ) {
					window.location.reload(true);
				}
			})
			.catch(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSavingUserData: false,
					hasSavingUserDataError: true,
				});
			});
	}

	_renderOverlay (content) {
		return (
			<div className={overlayCssClassName}>
				<Overlay>
					<Alignment
						horizontal={Alignment.horizontal.CENTER}
						vertical={Alignment.vertical.CENTER}
					>
						{content}
					</Alignment>
				</Overlay>
			</div>
		);
	}

	_renderStatus () {
		if ( this.state.isSavingUserData ) {
			return this._renderOverlay(
				<div className={statusMessageCssClassName}>
					<Loading />
					<div
						className={statusMessageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.wait'),
						}}
					/>
				</div>
			);
		}
		else if (this.state.hasSavingUserDataError) {
			return this._renderOverlay(
				<div className={statusMessageCssClassName}>
					<div
						className={statusMessageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.error.with_retry'),
						}}
					/>
					<div>
						<Button
							theme={Button.AVAILABLE_THEMES.PRIMARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							onClick={this._handleSaveTryAgainButtonClick}
						>{i18nShared('buttons.try_again')}</Button>
					</div>
				</div>
			);
		}

		return null;
	}

	_handleCloseChangeEmail = () => {
		this.setState({
			  isChangeEmailModalOpened: false,
		});
	}

	_handleCloseChangePassword = () => {
		this.setState({
			isChangePasswordModalOpened: false,
	  	})
	}

	_handleCloseUploadingWrongExtension =() => {
		this.setState({
		  showUploadingWrongExtensionModal: false,
		});
	}

	_renderModals () {
		if ( this.state.isChangeEmailModalOpened ) {
			return (<ChangeEmail onClose={this._handleCloseChangeEmail}/>);
		}
		else if ( this.state.isChangePasswordModalOpened ) {
			return (<ChangePassword onClose={this._handleCloseChangePassword}/>);
		}
		else if ( this.state.showUploadingWrongExtensionModal ) {
			return (<ImageUploadingWrongExtension onClose={this._handleCloseUploadingWrongExtension}/>);
		}
	}

	_renderField (id, callback) {
		if ( this.props.availableFields.includes(id) === false ) {
			return null;
		}

		return callback(this.props.editableFields.includes(id) === false);
	}

	render () {
		const {
			user,
			isAuthenticated,
		} = this.props;

		if ( !isAuthenticated ) {
			return null;
		}

		const {
			isDataDirty,
			wrongFields,
			userData,
		} = this.state;

		return (
			<div className={baseCssClassName}>
				<div className={infoCssClassName}>
					<UserInfo avatarBackgroundColor={AVATAR_BACKGROUND_COLOR} />
				</div>
				<div className={editorCssClassName}>
					<div className={`${formCssClassName} ${formCssClassName}__m-personal`}>
						<div className={formSecondaryCssClassName}>
							<Avatar
								width={70}
								height={70}
								url={user.avatar}
								sign={user.username[0]}
								backgroundColor={AVATAR_BACKGROUND_COLOR}
							/>
						</div>
						<div className={formMainCssClassName}>
							{this._renderField('first_name', (disabled) => (
								<TextField
									required
									error={wrongFields.indexOf('first_name') !== -1}
									id={'first_name'}
									InputLabelProps={{
										shrink: true,
									}}
									name={'first_name'}
									label={i18n('labels.first_name')}
									value={userData.first_name}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								/>
							))}
							{this._renderField('last_name', (disabled) => (
								<TextField
									required
									error={wrongFields.indexOf('last_name') !== -1}
									id={'last_name'}
									InputLabelProps={{
										shrink: true,
									}}
									name={'last_name'}
									value={userData.last_name}
									label={i18n('labels.last_name')}
									margin={'dense'}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								/>
							))}
							{this._renderField('country', (disabled) => (
								<TextField
									required
									error={wrongFields.indexOf('country') !== -1}
									id={'country'}
									name={'country'}
									select
									label={i18n('labels.country')}
									value={userData.country}
									SelectProps={{
										native: true,
									}}
									margin={'dense'}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								>
									{this.props.countries.map(country => (
										<option style={{color: 'black'}} key={country.code} value={country.code}>
											{country.name}
										</option>
									))}
								</TextField>
							))}
							{this._renderField('language', (disabled) => (
								<TextField
									id={'language'}
									name={'language'}
									select
									label={i18n('labels.language')}
									value={userData.language || locale()}
									SelectProps={{
										native: true,
									}}
									margin={'dense'}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								>
									{mainConfig.AVAILABLE_LOCALES.map(data => (
										<option style={{color: 'black'}} key={data.key} value={data.key}>
											{data.name}
										</option>
									))}
								</TextField>
							))}
							{this._renderField('notation_type', (disabled) => (
								<TextField
									id={'notation_type'}
									name={'notation_type'}
									select
									label={i18n('labels.notation')}
									value={userData.notation_type || 'iso'}
									SelectProps={{
										native: true,
									}}
									margin={'dense'}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								>
									{teethConfig.DENTAL_NOTATION_SYSTEM.map(data => (
										<option style={{color: 'black'}} key={data.key} value={data.key}>
											{data.name}
										</option>
									))}
								</TextField>
							))}
						</div>
					</div>
					{this._renderField('change_email', () => (
						<div className={`${formCssClassName} ${formCssClassName}__m-links`}>
							<a
								href={'javascript:void(0);'}
								className={formLinkCssClassName}
								onClick={this._handleChangeEmailLinkClick}
							>{i18n('buttons.change_email')}</a>
						</div>
					))}
					{this._renderField('change_password', () => (
						<div className={`${formCssClassName} ${formCssClassName}__m-links`}>
							<a
								href={'javascript:void(0);'}
								className={formLinkCssClassName}
								onClick={this._handleChangePasswordLinkClick}
							>{i18n('buttons.change_password')}</a>
						</div>
					))}
					<div className={`${formCssClassName} ${formCssClassName}__m-job`}>
						<div className={formSecondaryCssClassName}>
							<label
								className={uploadClinicLogoCssClassName}
							>
								{ user.full_logo_url && (
									<Avatar
										width={70}
										height={70}
										url={user.full_logo_url}
										sign={user.clinic ? user.clinic[0] : ''}
										backgroundColor={AVATAR_BACKGROUND_COLOR}
									/>
								) }
								{ !user.full_logo_url && (
									<span
										dangerouslySetInnerHTML={{
											__html: i18n('buttons.upload_logo'),
										}}
									/>
								) }

								<input type={'file'} onClick={this._handleUploadClinicLogoClick} onChange={this._handleUploadClinicLogoInputChange} />
							</label>
						</div>
						<div className={formMainCssClassName}>
							{this._renderField('clinic', (disabled) => (
								<TextField
									id={'clinic'}
									name={'clinic'}
									value={userData.clinic}
									label={`${i18n('labels.clinic')}*`}
									error={wrongFields.indexOf('clinic') !== -1}
									margin={'dense'}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								/>
							))}
							{this._renderField('report_email', (disabled) => (
								<TextField
									id={'report_email'}
									name={'report_email'}
									value={userData.report_email}
									label={i18n('labels.report_specialist_email')}
									margin={'dense'}
									InputLabelProps={{
										shrink: true,
										className: emailLabelCssClassName,
									}}
									InputProps={{
										className: emailFieldCssClassName,
									}}
									fullWidth
									disabled={disabled}
									onChange={this._handleDataChanged}
								/>
							))}
						</div>
					</div>
				</div>
				<div className={footerCssClassName}>
					<div className={footerControlsCssClassName}>
						<div className={footerControlsButtonCssClassName}>
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								disabled={!isDataDirty}
								block
								title={i18nShared('buttons.save')}
								onClick={this._handleSaveButtonClick}
							>{i18nShared('buttons.save')}</Button>
						</div>
						<div className={footerControlsButtonCssClassName}>
							<Button
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								block
								title={i18nShared('buttons.logout')}
								onClick={this._handleLogOutButtonClick}
							>{i18nShared('buttons.logout')}</Button>
						</div>
					</div>
				</div>
				{this._renderStatus()}
				{this._renderModals()}
			</div>
		);
	};
}

export default connect((state) => ({
	isAuthenticated: userSelectors.selectIsAuthenticated(state),
	user: userSelectors.selectUserData(state),
	countries: countriesSelectors.selectCountries(state),
	currentCollectionId: editorSelectors.selectEditor(state).currentCollectionHashName,
}), (dispatch) => ({
	onUpdateUserProfile: (options) => dispatch(userActions.updateProfile(options)),
	onUploadClinicLogo: (image) => dispatch(userActions.uploadClinicLogo({
		image,
	})),
	onLogOut: () => dispatch(userActions.signOut()),
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
}))(UserSidebar);
