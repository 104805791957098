export default {
	'image_id': 'ID снимка',
	'image_date': 'Дата снимка',
	'patient_id': 'ID пациента',
	'birthday': 'Дата рождения',
	'gender': 'Пол',
	'age': 'Возраст',
	'image_type': 'Тип снимка',

	'close_image': 'Закрыть снимок',
};
