import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import imageUtils from '../../appUtils/imageUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';

import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';

import './styles/CanvasControls.css';


const i18n = getDictionary('canvas-controls');


const contextTypes = {
	store: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
};

const baseCssClassName = 'canvas-controls__prev-image-button';


class NextImageButton extends Component {
	_redirect () {
		const {
			prevImage,
		} = this.props;
		
		const collectionId = this.context.router.route.match.params.id;
		this.context.router.history.replace(`/collections/${collectionId}/image/${prevImage.hashname}`, {imageId: prevImage.hashname});
	}
	_handleClick = () => {
		const {
			prevImage,
		} = this.props;

		if ( prevImage ) {
			imageUtils.checkHasUnsavedChanged( () => {
				this._redirect();
			});
		}
	}

	render () {
		return (
			<Button
				size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.LARGE}
				theme={Button.AVAILABLE_THEMES.SECONDARY}
				title={i18n('buttons.prev_image.alt')}
				disabled={this.props.prevImage === null}
				onClick={this._handleClick}
			>
				<span className={baseCssClassName} />{i18n('buttons.prev_image.text')}
			</Button>
		);
	}
}

NextImageButton.contextTypes = contextTypes;

export default connect((state) => ({
	prevImage: imagesCollectionsSelectors.selectPrevImageInCollection(state, { preventCircularSelection: true }),
}))(NextImageButton);
