export { default as Storage } from './storage';

let service = null;


export function setStorageService (storage) {
	service = storage;
}

export function getStorageService () {
	return service;
}
