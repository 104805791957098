import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { trackEvent } from '../../../../integrations/mixpanel';

import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesHistorySelectors from '../../../../selectors/imagesHistorySelectors';

import RoundedButton from '../../../../components/RoundedButton';

import { getDictionary } from '../../../../appUtils/locale';

import '../editor-toolbar/styles/ResolverHistoryStateButton.css';


const i18n = getDictionary('canvas-controls');


class ResolverHistoryStateButtonUndo extends PureComponent {
	static propTypes = {
		isAvailable: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired,
	}

	_handleClick = () => {
		if ( this.props.isAvailable === false ) {
			return;
		}

		this.props.onClick();
	};

	render () {
		return (
			<RoundedButton
				theme={RoundedButton.themes.primary}
				type={'undo'}
				disabled={this.props.isAvailable === false}
				title={i18n('buttons.prev_history.text')}
				size={RoundedButton.sizes.MEDIUM}
				onClick={this._handleClick}
			/>
		);
	}
}

export default connect((state) => {
	const editorState = editorSelectors.selectEditor(state);
	const currentImageId = editorState.currentImageId;
	const currentImageHistoryIndex = editorState.currentImageHistoryIndex;
	const imageHistoryLength = ( imagesHistorySelectors.selectImageHistoryByImageId(state, {
		imageId: currentImageId,
	}) || [] ).length;

	return {
		isAvailable: (
			imageHistoryLength > 0 &&
			currentImageHistoryIndex !== 0
		),
	};
}, (dispatch) => ({
	onClick: () => {
		trackEvent('Undo Button Click');
		return dispatch(editorActions.setPrevImageHistoryState());
	},
}))(ResolverHistoryStateButtonUndo);
