import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import { isSequentialModeEnabledForVisit } from '../../utils';
import labelsUtils from '../../../../appUtils/labelsUtils';

import editorSelectors from '../../../../selectors/editorSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';

import labelGetters from '../../../labels/selectors/labelGetters';

import ResolverFindings from './ResolverFindingsConnector';


export default connect((state, { labels, findingsFilter }) => {
	const editorData = editorSelectors.selectEditor(state);
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const user = userSelectors.selectUserData(state);
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;


	const categories = editorData.isFmxModeEnabled === false ? [
		{
			title: 'Image Findings',
			allowExpand: false,
			rows: [],
		},
		{
			title: 'Image Past Restorations',
			allowExpand: false,
			rows: [],
		},
		{
			title: 'Patient Level Findings',
			rows: [],
		},
		{
			title: 'Other Images Findings',
			defaultIsExpanded: false,
			rows: [],
		},
		{
			title: 'Other Images Past Restorations',
			defaultIsExpanded: false,
			rows: [],
		},
	] : [ {
		title: 'Visit Level',
		allowExpand: false,
		rows: [],
	} ];

	labels.forEach((label) => {
		if ( editorData.isFmxModeEnabled === true ) {
			categories[0].rows.push(label);
			return;
		}

		const classId = labelGetters.getLabelClassId(label);
		const parentLabelId = label.parentLabelId;

		if ( undefined === parentLabelId || null === parentLabelId ) {
			categories[2].rows.push(label);
		}
		else {
			const parentLabel = labelsSelectors.selectLabelById(state, {
				labelId: parentLabelId,
			});

			const shape = label.shapes[currentImage.hashname] || {};
			const parentLabelShape = label.shapes[currentImage.hashname] || {};

			if (
				labelGetters.getLabelClassId(parentLabel) === 'tooth' &&
				typeof shape.type === 'string' &&
				typeof parentLabelShape.type === 'string'
			) {
				if ( labelsUtils.getLabelAvailabilityOptions(classId).findings_group === 'pathological' ) {
					categories[0].rows.push(label);
				}
				else {
					categories[1].rows.push(label);
				}
			}
			else {
				if ( labelsUtils.getLabelAvailabilityOptions(classId).findings_group === 'pathological' ) {
					categories[3].rows.push(label);
				}
				else {
					if ( findingsFilter['treatment'] === true ) {
						categories[4].rows.push(label);
					}
				}
			}
		}
	});

	return {
		categories,
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
	};
}, () => ({}))(ResolverFindings);
