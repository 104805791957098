import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import when from 'when';
import commonUtils from '../../appUtils/common';
import imageApi from '../../api/imageApi';
import { APP_IMAGE_MODE } from '../../constants/imageConstants';
import { setAppImageMode } from '../../services/app';
import urlCache from '../../services/url-cache';
import { runInactiveTimer,  closeFeedback } from '../../services/feedback';

import imagesActions from '../../actions/imagesActions';
import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import currentCollectionSelectors from "../../selectors/currentCollectionSelectors";

import Alignment from '../Alignment';
import Loading from '../Loading';
import Main from './Main';
import imageUtils from "../../appUtils/imageUtils";
import collectionsSelectors from "../../selectors/collectionsSelectors";


class MainHoc extends PureComponent {
	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,

		currentImageId: PropTypes.string,
		currentImage: PropTypes.object,
		currentCollectionId: PropTypes.string,
		shouldAnalyze: PropTypes.bool.isRequired,

		getFilteredClasses: PropTypes.func.isRequired,
		getShowAllFindingsFromStorage: PropTypes.func.isRequired,
		onGoToLandingPage: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		setCurrentImage: PropTypes.func.isRequired,
		loadSharedImage: PropTypes.func.isRequired,
		setFeedbackStatus: PropTypes.func.isRequired,
		setShowAllFindings: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		this._store = context.store;
		this._router = context.router;
		this._storeUnsubscribeCallback = null;

		this._prevHashName = null;
		this._imagePrepared = false;

		this._currentImageHashName = null;

		this._feedbackActiveTimerDestroy = null;
		
		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount () {
		if ( this.props.user.is_treatment_default === true ) {
			window.location.href = `/collections/${this.props.match.params.id}/image/${this.props.match.params.imageId}/treatment_plan`;
		}
		setAppImageMode(APP_IMAGE_MODE.SINGLE);
		this._storeUnsubscribeCallback = this._store.subscribe(this._handleStoreChange);

		this._checkImage(this.props);
	}

	componentWillUnmount () {
		this.state = null;

		if ( this._storeUnsubscribeCallback ) {
			this._storeUnsubscribeCallback();
			this._storeUnsubscribeCallback = null;
		}

		closeFeedback();

		this.props.setCurrentImage(null);
	}

	UNSAFE_componentWillReceiveProps (newProps) {
		this._checkImage(newProps);
	}

	_loadImage (image, imageHash) {
		this.setState({
			isLoaded: false,
		});
		if ( this._feedbackActiveTimerDestroy ) {
			this._feedbackActiveTimerDestroy();
			this._feedbackActiveTimerDestroy = null;
		}
		when.all([
			this._store.dispatch(imagesActions.loadImage({
				collectionHashName: this.props.match.params.id,
				imageHashName: imageHash,
			})),
			imageApi.getFeedback(this.props.match.params.id, imageHash)
		])
			.then(([ image, feedback ]) => {
				if ( !this.state ) {
					return;
				}

				urlCache.cleanAll();

				this.props.setCurrentImage(image.id);

				if ( feedback && 'boolean' === typeof feedback.is_positive ) {
					this.props.setFeedbackStatus(false);
				}
				else {
					this.props.setFeedbackStatus(true);
					this._feedbackActiveTimerDestroy = runInactiveTimer();
				}
				
				const showAllFindingFromStorage = this.props.getShowAllFindingsFromStorage();
				if( null === showAllFindingFromStorage ) {
					const classes = this.props.getFilteredClasses();
					const numOfCheckedClasses = Object.keys(classes).reduce((result, classId) => {
						if ( true === classes[classId] ) {
							++result;
						}
						
						return result;
					}, 0);

					if ( numOfCheckedClasses > 0 ) {
						this.props.setShowAllFindings(false);
					}
				}
				else {
					this.props.setShowAllFindings(showAllFindingFromStorage);
				}

				if ( this.props.shouldAnalyze === true && image.is_sequential_viewed === false ) {
					this.props.onAnalyzeImage();
				}
				
				this.setState({
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.error(error);
			});

		this._currentImageHashName = imageHash;
	}

	_checkImage (props) {
		const { currentImageId, currentImage, match } = props;
		if ( currentImageId && !currentImage ) {
			this._router.history.replace('/');
			return;
		}

		const imageHash = match.params.imageId;

		if ( this._prevHashName !== imageHash ) {
			this._imagePrepared = false;
		}

		if ( imageHash && this._currentImageHashName !== imageHash && !this._imagePrepared ) {
			const image = imagesSelectors.selectImageByHashNameInCollection(this._store.getState(), {
				collectionHashName: this.props.match.params.id,
				imageHashName: imageHash,
			});

			this._prevHashName = imageHash;
			this._imagePrepared = true;

			if ( image ) {
				closeFeedback();
				this._loadImage(image, imageHash)
			}
			else {
				this.setState({
					isLoaded: false,
				});
				this.props.loadSharedImage(imageHash)
					.then(({ collection }) => {
						if ( !this.state ) {
							return;
						}

						if ( collection.col_hash && collection.img_hash ) {
							this._router.history.push(`/collections/${collection.col_hash}/image/${collection.img_hash}`);
							window.location.reload();
							return;
						}
						this.setState({
							isLoaded: true,
						});
					})
			}
		}
		else {
			this._currentImageHashName = null;
		}
	}

	_handleStoreChange = () => {
		if ( !this._storeUnsubscribeCallback ) {
			return;
		}

		this.forceUpdate();
	};

	render () {
		const {
			isAuthenticated,
			currentImageId,
			currentImage,
		} = this.props;

		if ( !this.state.isLoaded ) {
			return (
				<Alignment
					horizontal={Alignment.horizontal.CENTER}
					vertical={Alignment.vertical.CENTER}
				>
					<Loading />
				</Alignment>
			);
		}

		if ( !currentImageId || !currentImage ) {
			return null;
		}

		return (<Main isAuthenticated={isAuthenticated} />);
	}
}

export default withRouter(connect((state) => {
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const isAuthenticated = userSelectors.selectIsAuthenticated(state);
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const shouldAnalyze = userSelectors.selectUsesComputerAidedDeviceUi(state);

	return {
		isAuthenticated: isAuthenticated,
		user: userSelectors.selectUserData(state),
		currentImageId,
		currentCollectionId,
		currentImage,
		shouldAnalyze,
		getFilteredClasses: () => editorSelectors.selectFilteredClassesForCurrentImage(state),
		getShowAllFindingsFromStorage: () => {
			const collection = collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			});
			const data = imageUtils.getShowAllFindingsFromStorage();
			if( data && data.hasOwnProperty(collection.hashname) ) {
				return data[collection.hashname];
			}
			
			return null;
		},
	};
}, (dispatch) => ({
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
	setCurrentImage: (id) => {
		if ( id !== null ) {
			return dispatch(editorActions.setCurrentImage({ id }));
		}

		return dispatch(editorActions.unsetCurrentImage());
	},
	loadSharedImage: (hashName) => dispatch(imagesActions.loadSharedImage({ hashName })),
	setFeedbackStatus: (status) => dispatch(editorActions.updateData({
		data: {
			shouldShowFeedback: status,
		},
	})),
	setShowAllFindings: (value) => dispatch(editorActions.setShowAllFindings(value)),
	onAnalyzeImage: () => dispatch(editorActions.analyzeCurrentImage({
		params: {
			keep_manual_labels: true,
		},
	})),
}))(MainHoc));
