import { v4 } from 'node-uuid';
import lodashGet from 'lodash/get';
import lodashUnion from 'lodash/union';
import lodashUniq from 'lodash/uniq';
import labelsUtils from './labelsUtils';
import teethUtils from './teeth/teethUtils';
import imageUtils from './imageUtils';
import { getAppImageMode } from '../services/app';

import { SHIFT_DIRECTION__LEFT } from '../constants/teethConstants';
import { APP_IMAGE_MODE } from '../constants/imageConstants';


import imagesSelectors from '../selectors/imagesSelectors';
import imagesCollectionsSelectors from '../selectors/imagesCollectionsSelectors';
import labelsSelectors from '../modules/labels/selectors/labelsSelectors';
import imagesLabelsSelectors from '../modules/labels/selectors/imagesLabelsSelectors';
import labelChildrenSelectors from '../modules/labels/selectors/labelChildrenSelectors';
import labelTagsSelectors from '../modules/label-tags/selectors/labelTagsSelectors';
import labelsTagsSelectors from '../modules/label-tags/selectors/labelsTagsSelectors';
import editorSelectors from '../selectors/editorSelectors';
import labelTagGetter from '../modules/label-tags/selectors/labelTagGetter';
import userSelectors from '../selectors/userSelectors';
import currentCollectionSelectors from '../selectors/currentCollectionSelectors';
import labelGetters from '../modules/labels/selectors/labelGetters';


/**
 * @return {string}
 */
function generateImageId () {
	return v4();
}

/**
 * @return {string}
 */
function generateLabelId () {
	return v4();
}

/**
 * @return {string}
 */
function generateLabelTagId () {
	return v4();
}

/**
 * @param {Object} params
 * @param {CollectionImage} params.image
 * @param {boolean} params.isOwnImages
 *
 * @return {CollectionImage}
 */
function fetchImage (params) {
	const {
		image,
		isOwnImages,
	} = params;

	const result = {
		...image,
		id: generateImageId(),
		isOwn: ( isOwnImages === true ),
		...(process.env.REACT_APP_FAKE_IMAGE_URL) && {
			image_url: process.env.REACT_APP_FAKE_IMAGE_URL,
		},
	};

	return result;
}

/**
 * @param {Object} params
 * @param {CollectionImage[]} params.images
 * @param {boolean} params.isOwnImages
 * @param {StoreState} params.storeState
 *
 * @return {Object<string,CollectionImage>}
 */
function fetchImages (params) {
	const {
		images,
		isOwnImages,
		storeState,
	} = params;

	const result = {};

	if ( Array.isArray(images) === true && images.length > 0 ) {
		return images.reduce((result, image) => {
			const newImage = fetchImage({
				image,
				isOwnImages
			});

			const existingImage = imagesSelectors.selectImageByHashName(storeState, { hashname: newImage.hashname });

			if ( typeof existingImage === 'object' && existingImage !== null ) {
				newImage.id = existingImage.id;
				result[existingImage.id] = newImage;
			}
			else {
				result[newImage.id] = newImage;
			}

			return result;
		}, result);
	}

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {CollectionImage[]} params.images
 * @param {Collection.id} params.imageCollectionId
 *
 * @return {Object<string,string[]>}
 */
function fetchImagesCollections (params) {
	const {
		storeState,
		images,
		imageCollectionId,
	} = params;
	let imageCollection = null;

	if ( Array.isArray(images) === true && images.length > 0 ) {
		imageCollection = images
			.slice().sort((a, b) => b.created_at - a.created_at)
			.reduce((result, image) => {
				result[imageCollectionId].push(image.id);
				return result;
			}, {
				...imagesCollectionsSelectors.selectImagesCollections(storeState),
				[imageCollectionId]: [],
			});
	}

	return imageCollection;
}

/**
 * @param {Object} params.storeState
 * @param {CollectionImage} params.image
 * @param {Object} params.annotation
 * @param {Object[]} params.annotation.labels
 * @param {string} [params.annotation.stage]
 *
 * @return {StoreState}
 */
function fetchImageData (params) {
	const {
		storeState,
		image,
		annotation,
		// isAnalysedByUser,
	} = params;

	const result = {
		labels: {},
		imagesLabels: [],
		labelTags: {},
		labelsTags: [],
		labelChildren: labelChildrenSelectors.selectLabelChildren(storeState),
	};

	if ( Array.isArray(annotation.labels) === true && annotation.labels.length > 0 ) {
		const labelsConfigs = labelsUtils.getCollectionLabelConfig((image).image_type);
		const classesTagsMap = Object.keys(labelsConfigs).reduce((result, classId) => {
			result[classId] = (labelsConfigs[classId].attributes || []).reduce((acc, tag) => {
				acc[tag.key] = tag;
				return acc;
			}, {});
			return result;
		}, {});

		annotation.labels.forEach((label) => {
			const classId = label.class_id;
			const labelId = (label.id || generateLabelId());
			result.labels[labelId] = {
				...label,
				labelId,
			};

			delete result.labels[labelId].id;

			// result.isAnalysedByUser = isAnalysedByUser;
			result.imagesLabels.push(labelId);
			result.labelsTags[labelId] = [];

			if ( Array.isArray(label.tags) === true && label.tags.length > 0 ) {
				label.tags.forEach((tagKey) => {
					const tagId = generateLabelTagId();

					result.labelTags[tagId] = {
						tagId,
						key: tagKey,
						localizedName: lodashGet(classesTagsMap, [ classId, tagKey, 'readable_name' ], tagKey),
						hotKey: lodashGet(classesTagsMap, [ classId, tagKey, 'hotkey' ], ''),
					};
					result.labelsTags[labelId].push(tagId);
				});
			}
			delete result.labels[labelId].tags;

			if ( Array.isArray(label.relations) === true && label.relations.length > 0 ) {
				label.relations.forEach((relation) => {
					if ( relation.type === 'child' ) {
						if ( !result.labelChildren[relation.label_id] ) {
							result.labelChildren[relation.label_id] = [];
						}

						result.labelChildren[relation.label_id].push(labelId);
					}
				});
			}
		});
	}

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {CollectionImage} params.image
 * @param {Object} params.annotation
 * @param {Object[]} params.annotation.labels
 * @param {string} [params.annotation.stage]
 * @param {boolean} [params.mergeStrategy="clear"]
 *
 * @return {StoreState}
 */
function toStateStructureAppModeSingle (params) {
	const {
		storeState,
		image,
		annotation,
	} = params;

	const imageId = image.id;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};

	if ( params.mergeStrategy === 'clear ') {
		Object.values(imagesSelectors.selectImages(storeState)).forEach((collectionImage) => {
			result.imagesLabels[collectionImage.id] = [];
		});
	}

	const labelsIds = (annotation.labels || []).map((labelData) => labelData.id);

	// Clear all information about prev annotations
	if ( labelsIds.length > 0 ) {
		labelsIds.forEach((labelId) => {
			const tagsIds = labelsTagsSelectors.selectLabelTagsByLabelId(storeState, {
				labelId,
			});

			if ( tagsIds.length > 0 ) {
				tagsIds.forEach((tagId) => {
					delete result.labelTags[tagId];
				});
			}
			delete result.labelsTags[labelId];
			delete result.labels[labelId];
			delete result.labelChildren[labelId];
		});
	}

	const imageData = fetchImageData({
		storeState: result,
		image,
		annotation,
		// isAnalysedByUser,
	});

	result.labels = {
		...result.labels,
		...imageData.labels,
	};

	result.imagesLabels = {
		...result.imagesLabels,
		[imageId]: params.mergeStrategy === 'clear' ? imageData.imagesLabels : lodashUnion(result.imagesLabels[imageId], imageData.imagesLabels),
	};

	result.labelTags = {
		...result.labelTags,
		...imageData.labelTags,
	};

	result.labelsTags = {
		...result.labelsTags,
		...imageData.labelsTags,
	};

	result.labelChildren = {
		...result.labelChildren,
		...imageData.labelChildren,
	};
	if ( annotation.stage != null ) {
		result.labelsStage = annotation.stage;
	}
	// result.isAnalysedByUser = imageData.isAnalysedByUser;

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {CollectionImage} params.image
 * @param {Object} params.annotation
 * @param {Object[]} params.annotation.labels
 * @param {string} [params.annotation.stage]
 * @param {boolean} [params.mergeStrategy="clear"]
 *
 * @return {StoreState}
 */
function toStateStructureAppModeTreatmentPlan (params) {
	const image = params.image;
	const storeState = params.storeState;
	const annotation = params.annotation;
	const nextState = toStateStructureAppModeSingle(params);

	let images = [];
	nextState.editor = {
		...editorSelectors.selectEditor(storeState),
		originalLabels: annotation.labels,
	};

	if ( typeof image.examination === 'string' ) {
		images = currentCollectionSelectors.selectExaminationImages(storeState, { examination: image.examination });

		if ( params.mergeStrategy === 'clear' ) {
			// Clear images
			images.forEach((_image) => {
				nextState.imagesLabels[_image.id] = [];
			});
		}
	}


	if ( Array.isArray(annotation.labels) === true ) {
		annotation.labels.forEach((label) => {
			if ( !nextState.labels[label.id] ) {
				return;
			}

			if ( images.length > 0 ) {
				images.forEach((_image) => {
					nextState.imagesLabels[_image.id].push(label.id);
				});
			}

			nextState.labels[label.id].shapes = (label.image_shapes || []).reduce((result, shapeData) => {
				result[shapeData.image_hashname] = {
					...shapeData.shape,
					__data: shapeData,
				};

				return result;
			}, {});

			delete nextState.labels[label.id].image_shapes;
			// delete nextState.labels[label.id].image_source;
		});
	}

	if ( params.mergeStrategy === 'merge' ) {
		images.forEach((_image) => {
			nextState.imagesLabels[_image.id] = lodashUniq(nextState.imagesLabels[_image.id]);
		});
	}

	return nextState;
}

/**
 * Treatment plan report labels adapter.
 *
 * @param {Object} params.storeState
 * @param {CollectionImage} params.image
 * @param {Object} params.annotation
 * @param {Object[]} params.annotation.labels
 * @param {string} [params.annotation.stage]
 * @param {boolean} [params.mergeStrategy="clear"]
 * @return {StoreState}
 */
function toStateStructureReportTreatmentPlan (params) {
	const imageId = params.imageId;
	const imageData = params.image;
	const storeState = params.storeState;
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: imageId,
	});

	const images = Object.values(imagesSelectors.selectImages(storeState));

	if ( imageData.labels && imageData.labels.length > 0 ) {
		const teethIdMap = {};
		// 1. Aggregate teeth shapes by image hash names.
		const teethShapesByImages = imageData.labels.reduce((result, label) => {
			if ( label.class_id === 'tooth' && Array.isArray(label.tags) && label.tags.length > 0 ) {
				const toothKey = label.tags[0];

				result[toothKey] = {
					toothKey,
					data: label,
					shapes: images.reduce((result, image) => {
						result[image.hashname] = {
							imageHashName: image.hashname,
							shape: null,
							children: [],
						};
						return result;
					}, {}),
				};

				teethIdMap[label.id] = result[toothKey];

				if ( Array.isArray(label.image_shapes) === true && label.image_shapes.length > 0 ) {
					label.image_shapes.forEach((shapeData) => {
						if ( typeof shapeData.image_hashname === 'string' ) {
							result[toothKey].shapes[shapeData.image_hashname] = {
								imageHashName: shapeData.image_hashname,
								shape: shapeData.shape,
								children: [],
							};
						}
					});
				}
			}
			return result;
		}, {});

		// 2. Append labels to the teeth shapes(accordingly to image hash name).
		imageData.labels.forEach((label) => {
			if ( label.class_id === 'tooth' ) {
				return;
			}

			if ( Array.isArray(label.relations) === true && label.relations.length > 0 ) {
				let tooth;

				label.relations.forEach((relation) => {
					if ( relation.type === 'child' ) {
						if ( teethIdMap.hasOwnProperty(relation.label_id) === true ) {
							tooth = teethIdMap[relation.label_id];

							if ( label.class_id === 'missing_tooth' ) {
								Object.values(imagesSelectors.selectImages(storeState)).forEach((image) => {
									if ( Array.isArray(tooth.shapes[image.hashname].children) === false ) {
										tooth.shapes[image.hashname].children = [];
									}
									tooth.shapes[image.hashname].children.push({
										label,
										shape: {
											type: 'none',
										},
									});
								});
							}
							else if ( Array.isArray(label.image_shapes) === true ) {
								label.image_shapes.forEach((shapeData) => {
									if (
										tooth.shapes.hasOwnProperty(shapeData.image_hashname) === false ||
										shapeData.shape === null ||
										shapeData.shape === undefined ||
										typeof shapeData.shape.type !== 'string'
									) {
										return;
									}

									tooth.shapes[shapeData.image_hashname].children.push({
										label,
										shape: shapeData.shape,
									});
								});
							}
						}
					}
				});
			}
		});

		const labels = [];
		const toothImageHashNameMap = {};

		Object.keys(teethShapesByImages).forEach((toothKey) => {
			const toothData = teethShapesByImages[toothKey];

			if ( Object.keys(teethShapesByImages[toothKey].shapes).length === 0 ) {
				labels.push(toothData.data);
				return;
			}

			// 3. Find a tooth shape with maximum children.
			let shapeWithMaxChildren = null;
			Object.keys(teethShapesByImages[toothKey].shapes).forEach((imageHashName) => {
				const imageWithChildren = teethShapesByImages[toothKey].shapes[imageHashName];
				// if ( imageWithChildren.shape === null || imageWithChildren.shape === undefined ) {
				// 	return;
				// }
				if ( shapeWithMaxChildren === null || shapeWithMaxChildren.children.length < imageWithChildren.children.length ) {
					shapeWithMaxChildren = imageWithChildren;
				}
			});

			if ( shapeWithMaxChildren === null ) {
				return;
			}
			// 4. Create a map to find matches later.
			toothImageHashNameMap[toothKey] = shapeWithMaxChildren.imageHashName;

			// 5. Add a tooth with modified shape.
			labels.push({
				...toothData.data,
				image_shapes: [
					{
						...shapeWithMaxChildren.shape,
						image_hashname: currentImage.hashname,
					},
				],
				shape: shapeWithMaxChildren.shape,
			});

			// 6. Add labels with modified shapes.
			shapeWithMaxChildren.children.forEach((labelData) => {
				labels.push({
					...labelData.label,
					image_shapes: [
						{
							...labelData.shape,
							image_hashname: currentImage.hashname,
						},
					],
					shape: labelData.shape,
				});
			});
		});

		const nextState = toStateStructureAppModeSingle({
			imageId,
			storeState,
			image: currentImage,
			annotation: {
				labels,
			},
		});

		nextState.editor = {
			...editorSelectors.selectEditor(params.storeState),
			treatmentPlanToothImageHashNameMap: toothImageHashNameMap,
		};

		return nextState;
	}

	return {};
}

/**
 * @param {Object} params.storeState
 * @param {CollectionImage} params.image
 * @param {Object} params.annotation
 * @param {Object[]} params.annotation.labels
 * @param {string} [params.annotation.stage]
 * @param {boolean} [params.mergeStrategy="clear"]
 *
 * @return {StoreState}
 */
function toStateStructure (params) {
	if ( !params.mergeStrategy ) {
		params.mergeStrategy = 'clear';
	}

	switch (getAppImageMode()) {
		case APP_IMAGE_MODE.TREATMENT_PLAN: {
			return toStateStructureAppModeTreatmentPlan(params);
		}

		case APP_IMAGE_MODE.SINGLE:
		default:
			return toStateStructureAppModeSingle(params);
	}
}

/**
 * @param {Object} params.storeState
 * @param {string} params.imageId
 *
 * @return {CollectionImage}
 */
function toApiStructureAppModeSingle (params) {
	const {
		storeState,
		imageId,
	} = params;

	const image = {
		...imagesSelectors.selectImageById(storeState, {
			id: imageId,
		}),
	};

	delete image.id;

	const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
	const labelParentMap = {};
	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	image.labels = ( imagesLabelsSelectors.selectImageLabelsByImageId(storeState, {
		imageId,
	}) || [] )
		.map((labelId) => {
			const label = {
				...labelsSelectors.selectLabelById(storeState, {
					labelId,
				}),
				id: labelId,
			};

			delete label.labelId;
			// WORKAROUND
			delete label.toothkey;
			delete label.localizedToothKey;

			label.tags = ( labelsTagsSelectors.selectLabelTagsByLabelId(storeState, {
				labelId,
			}) || [] )
				.map((tagId) => {
					const tag = labelTagsSelectors.selectLabelTagById(storeState, {
						tagId,
					});

					return tag.key;
				});

			if ( labelParentMap[labelId] ) {
				label.relations = [
					{
						type: 'child',
						label_id: labelParentMap[labelId],
					},
				];
			}

			delete label.children;

			return label;
		});

	return image;
}

/**
 * @param {Object} params.storeState
 * @param {string} params.imageId
 *
 * @return {CollectionImage}
 */
function toApiStructureAppModeTreatmentPlan (params) {
	const {
		storeState,
		imageId,
	} = params;

	const image = toApiStructureAppModeSingle({
		storeState,
		imageId,
	});

	const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
	const labelParentMap = {};
	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});
	const originalLabels = (editorSelectors.selectEditor(storeState).originalLabels || []);
	const labels = Object.values(labelsSelectors.selectLabels(storeState)).reduce((result, label) => {
		result[label.labelId] = label;

		return result;
	}, {});
	const originalLabelsIds = originalLabels.reduce((result, labelData) => {
		result[labelData.id] = true;
		return result;
	}, {});

	// Existing labels.
	image.labels = originalLabels.map((labelData) => {
		const labelId = labelData.id;
		const label = labels[labelData.id];

		// Label was removed
		if ( !label ) {
			return false;
		}

		// Label was changed
		const nextLabelData = {
			...labelData,
			...label,
		};

		delete nextLabelData.labelId;

		nextLabelData.tags = ( labelsTagsSelectors.selectLabelTagsByLabelId(storeState, {
			labelId,
		}) || [] )
			.map((tagId) => {
				const tag = labelTagsSelectors.selectLabelTagById(storeState, {
					tagId,
				});

				return tag.key;
			});

		if ( labelParentMap[labelId] ) {
			nextLabelData.relations = [
				{
					type: 'child',
					label_id: labelParentMap[labelId],
				},
			];
		}

		delete label.children;

		if ( nextLabelData.hasOwnProperty('shapes') === true ) {
			nextLabelData.image_shapes = Object.keys(nextLabelData.shapes).reduce((result, imageHashName) => {
				const data = {
					...nextLabelData.shapes[imageHashName].__data,
					shape: {
						...nextLabelData.shapes[imageHashName],
					},
				};
				delete data.shape.__data;
				result.push(data);
				return result;
			}, []);
			delete nextLabelData.shapes;
		}

		return nextLabelData;
	}).filter(Boolean);

	// New labels.
	Object.keys(labels).forEach((labelId) => {
		// Skip if a label exists
		if ( true === originalLabelsIds[labelId] ) {
			return;
		}
		const label = labels[labelId];
		const labelData = {
			...label,
			id: labelId,
		};

		delete labelData.labelId;

		labelData.tags = ( labelsTagsSelectors.selectLabelTagsByLabelId(storeState, {
			labelId,
		}) || [] )
			.map((tagId) => {
				const tag = labelTagsSelectors.selectLabelTagById(storeState, {
					tagId,
				});

				return tag.key;
			});

		if ( labelParentMap[labelId] ) {
			labelData.relations = [
				{
					type: 'child',
					label_id: labelParentMap[labelId],
				},
			];
		}

		delete labelData.children;

		if ( labelData.hasOwnProperty('shapes') === true ) {
			labelData.image_shapes = Object.keys(labelData.shapes).reduce((result, imageHashName) => {
				const data = {
					...labelData.shapes[imageHashName].__data,
					shape: {
						...labelData.shapes[imageHashName],
					},
				};
				delete data.shape.__data;
				result.push(data);
				return result;
			}, []);
			delete labelData.shapes;
		}

		image.labels.push(labelData);
	});

	return image;
}

function toApiStructure (params) {
	switch (getAppImageMode()) {
		case APP_IMAGE_MODE.TREATMENT_PLAN: {
			return toApiStructureAppModeTreatmentPlan(params);
		}

		case APP_IMAGE_MODE.SINGLE:
		default:
			return toApiStructureAppModeSingle(params);
	}
}

function fetchImageHistory ({
	history,
	imageId,
}) {
	return {
		imageId,
		data: history.map((item) => ({
			labels: item.labels,
		})),
	};
}

/**
 * @return {StoreState}
 */
function clearLabels () {
	return {
		labels: {},
		imagesLabels: {},
		labelTags: {},
		labelsTags: {},
		labelChildren: {},
	};
}

function addLabel (options) {
	const {
		storeState,
		imageId,
		classId,
		labelId = generateLabelId(),
		shape = null,
		relations = null,
		meta = null,
		surfaces = null,
		source = 'manual',
		params = null,
		date = null,
	} = options;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
	};

	result.labels[labelId] = {
		labelId,
		class_id: classId,
		relations,

		shape,

		tags: null,
		meta,
		surfaces,
		source,
		params,
		date,
	};

	if ( getAppImageMode() === APP_IMAGE_MODE.TREATMENT_PLAN ) {
		const currentImage = imagesSelectors.selectImageById(storeState, { id: imageId });
		result.labels[labelId].shapes = {
			[currentImage.hashname]: shape,
		};
	}

	result.imagesLabels[imageId] = (result.imagesLabels[imageId] || []).concat(labelId);

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {string} params.labelId
 *
 * @return {StoreState}
 */
function removeLabel (params) {
	const {
		storeState,
		labelId,
	} = params;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};

	// Find a parent label
	const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
	const labelParentMap = {};
	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	const parentLabelId = labelParentMap[labelId];

	if ( parentLabelId ) {
		result.labelChildren[parentLabelId] = result.labelChildren[parentLabelId].filter((childLabelId) => (childLabelId !== labelId));
	}

	// Find children labels
	if ( result.labelChildren[labelId] ) {
		result.labelChildren[labelId].forEach((childId) => {
			Object.assign(result, removeLabel({
				storeState: result,
				labelId: childId,
			}));
		});
		delete result.labelChildren[labelId];
	}

	// Remove label tags
	labelsTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId })
		.forEach((tagId) => {
			delete result.labelTags[tagId];

			result.labelsTags[labelId] = result.labelsTags[labelId].filter((_tagId) => _tagId !== tagId);
		});

	delete result.labels[labelId];

	// Remove the label relations with the images
	Object.keys(result.imagesLabels).forEach((imageId => {
		result.imagesLabels[imageId] = result.imagesLabels[imageId].filter((_labelId) => _labelId !== labelId);
	}));

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {LabelId} params.labelId
 * @param {LabelClassId} params.newClassId
 *
 * @return {StoreState}
 */
function changeLabel (params) {
	const {
		storeState,
		labelId,
		newClassId,
	} = params;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
	};

	// Remove label tags
	labelsTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId })
		.forEach((tagId) => {
			delete result.labelTags[tagId];

			result.labelsTags[labelId] = result.labelsTags[labelId].filter((_tagId) => _tagId !== tagId);
		});

	result.labels[labelId] = {
		...result.labels[labelId],
		class_id: newClassId,
	};

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {LabelId} params.labelId
 * @param {string} params.parentLabelId
 * @param {string} params.newParentLabelId
 *
 * @return {StoreState}
 */
function moveLabel (params) {
	const {
		storeState,
		labelId,
		parentLabelId,
		newParentLabelId,
	} = params;

	const result = {
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};

	if ( parentLabelId ) {
		result.labelChildren[parentLabelId] = result.labelChildren[parentLabelId].filter((_labelId) => _labelId !== labelId);
	}
	if ( !result.labelChildren[newParentLabelId] ) {
		result.labelChildren[newParentLabelId] = [];
	}
	result.labelChildren[newParentLabelId].push(labelId);

	return result;
}

/**
 * @param {Object} params
 * @param {Object} params.storeState
 * @param {string} params.imageHashName
 * @param {string} [params.toothKey]
 * @param {string} [params.labelId]
 */
function removeLabelShape (params) {
	const storeState = params.storeState;
	let nextState = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};
	const labelParentMap = {};
	Object.keys(nextState.labelChildren)
		.forEach((labelId) => {
			nextState.labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	if ( typeof params.labelId === 'string' ) {
		const labelId = params.labelId;
		const label = labelsSelectors.selectLabelById(nextState, { labelId });
		const nextLabel = {
			...label,
			shapes: {
				...label.shapes,
			},
		};
		delete nextLabel.shapes[params.imageHashName];

		if ( Object.keys(nextLabel.shapes).length > 0 ) {
			nextState.labels[labelId] = nextLabel;
		}
		else {
			nextState = removeLabel({
				storeState: nextState,
				labelId,
			});
		}
	} else {
		const image = imagesSelectors.selectImageByHashName(storeState, { hashname: params.imageHashName });
		let needToAddMissingTooth = false;
		let toothId = null;

		// 1. Find the tooth and delete a shape.
		Object.values(nextState.labels).forEach((label) => {
			const labelId = labelGetters.getLabelId(label);
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(nextState, { labelId });
			if ( tags && tags.length > 0 && labelTagGetter.getTagKey(tags[0]) === params.toothKey ) {
				toothId = labelId;
				const nextLabel = {
					...label,
					shapes: {
						...label.shapes,
					},
				};
				delete nextLabel.shapes[params.imageHashName];
				nextState.labels[labelId] = nextLabel;

				if ( Object.keys(nextLabel.shapes).length === 0 ) {
					needToAddMissingTooth = true;
				}
			}
		});

		// 2. Remove shapes from children
		if ( needToAddMissingTooth === false ) {
			Object.values(nextState.labels).forEach((label) => {
				const labelId = labelGetters.getLabelId(label);
				if ( labelParentMap[labelId] === toothId ) {
					const nextLabel = {
						...label,
						shapes: {
							...label.shapes,
						},
					};
					delete nextLabel.shapes[params.imageHashName];

					if ( Object.keys(nextLabel.shapes).length > 0 ) {
						nextState.labels[labelId] = nextLabel;
					}
					else {
						// 3. Remove this label is there no shapes left.
						nextState = removeLabel({
							storeState: nextState,
							labelId,
						});
					}
				}
			});
		}
		else {
			// 2. Delete all findings if there are no shapes left.
			Object.values(nextState.labels).forEach((label) => {
				const labelId = labelGetters.getLabelId(label);
				if ( labelParentMap[labelId] === toothId ) {
					nextState = removeLabel({
						storeState: nextState,
						labelId,
					});
				}
			});

			const missingTooth = {
				labelId: generateLabelId(),
				class_id: 'missing_tooth',
				source: 'manual',
				meta: {
					confirmed: false,
					confidence_percent: 1,
				},
				shape: { type: 'none' },
				shapes: Object.values(imagesSelectors.selectImages(storeState)).reduce((result, image) => {
					result[image.hashname] = { type: 'none' };
					return result;
				}, {}),
				date: null,
				params: [],
				surfaces: [],
			};

			nextState.labels[missingTooth.labelId] = missingTooth;
			Object.keys(nextState.imagesLabels).forEach((imageId) => {
				nextState.imagesLabels[imageId].push(missingTooth.labelId);
			});
			if ( Array.isArray(nextState.labelChildren[toothId]) === false ) {
				nextState.labelChildren[toothId] = [];
			}
			nextState.labelChildren[toothId].push(missingTooth.labelId);
		}
	}

	return nextState;
}

/**
 * @param {Object} params.storeState
 * @param {LabelId} params.labelId
 * @param {LabelTag} params.tag
 *
 * @return {StoreState}
 */
function addLabelTag (params) {
	const {
		storeState,
		labelId,
		tag,
	} = params;

	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
	};

	if ( tag ) {
		const tagId = generateLabelTagId();

		result.labelTags[tagId] = {
			...tag,
			tagId,
		};

		result.labelsTags[labelId] = (result.labelsTags[labelId] || []).concat(tagId);
	}

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {LabelId} params.labelId
 * @param {LabelTagId} params.tagId
 *
 * @return {StoreState}
 */
function removeLabelTag (params) {
	const {
		storeState,
		labelId,
		tagId,
	} = params;

	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
	};

	delete result.labelTags[tagId];

	result.labelsTags[labelId] = result.labelsTags[labelId].filter((_tagId) => _tagId !== tagId);

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {LabelTagId} params.tagId
 * @param {LabelTag} params.tag
 *
 * @return {StoreState}
 */
function changeLabelTag (params) {
	const {
		storeState,
		tagId,
		tag,
	} = params;

	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
	};

	result.labelTags[tagId] = {
		...result.labelTags[tagId],
		...tag,
	};

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {string} params.imageId
 *
 * @return {StoreState}
 */
function confirmAllLabels (params) {
	const {
		storeState,
		imageId,
	} = params;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
	};

	imagesLabelsSelectors.selectImageLabelsByImageId(storeState, {
		imageId,
	})
		.forEach((labelId) => {
			const label = labelsSelectors.selectLabelById(storeState, { labelId });

			if ( label.meta && label.meta.hasOwnProperty('confirmed') && !label.meta.confirmed ) {
				result.labels[labelId] = {
					...label,
					meta: {
						...label.meta,
						confirmed: true,
					},
				};
			}
		});

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {string} params.imageId
 *
 * @return {StoreState}
 */
function removeAllLabels (params) {
	const {
		storeState,
		imageId,
		withTeeth = true,
	} = params;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};

	imagesLabelsSelectors.selectImageLabelsByImageId(storeState, { imageId })
		.forEach((labelId) => {
			if ( withTeeth === false ) {
				if ( labelsUtils.labelIsTooth(labelsSelectors.selectLabelById(storeState, { labelId })) === true ) {
					return;
				}
			}
			Object.assign(result, removeLabel({
				storeState: result,
				imageId,
				labelId,
			}));
		});

	return result;
}

/**
 * @param {Object} params.storeState
 * @param {Collection} params.currentCollection
 *
 * @return {Object}
 */
function getFilteredClasses (params) {
	const {
		storeState,
		currentCollection,
	} = params;

	const imageTypes = {};
	const classes = currentCollection.labels.reduce((result, label) => {
		imageTypes[label.image_type] = true;
		if ( !result[label.image_type] ) {
			result[label.image_type] = {};
		}
		result[label.image_type][label.class_id] = true;

		return result;
	}, {});

	const result = {};
	const user = userSelectors.selectUserData(storeState);

	if ( true === Array.isArray(user.default_filters) ) {
		Object.keys(imageTypes).forEach((imageType) => {
			if ( !result[imageType] ) {
				result[imageType] = {};
			}

			user.default_filters.forEach((classId) => {
				result[imageType][classId] = true;
			});
		});
	}

	const filteredClasses = imageUtils.getFilteredClassesFromStorage();
	if ( filteredClasses && filteredClasses[currentCollection.hashname] ) {
		for (const imageType in filteredClasses[currentCollection.hashname]) {
			if ( filteredClasses[currentCollection.hashname].hasOwnProperty(imageType) ) {
				if ( !result[imageType] ) {
					result[imageType] = {};
				}

				const imageClasses = true === Array.isArray(filteredClasses[currentCollection.hashname][imageType])
					? filteredClasses[currentCollection.hashname][imageType].reduce((result, classId) => {
						result[classId] = true;

						return result;
					}, {})
					: filteredClasses[currentCollection.hashname][imageType];

				Object.keys(imageClasses).forEach((classId) => {
					if ( classes[imageType] && classes[imageType][classId] ) {
						result[imageType][classId] = imageClasses[classId];
					}
				});
			}
		}
	}

	return result;
}

/**
 * @param {StoreState} params.storeState
 * @param {string[]} params.toothKeysToShift
 * @param {string} params.direction
 *
 * @return {StoreState}
 */
function shiftTeeth (params) {
	const {
		storeState,
		toothKeysToShift,
		direction,
	} = params;

	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
	};

	toothKeysToShift.forEach((toothKey) => {
		imagesLabelsSelectors.selectImageLabelsByImageId(storeState, {
			imageId: editorSelectors.selectCurrentImageId(storeState),
		})
			.forEach((labelId) => {
				const label = labelsSelectors.selectLabelById(storeState, {
					labelId,
				});

				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId });
					if ( tags && tags.length > 0 && labelTagGetter.getTagKey(tags[0]) === toothKey ) {
						const newToothKey = (direction === SHIFT_DIRECTION__LEFT)
							? teethUtils.getPreviousToothKey(toothKey)
							: teethUtils.getNextToothKey(toothKey);

						result.labelTags[labelTagGetter.getTagId(tags[0])] = {
							...tags[0],
							key: newToothKey,
						};
					}
				}
			});
	});

	return result;
}

/**
 * @param {StoreState} params.storeState
 * @param {Label[]} params.labels
 *
 * @return {StoreState}
 */
function syncLabels (params) {
	const {
		storeState,
		labels,
	} = params;

	const result = {
		editor: {
			...editorSelectors.selectEditor(storeState),
			originalLabels: labels,
		},
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		images: {
			...imagesSelectors.selectImages(storeState),
		},
	};

	labels.forEach((nextLabel) => {
		const prevLabel = labelsSelectors.selectLabelById(result, { labelId: nextLabel.id });

		// Removed label.
		if ( typeof prevLabel === 'undefined' ) {
			return;
		}

		result.labels[nextLabel.id] = {
			...prevLabel,
			conflicts: nextLabel.conflicts,
			warnings: nextLabel.warnings
		};
	});

	return result;
}

export default {
	generateLabelId,

	fetchImage,
	fetchImages,
	fetchImagesCollections,

	toStateStructure,
	toStateStructureReportTreatmentPlan,
	toApiStructure,
	fetchImageHistory,

	clearLabels,
	addLabel,
	removeLabel,
	changeLabel,
	moveLabel,
	removeLabelShape,

	addLabelTag,
	removeLabelTag,
	changeLabelTag,

	confirmAllLabels,
	removeAllLabels,

	getFilteredClasses,

	shiftTeeth,

	syncLabels,
};
