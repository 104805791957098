import React from "react";

export default {
	'grid.header.image_id': 'Image ID',
	'grid.header.image_date': 'Image date',
	'grid.header.patient_id': 'Patient\'s ID',
	'grid.header.birthday': 'Birth date (Age)',
	'grid.header.gender': 'Gender',
	'grid.header.filename': 'Filename',
	'grid.header.last_opening': 'Last opened',
	'grid.header.last_editing': 'Last edited',
	'grid.header.image_type': 'Image type',
	'incorrect_patient_id': 'Only digits allowed',
	'no_images': 'Images not found',
	'dialog.title': 'Registry',
	'filters.confirmed.all': 'Show all',
	'filters.confirmed.approved': 'Approved',
	'filters.confirmed.unapproved': 'Unapproved',
	'filters.empty.all': 'Show all',
	'filters.empty.empty': 'Empty',
	'filters.empty.nonempty': 'Nonempty',
	'filters.classes': 'Classes ({n})',
	'filters.same_as_predicted': 'Same as predicted',
	'filters.reset': 'Show all',
};
