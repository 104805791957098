import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import classnames from 'classnames';

import commonUtils from '../../appUtils/common';

import Avatar from '../Avatar';

import Button from '../Button';

import userActions from '../../actions/userActions';
import editorActions from '../../actions/editorActions';

import userSelectors from '../../selectors/userSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import { getDictionary } from '../../appUtils/locale';

import './styles/UserInfo.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'user-info';
const authButtonsCssClassName = `${baseCssClassName}__auth-buttons`;
const authButtonCssClassName = `${baseCssClassName}__auth-button`;
const userNameCssClassName = `${baseCssClassName}__username`;
const userMenuButtonCssClassName = `${baseCssClassName}__user-menu-button`;

class UserInfo extends Component {
	static propTypes = {
		isUserMenuOpened: PropTypes.bool,
		user: PropTypes.object.isRequired, // @todo add shape
		avatarBackgroundColor: PropTypes.string,
		isAuthenticated: PropTypes.bool.isRequired,

		onOpenUserMenu: PropTypes.func.isRequired,
		onCloseUserMenu: PropTypes.func.isRequired,
		onLogOut: PropTypes.func.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	_handleSignIn = () => {
		commonUtils.goToLoginPage();
	}

	_handleSignUp = () => {
		commonUtils.goToLoginPage();
	}

	_handleLogOut = () => {
		this.props.onLogOut()
			.finally(() => {
				commonUtils.goToLoginPage();
			});
	}

	_handleUserMenuButtonClick = () => {
		const { isUserMenuOpened, onOpenUserMenu, onCloseUserMenu } = this.props;

		isUserMenuOpened ? onCloseUserMenu() : onOpenUserMenu();
	}

	_renderAuthenticated () {
		const {
			user,
			isUserMenuOpened,
			avatarBackgroundColor,
		} = this.props;

		return (
			<div className={baseCssClassName}>
				<div
					className={userNameCssClassName}
					title={user.username}
				>
					{user.username}
				</div>
				{ user.username && (
					<Avatar
						width={44}
						height={44}
						url={user.avatar}
						sign={user.username[0]}
						backgroundColor={avatarBackgroundColor}
					/>
				) }
				<div
					className={classnames([
						userMenuButtonCssClassName,
						isUserMenuOpened && `${userMenuButtonCssClassName}__m-opened`,
					])}
					onClick={this._handleUserMenuButtonClick}
				></div>
			</div>
		);
	}

	_renderNotAuthenticated () {
		return (
			<div className={authButtonsCssClassName}>
				<div className={authButtonCssClassName}>
					<Button
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this._handleSignIn}
						uppercase
					>{i18nShared('buttons.sign_in')}</Button>
				</div>
				<div className={authButtonCssClassName}>
					<Button
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this._handleSignUp}
						uppercase
					>{i18nShared('buttons.sign_up')}</Button>
				</div>
			</div>
		);
	}

	render () {
		const {
			isAuthenticated,
			user
		} = this.props;

		return (
			<div className={baseCssClassName}>
				{ isAuthenticated && user && this._renderAuthenticated()}
				{ !isAuthenticated && this._renderNotAuthenticated()}
			</div>
		);
	}
}


export default connect((state) => ({
	isAuthenticated: userSelectors.selectIsAuthenticated(state),
	isUserMenuOpened: editorSelectors.selectEditor(state).isUserMenuOpened,
	user: userSelectors.selectUserData(state),
}), (dispatch) => ({
	onOpenUserMenu: () => dispatch(editorActions.openUserMenu()),
	onCloseUserMenu: () => dispatch(editorActions.closeUserMenu()),
	onLogOut: () => dispatch(userActions.signOut()),
}))(UserInfo);
