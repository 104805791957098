import moment from 'moment';


const DEFAULT_LOCALE = 'en';


function formatDay(day, locale = DEFAULT_LOCALE) {
	return moment(day).locale(locale).format('ddd ll');
}

function formatMonthTitle(date, locale = DEFAULT_LOCALE) {
	return moment(date).locale(locale).format('MMMM YYYY');
}

function formatWeekdayShort(day, locale = DEFAULT_LOCALE) {
	return moment().locale(locale)._locale.weekdaysMin({
		day: () => day,
	});
}

function formatWeekdayLong(day, locale = DEFAULT_LOCALE) {
	return moment().locale(locale)._locale.weekdays({
		day: () => day,
	});
}

function getFirstDayOfWeek(locale = DEFAULT_LOCALE) {
	const localeData = moment.localeData(locale);
	return localeData.firstDayOfWeek();
}

function getMonths(locale = DEFAULT_LOCALE) {
	const months = [];
	let i = 0;
	while (i < 12) {
		months.push(moment().locale(locale).month(i).format('MMMM'));
		i += 1;
	}
	return months;
}

export default {
	formatDay,
	formatMonthTitle,
	formatWeekdayShort,
	formatWeekdayLong,
	getFirstDayOfWeek,
	getMonths,
};
