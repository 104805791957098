import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import './styles/RcSlider.css';

const wrapperStyle = {
	width: 100,
	height: 17
};

const handleStyle = {
	borderColor: '#65666d',
	backgroundColor: '#4c4b55',
};
const trackStyle = { height: 2 };
const railStyle = {
	backgroundColor: '#949599',
	height: 2,
};

class RcSlider extends PureComponent {
	static propTypes = {
		value: PropTypes.number,
		initialValue: PropTypes.number,
		min: PropTypes.number,
		max: PropTypes.number,
		wrapperStyle: PropTypes.object,
		handleStyle: PropTypes.object,
		railStyle: PropTypes.object,
		trackStyle: PropTypes.object,
		disabled: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		value: 0,
		initialValue: 0,
		wrapperStyle,
		handleStyle,
		railStyle,
		trackStyle,
		disabled: false,
	};

	/**
	 * @type {ReactComponent|null}
	 * @private
	 */
	_instance = null;

	/**
	 * @param {ReactComponent} ref
	 * @private
	 */
	_handleRef = (ref) => {
		this._instance = ref;
	};

	_handleAfterChange = () => {
		this._instance.blur();
	};

	render() {
		return (
			<div style={this.props.wrapperStyle}>
				<Slider
					min={this.props.min}
					max={this.props.max}
					ref={this._handleRef}
					value={this.props.value}
					trackStyle={this.props.trackStyle}
					handleStyle={this.props.handleStyle}
					railStyle={this.props.railStyle}
					disabled={this.props.disabled}
					onChange={this.props.onChange}
					onAfterChange={this._handleAfterChange}
				/>
			</div>
		);
	}
}

export default RcSlider;
