import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DayPicker from 'react-day-picker';

import LocaleUtils from './DatepickerMomentAdapter';

import momentUtils from '../../appUtils/momentUtils';

import DatepickerCaption from './DatepickerCaption';
import { locale } from '../../appUtils/locale';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import './styles/Datepicker.css';
import './styles/DatepickerInput.css';
import { formatDate, parseDate,} from 'react-day-picker/moment';

import  'moment/locale/ru';
import  'moment/locale/zh-cn';
const baseCssClassName = 'datepicker';

const navbarCssClassName = `${baseCssClassName}-navbar`;
const navbarButtonCssClassName = `${navbarCssClassName}__nav-button`;
const weekdaysCssClassName = `${baseCssClassName}-weekdays`;


const classNames = {
	container: baseCssClassName ,
	wrapper: `${baseCssClassName}__inner`,
	interactionDisabled: `${baseCssClassName}__m-interaction-disabled`,
	month: `${baseCssClassName}-month`,

	navBar: navbarCssClassName,
	navButtonPrev: `${navbarButtonCssClassName} ${navbarButtonCssClassName}__m-prev`,
	navButtonNext: `${navbarButtonCssClassName} ${navbarButtonCssClassName}__m-next`,
	navButtonInteractionDisabled: `${navbarButtonCssClassName}__m-disabled`,

	weekdays: weekdaysCssClassName,
	weekdaysRow: `${weekdaysCssClassName}__row`,
	weekday: `${baseCssClassName}-weekday`,
	body: `${baseCssClassName}-body`,
	week: `${baseCssClassName}-week`,
	weekNumber: `${baseCssClassName}-week-number`,
	day: `${baseCssClassName}-day`,
	footer: `${baseCssClassName}-footer`,
	todayButton: `${baseCssClassName}-today-button`,

	today: 'today',
	selected: 'selected',
	disabled: 'disabled',
	outside: 'outside',
};


class DatepickerInput extends Component {
	static propTypes = {
		...DayPicker.propTypes,

		minDate: PropTypes.instanceOf(Date),
		maxDate: PropTypes.instanceOf(Date),

		numberOfYears: PropTypes.number,

		isInvalid: PropTypes.bool,

		disabledDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
	};

	static defaultProps = {
		...DayPicker.defaultProps,
		locale: null,
		showTodayButton: false,
		enableOutsideDays: true,
		disabledDays: [],
		month: null
	};

	constructor(props) {
		super();
		this.state = {
			month: props.value || momentUtils.getTodayDate({ timezone: props.timezone })
		}
	}

	_handleCaptionChange = (date) => {
		const {
			onMonthChange,
		} = this.props;

		this.setState({
			month: date,
		});

		if ( onMonthChange ) {
			onMonthChange(date);
		}
	}

	_getBaseModifiers () {
		return {
			today: (date) => momentUtils.isTodayDate({ date, timezone: this.props.timezone }),
		};
	}

	_getCaptionProps () {
		const {
			minDate,
			maxDate,
			numberOfYears,
		} = this.props;

		return {
			minDate,
			maxDate,

			numberOfYears,

			onChange: this._handleCaptionChange,
		};
	}

	_getBaseProps = () => {
		let placeholder = '';
		const {
			minDate,
			maxDate,
			modifiers,
			enableOutsideDays,
			disabled,
		} = this.props;
		const dayPickerProps = {
			locale: locale(),
			month: this.state.month,
			modifiers: {
				...this._getBaseModifiers(),
				...modifiers,
			},
			classNames: classNames,
			enableOutsideDays: enableOutsideDays,
			localeUtils: LocaleUtils,
			captionElement: (
				<DatepickerCaption
					{...this._getCaptionProps()}
				/>
			),
		};

		if ( minDate || maxDate ) {
			dayPickerProps.fromMonth = ( minDate || null );
			dayPickerProps.toMonth = ( maxDate || null );

			dayPickerProps.disabledDays = [
				{
					before: ( minDate || null ),
					after: ( maxDate || null ),
				},
			];
		}

		switch (locale()) {
			case 'ru':
				placeholder = 'ДД.MM.ГГГГ';
				break;
			default:
				moment.locale(locale());
				placeholder = moment.localeData().longDateFormat('L');
		}

		const inputClassNames = {
			container: 'DayPickerInput',
			overlayWrapper: 'DayPickerInput-OverlayWrapper',
			overlay: 'DayPickerInput-Overlay'
		};

		if ( this.props.isInvalid ) {
			inputClassNames.container += ' DayPickerInput__m-invalid';
		}

		return {
			format: "L",
			formatDate: formatDate,
			parseDate: parseDate,
			placeholder: placeholder,
			value: this.props.value,
			onDayChange: this.props.onDayChange,
			dayPickerProps,
			inputProps: {
				onBlur: this.props.onBlur,
				disabled,
			},
			classNames: inputClassNames,
		};
	}

	render () {
		return (
			<DayPickerInput {...this._getBaseProps()} />
		);
	}
}

export default DatepickerInput;
