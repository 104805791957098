/**
 * @typedef {Object} CollectionLabelShape
 *
 * @property {string} border_style
 * @property {string} color
 * @property {string} color
 * @property {number} color_priority
 * @property {string} type
 */

/**
 * @typedef {Object} CollectionLabelTag
 *
 * @property {string} key
 * @property {string} readable_name
 * @property {string} hotkey
 */

/**
 * @typedef {Object} CollectionLabel
 *
 * @property {number} id
 * @property {LabelClassId} class_id
 * @property {CollectionImageType} image_type
 * @property {CollectionLabelShape} shape
 * @property {boolean} shape_required
 * @property {CollectionLabelTag[]} attributes
 * @property {boolean} is_attributes_unique
 */

/**
 * @typedef {string} CollectionHashName
 */

/**
 * @typedef {Object} Collection
 *
 * @property {CollectionHashName} hashname
 * @property {string} name
 * @property {string} description
 * @property {string} thumbnail
 * @property {number} num_images
 * @property {CollectionImage[]} images
 * @property {CollectionLabel[]} labels
 * @property {{ image_type: CollectionImageType, relation: CollectionRelation}} labels
 * @property {Object<string,Object>} hotkeys
 * @property {boolean} is_default
 * @property {boolean} is_shared_within_company
 */

/**
 * @enum {string}
 */
export const CollectionImageType = {
	IMAGE_TYPE__PAN: 'pan',
	IMAGE_TYPE__XRAY: 'xray',
	IMAGE_TYPE__PAN_CHILD: 'child_pan',
	IMAGE_TYPE__PERIAPICAL: 'periapical',
	IMAGE_TYPE__BITEWING: 'bitewing',
	IMAGE_TYPE__UNKNOWN: 'unknown',
};

/**
 * @typedef {CollectionImageType}  CollectionImageType
 */

/**
 * @typedef {string} CollectionImageHashName
 */

/**
 * @typedef {string} CollectionImageId
 */

/**
 * @typedef {Object} CollectionImage
 *
 * @property {CollectionImageType} image_type
 * @property {CollectionImageHashName} hashname
 * @property {CollectionHashName} collection_hashname
 * @property {string} name
 * @property {string} image_url
 * @property {string} web_url
 * @property {string|null} image_date
 * @property {number} modified_at
 * @property {number} last_opened_at
 * @property {number} created_at
 * @property {boolean} is_bad_quality
 * @property {string|null} gender
 * @property {string|null} birthday
 * @property {number|null} patient_id
 * @property {string|null} examination
 * @property {number} examination_id
 * @property {boolean} is_fmx
 * @property {number[]} teeth_meta
 * @property {string} [id]
 */

/**
 * @enum {string}
 */
export const CollectionRelationType = {
	MANDATORY: 'mandatory,'
};

/**
 * @typedef {Object} CollectionRelation
 *
 * @property {number} id
 * @property {LabelClassId} object_label
 * @property {LabelClassId} subject_label
 * @property {CollectionRelationType} relation_type
 * @property {string} description
 */

export const IMAGE_TYPE__PAN = 'pan';
export const IMAGE_TYPE__XRAY = 'xray';
export const IMAGE_TYPE__PAN_CHILD = 'child_pan';
export const IMAGE_TYPE__PERIAPICAL = 'periapical';
export const IMAGE_TYPE__CEPHALOMETRIC = 'ceph';
export const IMAGE_TYPE__BITEWING = 'bitewing';
export const IMAGE_TYPE__UNKNOWN = 'unknown';
export const IMAGE_TYPE__EXTRAORAL_BITEWING = 'extraoral_bitewing';
export const IMAGE_TYPE__EXTRAORAL_BITEWING_CHILD = 'extraoral_bitewing_child';

export const AVAILABLE_TYPES = [
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__XRAY,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__CEPHALOMETRIC,
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__EXTRAORAL_BITEWING,
	IMAGE_TYPE__EXTRAORAL_BITEWING_CHILD,
	IMAGE_TYPE__UNKNOWN,
];

/**
 * @enum
 */
export const APP_IMAGE_MODE = {
	SINGLE: 'single',
	TREATMENT_PLAN: 'treatment_plan',
};

export const MIN_BOX_HEIGHT = 5;
export const MIN_BOX_WIDTH = 5;
