import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lodashDebounce from 'lodash/debounce';

import ImageShapeBoneloss from '../ImageShapes/shapes/boneloss';
import labelUtils from '../../appUtils/labelsUtils';
import namedPolyHintController from '../../services/named-poly-hint';

import './styles/DrawBoneloss.css';


const baseCssClassName = 'draw-boneloss';
const svgCssClassName = `${baseCssClassName}__svg`;
const hintCssClassName = `${baseCssClassName}__hint`;

const defaultLabels = [ 'CRm','CEJm', 'Bm', 'Rm', 'Rd', 'Bd', 'CEJd', 'CRd' ];

const CLICKS_DEBOUNCE_TIMEOUT = 200;


export default class DrawBoneloss extends Component {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		selectedClassId: PropTypes.string,
		imageOriginalWidth: PropTypes.number.isRequired,
		imageOriginalHeight: PropTypes.number.isRequired,

		onStart: PropTypes.func.isRequired,
		onFinish: PropTypes.func.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._el = null;
		
		const customLabels = props.selectedClassId && labelUtils.getNamedPolyDotNames(props.selectedClassId);

		this.state = {
			mode: 'draw',
			points: [],
			labels: customLabels && customLabels.length > 0 ? customLabels : defaultLabels,
			labelsIndex: 0,
			selectedLabels: [],
			cursorX: 0,
			cursorY: 0,
		};
		this._handleMouseDownDebounce = lodashDebounce(this._handleMouseDown, CLICKS_DEBOUNCE_TIMEOUT);
	}

	componentDidMount () {
		window.document.addEventListener('keydown', this._handleKeyDown, false);
		
		namedPolyHintController.show();
		this._printTextInHint(this.state.labels[0]);
	}

	componentWillUnmount () {
		if ( this._handleMouseDownDebounce !== null ) {
			this._handleMouseDownDebounce.cancel();
			this._handleMouseDownDebounce = null;
		}

		window.document.removeEventListener('keydown', this._handleKeyDown, false);
		namedPolyHintController.hide();
	}

	_printTextInHint (dotName) {
		namedPolyHintController.text(`Point for the “${dotName}” dot. Press “Space” to skip or “Esc” to discard`);
	}
	
	_getDiff () {
		const {
			zoom,
		} = this.props;

		return (
			zoom === 1
				? 1
				: ( 1 / zoom )
		);
	}

	_finish () {
		this.props.onFinish({
			type: 'named_poly',
			points: this.state.points,
			labels: this.state.selectedLabels,
		});

		window.document.removeEventListener('keydown', this._handleKeyDown, false);
		namedPolyHintController.hide();
	}

	_handleRef = (el) => {
		this._el = el;
	}

	_handleMouseDown = (clientX, clientY) => {
		this.props.onStart();
		const diff = this._getDiff();

		const el = this._el.getBoundingClientRect();

		const x = ( clientX - el.left ) * diff;
		const y = ( clientY - el.top ) * diff;

		if ( x < 0 || y < 0 || x > this.props.imageOriginalWidth || y > this.props.imageOriginalHeight ) {
			return;
		}

		const nextLabel = this.state.labels[this.state.labelsIndex];
		const nextSelectedLabels = this.state.selectedLabels.slice();
		if ( nextLabel ) {
			nextSelectedLabels.push(nextLabel);
		}
		
		this._printTextInHint(nextLabel);

		this.setState((prevState) => ({
			points: [
				...prevState.points,
				[ x, y ],
			],
			labelsIndex: prevState.labelsIndex + 1,
			selectedLabels: nextSelectedLabels,
		}), () => {
			if ( !this.state.labels[this.state.labelsIndex] ) {
				this._finish();
			}
			else {
				this._printTextInHint(this.state.labels[this.state.labelsIndex]);
			}
		});
	}

	_handleKeyDown = (event) => {
		if ( event.key.toLowerCase() === 'enter' ) {
			if ( this.state.points.length > 1 ) {
				this._finish();
			}
		}
		else if ( event.code.toLowerCase() === 'space' ) {
			event.preventDefault();
			this.setState((prevState) => ({
				labelsIndex: prevState.labelsIndex + 1,
			}), () => {
				if ( !this.state.labels[this.state.labelsIndex] ) {
					this._finish();
				}
				else {
					this._printTextInHint(this.state.labels[this.state.labelsIndex]);
				}
			});
		}
	}

	_handleMouseMove = (event) => {
		this.setState({
			cursorX: event.clientX + 30,
			cursorY: event.clientY + 30,
		});
	}

	_renderHint () {
		if ( !this._el ) {
			return null;
		}

		const elRect = this._el.getBoundingClientRect();

		return (
			<div
				className={hintCssClassName}
				style={{
					left: this.state.cursorX - elRect.left,
					top: this.state.cursorY - elRect.top,
				}}
			>{this.state.labels[this.state.labelsIndex]}</div>
		)
	}

	_renderShape () {
		const {
			zoom,
			labelShape,
		} = this.props;

		if ( this.state.points.length === 0 ) {
			return null;
		}

		let color = '#fff';
		let borderStyle = 'solid';

		if ( labelShape ) {
			color = (labelShape.color || color);
			borderStyle = (labelShape.border_style || borderStyle);
		}

		const props = {
			shape: {
				type: 'named_poly',
				points: this.state.points,
				labels: this.state.selectedLabels,
			},
			color: color,
			borderStyle: borderStyle,
			showControls: true,
			isHighlighted: false,
			allowEditing: false,
			zoom,
		};

		return (
			<ImageShapeBoneloss {...props} />
		);
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				onMouseDown={
					(event) => this._handleMouseDownDebounce(event.clientX, event.clientY)
				}
				onMouseMove={this._handleMouseMove}
				ref={this._handleRef}
			>
				{this._renderHint()}
				<svg xmlns={'http://www.w3.org/2000/svg'} className={svgCssClassName}>
					{this._renderShape()}
				</svg>
			</div>
		);
	}
}
