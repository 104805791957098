import apiActionTypes from '../../../actions/apiActionTypes';


/**
 * @typedef {Object<LabelId,LabelId[]>} LabelChildrenStoreState
 */

/**
 *
 * @param {LabelChildrenStoreState} state
 * @param {ReduxAction} action
 * @return {LabelChildrenStoreState}
 */
export default function labelChildrenReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return (action.payload.labelChildren || state);

		default:
			return state;
	}
}
