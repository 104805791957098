import { getDictionary } from '../../appUtils/locale';


const i18n = getDictionary('report');

const TEETH_STATUS_INDICATORS = {
	'normal': `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15"><circle cx="8" cy="8" r="6" fill="none" stroke="#349563" stroke-width="2"/></svg>`,
	'normal-with-findings': `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15"><circle cx="7.5" cy="7.5" r="7" stroke="#9B9B9B"/></svg>`,
	'pathology': `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15"><circle cx="8" cy="8" r="6" fill="none" stroke="#EF5350" stroke-width="2"/></svg>`,
	'missing': `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15"><circle cx="8" cy="8" r="6" fill="none" stroke="#9B9B9B" stroke-width="2"/></svg>`,
	'absent': `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15"><defs><circle id="a" cx="7.5" cy="7.5" r="7.5"/><mask id="b" width="15" height="15" x="0" y="0" fill="#fff"><use xlink:href="#a"/></mask></defs><use fill="url(#absent-tooth)" fill-rule="evenodd" mask="url(#b)" xlink:href="#a"/></svg>`,
};

function getIndicators () {
	return [
		{
			key: 'normal',
			title: i18n('legend.labels.normal'),
		},
		// {
		// 	key: 'normal-with-findings',
		// 	title: i18n('legend.labels.normal-with-findings'),
		// },
		{
			key: 'pathology',
			title: i18n('legend.labels.pathology'),
		},
		{
			key: 'missing',
			title: i18n('legend.labels.missing'),
		},
		{
			key: 'absent',
			title: 'Not in image',
		},
	];
}

function getToothStyle ({
	type,
	color,
}) {
	switch (type) {
		case 'normal':
			return {
				'fill': '#349563',
				'fill-rule': 'evenodd',
			};

		case 'missing':
			return {
				'fill': 'none',
				'stroke': '#9B9B9B',
			};

		case 'absent':
			return {
				'fill': 'url(#absent-tooth)',
				'fill-rule': 'evenodd',
			};

		default:
			return {
				'fill': color,
				'fill-rule': 'evenodd',
			};
	}
}


export default {
	TEETH_STATUS_INDICATORS,
	getIndicators,
	getToothStyle,
};
