import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Route, withRouter, Switch, Redirect } from 'react-router-dom';

import collectionsActions from '../../actions/collectionsActions';

import CollectionPage from './CollectionPageConnector';
import Main from '../Main';
import Registry from '../Registry';
import editorSelectors from '../../selectors/editorSelectors';
import collectionsSelectors from '../../selectors/collectionsSelectors';
import Loading from '../Loading';


class CollectionHoc extends Component {
	static propTypes = {
		currentCollectionId: PropTypes.string,

		onIsSyncedCollection: PropTypes.func.isRequired,
		onGetCollection: PropTypes.func.isRequired,
		onSetCurrentCollection: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			isSyncing: false,
			isSynced: false,
			image: null
		};
	}

	componentDidMount () {
		this._loadCollection();
	}

	componentWillUnmount () {
		this.state = null;

		this.props.onSetCurrentCollection({ collection: null });
	}

	_loadCollection () {
		const hashName = this.props.match.params.id;

		if ( !hashName ) {
			this.context.router.history.push('/collection-list');
			return;
		}

		if ( this.props.onIsSyncedCollection(hashName) ) {
			this.setState({
				isSynced: true,
			});
			this.props.onSetCurrentCollection({ collection: hashName });
			return;
		}

		this.setState({
			isSyncing: true,
		});

		this.props.onGetCollection({ hashName })
			.then(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSynced: true,
				});
			})
			.catch((error) => {
				if ( !this.state ) {
					return;
				}

				this.context.router.history.push('/');
			})
			.finally(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSyncing: false,
				});
		});
	}

	renderPath = () => {
		const { currentCollectionId } = this.props;
		const collectionPath = `/collections/${currentCollectionId}`;
		return <Redirect to={collectionPath} />
	}

	render() {
		return this.state.isSynced
			?  (<Switch>
					<Route exact path="/" render={this.renderPath} />
					<Route path={'/collections/:id'} exact component={CollectionPage}/>
					<Route path={'/collections/:id/image/:imageId'} component={Main} />
					<Route path={'/collections/:id/registry'} component={Registry} />
				</Switch>
				)
			:  <Loading />
	}
}

export default withRouter(
	connect(
		(state) => {
			return {
				currentCollectionId: editorSelectors.selectEditor(state).currentCollectionHashName,
				onIsSyncedCollection: (id) => collectionsSelectors.selectIsSyncedCollection(state, { id }) ,
			};
		},
		(dispatch) => ({
			onGetCollection: (data) => dispatch(collectionsActions.getCollection(data)),
			onSetCurrentCollection: (data) => dispatch(collectionsActions.setCurrentCollection(data)),
		}),
)(CollectionHoc));
