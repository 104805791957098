import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';

import ResolverSidebarPanel from './ResolverSidebarPanel';


export default class ResolverSidebarPanelConnector extends PureComponent {
	static propTypes = {
		onFindingsFilterChange: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
	};

	_storeUnsubscribeCallback = null;

	constructor (props, context) {
		super(props, context);

		this.state = this._fillState();
	}

	componentDidMount () {
		this._storeUnsubscribeCallback = this.context.store.subscribe(this._handleStoreChange);
	}

	componentWillUnmount () {
		if ( this._storeUnsubscribeCallback !== null ) {
			this._storeUnsubscribeCallback();
			this._storeUnsubscribeCallback = null;
		}
	}

	_fillState () {
		const storeState = this.context.store.getState();
		const editorData = editorSelectors.selectEditor(storeState);

		return {
			shouldShowBoneStages: editorData.showBoneLossStages,
			isActiveMagnifyingGlass: editorData.magnifyingGlassEnabled,
			shouldHideFindings: editorData.showFindingsOnImage === false,
			findingsFilter: editorData.treatmentPlanFilters,
		};
	}

	_handleStoreChange = () => {
		this.setState(this._fillState());
	}

	_handleToggleExpand = () => {

	};

	handleToggleMagnifyingGlass = () => {
		this.context.store.dispatch(editorActions.toggleMagnifyingGlass(!this.state.isActiveMagnifyingGlass));
	};

	handleToggleHideFindings = () => {
		this.context.store.dispatch(editorActions.toggleShowFindings(!this.state.shouldHideFindings));
	};

	_handleChaneBoneStages = () => {
		this.context.store.dispatch(editorActions.toggleShowBoneLossStages(!this.state.shouldShowBoneStages));
	};

	_handleTogglePreExistingFindings = () => {
		const nextFilter = {
			...this.state.findingsFilter,
		}

		nextFilter['treatment'] = nextFilter['treatment'] !== true;
		this.props.onFindingsFilterChange(nextFilter);
	};

	_handleToggleChartedFindings = () => {
		const nextFilter = {
			...this.state.findingsFilter,
		}

		nextFilter['existing'] = nextFilter['existing'] !== true;
		this.props.onFindingsFilterChange(nextFilter);
	};

	render () {
		return (
			<ResolverSidebarPanel
				{...this.props}
				shouldShowPreExistingFindings={this.state.findingsFilter['treatment'] === true}
				onTogglePreExistingFindings={this._handleTogglePreExistingFindings}
				shouldShowChartedFindings={this.state.findingsFilter['existing'] === true}
				onToggleChartedFindings={this._handleToggleChartedFindings}
				shouldHideFindings={this.state.shouldHideFindings}
				onToggleHideFindings={this.handleToggleHideFindings}
				isActiveMagnifyingGlass={this.state.isActiveMagnifyingGlass}
				onToggleMagnifyingGlass={this.handleToggleMagnifyingGlass}
				shouldShowBoneStages={this.state.shouldShowBoneStages}
				onChaneBoneStages={this._handleChaneBoneStages}
			/>
		);
	}
}
