import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { events } from '../../services/events';
import ImagesCache from '../../services/images-cache';

import Loading from '../Loading';
import Button from '../Button';
import MagnifyingGlassImage from './MagnifyingGlassImageConnector';

import { STATUSES } from './MagnifyingGlassConstants';
import { getDictionary } from '../../appUtils/locale';

import './styles/MagnifyingGlass.css';



const i18nShared = getDictionary('shared');

const baseCssClassName = 'magnifying-glass';
const viewportCssClassName = `${baseCssClassName}__viewport`;
const imageStatusCssClassName = `${baseCssClassName}-image-status`;
const imageStatusContentCssClassName = `${imageStatusCssClassName}__content`;


export default class MagnifyingGlass extends PureComponent {
	static propTypes = {
		image: PropTypes.object,
		zoom: PropTypes.number,
	};

	static defaultProps = {
		image: null,
		zoom: 2,
	};

	constructor (props) {
		super(props);

		this._el = null;
		this._viewportEl = null;
		this._canvasIsDragging = false;
		this._pointerPosition = {
			x: 0,
			y: 0,
		};
		this._imageSize = {
			width: 0,
			height: 0,
		};
		
		this.state = {
			imageStatus: STATUSES.IDLE,
		};
	}

	componentDidMount () {
		events.on('canvas.dragging.started', this._handleCanvasDraggingStarted);
		events.on('canvas.dragging.finished', this._handleCanvasDraggingFinished);
		events.on('canvas.pointer.moved', this._handleCanvasPointerMoved);
	}

	componentWillUnmount () {
		events.removeListener('canvas.dragging.started', this._handleCanvasDraggingStarted);
		events.removeListener('canvas.dragging.finished', this._handleCanvasDraggingFinished);
		events.removeListener('canvas.pointer.moved', this._handleCanvasPointerMoved);
	}

	componentDidUpdate (prevProps) {
		if ( this.props.image === null && prevProps.image !== null ) {
			this.setState({
				imageStatus: STATUSES.IDLE,
			});
		}
	}

	_setViewportOffset () {
		const origWidth = this._imageSize.width * this.props.zoom;
		const origHeight = this._imageSize.height * this.props.zoom;

		let offsetX = 0;
		let offsetY = 0;

		if ( this._el !== null ) {
			const viewportPosition = this._el.getBoundingClientRect();
			const viewportWidth = viewportPosition.right - viewportPosition.left;
			const viewportHeight = viewportPosition.right - viewportPosition.left;
			offsetX = Math.min(
				0,
				Math.max(
					-(this._imageSize.width - (viewportWidth / 2)) * this.props.zoom,
					-(this._pointerPosition.x * this.props.zoom) + (viewportWidth / 2)
				)
			);
			offsetY = Math.min(
				0,
				Math.max(
					-(this._imageSize.height - (viewportHeight / 2)) * this.props.zoom,
					-(this._pointerPosition.y *this.props.zoom) + (viewportHeight / 2)
				)
			);
		}

		this._viewportEl.style.width = `${origWidth}px`;
		this._viewportEl.style.height = `${origHeight}px`;
		this._viewportEl.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
	}

	/**
	 * @param {HTMLElement} el
	 * @private
	 */
	_handleRef = (el) => {
		this._el = el || null;
	};

	/**
	 * @param {HTMLElement} el
	 * @private
	 */
	_handleViewportRef = (el) => {
		this._viewportEl = el || null;
	};

	_handleCanvasDraggingStarted = () => {
		this._canvasIsDragging = true;
	};

	_handleCanvasDraggingFinished = () => {
		this._canvasIsDragging = false;
	};

	/**
	 * @param {{x: number, y: number}} pointerPosition
	 * @private
	 */
	_handleCanvasPointerMoved = (pointerPosition) => {
		if ( this._canvasIsDragging === true ) {
			return;
		}

		this._pointerPosition = pointerPosition;
		this._setViewportOffset();
	};

	_handleImageStatusChanged = (imageStatus, data) => {
		if ( imageStatus === STATUSES.LOADED ) {
			this._imageSize = data;
		}
		this.setState( {
			imageStatus,
		}, () => {
			if ( imageStatus === STATUSES.LOADED ) {
				this._setViewportOffset();
			}
		});
	};

	_handleTryAgainClick = () => {
		this.setState({
			imageSeed: ImagesCache.generateCache(this.props.image.id),
		});
	};

	_renderImage () {
		if ( this.props.imageStatus === STATUSES.ERROR || this.props.image === null ) {
			return null;
		}

		return (
			<MagnifyingGlassImage
				image={this.props.image}
				width={this._imageSize.width * this.props.zoom}
				height={this._imageSize.height * this.props.zoom}
				onStatusChanged={this._handleImageStatusChanged}
			/>
		);
	}

	_renderImageStatus () {
		let content = null;

		switch (this.state.imageStatus) {
			case STATUSES.IN_PROGRESS:
				content = (
					<Loading />
				);
				break;

			case STATUSES.ERROR:
				content = (
					<div
						style={{
							textAlign: 'center',
							color: '#fff',
						}}
					>
						<h3>An error occurred when loading image. Please try again</h3>
						<Button
							theme={Button.AVAILABLE_THEMES.PRIMARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							title={i18nShared('buttons.try_again')}
							onClick={this._handleTryAgainClick}>
							{i18nShared('buttons.try_again')}
						</Button>
					</div>
				);
				break;

			default:
				break;
		}

		return (
			<div className={imageStatusCssClassName}>
				<div className={imageStatusContentCssClassName}>
					{content}
				</div>
			</div>
		);
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				ref={this._handleRef}
			>
				<div
					className={viewportCssClassName}
					ref={this._handleViewportRef}
				>
					{this._renderImage()}
				</div>
				{this._renderImageStatus()}
			</div>
		);
	}
}
