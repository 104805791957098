/**
 * @param {LabelTag} tag
 * @return {LabelTagId}
 */
const getTagId = (tag) => tag.tagId;

/**
 * @param {LabelTag} tag
 * @return {string}
 */
const getTagKey = (tag) => tag.key;

/**
 * @param {LabelTag} tag
 * @return {string}
 */
const getLocalizedTagName = (tag) => tag.localizedName;

/**
 * @param {LabelTag} tag
 * @return {string}
 */
const getTagHotKey = (tag) => tag.hotKey;


export default {
	getTagId,
	getTagKey,
	getLocalizedTagName,
	getTagHotKey,
};
