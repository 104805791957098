/**
 * @param {StoreState} state
 * @return {LabelChildrenStoreState}
 */
export const selectLabelChildren = (state) => (state.labelChildren || {});

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @return {LabelId[]}
 */
export const selectLabelChildrenByLabelId = (state, options) => (selectLabelChildren(state)[options.labelId] || []);


export default {
	selectLabelChildren,
	selectLabelChildrenByLabelId,
};
