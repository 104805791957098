import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import moment from 'moment';

import Chip from "@material-ui/core/Chip";

import collectionApi from '../../api/collectionApi';

import mainConfig from '../../configs/mainConfig';

import gridUtils from '../../appUtils/gridUtils';
import dateUtils from '../../appUtils/dateUtils';
import momentUtils from '../../appUtils/momentUtils';
import imageUtils from '../../appUtils/imageUtils';

import Overlay from '../Overlay';
import Alignment from '../Alignment';
import Loading from '../Loading';

import Grid from '../Grid';
import FormCheckbox from '../FormCheckbox';
import TextField from '@material-ui/core/TextField';
import FormSelect from '../FormSelect';
import Button from '../Button';
import FormCustomSelect from '../FormCustomSelect';

import aclService from '../../services/acl';

import PopupDialog,
{
	getFooterCloseButton,
	PopupDialogContentWithoutPadding,
	PopupDialogHeader,
} from '../PopupDialog';

import { getMaxDate, getMinDate } from '../Datepicker';
import FormDatepicker from '../FormDatepicker';

import { getDictionary, locale } from '../../appUtils/locale';

import {
	USER_PERMISSION__CHANGE_IMAGE,
	USER_PERMISSION__DELETE_IMAGE,
	USER_PERMISSION__DOWNLOAD_IMAGE,
	USER_PERMISSION__VIEW_IMAGE,
} from '../../constants/userPermissionsConstants';

import './styles/Registry.css';


function getDefaultFilters () {
	return {
		confirmed: null,
		empty: null,
		same_as_predictions: false,
		classes: [],
	};
}

function getDefaultState () {
	return {
		invalidFieldName: null,

		selectedRowData: {},

		hoveredItemId: null,
		selectedItems: {},
		selectedRow: null,
		selectedColumn: null,
	};
}


const i18n = getDictionary('registry');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'registry';
const contentCssClassName = `${baseCssClassName}__content`;
const statusContentCssClassName = `${baseCssClassName}__status-content`;
const overlayCssClassName = `${baseCssClassName}__overlay`;
const removingErrorMessageCssClassName = `${baseCssClassName}__removing-error-message`;
const dividerCssClassName = `${baseCssClassName}__divider`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonsMainCssClassName = `${baseCssClassName}__buttons-main`;
const buttonsSecondaryCssClassName = `${baseCssClassName}__buttons-secondary`;
const noImagesCssClassName = `${baseCssClassName}__no-images`;
const errorCssClassName = `${baseCssClassName}__error`;
const filtersCssClassName = `${baseCssClassName}__filters`;
const filterCssClassName = `${baseCssClassName}__filter`;
const filterLinkCssClassName = `${baseCssClassName}__filter-link`;
const filterLabelCssClassName = `${baseCssClassName}__filter-label`;
const filterLabelTextCssClassName = `${baseCssClassName}__filter-label-text`;
const filterClassesCssClassName = `${baseCssClassName}__filter-classes`;
const filterClassesItemCssClassName = `${baseCssClassName}__filter-classes-item`;
const filterClassesItemControlCssClassName = `${baseCssClassName}__filter-classes-item-control`;

const getGridContentRowKey = (eventData) => eventData.id;

const noop = () => {};


export default class Registry extends Component {
	static propTypes = {
		currentImageId: PropTypes.string,
		currentCollectionId: PropTypes.string,

		onRemoveImages: PropTypes.func.isRequired,
		onGetImages: PropTypes.func.isRequired,
		onGoToImage: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	state = {
		isDataDirty: false,

		images: [],

		isLoading: true,
		hasLoadingError: false,

		hasSavingError: false,

		isRemoving: false,
		hasRemovingError: false,

		...getDefaultState(),
		filters: getDefaultFilters()
	};

	componentDidMount () {
		this._loadData();
	}

	componentWillUnmount () {
		this.state = null;
	}

	async _loadData () {
		this.setState({
			isLoading: true,
			hasLoadingError: false,
			...getDefaultState(),
		});

		try {
			const data = await collectionApi.getCollection({
				hashName: this.props.currentCollectionId,
				params: {
					...this.state.filters,
					same_as_predictions: this.state.filters.same_as_predictions === true ? true : null,
				},
			});

			if ( !this.state ) {
				return;
			}

			this.setState({
				isLoading: false,
				images: this.props.onGetImages(data.images.sort((a, b) => b.created_at - a.created_at).map((image) => image.hashname)),
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isLoading: false,
				hasLoadingError: true,
			});
		}
	}

	_handleClose = () => {
		this.context.router.history.goBack();
	}

	_selectItem = ({id, value,}) => {
		this.setState({
			selectedItems: {
				...this.state.selectedItems,
				[id]: value,
			},
		});
	}

	_isRowChanged (rowId) {
		const {
			isDataDirty,
			selectedRow,
		} = this.state;

		const selectedRowId = selectedRow ? selectedRow.id : null;
		return isDataDirty && isDataDirty[rowId] && rowId === selectedRowId;
	}

	_getPatientColumn = (rowData) => {
		const {
			selectedRowData,
			selectedRow,
			selectedColumn
		} = this.state;

		const selectedRowId = selectedRow ? selectedRow.id : null;
		const selectedColumnKey = selectedColumn ? selectedColumn.key : null;

		const patientId = this._isRowChanged(rowData.id)
			? selectedRowData.patient_id
			: rowData.patient_id;

		if (rowData.id === selectedRowId && 'patients-id' === selectedColumnKey) {
			const check = mainConfig.FIELDS_PATTERNS['patient_id'];
			const error = !check(patientId);

			return (
				<div onDoubleClick={(event) => event.stopPropagation()}>
					{error && <div className={errorCssClassName}>{i18n('incorrect_patient_id')}</div>}
					<TextField
						error={error}
						id={'patient_id'}
						name={'patient_id'}
						value={(patientId || '' )}
						onChange={this._handleActiveInputChange}
						onBlur={this._handleActiveInputBlur}
						disabled={!aclService.checkPermission(USER_PERMISSION__CHANGE_IMAGE)}
					/>
				</div>
			);
		}

		return patientId;
	}

	_getImageDateColumn = (rowData) => {
		const {
			selectedRowData,
			selectedRow,
			selectedColumn
		} = this.state;

		const selectedRowId = selectedRow ? selectedRow.id : null;
		const selectedColumnKey = selectedColumn ? selectedColumn.key : null;

		const imageDate = this._isRowChanged(rowData.id) && selectedRowData.image_date
			? selectedRowData.image_date
			: rowData.image_date;

		if (rowData.id === selectedRowId && 'image-date' === selectedColumnKey) {
			return (
				<div onDoubleClick={(event) => event.stopPropagation()}>
					<FormDatepicker
						name={'image_date'}
						date={imageDate ? moment(imageDate).toDate(): null}
						datepickerProps={{
							minDate: getMinDate(),
							maxDate: getMaxDate(),
						}}
						onGetRef={this._handleActiveInputRef}
						onDateChange={this._handleImageDateChange}
						disabled={!aclService.checkPermission(USER_PERMISSION__CHANGE_IMAGE)}
					/>
				</div>
			);
		}

		return imageDate
			? `${momentUtils.getMomentForDate({
				date: moment(imageDate).toDate(),
			}).format('L')}`
			: null;
	}

	_getBirthdayDayColumn = (rowData) => {
		const {
			selectedRowData,
			selectedRow,
			selectedColumn
		} = this.state;

		const selectedRowId = selectedRow ? selectedRow.id : null;
		const selectedColumnKey = selectedColumn ? selectedColumn.key : null;

		const birthdayDate =this._isRowChanged(rowData.id) && selectedRowData.birthday
			? selectedRowData.birthday
			: rowData.birthday;

		const birthday = birthdayDate ? dateUtils.getAge(birthdayDate) : null;
		if (rowData.id === selectedRowId && 'birthday' === selectedColumnKey) {
			return (
				<FormDatepicker
					name={'birthday'}
					date={birthdayDate ? moment(birthdayDate).toDate() : null}
					datepickerProps={{
						minDate: new Date(momentUtils.getNowMoment().subtract('140', 'years').valueOf()),
						maxDate: getMaxDate(),
					}}
					birthday={birthday}
					onGetRef={this._handleActiveInputRef}
					onDateChange={this._handleBirthdayChange}
					disabled={!aclService.checkPermission(USER_PERMISSION__CHANGE_IMAGE)}
				/>
			);
		}

		return birthdayDate
			? `${momentUtils.getMomentForDate({date: moment(birthdayDate).toDate(),}).format('L')}(${birthday})`
			: null;
	}

	_getGenderColumn = (rowData, index) => {
		const {
			selectedRowData,
			selectedRow,
			selectedColumn
		} = this.state;

		const selectedRowId = selectedRow ? selectedRow.id : null;
		const selectedColumnKey = selectedColumn ? selectedColumn.key : null;

		const gender = this._isRowChanged(rowData.id) && selectedRowData.gender
			? selectedRowData.gender
			: rowData.gender;

		if (rowData.id === selectedRowId && 'gender' === selectedColumnKey) {
			return (
				<div onDoubleClick={(event) => event.stopPropagation()}>
					<FormSelect
						name={'gender'}
						value={gender}
						items={mainConfig.GENDERS.map((type) => ({
							value: type,
							label: i18nShared(`gender.${type}`),
						}))}
						block
						disabled={!aclService.checkPermission(USER_PERMISSION__CHANGE_IMAGE)}
						onChange={this._handleGenderChange}
					/>
				</div>
			);
		}

		return i18nShared(`gender.${gender}`);
	}

	_getCheckedColumn = (rowData, index) => {
		const {
			selectedItems,
			hoveredItemId,
		}  = this.state;

		if (rowData.id === hoveredItemId || Boolean(selectedItems[rowData.id])) {
			return (
				<FormCheckbox
					key={index}
					inputProps={{
						onChange: (event) => this._selectItem({
							id: rowData.id,
							value: event.target.checked,
						}),
						checked: Boolean(selectedItems[rowData.id]),
					}}
				/> );
		}

		return '';
	}

	_getColumns () {
		const columns = [
			gridUtils.makeGridColumn({
				key: 'checked',
				width: 40,
				headerValue: '',
				getValue: this._getCheckedColumn,
			}),
			gridUtils.makeGridColumn({
				key: 'index',
				width: '5%',
				headerValue: '',
				getValue: (rowData, index) => `${index + 1}`,
			}),
			gridUtils.makeGridColumn({
				key: 'image-id',
				width: '20%',
				headerValue: i18n('grid.header.image_id'),
				getValue: (rowData) => rowData.hashname,
			}),
			gridUtils.makeGridColumn({
				key: 'image-date',
				width: '15%',
				headerValue: i18n('grid.header.image_date'),
				getValue: this._getImageDateColumn,
			}),
		];

		if (aclService.checkPermission(USER_PERMISSION__VIEW_IMAGE)) {
			columns.push(
				gridUtils.makeGridColumn({
					key: 'patients-id',
					width: 120,
					headerValue: i18n('grid.header.patient_id'),
					getValue: this._getPatientColumn,
				}),
				gridUtils.makeGridColumn({
					key: 'birthday',
					width: '14%',
					headerValue: i18n('grid.header.birthday'),
					getValue: this._getBirthdayDayColumn,
				}),
				gridUtils.makeGridColumn({
					key: 'image-type',
					width: 120,
					headerValue: i18n('grid.header.image_type'),
					getValue: (rowData) => imageUtils.getGeneralType(rowData).toLocaleUpperCase(),
				}),
				gridUtils.makeGridColumn({
					key: 'gender',
					width: 120,
					headerValue: i18n('grid.header.gender'),
					getValue: this._getGenderColumn,
				}),
			);
		}

		columns.push(
			gridUtils.makeGridColumn({
				key: 'last-opening',
				width: '13%',
				headerValue: i18n('grid.header.last_opening'),
				getValue: (rowData) => moment.unix(rowData.last_opened_at).format('MM/DD/YYYY'),
			}),
			gridUtils.makeGridColumn({
				key: 'modified-at',
				width: '13%',
				headerValue: i18n('grid.header.last_editing'),
				getValue: (rowData) => moment.unix(rowData.modified_at).format('MM/DD/YYYY'),
			}),
		);

		return columns;
	}
	
	_preproccessData (data) {
		return {
			...data,
			patient_id: data.patient_id || null,
		};
	}

	_saveRowData () {
		this.setState({
			hasSavingError: false,
		});

		return this.props.onSaveImageData({
			imageId: this.state.selectedRow.id,
			data: this._preproccessData(this.state.selectedRowData),
		})
			.then(() => {
				this.setState({
					isDataDirty: {[this.state.selectedRow.id]: false},
				});
				this._loadData();
			})
			.catch(() => {
				this.setState({
					hasSavingError: true,
				});
			});
	}

	_validateField = (fieldName) => {
		const check = mainConfig.FIELDS_PATTERNS[fieldName];
		const value = this.state.selectedRowData[fieldName];

		if ( typeof check === 'function' ) {
			return check(value);
		}
		else {
			return check.test(value);
		}
	}

	_removeItems () {
		if ( !this.state.isRemoving ) {
			this.setState({
				isRemoving: true,
				hasRemovingError: false,
			});

			const imageIds = Object.keys(this.state.selectedItems)
				.filter((imageId) => this.state.selectedItems[imageId] === true);

			this.props.onRemoveImages({
				imageIds,
			})
				.then(() => {
					this.setState({
						selectedItems: {},
						isRemoving: false,
					});

					if ( imageIds.indexOf(this.props.currentImageId) !== -1 ) {
						imageUtils.checkHasUnsavedChanged(() => {
							this.context.router.history.push(`/collections/${this.props.collectionId}`);
						});
					}
					else {
						this._loadData();
					}
				})
				.catch(() => {
					this.setState({
						isRemoving: false,
						hasRemovingError: true,
					});
				});
		}
	}

	_setDateChange (name, date) {
		this.setState({
			selectedRowData: {
				...this.state.selectedRowData,
				[name]: date
					? momentUtils.getMomentForDate({date,}).format('YYYY-MM-DD')
					: null,
			},
			isDataDirty: {[this.state.selectedRow.id]: true},
			invalidFieldName: null,
		}, () => {
			this._saveRowData();
			this.setState({selectedRowData: {} })
		});
	}

	_handleImageDateChange = (date) => {
		this._setDateChange('image_date', date);
	}

	_handleBirthdayChange = (date) => {
		this._setDateChange('birthday', date);
	}

	_handleGenderChange = (event) => {
		this.setState({
			selectedRowData: {
				...this.state.selectedRowData,
				[event.currentTarget.name]: event.currentTarget.value,
			},
			isDataDirty: {[this.state.selectedRow.id]: true},
			invalidFieldName: null,
		}, () => {
			this._saveRowData();
		});
	}

	_handleActiveInputRef = (el) => {
		if ( !el || this._activeFormInputEl === el ) {
			return;
		}

		this._activeFormInputEl = el;
		this._activeFormInputEl.focus();
	}

	_handleActiveInputChange = (event) => {
		this.setState({
			selectedRowData: {
				...this.state.selectedRowData,
				[event.target.name]: event.target.value,
			},
			isDataDirty: {[this.state.selectedRow.id]: true},
			invalidFieldName: null,
		});
	}

	_handleActiveInputBlur = (event) => {
		const fieldName = event.target.name;
		const selectedRowData = {
			...this.state.selectedRowData,
			[event.target.name]: event.target.value,
		};
		this.setState({
			selectedRowData: selectedRowData,
		}, () => this._saveData(fieldName));
	}

	_saveData = (fieldName) => {
		if ( this._validateField(fieldName) ) {
			this._saveRowData();

			this.setState({
				selectedColumn: null,
				invalidFieldName: null
			});
		}
		else {
			this.setState({
				invalidFieldName: fieldName,
			});
		}
	}

	_handleRemoveButtonClick = () => {
		this._removeItems();
	}

	_handleLoadingTryAgainButtonClick = () => {
		this._loadData();
	}

	_handleRemoveTryAgainButtonClick = () => {
		this._removeItems();
	}

	_handleContentRowClick = (rowData) => {
		if (this.state.invalidFieldName || ( this.state.selectedRow && this.state.selectedRow.id === rowData.id ) ) {
			return;
		}

		this.setState({
			selectedRow: rowData,
			invalidFieldName: null,
		});
	}

	_handleContentRowDoubleClick = (rowData) => {
		if ( this.props.currentImageId !== rowData.hashname ) {
			this.props.onGoToImage(rowData.hashname);
		}
	}

	_handleContentRowMouseOver = (item) => {
		this.setState({
			hoveredItemId: item.id,
		});
	}

	_handleContentRowMouseOut = () => {
		this.setState({
			hoveredItemId: null,
		});
	}

	_handleContentCellClick = (column) => {
		if ( this.state.selectedColumn === column || this.state.invalidFieldName ) {
			return;
		}

		this.setState({
			selectedColumn: column,
		});
	}

	_handleResetFiltersClick = () => {
		if ( this.state.isLoading || this.state.isRemoving ) {
			return null;
		}

		this.setState({
			filters: getDefaultFilters(),
		}, () => {
			this._loadData();
		});
	}

	_handleConfirmedFilterChange = (value) => {
		this.setState({
			filters: {
				...this.state.filters,
				confirmed: value === 'all'
					? null
					: value === 'true'
			},
		}, () => {
			this._loadData();
		});
	}

	_handleEmptyFilterChange = (value) => {
		this.setState({
			filters: {
				...this.state.filters,
				empty: value === 'all'
					? null
					: value === 'true'
			},
		}, () => {
			this._loadData();
		});
	}

	_handleSameAsPredictedFilterChange = (event) => {
		this.setState({
			filters: {
				...this.state.filters,
				same_as_predictions: event.currentTarget.checked,
			},
		}, () => {
			this._loadData();
		});
	}

	_handleClassesRenderItem = (item) => {
		return (
			<div className={filterClassesItemCssClassName}>
				<div className={filterClassesItemControlCssClassName}>
					<FormCheckbox
						inputProps={{
							checked: this.state.filters.classes.includes(item.value),
						}}
					/>
				</div>
				{item.label}
			</div>
		);
	}

	_countSelectedImages () {
		return Object.keys(this.state.selectedItems)
			.filter((imageId) => this.state.selectedItems[imageId] === true).length;
	}

	_getFirstImageUrlToDownload () {
		if ( this.state.images.length === 0 ) {
			return null;
		}

		const firstImageId = Object.keys(this.state.selectedItems)
			.filter((imageId) => this.state.selectedItems[imageId] === true)[0];

		return ( this.state.images.filter((image) => image.id === firstImageId) || [] )[0].image_url;
	}

	_renderOverlay = (content) => {
		return (
			<div className={overlayCssClassName}>
				<Overlay>
					<Alignment
						horizontal={Alignment.horizontal.CENTER}
						vertical={Alignment.vertical.CENTER}>
						{content}
					</Alignment>
				</Overlay>
			</div>
		);
	}

	_renderStatus () {
		if ( this.state.isLoading && this.state.images.length === 0 ){
			return (
				<div className={statusContentCssClassName}>
					<Loading />
					<div>{i18nShared('messages.wait')}</div>
				</div>
			);
		}
		else if (this.state.isLoading || this.state.isRemoving) {
			return this._renderOverlay(
				<div className={statusContentCssClassName}>
					<Loading />
					<div>{i18nShared('messages.wait')}</div>
				</div>
			);
		}
		else if (this.state.hasRemovingError || this.state.hasLoadingError) {
			const content = (
				<div className={statusContentCssClassName}>
					<div className={removingErrorMessageCssClassName}>
						{i18nShared('messages.error.with_retry')}
					</div>
					<div>
						<Button
							theme={Button.AVAILABLE_THEMES.PRIMARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							onClick={this.state.hasLoadingError ? this._handleLoadingTryAgainButtonClick : this._handleRemoveTryAgainButtonClick}
						>{i18nShared('buttons.try_again')}</Button>
					</div>
				</div>
			);

			if ( this.state.images.length === 0 ) {
				return content;
			}

			return this._renderOverlay(content);
		}

		return null;
	}

	_renderGrid () {
		if ( this.state.hasLoadingError || (this.state.isLoading && this.state.images.length === 0) ) {
			return null;
		}

		if ( this.state.images.length === 0 ) {
			return (
				<div className={noImagesCssClassName}>{i18n('no_images')}</div>
			);
		}

		return (
			<Grid
				cssClassName={`${baseCssClassName}-grid`}
				minWidth={1200}
				columns={this._getColumns()}
				data={this.state.images}
				headerRowHeight={37}
				contentRowHeight={37}
				getContentRowKey={getGridContentRowKey}
				getContentRowModifiers={noop}
				onContentRowClick={this._handleContentRowClick}
				onContentRowDoubleClick={this._handleContentRowDoubleClick}
				onContentRowMouseOver={this._handleContentRowMouseOver}
				onContentRowMouseOut={this._handleContentRowMouseOut}
				onContentCellClick={this._handleContentCellClick}
			/>
		);
	}

	_renderContent = (dialogInterface) => {
		const countSelectedImages = this._countSelectedImages();
		const shouldShowDownloadButton = !this.state.isRemoving && countSelectedImages === 1;

		return (
			<PopupDialogContentWithoutPadding>
				<div className={baseCssClassName}>
					<div className={filtersCssClassName}>
						<div className={filterCssClassName}>
							<FormCustomSelect
								items={[
									{
										label: i18n('filters.confirmed.all'),
										value: 'all',
									},
									{
										label: i18n('filters.confirmed.approved'),
										value: 'true',
									},
									{
										label: i18n('filters.confirmed.unapproved'),
										value: 'false',
									},
								]}
								value={this.state.filters.confirmed === null
									? 'all'
									: String(this.state.filters.confirmed)
								}
								disabled={this.state.isLoading || this.state.isRemoving}
								onChange={this._handleConfirmedFilterChange}
							/>
						</div>
						<div className={filterCssClassName}>
							<FormCustomSelect
								items={[
									{
										label: i18n('filters.empty.all'),
										value: 'all',
									},
									{
										label: i18n('filters.empty.empty'),
										value: 'true',
									},
									{
										label: i18n('filters.empty.nonempty'),
										value: 'false',
									},
								]}
								value={this.state.filters.empty === null
									? 'all'
									: String(this.state.filters.empty)
								}
								disabled={this.state.isLoading || this.state.isRemoving}
								onChange={this._handleEmptyFilterChange}
							/>
						</div>
						<div className={filterCssClassName}>
							<FormCustomSelect
								displayValue={i18n('filters.classes', {
									'n': this.state.filters.classes.length,
								})}
								items={this.props.availableLabels}
								className={filterClassesCssClassName}
								disabled={this.state.isLoading || this.state.isRemoving}
								onRenderItem={this._handleClassesRenderItem}
								onChange={(itemValue) => {
									if ( this.state.isLoading || this.state.isRemoving ) {
										return;
									}

									const item = this.props.availableLabels.find((label) => label.value === itemValue);

									const nextClasses = [
										...this.state.filters.classes,
									];

									const index = nextClasses.indexOf(item.value);

									if ( index !== -1 ) {
										nextClasses.splice(index, 1);
									}
									else {
										nextClasses.push(item.value);
									}

									this.setState({
										filters: {
											...this.state.filters,
											classes: nextClasses,
										},
									}, () => {
										this._loadData();
									});
								}}
							/>
						</div>
						<div className={filterCssClassName}>
							<label className={filterLabelCssClassName}>
								<FormCheckbox
									inputProps={{
										checked: this.state.filters.same_as_predictions,
										disabled: (this.state.isLoading || this.state.isRemoving),
										onChange: this._handleSameAsPredictedFilterChange,
									}}
								/>
								<div className={filterLabelTextCssClassName}>{i18n('filters.same_as_predicted')}</div>
							</label>
						</div>
						<div className={filterCssClassName}>
							<a
								href={'javascript:void(0);'}
								className={classnames([
									filterLinkCssClassName,
									(this.state.isLoading || this.state.isRemoving) && `${filterLinkCssClassName}__m-disabled`,
								])}
								onClick={this._handleResetFiltersClick}
							>{i18n('filters.reset')}</a>
						</div>
					</div>
					<div className={contentCssClassName}>
						{this._renderGrid()}
						{this._renderStatus()}
					</div>
					<div className={dividerCssClassName} />

					<div className={buttonsCssClassName}>
						<div className={buttonsSecondaryCssClassName}>
							{
								aclService.checkPermission(USER_PERMISSION__DOWNLOAD_IMAGE) &&
								<Button
									theme={Button.AVAILABLE_THEMES.SECONDARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									disabled={this.state.isLoading || !shouldShowDownloadButton}
									href={shouldShowDownloadButton ? this._getFirstImageUrlToDownload() : ''}
									download
									title={i18nShared('buttons.download')}
								>
									{i18nShared('buttons.download')}
								</Button>
							}
							{ aclService.checkPermission(USER_PERMISSION__DELETE_IMAGE) &&
							<Button
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								title={i18nShared('buttons.delete')}
								disabled={this.state.isLoading || !shouldShowDownloadButton}
								onClick={this._handleRemoveButtonClick}
							>
								{i18nShared('buttons.delete')}
							</Button>
							}
						</div>
						<div className={buttonsMainCssClassName}>
							{getFooterCloseButton(dialogInterface)}
						</div>
					</div>
				</div>
			</PopupDialogContentWithoutPadding>
		);
	}

	render () {
		const { collectionName, owner } = this.props;
		return (
			<PopupDialog
				headerProps={{
					title: collectionName ? `Collection "${collectionName}"`: 'Registry',
					icon: PopupDialogHeader.icons.LIST,
					buttons: [
						(
							<Chip key={'owner'} label={owner} />
						),
					],
				}}
				content={this._renderContent}
				popupProps={{
					onClose: this._handleClose,
				}}
			/>
		);
	}
}
