import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import momentUtils from '../../../../appUtils/momentUtils';

import PopupDialog from '../../../../components/PopupDialog';
import Datepicker, { getMaxDate, getMinDate } from '../../../../components/Datepicker';
import Button from '../../../../components/Button';

import './styles/ResolveAddLabelDate.css';


const baseCssClassname = 'resolve-add-label-date';


export default class ResolveAddLabelDate extends Component {
	static propTypes = {
		date: PropTypes.string,
	}
	
	constructor (props, context) {
		super(props, context);
		
		this.state = {
			isDatepickerOpened: false,
		};
	}
	
	_handleDateChange = (date) => {
		this.props.onChange(momentUtils.getMomentForDate({ date }).format('YYYY-MM-DD'));
		this.setState({
			isDatepickerOpened: false,
		});
	}
	
	_handleClick = () => {
		this.setState({
			isDatepickerOpened: true,
		});
	}
	
	_renderDatepicker () {
		if ( !this.state.isDatepickerOpened ) {
			return null;
		}
		
		const {
			date,
		} = this.props;
		
		return (
			<PopupDialog
				headerProps={{
					title: 'Select date',
				}}
				content={(
					<Datepicker
						selectedDays={date ? [ momentUtils.moment({ args: [ date ]}).toDate() ] : null}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						onDayClick={this._handleDateChange}
					/>
				)}
				footerProps={{
					buttons: ({ popup: { close } }) => {
						return [
							(
								<Button
									key={'popup_cancel'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={close}
								>{'Cancel'}</Button>
							),
						];
					},
				}}
				popupProps={{
					onClose: () => {
						this.setState({
							isDatepickerOpened: false,
						});
					},
				}}
			/>
		);
	}
	
	render () {
		const { date } = this.props;
		
		return (
			<Fragment>
				<div
					className={baseCssClassname}
					onClick={this._handleClick}
				>{date ? momentUtils.moment({ args: [ date ]}).format('DD/MM/YYYY') : '-'}</div>
				{this._renderDatepicker()}
			</Fragment>
		);
	}
}
