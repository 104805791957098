import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStorageService, setStorageService, Storage } from '../../../../services/storage';

import editorActions from '../../../../actions/editorActions';
import editorSelectors from '../../../../selectors/editorSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';


const ResolverStorage = (props) => {
	const [ imageBrightness, setImageBrightness ] = useState(props.imageBrightness);
	const [ imageContrast, setImageContrast ] = useState(props.imageContrast);

	useEffect(() => {
		if ( getStorageService() !== null ) {
			const storage = getStorageService();
			if ( imageBrightness !== props.imageBrightness ) {
				storage.set(`${props.currentCollectionId}.imageBrightness`, props.imageBrightness);
				setImageBrightness(props.imageBrightness);
			}

			if ( imageContrast !== props.imageContrast ) {
				storage.set(`${props.currentCollectionId}.imageContrast`, props.imageContrast);
				setImageContrast(props.imageContrast);
			}
		}
	}, [ props.imageBrightness, props.imageContrast ]);

	useEffect(() => {
		if ( getStorageService() === null ) {
			setStorageService(new Storage('dentiai__app'));
		}
	}, []);

	useEffect(() => {
		const storage = getStorageService();
		const imageBrightness = storage.get(`${props.currentCollectionId}.imageBrightness`);
		const imageContrast = storage.get(`${props.currentCollectionId}.imageContrast`);
		const data = {};

		if ( typeof imageBrightness === 'number' ) {
			data.imageBrightness = imageBrightness;
		}
		if ( typeof imageContrast === 'number' ) {
			data.imageContrast = imageContrast;
		}

		if ( Object.keys(data).length > 0 ) {
			props.onSetData(data);
		}
	}, [ props.currentImageId ]);

	return null;
};

ResolverStorage.propTypes = {
	imageBrightness: PropTypes.number.isRequired,
	imageContrast: PropTypes.number.isRequired,
	currentCollectionId: PropTypes.string.isRequired,
	currentImageId: PropTypes.string.isRequired,
	onSetData: PropTypes.func.isRequired,
};


export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);
		return {
			imageBrightness: editorData.imageBrightness,
			imageContrast: editorData.imageContrast,
			currentCollectionId: editorData.currentCollectionHashName,
			currentImageId: editorData.currentImageId,
		};
	},
	(dispatch) => ({
		onSetData: (data) => dispatch(editorActions.updateData({
			data,
		})),
	}),
)(ResolverStorage);
