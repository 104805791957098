import apiActionTypes from '../actions/apiActionTypes';
import collectionsActionTypes from '../actions/collectionsActionTypes';


const initialState = {
	collections: {},
	orderedCollections: [],
	syncedCollections: {},
	currentCollection: null,
};


export default function collectionsReducer (state = initialState, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.collections || state );

		case collectionsActionTypes.ACTION_COLLECTIONS__SET_COLLECTIONS:
			return {
				...state,
				collections: action.payload.collections,
				orderedCollections: action.payload.orderedCollections,
			};

		case collectionsActionTypes.ACTION_COLLECTIONS__UPDATE_COLLECTION:
			return {
				...state,
				collections: {
					...state.collections,
					[action.payload.collectionId]: action.payload.collection,
				},
			};

		case collectionsActionTypes.ACTION_COLLECTIONS__MARK_AS_SYNCED:
			return {
				...state,
				syncedCollections: {
					...state.syncedCollections,
					[action.payload]: true,
				},
			};
		case collectionsActionTypes.ACTION_COLLECTIONS__SET_CURRENT_COLLECTION:
			return {
				...state,
				currentCollection: action.payload,
			};

		default:
			return state;
	}
}
