import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gridUtils from '../../../../appUtils/gridUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import userSelectors from '../../../../selectors/userSelectors';

import Grid from '../../../../components/Grid';

import './styles/HuddleGrid.css';


const baseCssClassName = 'huddle-grid';
const lastImageCssClassName = `${baseCssClassName}-last-image`;
const lastImageDateCssClassName = `${lastImageCssClassName}__date`;
const lastImageSummaryCssClassName = `${lastImageCssClassName}__summary`;

const lastVisitSummaryCssClassName = `${baseCssClassName}-last-visit-summary`;
const lastVisitSummaryNameCssClassName = `${lastVisitSummaryCssClassName}__name`;
const lastVisitSummaryLabelCssClassName = `${lastVisitSummaryCssClassName}__label`;
const lastVisitSummaryLabelNameCssClassName = `${lastVisitSummaryCssClassName}__label-name`;

const getGridContentRowKey = (rowData, index) => index;

const noop = () => {};

const getLstVisitSummary = (lastVisit, lastImages, notationType) => {
	if ( !Array.isArray(lastVisit) || lastVisit.length === 0 ) {
		return null;
	}

	return lastVisit
		.filter((summary) => !(summary.class_id || '').includes('bone_loss'))
		.map((summary, i) => {
			return (
				<div
					key={i}
					className={lastVisitSummaryCssClassName}
					style={{ backgroundColor: summary.color }}
				>
					<div className={lastVisitSummaryNameCssClassName}>
						{summary.display_name}
					</div>
					<div className={lastVisitSummaryLabelCssClassName} style={{ padding: 0 }} />
					{(summary.type !== 'due_periochart' && summary.type !== 'due_radiograph' && summary.items || [])
						.sort((a, b) => teethUtils.sortTeeth(teethUtils.getLocalizedToothKey({ toothKey: a.tooth, notationType }),teethUtils.getLocalizedToothKey({ toothKey: b.tooth, notationType })))
						.map((item, i) => {
							const probability = typeof item.probability === 'number'
								? `${Math.floor(item.probability * 100)}%`
								: '-';

							return (
								<a
									key={i}
									href={`/collections/${lastImages.col_hashname}/visit/${lastImages.date}/tfv/${item.tooth}`}
									className={lastVisitSummaryLabelCssClassName}
									target={'_blank'}
								>
									<span className={lastVisitSummaryLabelNameCssClassName}>
										{teethUtils.getLocalizedToothKey({ toothKey: item.tooth, notationType })}
									</span>: {probability}
								</a>
							);
						})}
				</div>
			);
	}).filter(Boolean);
}


class HuddleGrid extends Component {
	static propTypes = {
		data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		notationType: PropTypes.string.isRequired,
	};

	_getColumns () {
		return [
			gridUtils.makeGridColumn({
				key: 'time',
				width: '10%',
				headerValue: 'Time',
				getValue: (rowData) => rowData.time,
			}),
			gridUtils.makeGridColumn({
				key: 'patient',
				width: '10%',
				headerValue: 'Patient',
				getValue: (rowData) => rowData.patient_name,
			}),
			gridUtils.makeGridColumn({
				key: 'op',
				width: '10%',
				headerValue: 'OP#',
				getValue: (rowData) => rowData.operatory,
			}),
			gridUtils.makeGridColumn({
				key: 'images',
				width: '10%',
				headerValue: 'Images',
				getValue: (rowData) => {
					if (
						typeof rowData.last_images !== 'object' ||
						rowData.last_images === null ||
						rowData.last_images.date === null
					) {
						return null;
					}

					const lastImages = rowData.last_images;

					return (
						<a href={`/collections/${lastImages.col_hashname}/image/${lastImages.image_hashname}/fmx`} className={lastImageCssClassName} target={'_blank'}>
							<div className={lastImageDateCssClassName}>{lastImages.date}</div>
							{lastImages.image_summary.length > 0 && (
								<div className={lastImageSummaryCssClassName}>
									{lastImages.image_summary.join(', ')}
								</div>
							)}
						</a>
					);
				},
			}),
			gridUtils.makeGridColumn({
				key: 'findings',
				width: '40%',
				headerValue: 'Findings',
				getValue: (rowData) => {
					const summary = Object.keys(rowData.last_visit_summary);

					if ( summary.length === 0 ) {
						return null;
					}

					// const lastExaminationSummary = summary.map((key) => (
					// 	<div key={key} className={'huddle-grid__label'}>
					// 		{key} <div className={'huddle-grid__label-inner'}>{rowData.last_examination_summary[key]}</div>
					// 	</div>
					// ));

					return (
						<>
							{getLstVisitSummary(rowData.last_visit_summary, rowData.last_images || {}, this.props.notationType)}
						</>
					);
				},
			}),
			gridUtils.makeGridColumn({
				key: 'provider',
				width: '20%',
				headerValue: 'Provider',
				getValue: (rowData) => rowData.provider,
			}),
		];
	}

	render () {
		return (
			<Grid
				cssClassName={baseCssClassName}
				minWidth={800}
				columns={this._getColumns()}
				data={this.props.data}
				headerRowHeight={37}
				contentRowHeight={'auto'}
				getContentRowKey={getGridContentRowKey}
				getContentRowModifiers={noop}
			/>
		);
	}
}

export default connect(
	(state) => ({
		notationType: userSelectors.selectUserData(state).notation_type,
	}),
	() => {}
)(HuddleGrid);
