import React from 'react';
import PropTypes from 'prop-types';
import lodashValues from 'lodash/values';

import './styles/PopupDialogHeader.css';


const baseCssClassName = 'popup-dialog-header';
const titleCssClassName = `${baseCssClassName}__title`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonContainerCssClassName = `${baseCssClassName}__button-container`;
const closeButtonCssClassName = `${baseCssClassName}__close-button`;
const iconCssClassName = `${baseCssClassName}__icon`;


function getHeaderButtonContainer (content, props) {
	return (
		<div {...props} className={buttonContainerCssClassName}>
			{content}
		</div>
	);
}

function getHeaderCloseButton ({ popup: { close } }) {
	return getHeaderButtonContainer((
		<div key={'close_button'} className={closeButtonCssClassName} onClick={close}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z" fill="white"/>
			</svg>
		</div>
	), { key: 'close_button' });
}

const ICONS = {
	SUCCESS: 'success',
	ERROR: 'error',
	LIST: 'list',
};


/**
 * @typedef {Object} PopupDialogHeaderProps
 *
 * @property {string} title
 * @property {string} icon
 * @property {function(PopupInterface):Array<ReactElement>} [buttons]
 * @property {PopupDialogInterface} [dialogInterface]
 */

const propTypes = {
	title: PropTypes.node.isRequired,
	icon: PropTypes.oneOf(lodashValues(ICONS)),
	
	buttons: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.arrayOf(PropTypes.node),
	]),

	dialogInterface: PropTypes.object,
};

/**
 * @param {PopupDialogHeaderProps} props
 * @returns {ReactElement}
 */
function PopupDialogHeader (props) {
	const icon = props.icon
		? ( <div className={`${iconCssClassName} ${iconCssClassName}__m-${props.icon}`} /> )
		: null;

	if ( props.title === null ) {
		return null;
	}
	
	return (
		<div className={baseCssClassName}>
			{icon}
			<div className={titleCssClassName} title={props.title}>{props.title}</div>
			<div className={buttonsCssClassName}>
				{(
					typeof props.buttons === 'function'
						? props.buttons(props.dialogInterface)
						: props.buttons
				)}
			</div>
		</div>
	);
}

PopupDialogHeader.icons = ICONS;
PopupDialogHeader.propTypes = propTypes;

export default PopupDialogHeader;

export {
	getHeaderButtonContainer,
	getHeaderCloseButton,
};
