import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DRAGGING_STATUS } from '../../../../components/Canvas/CanvasConstants';

import mainConfig from '../../../../configs/mainConfig';
import imageUtils from '../../../../appUtils/imageUtils';

import { setCanvas, setViewportData } from '../../../../services/canvas';
import { events } from '../../../../services/events';
import { message } from '../../../../services/popup';
import { trackEvent } from '../../../../integrations/mixpanel';

import labelsActions from '../../../labels/actions/labelsActions';
import { removeLabelShape } from '../../actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';

import Alignment from '../../../../components/Alignment';
import Loading from '../../../../components/Loading';
import Popup from '../../../../components/Popup';
import Viewport from '../../../../components/Canvas/Viewport';
import CanvasCollectionImageLoader from '../../../../components/Canvas/CanvasCollectionImageLoader';
import UiAttachedImage from '../../../../components/MagnifyingGlass/UiAttachedImage';
import MagnifyingGlassImage from '../../../../components/MagnifyingGlass/MagnifyingGlassImageConnector';
import MagnifyGlassCanvas from '../../../../components/MagnifyingGlass/MagnifyGlassCanvas';
import SizeDetector from '../../../../components/SizeDetector';
import AddShapeWizard from '../../../../components/AddShapeWizard';
import CanvasEditorConnector from '../../../../components/Canvas/CanvasEditorConnector';
import CanvasGrid from '../../../../components/Canvas/CanvasGrid';
import CanvasObjects from '../../../../components/ImageShapes/CanvasObjects';
import ImageEditorShapes from './ImageEditorShapes';
import ToothActions from '../tooth-actions';

import { getDictionary } from '../../../../appUtils/locale';


const PROGRESS_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	FAILED: 'failed',
	DONE: 'done',
};

const i18nShared = getDictionary('shared');


class ResolverImageEditor extends Component {
	static propTypes = {
		imageHashName: PropTypes.string.isRequired,
		editorMode: PropTypes.string.isRequired,
		showBoneLossStages: PropTypes.bool.isRequired,
		showCanvasGrid: PropTypes.bool.isRequired,
		showFindingsOnImage: PropTypes.bool.isRequired,
		shouldDisplayTeethNumbers: PropTypes.bool.isRequired,
		allowShiftTooth: PropTypes.bool.isRequired,
		allowRemoveTooth: PropTypes.bool.isRequired,
		enhancedImageFilter: PropTypes.bool.isRequired,
		onShiftTooth: PropTypes.func.isRequired,
		onLabelShapeRemove: PropTypes.func.isRequired,
	};

	state = {
		teethMoveStatus: PROGRESS_STATUS.IDLE,
		findingRemoveStatus: PROGRESS_STATUS.IDLE,
	};

	componentDidMount () {
		events.on('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
		trackEvent('TP image opened', {
			hashname: this.props.imageHashName,
		});
	}

	componentWillUnmount () {
		events.off('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
	}

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_moveTeeth (toothKey, nextToothKey, imageHashName) {
		return this.props.onShiftTooth({
			toothKey,
			nextToothKey,
			imageHashName,
		});
	}

	_removeShape (params) {
		this.setState({
			findingRemoveStatus: PROGRESS_STATUS.IN_PROGRESS,
		});
		this.props.onLabelShapeRemove(params)
			.then(() => {
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'Finding is not removed. Please try again.',
				});
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			});
	}

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_handleMoveTeeth = (toothKey, nextToothKey, imageHashName) => {
		this._moveTeeth(toothKey, nextToothKey, imageHashName);
	};

	/**
	 * @param {string} toothKey
	 * @param {string} imageHashName
	 * @private
	 */
	_handleRemoveTooth = ({ toothKey, imageHashName }) => {
		this._removeShape({
			toothKey,
			imageHashName,
		});
	};

	_handleTeethShiftStatusChanged = (teethMoveStatus) => {
		this.setState({
			teethMoveStatus,
		});
	};

	_renderLayouts () {
		if ( this.state.teethMoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The image tooth is shifting, please wait.',
						}}
					/>
				</Popup>
			);
		}
		else if ( this.state.findingRemoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The finding is removing, please wait.',
						}}
					/>
				</Popup>
			);
		}

		return null;
	}

	render () {
		const {
			editorMode,
		} = this.props;

		const canShowImageShapes = (
			this.props.showFindingsOnImage === true &&
			(
				editorMode === mainConfig.EDITOR_MODE__SELECT_MODE ||
				editorMode === mainConfig.EDITOR_MODE__EDIT_MODE
			)
		);

		const canShowAddWizard = (
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE ||
			editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH
		);

		return (
			<div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
				<SizeDetector>
					{({ width: containerWidth, height: containerHeight }) => (
						<CanvasCollectionImageLoader image={this.props.image} key={this.props.image.hashname} useEnhancedImage={this.props.enhancedImageFilter}>
							{({ status, image, retryLoad }) => {
								switch (status) {
									case CanvasCollectionImageLoader.STATUSES.FAILED:
										return (
											<Alignment
												horizontal={Alignment.horizontal.CENTER}
												vertical={Alignment.vertical.CENTER}
											>
													<span
														style={{ color: '#fff', fontSize: 12, cursor: 'pointer' }}
														onClick={retryLoad}
													>Reload image</span>
											</Alignment>
										);

									case CanvasCollectionImageLoader.STATUSES.LOADED: {
										const imageWidth = image.naturalWidth;
										const imageHeight = image.naturalHeight;
										return (
											<Viewport
												canvasWidth={imageWidth}
												canvasHeight={imageHeight}
												viewportWidth={containerWidth}
												viewportHeight={containerHeight}
												onGetZoom={(zoom) => {
													if ( zoom !== null ) {
														return Math.min(
															6,
															Math.max(0.1, zoom)
														);
													}
													else {
														return imageUtils.getDefaultZoom({
															canvasWidth: containerWidth,
															canvasHeight: containerHeight,
															imageWidth,
															imageHeight,
														});
													}
												}}
												onGetInitialPosition={({ zoom }) => ({
													x: (containerWidth / 2) - ((imageWidth * zoom) / 2),
													y: (containerHeight / 2) - ((imageHeight) * zoom / 2),
												})}
											>
												{(canvasApi) => {
													if ( canvasApi.viewport === null ) {
														return null;
													}

													setCanvas(canvasApi.viewport);
													setViewportData({
														zoom: canvasApi.zoom,
														offsetX: -canvasApi.offsetX,
														offsetY: -canvasApi.offsetY,
													});

													return (
														<Fragment>
															<CanvasEditorConnector canvasApi={canvasApi} />
															<MagnifyGlassCanvas
																zoom={canvasApi.zoom}
																draggingStatus={canvasApi.draggingStatus}
															/>
															<MagnifyingGlassImage
																image={image}
																width={imageWidth * canvasApi.zoom}
																height={imageHeight * canvasApi.zoom}
																sharpen={this.props.enhancedImageFilter ? !this.props.image.enhanced_image_url : undefined}
																View={UiAttachedImage}
															/>
															{this.props.showCanvasGrid === true && (
																<CanvasGrid
																	width={imageWidth}
																	height={imageHeight}
																	zoom={canvasApi.zoom}
																/>
															)}
															{canShowImageShapes === true && (
																<CanvasObjects
																	zoom={canvasApi.zoom}
																	isSelectable={this.props.image.isOwn === true}
																	isVisible={canvasApi.draggingStatus === DRAGGING_STATUS.IDLE}
																>
																	{(canvasObjectsApi) => {
																		return (
																			<ImageEditorShapes
																				canvasApi={canvasApi}
																				canvasObjectsApi={canvasObjectsApi}
																				selectedObject={canvasObjectsApi.selectedObject}
																				closestObjectToPoint={canvasObjectsApi.closestObjectToPoint}
																				isSelectedObjectInTransformation={canvasObjectsApi.isSelectedObjectInTransformation}
																				imageWidth={imageWidth}
																				imageHeight={imageHeight}
																				canEditShapes={this.props.image.isOwn === true}
																			/>
																		);
																	}}
																</CanvasObjects>
															)}
															{this.props.showFindingsOnImage === true && this.props.shouldDisplayTeethNumbers === true && (
																<ToothActions
																	zoom={canvasApi.zoom}
																	imageHashName={this.props.imageHashName}
																	allowShifting={this.props.allowShiftTooth}
																	allowRemoving={this.props.allowRemoveTooth}
																	onShiftTooth={this._handleMoveTeeth}
																	onRemoveTooth={this._handleRemoveTooth}
																/>
															)}
															{canShowAddWizard === true && (
																<AddShapeWizard
																	canvasApi={canvasApi}
																	zoom={canvasApi.zoom}
																	imageOriginalWidth={imageWidth}
																	imageOriginalHeight={imageHeight}
																/>
															)}
														</Fragment>
													);
												}}
											</Viewport>
										);
									}

									default:
										return (
											<Alignment
												horizontal={Alignment.horizontal.CENTER}
												vertical={Alignment.vertical.CENTER}
											>
												<Loading />
											</Alignment>
										);
								}
							}}
						</CanvasCollectionImageLoader>
					)}
				</SizeDetector>
				{this._renderLayouts()}
			</div>
		);
	}
}

export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImage = imagesSelectors.selectImageById(state, {
			id: editorSelectors.selectCurrentImageId(state),
		});
		const user = userSelectors.selectUserData(state);
		return {
			imageHashName: currentImage.hashname,
			editorMode: editorData.editorMode,
			showBoneLossStages: editorData.showBoneLossStages,
			showCanvasGrid: editorData.showCanvasGrid,
			showFindingsOnImage: (
				editorData.showFindingsOnImage &&
				editorData.notAnalyzedImages.includes(currentImage.id) === false
			),
			allowShiftTooth: currentImage.isOwn === true,
			allowRemoveTooth: currentImage.isOwn === true,
			shouldDisplayTeethNumbers: user.is_simple_ui_enabled !== true,
			enhancedImageFilter: editorData.enhancedImageFilter,
		};
	},
	(dispatch) => ({
		onShiftTooth: (data) => {
			trackEvent('TP Shift Tooth', data);
			return dispatch(labelsActions.shiftTooth(data));
		},
		onLabelShapeRemove: (data) => {
			trackEvent('TP Shape Remove', data);
			return dispatch(removeLabelShape(data));
		},
	})
)(ResolverImageEditor);
