export default {
	'buttons.next_history.alt': 'Next history state',
	'buttons.next_history.text': 'Redo',
	'buttons.prev_history.alt': 'Previous history state',
	'buttons.prev_history.text': 'Undo',
	'buttons.next_image.title': 'Next image',
	'buttons.next_image.text': 'Next',
	'buttons.prev_image.alt': 'Previous image',
	'buttons.prev_image.text': 'Prev',
	'buttons.first_image.alt': 'First image',
	'buttons.last_image.alt': 'Last image',
};
