export default {
	'grid.header.image_id': 'ID снимка',
	'grid.header.image_date': 'Дата снимка',
	'grid.header.patient_id': 'ID пациента',
	'grid.header.birthday': 'Дата рождения (Возраст)',
	'grid.header.gender': 'Пол',
	'grid.header.filename': 'Имя файла',
	'grid.header.last_opening': 'Последнее открытие',
	'grid.header.last_editing': 'Последнее редактирование',
	'grid.header.image_type': 'Тип снимка',
	'incorrect_patient_id': 'Только цифры',
	'no_images': 'У вас еще нет снимков',
	'dialog.title': 'Реестр',
};
