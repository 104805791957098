import React from 'react';
import PropTypes from 'prop-types';

import mainConfig from '../../configs/mainConfig';

import PopupDialog, { getFooterCloseButton, PopupDialogContentText } from '../PopupDialog';

import { getDictionary } from '../../appUtils/locale';


const i18n = getDictionary('image-uploading-form');

const propTypes = {
	onClose: PropTypes.func.isRequired,
};


function ImageUploadingWrongExtension (props) {
	return (
		<PopupDialog
			headerProps={{
				title: i18n('dialog.title'),
			}}
			content={(dialogInterface) => (
				<PopupDialogContentText>
					<div
						dangerouslySetInnerHTML={{
							__html: i18n('wrong_extension.dialog.content').replace('{formats}', mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS.join(', ')),
						}}
					/>
				</PopupDialogContentText>
			)}
			footerProps={{
				buttons: (dialogInterface) => {
					return getFooterCloseButton(dialogInterface);
				},
			}}
			popupProps={{
				onClose: () => {
					props.onClose();
				},
			}}
		/>
	);
}

ImageUploadingWrongExtension.propTypes = propTypes;

export default ImageUploadingWrongExtension;
