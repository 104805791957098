const SIZE_REG_EXP = /^([\d]*\.?[\d]+)(px|%)$/;

export const UNIT_PIXEL = 'px';
export const UNIT_PERCENT = '%';

/**
 * Parses given value to size object.
 *
 * @example
 * parseSize(10) // { value: 10, unit: 'px' }
 * parseSize('10.3px') // { value: 11, unit: 'px' }
 * parseSize('10.3%') // { value: 10.3, unit: '%' }
 *
 * @param {number|string} size
 * @returns {{ value: number, unit: string }}
 * @throws {Error}
 */
export function parseSize (size) {
	if (
		(
			typeof size !== 'number' &&
			typeof size !== 'string'
		) ||
		(
			typeof size === 'number' &&
			size < 0
		) ||
		(
			typeof size === 'string' &&
			!SIZE_REG_EXP.test(size)
		)
	) {
		throw new Error(`parseSize: Argument 'size' is incorrect.`);
	}

	if ( typeof size === 'number' ) {
		return {
			value: size,
			unit: UNIT_PIXEL,
		};
	}

	const matches = size.match(SIZE_REG_EXP);

	const unit = matches[2];
	const value = parseFloat(matches[1]);

	return {
		value,
		unit,
	};
}
