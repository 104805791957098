import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';
import { setLocale } from '../../appUtils/locale';
import { identifyUser } from '../../appUtils/errors';
import commonUtils from '../../appUtils/common';

import aclService from '../../services/acl';
import { initFeedback } from '../../services/feedback';
import { message } from '../../services/popup';
import { getStorageService } from '../../services/storage';
import { initMixPanel, getMixPanel, trackEvent } from '../../integrations/mixpanel';

import {
	USER_PERMISSION__CHANGE_IMAGE,
	USER_PERMISSION__SHARE_IMAGE,
	USER_PERMISSION__LIST_COLLECTIONS,
} from '../../constants/userPermissionsConstants';

import userActions from '../../actions/userActions';

import userSelectors from '../../selectors/userSelectors';

import { Route, Switch } from 'react-router-dom';
import Editor from '../Editor';
import Report from '../Report';
import LoginMessage from './LoginMessage';
import { Resolver, FmxFrontController } from '../../modules/resolver';
import TreatmentPlanCollection from '../../modules/resolver/components/collection';
import TreatmentPlanReport from '../../modules/resolver/components/report';
import TfvEntry from '../../modules/tfv/components/entry';


class Authorization extends Component {
	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		user: PropTypes.object,
		onUpdateUserProfile: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	constructor(props, context) {
		super(props, context);

		this._store = context.store;
		this._storeUnsubscribeCallback = null;

		this.state = {
			isChecked: false,
		};
	}

	UNSAFE_componentWillReceiveProps () {
		this._checkAuth();
	}

	componentDidMount () {
		this._checkAutoLogin();
		this._storeUnsubscribeCallback = this._store.subscribe(this._handleStoreChange);
	}

	componentWillUnmount () {
		this.state = null;

		if ( this._storeUnsubscribeCallback ) {
			this._storeUnsubscribeCallback();
			this._storeUnsubscribeCallback = null;
		}
	}

	_checkAutoLogin () {
		if ( !this.props.isAuthenticated ) {
			this._store.dispatch(userActions.autoSignIn())
				.then(() => {
					if ( !this.state ) {
						return;
					}

					if ( this.props.user.language ) {
						setLocale(this.props.user.language);
					}

					identifyUser(this.props.user);

					const permissions = {
						...this.props.user.permissions,
					};

					if ( this.props.user.uses_computer_aided_device_ui === true ) {
						permissions[USER_PERMISSION__SHARE_IMAGE] = false;
						permissions[USER_PERMISSION__CHANGE_IMAGE] = false;
					}

					if ( permissions[USER_PERMISSION__LIST_COLLECTIONS] !== false ) {
						permissions[USER_PERMISSION__LIST_COLLECTIONS] = true;
					}

					this.props.user.permissions = permissions;
					aclService.setPermissions(permissions);

					if ( this.props.user.show_feedback_popup ) {
						initFeedback(this._store);
					}

					if ( getRuntimeConfig().mixpanel.enabled ) {
						initMixPanel();
						const mixpanel = getMixPanel();
						mixpanel.identify(this.props.user.username);

						const storage = getStorageService();

						if ( storage.get('mixpanel_sign_in_event') !== true ) {
							trackEvent('Sign In', { user: this.props.user });
							storage.set('mixpanel_sign_in_event', true);
						}

						// if ( storage.get('mixpanel_dtr_params_event') !== true ) {
						// 	const userApiParams = commonUtils.getUserApiParams();
						// 	if ( Object.keys(userApiParams).length > 0 ) {
						// 		trackEvent('DTR params', { params: userApiParams });
						// 		storage.set('mixpanel_dtr_params_event', true);
						// 	}
						// }
					}
					
					this.setState({
						isChecked: true,
					});
				})
				.catch((error) => {
					// todo display error
					if ( !this.state ) {
						return;
					}
					
					this.setState({
						isChecked: true,
					});
				});
		}
		else {
			this.setState({
				isChecked: true,
			});
		}
	}

	_checkAuth () {
		if (this.state.isChecked && this.props.isAuthenticated) {
			this.forceUpdate();
		}
	}

	_shouldShowLoginMessage () {
		return (this.props.isAuthenticated === true && this.props.user.has_accepted_terms_of_use === false);
	}

	_handleStoreChange = () => {
		if ( !this._storeUnsubscribeCallback ) {
			return;
		}

		// console.dir(lodashCloneDeep(this._store.getState()));
		console.dir(this._store.getState());
	};

	_handleShowLoginMessageAgreeButtonClick = () => {
		this.props.onUpdateUserProfile({
			data: {
				has_accepted_terms_of_use: true,
			},
		})
			.catch(() => {
				if ( !this.state ) {
					return;
				}

				message({
					title: 'Error',
					titleIcon: 'error',
					message: 'An error occurred while saving user data. Please try again',
				});
			});
	};

	render () {
		if ( !this.state.isChecked ) {
			return null;
		}

		if ( this._shouldShowLoginMessage() === true ) {
			return (
				<LoginMessage
					onAgreeButtonClick={this._handleShowLoginMessageAgreeButtonClick}
				/>
			);
		}

		return (
			<Switch>
				<Route path={'/shared/:imageId/report'} component={Report}/>
				<Route path={'/collections/:collectionId/image/:imageId/report'} component={Report} />
				<Route path={'/collections/:collectionId/image/:imageId/treatment_plan/report'} component={TreatmentPlanReport} />
				<Route path={'/collections/:collectionId/image/:imageId/treatment_plan'} component={Resolver} />
				<Route path={'/collections/:collectionId/image/:imageId/fmx'} component={FmxFrontController} />
				<Route path={'/collections/:collectionId/visit/:visit/tfv/:tooth'} component={TfvEntry} />
				<Route path={'/collections/:collectionId/treatment_plan'} component={TreatmentPlanCollection} />
				<Route path={'/'} component={Editor} />
			</Switch>
		);
	}
}


export default connect(
	(state) => {
		return {
			isAuthenticated: userSelectors.selectIsAuthenticated(state),
			user: userSelectors.selectUserData(state),
		};
	},
	(dispatch) => ({
		onUpdateUserProfile: (data) => dispatch(userActions.updateProfile(data)),
	}),
)(Authorization);
