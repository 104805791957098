import React from 'react';
import { connect } from 'react-redux';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import { USER_PERMISSION__VIEW_IMAGE } from '../../constants/userPermissionsConstants';
import aclService from '../../services/acl';

import CurrentImageInfo from '../CurrentImageInfo';


import './styles/TopPane.css';



const baseCssClassName = 'top-pane';
const currentImageInfoCssClassName = `${baseCssClassName}__current-image-info`;


function TopPane ({ currentImage }) {
	const shouldDisplayInfo = (
		aclService.checkPermission(USER_PERMISSION__VIEW_IMAGE)
		&& currentImage
		&& currentImage.isOwn
	);

	return (
		<div className={baseCssClassName}>
			<div className={currentImageInfoCssClassName}>
				{ shouldDisplayInfo && (
					<CurrentImageInfo
						panelFields={[ 'patient_id', 'image_date' ]}
						dropdownFields={[ 'image_hash', 'image_type', 'gender', 'birthday', 'age' ]}
					/>
				)}
			</div>
		</div>
	)
}

export default connect((state) => {
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	return {
		currentImage: imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}),
	}
})(TopPane)
