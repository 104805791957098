import React, { Component } from 'react';

import { connect } from 'react-redux';
import editorSelectors from '../../selectors/editorSelectors';
import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';
import userSelectors from "../../selectors/userSelectors";

import UploadButton from '../ImageControls/UploadButtonConnector';

import { Link } from 'react-router-dom';

import { getDictionary } from '../../appUtils/locale';

import { USER_PERMISSION__UPLOAD_IMAGE, USER_PERMISSION__LIST_COLLECTIONS } from '../../constants/userPermissionsConstants';
import aclService from '../../services/acl';

import '../ImageControls/styles/ImageControls.css';
import './styles/CollectionControls.css';


const i18n = getDictionary('image-controls');

const baseCssClassName = 'image-controls';

const buttonCssClassName = `${baseCssClassName}__button`;


class CollectionControls extends Component {
	render () {
		return (
			<div className={baseCssClassName}>
				{aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
					<Link to={'/'}>
						<button
							className={`${buttonCssClassName} ${buttonCssClassName}__m-collections`}
							title={i18n('buttons.collections')}
						/>
					</Link>
				)}
				{aclService.checkPermission(USER_PERMISSION__UPLOAD_IMAGE) && <UploadButton /> }
				{this.props.isRegistryAvailable && <Link to={{
					pathname: `${this.props.currentCollectionId}/registry`,
					state: {modal: true}
				}}>
					<button
						className={`${buttonCssClassName} ${buttonCssClassName}__m-open`}
						title={i18n('buttons.open_registry')}
					/>
				</Link>
				}
			</div>

		);
	}
}

const mapStateToProps = (state) => ({
	currentImageId: editorSelectors.selectCurrentImageId(state),
	currentCollectionId: editorSelectors.selectEditor(state).currentCollectionHashName,
	isRegistryAvailable: userSelectors.selectUsesReaderStudyModeUi(state) !== true,
});

export default connect(mapStateToProps)(CollectionControls);
