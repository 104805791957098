import { getStore } from '../../../services/store';

import { CONFIDENCE_PERCENT_STORAGE_NAME } from '../constants';

import teethUtils from '../../../appUtils/teeth/teethUtils';

import labelsSelectors from '../../labels/selectors/labelsSelectors';
import labelChildrenSelectors from '../../labels/selectors/labelChildrenSelectors';
import editorSelectors from '../../../selectors/editorSelectors';
import imagesSelectors from '../../../selectors/imagesSelectors';
import imagesCollectionsSelectors from '../../../selectors/imagesCollectionsSelectors';
import collectionsSelectors from '../../../selectors/collectionsSelectors';

import labelGetters from '../../labels/selectors/labelGetters';


export function getAvailableSurfaces ({ toothKey }) {
	if ( (toothKey >= 11 && toothKey <= 18) || (toothKey >= 41 && toothKey <= 48) ) {
		return [ 'd/m', 'b/f', 'l', 'm/d', 'i/o' ];
	}

	return [ 'm/d', 'b/f', 'l', 'd/m', 'i/o' ];
}

export function getLocalizedSurface (surface, toothKey) {
	const ranges = [
		{
			from: 14,
			to: 18,
			map: {
				'm/d': 'M',
				'b/f': 'B',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'O',
			},
		},
		{
			from: 44,
			to: 48,
			map: {
				'm/d': 'M',
				'b/f': 'B',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'O',
			},
		},
		{
			from: 11,
			to: 13,
			map: {
				'm/d': 'M',
				'b/f': 'F',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'I',
			},
		},
		{
			from: 41,
			to: 43,
			map: {
				'm/d': 'M',
				'b/f': 'F',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'I',
			},
		},
		{
			from: 21,
			to: 23,
			map: {
				'm/d': 'M',
				'b/f': 'F',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'I',
			},
		},
		{
			from: 31,
			to: 33,
			map: {
				'm/d': 'M',
				'b/f': 'F',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'I',
			}
		},
		{
			from: 24,
			to: 28,
			map: {
				'm/d': 'M',
				'b/f': 'B',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'O',
			},
		},
		{
			from: 34,
			to: 38,
			map: {
				'm/d': 'M',
				'b/f': 'B',
				'l': 'L',
				'd/m': 'D',
				'i/o': 'O',
			},
		},
	];
	
	toothKey = Number(toothKey);

	for (let i = 0, l = ranges.length; i < l; i++) {
		const descriptor = ranges[i];
		
		if ( descriptor.from <= toothKey && descriptor.to >= toothKey ) {
			return descriptor.map[surface];
		}
	}

	return null;
}

/**
 * Returns tooth with missing label if last exits.
 *
 * @param {string} toothKey
 * @param {Object} [options]
 * @param {string} [options.imageId]
 * @returns {null|Object}
 */
export function getMissingTooth (toothKey, options = {}) {
	const storeState = getStore().getState();
	
	const label = labelsSelectors.selectLabelByToothKey(storeState, { toothKey, ...options });
	
	if ( !label ) {
		return null;
	}
	
	const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState)[labelGetters.getLabelId(label)];
	
	if ( !labelChildren ) {
		return null;
	}
	
	let isMissingTooth = false;
	let missingLabel = null;
	
	labelChildren.forEach((childLabelId) => {
		const childLabel = labelsSelectors.selectLabelById(storeState, {
			labelId: childLabelId,
		});
		
		if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
			isMissingTooth = true;
			missingLabel = childLabel;
		}
	});
	
	if ( !isMissingTooth ) {
		return null;
	}
	
	return {
		...label,
		missingLabel,
	};
}

export function isMissingTooth (toothKey, options) {
	return getMissingTooth(toothKey, options) !== null;
}

export function setConfidencePercentToStorage (value) {
	try {
		window.localStorage.setItem(CONFIDENCE_PERCENT_STORAGE_NAME, String(value));
	}
	catch (error) {
		// do nothing
	}
}

export function getConfidencePercentToStorage (value) {
	try {
		return window.localStorage.getItem(CONFIDENCE_PERCENT_STORAGE_NAME);
	}
	catch (error) {
		// do nothing
	}
}

/**
 * @param {Object} options
 * @param {CollectionImageHashName} options.imageHashName
 *
 * @return {string}
 */
export function getExaminationByImageHashName ({ imageHashName }) {
	const storeState = getStore().getState();
	const currentCollection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	
	const image = (currentCollection.images || []).find(({ hashname }) => hashname === imageHashName);
	
	return image.examination;
}

/**
 * @param {Label} label
 */
export function getMissingToothBox (label) {
	const topTeeth = teethUtils.getOrderedTopTeeth();
	const bottomTeeth = teethUtils.getOrderedBottomTeeth();
	const storeState = getStore().getState();
	const currentImageId = editorSelectors.selectCurrentImageId(storeState);
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: currentImageId,
	});
	const toothKey = label.toothKey;
	const isTopTooth = topTeeth.includes(toothKey);

	function getClosestTeeth (toothKey, row) {
		const index = row.indexOf(toothKey);
		const leftTooth = {
			toothKey: null,
			shape: null,
			index: null,
			skipped: 0,
		};
		const rightTooth = {
			toothKey: null,
			shape: null,
			index: null,
			skipped: 0,
		};

		function getShape (toothKey) {
			const toothLabel = labelsSelectors.selectLabelByToothKey(storeState, {
				toothKey,
			});

			if ( !toothLabel ) {
				return null;
			}

			const shape = (toothLabel.image_shapes || []).filter((shapeData) => (
				shapeData.image_hashname === currentImage.hashname
			))[0] || {};
			if ( isShapeIsPresent(shape) === true && shape.type === 'box' ) {
				return {
					left: shape.startX,
					right: shape.endX,
					top: shape.startY,
					bottom: shape.endY,
				};
			}

			return null;
		}

		for (let i = 0, l = index - 1, r = index + 1; i < row.length; i++, l--, r++) {
			const leftToothKey = row[l];
			const rightToothKey = row[r];

			if ( leftToothKey !== 'undefined' && leftTooth.shape === null ) {
				const shape = getShape(leftToothKey);
				if ( shape !== null ) {
					leftTooth.shape = shape;
					leftTooth.toothKey = leftToothKey;
				}
				else {
					++leftTooth.skipped;
				}
			}

			if ( rightToothKey !== 'undefined' && rightTooth.shape === null ) {
				const shape = getShape(rightToothKey);
				if ( shape !== null ) {
					rightTooth.shape = shape;
					rightTooth.toothKey = rightToothKey;
				}
				else {
					++rightTooth.skipped;
				}
			}
		}

		return {
			left: leftTooth,
			right: rightTooth,
		};
	}

	const closestTeeth = isTopTooth === true ? getClosestTeeth(toothKey, topTeeth) : getClosestTeeth(toothKey, bottomTeeth);

	if ( closestTeeth.left.shape === null && closestTeeth.right.shape === null ) {
		return null;
	}

	let newShape;

	if ( closestTeeth.left.shape !== null && closestTeeth.right.shape !== null ) {
		if ( closestTeeth.left.skipped < closestTeeth.right.skipped ) {
			const width = (closestTeeth.left.shape.right - closestTeeth.left.shape.left);
			const left = closestTeeth.left.shape.left + width + (width * closestTeeth.left.skipped);
			newShape = {
				left,
				top: closestTeeth.left.shape.top,
				right: left + width,
				bottom: closestTeeth.left.shape.bottom,
			};
		}
		else {
			const width = (closestTeeth.right.shape.right - closestTeeth.right.shape.left);
			const left = closestTeeth.right.shape.left - (width * (closestTeeth.right.skipped || 1));
			newShape = {
				left,
				top: closestTeeth.right.shape.top,
				right: left + width,
				bottom: closestTeeth.right.shape.bottom,
			};
		}
	}
	else if ( closestTeeth.left.shape !== null ) {
		const width = (closestTeeth.left.shape.right - closestTeeth.left.shape.left);
		const left = closestTeeth.left.shape.left + width + (width * closestTeeth.left.skipped);
		newShape = {
			left,
			top: closestTeeth.left.shape.top,
			right: left + width,
			bottom: closestTeeth.left.shape.bottom,
		};
	}
	else {
		const width = (closestTeeth.right.shape.right - closestTeeth.right.shape.left);
		const left = closestTeeth.right.shape.left - (width * (closestTeeth.right.skipped || 1));
		newShape = {
			left,
			top: closestTeeth.right.shape.top,
			right: left + width,
			bottom: closestTeeth.right.shape.bottom,
		};
	}

	return newShape;

	// console.dir(bottomAvgBoxSize);
}

/**
 * @param {Object} params
 * @param {CollectionImage} params.currentImage
 * @returns {boolean}
 */
export function isSequentialModeEnabledForVisit ({ currentImage }) {
	const examination = currentImage.examination;
	const state = getStore().getState();
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;

	let result = false;
	(imagesCollectionsSelectors.selectImagesIdsByCollectionId(state, { collectionId: currentCollectionId })).forEach((imageId) => {
		const image = imagesSelectors.selectImageById(state, { id: imageId });
		if ( image.examination === examination && image.is_sequential_viewed === true ) {
			result = true;
		}
	});

	return result;
}

/**
 * @param {LabelShape} shape
 * @returns {boolean}
 */
export function isShapeIsPresent (shape) {
	return typeof shape !== 'undefined' && null !== shape && typeof shape.type === 'string' && shape.type !== 'none';
}
