export default {
	'no_collections': 'Нет коллекций',
	'add_collection': 'Добавить коллекцию',
	'edit_collection': 'Изменить коллекцию',

	'copy.title': 'Копировать коллекцию',

	'copy.labels.user': 'Пользователь',
	'copy.labels.collection_name': 'Название поллекции',
	'copy.labels.collection_description': 'Описание коллекции',
	'copy.labels.with_annotations': 'Копировать находки',
	'copy.labels.randomize': 'Случайный порядок изображений',

	'copy.buttons.copy': 'Копировать',

	'search.placeholder': 'Название коллекции',
	'search.button': 'Найти',
};
