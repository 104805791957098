import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getRuntimeConfig, setRuntimeConfig } from '../../../../appUtils/runtimeConfig';

import lodashMerge from 'lodash/merge';

import { enable as enableTeethConflictsMiddleware } from '../../../labels/middlewares/teethConflicts';

import { Route } from 'react-router-dom';
import { ResolverCollectionHoc } from '../collection';
import ResolverImageHoc from './ResolverImageHoc';
import ResolverController from '../controller';
import ResolverUploadImageHoc from '../upload-image-hoc';
import Registry from '../registry';


export default class ResolverEntry extends Component {
	static propTypes = {
		defaultFmxModeEnabled: PropTypes.bool,
		onSetFilters: PropTypes.func.isRequired,
	};

	static defaultProps = {
		defaultFmxModeEnabled: false,
	};
	
	constructor (props) {
		super(props);

		this.state = {
			findingsFilter: [],
		};

		enableTeethConflictsMiddleware();
	}

	componentWillMount () {
		const config = getRuntimeConfig();
		config.api = lodashMerge(config.api, config.treatment_plan.api);
		setRuntimeConfig(lodashMerge(config));
	}
	
	_handleFindingsFilterChange = (filter) => {
		this.props.onSetFilters(filter);
	};
	
	render () {
		return (
			<ResolverUploadImageHoc>
				<ResolverCollectionHoc>
					<ResolverImageHoc>
						{({ loadingStatus }) => {
							switch (loadingStatus) {
								case ResolverImageHoc.LOADING_STATUS.FAILED:
									return ('failed');

								case ResolverImageHoc.LOADING_STATUS.LOADED:
									return (
										<>
											<ResolverController
												defaultFmxModeEnabled={this.props.defaultFmxModeEnabled}
												imageLoadingStatus={loadingStatus}
												onFindingsFilterChange={this._handleFindingsFilterChange}
											/>
											<Route path={'/collections/:collectionId/image/:imageId/treatment_plan/registry'} component={Registry} />
										</>
									);

								case ResolverImageHoc.LOADING_STATUS.PROGRESS:
								default:
									return null;
							}
						}}
					</ResolverImageHoc>
				</ResolverCollectionHoc>
			</ResolverUploadImageHoc>
		);
	}
}
