import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import mainConfig from '../../configs/mainConfig';
import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import commonUtils from '../../appUtils/common';
import { getDictionary } from '../../appUtils/locale';
import { areFindingsMasksEnabled } from '../../appUtils/findingsMasks';

import { initService as initUploadImageService } from '../../services/upload-image';
import { get as getHelp } from '../../services/help';

import editorActions from '../../actions/editorActions';
import imagesActions from '../../actions/imagesActions';
import collectionsActions from '../../actions/collectionsActions';

import userSelectors from '../../selectors/userSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import collectionsSelectors from '../../selectors/collectionsSelectors';

import { Header, GuestHeader } from '../Header';
import CollectionsHandler from '../Collections'
import Stats from '../Stats';
import Huddle from '../../modules/huddle/ui/HuddleHoc';
import MagnifyingGlass from '../../components/MagnifyingGlass';

import Loading from '../Loading';
import Popup from '../Popup';

import Main from '../Main';
import Collection from '../Collection';
import UserMenu from './UserMenu';
import SelectToothEditor from './SelectToothEditor';
import UploadImage from '../UploadImage';

import { processKey } from './EditorUtils';

import './styles/Editor.css';


const i18n = getDictionary('editor');

const baseCssClassName = 'editor';
const headerCssClassName = `${baseCssClassName}__header`;
const mainCssClassName = `${baseCssClassName}__main`;
const currentImageAnalyzingCssClassName = `${baseCssClassName}__current-image-analyzing`;
const magnifyingGlassCssClassName = `${baseCssClassName}-magnifying-glass`;


class Editor extends Component {
	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		currentImage: PropTypes.object,
		currentImageId: PropTypes.string,
		currentImageIsAnalyzing: PropTypes.bool.isRequired,
		isUserMenuOpened: PropTypes.bool.isRequired,
		editorMode: PropTypes.string.isRequired,
		isAutosaveEnabled: PropTypes.bool.isRequired,
		magnifyingGlassEnabled: PropTypes.bool.isRequired,
		areFindingsMasksFromProfileEnabled: PropTypes.bool.isRequired,
		usesReaderStudyModeUi: PropTypes.bool.isRequired,
		usesComputerAidedDeviceUi: PropTypes.bool.isRequired,

		onGoToLandingPage: PropTypes.func.isRequired,
		onGetCurrentCollection: PropTypes.func.isRequired,
		onUploadImage: PropTypes.func.isRequired,
		onAddToCollection: PropTypes.func.isRequired,
		onSaveImageData: PropTypes.func.isRequired,
		onRemoveImage: PropTypes.func.isRequired,
		onFlipImageHorizontal: PropTypes.func.isRequired,
		onFlipImageVertical: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired
	};

	_uploadImageService = null;

	constructor (props, context) {
		super(props, context);

		this._store = context.store;
		this._router = context.router;

		this.state = {
			isSyncing: false,
		};

		this._uploadImageService = initUploadImageService({
			uploadImage: this.props.onUploadImage,
			removeImage: this.props.onRemoveImage,
			saveImageData: this.props.onSaveImageData,
			flipImageHorizontal: this.props.onFlipImageHorizontal,
			flipImageVertical: this.props.onFlipImageVertical,
			rotateImageLeft: this.props.onRotateImageLeft,
			rotateImageRight: this.props.onRotateImageRight,
			addImageToCollection: this.props.onAddToCollection,
			getCurrentCollection: this.props.onGetCurrentCollection,
		});

		this._uploadImageService.getEvents().on('upload-file-saved', this._handleUploadFileSuccess);
	}

	componentDidMount () {
		window.document.body.addEventListener('keydown', this._handleWindowKeyDown);

		if ( this.props.areFindingsMasksFromProfileEnabled === true ) {
			this.props.onSetFindingsMasks(areFindingsMasksEnabled());
		}
	}

	componentWillUnmount () {
		window.document.body.removeEventListener('keydown', this._handleWindowKeyDown);

		if ( this._uploadImageService !== null ) {
			this._uploadImageService.getEvents().on('upload-file-saved', this._handleUploadFileSuccess);
			this._uploadImageService.destroy();
			this._uploadImageService = null;
		}
	}

	_handleUploadFileSuccess = ({ collectionHashName, imageHashName }) => {
		const help = getHelp();
		if ( help !== null ) {
			help.sendUploadedImageEvent(`${window.location.origin}/collections/${collectionHashName}/image/${imageHashName}`);
		}
		this.context.router.history.push(`/collections/${collectionHashName}/image/${imageHashName}`);
	};

	_handleWindowKeyDown = (event) => {
		processKey(this._store, event);
	};

	_renderLayouts () {
		const { editorMode } = this.props;

		if (this.state.isSyncing) {
			return (
				<Popup isGlobal={false}>
					<Loading />
				</Popup>
			);
		}

		if ( this.props.currentImageIsAnalyzing ) {
			return (
				<div className={currentImageAnalyzingCssClassName}>
					<Popup>
						<div className={`${currentImageAnalyzingCssClassName}-content`}>
							<Loading />
							<div
								dangerouslySetInnerHTML={{
									__html: i18n('analyzing.progress')
								}}
							/>
						</div>
					</Popup>
				</div>
			);
		}

		if ( editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH ) {
			return <SelectToothEditor />
		}

		if ( this.props.imageFlipping ) {
			return (
				<Popup>
					<div className={`${currentImageAnalyzingCssClassName}-content`}>
						<Loading />
						<div
							dangerouslySetInnerHTML={{
								__html: i18n('image_flipping')
							}}
						/>
					</div>
				</Popup>
			);
		}

		if ( this.props.isSavingChanges && !this.props.isAutosaveEnabled ) {
			return (
				<Popup>
					<div className={`${currentImageAnalyzingCssClassName}-content`}>
						<Loading />
						<div
							dangerouslySetInnerHTML={{
								__html: i18n('unsaved_changes.saving'),
							}}
						/>
					</div>
				</Popup>
			);
		}

		return null;
	}

	_renderEmptyPage = () => {
		if (!this.props.isAuthenticated) {
			this.props.onGoToLandingPage();
		}

		return null;
	};

	_renderMagnifyingGlass () {
		if ( this.props.magnifyingGlassEnabled === false ) {
			return null;
		}

		return (
			<div className={magnifyingGlassCssClassName}>
				<MagnifyingGlass
					image={this.props.currentImage}
				/>
			</div>
		);
	}

	render() {
		const {
			isAuthenticated,
			isUserMenuOpened
		} = this.props;

		const isCollectionsPage = this.context.router.route.location.pathname.includes('/collection-list');
		const isStatsPage = this.context.router.route.location.pathname === '/huddle';

		return (
			<div className={baseCssClassName}>
				<div className={headerCssClassName}>
					{isAuthenticated
						? <Header
							isCollectionsPage={isCollectionsPage}
							isStatsPage={isStatsPage}
						/>
						: <GuestHeader />}
				</div>
				<div className={mainCssClassName}>
					{isAuthenticated && <Route path={'/collections'} exact render={() => (
						<Redirect to={'/'} />
					)} /> }
					{isAuthenticated && <Route path={'/collection-list'} component={CollectionsHandler} /> }
					{isAuthenticated && <Route path={'/stats'} exact component={Stats} /> }
					{isAuthenticated && <Route path={'/huddle'} exact component={Huddle} /> }
					{isAuthenticated && <Route path={'/'} exact component={Collection} /> }
					<Route path={'/shared/:imageId'} component={Main} />
					{isAuthenticated && <Route path={'/collections/:id'} component={Collection} />}
					{!isAuthenticated && (
						<Route path={'/'} exact render={() => (
							<Redirect to={'/sign_in'} />
						)} />
					)}
				</div>
				{this._renderMagnifyingGlass()}
				{this._renderLayouts()}
				{isUserMenuOpened && (
					<UserMenu
						availableFields={this.props.usesReaderStudyModeUi === true || this.props.usesComputerAidedDeviceUi === true ? [
							'first_name',
							'last_name',
							'country',
							'language',
							'notation_type',
							'clinic',
							'report_email',
							'change_password',
						] : undefined}
						editableFields={this.props.usesReaderStudyModeUi === true || this.props.usesComputerAidedDeviceUi === true ? [
							'notation_type',
							'change_email',
							'change_password',
						] : undefined}
					/>
				)}
				<UploadImage UploadService={this._uploadImageService} />
			</div>
		);
	}
}

export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);
		const isAuthenticated = userSelectors.selectIsAuthenticated(state);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		return {
			isAuthenticated,
			user: isAuthenticated ? userSelectors.selectUserData(state) : null,
			currentImageIsAnalyzing: editorData.currentImageIsAnalyzing,
			isUserMenuOpened: editorData.isUserMenuOpened,
			editorMode: editorData.editorMode,
			currentImage,
			imageFlipping: editorData.imageFlipping,
			isSavingChanges: editorData.isSavingChanges,
			isAutosaveEnabled: userSelectors.selectIsAutosaveEnabled(state),
			magnifyingGlassEnabled: editorData.magnifyingGlassEnabled,
			areFindingsMasksFromProfileEnabled: userSelectors.selectAreFindingsMasksEnabled(state),
			usesReaderStudyModeUi: userSelectors.selectUsesReaderStudyModeUi(state),
			usesComputerAidedDeviceUi: userSelectors.selectUsesComputerAidedDeviceUi(state),
			onGetCurrentCollection: (storeState) =>
				collectionsSelectors.selectCollectionById(storeState, {
					id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
				}),
		};
	},
	(dispatch) => ({
		onGoToLandingPage: () => commonUtils.goToLandingPage(),
		onUploadImage: (image) => dispatch(imagesActions.uploadImage({ image })),
		onAddToCollection: (options) => dispatch(collectionsActions.addImageToCurrentCollection(options)),
		onSaveImageData: (options) => {
			return dispatch(imagesActions.saveImageData(options))
				.then(() => {
					if ( getRuntimeConfig()['auto-image-analysis'] ) {
						dispatch(imagesActions.analyzeImage({
							id: options.id,
							isAnalysedByUser: false,
						}))
							.catch((error) => {
								// do nothing
							});
					}
				});
		},
		onRemoveImage: (options) => dispatch(imagesActions.removeImage(options)),
		onFlipImageHorizontal: (data) => dispatch(imagesActions.flipHorizontal(data)),
		onFlipImageVertical: (data) => dispatch(imagesActions.flipVertical(data)),
		onRotateImageLeft: (data) => dispatch(imagesActions.rotateLeft(data)),
		onRotateImageRight: (data) => dispatch(imagesActions.rotateRight(data)),
		onSetFindingsMasks: (value) => dispatch(editorActions.setFindingsMasks(value)),
	})
)(Editor);
