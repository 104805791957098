import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Overlay from "../Overlay";
import Alignment from "../Alignment";
import UserSidebar from '../UserSidebar';
import editorActions from "../../actions/editorActions";
import Popup from '../Popup';


import './styles/Editor.css';


const userMenuContainerCssClassName = `editor__user-menu-container`;

class UserMenu extends PureComponent {
	static propTypes = {
		availableFields: PropTypes.arrayOf(PropTypes.string),
		editableFields: PropTypes.arrayOf(PropTypes.string),
	};

	_handleUserMenuOverlayClick = () => {
		this.props.onCloseUserMenu();
	};

	render() {
		return (
			<div className={userMenuContainerCssClassName}>
				<Popup
					overlayProps={{
						theme: Overlay.themes.LIGHT
					}}
					alignmentProps={{
						horizontal: Alignment.horizontal.RIGHT,
						height: '100%'
					}}
					contentStyle={{
						height: '100%'
					}}
					onClose={this._handleUserMenuOverlayClick}
				>
					<UserSidebar availableFields={this.props.availableFields} editableFields={this.props.editableFields}/>
				</Popup>
			</div>
		);
	}
}

export default connect(
	null,
	dispatch => ({
		onCloseUserMenu: () => dispatch(editorActions.closeUserMenu()),
	})
)(UserMenu);
