import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import imageUtils from '../../appUtils/imageUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';

import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';

import './styles/CanvasControls.css';


const i18n = getDictionary('canvas-controls');


const contextTypes = {
	store: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
};

const baseCssClassName = 'canvas-controls__next-image-button';


class NextImageButton extends PureComponent {
	_redirect () {
		const {
			nextImage,
		} = this.props;
		
		const collectionId = this.context.router.route.match.params.id;
		this.context.router.history.replace(`/collections/${collectionId}/image/${nextImage.hashname}`, {imageId: nextImage.hashname});
	}
	
	_handleClick = () => {
		const {
			nextImage,
		} = this.props;

		if ( nextImage ) {
			imageUtils.checkHasUnsavedChanged(() => {
				this._redirect();
			});
		}
	}

	render () {
		return (
			<React.Fragment>
				<Button
					size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.LARGE}
					theme={Button.AVAILABLE_THEMES.SECONDARY}
					title={i18n('buttons.next_image.title')}
					disabled={this.props.nextImage === null}
					onClick={this._handleClick}
				>{i18n('buttons.next_image.text')}<span className={baseCssClassName} /></Button>
			</React.Fragment>
		);
	}
}

NextImageButton.contextTypes = contextTypes;

export default connect((state) => ({
	nextImage: imagesCollectionsSelectors.selectNextImageInCollection(state, { preventCircularSelection: true }),
}))(NextImageButton);
