import React, { PureComponent } from 'react';
import Tooltip from './Tooltip';

export default class OptionalTooltip extends PureComponent {
	_el = null;

	state = {
		isHovered: false,
		overflowActive: false,
	}

	isEllipsisActive(e) {
		return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
	}

	componentDidMount() {
		this.setState({ overflowActive: this.isEllipsisActive(this._el) });
	}

	_handleMouseOver = () => {
		this.setState({
			isHovered: true,
		});
	}
	
	_handleMouseOut = () => {
		this.setState({
			isHovered: false,
		});
	}
	
	_handleRef = (el) => {
		this._el = el;
	}
	
	render () {
		return (
			<span
				onMouseOver={this._handleMouseOver}
				onMouseOut={this._handleMouseOut}
				ref={this._handleRef}
				{...this.props}
			>
				{this.props.children}
				{this.state.isHovered && this.state.overflowActive && (
					<Tooltip
						target={this._el}
						isGlobal
					>{this.props.content}</Tooltip>
				)}
			</span>
		);
	}
}
