export default {
	'snapshot_link': 'Snapshot link',
	'report_link': 'Report link',

	'normal_appearance.title': 'Normal appearance',

	'findings.title': 'Findings',
	'findings.confirmed': 'confirmed',

	'finding.title': 'Finding',
	'tooth.title': 'Tooth',

	'summary.title': 'Summary',

	'teeth-numbers.title': 'Teeth numbers',

	'legend.labels.normal': 'Normal',
	'legend.labels.normal-with-findings': 'Normal with findings',
	'legend.labels.pathology': 'Pathology',
	'legend.labels.missing': 'Missing',
	
	'missing_teeth.title': 'Missing teeth',
	
	'prepared': 'Prepared using',
	
	'language.label': 'Language',
	'show_links.label': 'Show links',
	'print_button': 'Print',

	'comment.placeholder': 'Your comment',

	'specialist.label': 'Specialist',

	'fields.patient_id.label': 'Patient\'s ID',
	'fields.full_name.label': 'Patient full name',
	'fields.age.label': 'Age',
	'fields.gender.label': 'Gender',
	'fields.screening_date.label': 'Date of screening',
	'fields.screening_date.placeholder': 'Date',
	'fields.report_name.label': 'Report name',

	'report_name': 'OPG analysis report',
	'report_name.x-ray': 'Intraoral X-ray analysis report',
	'report_name.bitewing': 'Intraoral Bitewing analysis report',
	'report_name.periapical': 'Intraoral Periapical analysis report',

	'footer.comment': 'The assessment report is tentative, interpretation by a certified professional is required',

	'errors.language': 'An error occurred when language setting. Please try again',

	'specialists.label.specialist': 'Specialist',
	'specialists.label.assistant': 'Assistant',
	'specialists.label.hygienist': 'Hygienist',
};
