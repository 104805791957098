import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/FindingsCategory.css';


const baseCssClassName = 'resolver-findings-category';
const headerCssClassName = `${baseCssClassName}__header`;
const titleCssClassName = `${baseCssClassName}__title`;
const expandButtonCssClassName = `${baseCssClassName}__expand-button`;
const contentCssClassName = `${baseCssClassName}__content`;


export default class FindingsCategory extends PureComponent {
	static propTypes = {
		title: PropTypes.node.isRequired,
		children: PropTypes.node,
		allowExpand: PropTypes.bool,
		defaultIsExpanded: PropTypes.bool,
	};

	static defaultProps = {
		allowExpand: true,
		defaultIsExpanded: true,
	};

	state = {
		isExpanded: this.props.defaultIsExpanded,
	};

	_handleExpandButtonClick = () => {
		if ( this.props.allowExpand === false ) {
			return;
		}

		this.setState((prevState) => ({
			isExpanded: !prevState.isExpanded,
		}));
	};

	render () {
		return (
			<div className={baseCssClassName}>
				<div
					className={classnames([
						headerCssClassName,
						this.props.allowExpand === true && `${headerCssClassName}__m-with-expand`,
					])}
					onClick={this._handleExpandButtonClick}
				>
					{this.props.allowExpand === true && (
						<div
							className={classnames([
								expandButtonCssClassName,
								this.state.isExpanded === true && `${expandButtonCssClassName}__m-expanded`,
							])}
						/>
					)}
					<div className={titleCssClassName}>
						{this.props.title}
					</div>
				</div>
				{this.state.isExpanded === true && (
					<div className={contentCssClassName}>
						{this.props.children}
					</div>
				)}
			</div>
		);
	}
}
