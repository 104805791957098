import React from 'react';
import { connect } from 'react-redux';

import mainConfig from '../../../../configs/mainConfig';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';

import { isSequentialModeEnabledForVisit, isShapeIsPresent } from '../../utils';
import { checkFindingsFilter } from '../../utils/findings-filter';
import { prepareBonelosses } from '../../../labels/utils';

import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../../label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';
import userSelectors from '../../../../selectors/userSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';

import { ShapeTooltip } from '../../../../components/ShapeTooltip';


export default connect((state, props) => {
	let label = {
		...props.label,
		tags: [],
	};

	const editorData = editorSelectors.selectEditor(state);
	const findingsFilter = editorData.treatmentPlanFilters;
	const showToothKey = labelsUtils.labelIsTooth(label);
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const user = userSelectors.selectUserData(state);
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;

	const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
	if ( children.length > 0 ) {
		label.children = [];
		children.forEach((childLabelId) => {
			const childLabel = labelsSelectors.selectLabelById(state, {
				labelId: childLabelId,
			});
			childLabel.tags = [];
			const classId = labelGetters.getLabelClassId(childLabel);

			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);

			labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(childLabel) })
				.forEach((tagId) => {
					childLabel.tags.push(labelTagsSelectors.selectLabelTagById(state, { tagId }));
				});

			if ( isSequentialMode === true && labelsUtils.getLabelAvailabilityOptions(classId).findings_group === 'pathological') {
				return;
			}

			const shape = childLabel.shapes[currentImage.hashname];

			if (
				isShapeIsPresent(shape) === true &&
				checkFindingsFilter({
					findingsFilter,
					label: childLabel,
					showBoneLossStages: editorData.showBoneLossStages,
					allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
				})
				&& (
					typeof measureOfConfidence !== 'number' ||
					editorData.filteredConfidencePercent <= measureOfConfidence
				)
			) {
				label.children.push(childLabel);
			}
		});

		label.children = prepareBonelosses(label.children, { filterGreenLines: true, filterNotPathologies: true });
	}
	else {
		labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) })
			.forEach((tagId) => {
				label.tags.push(labelTagsSelectors.selectLabelTagById(state, { tagId }));
			});
	}

	const preparedLabels = prepareBonelosses([ label ], { filterGreenLines: false, filterNotPathologies: false });
	label = preparedLabels[preparedLabels.length - 1];

	return {
		...props,
		label,
		showToothKey,
		onGetToothKey: () => {
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( tags && tags.length > 0 ) {
				return teethUtils.getLocalizedToothKey({
					toothKey: labelTagGetter.getTagKey(tags[0]),
					notationType: userSelectors.selectUserData(state).notation_type,
				});
			}
			return '-';
		},
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
	};
}, null)(ShapeTooltip);
