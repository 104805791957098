import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import mainConfig from '../../../../configs/mainConfig';
import editorSelectors from '../../../../selectors/editorSelectors';
import Float, { FloatAxis, FloatPosition } from '../../../../components/Float';
import ResolverFindingViewer from './FindingViewer';

import { getCanvas } from '../../../../services/canvas';


const baseCssClassName = 'resolver-shape-viewer';


class ToothWrapper extends PureComponent {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		shouldShowFindingsViewerForDentalNotation: PropTypes.bool.isRequired,
	};

	state = {
		axis: FloatAxis.horizontal,
		pos: FloatPosition.start,
	};

	_anchorEl = null;

	componentDidMount () {
		this._anchorEl = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
	}

	_handleReady = (position) => {
		this.setState({
			axis: position.mainAxis,
			pos: position.mainAxisPosition,
		});
	};

	_getViewportLocation () {
		const canvas = getCanvas();
		if ( canvas !== null ) {
			return canvas.getBoundingClientRect();
		}
		else {
			return {
				left: 0,
				top: 0,
				right: document.body.offsetWidth,
				bottom: document.body.offsetHeight,
			};
		}
	}

	render () {
		const hasShape = typeof lodashGet(this.props.teeth[this.props.selectedToothKey], 'shape.type') === 'string';
		if (
			(
				typeof this.props.selectedToothKey === 'string' &&
				this.props.selectedToothKey === this.props.toothKey &&
				hasShape === false &&
				this.props.editorMode === mainConfig.EDITOR_MODE__EDIT_MODE &&
				this.props.shouldShowFindingsViewerForDentalNotation === true
			) === false
		) {
			return this.props.children;
		}

		return (
			<Fragment>
				{this.props.children}
				{this._anchorEl !== null && (
					<Float
						target={this._anchorEl}
						initialPosition={{
							mainAxis: FloatAxis.horizontal,
							mainAxisPosition: FloatPosition.start,
							mainAxisOffset: 24,
						}}
						viewportLocation={this._getViewportLocation()}
						onReady={this._handleReady}
					>
						<div className={`${baseCssClassName} ${baseCssClassName}__m-axis-${this.state.axis}__m-pos-${this.state.pos}`}>
							<ResolverFindingViewer
								toothKey={this.props.selectedToothKey}
							/>
						</div>
					</Float>
				)}
			</Fragment>
		);
	}
}

export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);

	return {
		shouldShowFindingsViewerForDentalNotation: editorData.shouldShowFindingsViewerForDentalNotation,
	};
}, () => ({}))(ToothWrapper)
