import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getAvailableSurfaces, getLocalizedSurface } from '../../utils';

import './styles/ResolverSurfaceEditor.css';


const baseCssClassName = 'resolver-surface-editor';
const controlCssClassName = `${baseCssClassName}__control`;
const controlContentCssClassName = `${controlCssClassName}-content`;


export default class ResolverSurfaceEditor extends Component {
	static propTypes = {
		toothKey: PropTypes.string,
		values: PropTypes.arrayOf(PropTypes.string.isRequired),

		onChange: PropTypes.func,
	}
	
	static defaultProps = {
		values: [],
	}
	
	_handleControlClick = (event) => {
		const index = parseInt(event.currentTarget.dataset.index, 10);
		
		if ( this.props.onChange ) {
			const value = getAvailableSurfaces({ toothKey: this.props.toothKey })[index - 1];
			const isSelected = this.props.values.includes(value);
			
			this.props.onChange(isSelected
				? this.props.values.filter((_value) => _value !== value)
				: [ ...this.props.values, value ]
			);
		}
	}
	
	renderControls () {
		const surfaces = getAvailableSurfaces({ toothKey: this.props.toothKey });
		const controls = [];
		for (let i = 1; i < 6; i++) {
			controls.push(
				<div
					key={i}
					className={classnames([
						controlCssClassName,
						`${controlCssClassName}__m-index-${i}`,
						this.props.values.includes(surfaces[i - 1]) && `${controlCssClassName}__m-selected`
					])}
					data-index={i}
					onClick={this._handleControlClick}
				>
					<div className={controlContentCssClassName}>{getLocalizedSurface(surfaces[i - 1], this.props.toothKey)}</div>
				</div>
			);
		}
		
		return controls;
	}
	
	render () {
		return (
			<div className={baseCssClassName}>
				{this.renderControls()}
			</div>
		);
	}
}
