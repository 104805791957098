import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import teethUtils from '../../../../appUtils/teeth/teethUtils';
import labelsUtils from '../../../../appUtils/labelsUtils';
import { isMissingTooth, getMissingToothBox } from '../../utils';

import editorSelectors from '../../../../selectors/editorSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';

import { MODES } from './reportConsts';

import TextField from '@material-ui/core/TextField';

import arrowDownImagePath from './images/arrow-down.png';
import arrowUpImagePath from './images/arrow-up.png';


const baseCssClassName = 'resolver-report-findings';
const rowCssClassName = `${baseCssClassName}__row`;
const leftCssClassName = `${baseCssClassName}__left`;
const rightCssClassName = `${baseCssClassName}__right`;
const imageCssClassName = `${baseCssClassName}__image`;
const toothCssClassName = `${baseCssClassName}__tooth`;
const toothIndicatorCssClassName = `${baseCssClassName}__tooth-indicator`;
const findingsCssClassName = `${baseCssClassName}__findings`;
const findingCssClassName = `${baseCssClassName}__finding`;
const findingCommentCssClassName = `${baseCssClassName}-finding-comment`;


class ResolverReportFindingComment extends PureComponent {
	static propTypes = {
		mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
	};

	state = {
		comment: '',
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleChange = (event) => {
		this.setState({
			comment: event.target.value,
		});
	};

	render () {
		return (
			<div className={findingCommentCssClassName}>
				{this.props.mode === MODES.PREVIEW && this.state.comment.length > 0 && (
					<Fragment>
						<div className={`${findingCommentCssClassName}__label`}>
							Comment
						</div>
						<div className={`${findingCommentCssClassName}__value`}>
							{this.state.comment}
						</div>
					</Fragment>
				)}
				{this.props.mode === MODES.EDITING && (
					<div className={`${findingCommentCssClassName}__form`}>
						<TextField
							value={this.state.comment}
							label={'Comment'}
							placeholder={'Type your comment'}
							margin={'dense'}
							fullWidth
							InputLabelProps={{
								shrink: true,
								className: `${findingCommentCssClassName}__label`,
							}}
							InputProps={{
								className: `${findingCommentCssClassName}__input`,
							}}
							onChange={this._handleChange}
						/>
					</div>
				)}
			</div>
		)
	}
}

const SCREEN_STATUSES = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	DONE: 'done',
	FAIL: 'fail',
};


class ResolverReportFindings extends PureComponent {
	static propTypes = {
		currentImage: PropTypes.object.isRequired,
		teethOrder: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		teeth: PropTypes.object.isRequired,
		mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
		toothImageHashNameMap: PropTypes.object.isRequired,
		getImageUrl: PropTypes.func.isRequired,
	};

	_arrowDownImage = null;

	_arrowUpImage = null;

	_images = {};

	state = {
		loadedScreenImages: {},
	};

	componentDidMount () {
		// this._loadScreenImage();
		this._loadArrowsImage();
	}

	/**
	 * @param {string} toothKey
	 * @private
	 */
	_loadScreenImage (toothKey) {
		const status = this.state.loadedScreenImages[toothKey];
		const src = this.props.getImageUrl(this.props.toothImageHashNameMap[toothKey]);

		if ( status === SCREEN_STATUSES.DONE || src === undefined ) {
			return;
		}

		this.setState((prevState) => ({
			loadedScreenImages: {
				...prevState.loadedScreenImages,
				[toothKey]: SCREEN_STATUSES.IN_PROGRESS,
			},
		}));

		const image = new Image();
		image.src = src;
		image.onload = () => {
			this._images[toothKey] = image;
			this.setState((prevState) => ({
				loadedScreenImages: {
					...prevState.loadedScreenImages,
					[toothKey]: SCREEN_STATUSES.DONE,
				},
			}));
		};
		image.onerror = () => {
			this.setState((prevState) => ({
				loadedScreenImages: {
					...prevState.loadedScreenImages,
					[toothKey]: SCREEN_STATUSES.FAIL,
				},
			}));
		};
	}

	_loadArrowsImage () {
		const arrowDownImage = new Image();
		arrowDownImage.onload = () => {
			this._arrowDownImage = arrowDownImage;
			this.forceUpdate();
		};
		arrowDownImage.src = arrowDownImagePath;

		const arrowUpImage = new Image();
		arrowUpImage.onload = () => {
			this._arrowUpImage = arrowUpImage;
			this.forceUpdate();
		};
		arrowUpImage.src = arrowUpImagePath;
	}

	_getImage (tooth) {
		if ( this._arrowUpImage === null || this._arrowDownImage === null ) {
			return null;
		}

		const toothKey = tooth.toothKey;
		const status = this.state.loadedScreenImages[toothKey];
		if ( status === undefined || status === SCREEN_STATUSES.IDLE ) {
			this._loadScreenImage(toothKey)
			return null;
		}
		else if ( status !== SCREEN_STATUSES.DONE ) {
			return null;
		}

		let primaryBox = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(tooth));
		const toothIsMissing = isMissingTooth(tooth.toothKey);

		if ( toothIsMissing ) {
			primaryBox = getMissingToothBox(tooth);
		}

		if ( !primaryBox ) {
			return null;
		}

		return (<canvas
			key={tooth.toothKey}
			ref={(canvas) => {
				if ( !canvas ) {
					return;
				}

				const isTopTooth = teethUtils.getOrderedTopTeeth().includes(toothKey);

				const image = this._images[toothKey];
				const context = canvas.getContext('2d');

				const primaryBoxWidth = ( primaryBox.right - primaryBox.left );
				const primaryBoxHeight = ( primaryBox.bottom - primaryBox.top );
				let zoom = 1;

				const maxSide = Math.max(primaryBoxWidth, primaryBoxHeight);

				if ( maxSide * 1.5 > 90 ) {
					zoom = 90 / ( maxSide * 1.5 );
				}

				const ratio = image.width / image.height;
				const imageHeight = image.height * zoom
				const imageWidth = imageHeight * ratio;

				let offsetLeft = ( primaryBox.left * zoom ) - 45 + (primaryBoxWidth * zoom / 2);
				let offsetTop = ( primaryBox.top * zoom ) - 45 + (primaryBoxHeight * zoom / 2);
				const primaryBoxStartX = (45 - (primaryBoxWidth * zoom / 2));
				const primaryBoxStartY = (45 - (primaryBoxHeight * zoom / 2));

				context.drawImage(
					image,
					-offsetLeft,
					-offsetTop,
					imageWidth,
					imageHeight,
				);

				tooth.children.forEach((label) => {
					let box = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(label));
					if ( toothIsMissing === true ) {
						box = primaryBox;
					}

					const boxWidth = Math.floor( box.right - box.left );
					const boxHeight = Math.floor( box.bottom - box.top );

					const boxStartX = Math.floor( primaryBoxStartX + ( box.left - primaryBox.left ) * zoom );
					const boxStartY = Math.floor( primaryBoxStartY + ( box.top - primaryBox.top ) * zoom );
					const boxEndY = Math.floor( boxStartY + boxHeight * zoom );

					const boxCenterX = Math.floor( boxStartX + ( boxWidth / 2 ) * zoom );
					const boxCenterY = Math.floor( boxStartY + ( boxHeight / 2 ) * zoom );

					const boxShorterSide = Math.floor( boxWidth > boxHeight  ? boxHeight : boxWidth ) * zoom;

					// context.beginPath();
					// context.fillStyle = 'red';

					if ( isTopTooth === false ) {
						const posY = Math.floor( boxStartY + boxShorterSide / 2 / 2);

						// context.fillRect(boxCenterX, posY - 3, 2, -12);

						// context.moveTo(boxCenterX, posY);
						// context.lineTo(boxCenterX - 3, posY - 9);
						// context.lineTo(boxCenterX, posY - 7);
						// context.lineTo(boxCenterX + 2, posY - 7);
						// context.lineTo(boxCenterX + 5, posY - 9);
						// context.lineTo(boxCenterX + 1, posY);
						// context.lineTo(boxCenterX, posY);

						context.drawImage(
							this._arrowDownImage,
							boxCenterX - ( this._arrowDownImage.width / 2 ),
							posY - this._arrowDownImage.height,
							this._arrowDownImage.width,
							this._arrowDownImage.height,
						);
					}
					else {
						const posY = Math.floor( boxEndY - boxShorterSide / 2 / 2 );

						// context.moveTo(boxCenterX - 1, posY + 3);

						// context.fillRect(boxCenterX, posY + 3, 2, 12);

						// context.moveTo(boxCenterX, posY);
						// context.lineTo(boxCenterX - 3, posY + 9);
						// context.lineTo(boxCenterX, posY + 7);
						// context.lineTo(boxCenterX + 2, posY + 7);
						// context.lineTo(boxCenterX + 5, posY + 9);
						// context.lineTo(boxCenterX + 1, posY);
						// context.lineTo(boxCenterX, posY);

						context.drawImage(
							this._arrowUpImage,
							boxCenterX - ( this._arrowUpImage.width / 2 ) + 1,
							posY,
							this._arrowUpImage.width,
							this._arrowUpImage.height,
						);
					}

					// context.closePath();
					// context.fill();

					// context.beginPath();
					// context.moveTo(boxStartX, boxStartY);
					// context.lineTo(boxStartX + boxWidth * zoom, boxStartY);
					// context.lineTo(boxStartX + boxWidth * zoom, boxStartY + boxHeight * zoom);
					// context.lineTo(boxStartX, boxStartY + boxHeight * zoom);
					// context.closePath();
					// context.stroke();
				});

				// context.beginPath();
				// context.moveTo(primaryBoxStartX, primaryBoxStartY);
				// context.lineTo(primaryBoxStartX + primaryBoxWidth * zoom, primaryBoxStartY);
				// context.lineTo(primaryBoxStartX + primaryBoxWidth * zoom, primaryBoxStartY + primaryBoxHeight * zoom);
				// context.lineTo(primaryBoxStartX, primaryBoxStartY + primaryBoxHeight * zoom);
				// context.closePath();
				// context.stroke();
			}}
			width={90}
			height={90}
		/>);
	}

	_renderLabelName (childLabel) {
		let additionalContent = null;

		const surfaces = labelGetters.getLabelSurfaces(childLabel);
		if ( surfaces.length > 0 ) {
			additionalContent = (
				<span>({surfaces.length === 1 ? '1 surface' : `${surfaces.length} surfaces`})</span>
			);
		}

		return (
			<span>
				{labelsUtils.getLocalizedLabelName(childLabel)} {additionalContent}
			</span>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				{this.props.teethOrder.map((toothKey) => {
					const tooth = this.props.teeth[toothKey];

					if ( tooth === undefined ) {
						return null;
					}

					return (
						<div className={rowCssClassName} key={toothKey}>
							<div className={leftCssClassName}>
								<div className={imageCssClassName}>
									{this._getImage(tooth)}
								</div>
								<div className={toothCssClassName}>
									<div
										className={toothIndicatorCssClassName}
										style={{
											borderColor: labelsUtils.getLabelColor(tooth),
										}}
									>
										{tooth.localizedToothKey}
									</div>
								</div>
							</div>
							<div className={rightCssClassName}>
								{tooth.children.length > 0 && (
									<div className={findingsCssClassName}>
										{tooth.children.map((childLabel, index) => (
											<div className={findingCssClassName} key={index}>{this._renderLabelName(childLabel)}</div>
										))}
									</div>
								)}
								<div>
									{<ResolverReportFindingComment mode={this.props.mode} />}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		)
	}
}

export default connect((state, props) => {
	const editorData = editorSelectors.selectEditor(state);

	const nextTeeth = {};
	props.teethOrder.forEach((toothKey) => {
		const tooth = props.teeth[toothKey];
		const nextTooth = {
			...tooth,
			children: [],
		};

		tooth.children.forEach((label) => {
			const classId = labelGetters.getLabelClassId(label);
			const source = labelGetters.getLabelSource(label);
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);

			if (
				labelsUtils.getLabelAvailabilityOptions(classId).findings_group === 'pathological' &&
				(
					source === 'manual' ||
					source === 'ai'
				) &&
				(
					classId !== 'missing_tooth' ||
					(
						toothKey !== '18' &&
						toothKey !== '28' &&
						toothKey !== '38' &&
						toothKey !== '48'
					)
				) &&
				(
					typeof measureOfConfidence === 'number' &&
					editorData.filteredConfidencePercent <= measureOfConfidence
				) &&
				labelGetters.getLabelIsWatch(label) === false
			) {
				nextTooth.children.push(label);
			}
		});

		if ( nextTooth.children.length > 0 ) {
			nextTeeth[toothKey] = nextTooth;
		}
	});


	return {
		teeth: nextTeeth,
	};
}, null)(ResolverReportFindings);
