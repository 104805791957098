/* global document */
import ReactDOM from 'react-dom';


const defaultOptions = {
	autoOpen: true,
};

/**
 * @typedef {Object} AbstractPopupControllerOptions
 *
 * @property {function} onOpen
 * @property {function} onClose
 */


class AbstractPopupController {
	/**
	 * @param {AbstractPopupControllerOptions} options
	 */
	constructor(options = {}) {
		this._options = {
			...defaultOptions,
			...options,
		};

		this._container = null;
		this._isOpened = false;
	}

	_createContainer () {
		this._container = document.createElement('DIV');
		(this._options.container || document.body).appendChild(this._container);
	}

	open () {
		const {
			onOpen,
		} = this._options;

		if ( this._isOpened ) {
			return;
		}

		if ( !this._container ) {
			this._createContainer();
		}

		this._isOpened = true;

		this.render();

		if ( onOpen ) {
			onOpen();
		}
	}

	close () {
		const {
			onClose,
		} = this._options;

		this.destroy();

		this._isOpened = false;

		if ( onClose ) {
			onClose();
		}
	}

	destroy () {
		if ( this._container ) {
			ReactDOM.unmountComponentAtNode(this._container);
			this._container.parentNode.removeChild(this._container);
			this._container = null;
		}
	}

	setOptions (options = {}) {
		this._options = {
			...this._options,
			...options,
		};
	}

	render () {}
}

export default AbstractPopupController;
