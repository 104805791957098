import lodashGet from 'lodash/get';


/**
 * @param {StoreState} state
 * @return {UserStoreState}
 */
const selectUser = (state) => state.user;

/**
 * @param {StoreState} state
 * @return {User}
 */
const selectUserData = (state) => selectUser(state).data;

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectIsAuthenticated = (state) => selectUser(state).isAuthenticated;

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectIsAutosaveEnabled = (state) => lodashGet(selectUserData(state), 'is_autosave_enabled', false);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectIsFDAAnnotationEnabled = (state) => lodashGet(selectUserData(state), 'is_fda_annotator', false);

/**
 * @param {StoreState} state
 * @return {number}
 */
const selectAutosaveTiming = (state) => lodashGet(selectUserData(state), 'autosave_timing', 1000);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectAreFindingsMasksEnabled = (state) => lodashGet(selectUserData(state), 'are_findings_masks_enabled', false);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectAreHeatMapsEnabled = (state) => lodashGet(selectUserData(state), 'are_findings_heatmaps_enabled', false);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectUsesReaderStudyModeUi = (state) => lodashGet(selectUserData(state), 'uses_reader_study_mode_ui', false);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectUsesComputerAidedDeviceUi = (state) => lodashGet(selectUserData(state), 'uses_computer_aided_device_ui', false);

/**
 * @param {StoreState} state
 * @return {boolean}
 */
const selectIsAutoChartUser = (state) => lodashGet(selectUserData(state), 'is_autochart_user', false);


export default {
	selectUserData,
	selectIsAuthenticated,
	selectIsAutosaveEnabled,
	selectAutosaveTiming,
	selectIsFDAAnnotationEnabled,
	selectAreFindingsMasksEnabled,
	selectAreHeatMapsEnabled,
	selectUsesReaderStudyModeUi,
	selectUsesComputerAidedDeviceUi,
	selectIsAutoChartUser,
};
