import React from 'react';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import HeaderRow from './HeaderRow';
import HeaderCell from './HeaderCell';
import ContentRow from './ContentRow';
import ContentCell from './ContentCell';

import GridProvider from './GridProvider';
import GridContainer from './GridContainer';
import SizeDetector from '../../components/SizeDetector';


const propTypes = {
	cssClassName: PropTypes.string.isRequired,
	cssClassModifier: PropTypes.string,
	minWidth: PropTypes.number.isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		width: PropTypes.oneOfType([
			PropTypes.number.isRequired,
			PropTypes.string.isRequired,
		]).isRequired,
		headerValue: PropTypes.node,
		headerContextTitle: PropTypes.string,
		getValue: PropTypes.func.isRequired,
		getContextTitle: PropTypes.func.isRequired,
	})).isRequired,
	headerRowHeight: PropTypes.number.isRequired,
	contentRowHeight: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	getContentRowKey: PropTypes.func.isRequired,
	getContentRowModifiers: PropTypes.func,
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	dataType: PropTypes.string,
	onContentRowClick: PropTypes.func,
	onContentRowDoubleClick: PropTypes.func,
	onContentCellClick: PropTypes.func,
	onContentRef: PropTypes.func,
	onScrolledToEnd: PropTypes.func,
	onCustomRender: PropTypes.func,
	onGetCategoryRenderDescriptor: PropTypes.func,
};

const columnRender = (columns) => {
	return (
		<HeaderRow>
			{columns.map((column) => {
				return (
					<HeaderCell
						key={column.key}
						cellKey={column.key}
						width={column.width}
						contextTitle={column.headerContextTitle}
					>
						{column.headerValue}
					</HeaderCell>
				);
			})}
		</HeaderRow>
	);
}

const Grid = (props) => {
	const {
		cssClassName,
		cssClassModifier,
		minWidth,
		columns,
		headerRowHeight,
		contentRowHeight,
		getContentRowKey,
		getContentRowModifiers,
		data,
		dataType,
		onContentRowClick,
		onContentRowDoubleClick,
		onContentRowMouseOver,
		onContentRowMouseOut,
		onContentRef,
		onContentCellClick,
		onScrolledToEnd,
		onCustomRender,
		onGetCategoryRenderDescriptor,
	} = props;


	let columnWidth = columns.reduce((result, column) => result + column.width, 0);

	return (
		<GridProvider cssClassName={cssClassName}>
			<div
				className={classnames([
					cssClassName,
					cssClassModifier && `${cssClassName}__m-${cssClassModifier}`,
				])}
			>
				<SizeDetector>
					{({ width, height }) => (
						<GridContainer
							width={width}
							height={height}
							columns={columns}
							minWidth={minWidth}
							data={data}
							dataType={dataType}
							getHeaderRowRenderOptions={() => {
								return {
									render: columnRender,
									height: headerRowHeight,
								};
							}}
							getContentRowRenderOptions={(rowData, index) => {
								let rowClickHandler = null;

								if ( onContentRowClick ) {
									rowClickHandler = () => {
										onContentRowClick(rowData);
									};
								}

								const rowDoubleClickHandler = () => {
									if ( onContentRowDoubleClick ) {
										onContentRowDoubleClick(rowData);
									}
								};

								const rowMouseOverHandler = () => {
									if ( onContentRowMouseOver ) {
										onContentRowMouseOver(rowData);
									}
								};

								const rowMouseOutHandler = () => {
									if ( onContentRowMouseOut ) {
										onContentRowMouseOut(rowData);
									}
								};

								return {
									render: (columns) => {
										const cellClickHandler = (column) => {
											if ( onContentCellClick ) {
												onContentCellClick(column);
											}
										};

										return (
											<ContentRow
												key={getContentRowKey(rowData, index)}
												modifiers={getContentRowModifiers(rowData)}
												width={columnWidth}
												height={contentRowHeight}
												onClick={rowClickHandler}
												onRef={(el) => {
													if ( onContentRef ) {
														onContentRef(el, rowData);
													}
												}}
												onDoubleClick={rowDoubleClickHandler}
												onMouseOver={rowMouseOverHandler}
												onMouseOut={rowMouseOutHandler}

											>
												{columns.map((column) => {
													return (
														<ContentCell
															key={column.key}
															cellKey={column.key}
															width={column.width}
															contextTitle={column.getContextTitle(rowData, index)}
															onClick={() => cellClickHandler(column)}
														>
															{column.getValue(rowData, index)}
														</ContentCell>
													);
												})}
											</ContentRow>
										);
									},
								};
							}}
							getCategoryRenderOptions={onGetCategoryRenderDescriptor}
							onScrolledToEnd={onScrolledToEnd}
							onCustomRender={onCustomRender}
						/>
					)}
				</SizeDetector>
			</div>
		</GridProvider>
	);
}

Grid.propTypes = propTypes;
Grid.defaultProps = {
	dataType: 'rows',
};

export default Grid;
