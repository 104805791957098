import editorSelectors from './editorSelectors';
import imagesCollectionsSelectors from './imagesCollectionsSelectors';
import imagesSelectors from './imagesSelectors';


const selectImagesCollections = (state) => ( state.imagesCollections || {} );

const selectImagesIdsByCollectionId = (state, options) => ( selectImagesCollections(state)[options.collectionId] || [] );

/**
 * @param {StoreState} state
 * @param {Object} [options]
 * @param {boolean} [options.preventCircularSelection=false]
 * @return {CollectionImage|null}
 */
const selectNextImageInCollection = (state, options = {}) => {
	if ( typeof options.preventCircularSelection === 'undefined' ) {
		options.preventCircularSelection = false;
	}

	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	const imagesIds = imagesCollectionsSelectors.selectImagesIdsByCollectionId(state, {
		collectionId: currentCollectionId,
	});

	const currentImageIndexInCollection = imagesIds.indexOf(currentImageId);

	let nextIndex = ( currentImageIndexInCollection + 1 );
	let nextId = imagesIds[nextIndex];
	if ( nextIndex === imagesIds.length ) {
		if ( options.preventCircularSelection === true ) {
			return null;
		}
		nextId = imagesIds[0];
	}

	return imagesSelectors.selectImageById(state, {
		id: nextId,
	});
};

/**
 * @param {StoreState} state
 * @param {Object} [options]
 * @param {boolean} [options.preventCircularSelection=false]
 * @return {CollectionImage|null}
 */
const selectPrevImageInCollection = (state, options= {}) => {
	if ( typeof options.preventCircularSelection === 'undefined' ) {
		options.preventCircularSelection = false;
	}

	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	const imagesIds = imagesCollectionsSelectors.selectImagesIdsByCollectionId(state, {
		collectionId: currentCollectionId,
	});

	const currentImageIndexInCollection = imagesIds.indexOf(currentImageId);

	let prevIndex = ( currentImageIndexInCollection - 1 );
	let prevId = imagesIds[prevIndex];
	if ( prevIndex < 0 ) {
		if ( options.preventCircularSelection === true ) {
			return null;
		}
		prevId = imagesIds[imagesIds.length - 1];
	}

	return imagesSelectors.selectImageById(state, {
		id: prevId,
	});
};

const selectLastImageInCollection = (state) => {
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const images = selectImagesIdsByCollectionId(state, {
		collectionId: currentCollectionId,
	});

	if ( images && images.length ) {
		return imagesSelectors.selectImageById(state, {
			id: images[0],
		});
	}

	return null;
};

const selectFirstImageInCollection = (state, options) => {
	const collectionId = options
		? options.collectionId
		: editorSelectors.selectEditor(state).currentCollectionHashName;

	const images = selectImagesIdsByCollectionId(state, { collectionId });

	if ( images && images.length > 0 ) {
		return imagesSelectors.selectImageById(state, {
			id: images[images.length - 1],
		});
	}

	return null;
};

const selectLastOpenedImageInCollection = (state, options) => {
	const collectionId = options
		? options.collectionId
		: editorSelectors.selectEditor(state).currentCollectionHashName;

	const images = selectImagesIdsByCollectionId(state, { collectionId });

	if ( images && images.length > 0 ) {
		let lastImage = null;
		let lastOpenedTime = 0;

		images.forEach((imageId) => {
			const image = imagesSelectors.selectImageById(state, {
				id: imageId,
			});

			if ( image && image.last_opened_at > lastOpenedTime ) {
				lastImage = image;
				lastOpenedTime = image.last_opened_at;
			}
		});

		return lastImage;
	}

	return null;
};

const selectImageInCollectionByIndex = (state, options = {}) => {
	const collectionId = options.collectionId ||
		editorSelectors.selectEditor(state).currentCollectionHashName;

	const images = selectImagesIdsByCollectionId(state, { collectionId });
	return imagesSelectors.selectImageById(state, {
		id: images[options.index],
	});
};

export default {
	selectImagesCollections,
	selectImagesIdsByCollectionId,
	selectNextImageInCollection,
	selectPrevImageInCollection,
	selectLastImageInCollection,
	selectFirstImageInCollection,
	selectLastOpenedImageInCollection,
	selectImageInCollectionByIndex,
};
