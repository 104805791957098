export default {
	'drawing_mode.on': 'Enable drawing mode',
	'drawing_mode.off': 'Disable drawing mode',
	'zoom_in': 'Zoom in',
	'zoom_out': 'Zoom out',
	'default_zoom': 'Default zoom',
	'brightness': 'Brightness',
	'contrast': 'Contrast',
	'flip.horizontal': 'Flip horizontal',
	'flip.vertical': 'Flip vertical',
	'rotate.left': 'Rotate left',
	'rotate.right': 'Rotate right',
	'invert': 'Invert colors',
	'sharpen': 'Sharpen image',
};
