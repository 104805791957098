export default {
	'image_id': 'Image ID',
	'image_date': 'Image date',
	'patient_id': 'Patient\'s ID',
	'birthday': 'Birth date',
	'age': 'Age',
	'gender': 'Gender',
	'image_type': 'Image type',

	'close_image': 'Close image',
};
