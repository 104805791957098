import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import labelsUtils from '../../appUtils/labelsUtils';
import labelTagsAction from '../../modules/label-tags/actions/labelTagsAction';
import labelGetters from '../../modules/labels/selectors/labelGetters';
import { tagIsSurface } from '../../modules/label-tags/utils';

import FormCustomSelect from '../FormCustomSelect';
import LabelTagsSelector from './LabelTagsSelector';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import userSelectors from '../../selectors/userSelectors';

import './styles/LabelTags.css';


const baseCssClassName = 'label-tags';
const rowCssClassName = `${baseCssClassName}__row`;
const selectCssClassName = `${baseCssClassName}__select`;
const tagIconCssClassName = `${baseCssClassName}__tag-icon`;
const controlsCssClassName = `${baseCssClassName}__controls`;
const removeButtonCssClassName = `${baseCssClassName}__remove-button`;
const tagValueCssClassName = `${baseCssClassName}__tag-value`;


class LabelTags extends PureComponent {
	static propTypes = {
		label: PropTypes.object.isRequired,

		tags: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})),

		availableTags: PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			hotKey: PropTypes.string,
		})),

		isEditMode: PropTypes.bool.isRequired,

		onChangeTag: PropTypes.func.isRequired,
		onRemoveTag: PropTypes.func.isRequired,
		onAddTag: PropTypes.func.isRequired,
	}

	_getAvailableTags () {
		return this.props.availableTags.map((tag) => ({
			...tag,
			value: tag.key,
			disabled: false === tag.canAdd,
		}));
	}

	_handleRemoveButtonClick = (event) => {
		this.props.onRemoveTag({
			labelId: labelGetters.getLabelId(this.props.label),
			tagId: event.currentTarget.dataset.tagId,
		});
	}

	_handleAddTag = (tagKey) => {
		const tag = labelsUtils.getLabelTagByKey(tagKey, labelGetters.getLabelClassId(this.props.label));
		this.props.onAddTag({
			labelId: labelGetters.getLabelId(this.props.label),
			tag: {
				key: tagKey,
				localizedName: tag.readable_name,
				hotKey: tag.hotkey,
			},
		});
	}

	_renderTags () {
		if ( this.props.tags.length === 0 ) {
			return null;
		}

		const isEditMode = this.props.isEditMode;

		return this.props.tags.map((tag) => {
			const canEdit = (isEditMode && tag.canEdit === true);
			return (
				<div
					key={tag.id}
					className={rowCssClassName}
				>
					<div className={tagIconCssClassName} />
					<div className={selectCssClassName}>
						{ !canEdit && (<div className={tagValueCssClassName}>{tag.label}</div>) }
						{ canEdit && (
							<FormCustomSelect
								value={tag.key}
								items={this._getAvailableTags()}
								onChange={(tagKey) => {
									const nextTag = labelsUtils.getLabelTagByKey(tagKey, labelGetters.getLabelClassId(this.props.label));

									this.props.onChangeTag({
										tagId: tag.id,
										tag: {
											key: tagKey,
											localizedName: nextTag.readable_name,
											hotKey: nextTag.hotkey,
										},
									});
								}}
							/>
						) }
					</div>
					{ canEdit && (
						<div className={controlsCssClassName}>
							<div
								data-tag-id={tag.id}
								className={removeButtonCssClassName}
								onClick={this._handleRemoveButtonClick}
							/>
						</div>
					) }
				</div>
			);
		});
	}

	_renderNew () {
		if ( this.props.availableTags.length === 0 ) {
			return null;
		}

		return (
			<div className={rowCssClassName}>
				<div className={tagIconCssClassName} />
				<div className={classnames([
					selectCssClassName,
					`${selectCssClassName}__m-add-new`,
				])}>
					<LabelTagsSelector
						value={'-1'}
						items={[ { value: '-1', label: 'Add new tag' } ].concat(this._getAvailableTags())}
						onChange={this._handleAddTag}
					/>
				</div>
			</div>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				{this._renderTags()}
				{this.props.isEditMode && this._renderNew()}
			</div>
		);
	}
}

export default connect((state, { label }) => {
	const usesReaderStudyModeUi = userSelectors.selectUsesReaderStudyModeUi(state);
	return {
		availableTags: labelsSelectors
			.selectAvailableTagsByClassId(state, { classId: labelGetters.getLabelClassId(label) })
			.map((data) => ({
				...data,
				canAdd: (data.canAdd === true && ( usesReaderStudyModeUi !== true || tagIsSurface(data.key) === false )),
			}))
			.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' })),
	};
},(dispatch) => ({
	onChangeTag: (data) => dispatch(labelTagsAction.changeTag(data)),
	onRemoveTag: (data) => dispatch(labelTagsAction.removeTag(data)),
	onAddTag: (data) => dispatch(labelTagsAction.addTag(data)),
}))(LabelTags);
