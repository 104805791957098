export default function () {
	return {
		'shared': require('./shared').default,
		'report': require('./report').default,
		'box-tooltip': require('./box-tooltip').default,
		'canvas': require('./canvas').default,
		'canvas-controls': require('./canvas-controls').default,
		'change-email': require('./change-email').default,
		'change-password': require('./change-password').default,
		'image': require('./image').default,
		'dental-notation-diagram': require('./dental-notation-diagram').default,
		'editor': require('./editor').default,
		'edit-collection-form': require('./edit-collection-form').default,
		'findings': require('./findings').default,
		'image-boxes': require('./image-boxes').default,
		'image-controls': require('./image-controls').default,
		'image-share': require('./image-share').default,
		'image-uploading-form': require('./image-uploading-form').default,
		'collections': require('./collections').default,
		'collection': require('./collection').default,
		'unsaved-changes': require('./unsaved-changes').default,
		'registry': require('./registry').default,
		'remove-collection-form': require('./remove-collection-form').default,
		'sign-in': require('./sign-in').default,
		'tooth-info': require('./tooth-info').default,
		'user-sidebar': require('./user-sidebar').default,
		'toolbar': require('./toolbar').default,
		'messenger': require('./messenger').default,
		'datepicker': require('./datepicker').default,
		'stats': require('./stats').default,
	};
}
