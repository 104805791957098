// @todo we should create initial state based on the config/mainConfig.
export default {
	user: {
		/*data: {
			firstName: 'FirstName',
			lastName: 'LastName',
			userName: 'VeryLongUserNameAlmostEndlessly',
			email: 'test@test.test',
			avatar: null,
			clinic: 'Dent Ltd',
			clinicLogo: null,
			// clinicLogo: 'http://n1s1.hsmedia.ru/25/01/8b/25018bc41bee5948c0db716d79187010/50x50_21_e368232b9ce212c6763606f5ae239b32@200x200_0xd42ee437_20055327721454958704.jpeg',
			specialization: 'Orthodont',
			// avatar: 'http://n1s1.hsmedia.ru/25/01/8b/25018bc41bee5948c0db716d79187010/50x50_21_e368232b9ce212c6763606f5ae239b32@200x200_0xd42ee437_20055327721454958704.jpeg',
			country: 'Italy',
		},*/
		data: {},
		isAuthenticated: false,
	},
};
