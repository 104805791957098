import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { events } from '../../services/events';
import ImagesCache from '../../services/images-cache';
import commonUtils from '../../appUtils/common';


export default class CollectionImageLoader extends Component {
	static propTypes = {
		image: PropTypes.object.isRequired,
		useEnhancedImage: PropTypes.bool,
		onStatusChanged: PropTypes.func,
	};

	static defaultProps = {
		useEnhancedImage: false,
		onStatusChanged: () => {},
	};

	static STATUSES = {
		IDLE: 'idle',
		IN_PROGRESS: 'progress',
		LOADED: 'loaded',
		FAILED: 'failed',
	};

	/**
	 * @type {HTMLImageElement|null}
	 * @private
	 */
	_image = null;

	constructor (props, context) {
		super(props, context);

		this.state = {
			status: CollectionImageLoader.STATUSES.IDLE,
			seed: ImagesCache.getCache(props.image.id),
		};
	}

	componentDidMount () {
		events.on('image.flip.finished', this._handleImageFlipFinished);
		this._loadImage();
	}

	componentDidUpdate (prevProps) {
		if ( prevProps.useEnhancedImage !== this.props.useEnhancedImage ) {
			this._loadImage();
		}
	}

	componentWillUnmount () {
		this._unsubscribe();
		events.removeListener('image.flip.finished', this._handleImageFlipFinished);
	}

	/**
	 * @param {CollectionImage} image
	 * @private
	 */
	_handleImageFlipFinished = (image) => {
		if ( this.props.image.hashname === image.hashname ) {
			this.setState({
				seed: ImagesCache.getCache(image.id),
			}, () => this._loadImage());
		}
	};

	_handleLoad = () => {
		this.setState({
			status: CollectionImageLoader.STATUSES.LOADED,
		});

		this.props.onStatusChanged(CollectionImageLoader.STATUSES.LOADED);
	};

	_handleError = () => {
		this.setState({
			status: CollectionImageLoader.STATUSES.FAILED,
		});
		this.props.onStatusChanged(CollectionImageLoader.STATUSES.FAILED);
	};

	_handleRetryLoad = () => {
		this._loadImage();
	}

	_loadImage () {
		if ( this.state.status !== CollectionImageLoader.STATUSES.IN_PROGRESS ) {
			this.setState({
				status: CollectionImageLoader.STATUSES.IN_PROGRESS,
			});
			this.props.onStatusChanged(CollectionImageLoader.STATUSES.IN_PROGRESS);
		}

		this._unsubscribe();

		this._image = new Image();
		this._image.crossOrigin = 'anonymous';
		this._image.addEventListener('load', this._handleLoad, false);
		this._image.addEventListener('error', this._handleError, false);
		this._image.src = this._getUrl();
	}

	_unsubscribe () {
		if ( this._image !== null ) {
			this._image.removeEventListener('load', this._handleLoad, false);
			this._image.removeEventListener('error', this._handleError, false);
		}
	}

	/**
	 * @return {string}
	 * @private
	 */
	_getUrl () {
		const url = (this.props.useEnhancedImage && this.props.image.enhanced_image_url
			? this.props.image.enhanced_image_url
			: this.props.image.image_url);
		if ( this.state.seed !== null ) {
			return commonUtils.addParamToUrl(url, 'seed', this.state.seed);
		}

		return url;
	}

	render () {
		return (this.props.children({
			status: this.state.status,
			image: this._image,
			retryLoad: this._handleRetryLoad,
		}));
	}
}
