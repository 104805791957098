import ApiError from './ApiError'

export default class AccessDeniedApiError extends ApiError {
  constructor(message) {
    super(message);

    this.name = 'AccessDeniedApiError';

    //  https://medium.com/@xpl/javascript-deriving-from-error-properly-8d2f8f315801
    this.constructor = AccessDeniedApiError;
    this.__proto__ = AccessDeniedApiError.prototype;
  }
}

AccessDeniedApiError.prototype = ApiError.prototype;
