import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import labelsUtils from '../../appUtils/labelsUtils';

import LabelToothControls from './LabelToothControls';
import MoveLabelSelector from '../ToothInfo/MoveLabelSelector';
import Label from '../Label';

import labelGetters from '../../modules/labels/selectors/labelGetters';

import './styles/LabelListItem.css';


const baseCssClassName = 'label-list-item';
const toothCssClassName = `${baseCssClassName}__tooth`;
const toothKeyCssClassName = `${baseCssClassName}__tooth-key`;
const toothMissingCssClassName = `${baseCssClassName}__tooth-missing`;
const mainCssClassName = `${baseCssClassName}__main`;
const labelChildrenCssClassName = `${baseCssClassName}__label-children`;
const labelChildCssClassName = `${baseCssClassName}__label-child`;
const labelChildControlsCssClassName = `${labelChildCssClassName}-controls`;
const labelChildMainCssClassName = `${labelChildCssClassName}-main`;
const labelContentCssClassName = `${baseCssClassName}__label-content`;
const labelControlCssClassName = `${baseCssClassName}__label-control`;


class LabelListItem extends PureComponent {
	static propTypes = {
		label: PropTypes.object.isRequired,
		isEditMode: PropTypes.bool,
		showConfirmation: PropTypes.bool.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		this.state = {
			isTooth: labelsUtils.labelIsTooth(props.label),
		};
	}

	componentWillReceiveProps (nextProps) {
		this.setState({
			isTooth: labelsUtils.labelIsTooth(nextProps.label),
		});
	}

	_getToothAvailableLabels (classId) {
		return this.props.onGetAvailableLabels({
			classId,
			excludeParent: true,
		});
	}

	_getAvailableLabels (onlyChildren) {
		const params = onlyChildren ? { onlyInRelations: true } : { onlyParents: true };
		return this.props.onGetAvailableLabels(params);
	}

	_handleToothClick = () => {
		this.props.onSelectLabel(this.props.label);
	}

	_handleLabelOver = () => {
		this.props.onLabelOver(this.props.label);
	}

	_handleLabelOut = () => {
		this.props.onLabelOut(this.props.label);
	}

	_handleAddChildLabel = (options) => {
		this.props.onAddLabel({
			...options,
			parentLabel: this.props.label,
			requiredShape: labelsUtils.isShapeRequiredForClass(options.classId),
		});
	}

	_renderLabelControls () {
		if ( !this.props.isEditMode ) {
			return null;
		}

		if ( this.state.isTooth ) {
			return (
				<LabelToothControls
					label={this.props.label}
					classes={
						this._getToothAvailableLabels('tooth')
							.map((label) => ({
								value: label.classId,
								label: label.localizedClassName,
								hotKey: label.hotKey,
								requiredLabel: label.requiredLabel,
							}))
					}
					isEditMode={this.props.isEditMode}
					toothKey={this.props.toothKey}
					onRemoveTooth={this.props.onRemove}
					onAddDiagnosis={this._handleAddChildLabel}
				/>
			);
		}

		return null;
	}

	_renderTooth () {
		if ( this.props.isEditMode ) {
			return null;
		}

		if ( this.state.isTooth ) {
			return (
				<div className={toothCssClassName}>
					<div
						className={toothKeyCssClassName}
						style={{
							borderColor: labelsUtils.getLabelColor(this.props.label),
						}}
						onClick={this._handleToothClick}
						onMouseOver={this._handleLabelOver}
						onMouseOut={this._handleLabelOut}
					>{this.props.displayedToothKey || '-'}</div>
				</div>
			);
		}

		return (
			<div className={toothCssClassName}>
				<div
					className={toothMissingCssClassName}
					style={{
						borderColor: labelsUtils.getLabelColor(this.props.label)
					}}
					onClick={this._handleToothClick}
					onMouseOver={this._handleLabelOver}
					onMouseOut={this._handleLabelOut}
				/>
			</div>
		);
	}

	_renderLabel () {
		const {
			tags,
		} = this.props;

		if ( this.state.isTooth ) {
			return (
				<div className={mainCssClassName}>
					<div className={labelChildrenCssClassName}>
						{this._renderChildren()}
					</div>
				</div>
			);
		}

		const labelId = labelGetters.getLabelId(this.props.label);
		const isSelectedLabel = Boolean(this.props.selectedLabel && labelId === labelGetters.getLabelId(this.props.selectedLabel));

		return (
			<div className={mainCssClassName}>
				{this.props.isEditMode && (
					<div className={labelControlCssClassName}>
						<MoveLabelSelector
							label={this.props.label}
							parentLabel={null}
							toothKey={this.props.toothKey}
							isEditMode
						/>
					</div>
				)}
				<div className={labelContentCssClassName}>
					<Label
						label={this.props.label}
						tags={tags[labelId] || []}
						showControls={this.props.showControls}
						isEditMode={this.props.isEditMode && isSelectedLabel}
						showConfirmation={this.props.showConfirmation}
						availableLabels={
							labelsUtils.labelIsTooth(this.props.label)
								? this._getToothAvailableLabels('tooth')
								: this._getAvailableLabels()
						}
						availableChildrenLabels={[]}

						onNameClick={this.props.onSelectLabel}
						onNameOver={this.props.onLabelOver}
						onNameOut={this.props.onLabelOut}
						onSetConfirmed={this.props.onSetConfirmed}
						onRemove={this.props.onRemove}
						onLabelChange={this.props.onLabelChange}
						onAddDiagnosis={this.props.onAddLabel}
					/>
				</div>
				{(this.props.label.children && this.props.label.children.length > 0) && (
					<div className={labelChildrenCssClassName}>
						{this._renderChildren()}
					</div>
				)}
			</div>
		);
	}

	_renderChildren () {
		const {
			tags
		} = this.props;

		if ( !this.props.label.children ) {
			return null;
		}

		return this.props.label.children.map((childLabel, i) => {
			const childLabelId = labelGetters.getLabelId(childLabel);
			const isSelectedLabel = Boolean(this.props.selectedLabel && childLabelId === labelGetters.getLabelId(this.props.selectedLabel));

			return (
				<div
					key={i}
					className={classnames([
						labelChildCssClassName,
						isSelectedLabel && `${labelChildCssClassName}__m-selected`,
					])}
				>
					{this.props.isEditMode && (
						<div className={labelChildControlsCssClassName}>
							<MoveLabelSelector
								label={childLabel}
								parentLabel={this.props.label}
								toothKey={this.props.toothKey}
								isEditMode={this.props.isEditMode && isSelectedLabel}
							/>
						</div>
					)}
					<div className={labelChildMainCssClassName}>
						<Label
							label={childLabel}
							selectedLabel={this.props.selectedLabel}
							showControls={this.props.showControls}
							isEditMode={this.props.isEditMode && isSelectedLabel}
							showConfirmation={this.props.showConfirmation}
							availableLabels={
								labelsUtils.labelIsTooth(this.props.label)
									? this._getToothAvailableLabels('tooth')
									: this._getAvailableLabels(true)
							}
							availableChildrenLabels={[]}
							tags={tags[childLabelId] || []}

							onNameClick={this.props.onSelectLabel}
							onNameOver={this.props.onLabelOver}
							onNameOut={this.props.onLabelOut}
							onSetConfirmed={this.props.onSetConfirmed}
							onRemove={this.props.onRemove}
							onLabelChange={this.props.onLabelChange}
							onAddDiagnosis={this.props.onAddLabel}
						/>
					</div>
				</div>
			);
		});
	}

	render () {
		return (
			<div className={classnames([
				baseCssClassName,
				this.props.isEditMode && `${baseCssClassName}__m-edit-mode`,
			])}>
				{this._renderLabelControls()}
				{this._renderTooth()}
				{this._renderLabel()}
			</div>
		);
	}
}

export default LabelListItem;
