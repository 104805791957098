import commonActionTypes from '../actions/commonActionTypes';

/**
 * @typedef {Object} ProcedureOption
 *
 * @property {string} value
 * @property {string} label
 */


/**
 * @typedef {Object} Procedure
 *
 * @property {string} code
 * @property {string} description
 */

/**
 * @typedef {Procedure[]} ProceduresStoreState
 */

/**
 *
 * @param {ProceduresStoreState} state
 * @param {ReduxAction} action
 * @return {ProceduresStoreState}
 */
export default function proceduresReducer (state = {}, action) {
	switch (action.type) {
		case commonActionTypes.ACTION_PROCEDURES__SET_DATA:
			return action.payload;

		default:
			return state;
	}
}
