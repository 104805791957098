import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '../../../../components/Toolbar';
import mainConfig from '../../../../configs/mainConfig';
import aclService from '../../../../services/acl';
import {
	USER_PERMISSION__FLIP_IMAGE,
	USER_PERMISSION__ROTATE_IMAGE
} from '../../../../constants/userPermissionsConstants';

import './styles/ResolverEditorToolbar.css';


const baseCssClassName = 'resolver-editor-toolbar';
const toolbarCssClassName = `${baseCssClassName}__toolbar`;


export default class ResolverEditorToolbar extends PureComponent {
	static propTypes = {
		isFmxModeEnabled: PropTypes.bool.isRequired,
		areFindingsMasksFromProfileEnabled: PropTypes.bool.isRequired,
		areHeatMapsFromProfileEnabled: PropTypes.bool.isRequired,
	};

	_getToolbarConfig () {
		let items = mainConfig.TOOLBAR_ORDER.filter((item) => {
			if (
				item === mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL ||
				item === mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL
			) {
				return aclService.checkPermission(USER_PERMISSION__FLIP_IMAGE) && this.props.isFmxModeEnabled === false;
			}

			if (
				item === mainConfig.TOOLBAR_ITEM__ROTATE_LEFT ||
				item === mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT
			) {
				return aclService.checkPermission(USER_PERMISSION__ROTATE_IMAGE) && this.props.isFmxModeEnabled === false;
			}

			return (
				item !== mainConfig.TOOLBAR_ITEM__DRAW_MODE &&
				item !== mainConfig.TOOLBAR_ITEM__GRID &&
				item !== mainConfig.TOOLBAR_ITEM__BONE_LEVELS &&
				item !== mainConfig.TOOLBAR_ITEM__HIDE_FINDINGS &&
				item !== mainConfig.TOOLBAR_ITEM__HEAT_MAP &&
				item !== mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS
			);
		});

		return items;
	}


	render () {
		return (
			<div className={baseCssClassName}>
				<div className={toolbarCssClassName}>
					<Toolbar items={this._getToolbarConfig()} />
				</div>
			</div>
		);
	}
}
