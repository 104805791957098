/**
 * @param {StoreState} state
 * @return {LabelsTagsStoreState}
 */
const selectLabelsTags = (state) => (state.labelsTags || {});

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @return {LabelTagId[]}
 */
const selectLabelTagsByLabelId = (state, options) => (selectLabelsTags(state)[options.labelId] || []);


export default {
	selectLabelsTags,
	selectLabelTagsByLabelId,
};
