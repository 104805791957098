import lodashGet from 'lodash/get';
import lodashMemoize from 'lodash/memoize';
import { getRuntimeConfig } from './runtimeConfig';

import { getStore } from '../services/store';
import { getLabelName } from '../services/labels';
import { isMissingTooth, isShapeIsPresent } from '../modules/resolver/utils';
import { hslToRgb } from './colors';

import currentCollectionSelectors from '../selectors/currentCollectionSelectors';
import imagesSelectors from '../selectors/imagesSelectors';
import editorSelectors from '../selectors/editorSelectors';
import userSelectors from '../selectors/userSelectors';

import labelChildrenSelectors from '../modules/labels/selectors/labelChildrenSelectors';
import labelsSelectors from '../modules/labels/selectors/labelsSelectors';
import labelTagsSelectors from '../modules/label-tags/selectors/labelTagsSelectors';
import imagesLabelsSelectors from '../modules/labels/selectors/imagesLabelsSelectors';

import labelGetters from '../modules/labels/selectors/labelGetters';
import labelTagGetter from '../modules/label-tags/selectors/labelTagGetter';

import ImageBox from '../components/ImageBox/ImageBox';
import collectionsSelectors from "../selectors/collectionsSelectors";



/**
 * @param {LabelClassId} classId
 * @return {string}
 */
function getLocalizedLabelNameByClassId (classId) {
	return getLabelName(classId) || classId;
}

/**
 * @param {Label} label
 * @return {string}
 */
function getLocalizedLabelName (label) {
	return getLocalizedLabelNameByClassId(labelGetters.getLabelClassId(label));
}

/**
 * @param {Label} label
 * @return {string}
 */
function getLocalizedClass (label) {
	return getLocalizedClassByClassId(labelGetters.getLabelClassId(label));
}

/**
 * @param {Label} label
 * @return {LabelShape}
 */
const getShape = lodashMemoize((label) => {
	let classId = label;
	let shape = null;

	if ( typeof label === 'object' && label ) {
		classId = labelGetters.getLabelClassId(label);
	}
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});

	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			shape = labelData.shape;
		}
	});

	return shape;
});

/**
 * @param {Label} label
 * @return {string}
 */
const getShapeBorderStyle = lodashMemoize((label) => {
	let style = ImageBox.BORDER_STYLES.DASHED;
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const classId = labelGetters.getLabelClassId(label);
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			style = lodashGet(labelData, [ 'shape', 'border_style' ], style);
		}
	});

	return style;
}, (label) => labelGetters.getLabelClassId(label));

/**
 * @param {LabelClassId} classId
 * @return {string[]}
 */
const getNamedPolyDotNames = lodashMemoize((classId) => {
	let dotNames = [];
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	collection.label.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			dotNames = lodashGet(labelData, [ 'shape', 'meta', 'dot_names' ], dotNames);
		}
	});

	return dotNames;
});

/**
 * @param {LabelClassId} classId
 * @return {string}
 */
function getLabelColorById (classId, confidencePercent) {
	let color = '#00ad66';

	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			color = lodashGet(labelData, [ 'shape', 'color' ], color);
		}
	});

	if ( classId === 'missing_tooth' ) {
		return '#aaaaaa';
	}

	const fdaColor = getSpecialLabelColor(classId, confidencePercent);
	if (fdaColor) {
		return fdaColor;
	}

	return color;
}

/**
 * @param label
 * @return {number}
 */
const getLabelColorPriorityById = lodashMemoize((label) => {
	let priority = 0;
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	const classId = labelGetters.getLabelClassId(label);
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			priority = lodashGet(labelData, [ 'shape', 'color_priority' ], priority);
		}
	});

	return priority;
}, (label) => labelGetters.getLabelClassId(label));

function getColorValue(max, min, confidence) {
	return max - (max - min) * confidence;
}

function getSpecialLabelColor (classId, confidencePercent = 1) {
	const storeState = getStore().getState();
	if ( !userSelectors.selectIsFDAAnnotationEnabled(storeState) ) {
		return false;
	}

	if ( classId === 'caries' ) {
		if ( confidencePercent < 1 ) {
			return `hsl(0,100%,${getColorValue(95, 50, confidencePercent)}%)`;
		}
		else {
			// return 'rgb(237,32,15)';
			// return 'hsl(45, 100%, 60%)';
			return 'hsl(315, 100%, 60%)';
		}
	}
	else if ( classId === 'periodontitis' ) {
		if ( confidencePercent < 1 ) {
			return `hsl(0,100%,${getColorValue(95, 50, confidencePercent)}%)`;
		}
		else {
			return 'hsl(60, 100%, 60%)';
		}
	}
	else {
		return false;
	}
}

/**
 * @param {string} classId
 * @param {number} matrixValue
 * @param {number} [measureOfConfidence=0]
 *
 * @return {[number,number,number]|null} RGB color
 */
const getHeatMapColor = lodashMemoize((classId, matrixValue, measureOfConfidence = 0) => {
	let alpha = 85;
	const breaker = getRuntimeConfig()['heat_map_cutoff_threshold'];
	if ( matrixValue <= breaker ) {
		alpha = (matrixValue * matrixValue) / (breaker * breaker) * alpha;
	}

	const rgb = hslToRgb(0, 1, getColorValue(95, 50,  (measureOfConfidence * matrixValue) /  255) / 100);
	rgb[3] = alpha;
	return rgb;
}, (a, b, c) => a + b + c);

/**
 * @param {Label} label
 * @param {function:boolean} filterFn
 * @return {string}
 */
function getLabelColor (label, filterFn = () => true) {
	if ( !label ) {
		return '#838383';
	}

	const storeState = getStore().getState();
	const labelId = labelGetters.getLabelId(label);
	const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);

	if ( labelChildren[labelId] && labelChildren[labelId].length > 0 ) {
		const children = labelChildren[labelId]
			.map((childLabelId) =>
				labelsSelectors.selectLabelById(storeState, {
					labelId: childLabelId,
				})
			)
			.filter(filterFn);

		if ( children.length > 0 ) {
			// Add parent label
			children.push(label);
			children.sort((a, b) => getLabelColorPriorityById(b) - (getLabelColorPriorityById(a)));
			return getLabelColorById(labelGetters.getLabelClassId(children[0]));
		}
	}

	return getLabelColorById(labelGetters.getLabelClassId(label), labelGetters.getLabelMeasureOfConfidence(label));
}

/**
 * @param {LabelClassId} classId
 * @return {string}
 */
const getLocalizedClassByClassId = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let name = classId;
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			name = labelData.readable_name;
			const user = userSelectors.selectUserData(storeState);
			const stage1LowerBoundary = typeof user.stage_1_ratio_threshold === 'number'
				? Math.round(user.stage_1_ratio_threshold * 100)
				: 0;

			switch (classId) {
				case 'stage_0_bone_loss_line':
					name = name.replace('MinRatio%', `${stage1LowerBoundary}%`);
					break;

				case 'stage_1_bone_loss_line':
					name = name.replace('MinRatio%', `${stage1LowerBoundary}%`);
					break;
			}
		}
	});

	return name;
});

/**
 * Returns all label configs for passed image type.
 *
 * @param {CollectionImageType} imageType
 * @return {Object<LabelClassId, CollectionLabel>}
 */
const getCollectionLabelConfig = lodashMemoize((imageType) => {
	const storeState = getStore().getState();
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	return collection.labels.reduce((result, labelData) => {
		if ( imageType === labelData.image_type ) {
			result[labelData.class_id] = labelData;
		}

		return result;
	}, {});
});

/**
 * @param {LabelClassId} classId
 * @return {LabelParam[]}
 */
const getParamsByClassId = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let params = [];
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			params = labelData.params;
		}
	});

	return params || [];
});

/**
 * @param {LabelClassId} classId
 * @param {LabelParam} param
 * @return {string|null}
 */
function getLocalizedParam (classId, param) {
	const params = getParamsByClassId(classId);
	if ( params && params.length > 0 ) {
		const foundParam = params.find((_param) => _param.name === param);
		if ( foundParam ) {
			return foundParam.readable_name;
		}
	}
	return param;
}

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
const isSurfaceRequiredForClass = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let required = false;
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			required = labelData.surface_required;
		}
	});

	return required;
});

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
const isDateRequiredForClass = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let required = false;
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			required = labelData.date_required;
		}
	});

	return required;
});

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
const isToothRequiredForClass = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let required = false;
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			required = labelData.tooth_required;
		}
	});

	return required;
});

/**
 * @param {string} key
 * @return {string}
 */
function getLabelByKey (key) {
	const storeState = getStore().getState();
	const hotKeys = currentCollectionSelectors.selectCurrentCollectionHotKeys(storeState, {
		action: 'add_labels',
	});

	return hotKeys[key];
}

/**
 * @param {string} key
 * @param {LabelClassId} classId
 * @return {LabelTag|null}
 */
function getLabelTagByKey (key, classId) {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let tag = null;

	collection.labels.forEach((labelData) => {
		if (
			currentImage.image_type === labelData.image_type &&
			( !classId || labelGetters.getLabelClassId(labelData) === classId )
		) {
			(labelData.attributes || []).forEach((labelTag) => {
				if ( labelTag.key === key ) {
					tag = labelTag;
				}
			});
		}
	});

	return tag;
}

/**
 * @param {string} key
 * @return {LabelTag|null}
 */
const getLabelTagByHotKey = lodashMemoize((key) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let tag = null;

	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type ) {
			(labelData.attributes || []).forEach((labelTag) => {
				if ( labelTag.hotkey === key ) {
					tag = labelTag;
				}
			});
		}
	});

	return tag;
});

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
const isShapeRequiredForClass = lodashMemoize((classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	let required = true;
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			if ( labelData.hasOwnProperty('shape_required') || labelData.hasOwnProperty('box_required') ) {
				required = labelData.shape_required || labelData.box_required;
			}
		}
	});

	return required;
});

/**
 * @param {LabelClassId} classId
 * @param {string} imageType
 * @return {boolean}
 */
const shouldShowConfidenceForClassInternal = lodashMemoize((classId, imageType) => {
	const storeState = getStore().getState();
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	const labels = collection.labels;

	for (let i = 0; i < labels.length; i++) {
		const labelData = labels[i];
		if (
			imageType === labelData.image_type &&
			labelGetters.getLabelClassId(labelData) === classId &&
			typeof labelData.show_confidence === 'boolean'
		) {
			return labelData.show_confidence;
		}
	}

	return true;
}, (a, b) => a + b);

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
const shouldShowConfidenceForClass = (classId) => {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});

	return shouldShowConfidenceForClassInternal(classId, currentImage.image_type);
};

/**
 * @param {LabelClassId} classId
 * @return {boolean}
 */
function getLabelAvailabilityOptions (classId) {
	const storeState = getStore().getState();
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: editorSelectors.selectCurrentImageId(storeState),
	});
	const collection = collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	});
	const result = {
		findings_group: null,
		is_show_on_export: false,
		is_watch_allowed: false,
	};
	collection.labels.forEach((labelData) => {
		if ( currentImage.image_type === labelData.image_type && labelGetters.getLabelClassId(labelData) === classId ) {
			result.findings_group = labelData.findings_group;
			result.is_show_on_export = labelData.is_show_on_export  === true;
			result.is_watch_allowed = labelData.is_watch_allowed === true;
		}
	});

	return result;
}

/**
 * @param {StoreState} storeState
 * @param {LabelClassId} classId
 * @param {string} action
 * @return {string|null}
 */
function getHotKeyByClassId ({
	storeState,
	classId,
	action,
}) {
	const hotKeys = currentCollectionSelectors.selectCurrentCollectionHotKeys(storeState, {
		action,
	});
	let hotKey = null;
	Object.keys(hotKeys).forEach((key) => {
		if ( hotKeys[key] === classId ) {
			hotKey = key;
		}
	});

	return hotKey;
}

/**
 * @param {Label} label
 * @return {boolean}
 */
function labelIsTooth (label) {
	return (labelGetters.getLabelClassId(label) === 'tooth');
}

/**
 * @param {StoreState} state
 * @param {LabelId} childLabelId
 * @return {LabelId|null}
 */
function findParentLabelId (state, childLabelId) {
	let parentLabelId = null;

	const labels = labelChildrenSelectors.selectLabelChildren(state);
	Object.keys(labels).forEach((labelId) => {
		labels[labelId].forEach((_childLabelId) => {
			if ( _childLabelId === childLabelId ) {
				parentLabelId = labelId;
			}
		});
	});

	return parentLabelId;
}

/**
 * @param {LabelShape} shape
 * @return {{top: number, left: number, bottom: number, right: number}}
 */
function getBoundingRectForShape (shape) {
	switch (shape.type) {
		case 'poly':
		case 'named_poly': {
			let top = shape.points[0][1];
			let left = shape.points[0][0];
			let right = shape.points[0][0];
			let bottom = shape.points[0][1];

			for (let j = 0, jl = shape.points.length; j < jl; j++) {
				const point = shape.points[j];
				const x = point[0];
				const y = point[1];

				if ( x < left ) {
					left = x;
				}
				if ( x > right ) {
					right = x;
				}
				if ( y < top ) {
					top = y;
				}
				if ( y > bottom ) {
					bottom = y;
				}
			}

			return {
				top,
				left,
				right,
				bottom,
			};
		}

		case 'box':
			return {
				left: shape.startX,
				top: shape.startY,
				right: shape.endX,
				bottom: shape.endY,
			};

		default:
			return { left: 0, top: 0, right: 0, bottom: 0 };
	}
}

/**
 * @param {LabelShape} shape
 * @param {number} x
 * @param {number} y
 * @return {boolean}
 */
const isCursorInShape = (shape, x, y) => {
	switch (shape.type) {
		case 'poly':
		case 'named_poly':
			let points = shape.points;
			let length = points.length;

			// Line as a box
			// if ( length === 2 ) {
			// 	const left = Math.min(points[0][0], points[1][0]);
			// 	const right = Math.max(points[0][0], points[1][0]);
			// 	const top = Math.min(points[0][1], points[1][1]);
			// 	const bottom = Math.max(points[0][1], points[1][1]);
			//
			// 	return (
			// 		left < x &&
			// 		right > x &&
			// 		top < y &&
			// 		bottom > y
			// 	);
			// }

			if ( length === 1 ) {
				// distance from the cursor for a single point
				return Math.hypot(points[0][0] - x, points[0][1] - y) < 20
			}

			// //try to found outer box for a polygon
			// let left;
			// let right;
			// let top;
			// let bottom;
			// for (let i = 0; i < length; i++){
			// 	if( !left || left > points[i][0]) {
			// 		left = points[i][0]
			// 	}
			// 	if( !right || right < points[i][0]) {
			// 		right = points[i][0]
			// 	}
			// 	if( !top || top > points[i][1]) {
			// 		top = points[i][1]
			// 	}
			// 	if( !bottom || bottom < points[i][1]) {
			// 		bottom = points[i][1]
			// 	}
			// }
			//
			//
			// if ((right - left) < MIN_BOX_SIZE) {
			// 	const margin = MIN_BOX_SIZE - right + left;
			// 	left -= margin;
			// 	right += margin;
			// }
			//
			// if ((bottom - top) < MIN_BOX_SIZE) {
			// 	const margin = MIN_BOX_SIZE - bottom + top;
			// 	top -= margin;
			// 	bottom += margin;
			// }

			// return (
			// 	left < x &&
			// 	right > x &&
			// 	top < y &&
			// 	bottom > y
			// );


			//Ray tracing for poly
			let j = length - 1;

			let result = false;
			for (let i = 0; i < length; i++){
				const [ px, py ] = points[i];
				const [ jpx, jpy ] = points[j];

				if (
					(((py <= y) && (y < jpy)) || ((jpy <= y) && (y < py))) &&
					(x > (jpx - px) * (y - py) / (jpy - py) + px))
				{
					result = !result
				}
				j = i;
			}
			return result;

		case 'box':
			return (
				shape.startX < x &&
				shape.endX > x &&
				shape.startY < y &&
				shape.endY > y
			);

		default:
			return false;
	}
};

/**
 * @param {StoreState} state
 * @return {Object<string,Object<string,{ toothKey: string, existing: boolean }>>}
 */
export function getTeethInfo ({ state }) {
	return Object.values(imagesSelectors.selectImages(state)).reduce((result, image) => {
		imagesLabelsSelectors.selectImageLabelsByImageId(state, { imageId: image.id }).forEach((labelId) => {
			const label = labelsSelectors.selectLabelById(state, { labelId });
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
			if (
				labelGetters.getLabelClassId(label) === 'tooth'
				&& Array.isArray(tags) === true &&
				tags.length > 0
			) {
				const toothKey = labelTagGetter.getTagKey(tags[0]);
				if ( typeof result[image.hashname] === 'undefined' ) {
					result[image.hashname] = {};
				}

				result[image.hashname][toothKey] = {
					toothKey,
					existing: false,
				};

				if ( isMissingTooth(toothKey, { imageId: image.id }) === true ) {
					return;
				}

				const shape = label.shapes[image.hashname] || {};

				if ( isShapeIsPresent(shape) === true ) {
					result[image.hashname][toothKey].existing = true;
				}
			}
		});
		return result;
	}, {});
}

export default {
	getLocalizedLabelNameByClassId,
	getLocalizedLabelName,
	getLocalizedClass,
	getLocalizedClassByClassId,
	getCollectionLabelConfig,
	getShape,
	getShapeBorderStyle,
	getLabelColorById,
	getLabelColor,
	getSpecialLabelColor,
	getParamsByClassId,
	getLocalizedParam,
	getLabelByKey,
	getLabelTagByKey,
	getLabelTagByHotKey,
	getLabelAvailabilityOptions,
	getHotKeyByClassId,
	getNamedPolyDotNames,
	isShapeRequiredForClass,
	isSurfaceRequiredForClass,
	isDateRequiredForClass,
	isToothRequiredForClass,
	shouldShowConfidenceForClass,
	labelIsTooth,
	findParentLabelId,
	getBoundingRectForShape,
	isCursorInShape,
	getTeethInfo,
	getHeatMapColor,
};
