import editorActions from '../../../actions/editorActions';
import storeUtils from '../../../appUtils/storeUtils';
import labelsUtils from '../../../appUtils/labelsUtils';
import helpers from '../../../appUtils/helpers';

import imageApi from '../../../api/imageApi';
import collectionApi from '../../../api/collectionApi';

import labelsActionTypes from './labelsActionTypes';
import mainConfig from '../../../configs/mainConfig';

import labelTagsAction from '../../label-tags/actions/labelTagsAction';
import apiActions from '../../../actions/apiActions';
import collectionsActions from '../../../actions/collectionsActions';

import editorSelectors from '../../../selectors/editorSelectors';
import labelsSelectors from '../selectors/labelsSelectors';
import labelChildrenSelectors from '../selectors/labelChildrenSelectors';
import currentCollectionSelectors from '../../../selectors/currentCollectionSelectors';
import userSelectors from '../../../selectors/userSelectors';
import imagesSelectors from '../../../selectors/imagesSelectors';

import labelGetters from '../selectors/labelGetters';

import { message } from '../../../services/popup';

import { getDictionary } from '../../../appUtils/locale';
import collectionsSelectors from "../../../selectors/collectionsSelectors";


const i18nShared = getDictionary('shared');


// @todo HACK: saving labels should go via service to handle an error.
/**
 * Saves labels.
 *
 * @param {Object} [options={}]
 * @param {boolean} [options.markHasNoChanges]
 * @param {boolean} [options.skipSyncWithReanalyse]
 * @returns {Function}
 */
function saveLabels (options = {}) {
	return async (dispatch, getState) => {
		let markHasNoChanges = options.markHasNoChanges;
		const skipSyncWithReanalyse = options.skipSyncWithReanalyse || false;

		if ( markHasNoChanges === undefined ) {
			markHasNoChanges = true;
		}

		const storeState = getState();
		const currentCollectionId = editorSelectors.selectEditor(storeState).currentCollectionHashName;
		const currentImage = helpers.toApiStructure({
			storeState,
			imageId: editorSelectors.selectCurrentImageId(storeState),
		});

		dispatch(editorActions.updateData({
			data: {
				isSavingChanges: true,
			},
		}));

		const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(getState());

		if ( !currentCollectionId || !currentImage.hashname ) {
			return Promise.resolve();
		}

		try {
			const data = await imageApi.saveAnnotations(
				currentCollectionId,
				currentImage.hashname,
				{ labels: (currentImage.labels || {}) },
				isFDAAnnotationEnabled
			);

			if ( skipSyncWithReanalyse === false ) {
                const editorData = editorSelectors.selectEditor(storeState);
                const notAnalyzedImages = editorData.notAnalyzedImages;
                const currentExamination = editorData.currentExamination;
                const collection = collectionsSelectors.selectCollectionById(storeState, { id: currentCollectionId });
                let examinationId = null;
                let reanalyzeValue = false;
                const shouldUpdateImages = collection.images
                    .filter((image) => image.examination === currentExamination)
                    .some((image) => {
                        let result = false;
                        if ( notAnalyzedImages.includes(image.id) === true && image.reanalyze_required === false ) {
                            examinationId = image.examination_id;
                            reanalyzeValue = true;
                            result = true;
                        }
                        else if ( notAnalyzedImages.includes(image.id) === false && image.reanalyze_required === true ) {
                            examinationId = image.examination_id;
                            reanalyzeValue = false;
                            result = true;
                        }

                        return result;
                    });

                if ( shouldUpdateImages === true ) {
                    await dispatch(collectionsActions.markReanalyzeRequired({
                        collectionId: currentCollectionId,
                        examinationId,
                        value: reanalyzeValue,
                    }));
                }
			}

			const nextEditorData = {
				data: {
					isSavingChanges: false,
				},
			};
			
			let nextApiData = null;

			if ( markHasNoChanges ) {
				nextEditorData.data.hasUnsavedChanges = false;
			}

			dispatch(editorActions.updateData(nextEditorData));

			if ( data.labels ) {
				nextApiData = helpers.syncLabels({
					storeState: getState(),
					labels: data.labels,
				});
			}

			if ( nextApiData ) {
				dispatch(apiActions.setData(nextApiData));
			}
			
		}
		catch (error) {
			dispatch(editorActions.updateData({
				data: {
					isSavingChanges: false,
				},
			}));
			
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: 'An error occurred when syncing image data. Please try again.',
			});
			throw error;
		}
	};
}

/**
 * Sets a new confirm value for label.
 *
 * @param {Object} options
 * @param {string} options.labelId
 * @param {boolean} options.value
 * @returns {Function}
 */
function setConfirmed (options = {}) {
	return (dispatch) => {
		dispatch(editorActions.putInImageHistory(async () => {
			dispatch(storeUtils.makeAction(labelsActionTypes.ACTION_LABELS__SET_CONFIRMED, {
				labelId: options.labelId,
				value: options.value,
			}));
		}));
	};
}

/**
 * Adds a new label.
 *
 * @param {Object} options
 * @param {CollectionImageId} options.imageId
 * @param {LabelClassId} options.classId
 * @param {LabelId} options.labelId
 * @param {LabelShape} options.shape
 * @param {Object<CollectionImage.hashname,LabelShape>} options.shapes
 * @param {LabelMeta} options.meta
 * @param {Label.surfaces} options.surfaces
 * @param {Label.source} options.source
 * @param {Label.params} options.params
 * @param {Label.date} options.date
 * @param {Label} [options.parentLabel]
 * @param {LabelTag} [options.tag]
 * @return {Function}
 */
function addLabel (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.addLabel({
				storeState,
				imageId: options.imageId,
				classId: options.classId,
				labelId: options.labelId,
				shape: options.shape,
				meta: options.meta,
				surfaces: options.surfaces,
				source: options.source,
				params: options.params,
				date: options.date,
			});

			if ( options.parentLabel ) {
				const parentLabelId = options.parentLabel.labelId;
				data.labelChildren = {
					...labelChildrenSelectors.selectLabelChildren(storeState),
				};

				if ( data.labelChildren[parentLabelId] ) {
					data.labelChildren[parentLabelId] = [
						...data.labelChildren[parentLabelId],
					];
				}
				else {
					data.labelChildren[parentLabelId] = [];
				}

				data.labelChildren[parentLabelId].push(options.labelId);
			}

			dispatch(apiActions.setData(data));

			if ( options.tag ) {
				const tag = labelsUtils.getLabelTagByKey(options.tag, options.classId);
				dispatch(labelTagsAction.addTag({
					labelId: options.labelId,
					tag: {
						key: tag.key,
						localizedName: tag.readable_name,
						hotKey: tag.hotkey,
					},
				}));
			}
		}));
	};
}

/**
 * Removes a label.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {CollectionImageId} options.imageId
 * @return {Function}
 */
function removeLabel (options = {}) {
	return (dispatch, getState) => {
		return dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();
			const labelId = options.labelId;
			const label = labelsSelectors.selectLabelById(storeState, {
				labelId,
			});

			const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
			const labelParentMap = {};
			Object.keys(labelChildren)
				.forEach((labelId) => {
					labelChildren[labelId].forEach((childLabelId) => {
						labelParentMap[childLabelId] = labelId;
					});
				});

			const parentLabelId = labelParentMap[labelId];

			const data = helpers.removeLabel({
				storeState,
				imageId: (options.imageId || editorSelectors.selectEditor(getState()).currentImageId),
				labelId,
			});

			dispatch(apiActions.setData(data));

			if ( labelsUtils.labelIsTooth(label) ) {
				dispatch(editorActions.resetMode());
			}
			else if (parentLabelId) {
				dispatch(editorActions.selectLabel({
					labelId: parentLabelId,
				}));
			}

			// мы не можем полагаться на этот неактуальный стейт
			const selectedLabel = editorSelectors.selectEditor(storeState).selectedLabel;
			if (selectedLabel && selectedLabel.labelId === label.labelId) {
				// а это вообще наверно не имеет смысла
				dispatch(editorActions.selectLabel({ box: null }));
			}
		}));
	};
}

/**
 * Changes a label.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {LabelClassId} options.newClassId
 * @return {Function}
 */
function changeLabel (options = {}) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.changeLabel({
				storeState,
				labelId: options.labelId,
				newClassId: options.newClassId,
			});

			dispatch(apiActions.setData(data));
			
			const label = labelsSelectors.selectLabelById(storeState, {
				labelId: options.labelId,
			});
			
			const shape = labelGetters.getLabelShape(label);
			
			if ( labelsUtils.isShapeRequiredForClass(options.newClassId) && !shape || !shape.type ) {
				dispatch(
					editorActions.updateData({
						data: {
							editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE,
						},
					})
				);
			}
		}));
	};
}

/**
 * Confirms all image labels.
 *
 * @param {Object} [options={}]
 * @param {CollectionImageId} [options.imageId]
 * @return {Function}
 */
function confirmAllLabels (options = {}) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.confirmAllLabels({
				storeState,
				imageId: (options.imageId || editorSelectors.selectEditor(getState()).currentImageId),
			});

			dispatch(apiActions.setData(data));
		}));
	};
}

/**
 * Removes all image labels.
 *
 * @param {Object} [options={}]
 * @param {CollectionImageId} [options.imageId]
 * @param {CollectionImageId} [options.withTeeth=true]
 * @return {Function}
 */
function removeAllLabels (options = { withTeeth: true }) {
	return (dispatch, getState) => {
		return dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.removeAllLabels({
				storeState,
				imageId: (options.imageId || editorSelectors.selectEditor(getState()).currentImageId),
				withTeeth: options.withTeeth,
			});

			return dispatch(apiActions.setData(data));
		}));
	};
}

/**
 * Updates a label shape.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {CollectionImage.hashname} options.imageHashName
 * @param {LabelShape} options.data
 * @return {Function}
 */
function updateLabelShape (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const labelId = options.labelId;
			const label = labelsSelectors.selectLabelById(getState(), { labelId });
			const nextLabel = {
				...label,
			};

			if ( label.hasOwnProperty('shapes') === true && typeof options.imageHashName !== 'undefined' ) {
				nextLabel.shapes[options.imageHashName] = options.data;
			}
			else {
				nextLabel.shape = options.data;
			}

			dispatch(storeUtils.makeAction(labelsActionTypes.ACTION_LABELS__UPDATE_DATA, {
				labelId,
				data: nextLabel,
			}));
		}));
	};
}

/**
 * Updates a label data.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {Label} options.data
 * @return {Function}
 */
function updateLabelData (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const labelId = options.labelId;
			const label = labelsSelectors.selectLabelById(getState(), { labelId });
			dispatch(storeUtils.makeAction(labelsActionTypes.ACTION_LABELS__UPDATE_DATA, {
				labelId,
				data: {
					...label,
					...options.data,
				},
			}));
		}));
	};
}

/**
 * Moves label to new parent.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId Target
 * @param {LabelId} options.parentLabelId From
 * @param {LabelId} options.newParentLabelId To
 * @param {LabelShape} options.data
 * @return {Function}
 */
function moveLabel (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.moveLabel({
				storeState,
				labelId: options.labelId,
				parentLabelId: options.parentLabelId,
				newParentLabelId: options.newParentLabelId,
			});

			dispatch(apiActions.setData(data));
		}));
	};
}

/**
 * Shifts teeth.
 *
 * @param {Object} options
 * @param {string[]} options.toothKeysToShift
 * @param {string} options.direction
 * @return {Function}
 */
function shiftTeeth (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.shiftTeeth({
				storeState,
				toothKeysToShift: options.toothKeysToShift,
				direction: options.direction,
			});

			dispatch(apiActions.setData(data));
		}));
	};
}


/**
 * Shifts teeth.
 *
 * @param {Object} options
 * @param {string} options.toothKey
 * @param {string} options.nextToothKey
 * @param {string} options.imageHashName
 * @return {Function}
 */
function shiftTooth (options) {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const image = imagesSelectors.selectImageByHashName(storeState, { hashname: options.imageHashName });

		if ( editorData.teethToShift.some((descriptor) => (
			descriptor.imageHashName === image.hashname &&
			descriptor.from === options.toothKey
		)) ) {
			const nextTeethToShift = editorData.teethToShift.filter((descriptor) => descriptor.from !== options.toothKey);
			if ( options.toothKey !== options.nextToothKey ) {
				nextTeethToShift.push({ imageHashName: image.hashname, from: options.toothKey, to: options.nextToothKey });
			}

			dispatch(editorActions.updateData({
				data: {
					teethToShift: nextTeethToShift,
				},
			}));
		}
		else {
			dispatch(editorActions.updateData({
				data: {
					teethToShift: [
						...editorData.teethToShift,
						{ imageHashName: image.hashname, from: options.toothKey, to: options.nextToothKey },
					],
				},
			}));
		}
	};
}

export default {
	saveLabels,
	setConfirmed,
	addLabel,
	removeLabel,
	changeLabel,
	confirmAllLabels,
	removeAllLabels,
	updateLabelShape,
	updateLabelData,
	moveLabel,
	shiftTeeth,
	shiftTooth,
};
