import { connect } from 'react-redux';
import mainConfig from '../../../../configs/mainConfig';

import {
	LABEL_VALIDATION_ERROR__BOX_REQUIRED,
	LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS,
} from '../../../labels/constants';

import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';

import {
	checkFindingsFilter,
	getFindingCategory,
	isExtraFinding,
} from '../../../resolver/utils/findings-filter';
import { isSequentialModeEnabledForVisit } from '../../../resolver/utils';

import labelsActions from '../../../labels/actions/labelsActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../../label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';

import ResolverSidebar from '../../../resolver/components/findings';


export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImageId = editorData.currentImageId;
		const selectedToothKey = editorData.selectedToothKey;
		const selectedLabel = editorData.selectedLabel;
		const selectedLabelId = editorData.selectedLabel ? labelGetters.getLabelId(editorData.selectedLabel) : null;
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		const labelParentMap = {};
		const findingsFilter = editorData.treatmentPlanFilters;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
		const categories = [
			{
				title: 'Tooth Level',
				allowExpand: false,
				rows: [],
			}/*,
			{
				title: 'Visit level',
				allowExpand: false,
				rows: [],
			}*/
		];

		let selectedLabelToothId = null;

		Object.keys(labelChildren)
			.forEach((labelId) => {
				labelChildren[labelId].forEach((childLabelId) => {
					labelParentMap[childLabelId] = labelId;
				});
			});

		const teethLabelMap = {};

		const teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.reduce((result, labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});

				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
					if ( tags.length > 0 ) {
						const toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));

						let isMissingTooth = false;

						if ( labelChildren[labelId] ) {
							labelChildren[labelId].forEach((childLabelId) => {
								const childLabel = labelsSelectors.selectLabelById(state, {
									labelId: childLabelId,
								});

								if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
									isMissingTooth = true;
								}
							});
						}

						if ( !isMissingTooth ) {
							result.push({
								toothKey,
								localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
								isMissing: false,
							});
							teethLabelMap[toothKey] = labelId;


							if ( selectedToothKey === toothKey ) {
								selectedLabelToothId = labelId;
							}
						}
					}
				}

				return result;
			}, []);

		teeth.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		let labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.map((labelId) => {
				const label = {
					...labelsSelectors.selectLabelById(state, {
						labelId,
					}),
				};
				const classId = labelGetters.getLabelClassId(label);

				label.category = getFindingCategory({ label });

				const isToothLabel = labelsUtils.labelIsTooth(label);
				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
				if (
					selectedLabelId !== labelId &&
					!isToothLabel &&
					(
						(
							typeof measureOfConfidence === 'number' &&
							editorData.filteredConfidencePercent > measureOfConfidence
						) ||
						!checkFindingsFilter({
							findingsFilter,
							label,
							showBoneLossStages: false,
							allowBoneLossLines: mainConfig.BONE_LOSS_LINES,
						}) ||
						isExtraFinding({ label }) ||
						(
							isSequentialModeEnabled === true && (
								classId === 'caries' ||
								classId === 'tooth_bone_loss' ||
								classId === 'periodontitis'
							)
						)
					)
				) {
					return false;
				}

				if ( isToothLabel ) {
					const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
					if ( tags.length > 0 ) {
						label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
						label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
					}

					const conflicts = labelGetters.getLabelConflicts(label);

					if (
						!conflicts.includes(LABEL_VALIDATION_ERROR__BOX_REQUIRED) &&
						!conflicts.includes(LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS)
					) {
						return false;
					}
				}
				else {
					const parentLabelId = labelParentMap[labelId];

					label.parentLabelId = parentLabelId;

					if ( parentLabelId ) {
						const parentLabel = labelsSelectors.selectLabelById(state, {
							labelId: parentLabelId,
						});

						if ( parentLabelId === selectedLabelToothId ) {
							categories[0].rows.push(label);
						}
						else {
							return false;
							// categories[1].rows.push(label);
						}

						if ( labelsUtils.labelIsTooth(parentLabel) ) {
							const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: parentLabelId });
							if ( tags.length > 0 ) {
								label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
								label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
							}
						}
					}
					else {
						return false;
					}
				}

				return label;
			}).filter(Boolean);

		labels.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		return {
			dataType: 'rows',
			categories,
			labels,
			teeth,
			teethLabelMap,
			findingsFilter,
		};
	},
	(dispatch) => ({
		onMoveLabel: (data) => {
			dispatch(labelsActions.moveLabel(data));
		},
	}),
)(ResolverSidebar);
