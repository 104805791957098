import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import ImagesCache from '../../../../services/images-cache';
import { events } from '../../../../services/events';

import labelGetters from '../../../labels/selectors/labelGetters';

import ImageShapeBox from '../../../../components/ImageShapes/shapes/box';
import ImageShapePolygon from '../../../../components/ImageShapes/shapes/polygon';
import ImageShapeBoneloss from '../../../../components/ImageShapes/shapes/boneloss';
import MagnifyingGlassImage from '../../../../components/MagnifyingGlass/MagnifyingGlassImageConnector';

import { getDictionary } from '../../../../appUtils/locale';

import './styles/ResolverFindingViewer.css';


const i18nShared = getDictionary('shared');


const baseCssClassName = 'resolver-finding-viewer';
const mainCssClassName = `${baseCssClassName}__main`;
const secondaryCssClassName = `${baseCssClassName}__secondary`;
const previewContainerCssClassName = `${baseCssClassName}__preview-container`;
const toothContainerCssClassName = `${baseCssClassName}__tooth-container`;
const toothCssClassName = `${baseCssClassName}__tooth`;
const listCssClassName = `${baseCssClassName}-list`;
const listInnerCssClassName = `${listCssClassName}__inner`;
const imageCssClassName = `${baseCssClassName}-image`;
const imagePreviewCssClassName = `${imageCssClassName}__preview`;
const contentCssClassName = `${baseCssClassName}-content`;
const findingsCssClassName = `${baseCssClassName}-findings`;
const findingCssClassName = `${baseCssClassName}-finding`;
const findingNameCssClassName = `${findingCssClassName}__name`;
const actionsCssClassName = `${findingCssClassName}__actions`;
const actionCssClassName = `${findingCssClassName}__action`;
const viewportCssClassName = `${baseCssClassName}__viewport`;
const imageShapesCssClassName = `${baseCssClassName}__image-shapes`;
const imageTypeCssClassName = `${baseCssClassName}__image-type`;
const closeCssClassName = `${baseCssClassName}__close`;
const currentCssClassName = `${baseCssClassName}__current`;

const PREVIEW__WIDTH = 184;
const PREVIEW__HEIGHT = 268;

const SCREEN_STATUSES = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	LOADED: 'loaded',
	ERROR: 'error',
};


export default class ResolverFindingViewer extends PureComponent {
	static propTypes = {
		tooth: PropTypes.object,
		currentImage: PropTypes.object,
		isTooth: PropTypes.bool.isRequired,
		findings: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		getImageUrl: PropTypes.func.isRequired,
		getImageLink: PropTypes.func.isRequired,
		labelColorFilterFn: PropTypes.func,
		withMeasureOfConfidence: PropTypes.bool,
		notationType: PropTypes.string,
		onRemoveLabel: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	static defaultProps = {
		tooth: null,
		withMeasureOfConfidence: true,
		labelColorFilterFn: () => true,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_mainEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_secondaryEl = null;

	/**
	 * @type {boolean}
	 * @private
	 */
	_preserveMouseDown = false;

	state = {
		loadedStatus: SCREEN_STATUSES.IDLE,
		imageSeed: ImagesCache.getCache(this.props.currentImage.id),
		position: {
			scale: null,
			width: null,
			height: null,
			left: null,
			top: null,
			originalWidth: null,
			originalHeight: null,
		},
	};

	componentDidMount () {
		events.on('image.flip.finished', this._handleImageFlipFinished);

		window.document.addEventListener('click', this._handleDocumentClick);
	}

	componentWillUnmount () {
		this.state = null;

		if ( this._secondaryEl ) {
			this._secondaryEl.removeEventListener('mousedown', this._handleSecondaryMouseDownMouseDown);
		}

		events.removeListener('image.flip.finished', this._handleImageFlipFinished);
		window.document.removeEventListener('click', this._handleDocumentClick);
	}

	_goToImage (link) {
		this.context.router.history.push(link);
	}

	_removeLabel (label) {
		this.props.onRemoveLabel({
			labelId: labelGetters.getLabelId(label),
		});
	};

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleRef = (element) => {
		this._baseEl = element;
		if ( element ) {
			element.addEventListener('mousedown', this._handleSecondaryMouseDownMouseDown, false);
		}
	};

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleMainRef = (element) => {
		this._mainEl = element;
	};

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleSecondaryRef = (element) => {
		this._secondaryEl = element;
		if ( element ) {
			element.addEventListener('mousedown', this._handleSecondaryMouseDownMouseDown, false);
		}
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleSecondaryMouseDownMouseDown = (event) => {
		this._preserveMouseDown = true;
		event.stopPropagation();
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleDocumentClick = (event) => {
		// if ( this._preserveMouseDown === true ) {
		// 	this._preserveMouseDown = false;
		// 	return;
		// }
		// this.props.onClose();
	};

	/**
	 * @param {SyntheticEvent} event
	 * @private
	 */
	_handleImageMouseDown = (event) => {
		this._preserveMouseDown = true;
		event.stopPropagation();
	};

	_handleImageFlipFinished = () => {
		this.setState({
			imageSeed: ImagesCache.getCache(this.props.currentImage.id),
			loadedStatus: SCREEN_STATUSES.IDLE,
		});
	};

	_handlePreviewStatusChanged = (status, data) => {
		switch (status) {
			case SCREEN_STATUSES.LOADED: {
				if ( this.state === null ) {
					return;
				}

				const imageWidth = data.width;
				const imageHeight = data.height;

				let toothShape = this.props.tooth.shapes[this.props.currentImage.hashname] || {};
				if (
					typeof this.props.tooth.shape.type !== 'string' &&
					this.props.findings.length > 0 &&
					this.props.findings[0].imageToothShape &&
					typeof this.props.findings[0].imageToothShape.type === 'string'
				) {
					toothShape = this.props.findings[0].imageToothShape;
				}

				if ( toothShape === null ) {
					this.setState({
						loadedStatus: SCREEN_STATUSES.LOADED,
						position: {
							originalWidth: data.width,
							originalHeight: data.height,
						},
					});
					return;
				}

				// 1. Get a polygon bounding rect.
				const roiRect = labelsUtils.getBoundingRectForShape(toothShape);
				const roiLeft = roiRect.left;
				const roiRight = roiRect.right;
				const roiTop = roiRect.top;
				const roiBottom = roiRect.bottom;
				const roiWidth = roiRight - roiLeft;
				const roiHeight = roiBottom - roiTop;

				// 2. Find smallest ratio for x and y.
				const ratioX = PREVIEW__WIDTH / roiWidth;
				const ratioY = PREVIEW__HEIGHT / roiHeight;
				const resultRatio = Math.min(ratioX, ratioY);

				// 3. Get scaled x and y of the roi.
				let resultLeft = roiLeft * resultRatio;
				let resultTop = roiTop * resultRatio;

				// 4. Scale image
				const resultImageWidth = imageWidth * resultRatio;
				const resultImageHeight = imageHeight * resultRatio;

				// 5. Calculate offset x
				const realWidth = Math.ceil(roiWidth * resultRatio);
				let nextLeft = (PREVIEW__WIDTH / 2) - (resultLeft + (realWidth / 2));
				// No left empty space
				if ( nextLeft > 0 ) {
					nextLeft = 0;
				}
				// No right empty space
				if ( resultImageWidth - Math.abs(nextLeft) < PREVIEW__WIDTH ) {
					nextLeft += PREVIEW__WIDTH - (resultImageWidth - Math.abs(nextLeft));
				}
				resultLeft = nextLeft;

				// 6. Calculate offset y
				const realHeight = Math.ceil(roiHeight * resultRatio);
				// No top empty space
				let nextTop = (PREVIEW__HEIGHT / 2) - (resultTop + (realHeight / 2));
				if ( nextTop > 0 ) {
					nextTop = 0;
				}
				// No bottom empty space
				if ( resultImageHeight - Math.abs(nextTop) < PREVIEW__HEIGHT ) {
					nextTop += PREVIEW__HEIGHT - (resultImageHeight - Math.abs(nextTop));
				}
				resultTop = nextTop;

				this.setState({
					loadedStatus: SCREEN_STATUSES.LOADED,
					position: {
						scale: resultRatio,
						width: resultImageWidth,
						height: resultImageHeight,
						left: resultLeft,
						top: resultTop,
						originalWidth: data.width,
						originalHeight: data.height,
					},
				});
				break;
			}

			default: {
				this.setState({
					loadedStatus: status,
				});
				break;
			}
		}
	};

	_renderTooth () {
		return (
			<div className={toothContainerCssClassName}>
				<div
					className={toothCssClassName}
					style={{
						borderColor: labelsUtils.getLabelColor(this.props.tooth, this.props.labelColorFilterFn),
					}}
				>
					{this.props.tooth.localizedToothKey}
				</div>
			</div>
		);
	}

	_renderShapes () {
		if ( this.state.loadedStatus !== SCREEN_STATUSES.LOADED || this.props.findings.length === 0 ) {
			return null;
		}

		return (
			<div
				className={imageShapesCssClassName}
				style={{
					width: this.state.position.width,
					height: this.state.position.height,
					left: this.state.position.left,
					top: this.state.position.top,
				}}
			>
				<svg xmlns={'http://www.w3.org/2000/svg'}>
					{this.props.findings[0].findings.map(({ label }) => {
						const labelId = labelGetters.getLabelId(label);
						const props = {
							key: labelGetters.getLabelId(label),
							shape: labelGetters.getLabelShape(label),
							color: labelsUtils.getLabelColor(label, this.props.labelColorFilterFn),
							borderStyle: labelsUtils.getShapeBorderStyle(label),
							showControls: false,
							isHighlighted: false,
							allowEditing: false,
							zoom: this.state.position.scale,
							imageWidth: this.state.position.width,
							imageHeight: this.state.position.height,
							showMask: this.props.areFindingsMasksEnabled === true,
						};

						let Component = null;

						switch (labelGetters.getLabelShape(label).type) {
							case 'box':
								Component = ImageShapeBox;
								break;

							case 'poly':
								Component = ImageShapePolygon;
								break;

							case 'named_poly':
								Component = ImageShapeBoneloss;
								break;

							default:
								return null;
						}

						return (<Component key={`shape_${labelId}`} {...props} />);
					})}
				</svg>
			</div>
		);
	}

	_renderImage () {
		if ( this.props.isTooth === false && this.props.findings.length === 0 ) {
			return null;
		}

		return (
			<div
				className={viewportCssClassName}
				style={{ width: PREVIEW__WIDTH, height: PREVIEW__HEIGHT }}
			>
				<div
					style={{
						position: 'absolute',
						left: this.state.position.left,
						top: this.state.position.top,
						width: this.state.position.width,
						height: this.state.position.height,
					}}>
					<MagnifyingGlassImage
						image={this.props.isTooth ?
							this.props.currentImage:
							{image_url: this.props.findings[0].imageUrl}
						}
						seed={this.state.imageSeed}
						width={this.state.position.width}
						height={this.state.position.height}
						onStatusChanged={this._handlePreviewStatusChanged}
					/>
				</div>
				{this._renderShapes()}
			</div>
		);
	}

	_renderPreview () {
		return (
			<div className={previewContainerCssClassName} style={{ width: PREVIEW__WIDTH, height: PREVIEW__HEIGHT }}>
				{this._renderTooth()}
				{this._renderImage()}
			</div>
		);
	}

	_renderSecondary () {
		return (
			<div className={secondaryCssClassName} ref={this._handleSecondaryRef}>
				{this._renderPreview()}
			</div>
		);
	}

	_renderFindings (findings) {
		return (
			<div className={findingsCssClassName}>
				{findings.map(({ label, allowRemove }, i) => {
					const confidencePercent = labelGetters.getLabelMeasureOfConfidence(label);

					return (
						<div className={findingCssClassName} key={i}>
							<div className={findingNameCssClassName}>
								{label.localizedLabelName} {this.props.withMeasureOfConfidence === true && labelsUtils.shouldShowConfidenceForClass(labelGetters.getLabelClassId(label)) && confidencePercent > 0 ? ` ${Math.floor(confidencePercent * 100)}%` : ''}
							</div>
							<div className={actionsCssClassName}>
								{allowRemove === true && (
									<div
										className={`${actionCssClassName} ${actionCssClassName}__m-remove`}
										title={'Remove'}
										onClick={() => this._removeLabel(label)}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	_renderTeeth (imageData) {
		if ( Array.isArray(imageData.teethMeta) === false || imageData.teethMeta.length === 0 ) {
			return null;
		}

		return ' ' + teethUtils.getTeethMetaTitle({ teeth: imageData.teethMeta, notationType: this.props.notationType })
	}

	_renderMain () {
		return (
			<div className={mainCssClassName} ref={this._handleMainRef}>
				<div className={listCssClassName}>
					<div className={listInnerCssClassName}>
						{this.props.findings.map((imageData) => {
							return (
								<div className={imageCssClassName} key={imageData.imageHashName} onMouseDown={this._handleImageMouseDown}>
									<div
										className={classnames([
											imagePreviewCssClassName,
											imageData.isCurrentImage === true && `${imagePreviewCssClassName}__m-current`,
										])}
									>
										<a
											href={'javascript:void(0);'}
											onClick={() => {
												this._goToImage(this.props.getImageLink(imageData.imageHashName))
											}}>
											<img src={this.props.getImageUrl(imageData.imageHashName, this.state.imageSeed)} crossOrigin={'anonymous'} />
										</a>
										{imageData.isCurrentImage === true && (
											<div className={currentCssClassName}>
												Current
											</div>
										)}
									</div>
									<div className={contentCssClassName}>
										<div className={imageTypeCssClassName}>{i18nShared(`image_types.${imageData.imageType}`)}{imageData.imageType !== 'pan' ? this._renderTeeth(imageData) : null}</div>
										{this._renderFindings(imageData.findings)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	render () {
		if ( this.props.findings.length === 0 ) {
			return null;
		}

		return (
			<div
				className={baseCssClassName}
				ref={this._handleRef}
			>
				{this._renderSecondary()}
				{this._renderMain()}
				<div
					className={closeCssClassName}
					title={'Close'}
					onClick={this.props.onClose}
				/>
			</div>
		);
	}
}
