import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import classnames from 'classnames';

import { events } from '../../../../services/events';

import mainConfig from '../../../../configs/mainConfig';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import imageUtils from '../../../../appUtils/imageUtils';
import apiUtils from '../../../../appUtils/apiUtils';

import { isSequentialModeEnabledForVisit } from '../../utils';
import { areFindingsMasksEnabled } from '../../../../appUtils/findingsMasks';
import { checkFindingsFilter, isExtraFinding, getFindingCategory } from '../../utils/findings-filter';

import { trackEvent } from '../../../../integrations/mixpanel';

import {
	hideImageBadQualityNotification,
	hideUnsupportedImageTypeNotification,
} from '../../actions';

import {
	LABEL_VALIDATION_ERROR__BOX_REQUIRED,
	LABEL_VALIDATION_ERROR__DATE_REQUIRED,
	LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS,
	LABEL_VALIDATION_ERROR__PARAMS_REQUIRED,
	LABEL_VALIDATION_ERROR__SURFACE_REQUIRED,
	LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH,
} from '../../../labels/constants';

import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__BITEWING,
} from '../../../../constants/imageConstants';

import {
	USER_PERMISSION__IS_EXPORT_ALLOWED,
	USER_PERMISSION__IS_SHOW_EXISTING_CHART,
	USER_PERMISSION__IS_SHOW_BONE_LEVELS,
	USER_PERMISSION__IS_SHOW_NON_PATHOLOGICAL, USER_PERMISSION__USE_ANALYSIS,
} from '../../../../constants/userPermissionsConstants';

import aclService from '../../../../services/acl';

import { getDictionary } from '../../../../appUtils/locale';
import { confirm, message } from '../../../../services/popup';

import imagesActions from '../../../../actions/imagesActions';
import editorActions from '../../../../actions/editorActions';
import { analyzeImage } from '../../actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../../label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';
import labelGetters from '../../../../modules/labels/selectors/labelGetters';

import SizeDetector from '../../../../components/SizeDetector';
import ResolverStorage from './ResolverStorageService';
import ResolverSidebar from './ResolverSidebar';
import ResolverSidebarPanel from '../sidebar-panel';
import ResolverEditorToolbar from '../editor-toolbar';
import ResolverImageEditor, { ResolverImageNotification } from '../image-editor';
import ResolverNavigation from '../navigation';
import Header from '../header';
import UserMenu from '../../../../components/Editor/UserMenu';
import Hint from '../../../../components/Hint';
import { DrawSecondaryBoxMode } from '../../../../components/DisableLayout';
import Popup from '../../../../components/Popup';
import Loading from '../../../../components/Loading';
import Alignment from '../../../../components/Alignment';
import MagnifyingGlass from '../../../../components/MagnifyingGlass';
import Button from '../../../../components/Button';

import ResolverAutoChart from '../auto-chart/ResolverAutoChart';
import FmxViewer from '../fmx/FmxViewerConnector';

import './styles/ResolverController.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'resolver-layout';
const headerCssClassName = `${baseCssClassName}__header`;
const topPanelCssClassName = `${baseCssClassName}-top-panel`;
const mainContentCssClassName = `${baseCssClassName}-main-content`;
const bottomPanelCssClassName = `${baseCssClassName}-bottom-panel`;
const bottomContentPanelCssClassName = `${bottomPanelCssClassName}__content`;
const sidebarCssClassName = `${baseCssClassName}__sidebar`;
const sidebarPanelCssClassName = `${baseCssClassName}__sidebar-panel`;
const editorToolbarCssClassName = `${baseCssClassName}__editor-toolbar`;
const imageEditorCssClassName = `${baseCssClassName}__image-editor`;
const canvasCssClassName = `${baseCssClassName}__canvas`;
const notificationsCssClassName = `${baseCssClassName}__notifications`;
const magnifyingGlassCssClassName = `${baseCssClassName}-magnifying-glass`;
const sequentialModeButtonClassName = `${baseCssClassName}__sequential-mode-button`;
const analyzeButtonClassName = `${baseCssClassName}__analyze-button`;

const SIDEBAR_PANEL_OFFSET_Y = 40;


class ResolverController extends Component {
	static propTypes = {
		editorMode: PropTypes.string.isRequired,
		classIdForNewLabel: PropTypes.string,
		showImageBadQualityNotification: PropTypes.bool.isRequired,
		showUnsupportedImageTypeNotification: PropTypes.bool.isRequired,
		findingsFilter: PropTypes.object.isRequired,
		isEnabledExportButton: PropTypes.bool.isRequired,
		isImageFlipping: PropTypes.bool.isRequired,
		currentImageIsAnalyzing: PropTypes.bool.isRequired,
		magnifyingGlassEnabled: PropTypes.bool.isRequired,
		shouldShowThumbnailsGallery: PropTypes.bool.isRequired,
		isSequentialModeEnabled: PropTypes.bool.isRequired,
		isFmxModeEnabled: PropTypes.bool.isRequired,
		imageLoadingStatus: PropTypes.string.isRequired,
		areFindingsMasksEnabled: PropTypes.bool,
		areFindingsMasksFromProfileEnabled: PropTypes.bool,
		areHeatmapsEnabled: PropTypes.bool,
		areHeatMapsFromProfileEnabled: PropTypes.bool,
		currentCollection: PropTypes.object.isRequired,
		currentImage: PropTypes.object.isRequired,
		isTfvModeEnabled: PropTypes.bool.isRequired,
		isTfvModeAvailable: PropTypes.bool.isRequired,
		isSimpleUiEnabled: PropTypes.bool.isRequired,
		notAnalyzedImages: PropTypes.array.isRequired,
		teethConflicts: PropTypes.array.isRequired,
		shouldDisplayAnalyzeButton: PropTypes.bool.isRequired,
		shouldHighlightAnalyzeButton: PropTypes.bool.isRequired,
		isAutoChartButtonDisabled: PropTypes.bool.isRequired,
		shouldNotifyThatPatientTooYoung: PropTypes.bool.isRequired,
		isPatientTooYoung: PropTypes.bool.isRequired,
		canReanalyze: PropTypes.bool.isRequired,
		onSetHeatMap: PropTypes.func,
		onSetFindingsMasks: PropTypes.func,
		onSetInitialFilters: PropTypes.func.isRequired,
		onFindingsFilterChange: PropTypes.func.isRequired,
		onHideBadImageQualityNotification: PropTypes.func.isRequired,
		onHideUnsupportedImageTypeNotification: PropTypes.func.isRequired,
		onSaveImageData: PropTypes.func.isRequired,
		onChangeFmxMode: PropTypes.func.isRequired,
		onGetImage: PropTypes.func.isRequired,
		onGoToTfv: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		onDisableAiNotification: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_bottomPanelEl = null;

	constructor (props) {
		super(props);

		this.state = {
			isSidebarExpanded: props.isSimpleUiEnabled === false,
			isAutoChartOpened: false,
			fmxSelectedImageHshName: null,
			sidebarPanelTopOffset: SIDEBAR_PANEL_OFFSET_Y,
		};
	}

	componentDidMount () {
		this.props.onSetInitialFilters(this.props.currentCollection, this.props.isSequentialModeEnabled);

		if ( this.props.areFindingsMasksFromProfileEnabled === true ) {
			this.props.onSetFindingsMasks(areFindingsMasksEnabled());
		}

		events.on('fmx.image.selected.changed', (imageHash) => {
			this.setState({
				fmxSelectedImageHshName: imageHash,
			});
		});
	}

	componentDidUpdate (prevProps, prevState) {
		if ( prevState.isSidebarExpanded !== this.state.isSidebarExpanded ) {
			events.emit('sidebar-collapse-changed');
		}

		if ( this.props.isSequentialModeEnabled === true && prevProps.isSequentialModeEnabled === false ) {
			this.props.onSetInitialFilters(this.props.currentCollection, this.props.isSequentialModeEnabled);
		}

		this._checkSidebarPanelSize();
	}

	/**
	 * @param {HTMLElement|null} element
	 * @private
	 */
	_handleBottomElRef = (element = null) => {
		this._bottomPanelEl = element;
	};

	_checkSidebarPanelSize () {
		const sidebarPanelEl = document.querySelector(`.${sidebarPanelCssClassName}`);
		let offset = 0;
		if ( sidebarPanelEl !== null ) {
			if ( sidebarPanelEl.firstChild !== null ) {
				if ( sidebarPanelEl.firstChild.clientHeight < sidebarPanelEl.clientHeight - (this.state.sidebarPanelTopOffset === 0 ? SIDEBAR_PANEL_OFFSET_Y : 0) ) {
					offset = SIDEBAR_PANEL_OFFSET_Y;
				}
			}
		}

		if ( this.state.sidebarPanelTopOffset !== offset ) {
			this.setState({
				sidebarPanelTopOffset: offset,
			});
		}
	}

	_handleSidebarSizeChanged = () => {
		this._checkSidebarPanelSize();
	};

	_handleToggleSidebarExpanded = () => {
		if ( this.props.isSimpleUiEnabled === true ) {
			return;
		}

		this.setState({
			isSidebarExpanded: !this.state.isSidebarExpanded,
		});
	};

	_handleSequentialModeButtonClick = () => {
		trackEvent('Sequential Mode Button Click');

		this.props.onSaveImageData({
			data: {
				is_sequential_viewed: true,
			},
		})
			.then(() => {
				this.props.onFindingsFilterChange({
					pathological: true,
					treatment: false,
					existing: false,
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'An error occurred while saving image data. Please try again',
				});
			});
	};

	_handleFmxButtonClick = () => {
		if ( this.props.isFmxModeEnabled === true ) {
			this.context.router.history.push(`/collections/${this.props.currentCollection.hashname}/image/${this.props.currentImage.hashname}/treatment_plan`);
			// window.location.href = `/collections/${this.props.currentCollection.hashname}/image/${this.props.currentImage.hashname}/treatment_plan`;
		}
		else {
			this.context.router.history.push(`/collections/${this.props.currentCollection.hashname}/image/${this.props.currentImage.hashname}/fmx`);
			// window.location.href = `/collections/${this.props.currentCollection.hashname}/image/${this.props.currentImage.hashname}/fmx`;
		}
	};

	_handleConfirmAutoChart = (params) => {
		trackEvent('Confirm Auto-Chart Confirm');

		const event = {
			func: 'handleDentiExport',
			message: this.props.onGetExportLink(params),
		};
		if ( window.parent ) {
			window.parent.postMessage(event, '*')
		}

		if ( window.CefSharp && typeof window.CefSharp.PostMessage === 'function' ) {
			window.CefSharp.PostMessage(event);
		}

		this.setState({
			isAutoChartOpened: false,
		});

		this.props.onResetMode();
	};

	_handleAutoChartButtonClick = () => {
		trackEvent('Confirm Auto-Chart Button Click');

		if ( this.props.isPatientTooYoung === true ) {
			confirm({
				message: 'The patient is younger than 21. Are you sure you want to proceed?',
				yesHandler: () => {
					this.setState({
						isAutoChartOpened: true,
					});
				},
			});

			return;
		}

		this.setState({
			isAutoChartOpened: true,
		});
	};

	_handleCloseAutoChart = () => {
		this.setState({
			isAutoChartOpened: false,
		});
		this.props.onResetMode();
	};

	_handleAnalyzeButtonClick = () => {
		trackEvent('Analyze Button Button Click');
		confirm({
			title: 'Warning',
			message: 'All manual changes will be deleted. Do you want to continue?',
			yesHandler: () => {
				this.props.onAnalyzeImage()
					.catch(() => {
						message({
							title: i18nShared('error.title'),
							titleIcon: 'error',
							message: 'An error occurred while analyzing image. Please try again',
						});
					});
			},
		})
	};

	_renderLayouts () {
		if ( this.props.currentImageIsAnalyzing ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'This app displays some regions of interest for a trained professional to examine possible pathologies',
						}}
					/>
				</Popup>
			);
		}
		else if ( this.props.isImageFlipping ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'Image is flipping',
						}}
					/>
				</Popup>
			);
		}
	}

	_renderMagnifyingGlass () {
		if ( this.props.magnifyingGlassEnabled === false ) {
			return null;
		}

		return (
			<div
				className={classnames([
					magnifyingGlassCssClassName,
					this.state.isSidebarExpanded === false && `${magnifyingGlassCssClassName}__m-padded`,
				])}
			>
				<MagnifyingGlass
					image={this.props.isFmxModeEnabled === true
						? this.props.onGetImage(this.state.fmxSelectedImageHshName)
						: this.props.currentImage
					}
				/>
			</div>
		);
	}

	_renderSequentialMode () {
		if ( this.props.isSequentialModeEnabled === false && this.props.autoChartButtonAllowed === false ) {
			return null;
		}

		return (
			<div
				className={classnames([
					sequentialModeButtonClassName,
					this.props.isFmxModeEnabled === true && `${sequentialModeButtonClassName}__m-top-panel`,
				])}
			>
				{this.props.isSequentialModeEnabled === true && (
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={this.props.isFmxModeEnabled === false ? Button.AVAILABLE_SIZES.LARGE : Button.AVAILABLE_SIZES.SMALL}
						onClick={this._handleSequentialModeButtonClick}
					>
						Review Investigative Findings
					</Button>
				)}
				{(
					this.props.isFmxModeEnabled === true &&
					this.props.isSequentialModeEnabled === false &&
					this.props.autoChartButtonAllowed === true
				) && (
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.SMALL}
						title={'Auto-Chart to PMS'}
						disabled={this.props.isAutoChartButtonDisabled === true}
						onClick={this._handleAutoChartButtonClick}
					>
						Auto-Chart to PMS
					</Button>
				)}
			</div>
		);
	}

	_renderAutoChartPopup () {
		if ( !this.state.isAutoChartOpened ) {
			return null;
		}

		return (
			<ResolverAutoChart
				findingsFilter={this.props.findingsFilter}
				onConfirm={this._handleConfirmAutoChart}
				onClose={this._handleCloseAutoChart}
			/>
		);
	}

	render () {
		const shouldShowDrawBoxHint = (
			this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
			this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE
		);
		
		return (
			<div className={baseCssClassName}>
				<ResolverStorage />
				<div className={headerCssClassName}>
					<Header
						isFmxModeAvailable
						isFmxModeEnabled={this.props.isFmxModeEnabled}
						isTfvModeEnabled={this.props.isTfvModeEnabled}
						isTfvModeAvailable={this.props.isTfvModeAvailable}
						onFmxButtonClick={this._handleFmxButtonClick}
						onTfvButtonClick={this.props.onGoToTfv}
					/>
				</div>
				<div className={topPanelCssClassName}>
					<div className={editorToolbarCssClassName}>
						<ResolverEditorToolbar
							isFmxModeEnabled={this.props.isFmxModeEnabled}
							areFindingsMasksFromProfileEnabled={this.props.areFindingsMasksFromProfileEnabled}
							areHeatMapsFromProfileEnabled={this.props.areHeatMapsFromProfileEnabled}
						/>
						{this.props.shouldDisplayAnalyzeButton === true && this.props.isSequentialModeEnabled === false && (
							<div className={analyzeButtonClassName}>
								<Button
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.SMALL}
									isPulsing={this.props.shouldHighlightAnalyzeButton}
									onClick={this._handleAnalyzeButtonClick}
								>{this.props.canReanalyze ? 'Re-analyze' : 'Analyze'}</Button>
							</div>
						)}
						<DrawSecondaryBoxMode />
					</div>
				</div>
				<div className={mainContentCssClassName}>
					{this._renderSequentialMode()}
					<div className={imageEditorCssClassName}>
						<div className={canvasCssClassName}>
							<div className={notificationsCssClassName}>
								{this.props.showImageBadQualityNotification && (
									<ResolverImageNotification onClose={this.props.onHideBadImageQualityNotification}>
										Image quality is low and it can degrade AI performance
									</ResolverImageNotification>
								)}
								{this.props.showUnsupportedImageTypeNotification && (
									<ResolverImageNotification onClose={this.props.onHideUnsupportedImageTypeNotification}>
										Unsupported image type
									</ResolverImageNotification>
								)}
								{this.props.teethConflicts.length > 0 && (
									<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR}>
										<div style={{ fontSize: 14, padding: 2 }}>You have unresolved teeth conflicts</div>
									</ResolverImageNotification>
								)}
								{this.props.shouldNotifyThatPatientTooYoung === true && (
									<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR}>
										<div style={{ fontSize: 14, padding: 2 }}>Patient should be older than 21</div>
									</ResolverImageNotification>
								)}
							</div>
							{this.props.isFmxModeEnabled === false && (<ResolverImageEditor image={this.props.currentImage} />)}
							{this.props.isFmxModeEnabled === true && (<FmxViewer />)}
						</div>
						<div
							className={classnames([
								bottomPanelCssClassName,
								(
									this.props.shouldShowThumbnailsGallery === false ||
									this.props.isFmxModeEnabled === true ||
									isMobile === true
								) && `${bottomPanelCssClassName}__m-shrinked`,
							])}
							ref={this._handleBottomElRef}
						>
							<div className={bottomContentPanelCssClassName}>
								<ResolverNavigation
									imageLoadingStatus={this.props.imageLoadingStatus}
									shouldShowThumbnailsGallery={
										this.props.shouldShowThumbnailsGallery === true &&
										this.props.isFmxModeEnabled === false &&
										isMobile === false
									}
								/>
							</div>
							<DrawSecondaryBoxMode />
						</div>
					</div>
					<div
						className={classnames([
							sidebarCssClassName,
							(this.props.isSimpleUiEnabled === true || this.state.isSidebarExpanded === false) && `${sidebarCssClassName}__m-collapsed`,
						])}
					>
						<SizeDetector onChange={this._handleSidebarSizeChanged}>
							{(size) => (
								<>
									{this.props.isSimpleUiEnabled === false && (
										<ResolverSidebar
											labels={this.props.labels}
											teeth={this.props.teeth}
											teethLabelMap={this.props.teethLabelMap}
											findingsFilter={this.props.findingsFilter}
											isFmxEnabled={this.props.isFmxModeEnabled}
											onFindingsFilterChange={this.props.onFindingsFilterChange}
										/>
									)}
									<div
										className={classnames([
											sidebarPanelCssClassName,
											(this.state.isSidebarExpanded === false && this.props.shouldShowThumbnailsGallery === false) && `${sidebarPanelCssClassName}__m-bottom`,
											mainConfig.MAIN_SCROLL_CSS_CLASS_NAME,
										])}
										style={{
											top: this.state.sidebarPanelTopOffset,
											bottom: (this._bottomPanelEl !== null ? this._bottomPanelEl.getBoundingClientRect().height : 0),
										}}
									>
										<ResolverSidebarPanel
											allowHeight={size.height - (this._bottomPanelEl !== null ? this._bottomPanelEl.getBoundingClientRect().height : 0)}
											isExpanded={this.state.isSidebarExpanded}
											disabledPreExistingFindings={this.props.isSequentialModeEnabled === true}
											// disabledMagnifyingGlass={this.props.isFmxModeEnabled === true}
											shouldRenderMagnifyingGlass={false}
											areHeatMapsEnabled={this.props.areHeatMapsEnabled}
											shouldRenderHeatMaps={this.props.areHeatMapsFromProfileEnabled}
											areFindingsMasksEnabled={this.props.areFindingsMasksEnabled}
											shouldRenderFindingsMasks={this.props.areFindingsMasksFromProfileEnabled}
											shouldRenderBoneStages={aclService.checkPermission(USER_PERMISSION__IS_SHOW_BONE_LEVELS)}
											shouldRenderPreExistingFindings={aclService.checkPermission(USER_PERMISSION__IS_SHOW_NON_PATHOLOGICAL)}
											shouldRenderChartedFindings={aclService.checkPermission(USER_PERMISSION__IS_SHOW_EXISTING_CHART)}
											shouldRenderShowHideControl={this.props.isSimpleUiEnabled === false}
											onSetHeatMaps={this.props.onSetHeatMap}
											onSetFindingsMasks={this.props.onSetFindingsMasks}
											onToggleExpand={this._handleToggleSidebarExpanded}
											onFindingsFilterChange={this.props.onFindingsFilterChange}
										/>
										<DrawSecondaryBoxMode />
									</div>
								</>
							)}
						</SizeDetector>
					</div>
					{shouldShowDrawBoxHint && (
						<Hint
							content={`Draw the box for ${labelsUtils.getLocalizedClassByClassId(this.props.classIdForNewLabel)}`}
							top={Math.max(0, 0 - window.document.body.scrollTop)}
							left={window.document.body.clientWidth / 3}
						/>
					)}
				</div>
				{this._renderMagnifyingGlass()}
				{this._renderLayouts()}
				{this.props.isUserMenuOpened && <UserMenu />}
				{this._renderAutoChartPopup()}
			</div>
		);
	}
}

export default withRouter(connect(
	(state, { history }) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const currentCollectionId = editorData.currentCollectionHashName;
		const collection = collectionsSelectors.selectCollectionById(state, { id: currentCollectionId });
		const selectedLabel = editorData.selectedLabel;
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		const labelParentMap = {};
		const classIdForNewLabel = (
			editorData.classIdForNewLabel ||
			(
				editorData.selectedLabel
					? labelGetters.getLabelClassId(editorData.selectedLabel)
					: null
			)
		);
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
		const findingsFilter = editorData.treatmentPlanFilters;
		const areHeatMapsFromProfileEnabled = userSelectors.selectAreHeatMapsEnabled(state);
		const isCollectionRotated = editorData.notAnalyzedImages.some((imageId) => {
			const image = imagesSelectors.selectImageById(state, {
				id: imageId,
			});

			return (image.examination === editorData.currentExamination);
		});
		const reAnalyzeRequired = collection.images
			.filter((image) => image.examination === editorData.currentExamination)
			.some((image) => image.reanalyze_required === true);
		const isAutoChartShortFilter = editorData.isAutoChartShortFilter;
		const isPatientTooYoung = editorData.currentExamination
			? true === collection.images
				.filter((image) => image.examination === editorData.currentExamination)
				.some((image) => imageUtils.isImagePatientOlder(image) === false)
			: imageUtils.isImagePatientOlder(currentImage) === false;

		Object.keys(labelChildren)
			.forEach((labelId) => {
				labelChildren[labelId].forEach((childLabelId) => {
					labelParentMap[childLabelId] = labelId;
				});
			});

		let labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.map((labelId) => {
				const label = {
					...labelsSelectors.selectLabelById(state, {
						labelId,
					}),
				};

				const classId = labelGetters.getLabelClassId(label);

				label.category = getFindingCategory({ label });

				const isToothLabel = labelsUtils.labelIsTooth(label);
				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
				if ( selectedLabel && labelGetters.getLabelId(selectedLabel) === labelId ) {
					if ( mainConfig.BONE_LOSS_LINES.includes(classId) ) {
						return;
					}
				}
				else {
					if (
						!isToothLabel &&
						(
							(
								typeof measureOfConfidence === 'number' &&
								editorData.filteredConfidencePercent > measureOfConfidence
							) ||
							!checkFindingsFilter({
								findingsFilter,
								label,
								showBoneLossStages: false,
								allowBoneLossLines: mainConfig.BONE_LOSS_LINES,
							}) ||
							isExtraFinding({ label })
						) ||
						(
							isSequentialModeEnabled === true && (
								classId === 'caries' ||
								classId === 'tooth_bone_loss' ||
								classId === 'periodontitis'
							)
						)
					) {
						return false;
					}
				}

				if ( isToothLabel ) {
					const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
					if ( tags.length > 0 ) {
						label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
						label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
					}

					const conflicts = labelGetters.getLabelConflicts(label);

					if (
						!conflicts.includes(LABEL_VALIDATION_ERROR__BOX_REQUIRED) &&
						!conflicts.includes(LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS)
					) {
						return false;
					}
				}
				else {
					const parentLabelId = labelParentMap[labelId];

					label.parentLabelId = parentLabelId;

					if ( parentLabelId ) {
						const parentLabel = labelsSelectors.selectLabelById(state, {
							labelId: parentLabelId,
						});

						if ( labelsUtils.labelIsTooth(parentLabel) ) {
							const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: parentLabelId });
							if ( tags.length > 0 ) {
								label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
								label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
							}
						}
					}
				}

				return label;
			}).filter(Boolean);
		
		labels.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));
		
		const teethLabelMap = {};
		
		const teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.reduce((result, labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});
				
				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
					if ( tags.length > 0 ) {
						const toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
						
						let isMissingTooth = false;
						
						if ( labelChildren[labelId] ) {
							labelChildren[labelId].forEach((childLabelId) => {
								const childLabel = labelsSelectors.selectLabelById(state, {
									labelId: childLabelId,
								});
								
								if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
									isMissingTooth = true;
								}
							});
						}

						result.push({
							toothKey,
							localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
							isMissing: isMissingTooth,
						});
						teethLabelMap[toothKey] = labelId;
					}
				}
				
				return result;
			}, []);
		
		const isEnabledExportButton = labels.length > 0
			? labels
				.every((label) => {
					const conflicts = labelGetters.getLabelConflicts(label);

					if ( conflicts.length > 0 ) {
						if (
							conflicts.includes(LABEL_VALIDATION_ERROR__BOX_REQUIRED) ||
							conflicts.includes(LABEL_VALIDATION_ERROR__DATE_REQUIRED) ||
							conflicts.includes(LABEL_VALIDATION_ERROR__SURFACE_REQUIRED) ||
							conflicts.includes(LABEL_VALIDATION_ERROR__PARAMS_REQUIRED) ||
							conflicts.includes(LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH) ||
							conflicts.includes(LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS)
						) {
							return false;
						}
					}

					return true;
				})
			: false;

		teeth.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		return {
			labels,
			teeth,
			isEnabledExportButton,
			teethLabelMap,
			showImageBadQualityNotification: (
				true === currentImage.is_bad_quality &&
				false === editorData.treatmentPlanHideBadImageNotification
			),
			showUnsupportedImageTypeNotification: (
				currentImage.image_type !== IMAGE_TYPE__PAN &&
				currentImage.image_type !== IMAGE_TYPE__BITEWING &&
				currentImage.image_type !== IMAGE_TYPE__PERIAPICAL &&
				false === editorData.treatmentPlanUnsupportedImageTypeNotification
			),
			currentImage,
			currentCollection: collectionsSelectors.selectCollectionById(state, { id: currentCollectionId }),
			currentImageIsAnalyzing: editorData.currentImageIsAnalyzing,
			classIdForNewLabel,
			editorMode: editorData.editorMode,
			findingsFilter,
			isImageFlipping: editorData.imageFlipping !== null,
			isUserMenuOpened: editorData.isUserMenuOpened,
			magnifyingGlassEnabled: editorData.magnifyingGlassEnabled,
			shouldShowThumbnailsGallery: editorData.showThumbnailsGallery,
			isSequentialModeEnabled,
			isFmxModeEnabled: editorData.isFmxModeEnabled,
			autoChartButtonAllowed: (
				aclService.checkPermission(USER_PERMISSION__IS_EXPORT_ALLOWED) === true &&
				user.is_simple_ui_enabled !== true &&
				(user.is_patient_age_restricted !== true || isPatientTooYoung === false)
			),
			isPatientTooYoung,
			isAutoChartButtonDisabled: editorData.teethConflicts.length > 0,
			areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
			areFindingsMasksFromProfileEnabled: areHeatMapsFromProfileEnabled === false && userSelectors.selectAreFindingsMasksEnabled(state),
			areHeatMapsFromProfileEnabled,
			areHeatMapsEnabled: editorData.areHeatMapsEnabled,
			isTfvModeEnabled: (
				typeof editorData.currentExamination === 'string' &&
				editorData.currentExamination.length > 0 &&
				editorData.selectedToothKey !== null
			),
			isSimpleUiEnabled: user.is_simple_ui_enabled === true,
			shouldDisplayAnalyzeButton: (
				user.is_simple_ui_enabled === true &&
				userSelectors.selectUserData(state).username === collection.username &&
				aclService.checkPermission(USER_PERMISSION__USE_ANALYSIS) &&
				(
					isCollectionRotated === true ||
					reAnalyzeRequired === true
				)
			),
			shouldHighlightAnalyzeButton: (
				editorData.notAnalyzedImages.length > 0 ||
				reAnalyzeRequired === true
			),
			canReanalyze: (
				editorData.notAnalyzedImages.length > 0 ||
				reAnalyzeRequired === true ||
				currentImage.is_analyzed_once === true
			),
			isTfvModeAvailable: user.is_tfv_enabled === true,
			notAnalyzedImages: editorData.notAnalyzedImages,
			teethConflicts: editorData.teethConflicts,
			shouldNotifyThatPatientTooYoung: isPatientTooYoung,
			onGetCurrentCollection: (storeState) => collectionsSelectors.selectCollectionById(storeState, {
				id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
			}),
			onGetExportLink: (params) => imageUtils.getExportLink({ findingsFilter, editorData, currentImage, currentCollectionId, isAutoChartShortFilter, ...params }),
			onGetImage: (hashname) => imagesSelectors.selectImageByHashName(state, { hashname }),
			onGoToTfv: () => {
				history.push(`/collections/${currentCollectionId}/visit/${editorData.currentExamination}/tfv/${editorData.selectedToothKey}`);
			},
		};
	},
	(dispatch, props) => () => ({
		onHideBadImageQualityNotification: () => dispatch(hideImageBadQualityNotification()),
		onHideUnsupportedImageTypeNotification: () => dispatch(hideUnsupportedImageTypeNotification()),
		onSaveImageData: (options) => dispatch(imagesActions.saveImageData(options)),
		onSetInitialFilters: (currentCollection, isSequentialModeEnabled) => {
			if ( isSequentialModeEnabled === true ) {
				dispatch(editorActions.updateData({
					data: {
						treatmentPlanFilters: {
							pathological: false,
							treatment: true,
							existing: false,
						},
					},
				}));
			}
			else {
				if ( typeof currentCollection.filter_list === 'object' && currentCollection.filter_list !== null ) {
					dispatch(editorActions.updateData({
						data: {
							treatmentPlanFilters: (currentCollection.filter_list.export_classes || [])
								.filter((className) => className !== 'perio')
								.reduce((result, classId) => {
									result[classId] = true;
									return result;
								}, {}),
						},
					}));
				}
			}
		},
		onChangeFmxMode: (value) => dispatch(editorActions.updateData({
			data: {
				isFmxModeEnabled: value,
				zoom: 1,
			},
		})),
		onResetMode: () => dispatch(editorActions.resetMode()),
		onSetFindingsMasks: (value) => dispatch(editorActions.setFindingsMasks(value)),
		onAnalyzeImage: () => dispatch(analyzeImage()),
		onSetHeatMap: (value) => dispatch(editorActions.updateData({
			data: {
				areHeatMapsEnabled: value,
			},
		})),
	}),
)(ResolverController));
