import reduxThunkMiddleware from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

import editorActionTypes from '../actions/editorActionTypes';

import initialState from '../configs/initialState';

import { getMixPanel } from '../integrations/mixpanel';

import editorReducer from '../reducers/editorReducer';
import imagesReducer from '../reducers/imagesReducer';
import imagesCollectionsReducer from '../reducers/imagesCollectionsReducer';
import imagesHistoryReducer from '../reducers/imagesHistoryReducer';
import userReducer from '../reducers/userReducer';
import countriesReducer from '../reducers/countriesReducer';
import analyseReducer from '../reducers/analyseReducer';
import collectionsReducer from '../reducers/collectionsReducer';


import labelsReducer from '../modules/labels/reducers/labelsReducer';
import imagesLabelsReducer from '../modules/labels/reducers/imagesLabelsReducer';
import labelChildrenReducer from '../modules/labels/reducers/labelChildren';

import labelTagsReducer from '../modules/label-tags/reducers/labelTagsReducer';
import labelsTagsReducer from '../modules/label-tags/reducers/labelsTagsReducer';
import proceduresReducer from '../reducers/proceduresReducer';

import teethConflictsMiddleware from '../modules/labels/middlewares/teethConflicts';

const composeEnhancers =
	process.env.NODE_ENV !== 'production' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			trace: true, traceLimit: 25
		}) : compose;

// NOTE: Part of reducers located in modules.


/**
 * @typedef {Object} StoreState
 *
 * @property {Object} editor
 * @property {AnalysisStoreState} analysis
 * @property {ImagesStoreState} images
 * @property {LabelsStoreState} labels
 * @property {ImagesLabelsStoreState} imagesLabels
 * @property {LabelChildrenStoreState} labelChildren
 * @property {LabelTagsStoreState} labelTags
 * @property {LabelsTagsStoreState} labelsTags
 * @property {UserStoreState} user
 * @property {CountriesStoreState} countries
 * @property {ProceduresStoreState} procedures
 */


/**
 *
 * @return {Reducer<StoreState>}
 */
function getReducer () {
	return combineReducers({
		editor: editorReducer,
		analysis: analyseReducer,

		images: imagesReducer,
		imagesCollections: imagesCollectionsReducer,
		collections: collectionsReducer,

		labels: labelsReducer,
		imagesLabels: imagesLabelsReducer,
		labelChildren: labelChildrenReducer,

		labelTags: labelTagsReducer,
		labelsTags: labelsTagsReducer,

		imagesHistory: imagesHistoryReducer,

		user: userReducer,

		countries: countriesReducer,
		procedures: proceduresReducer,
	});
}

/**
 * Returns base enhancers.
 *
 * @returns {Array<Function>}
 */
function getMiddlewares () {
	return [
		reduxThunkMiddleware,
		teethConflictsMiddleware,
	];
}

/**
 * Creates a configured store.
 *
 * @returns {Object}
 */
function configureStore(runtimeInitialState) {
    const enhancers = [
        applyMiddleware(...getMiddlewares()),
    ];

    return createStore(
        getReducer(),
		{
			...initialState,
			...runtimeInitialState,
		},
		composeEnhancers(...enhancers)
    );
}

/**
 * @typedef {Object} ReduxAction
 *
 * @property {string|Symbol} type
 * @property {any} [payload=undefined]
 * @property {any} [meta=undefined]
 */


/**
 * Makes a redux action.
 *
 * @param {string|Symbol} type
 * @param {*} [payload]
 * @param {*} [meta]
 * @returns {ReduxAction}
 */
function makeAction (type, payload, meta) {
	if ( !type || !( typeof type === 'string' || type instanceof Symbol ) ) {
		throw new Error('Argument "type" should be a non-empty string or Symbol');
	}

	return {
		type,
		payload,
		meta,
	};
}

export default {
    configureStore,
	makeAction,
};
