import Events from 'events';
import lodashGet from 'lodash/get';
import imageUtils from '../../appUtils/imageUtils';
import mainConfig from '../../configs/mainConfig';
import { getStore } from '../store';


const noop = () => {};

const defaultOptions = {
	uploadImage: noop(),
	removeImage: noop(),
	saveImageData: noop(),
	flipImageHorizontal: noop(),
	flipImageVertical: noop(),
	addImageToCollection: noop(),
	getCurrentCollection: noop(),
};


class UploadImage {
	_events = new Events();

	/**
	 * @param {Object} options
	 * @param {function():Promise} options.uploadImage
	 * @param {function():Promise} options.removeImage
	 * @param {function():Promise} options.saveImageData
	 * @param {function():Promise} options.flipImageHorizontal
	 * @param {function():Promise} options.flipImageVertical
	 * @param {function()} options.addImageToCollection
	 * @param {function():Collection} options.addImageToCollection
	 */
	constructor (options) {
		this._options = {
			...defaultOptions,
			...options,
		};
	}

	destroy () {
		if ( this._events !== null ) {
			this._events.removeAllListeners();
			this._events = null;
		}
	}

	uploadImage (imageData) {
		this._events.emit('upload-file-requested', new Promise((resolve, reject) => {
			if ( !imageUtils.checkImageExtension(imageData, mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS, mainConfig.AVAILABLE_UPLOADING_IMAGE_TYPES) ) {
				reject('WRONG_EXTENSION');
				return;
			}

			this._options.uploadImage(imageData)
				.then((image) => {
					resolve(image);
					this._options.addImageToCollection({
						imageId: image.id,
					});
					this._events.emit('upload-file-success', {
						imageHashName: image.hashname,
						collectionHashName: this._options.getCurrentCollection().hashname,
					});
				})
				.catch((error) => {
					if ( lodashGet(error, 'response.data.log', '').includes('limit exceeded') === true ) {
						reject('LIMIT_EXCEEDED');
					}
					else {
						reject('UPLOAD_ERROR');
					}

					this._events.emit('upload-file-fail');
				});
		}));
	}

	removeImage (data) {
		return this._options.removeImage(data);
	}

	saveImageData (data) {
		const collectionHashName = this._options.getCurrentCollection(getStore().getState()).hashname;
		return this._options.saveImageData({
			...data,
			collectionId: collectionHashName,
		})
			.then(() => {
				this._events.emit('upload-file-saved', {
					imageHashName: data.imageHashName,
					collectionHashName,
				});
			})
			.catch((error) => {
				console.dir(error);
			});
	}

	flipImageHorizontal (data) {
		return this._options.flipImageHorizontal(data);
	}

	flipImageVertical (data) {
		return this._options.flipImageVertical(data);
	}

	rotateImageLeft (data) {
		return this._options.rotateImageLeft(data);
	}

	rotateImageRight (data) {
		return this._options.rotateImageRight(data);
	}

	getEvents () {
		return this._events;
	}
}

/**
 * @type {UploadImage|null}
 */
let service = null;

export function initService (options) {
	service = new UploadImage(options);

	return service;
}

export function getService () {
	return service;
}
