import React from 'react';
import { connect } from 'react-redux';

import { changeFilters } from '../../actions';

import ResolverEntry from './ResolverEntry';


export default connect(() => ({}), (dispatch) => ({
	onSetFilters: (filters) => dispatch(changeFilters(filters)),
}), undefined, {
	areOwnPropsEqual: () => false,
})(ResolverEntry);
