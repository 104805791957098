export default {
	'buttons.next_history.alt': '下一历史操作',
	'buttons.next_history.text': '再做',
	'buttons.prev_history.alt': '上一历史操作',
	'buttons.prev_history.text': '撤销',
	'buttons.next_image.title': '下一张图',
	'buttons.next_image.text': '下一步',
	'buttons.prev_image.alt': '上一张图',
	'buttons.prev_image.text': '上一步',
	'buttons.first_image.alt': '第一张图',
	'buttons.last_image.alt': '最后一张图',
};
