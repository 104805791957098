export default {
	'dialog.remove_tooth.title': 'Убрать зуб',
	'dialog.remove_tooth.confirm': 'Вы уверены, что хотите убрать зуб',
	'dialog.remove_all.title': 'Убрать все находки',
	'dialog.remove_all.confirm': 'Вы уверены, что хотите убрать все находки?',
	
	'buttons.show_all': 'Подробнее',
	'buttons.show_less': 'Свернуть',
	
	'confirm_all.alt': 'Подтвердить все находки',
	'confirm_all.text': 'Подтвердить все',
	
	'remove_all.alt': 'Убрать все находки',
	'remove_all.text': 'Убрать все',

	'iso': 'ISO система',
	'uni': 'Универсальная система',

	'filtered-findings.label': 'Фильтры({n})',
	'confidence': 'Вероятность',

	'labels.tooth': 'Зуб',
	'labels.teeth': 'Зуб',
	'labels.caries': 'Кариес',
	'labels.periodontitis': 'Периодонтит',
	'labels.crown': 'Коронка',
	'labels.implant': 'Имплант',
	'labels.bridge': 'Мост',
	'labels.x': 'Неизвестный',
	'labels.md': 'Mesiodens',
	'labels.endodontic_treatment': 'Эндодонтическое лечение',
	'labels.restoration': 'Пломба',
	'labels.overfilling': 'Перепломбировка',
	'labels.underfilling': 'Недопломбировка',
	'labels.other': 'Другой тип',
	'labels.caries_proximal': 'Caries Proximal',
	'labels.caries_non_proximal': 'Caries Non-proximal',

	'labels.tags.secondary': 'Второстепенный',
	'labels.tags.suspected': 'Подозрение',
};
