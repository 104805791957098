export default {
	'hint.first': '上传图像',
	'hint.second': '支持文件格式',

	'buttons.back': 'Back',
	'buttons.open_registry': '打开资料库',
	'buttons.save_changes': '保存更改',
	'buttons.share_image': '分享图像',
	'buttons.collections': 'go to collections',

	'dialog.uploading_image_error.title': '图像上传出错',
	'dialog.uploading_image_error.text': `
		服务器错误，请再次尝试。<br/>

		如果此错误再次出现，请发邮件到<a href={'support@denti.ai'}>support@denti.ai</a>并提交产生错误的原因及相应医学图像
	`,
};
