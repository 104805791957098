export default {
	'labels.new_email': 'Новая почта',
	'labels.current_password': 'Текущий пароль',
	
	'info': `
		Подтверждающее письмо<br />
		будет выслано на старый адрес.<br />
		Пожалуйста, следуйте инструкциям.<br />

		<br />
		Вы должны ввести свой текущий<br />
		пароль, чтобы сменить почту
	`,
	
	'dialog.title': 'Сменить почту',
};
