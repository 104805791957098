import lodashUniqWith from 'lodash/uniqWith';
import lodashIsEqual from 'lodash/isEqual';
import Offset from 'polygon-offset';


/**
 *
 * @param {LabelShape} shape
 * @param {number} zoom
 * @param {number} [value=7]
 * @returns {{endY: *, endX: *, startY: number, startX: number}|*}
 */
export function addMarginToShape (shape, zoom, value = 7) {
	value = value / zoom;

	switch (shape.type) {
		case 'box':
			return ({
				...shape,
				startX: shape.startX - value,
				startY: shape.startY - value,
				endX: shape.endX + value,
				endY: shape.endY + value,
			});

		case 'poly':
		case 'named_poly': {
			return {
				...shape,
				points: addMarginToPoly(getUniquePoints(shape.points)),
			};
		}

		default:
			return shape;
	}
}

/**
 * @param {number[]} points
 * @param {number} value
 * @returns {number[]}
 */
export function addMarginToPoly (points, value = 7) {
	const offset = new Offset();
	points = [ ...points ];
	if ( points.length === 2 ) {
		return offset.data(points).offsetLine(value)[0];
	}
	return offset.data(points).margin(value)[0];
}

/**
 * @param {number[]} points
 * @param {number} value
 * @returns {number[]}
 */
export function addMarginToPolyWithOnePoint (points, value = 7) {
	const [ firstPoint ] = points;

	return [
		[ firstPoint[0] - value, firstPoint[1] - value ],
		[ firstPoint[0] + value, firstPoint[1] - value ],
		[ firstPoint[0] + value, firstPoint[1] + value ],
		[ firstPoint[0] - value, firstPoint[1] + value ],
		[ firstPoint[0] - value, firstPoint[1] - value ],
	];
}

/**
 * @param {number[]} points
 * @returns {number[]}
 */
export function getUniquePoints (points) {
	// return points;
	return lodashUniqWith(points, lodashIsEqual);
}
