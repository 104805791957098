import when from 'when';


/**
 * It function allows us to load all items from api using partial loading by the given limit.
 * If we want to load many items but our api has limit we can divide the loading into several part.
 *
 * @param {function(limit: number, skip: number):Promise<Array>} loadItemsFn
 * @param {number} limit
 * @returns {Promise<Array>}
 */
async function loadAllItems (loadItemsFn, limit) {
	let skip = 0;

	const result = [];

	while (true) {
		const items = await loadItemsFn(limit, skip);
		result.push(...items);
		if ( items.length < limit ) {
			break;
		}
		skip += limit;
	}

	return result;
}

/**
 * Creates request stack that returns result of last request.
 *
 * @returns {{exec: (function(Promise))}}
 */
function createRequestStack () {
	let lastRequestId = 0;

	return {
		exec: (request) => {
			const requestId = lastRequestId = lastRequestId + 1;
			return when.promise((resolve, reject) => {
				request
					.then((result) => {
						if ( requestId === lastRequestId ) {
							resolve(result);
						}
					})
					.catch((error) => {
						if ( requestId === lastRequestId ) {
							reject(error);
						}
					});
			});
		},
	};
}

function getApiParams (options) {
	const result = {};
	
	if ( typeof options === 'object' ) {
		if ( options.method ) {
			result.method = options.method;
		}
		
		result.url = options.url;
	}
	else {
		result.url = options;
	}
	
	return result;
}


export default {
	loadAllItems,
	createRequestStack,
	getApiParams,
};
