export default {
	'image.alt': '已上传的图像',
	
	'labels.image_type': "图像格式",
	'labels.patient_id': "病人身份标识号ID",
	'labels.birthday': "出生日期",
	'labels.gender': "性别",
	'labels.image_date': "影像日期",

	'image_types.pan': '全景的',
	'image_types.xray': '口腔内X光',

	'dialog.title': '图像正在上传',

	'wrong_extension.dialog.content': `
		抱歉，文件上次出错。请核对文件格式。<br />
		支持文件格式为：{formats}。<br />
		请上传尝试上传另一文件
	`,
};
