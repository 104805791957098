import React,  { PureComponent } from 'react';
import PropTypes from 'prop-types';
import mainConfig from '../../configs/mainConfig';
import { getDictionary } from '../../appUtils/locale';

import './styles/ImageControls.css';


const i18n = getDictionary('image-controls');

const baseCssClassName = 'image-controls';
const buttonCssClassName = `${baseCssClassName}__button`;


export default class UploadButton extends PureComponent {
	static propTypes = {
		onFileChange: PropTypes.func.isRequired,
	};

	_handleFileChange = (event) => {
		const inputFile = event.target;
		this.props.onFileChange(inputFile.files && inputFile.files[0]);
		inputFile.value = null;
	};

	render() {
		const title = `${i18n('hint.first')}\n${i18n('hint.second')}: ${mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS.join(', ')}`;

		return (
			<label className={`${buttonCssClassName} ${buttonCssClassName}__m-upload`} title={title}>
				<input type={'file'} style={{ display: 'none' }} onChange={this._handleFileChange} />
			</label>
		);
	}
}
