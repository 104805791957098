import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { isShapeIsPresent } from '../../utils';

import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';

import userSelectors from '../../../../selectors/userSelectors';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';
import labelGetters from '../../../labels/selectors/labelGetters';

import ToothActions from './ToothActions';


export default connect(
	(state, props) => {
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const image = imagesSelectors.selectImageByHashName(state, { hashname: props.imageHashName })
		const editorData = editorSelectors.selectEditor(state);
		const teethToShift = editorSelectors.selectEditor(state).teethToShift;
		const teethConflicts = editorSelectors.selectEditor(state).teethConflicts;
		const teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: image.id,
		})
			.reduce((result, labelId) => {
				let label = labelsSelectors.selectLabelById(state, { labelId });
				if ( labelGetters.getLabelClassId(label) !== 'tooth' ) {
					return result;
				}
				label = labelsSelectors.selectLabelById(state, { labelId });
				const shape = lodashGet(label, `shapes.[${image.hashname}]`, {});

				if ( shape === null || typeof shape.type !== 'string' || shape.type === 'none' ) {
					return result;
				}
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });

				if ( tags.length === 0 || isShapeIsPresent(shape) === false) {
					return result;
				}

				const toothKey = labelTagGetter.getTagKey(tags[0]);
				const requestedToShift = teethToShift.find((descriptor) => (
					descriptor.imageHashName === image.hashname &&
					descriptor.from === toothKey
				));

				if ( typeof requestedToShift !== 'undefined' && requestedToShift !== null ) {
					const conflict = teethConflicts.find((conflict) => (
						conflict.imageHashName === image.hashname &&
						conflict.toothKey === requestedToShift.to
					));
					result.push({
						toothKey,
						localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey: requestedToShift.to, notationType }),
						shiftedToothKey: requestedToShift.to,
						shape,
						withConflict: (typeof conflict !== 'undefined' && conflict !== null),
					});
				}
				else {
					const conflict = teethConflicts.find((conflict) => (
						conflict.imageHashName === image.hashname &&
						conflict.toothKey === toothKey
					));
					result.push({
						toothKey,
						localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
						shiftedToothKey: toothKey,
						shape,
						withConflict: (typeof conflict !== 'undefined' && conflict !== null),
					});
				}


				return result;
			}, []);

		return {
			teeth,
		};
	},
	() => ({})
)(ToothActions);
