import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { events } from '../../services/events';


export default class CanvasEditorConnector extends PureComponent {
	static propTypes = {
		canvasApi: PropTypes.object.isRequired,
		zoomInFactor: PropTypes.number,
		zoomOutFactor: PropTypes.number,
	};

	static defaultProps = {
		zoomInFactor: 3 / 2,
		zoomOutFactor: 2 / 3,
	};

	componentDidMount () {
		events.on('sidebar-collapse-changed', this._handleWindowResize);
		events.on('toolbar.zoom.in.requested', this._handleZoomInRequested);
		events.on('toolbar.zoom.out.requested', this._handleZoomOutRequested);
		events.on('toolbar.zoom.default.requested', this._handleZoomDefaultRequested);
	}

	componentWillUnmount () {
		events.removeListener('sidebar-collapse-changed', this._handleWindowResize);
		events.removeListener('toolbar.zoom.in.requested', this._handleZoomInRequested);
		events.removeListener('toolbar.zoom.out.requested', this._handleZoomOutRequested);
		events.removeListener('toolbar.zoom.default.requested', this._handleZoomDefaultRequested);
	}

	_handleZoomInRequested = () => {
		this.props.canvasApi.zoomIn(this.props.zoomInFactor);
	};

	_handleZoomOutRequested = () => {
		this.props.canvasApi.zoomOut(this.props.zoomOutFactor);
	};

	_handleZoomDefaultRequested = () => {
		this.props.canvasApi.resetPosition();
	};

	_handleWindowResize = () => {
		window.setTimeout(() => {
			this.props.canvasApi.resetPosition();
		}, 100);
	};

	render () {
		return null;
	}
}
