import React from 'react';
import { connect } from 'react-redux';

import mainConfig from '../../../../configs/mainConfig';

import commonUtils from '../../../../appUtils/common';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { isSequentialModeEnabledForVisit } from '../../utils';

import { checkFindingsFilter } from '../../utils/findings-filter';

import editorActions from '../../../../actions/editorActions';
import { removeLabel } from '../../actions';

import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import labelGetters from '../../../labels/selectors/labelGetters';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';

import { ResolverFindingViewer } from '../finding-viewer';


export default connect((state, props) => {
	const editorData = editorSelectors.selectEditor(state);
	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;
	let tooth = {
		toothKey: props.toothKey,
		localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey: props.toothKey, notationType }),
	};
	const currentCollectionId = editorData.currentCollectionHashName;
	const currentImageId = editorData.currentImageId;
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const findingsFilter = editorData.treatmentPlanFilters;
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
	const currentCollection = collectionsSelectors.selectCollectionById(state, {
		id: editorData.currentCollectionHashName,
	});
	const collectionImages = currentCollection.images.reduce((result, image) => {
		result[image.hashname] = image;

		return result;
	}, {});
	const imageTeeth = {};
	const teeth = labelsSelectors.selectTeeth(state);

	currentCollectionSelectors.selectExaminationImages(state, { examination: editorData.currentExamination })
		.forEach((image) => {
			imagesLabelsSelectors.selectImageLabelsByImageId(state, { imageId: image.id }).forEach((labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});

				if ( labelsUtils.labelIsTooth(label) === false ) {
					return;
				}

				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
				if ( Array.isArray(tags) === false || tags.length === 0 ) {
					return;
				}

				const toothKey = labelTagGetter.getTagKey(tags[0]);
				const shape = label.shapes[image.hashname] || {};
				if ( toothKey !== props.toothKey || typeof shape.type !== 'string' ) {
					return;
				}

				if ( currentImage.hashname === image.hashname ) {
					tooth = {
						...label,
						shape,
						toothKey,
						localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
					};
				}

				if ( imageTeeth.hasOwnProperty(image.hashname) === false ) {
					imageTeeth[image.hashname] = {};
				}

				imageTeeth[image.hashname] = { label, shape, imageId: image.id };
			});
		});

	const findings = Object.keys(imageTeeth).reduce((result, imageHashName) => {
		const label = imageTeeth[imageHashName].label;
		const data = {
			imageHashName,
			findings: [],
			imageType: collectionImages[imageHashName].image_type,
			imageUrl: collectionImages[imageHashName].image_url,
			teethMeta: teethUtils.sortTeethMeta(
				teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()),
				teeth[collectionImages[imageHashName]],
			),
			imageToothShape: imageTeeth[imageHashName].shapes[imageHashName] ? imageTeeth[imageHashName].shapes[imageHashName] : null,
		};

		result.push(data);

		labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) }).forEach((childLabelId) => {
			const childLabel = labelsSelectors.selectLabelById(state, {
				labelId: childLabelId,
			});
			const shape = childLabel.shapes[imageHashName] || {};
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);

			if (
				typeof shape.type === 'string' &&
				checkFindingsFilter({
					findingsFilter,
					label: childLabel,
					showBoneLossStages: editorData.showBoneLossStages,
					allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
				})
				&& (
					typeof measureOfConfidence !== 'number' ||
					editorData.filteredConfidencePercent <= measureOfConfidence
				)
			) {
				data.findings.push({
					label: {
						...childLabel,
						localizedLabelName: labelsUtils.getLocalizedLabelName(childLabel),
						shape,
					},
					allowRemove: mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(childLabel)) === false,
				});
			}
		});

		if ( data.findings.length > 0 ) {
			data.findings.sort((a, b) => a.label.localizedLabelName.localeCompare(b.label.localizedLabelName));
		}

		return result;
	}, []);

	return {
		isTooth: false,
		tooth: {
			toothKey: props.toothKey,
			localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey: props.toothKey, notationType }),
		},
		findings,
		currentImage,
		areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
		notationType,
		getImageUrl: (imageHashName = currentImage.hashname, seed) => {
			let imageUrl = null;
			currentCollection.images.forEach((image) => {
				if ( image.hashname === imageHashName ) {
					imageUrl = image.image_url;
					if ( seed !== null ) {
						imageUrl = commonUtils.addParamToUrl(imageUrl, 'seed', seed);
					}
				}
			});

			return imageUrl;
		},
		getImageLink: (imageHashName) => {
			return `/collections/${currentCollectionId}/image/${imageHashName}/treatment_plan`;
		},
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
	};
}, (dispatch) => ({
	onRemoveLabel: (data) => dispatch(removeLabel(data)),
	onClose: () => dispatch(editorActions.resetMode()),
}))(ResolverFindingViewer);
