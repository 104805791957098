import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';

import commonUtils from '../../appUtils/common';
import authApi from '../../api/authApi';

import { SUBMIT_STATUSES } from './PasswordResetConstants';
import PasswordReset from './PasswordReset';


class PasswordResetHoc extends PureComponent {
	static propTypes = {
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
	};

	state = {
		token: null,
		password: '',
		passwordConfirm: '',
		errors: [],
		submitStatus: SUBMIT_STATUSES.IDLE,
	};

	componentDidMount () {
		const params = (/^[?#]/.test(this.props.location.search) ? this.props.location.search.slice(1) : this.props.location.search)
			.split('&')
			.reduce((params, param) => {
					const [ key, value ] = param.split('=');
					params[key] = decodeURIComponent(value);
					return params;
				}, {});

		if ( typeof params.token === 'string' ) {
			this.setState({
				token: params.token,
			});
		}
		else {
			this.props.history.push('/');
		}
	}

	componentWillUnmount () {
		this.state = null;
	}

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleSubmit = (event) => {
		event.preventDefault();

		this._resetPassword();
	};

	/**
	 * @param {string} name
	 * @param {string} value
	 * @private
	 */
	_handleFieldChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	async _resetPassword () {
		try {
			this.setState({
				submitStatus: SUBMIT_STATUSES.IN_PROGRESS,
				errors: [],
			});

			const result = await authApi.changePassword(this.state.token, this.state.password);

			if ( this.state === null ) {
				return;
			}

			if ( commonUtils.inIframe() === true ) {
				commonUtils.goToLandingPage();
			}
			else {
				this.props.history.push(`/sign_in?from=password_reset`);
			}

			this.setState({
				submitStatus: SUBMIT_STATUSES.SUCCESS,
			});
		}
		catch (error) {
			if ( this.state === null ) {
				return;
			}

			this.setState({
				submitStatus: SUBMIT_STATUSES.FAILED,
				// errors: lodashGet(error, 'raw.error_codes', []),
				errors: lodashGet(error, 'response.data.errors', []),
			});
		}
	}

	render () {
		return (
			<PasswordReset
				password={this.state.password}
				passwordConfirm={this.state.passwordConfirm}
				submitStatus={this.state.submitStatus}
				errors={this.state.errors}
				onFieldChange={this._handleFieldChange}
				onSubmit={this._handleSubmit}
			/>
		);
	}
}

export default withRouter(PasswordResetHoc);
