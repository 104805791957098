import { getRuntimeConfig } from '../../appUtils/runtimeConfig';
import ReactDOM from 'react-dom';
import { createController } from '../../services/popup';
import helpers from '../../appUtils/helpers';
import Feedback from '../../components/Feedback';
import imageApi from '../../api/imageApi';
import editorActions from '../../actions/editorActions';
import editorSelectors from '../../selectors/editorSelectors';
import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';
import React from "react";


let serviceIsActive = false;
let store = null;
let isActiveTimerRun = false;


/**
 * Inits the service.
 *
 * @param {Object} reduxStore
 */
export function initFeedback (reduxStore) {
	serviceIsActive = true;
	store = reduxStore;
}

/**
 * Returns true if service is available, false otherwise.
 *
 * @return {boolean}
 */
export function canShowFeedback () {
	return serviceIsActive === true;
}

let globalContainer = null;


function detachElement () {
	if ( null === globalContainer ) {
		return;
	}

	ReactDOM.unmountComponentAtNode(globalContainer);
	window.document.body.removeChild(globalContainer);
	globalContainer = null;
}

/**
 * Displays feedback if required.
 *
 * @param {function} onReady
 */
export function requestFeedback ({ onReady } = {}) {
	if ( !serviceIsActive || !store ) {
		onReady && onReady();
		return;
	}
	
	const editorData = editorSelectors.selectEditor(store.getState());
	
	if ( !editorData.shouldShowFeedback ) {
		onReady && onReady();
		return;
	}
	
	store.dispatch(editorActions.updateData({
		data: {
			shouldShowFeedback: false,
		},
	}));

	globalContainer = document.createElement('DIV');
	globalContainer.className = 'feedback-container';
	window.document.body.appendChild(globalContainer);
	ReactDOM.render(
		<Feedback
			onSendFeedback={(params) => {
				const storeState = store.getState();
				const currentCollectionId = editorData.currentCollectionHashName;
				const currentImageId = editorSelectors.selectCurrentImageId(storeState);
				const currentImage = helpers.toApiStructure({
					storeState,
					imageId: currentImageId,
				});
				return imageApi.sendFeedback(currentCollectionId, currentImage.hashname, params)
			}}
			onClose={() => {
				detachElement();
				onReady && onReady();
			}}
		/>,
		globalContainer,
	);
}

let destroy = () => {};

/**
 * Runs timer to check activity.
 *
 * @return {function} destroy function
 */
export function runInactiveTimer () {
	if ( isActiveTimerRun || !canShowFeedback() ) {
		return;
	}
	let isDestroyed = false;
	isActiveTimerRun = true;
	
	let lastActionTime = Date.now();
	let checkTimeTimer = null;
	const handleAction = () => {
		lastActionTime = Date.now();
	};
	
	const checkTime = () => {
		const editorData = editorSelectors.selectEditor(store.getState());
		
		if ( !editorData.shouldShowFeedback ) {
			destroy();
			return;
		}
		
		if ( Date.now() - lastActionTime >= (getRuntimeConfig().feedback.non_interaction_timeout || 30000) ) {
			destroy();
			requestFeedback();
			return;
		}
		checkTimeTimer = window.setTimeout(checkTime, 1000);
	};
	
	checkTimeTimer = window.setTimeout(checkTime, 1000);
	
	window.addEventListener('mousemove', handleAction, false);
	window.addEventListener('mousedown', handleAction, false);
	window.addEventListener('mouseup', handleAction, false);
	window.addEventListener('keydown', handleAction, false);
	window.addEventListener('keyup', handleAction, false);
	window.addEventListener('touchstart', handleAction, false);
	window.addEventListener('touchend', handleAction, false);
	
	destroy = () => {
		if ( isDestroyed ) {
			return;
		}
		
		isDestroyed = true;
		if ( checkTimeTimer ) {
			window.clearTimeout(checkTimeTimer);
			checkTimeTimer = null;
		}
		window.removeEventListener('mousemove', handleAction, false);
		window.removeEventListener('mousedown', handleAction, false);
		window.removeEventListener('mouseup', handleAction, false);
		window.removeEventListener('keydown', handleAction, false);
		window.removeEventListener('keyup', handleAction, false);
		window.removeEventListener('touchstart', handleAction, false);
		window.removeEventListener('touchend', handleAction, false);
		
		isActiveTimerRun = false;
	};
	
	return destroy;
}

export function closeFeedback () {
	destroy();
	detachElement();

	if ( null !== store ) {
		const editorData = editorSelectors.selectEditor(store.getState());
		if ( true === editorData.shouldShowFeedback ) {
			store.dispatch(editorActions.updateData({
				data: {
					shouldShowFeedback: false,
				},
			}));
		}
	}
}
