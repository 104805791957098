import { addDictionary } from '../appUtils/locale';

import en from './en';
import ru from './ru';
import cn from './cn';

export default function () {
	addDictionary('en', en());
	addDictionary('ru', ru());
	addDictionary('cn', cn());
}
