import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ImageShapeBox from '../ImageShapes/shapes/box';

import './styles/DrawBox.css';
import {MIN_BOX_HEIGHT, MIN_BOX_WIDTH} from "../../constants/imageConstants";
import labelsUtils from "../../appUtils/labelsUtils";


const baseCssClassName = 'draw-box';
const svgCssClassName = `${baseCssClassName}__svg`;

class DrawBox extends Component {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		selectedClassId: PropTypes.string,
		imageOriginalWidth: PropTypes.number.isRequired,
		imageOriginalHeight: PropTypes.number.isRequired,

		onStart: PropTypes.func.isRequired,
		onFinish: PropTypes.func.isRequired,
	};
	
	constructor (props, context) {
		super(props, context);

		this._el = null;

		this._box = {
			startX: null,
			startY: null,
			endX: null,
			endY: null,
		};
	}
	componentWillUnmount () {
		window.document.removeEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.removeEventListener('mouseup', this._handleDocumentMouseUp, false);
	}

	_getDiff () {
		const {
			zoom,
		} = this.props;

		return (
			zoom === 1
				? 1
				: ( 1 / zoom )
		);
	}

	_handleRef = (el) => {
		this._el = el;
	}

	_handleMouseDown = (event) => {
		this.props.onStart();
		const diff = this._getDiff();

		this._beginX = event.clientX;
		this._beginY = event.clientY;

		const box = this._el.getBoundingClientRect();

		const x = ( event.clientX - box.left ) * diff;
		const y = ( event.clientY - box.top ) * diff;

		this._box.startX = x;
		this._box.startY = y;
		this._box.endX = x;
		this._box.endY = y;

		window.document.addEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.addEventListener('mouseup', this._handleDocumentMouseUp, false);

		this.forceUpdate();
	}

	_handleDocumentMouseMove = (event) => {
		const diff = this._getDiff();

		const deltaX = ( event.clientX - this._beginX ) * diff;
		const deltaY = ( event.clientY - this._beginY ) * diff;

		if ( deltaX < 0 ) {
			this._box.startX = this._box.endX + deltaX;
		}
		else {
			this._box.endX = this._box.startX + deltaX;
		}

		if ( deltaY < 0 ) {
			this._box.startY = this._box.endY + deltaY;
		}
		else {
			this._box.endY = this._box.startY + deltaY;
		}

		this._box.startX = Math.max(0, this._box.startX);
		this._box.startY = Math.max(0, this._box.startY);
		this._box.endX = Math.min(this.props.imageOriginalWidth, this._box.endX);
		this._box.endY = Math.min(this.props.imageOriginalHeight, this._box.endY);

		this.forceUpdate();
	}

	_handleDocumentMouseUp = () => {
		window.document.removeEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.removeEventListener('mouseup', this._handleDocumentMouseUp, false);

		if ((this._box.endX - this._box.startX) < MIN_BOX_WIDTH || (this._box.endY - this._box.startY) < MIN_BOX_HEIGHT) {
			this._box.endX = null;
		} else {
			this.props.onFinish({
				type: 'box',
				...this._box,
			});
		}
		this.forceUpdate();
	}
	
	_renderBox () {
		if ( this._box.startX === null || this._box.startY === null || this._box.endX === null || this._box.endY === null ) {
			return null;
		}

		const {
			zoom,
			labelShape,
			selectedClassId,
		} = this.props;

		let color = '#fff';
		let borderStyle = 'solid';


		if ( labelShape ) {
			color = (labelShape.color || color);
			borderStyle = (labelShape.border_style || borderStyle);
		}

		const fdaColor = labelsUtils.getSpecialLabelColor(selectedClassId);
		if ( fdaColor ) {
			color =  fdaColor;
		}

		const props = {
			shape: {
				type: 'box',
				...this._box,
			},
			color: color,
			borderStyle: borderStyle,
			zoom,
		};


		return (
			<ImageShapeBox {...props} />
		);
	}

	_renderPrimaryBox () {
		const {
			zoom,
			primaryBox,
		} = this.props;
		
		if ( true || !primaryBox ) {
			return null;
		}
	}
	
	render () {
		return (
			<div
				className={baseCssClassName}
				onMouseDown={this._handleMouseDown}
				ref={this._handleRef}
			>
				<svg xmlns={'http://www.w3.org/2000/svg'} className={svgCssClassName}>
					{this._renderBox()}
					{this._renderPrimaryBox()}
				</svg>
			</div>
		);
	}
}

export default DrawBox;
