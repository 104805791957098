import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import mainConfig from '../../configs/mainConfig';
import { APP_IMAGE_MODE } from '../../constants/imageConstants';

import { getAppImageMode } from '../../services/app';
import { confirm } from '../../services/popup';

import editorActions from '../../actions/editorActions';
import userActions from '../../actions/userActions';
import collectionsActions from '../../actions/collectionsActions';

import editorSelectors from '../../selectors/editorSelectors';

import Loading from '../Loading';


const ASYNC_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
};

class EnhancedImageFilter extends PureComponent {
	static propTypes = {
		className: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		isSmall: PropTypes.bool.isRequired,

		onUpdateUserProfile: PropTypes.func.isRequired,
		onUpdateCollection: PropTypes.func.isRequired,
		onChanged: PropTypes.func.isRequired,
	};

	state = {
		status: ASYNC_STATUS.IDLE,
	};

	componentWillUnmount () {
		this.state = null;
	}

	_save (value) {
		this.setState({
			status: ASYNC_STATUS.IN_PROGRESS,
		});
		this.props.onUpdateUserProfile({
			data: {
				is_image_enhancement_enabled: value,
			},
		})
			.then(() => {
				if ( !this.state ) {
					return;
				}

				const params = getAppImageMode() === APP_IMAGE_MODE.TREATMENT_PLAN
					? { params: { config_format: 'treatment' } }
					: {}

				return this.props.onUpdateCollection(params)
					.then(() => {
						if ( !this.state ) {
							return;
						}

						this.setState({
							status: ASYNC_STATUS.IDLE,
						});

						this.props.onChanged(value);
					});
			})
			.catch((error) => {
				console.log(error);
				if ( !this.state ) {
					return;
				}

				this.setState({
					status: ASYNC_STATUS.IDLE,
				});
				confirm({
					title: 'Error',
					titleIcon: 'error',
					message: 'An error occurred while saving user data. Please try again',
					yes: 'Try again',
					yesHandler: () => {
						this._save(value);
					},
				});
			});
	}

	_handleChanged = () => {
		this._save(!this.props.value);
	};

	render () {
		return (
			<div style={{ position: 'relative' }}>
				<div
					key={mainConfig.TOOLBAR_ITEM__IMAGE_SHARPEN}
					className={classnames([
						this.props.className,
						this.props.isSmall && `${this.props.className}__m-small`,
						`${this.props.className}__m-${mainConfig.TOOLBAR_ITEM__IMAGE_SHARPEN}`,
						this.props.value && `${this.props.className}__m-selected`,
					])}
					title={'Sharpen'}
					onClick={this._handleChanged}
				/>
				{this.state.status === ASYNC_STATUS.IN_PROGRESS && (
					<div style={{ position: 'absolute', inset: 0, background: 'rgba(255,255,255,.7)', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Loading width={20} height={20} />
					</div>
				)}
			</div>
		)
	};
}

export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);

		return {
			value: editorData.enhancedImageFilter,
		};
	},
	(dispatch) => ({
		onUpdateUserProfile: (options) => dispatch(userActions.updateProfile(options)),
		onUpdateCollection: (data) => dispatch(collectionsActions.getCollection(data)),
		onChanged: (value) => dispatch(editorActions.updateData({
			data: {
				enhancedImageFilter: value,
			},
		})),
	})
)(EnhancedImageFilter);
