import lodashDebounce from 'lodash/debounce';
import mainConfig from '../../../configs/mainConfig';
import labelsUtils from '../../../appUtils/labelsUtils';
import momentUtils from '../../../appUtils/momentUtils';
import { isMissingTooth, getMissingTooth } from '../utils';

import helpers from '../../../appUtils/helpers';
import {
	shiftAndSwapTeeth as shiftAndSwapTeethHelper,
	swapTeeth as swapTeethHelper,
	approveMissingTooth as approveMissingToothHelper,
	checkConflicts as checkConflictsHelper,
	removeShapesFromImage as removeShapesFromImageHelper,
} from '../helpers';

import imagesApi from '../../../api/imageApi';

import editorActions from '../../../actions/editorActions';
import apiActions from '../../../actions/apiActions';
import labelsActions from '../../../modules/labels/actions/labelsActions';
import collectionsActions from '../../../actions/collectionsActions';

import editorSelectors from '../../../selectors/editorSelectors';
import labelsSelectors from '../../../modules/labels/selectors/labelsSelectors';
import labelGetters from '../../../modules/labels/selectors/labelGetters';
import labelTagsSelectors from '../../label-tags/selectors/labelTagsSelectors';
import collectionsSelectors from '../../../selectors/collectionsSelectors';
import imagesSelectors from '../../../selectors/imagesSelectors';
import userSelectors from '../../../selectors/userSelectors';
import labelTagGetter from '../../label-tags/selectors/labelTagGetter';


export const addLabel = (data) => {
	return async (dispatch, getState) => {
		const isToothSelected = Boolean(data.toothKey);

		if ( isToothSelected ) {
			dispatch(editorActions.selectToothKey({
				toothKey: data.toothKey,
			}));
		}
		
		const existingLabel = labelsSelectors.selectLabelByToothKey(getState(), { toothKey: data.toothKey });
		
		if ( data.classId === 'tooth' && existingLabel ) {
			const missingToothLabel = getMissingTooth(data.toothKey);
			if ( missingToothLabel && missingToothLabel.missingLabel ) {
				return dispatch(labelsActions.removeLabel({
					labelId: labelGetters.getLabelId(missingToothLabel.missingLabel),
				}));
			}
		}

		dispatch(editorActions.setHistoryState(editorActions.HISTORY_STATE.STASH));

		// Adds a new tooth for missing finding without shape
		if ( data.classId === 'missing_tooth' ) {
			let existingLabelId = null;
			// Removes existing tooth and his findings
			if ( existingLabel ) {
				existingLabelId = labelGetters.getLabelId(existingLabel);
				await dispatch(labelsActions.removeLabel({ labelId: labelGetters.getLabelId(existingLabel) }));
			}
			
			const toothLabel = labelsSelectors.selectLabelByToothKey(getState(), {
				toothKey: data.toothKey,
			});
			
			if ( !toothLabel ) {
				dispatch(editorActions.addLabel({
					id: existingLabelId,
					classId: 'tooth',
					editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
					noParent: true,
					labelData: {
						tag: data.toothKey,
						source: 'manual',
					},
					requiredShape: false,
				}));
			}
		}

		dispatch(editorActions.setHistoryState(editorActions.HISTORY_STATE.WRITE));
		dispatch(editorActions.addLabel({
			classId: data.classId,
			editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
			noParent: !isToothSelected,
			labelData: {
				params: data.params,
				surfaces: data.surfaces,
				date: data.date,
				source: data.source,
				tag: data.classId === 'tooth' ? data.toothKey : null,
			},
			requiredShape: labelsUtils.isShapeRequiredForClass(data.classId),
		}));
	};
};

/**
 * @param {Object} options
 * @param {string} options.labelId
 * @return {(function(*, *): (undefined|*))|*}
 */
export const removeLabel = (options) => {
	return (dispatch, getState) => {
		const storeState = getState();
		const label = labelsSelectors.selectLabelById(storeState, { labelId: options.labelId });
		
		if ( labelsUtils.labelIsTooth(label) ) {
			dispatch(labelsActions.updateLabelData({
				labelId: options.labelId,
				data: {
					conflicts: [],
				},
			}));
			
			// Skip if tooth already is missing
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(getState(), { labelId: options.labelId });
			if ( tags && tags.length > 0 ) {
				if ( isMissingTooth(labelTagGetter.getTagKey(tags[0])) ) {
					return;
				}
			}

			dispatch(editorActions.addLabel({
				classId: 'missing_tooth',
				parentLabel: label,
				editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
				labelData: {
					date: momentUtils.getMomentForDate({ date: new Date() }).format('YYYY-MM-DD'),
					source: 'manual',
				},
				requiredShape: false,
			}));

			return;
		}

		dispatch(labelsActions.removeLabel(options));
	};
};

export const shiftAndSwapTeeth = (options) => {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = shiftAndSwapTeethHelper({
				storeState,
				selectedToothKey: editorSelectors.selectEditor(storeState).selectedToothKey,
				toothKeysToShift: options.toothKeysToShift,
				direction: options.direction,
			});
			
			dispatch(apiActions.setData(data));
		}));
	};
};

export const swapTeeth = (options) => {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();
			
			const data = swapTeethHelper({
				storeState,
				toothKey: options.toothKey,
				newToothKey: options.newToothKey,
			});
			
			dispatch(apiActions.setData(data));
		}));
	};
};

export const updateLabelData = (options) => {
	return (dispatch, getState) => {
		const labelId = options.labelId;
		const label = labelsSelectors.selectLabelById(getState(), { labelId });

		const labelData = {
			...label,
			...options.data,
		};

		dispatch(labelsActions.updateLabelData({
			labelId,
			data: checkConflictsHelper({ label: labelData, storeState: getState() }),
		}));
	};
};

export const approveMissingTooth = (options) => {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const labelId = options.labelId;
			const currentImageId = editorSelectors.selectCurrentImageId(storeState);

			const label = labelsSelectors.selectLabelById(storeState, { labelId });
			const parentLabel = labelsSelectors.selectLabelByToothKey(storeState, { toothKey: options.toothKey });
			const parentLabelId = labelGetters.getLabelId(parentLabel);

			// Remove children except the missing tooth
			const data = approveMissingToothHelper({
				storeState,
				labelId: parentLabelId,
				imageId: currentImageId,
			});

			// Change the shape of the parent label
			if ( data.labels[parentLabelId].shapes ) {
				data.labels[parentLabelId].shapes = Object.keys(data.labels[parentLabelId].shapes).reduce((result,hashname) => {
					result[hashname] = { type: 'none' };
					return result;
				}, {});
			}

			data.labels[labelId] = checkConflictsHelper({
				storeState: data,
				label,
			});
			
			dispatch(apiActions.setData(data));
		}));
	};
};

export const hideImageBadQualityNotification = () => {
	return (dispatch) => {
		dispatch(editorActions.updateData({
			data: {
				treatmentPlanHideBadImageNotification: true,
			},
		}));
	};
};

export const hideUnsupportedImageTypeNotification = () => {
	return (dispatch) => {
		dispatch(editorActions.updateData({
			data: {
				treatmentPlanUnsupportedImageTypeNotification: true,
			},
		}));
	};
};

const saveCollectionFilters = ({ collection, data, dispatch }) => {
	dispatch(collectionsActions.editCollection({
		hashname: collection.hashname,
		filter_list: data,
	}));
};

const saveCollectionFiltersQueue = lodashDebounce(saveCollectionFilters, 100);

export const changeFilters = (filters) => {
	return (dispatch, getState) => {
		try {
			dispatch(editorActions.updateData({
				data: {
					treatmentPlanFilters: filters,
				},
			}));

			const confidencePercent = editorSelectors.selectEditor(getState()).filteredConfidencePercent;

			const findingsFilterClasses = Object.keys(filters).reduce((result, key) => {
				if ( true === filters[key] ) {
					result.push(key);
				}

				return result;
			}, []);

			const storeState = getState();
			const currentCollection = collectionsSelectors.selectCollectionById(storeState, {
				id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
			});
			saveCollectionFiltersQueue({
				dispatch,
				collection: currentCollection,
				data: {
					threshold: confidencePercent,
					export_classes: findingsFilterClasses,
				},
			});
		}
		catch (error) {
			// do nothing
		}
	};
};

export const changeConfidencePercent = (value) => {
	return (dispatch, getState) => {
		try {
			dispatch(editorActions.setFilteredConfidencePercent(value));
			const filters = editorSelectors.selectEditor(getState()).treatmentPlanFilters;

			const findingsFilterClasses = Object.keys(filters).reduce((result, key) => {
				if ( true === filters[key] ) {
					result.push(key);
				}

				return result;
			}, []);

			const storeState = getState();
			const currentCollection = collectionsSelectors.selectCollectionById(storeState, {
				id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
			});
			saveCollectionFiltersQueue({
				collection: currentCollection,
				data: {
					threshold: value,
					export_classes: findingsFilterClasses,
				},
			});
		}
		catch (error) {
			// do nothing
		}
	};
};


export const loadImage = (options) => {
	return (dispatch, getState) => {
		const {
			collectionHashName,
			imageHashName,
		} = options;

		const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(getState());
		return imagesApi.getAnnotations(collectionHashName, imageHashName, isFDAAnnotationEnabled)
			.then((annotations) => {
				const storeState = getState();
				const image = imagesSelectors.selectImageByHashNameInCollection(getState(), {
					collectionHashName,
					imageHashName,
				});
				const imageId = image.id;

				let data = {};

				if ( annotations && Array.isArray(annotations.labels) && annotations.labels.length > 0  ) {
					data = {
						...helpers.toStateStructureReportTreatmentPlan({
							storeState,
							image: annotations,
							imageId,
						}),
					};
				}

				if ( Object.keys(data).length > 0 ) {
					dispatch(apiActions.setData(data));
				}

				return image;
			});
	};
};

export const updateLabelShape = (options) => {
	return (dispatch) => {
		const { labelId, imageHashName, data } = options;
		return dispatch(labelsActions.updateLabelShape({
			labelId,
			imageHashName,
			data,
		}));
	};
}

export const analyzeImage = () => {
	return (dispatch, getState) => {
		return dispatch(editorActions.analyzeCurrentImage())
			.then(() => {
				const storeState = getState();
				const currentImageId = editorSelectors.selectCurrentImageId(storeState);
				const editorData = editorSelectors.selectEditor(storeState);
				const { currentCollection } = editorSelectors.selectCurrentCollectionAndImage(storeState, { imageId: currentImageId });
				const currentExamination = editorData.currentExamination;
				let nextImages = [ ...editorData.notAnalyzedImages ];
				Object.values(imagesSelectors.selectImages(storeState)).forEach((image) => {
					if ( image.examination !== currentExamination ) {
						return;
					}
					nextImages = nextImages.filter((imageId) => imageId !== image.id);
				});

				dispatch(editorActions.updateData({
					data: {
						notAnalyzedImages: nextImages,
					},
				}));

				return dispatch(collectionsActions.getCollection({
					hashName: currentCollection.hashname,
					params: {
						config_format: 'treatment',
					},
				}));
			});
	};
}

/**
 * @param {Object} options
 * @param {string} options.imageHashName
 * @param {string} [options.toothKey]
 * @param {string} [options.labelId]
 * @return {(function(*, *): void)|*}
 */
export const removeLabelShape = (options) => {
	return (dispatch, getState) => {
		const storeState = getState();
		const findings = [];
		const currentCollectionId = editorSelectors.selectEditor(storeState).currentCollectionHashName;
		const image = imagesSelectors.selectImageByHashName(storeState, { hashname: options.imageHashName });
		const apiData = helpers.toApiStructure({
			storeState,
			imageId: image.id,
		});
		let labelUuid = options.labelId;
		if ( typeof options.labelId === 'string' ) {
			findings.push(apiData.labels.filter((label) => label.id === options.labelId)[0]);
		}
		else {
			// Find a tooth
			apiData.labels.forEach((label) => {
				if ( label.class_id === 'tooth' && Array.isArray(label.tags) && label.tags.length > 0 && label.tags[0] === options.toothKey ) {
					labelUuid = label.id;
					findings.push(label);
				}
			});
			// Find children
			apiData.labels.forEach((label) => {
				if (
					Array.isArray(label.relations) === true &&
					label.relations.length > 0 &&
					label.relations[0].type === 'child' &&
					label.relations[0].label_id === labelUuid
				) {
					findings.push(label);
				}
			});
		}

		return imagesApi.removeAnnotation(currentCollectionId, options.imageHashName, labelUuid, { findings })
			.then(({ findings: nextFindings }) => {
				dispatch(editorActions.putInImageHistory(async () => {
					let nextState = getState();
					findings.forEach((label) => {
						nextState = {
							...helpers.removeLabel({
								storeState: nextState,
								labelId: label.id,
							}),
						};
					});

					nextState = helpers.toStateStructure({
						storeState: {
							...getState(),
							...nextState,
						},
						annotation: {
							labels: nextFindings,
						},
						image,
						mergeStrategy: 'merge',
					});
					delete nextState.editor;

					return dispatch(apiActions.setData(nextState));
				}));
			});
	};
}

/**
 * @param {Object} options
 * @param {string} options.imageId
 * @return {(function(*, *): void)|*}
 */
export const removeShapesFromImage = (options) => {
	return (dispatch, getState) => {
		dispatch(apiActions.setData(removeShapesFromImageHelper({
			storeState: getState(),
			imageId: options.imageId,
		})));
	};
}
