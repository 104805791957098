import classnames from 'classnames';

import constants from './AlignmentConstants';


/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.horizontal="LEFT"]
 *
 * @return {string}
 */
function buildBaseClassName(props = {}) {
	const horizontal = props.horizontal || constants.HORIZONTAL_ALIGNMENT.LEFT;

	return classnames([
		constants.baseCssClassName,
		horizontal && `${constants.baseCssClassName}__m-horizontal-${horizontal}`,

	]);
}

/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.vertical="TOP"]
 *
 * @return {string}
 */
function buildContentClassName(props = {}) {
	const vertical = props.vertical || constants.VERTICAL_ALIGNMENT.TOP;

	return classnames([
		constants.contentCssClassName,
		vertical && `${constants.contentCssClassName}__m-vertical-${vertical}`,
	]);
}


export default {
	buildBaseClassName,
	buildContentClassName,
};
