import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/TileFilter.css';


const baseCssClassName = 'ui-huddle-tile-filter';
const contentCssClassName = `${baseCssClassName}__content`;
const showBtnCssClassName = `${baseCssClassName}__show-btn`;
const hideBtnCssClassName = `${baseCssClassName}__hide-btn`;


const TileFilter = ({ isActive, children, disabled, onChange }) => {
	const toggleChange = React.useCallback(() => {
		if ( disabled ) {
			return;
		}

		onChange(!isActive);
	}, [ isActive, disabled, onChange ]);

	return (
		<div
			className={classnames([
				baseCssClassName,
				isActive && `${baseCssClassName}__m-active`,
			])}
			onClick={toggleChange}
		>
			<div className={contentCssClassName}>
				{children}
			</div>
			{isActive && (<div className={hideBtnCssClassName} />)}
			{!isActive && (<div className={showBtnCssClassName} />)}
		</div>
	);
};

TileFilter.propTypes = {
	isActive: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

export default TileFilter;
