export default {
	'labels.new_password': '新密码',
	'labels.confirmation': '再次确认新密码',
	'labels.current_password': '目前密码',

	'info': `
		请输入新密码<br />
	`,

	'dialog.title': '修改密码',
};
