import React, { PureComponent } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Events from 'events';
import lodashGet from 'lodash/get';

import { setCanvas, setViewportData } from '../../../../services/canvas';
import urlCache from '../../../../services/url-cache';
import { message } from '../../../../services/popup';
import { events } from '../../../../services/events';

import labelsUtils from '../../../../appUtils/labelsUtils';
import { addMarginToShape } from '../../../../components/ImageShapes/shapes/utils/shapesUtils';

import labelGetters from '../../../labels/selectors/labelGetters';

import { DRAGGING_STATUS } from '../../../../components/Canvas/CanvasConstants';

import Alignment from '../../../../components/Alignment';
import Loading from '../../../../components/Loading';

import CanvasCollectionImageLoader from '../../../../components/Canvas/CanvasCollectionImageLoader';
import ImageShapeBox from '../../../../components/ImageShapes/shapes/box';
import ImageShapePolygon from '../../../../components/ImageShapes/shapes/polygon';
import ImageShapeBoneloss from '../../../../components/ImageShapes/shapes/boneloss';
import Popup from '../../../../components/Popup';

import ShapeTooltip from '../../../resolver/components/shape-tooltip';

import SizeDetector from '../../../../components/SizeDetector';
import TfvToothAction from './TfvToothActionConnector';
import Viewport from '../../../../components/Canvas/Viewport';
import MagnifyingGlassImage from '../../../../components/MagnifyingGlass/MagnifyingGlassImageConnector';
import UiAttachedImage from '../../../../components/MagnifyingGlass/UiAttachedImage';
import CanvasObjects from '../../../../components/ImageShapes/CanvasObjects';
import CanvasEditorConnector from '../../../../components/Canvas/CanvasEditorConnector';
import TfvListShapes from './TfvListShapes';

import { getDictionary } from '../../../../appUtils/locale';

import './style/TfvList.css';


const baseCssClassName = 'tfv-list';
const canvasCssClassName = `${baseCssClassName}__canvas`;
const imagesCssClassname = `${baseCssClassName}__images`;
const imageToothKeyCssClassName = `${baseCssClassName}__image-tooth-key`;
const imageToothKeyInnerCssClassName = `${baseCssClassName}__image-tooth-key-inner`;
const sliderCssClassName = `${baseCssClassName}__slider`;
const sliderActionCssClassName = `${baseCssClassName}__slider-action`;


const PROGRESS_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	FAILED: 'failed',
	DONE: 'done',
};

const i18nShared = getDictionary('shared');


class SelectionController {
	_selectedObject = null

	_events = new Events();

	setObject (selectedObject) {
		this._selectedObject = selectedObject;
		this._events.emit('changed', selectedObject);
	}

	getObject () {
		return this._selectedObject;
	}

	listen (callback) {
		this._events.on('changed', callback);
	}

	destroy () {
		this._events.removeAllListeners();
		this._events = null;
	}
}

export default class TfvList extends PureComponent {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		localizedToothKey: PropTypes.string.isRequired,
		prevToothKey: PropTypes.string,
		nextToothKey: PropTypes.string,
		images: PropTypes.object.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,
		labelColorFilterFn: PropTypes.func.isRequired,
		selectedLabel: PropTypes.object,
		allowShifting: PropTypes.bool.isRequired,
		allowRemoving: PropTypes.bool.isRequired,
		showFindingsOnImage: PropTypes.bool,
		enhancedImageFilter: PropTypes.bool.isRequired,

		onUpdateLabelShape: PropTypes.func.isRequired,
		onLabelRemove: PropTypes.func.isRequired,
		onSelectLabel: PropTypes.func.isRequired,
		onShiftTooth: PropTypes.func.isRequired,
		onGoToTooth: PropTypes.func.isRequired,
		onGoToClosestTooth: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	static defaultProps = {
		labelColorFilterFn: () => true,
		shouldDisplayTeethNumbersUnderImages: true,
	};

	_canvasApi = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_selectedImageEl = null;

	_leaveImageTimerId = null;

	_imagesRatio = {};

	/**
	 * @type {number}
	 * @private
	 */
	_lastTouchTime = 0;

	state = {
		selectedImageHashName: null,
		buttonsHovered: false,
		teethMoveStatus: PROGRESS_STATUS.IDLE,
		findingRemoveStatus: PROGRESS_STATUS.IDLE,
	};

	_selectionController = new SelectionController();

	componentDidMount () {
		window.document.body.addEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.body.addEventListener('keydown', this._handleDocumentKeyDown, false);

		events.on('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
		this._selectionController.listen((selectedObject) => {
			this.props.onSelectLabel(selectedObject ? { labelId: selectedObject.id } : { labelId: null });
		});

		ReactDom.findDOMNode(this).addEventListener('mousedown', this._handleLayoutMouseDown, false);
	}

	componentDidUpdate () {
		if ( Object.keys(this.props.images).length === 0 ) {
			this.props.onGoToClosestTooth(this.props.toothKey);
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			lodashGet(nextProps, 'selectedLabel.labelId') !== lodashGet(this.props, 'selectedLabel.labelId') &&
			nextProps.selectedLabel
		) {
			this._selectionController.setObject({
				id: lodashGet(nextProps, 'selectedLabel.labelId'),
				...labelGetters.getLabelShape(nextProps.selectedLabel)
			});
		}
	}

	componentWillUnmount() {
		if ( this._selectionController !== null ) {
			this._selectionController.destroy();
			this._selectionController = null;
		}
		window.document.body.removeEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.body.removeEventListener('keydown', this._handleDocumentKeyDown, false);
		events.off('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
		ReactDom.findDOMNode(this).removeEventListener('mousedown', this._handleLayoutMouseDown, false);
	}

	_handleImageMouseEnter = (event) => {
		const target = event.currentTarget;
		if ( target.dataset.imageHashname !== undefined && target.dataset.imageHashname.length > 0 ) {
			this.setState({
				selectedImageHashName: target.dataset.imageHashname,
			});
			this._selectedImageEl = target;
			if ( this._leaveImageTimerId !== null ) {
				clearTimeout(this._leaveImageTimerId);
				this._leaveImageTimerId = null;
			}
		}
	};

	_handleImageMouseLeave = () => {
		this._leaveImageTimerId = setTimeout(() => {
			if ( this.state.rotateButtonsHovered ) {
				return;
			}

			this.setState({
				selectedImageHashName: null,
			});
			this._selectedImageEl = null;
			this._leaveImageTimerId = null;
		}, 50);
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleDocumentMouseMove = (event) => {
		if ( this._selectedImageEl === null ) {
			return;
		}

		const image = this._selectedImageEl;
		const imagePosition = image.getBoundingClientRect();
		const zoom = this._imagesRatio[image.dataset.imageHashname];
		events.emit('canvas.pointer.moved', {
			x: (event.clientX - imagePosition.left) / zoom,
			y: (event.clientY - imagePosition.top) / zoom,
		});
	};

	/**
	 * @param {KeyboardEvent} event
	 * @private
	 */
	_handleDocumentKeyDown = (event) => {
		if ( event.key.toLowerCase() === 'escape' ) {
			this._selectionController.setObject(null);
		}
	};

	_handleTouchedObject = (object) => {
		this._touchedObject = object;
	};

	_handleLayoutMouseDown = () => {
		if ( this._touchedObject === null ) {
			this._selectionController.setObject(null);
		}
		this._touchedObject = null;
	};

	_handleTeethShiftStatusChanged = (teethMoveStatus) => {
		this.setState({
			teethMoveStatus,
		});
	};

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_handleMoveTeeth = (toothKey, nextToothKey, imageHashName) => {
		this._moveTeeth(toothKey, nextToothKey, imageHashName, true);
	};

	/**
	 * @param {string} toothKey
	 * @param {string} imageHashName
	 * @private
	 */
	_handleRemoveTooth = ({ toothKey, imageHashName }) => {
		this._removeShape({
			toothKey,
			imageHashName,
		});
	};

	_handleSliderPrevToothClick = () => {
		if (  typeof this.props.prevToothKey !== 'string' ) {
			return;
		}

		this.props.onGoToTooth(this.props.prevToothKey);
	};

	_handleSliderNextToothClick = () => {
		if (  typeof this.props.nextToothKey !== 'string' ) {
			return;
		}

		this.props.onGoToTooth(this.props.nextToothKey);
	};

	/**
	 * @param {CollectionImage} image
	 * @private
	 */
	_goToImage = (image) => {
		const collectionId = this.context.router.route.match.params.collectionId;
		this.context.router.history.push(`/collections/${collectionId}/image/${image.hashname}/treatment_plan`);
	};

	_removeShape (params) {
		this.setState({
			findingRemoveStatus: PROGRESS_STATUS.IN_PROGRESS,
		});
		this.props.onLabelRemove(params)
			.then(() => {
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'Finding is not removed. Please try again.',
				});
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			});
	}

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_moveTeeth (toothKey, nextToothKey, imageHashName) {
		return this.props.onShiftTooth({
			toothKey,
			nextToothKey,
			imageHashName,
		});
	}

	_renderLayouts () {
		if ( this.state.teethMoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The image tooth is shifting, please wait.',
						}}
					/>
				</Popup>
			);
		}
		else if ( this.state.findingRemoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The finding is removing, please wait.',
						}}
					/>
				</Popup>
			);
		}

		return null;
	}

	/**
	 * @param {Object} options
	 * @param {Object} options.shapeApi
	 * @param {Object} options.canvasObjectsApi
	 * @return {JSX.Element|null}
	 * @private
	 */
	_renderShape ({ canvasApi, shapeApi, canvasObjectsApi, imageWidth, imageHeight, imageHashName }) {
		const props = {
			id: shapeApi.id,
			shape: shapeApi.shape,
			color: shapeApi.color,
			borderStyle: shapeApi.borderStyle,
			showControls: shapeApi.isSelectedLabel === true,
			isHighlighted: shapeApi.isHighlighted === true,
			// allowEditing: false,
			allowEditing: shapeApi.isSelectedLabel === true,
			showConfirmation: shapeApi.isHighlighted === true,
			allowDeleting: shapeApi.isHighlighted === true,
			zoom: canvasObjectsApi.zoom,
			imageWidth,
			imageHeight,
			isVisible: shapeApi.isVisible,
			canvasObjectsApi,
			showMask: this.props.areFindingsMasksEnabled === true,
			showHeatMap: this.props.areHeatMapsEnabled === true,
			onSetEditing: (value) => {
				this._lastSelectedImageHashName = value === true ? this.state.selectedImageHashName : null;
				canvasApi.canDragCanvas(!value);
			},
			onLabelChange: (shape) => {
				this.props.onUpdateLabelShape({
					labelId: shapeApi.id,
					imageHashName: this._lastSelectedImageHashName,
					data: {
						...shapeApi.shape,
						...shape,
					},
				});
			},
			onLabelRemove: () => this.props.onLabelRemove({ labelId: shapeApi.id, imageHashName }),
			onGetHeatmapUrl: (shape) => {
				if ( urlCache.hasCache(shapeApi.id) ) {
					return urlCache.getCache(shapeApi.id);
				}

				const url = shapeApi.onGetHeatmapUrl(shape, shapeApi.onGetColor);
				urlCache.setCache(shapeApi.id, url);

				return url;
			},
		};

		let Component = null;

		switch (shapeApi.shape.type) {
			case 'box':
				Component = ImageShapeBox;
				break;

			case 'poly':
				Component = ImageShapePolygon;
				break;

			case 'named_poly':
				Component = ImageShapeBoneloss;
				break;

			default:
				return null;
		}

		if ( shapeApi.shouldShowTooltip === true ) {
			return (
				<ShapeTooltip
					key={`shape_${shapeApi.id}`}
					label={shapeApi.label}
					viewport={canvasApi.viewport}
					component={Component}
					componentProps={props}
				/>
			);
		}

		return (<Component {...props} key={shapeApi.id} />);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={imagesCssClassname}>
					<SizeDetector>
						{({ width: containerWidth, height: containerHeight }) => (
							<Viewport
								canvasWidth={containerWidth}
								canvasHeight={containerHeight}
								viewportWidth={containerWidth}
								viewportHeight={containerHeight}
								onGetZoom={(zoom) => {
									if ( zoom !== null ) {
										return Math.min(
											6,
											Math.max(0.5, zoom)
										);
									}

									return 1;
								}}
							>
								{(canvasApi) => {
									if ( canvasApi.viewport === null ) {
										return null;
									}
									this._canvasApi = canvasApi;
									setCanvas(canvasApi.viewport);
									setViewportData({
										zoom: canvasApi.zoom,
										offsetX: -canvasApi.offsetX,
										offsetY: -canvasApi.offsetY,
									});
									const images = Object.values(this.props.images);
									let index = 0;
									const [ rows, columns ] = [ 3, 3 ];
									const width = Math.floor(containerWidth / rows);
									const height = Math.floor(containerHeight / columns);
									const content = [];
									for (let row = 0; row < rows; row++) {
										for (let column = 0; column < columns; column++) {
											if ( index >= images.length ) {
												break;
											}

											const imageData = images[index++];
											const image = imageData.image;
											const shape = imageData.shape;
											const labels = imageData.labels;
											const cellWidth = (width - (column < columns - 1 ? 10 : 0)) * canvasApi.zoom;
											const cellHeight = (height - (row < rows - 1 ? 10 : 0))  * canvasApi.zoom;

											content.push((
												<div
													key={image.hashname}
													style={{
														position: 'absolute',
														top: row * height * canvasApi.zoom,
														left: column * width * canvasApi.zoom,
														width: cellWidth,
														height: cellHeight,
														overflow: 'hidden',
													}}
													onDoubleClick={() => this._goToImage(image)}
													onTouchStart={(event) => {
														if ( event.touches.length === 1 ) {
															if ( this._lastTouchTime === 0 ) {
																this._lastTouchTime = event.timeStamp;
															}
															else if ( event.timeStamp <= this._lastTouchTime + 400 ) {
																event.preventDefault();
																this._goToImage(image);
																this._lastTouchTime = 0;
															}
															else {
																this._lastTouchTime = event.timeStamp;
															}
														}
													}}
												>
													<CanvasCollectionImageLoader image={image} key={image.hashname} useEnhancedImage={this.props.enhancedImageFilter}>
														{({ status, image: imageEl, retryLoad }) => {
															switch (status) {
																case CanvasCollectionImageLoader.STATUSES.FAILED:
																	return (
																		<Alignment
																			horizontal={Alignment.horizontal.CENTER}
																			vertical={Alignment.vertical.CENTER}
																		>
																		<span
																			style={{
																				color: '#fff',
																				fontSize: 12,
																				cursor: 'pointer'
																			}}
																			onClick={retryLoad}
																		>Reload image</span>
																		</Alignment>
																	);

																case CanvasCollectionImageLoader.STATUSES.LOADED: {
																	const imageWidth = imageEl.naturalWidth;
																	const imageHeight = imageEl.naturalHeight;
																	const ratio = Math.min(width * canvasApi.zoom / imageWidth, height * canvasApi.zoom / imageHeight);

																	// 1. Get a polygon bounding rect.
																	const roiRect = labelsUtils.getBoundingRectForShape(addMarginToShape(shape, 1, 50));
																	const roiLeft = roiRect.left;
																	const roiRight = roiRect.right;
																	const roiTop = roiRect.top;
																	const roiBottom = roiRect.bottom;
																	const roiWidth = roiRight - roiLeft;
																	const roiHeight = roiBottom - roiTop;

																	// 2. Find smallest ratio for x and y.
																	const ratioX = cellWidth / roiWidth;
																	const ratioY = cellHeight / roiHeight;
																	const resultRatio = Math.min(ratioX, ratioY);

																	this._imagesRatio[image.hashname] = resultRatio;

																	return (
																		<>
																			<div
																				style={{
																					position: 'relative',
																					left: (-roiLeft * resultRatio) + (cellWidth - roiWidth * resultRatio) / 2,
																					top: (-roiTop * resultRatio) + (cellHeight - roiHeight * resultRatio) / 2 ,
																					width: imageWidth * resultRatio,
																					height: imageHeight * resultRatio,
																				}}
																				data-image-hashname={image.hashname}
																				onMouseEnter={this._handleImageMouseEnter}
																				onMouseLeave={this._handleImageMouseLeave}
																			>
																				<MagnifyingGlassImage
																					image={imageEl}
																					width={imageWidth * resultRatio}
																					height={imageHeight * resultRatio}
																					sharpen={this.props.enhancedImageFilter ? !image.enhanced_image_url : undefined}
																					View={UiAttachedImage}
																				/>
																				<CanvasObjects
																					zoom={resultRatio}
																					isVisible={this._canvasApi.draggingStatus === DRAGGING_STATUS.IDLE}
																					onTouched={this._handleTouchedObject}
																				>
																					{(canvasObjectsApi) => (
																						<TfvListShapes
																							labels={labels}
																							selectedObject={canvasObjectsApi.selectedObject}
																							closestObjectToPoint={canvasObjectsApi.closestObjectToPoint}
																							isSelectedObjectInTransformation={canvasObjectsApi.isSelectedObjectInTransformation}
																							labelColorFilterFn={this.props.labelColorFilterFn}
																							selectionController={this._selectionController}
																							onSetSelectedObject={canvasObjectsApi.setSelectedObject}
																						>
																							{(shapeApi) => this._renderShape({ canvasApi, shapeApi, canvasObjectsApi, imageWidth, imageHeight, imageHashName: image.hashname })}
																						</TfvListShapes>
																					)}
																				</CanvasObjects>
																			</div>
																			{this.props.showFindingsOnImage === true && (
																				<div className={imageToothKeyCssClassName}>
																					<div className={imageToothKeyInnerCssClassName}>
																						<TfvToothAction
																							toothKey={this.props.toothKey}
																							localizedToothKey={this.props.localizedToothKey}
																							viewportLocation={{
																								left: 0,
																								top: 0,
																								right: window.document.body.clientWidth,
																								bottom: window.document.body.clientHeight,
																							}}
																							imageHashName={image.hashname}
																							allowShifting={this.props.allowShifting}
																							allowRemoving={this.props.allowRemoving}
																							onShiftTooth={this._handleMoveTeeth}
																							onRemoveTooth={this._handleRemoveTooth}
																						/>
																					</div>
																				</div>
																			)}
																		</>
																	);
																}

																default:
																	return (
																		<Alignment
																			horizontal={Alignment.horizontal.CENTER}
																			vertical={Alignment.vertical.CENTER}
																		>
																			<Loading />
																		</Alignment>
																	);
															}
														}}
													</CanvasCollectionImageLoader>
												</div>
											));
										}
									}

									return (
										<div className={canvasCssClassName}>
											<CanvasEditorConnector canvasApi={canvasApi} />
											{content}
										</div>
									);
								}}
							</Viewport>
						)}
					</SizeDetector>
					<div className={sliderCssClassName}>
						<div
							className={classnames([
								sliderActionCssClassName,
								typeof this.props.prevToothKey !== 'string' && `${sliderActionCssClassName}__m-disabled`,
							])}
							title={'Go to the previous tooth'}
							onClick={this._handleSliderPrevToothClick}
						/>
					</div>
					<div className={`${sliderCssClassName} ${sliderCssClassName}__m-right`}>
						<div
							className={classnames([
								sliderActionCssClassName,
								`${sliderActionCssClassName}__m-right`,
								typeof this.props.nextToothKey !== 'string' && `${sliderActionCssClassName}__m-disabled`,
							])}
							title={'Go to the next tooth'}
							onClick={this._handleSliderNextToothClick}
						/>
					</div>
				</div>
				{this._renderLayouts()}
			</div>
		);
	}
}
