import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PopupDialog from '../PopupDialog';
import Datepicker from '../Datepicker';
import FormInput from '../FormInput';

import momentUtils from '../../appUtils/momentUtils';
import { getDictionary } from '../../appUtils/locale';


const i18n = getDictionary('datepicker');

const baseCssClassName = 'form-datepicker';


class FormDatepicker extends PureComponent {
	static propTypes = {
		name: PropTypes.string,
		date: PropTypes.instanceOf(Date),
		datepickerProps: PropTypes.object,
		onGetRef: PropTypes.func,
		onDateChange: PropTypes.func,
	}
	
	constructor (props, context) {
		super(props, context);

		this._inputEl = null;

		this.state = {
			isPopupOpened: false,
			date: props.date,
		};
	}

	_handlePopupClose = () => {
		this._inputEl.blur();

		this.setState({
			isPopupOpened: false,
		});
	}

	_handleDateChange = (date, modifiers) => {
		if ( modifiers.disabled ) {
			return;
		}

		const {
			onDateChange,
		} = this.props;

		this.setState({
			date,
			isPopupOpened: false,
		});

		this._inputEl.blur();

		if ( onDateChange ) {
			onDateChange(date);
		}
	}

	_handleFocus = () => {
		this.setState({
			isPopupOpened: true,
		});

		this._inputEl.blur();
	}

	_handleRef = (el) => {
		const {
			onGetRef,
		} = this.props;

		this._inputEl = el;

		if ( onGetRef ) {
			onGetRef(el);
		}
	}

	_renderPopup () {
		if ( !this.state.isPopupOpened ) {
			return;
		}

		return (
			<PopupDialog
				headerProps={{
					title: i18n('dialog.title'),
				}}
				content={(
					<Datepicker
						{...this.props.datepickerProps}
						month={this.props.date}
						fixedWeeks
						onDayClick={this._handleDateChange}
						selectedDays={[
							this.state.date,
						]}
					/>
				)}
				popupProps={{
					onClose: this._handlePopupClose,
				}}
			/>
		);
	}

	render () {
		const {
			name,
			date,
			birthday,
			disabled,
		} = this.props;
		const formattedDate = date ? momentUtils.getMomentForDate({date}).format('L') : '';
		const birthdayText = birthday ? `(${birthday})` : '';
		return (
			<div className={baseCssClassName}>
				<FormInput
					name={name}
					value={`${formattedDate}${birthdayText}`}
					onGetRef={this._handleRef}
					onFocus={this._handleFocus}
					disabled={disabled}
				/>
				
				{this._renderPopup()}
			</div>
		);
	}
}

export default FormDatepicker;
