import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormCustomSelect from '../FormCustomSelect';

import './styles/LabelTagsSelector.css';


const baseCssClassName = 'label-tags-selector';
const itemCssClassName = `${baseCssClassName}__item`;
const hotKeyCssClassName = `${baseCssClassName}__hot-key`;


class DiagnosisSelect extends PureComponent {
	static propTypes = {
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
			label: PropTypes.string.isRequired,
			hotKey: PropTypes.string,
			requiredBox: PropTypes.bool,
		})),
		onChange: PropTypes.func.isRequired,
	}

	_handleRenderItem = (item) => (
		<div className={classnames([
			itemCssClassName,
			item.disabled && `${itemCssClassName}__m-disabled`,
		])}>
			{item.hotKey && <div className={hotKeyCssClassName}>{item.hotKey}</div>}
			<span>{item.label}</span>
		</div>
	)

	render () {
		return (
			<FormCustomSelect
				items={this.props.items}
				value={this.props.value}
				onRenderItem={this._handleRenderItem}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default DiagnosisSelect;
