import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles/Canvas.css';


const baseCssClassName = 'canvas-grid';


export default class CanvasGrid extends PureComponent {
	static propTypes = {
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		zoom: PropTypes.number.isRequired,
	};

	/**
	 * @type {HTMLCanvasElement|null}
	 * @private
	 */
	_gridCanvasEl = null;

	componentDidMount () {
		this._drawGrid();
	}

	componentDidUpdate () {
		this._drawGrid();
	}

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handleGridCanvasRef = (element) => {
		if ( element === null || element === undefined || element === this._gridCanvasEl ) {
			return;
		}
		this._gridCanvasEl = element;
	};

	_drawGrid () {
		const canvas = this._gridCanvasEl;
		const width = this.props.width * this.props.zoom;
		const height = this.props.height * this.props.zoom;

		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext('2d');

		const side = Math.max(width, height) / 36;

		let x = side;
		let y = 0;

		context.strokeStyle = '#ccc';
		context.setLineDash([ 1, 1 ]);

		for (let i = 0, max = Math.ceil(width / side); i < max; i++) {
			context.lineWidth = 1;
			context.beginPath();

			context.moveTo(x, y);
			context.lineTo(x, height);
			context.stroke();

			x += side;
		}

		x = 0;
		y = side;

		for (let i = 1, max = Math.ceil(height / side); i < max; i++) {
			context.lineHeight = 1;
			context.beginPath();
			context.moveTo(x, y);
			context.lineTo(width, y);
			context.stroke();

			y += side;
		}
	}

	render () {
		return (
			<canvas
				className={baseCssClassName}
				ref={this._handleGridCanvasRef}
			/>
		);
	}
}
