import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import userActions from '../../actions/userActions';

import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';

import './styles/SignIn.css';


const i18n = getDictionary('sign-in');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'sign-in';
const titleCssClassName = `${baseCssClassName}__title`;
const formCssClassName = `${baseCssClassName}__form`;
const formButtonCssClassName = `${baseCssClassName}__form-button`;
const formElementCssClassName = `${baseCssClassName}__form-element`;
const errorCssClassName = `${baseCssClassName}__error`;


class SigIn extends Component {
	static propTypes = {
		onSignIn: PropTypes.func.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this.state = {
			username: '',
			password: '',
			hasSignInError: false,
		};
	}

	componentWillUnmount () {
		this.state = null;
	}

	_handleUsernameChange = (event) => {
		this.setState({
			username: event.target.value,
			hasSignInError: false,
		});
	}

	_handlePasswordChange = (event) => {
		this.setState({
			password: event.target.value,
			hasSignInError: false,
		});
	}

	_handleSubmit = (event) => {
		event.preventDefault();

		this.setState({
			hasSignInError: false,
		});

		this.props.onSignIn({
			username: this.state.username,
			password: this.state.password,
		})
			.then(() => {
				if ( !this.state ) {
					return;
				}

				this.context.router.history.push(`/`);
				window.location.reload(true);
			})
			.catch((error) => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					hasSignInError: true,
				});
			});
	}

	render () {
		const {
			hasSignInError,
		} = this.state;

		return (
			<div className={baseCssClassName}>
				<div
					className={titleCssClassName}
					onClick={this._handleSignInMenuClick}
				>{i18n('title')}</div>

				<form
					className={formCssClassName}
					onSubmit={this._handleSubmit}
				>
					{ this.state.hasSignInError && (
						<div
							className={errorCssClassName}
							dangerouslySetInnerHTML={{
								__html: i18n('auth.error'),
							}}
						/>
					) }
					<div className={formElementCssClassName}>
						<FormLabel
							element={
								(
									<FormInput
										name={'username'}
										value={this.state.username}
										type={'text'}
										isInvalid={hasSignInError}
										block
										onChange={this._handleUsernameChange}
									/>
								)
							}
							text={i18n('labels.login')}
							textPosition={FormLabel.textPosition.TOP}
						/>
					</div>
					<div className={formElementCssClassName}>
						<FormLabel
							element={
								(
									<FormInput
										name={'password'}
										value={this.state.password}
										type={'password'}
										isInvalid={hasSignInError}
										block
										onChange={this._handlePasswordChange}
									/>
								)
							}
							text={i18n('labels.password')}
							textPosition={FormLabel.textPosition.TOP}
						/>

					</div>
					<div style={{ fontSize: 12 }}><a href={'/forgot_password'}>Forgot password?</a></div>
					<div className={formButtonCssClassName}>
						<Button
							type='submit'
							theme={Button.AVAILABLE_THEMES.PRIMARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							type={Button.AVAILABLE_TYPES.SUBMIT}
							uppercase
						>{i18nShared('buttons.sign_in')}</Button>
					</div>
				</form>
			</div>
		);
	}
}

export default connect(null, (dispatch) => ({
	onSignIn: (data) => dispatch(userActions.signIn(data)),
}))(SigIn);
