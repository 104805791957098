export default {
	'image.alt': 'Загруженный снимок',

	'labels.image_type': "Тип снимка",
	'labels.patient_id': "ID пациента",
	'labels.birthday': "Дата рождения",
	'labels.gender': "Пол",
	'labels.image_date': "Дата снимка",

	'dialog.title': 'Загрузка снимка',

	'wrong_extension.dialog.content': `
		К сожалению не удалось загрузить изображение.<br />
		Доступные форматы изображений: {formats}.<br />
		Пожалуйста, загрузите файл с другим расширением.
	`,

	'notifications.child-type': 'По видимому вы пытаетесь загрузить панорамные снимки во время дентации. Сервис пока не поддерживает их анализ.',
	'notifications.unknown-type': 'По видимому вы пытаетесь загрузить снимок другой модальности или плохого качества. На данный момент сервис поддерживает только анализ интероральный рентгеновских снимков(прикусных и периапикальных) и панорам. ',
};
