// collections
export const USER_PERMISSION__DELETE_COLLECTION = 'delete_collection'; // delete collection. If false - hide this part in menu
export const USER_PERMISSION__CHANGE_COLLECTION = 'change_collection'; // edit collection data
export const USER_PERMISSION__ADD_COLLECTION = 'add_collection'; // create new collection
export const USER_PERMISSION__COPY_COLLECTION = 'copy_collection';
export const USER_PERMISSION__VIEW_COLLECTION = 'view_collection';
export const USER_PERMISSION__SHARE_COLLECTION = 'share_collection';
export const USER_PERMISSION__LIST_COLLECTIONS = 'list_collections';

// images
export const USER_PERMISSION__CHANGE_IMAGE = 'change_image'; // edit image data, if false - don't allow to edit it
export const USER_PERMISSION__VIEW_IMAGE = 'view_image'; // view image data. if false - hide data from image data row
export const USER_PERMISSION__ADD_IMAGE = 'add_image'; // upload image. If false - hide all image upload buttons
export const USER_PERMISSION__SHARE_IMAGE = 'share_image'; // share_image button
export const USER_PERMISSION__DELETE_IMAGE = 'delete_image'; // show delete image button
export const USER_PERMISSION__DOWNLOAD_IMAGE = 'download_image'; // download source image
export const USER_PERMISSION__UPLOAD_IMAGE = 'upload_image'; // upload image
export const USER_PERMISSION__FLIP_IMAGE = 'flip_image'; // flip images
export const USER_PERMISSION__ROTATE_IMAGE = 'rotate_image'; // rotate images
export const USER_PERMISSION__SHOW_GRID = 'show_grid'; // show grid for X-RAY images
export const USER_PERMISSION__IS_EXPORT_ALLOWED = 'is_export_allowed'; // show treatment plan export button
export const USER_PERMISSION__SHOW_BONE_LEVELS = 'is_show_bone_levels';

// other
export const USER_PERMISSION__VIEW_REPORTS = 'view_reports'; // show report button
export const USER_PERMISSION__USE_ANALYSIS = 'use_analysis'; // show analysis button
export const USER_PERMISSION__VIEW_OVERALL_STATS = 'view_overall_stats'; // show analysis button

export const USER_PERMISSION__ADD_TREATMENTPLAN = 'add_treatmentplan';
export const USER_PERMISSION__CHANGE_TREATMENTPLAN = 'change_treatmentplan';
export const USER_PERMISSION__DELETE_TREATMENTPLAN = 'delete_treatmentplan';

export const USER_PERMISSION__IS_FMX_VIEW_ENABLED = 'is_fmx_view_enabled';

export const USER_PERMISSION__IS_SHOW_EXISTING_CHART = 'is_show_existing_chart';
export const USER_PERMISSION__IS_SHOW_BONE_LEVELS = 'is_show_bone_levels';
export const USER_PERMISSION__IS_SHOW_NON_PATHOLOGICAL = 'is_show_non_pathological';

export const USER_PERMISSION__SKIP_CONFIRMATION_POPUP = 'skip_confirmation_popup';
