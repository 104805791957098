import cookiesUtils from '../../appUtils/cookiesUtils';


/**
 * @return {boolean}
 */
const isLocalStorageSupported = () => window.hasOwnProperty('localStorage') === true &&
	typeof window.localStorage === 'object';


export default class Storage {
	/**
	 * @type {Object|null}
	 * @private
	 */
	_data = null;

	/**
	 * @type {string|null}
	 * @private
	 */
	_storageKey = null;

	/**
	 * @param {string} key
	 */
	constructor (key) {
		this._storageKey = key;
		try {
			let data = isLocalStorageSupported() === true
				? window.localStorage.getItem(key)
				: cookiesUtils.read(key);

			if ( typeof data === 'string' ) {
				data = JSON.parse(data);
			}
			else if ( typeof data === 'undefined' || data === null ) {
				data = {};
			}
			this._data = data;
		}
		catch (error) {
			// do nothing
		}
	}

	/**
	 * @param {string} key
	 * @param {*} value
	 */
	set (key, value) {
		this._data[key] = value;
		try {
			if ( isLocalStorageSupported() === true ) {
				this._setInLocalStorage();
			}
			else {
				this._setInCookie();
			}
		}
		catch (error) {
			// do nothing
		}
	}

	/**
	 * @param {string} key
	 * @param {*} [defaultValue=undefined]
	 */
	get (key, defaultValue = undefined) {
		return this._data.hasOwnProperty(key) ? this._data[key] : defaultValue;
	}

	/**
	 * @param {string} key
	 */
	remove (key) {
		delete this._data[key];
		try {
			if ( isLocalStorageSupported() === true ) {
				this._setInLocalStorage();
			}
			else {
				this._setInCookie();
			}
		}
		catch (error) {
			// do nothing
		}
	}

	cleanAll (partialKey) {
		if ( typeof partialKey === 'string' ) {
			partialKey = partialKey.toLowerCase();
			this._data = Object.keys(this._data).reduce((result, key) => {
				if ( key.toLowerCase().includes(partialKey) === true ) {
					return result;
				}

				result[key] = this._data[key];
				return result;
			}, {});
		}
		else {
			this._data = {};
		}

		try {
			if ( isLocalStorageSupported() === true ) {
				this._setInLocalStorage();
			}
			else {
				this._setInCookie();
			}
		}
		catch (error) {
			// do nothing
		}
	}

	_setInLocalStorage () {
		window.localStorage.setItem(this._storageKey, JSON.stringify(this._data));
	}

	_setInCookie () {
		cookiesUtils.write(this._storageKey, JSON.stringify(this._data), Date.now() + (86400000 * 365), '/', undefined, true, 'None');
	}
}
