import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import {
	AVAILABLE_TYPES,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__XRAY,
} from '../constants/imageConstants';

import {
	LABELS_STAGE_AI_AIDED,
	LABELS_STAGE_COMPLETED,
	LABELS_STAGE_UNAIDED,
} from '../constants/labelsStageConstants';

import { getStore } from '../services/store';
import { getRuntimeConfig } from './runtimeConfig';

import apiUtils from './apiUtils';
import commonUtils from './common';
import dateUtils from './dateUtils';

import { tryToHandleApiError } from './errorUtils';
import { tagIsConfidence } from '../modules/label-tags/utils';

import imagesHistoryActions from '../actions/imagesHistoryActions';
import labelsActions from '../modules/labels/actions/labelsActions';
import imagesActions from '../actions/imagesActions';

import editorSelectors from '../selectors/editorSelectors';
import imagesLabelsSelectors from '../modules/labels/selectors/imagesLabelsSelectors';
import labelTagsSelectors from '../modules/label-tags/selectors/labelTagsSelectors';
import userSelectors from '../selectors/userSelectors';
import imagesSelectors from '../selectors/imagesSelectors';

import PopupDialog from '../components/PopupDialog';
import Button from '../components/Button/Button';


import { getDictionary } from './locale';


const i18n = getDictionary('editor');

const FILTERED_CLASSES_STORAGE_KEY = 'dentiai__filtered_classes';
const SHOW_ALL_FINDINGS_STORAGE_KEY = 'dentiai__show-all-findings';


/**
 * @param {File} image
 * @param {string[]} extensions
 * @param {string[]} types
 * @return {boolean}
 */
function checkImageExtension (image, extensions, types) {
	if ( typeof image.type === 'string' && image.type.length > 0 ) {
		return types.some((mimeType) => mimeType === image.type);
	}

	const fileNameParts = image.name.match(/\.\w+/g);

	if ( Array.isArray(fileNameParts) === false ) {
		return false;
	}

	const ext = fileNameParts.pop().substr(1);

	return extensions.some((extension) => extension === ext);
}

/**
 * @param {Object} options
 * @param {number} options.canvasWidth
 * @param {number} options.canvasHeight
 * @param {number} options.imageWidth
 * @param {number} options.imageHeight
 * @return {number}
 */
function getDefaultZoom (options) {
	const {
		canvasWidth,
		canvasHeight,
		imageWidth,
		imageHeight,
	} = options;

	if ( imageWidth > canvasWidth || imageHeight > canvasHeight ) {
		return Math.min(( canvasWidth / imageWidth ), ( canvasHeight / imageHeight ));
	}

	return 1;
}

function getGeneralType (image) {
	const imageType = image.image_type;
	if ( AVAILABLE_TYPES.includes(imageType) ) {
		return imageType;
	}

	throw new Error(`Unsupported image type: "${imageType}"`)
}

function showPopupMessage (title, icon, content, closeText, showConfirmButton) {
	return new Promise(function (resolve, reject) {
		const node = window.document.createElement('DIV');
		window.document.body.appendChild(node);

		ReactDOM.render(
			(
				<PopupDialog
					headerProps={{
						title: title,
						icon: icon
					}}
					content={content}
					footerProps={{
						buttons: ({ popup: { close } }) => {
							return [
								(
									showConfirmButton && <Button
										key={'popup_confirm'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={() => {
											close();
											resolve();
										}}
									>Confirm</Button>
								),(
									<Button
										key={'popup_cancel'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={() => {
											close();
											reject();
										}}
									>{closeText}</Button>
								),
							];
						},
					}}
					popupProps={{
						onClose: () => {
							ReactDOM.unmountComponentAtNode(node);
							window.document.body.removeChild(node);
						},
					}}
				/>
			),
			node
		);
	});

}

function showLabelsError () {
	return showPopupMessage(
		'Annotation error',
		'error',
		<Fragment>
			Some of the findings do not show a confidence tag or show multiple tags.
			<br/>Please add one confidence tag to each of the findings.
		</Fragment>,
		'Ok',
		false
	);
}

function showUncompletedError (dispatch, getState) {
	return showPopupMessage(
		'Annotation error',
		'error',
		<Fragment>
			Please complete both un-aided and computer-aided evaluations before moving to the next image.
			<br/>You can switch to computer-aided evaluation by clicking on the "Enable AI Predictions" button.
		</Fragment>,
		'Ok',
		false
	);
}

async function showFirstStateConfirmation (callback) {
	return showPopupMessage(
		'Annotation confirmation',
		null,
		<Fragment>
			Please confirm that un-aided evaluation has been completed.
			<br/>You can NOT change un-aided annotations after confirmation.
		</Fragment>,
		'Cancel',
		true
	);
}


function showSecondStateConfirmation () {
	return showPopupMessage(
		'Annotation confirmation',
		null,
		<Fragment>
			Please confirm computer-aided evaluation has been completed.
			<br/>You can NOT change annotations after confirmation.
		</Fragment>,
		'Cancel',
		true
	);
}

async function validateLabelsBeforeLeave (dispatch, getState, skipUnaidedLeaveCheck) {
	const state = getState();
	const imageId = editorSelectors.selectCurrentImageId(state);
	const labelsStage = editorSelectors.selectLabelsStage(state);
	const editorData = editorSelectors.selectEditor(state);
	let labelHaveNotConfidenceTag = false;

	if (!imageId) {
		return;
	}

	const currentImage = imagesSelectors.selectImageById(state, {
		id: imageId,
	});

	const isAnalyzeAvailable = (
		currentImage.isOwn  &&
		(
			currentImage.image_type === IMAGE_TYPE__PAN ||
			currentImage.image_type === IMAGE_TYPE__XRAY ||
			currentImage.image_type === IMAGE_TYPE__BITEWING ||
			currentImage.image_type === IMAGE_TYPE__PERIAPICAL
		)
	);

	imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId,
	}).forEach((labelId) => {
		if ( labelHaveNotConfidenceTag === true ) {
			return;
		}

		const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
		if ( tags.length > 0 ) {
			const matches = tags.filter((tag) => tagIsConfidence(tag.key));
			labelHaveNotConfidenceTag = (matches.length !== 1);
		}
		else {
			labelHaveNotConfidenceTag = true;
		}
	});

	if ( labelHaveNotConfidenceTag === true ) {
		await showLabelsError();
	}

	if (labelsStage === LABELS_STAGE_UNAIDED && isAnalyzeAvailable && !skipUnaidedLeaveCheck) {
		await showUncompletedError(dispatch, getState);
	} else if (labelsStage === LABELS_STAGE_AI_AIDED) {
		await showSecondStateConfirmation();

		//todo ask to save unsaved changes
		if (editorData.hasUnsavedChanges) {
			await dispatch(labelsActions.saveLabels());
		}

		await dispatch(imagesActions.completeFDAStage({
			id: imageId,
		}))
	}
}

function shouldWeShowWarningOnLeavingImage () {
	const state = getStore().getState();
	const imageId = editorSelectors.selectCurrentImageId(state);
	const editorData = editorSelectors.selectEditor(state);

	if ( editorData.hasUnsavedChanges === true ) {
		return true;
	}

	if ( userSelectors.selectIsFDAAnnotationEnabled(state) === true ) {
		const labelsIds = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId,
		});
		let labelNotHaveSingleTag = false;

		for (let i = 0; i < labelsIds.length; i++) {
			const labelId = labelsIds[i];
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
			if ( !tags || tags.length !== 1) {
				labelNotHaveSingleTag = true;
				break;
			}
		}

		return (
			labelNotHaveSingleTag === true ||
			editorSelectors.selectLabelsStage(state) !== LABELS_STAGE_COMPLETED
		);
	}

	return false;
}

async function checkHasUnsavedChanged (callback, skipAllFDAChecks = false, skipUnaidedLeaveCheck = false) {
	const { dispatch, getState } = getStore();
	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(getState());
	if (isFDAAnnotationEnabled && !skipAllFDAChecks) {
		await validateLabelsBeforeLeave(dispatch, getState, skipUnaidedLeaveCheck);
	}

	const editorData = editorSelectors.selectEditor(getState());

	if ( editorData.hasUnsavedChanges ) {
		const node = window.document.createElement('DIV');
		window.document.body.appendChild(node);

		ReactDOM.render(
			(
				<PopupDialog
					headerProps={{
						title: i18n('unsaved_changes.title'),
					}}
					content={i18n('unsaved_changes.confirm')}
					footerProps={{
						buttons: ({ popup: { close } }) => {
							return [
								(
									<Button
										key={'save'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={() => {
											dispatch(labelsActions.saveLabels())
												.then(close)
												.then(callback)
												.catch(error => {
													tryToHandleApiError(error);
												});
										}}
									>{i18n('unsaved_changes.buttons.save')}</Button>
								),
								(
									<Button
										key={'dont_save'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={() => {
											imagesHistoryActions.stopSaving();
											close();
											callback();
										}}
									>{i18n('unsaved_changes.buttons.dont_save')}</Button>
								),
								(
									<Button
										key={'popup_cancel'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={close}
									>{i18n('unsaved_changes.buttons.cancel')}</Button>
								),
							];
						},
					}}
					popupProps={{
						onClose: () => {
							ReactDOM.unmountComponentAtNode(node);
							window.document.body.removeChild(node);
						},
					}}
				/>
			),
			node
		);

		return;
	}

	callback();
}

function getFilteredClassesFromStorage () {
	try {
		const value = window.localStorage.getItem(FILTERED_CLASSES_STORAGE_KEY);
		if ( value ) {
			return JSON.parse(value);
		}
	}
	catch (error) {
		// do nothing
	}

	return null;
}

function setFilteredClassesToStorage (value) {
	try {
		window.localStorage.setItem(FILTERED_CLASSES_STORAGE_KEY, JSON.stringify(value));
	}
	catch (error) {
		// do nothing
	}
}

function getShowAllFindingsFromStorage () {
	try {
		const value = window.localStorage.getItem(SHOW_ALL_FINDINGS_STORAGE_KEY);
		if ( value ) {
			return JSON.parse(value);
		}
	}
	catch (error) {
		// do nothing
	}

	return null;
}

function setShowAllFindingsToStorage (value) {
	try {
		window.localStorage.setItem(SHOW_ALL_FINDINGS_STORAGE_KEY, JSON.stringify(value));
	}
	catch (error) {
		// do nothing
	}
}

function isSequentialModeEnabledForVisit ({ currentImage }) {

}

/**
 * Encodes a BMP image with alpha channel.
 *
 * @param {number} width
 * @param {number} height
 * @param {Uint8ClampedArray} imageData
 * @return {DataView}
 */
function encodeBMP (width, height, imageData) {
	const littleEndian = true;
	const bitmapFileHeaderLength = 14;
	const DIBHeaderLength = 108;
	const data = new DataView(new ArrayBuffer(bitmapFileHeaderLength + DIBHeaderLength + imageData.length));
	let pointer = 0;

	// File Type Data
	data.setUint8(pointer++, 0x42);
	data.setUint8(pointer++, 0x4D);
	data.setUint32(pointer, data.length, littleEndian);
	pointer += 4;
	data.setUint16(pointer, 0x00, littleEndian);
	pointer += 2;
	data.setUint16(pointer, 0x00, littleEndian);
	pointer += 2;
	data.setUint32(bitmapFileHeaderLength + DIBHeaderLength, littleEndian);
	pointer += 4;

	// DIB Header
	data.setUint32(pointer, DIBHeaderLength, littleEndian);
	pointer += 4;
	data.setInt32(pointer, width, littleEndian);
	pointer += 4;
	data.setInt32(pointer, height, littleEndian);
	pointer += 4;
	data.setUint16(pointer, 0x01, littleEndian);
	pointer += 2;
	data.setUint16(pointer, 0x20, littleEndian);
	// data.setUint16(pointer, 0x18, littleEndian);
	pointer += 2;
	data.setUint32(pointer, 0x03, littleEndian);
	pointer += 4;
	data.setUint32(pointer, imageData.length, littleEndian);
	pointer += 4;
	data.setUint8(pointer++, 0x13);
	data.setUint8(pointer++, 0x0b);
	data.setUint8(pointer++, 0x00);
	data.setUint8(pointer++, 0x00);
	data.setUint8(pointer++, 0x13);
	data.setUint8(pointer++, 0x0b);
	data.setUint8(pointer++, 0x00);
	data.setUint8(pointer++, 0x00);
	data.setUint32(pointer, 0x00, littleEndian);
	pointer += 4;
	data.setUint32(pointer, 0x00, littleEndian);
	pointer += 4;
	data.setInt32 (pointer, 0x00FF0000, littleEndian); pointer += 4;
	data.setInt32 (pointer, 0x0000FF00, littleEndian); pointer += 4;
	data.setInt32 (pointer, 0x000000FF, littleEndian); pointer += 4;
	data.setInt32 (pointer, 0xFF000000, littleEndian); pointer += 4;

	data.setUint8(pointer++, 0x20);
	data.setUint8(pointer++, 0x6E);
	data.setUint8(pointer++, 0x69);
	data.setUint8(pointer++, 0x57);

	for (let i = 0; i < 48; i++) {
		data.setUint8(pointer++, 0);
	}

	for (let y = 0; y < height; y++) {
		let position = (height - 1 - y) * 4 * width;

		for (let x = 0; x < width; x++) {
			// BGRA
			data.setUint8(pointer++, imageData[position + 2]);
			data.setUint8(pointer++, imageData[position + 1]);
			data.setUint8(pointer++, imageData[position]);
			data.setUint8(pointer++, imageData[position + 3]);

			position += 4;
		}
	}

	return data;
}

/**
 * @param shape
 * @param getColor
 */
function getHeatmapUrl (shape, getColor) {
	const width = typeof shape.matrix === 'string' ? shape.height : shape.matrix[0].length;
	const height = typeof shape.matrix === 'string' ? shape.width : shape.matrix.length ;
	let imageData = null;
	if ( typeof shape.matrix === 'string' ) {
		imageData = new Uint8ClampedArray(width * height * 4);

		const matrixAsOneRow = commonUtils.utfToBinary(shape.matrix);

		for (let i = 0; i < matrixAsOneRow.length; i++) {
			const pos = i * 4;
			const [ r, g, b, a ] = getColor({ matrixValue: matrixAsOneRow[i] });
			imageData[pos] = r;
			imageData[pos + 1] = g;
			imageData[pos + 2] = b;
			imageData[pos + 3] = a;
		}
	}
	else {
		imageData = new Uint8ClampedArray(width * height * 4);

		for(let y = 0; y < shape.matrix.length; y++) {
			for(let x = 0; x < shape.matrix[y].length; x++) {
				const pos = (y * width + x) * 4;
				const matrixValue = shape.matrix[y][x];
				const [ r, g, b, a ] = getColor({ matrixValue });
				imageData[pos] = r;
				imageData[pos + 1] = g;
				imageData[pos + 2] = b;
				imageData[pos + 3] = a;
			}
		}
	}

	const blob = new Blob([ encodeBMP(width, height, imageData) ], { type: 'image/bmp' });
	return URL.createObjectURL(blob);
}

/**
 *
 * @param {CollectionImage} image
 * @param {number} [age=21]
 * @return {boolean}
 */
function isImagePatientOlder (image, age = 21) {
	return (
		typeof image.birthday === 'undefined' ||
		image.birthday === null ||
		dateUtils.getAge(image.birthday) >= age
	);
}

function getExportLink ({ findingsFilter, editorData, currentImage, currentCollectionId, isAutoChartShortFilter }) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls['export_link']);
	const findingsFilterClasses = Object.keys(findingsFilter).reduce((result, key) => {
		if ( true === findingsFilter[key] ) {
			result.push(key);
		}

		return result;
	}, []);
	const exportClasses = findingsFilterClasses.length > 0
		? findingsFilterClasses.map((_class) => `&export_classes=${_class}`).join('')
		: '';
	let url = apiParams
		.url.replace('{collectionHashName}', currentCollectionId)
		.replace('{imageHashName}', currentImage.hashname)
		.replace('{plan_format}', editorData.imagePlanFormat)
		.replace('{threshold}', editorData.filteredConfidencePercent)
		.replace('{export_classes}', exportClasses);

	if ( isAutoChartShortFilter ) {
		url += '&export_classes=treatment&export_classes=watch&export_classes=treatment_plan';
	}

	const userApiParams = commonUtils.getUserApiParamsForUrl();
	if ( userApiParams !== null ) {
		url += '&' + userApiParams;
	}

	return url;
}

export default {
	checkImageExtension,
	getDefaultZoom,
	getGeneralType,
	checkHasUnsavedChanged,
	shouldWeShowWarningOnLeavingImage,
	getFilteredClassesFromStorage,
	setFilteredClassesToStorage,
	getShowAllFindingsFromStorage,
	setShowAllFindingsToStorage,
	isSequentialModeEnabledForVisit,
	showFirstStateConfirmation,
	encodeBMP,
	getHeatmapUrl,
	isImagePatientOlder,
	getExportLink,
};
