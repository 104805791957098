import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import when from 'when';

import { closeFeedback, runInactiveTimer } from '../../../../services/feedback';
import { getExaminationByImageHashName } from '../../utils';
import { APP_IMAGE_MODE } from '../../../../constants/imageConstants';
import { setAppImageMode } from '../../../../services/app';
import urlCache from '../../../../services/url-cache';

import imageApi from '../../../../api/imageApi';
import imagesActions from '../../../../actions/imagesActions';
import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';

import Alignment from '../../../../components/Alignment';
import Loading from '../../../../components/Loading';
import Popup from '../../../../components/Popup';
import Button from '../../../../components/Button';

import { getDictionary } from '../../../../appUtils/locale';

import './styles/ResolverImage.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'resolver-image';
const loadingErrorCssClassName = `${baseCssClassName}__loading-error`;

export const LOADING_STATUS = {
	IDLE: 'idle',
	PROGRESS: 'progress',
	LOADED: 'loaded',
	FAILED: 'failed',
};

class ResolverImageHoc extends PureComponent {
	static propTypes = {
		isSyncedCollection: PropTypes.bool.isRequired,
		imageIdFromParams: PropTypes.string,
		currentImageId: PropTypes.string,
		currentImage: PropTypes.object,
		currentCollectionId: PropTypes.string,
		setCurrentImage: PropTypes.func.isRequired,
		setCurrentExamination: PropTypes.func.isRequired,
		setFeedbackStatus: PropTypes.func.isRequired,
	};
	
	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	static LOADING_STATUS = LOADING_STATUS;
	
	constructor (props, context) {
		super(props, context);
		
		this._store = context.store;
		this._router = context.router;
		this._storeUnsubscribeCallback = null;
		
		this._prevHashName = null;
		this._imagePrepared = false;
		this._feedbackActiveTimerDestroy = null;
		
		this._currentImageHashName = null;
		
		this.state = {
			loadingStatus: LOADING_STATUS.IDLE,
		};
	}
	
	componentDidMount () {
		setAppImageMode(APP_IMAGE_MODE.TREATMENT_PLAN);
		this._storeUnsubscribeCallback = this._store.subscribe(this._handleStoreChange);
		console.log(1);
		this._checkImage(this.props);
	}
	
	componentWillUnmount () {
		this.state = null;
		
		if ( this._storeUnsubscribeCallback ) {
			this._storeUnsubscribeCallback();
			this._storeUnsubscribeCallback = null;
		}

		this.props.setCurrentImage(null);
	}
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		this._checkImage(nextProps);
	}
	
	_loadImage (image, imageHashName) {
		this.setState({
			loadingStatus: LOADING_STATUS.PROGRESS,
		});

		if ( this._feedbackActiveTimerDestroy ) {
			this._feedbackActiveTimerDestroy();
			this._feedbackActiveTimerDestroy = null;
		}

		when.all([
			this._store.dispatch(imagesActions.loadImage({
				collectionHashName: this.props.currentCollectionId,
				imageHashName,
				probeCache: (getState) => {
					if ( !this.props.currentImage ) {
						return;
					}

					const image = imagesSelectors.selectImageByHashName(getState(), { hashname: imageHashName });

					return (
						image &&
						typeof this.props.currentImage.examination === 'string' &&
						this.props.currentImage.examination.length > 0 &&
						this.props.currentImage.examination === image.examination
					);
				},
			})),
			imageApi.getFeedback(this.props.currentCollectionId, imageHashName)
		])
			.then(([ image, feedback ]) => {
				if ( !this.state ) {
					return;
				}

				urlCache.cleanAll();
				
				this.props.setCurrentImage(image.id);
				if ( feedback && 'boolean' === typeof feedback.is_positive ) {
					closeFeedback();
					this.props.setFeedbackStatus(false);
				}
				else {
					this.props.setFeedbackStatus(true);
					this._feedbackActiveTimerDestroy = runInactiveTimer();
				}

				const examination = getExaminationByImageHashName({ imageHashName });
				
				if ( null !== examination ) {
					this.props.setCurrentExamination(examination);
				}
				
				this.setState({
					loadingStatus: LOADING_STATUS.LOADED,
				});
			})
			.catch((error) => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					loadingStatus: LOADING_STATUS.FAILED,
				});
			});
		
		this._currentImageHashName = imageHashName;
	}
	
	_checkImage (props) {
		const {
			currentImageId,
			currentImage,
			imageIdFromParams,
		} = props;

		if ( currentImageId && !currentImage ) {
			this._router.history.replace(`/collections/${this.props.currentCollectionId}/treatment_plan`);
			return;
		}
		
		const imageHash = imageIdFromParams;
		
		if ( this._prevHashName !== imageHash ) {
			this._imagePrepared = false;
		}
		
		if ( imageHash && this._currentImageHashName !== imageHash && !this._imagePrepared ) {
			const image = imagesSelectors.selectImageByHashNameInCollection(this._store.getState(), {
				collectionHashName: this.props.currentCollectionId,
				imageHashName: imageHash,
			});
			
			this._prevHashName = imageHash;
			this._imagePrepared = true;
			
			if ( image ) {
				closeFeedback();
				this._loadImage(image, imageHash)
			}
			else {
				this._router.history.replace(`/collections/${this.props.currentCollectionId}/treatment_plan`);
			}
		}
		else {
			this._currentImageHashName = null;
		}
	}
	
	_handleStoreChange = () => {
		if ( !this._storeUnsubscribeCallback ) {
			return;
		}
		
		this.forceUpdate();
	};

	_handleTryAgain = () => {
		const {
			imageIdFromParams,
		} = this.props;

		const imageHash = imageIdFromParams;

		const image = imagesSelectors.selectImageByHashNameInCollection(this._store.getState(), {
			collectionHashName: this.props.currentCollectionId,
			imageHashName: imageHash,
		});

		this._loadImage(image, imageHash)
	};

	_renderError () {
		return (
			<div className={loadingErrorCssClassName}>
				<h2
					dangerouslySetInnerHTML={{
						__html: i18nShared('messages.loading_error'),
					}}
				/>
				<div>
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						dangerouslySetInnerHTML={{
							__html: i18nShared('buttons.try_again'),
						}}
						onClick={this._handleTryAgain}
					/>
				</div>
			</div>
		);
	}
	
	render () {
		const {
			currentImageId,
			currentImage,
		} = this.props;

		const api = {
			loadingStatus: this.state.loadingStatus,
		};
		
		if ( this.state.loadingStatus === LOADING_STATUS.PROGRESS ) {
			const content = (
				<Alignment
					horizontal={Alignment.horizontal.CENTER}
					vertical={Alignment.vertical.CENTER}
				>
					<Loading />
				</Alignment>
			);

			if ( true === this.props.isSyncedCollection && currentImage ) {
				return (
					<Fragment>
						{this.props.children(api)}
						<Popup>{content}</Popup>
					</Fragment>
				);
			}

			return content;
		}

		if ( this.state.loadingStatus === LOADING_STATUS.FAILED ) {
			if ( true === this.props.isSyncedCollection && currentImage ) {
				return (
					<Fragment>
						{this.props.children(api)}
						<Popup>{this._renderError()}</Popup>
					</Fragment>
				);
			}
			else {
				return this._renderError();
			}
		}
		
		if ( !currentImageId || !currentImage ) {
			return null;
		}
		
		return this.props.children(api);
	}
}

export default withRouter(connect((state, props) => {
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	
	return {
		isSyncedCollection: collectionsSelectors.selectIsSyncedCollection(state, { id: currentCollectionId }),
		imageIdFromParams: props.match.params.imageId,
		currentImageId,
		currentCollectionId,
		currentImage: imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}),
	};
}, (dispatch) => ({
	setCurrentImage: (id) => {
		if ( id !== null ) {
			return dispatch(editorActions.setCurrentImage({ id }));
		}

		return dispatch(editorActions.unsetCurrentImage());
	},
	setCurrentExamination: (currentExamination) => dispatch(editorActions.updateData({ data: { currentExamination } })),
	setFeedbackStatus: (status) => dispatch(editorActions.updateData({
		data: {
			shouldShowFeedback: status,
		},
	})),
}))(ResolverImageHoc));
