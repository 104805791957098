import React from 'react';
import FormCustomSelect from '../FormCustomSelect';

import { getDictionary } from '../../appUtils/locale';

import './styles/ToothSelector.css';


const i18n = getDictionary('editor');

const baseCssClassName = 'editor-tooth-selector';
const headerCssClassName = `${baseCssClassName}__header`;
const itemCssClassName = `${baseCssClassName}__item`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const selectCssNameClass = `${baseCssClassName}__select`;

function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if ( items[i].value === value ) {
			return items[i];
		}
	}

	return null;
}

function ToothSelector (props) {
	return (
		<FormCustomSelect
			items={props.items}
			value={props.value}
			disabled={props.disabled}
			className={selectCssNameClass}
			onRenderHeader={({
				isOpened,
				indicatorElement,
			}) => {
				const selectedItem = ( props.value && props.items
					? getItem(props.value, props.items) || {}
					: {}
				);

				return (
					<div className={headerCssClassName}>
						<span>{ selectedItem.label || i18n('select_tooth.placeholder') }</span>
						<div className={indicatorCssClassName}>{indicatorElement}</div>
					</div>
				);
			}}
			onRenderItem={(item) => (
				<div className={itemCssClassName}>
					<span>{item.label}</span>
				</div>
			)}
			onChange={props.onChange}
		/>
	);
}

export default ToothSelector;
