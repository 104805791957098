import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { trackEvent } from '../../integrations/mixpanel';

import PopupDialog from '../PopupDialog';

import Button from '../Button';
import FormCheckbox from '../FormCheckbox';
import FormLabel from '../FormLabel/FormLabel';

import { getDictionary } from '../../appUtils/locale';

import Loading from '../Loading';

import statsApi from '../../api/statsApi';
import collectionsActions from '../../actions/collectionsActions';

import './styles/CopyCollectionForm.css';


const i18n = getDictionary('collections');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'copy-collection-form';
const statusCssClassName = `${baseCssClassName}__status`;


class CopyCollectionForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			users: [],
			collectionName: props.name,
			collectionDescription: props.description,
			withAnnotations: false,
			randomize: false,
			isUsersLoading: false,
			hasUsersLoadingError: false,
			isSaving: false,
			hasSavingError: false,
		};
	}

	componentDidMount () {
		this._loadUsers();
	}

	componentWillUnmount () {
		this.state = null;
	}

	async _loadUsers () {
		this.setState({
			isUsersLoading: true,
			hasUsersLoadingError: false,
		});

		try {
			const users = await(statsApi.getUsers());

			if ( !this.state ) {
				return;
			}

			this.setState({
				isUsersLoading: false,
				users,
				user: users[0],
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isUsersLoading: false,
				hasUsersLoadingError: true,
			});
		}
	}

	async _copyCollection () {
		this.setState({
			isSaving: true,
			hasSavingError: false,
		});

		try {
			await(this.props.onCopyCollection({
				hashName: this.props.hashname,
				data: {
					username: this.state.user,
					collection_name: this.state.collectionName,
					description: this.state.collectionDescription,
					with_annotations: this.state.withAnnotations,
					randomize_images: this.state.randomize,
				},
			}));

			trackEvent('Collection copied');

			if ( !this.state ) {
				return;
			}

			this.setState({
				isSaving: false,
			});

			this.props.onClose();
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isSaving: false,
				hasSavingError: true,
			});
		}
	}

	_handleDataChanged = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	_handleCopy = () => {
		this._copyCollection();
	}

	_handleTryAgain = () => {
		if ( this.state.hasUsersLoadingError ) {
			this._loadUsers();
		}
		else if ( this.state.hasSavingError ) {
			this._copyCollection();
		}
	}

	_handleWithAnnotationsChange = (event) => {
		this.setState({
			withAnnotations: event.target.checked,
		});
	}

	_handleRandomizeChange = (event) => {
		this.setState({
			randomize: event.target.checked,
		});
	}

	_renderContent () {
		if ( this.state.isUsersLoading || this.state.isSaving ) {
			return (
				<div className={statusCssClassName}><Loading /></div>
			);
		}
		else if ( this.state.hasUsersLoadingError || this.state.hasSavingError ) {
			return (
				<div>{i18nShared('messages.error.with_retry')}</div>
			);
		}

		return (
			<Fragment>
				{ this.state.users.length > 1 && (
					<TextField
						required
						name={'user'}
						select
						label={i18n('copy.labels.user')}
						value={this.state.user}
						SelectProps={{
							native: true,
						}}
						margin={''}
						fullWidth
						onChange={this._handleDataChanged}
					>
						{this.state.users.map((user) => (
							<option style={{color: 'black'}} key={user} value={user}>
								{user}
							</option>
						))}
					</TextField>
				) }
				<TextField
					required
					InputLabelProps={{
						shrink: true,
					}}
					name={'collectionName'}
					value={this.state.collectionName}
					label={i18n('copy.labels.collection_name')}
					margin={'dense'}
					fullWidth
					onChange={this._handleDataChanged}
				/>
				<TextField
					InputLabelProps={{
						shrink: true,
					}}
					name={'collectionDescription'}
					value={this.state.collectionDescription}
					label={i18n('copy.labels.collection_description')}
					margin={'dense'}
					fullWidth
					onChange={this._handleDataChanged}
				/>
				<FormLabel
					element={(
						<FormCheckbox
							inputProps={{
								checked: this.state.withAnnotations,
								onChange: this._handleWithAnnotationsChange,
							}}
						/>
					)}
					text={i18n('copy.labels.with_annotations')}
					textPosition={FormLabel.textPosition.RIGHT}
					disableSpacing
				/>
				<FormLabel
					element={(
						<FormCheckbox
							inputProps={{
								checked: this.state.randomize,
								onChange: this._handleRandomizeChange,
							}}
						/>
					)}
					text={i18n('copy.labels.randomize')}
					textPosition={FormLabel.textPosition.RIGHT}
					disableSpacing
				/>

			</Fragment>
		);
	}

	_renderButtons = ({ popup: { close } }) => {
		const buttons = [];

		if (
			!this.state.isUsersLoading &&
			!this.state.hasUsersLoadingError &&
			!this.state.isSaving &&
			!this.state.hasSavingError
		) {
			buttons.push(
				<Button
					key={'copy'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					onClick={this._handleCopy}
				>{i18n('copy.buttons.copy')}</Button>
			);
		}

		if ( this.state.hasUsersLoadingError || this.state.hasSavingError ) {
			buttons.push(
				<Button
					key={'try_again'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					onClick={this._handleTryAgain}
				>{i18nShared('buttons.try_again')}</Button>
			);
		}

		buttons.push(
			<Button
				theme={Button.AVAILABLE_THEMES.SECONDARY}
				size={Button.AVAILABLE_SIZES.LARGE}
				onClick={close}
			>{i18nShared('buttons.close')}</Button>
		);

		return buttons;
	}

	render() {
		return (
			<PopupDialog
				headerProps={{
					title: i18n('copy.title'),
				}}
				content={this._renderContent()}
				footerProps={{
					buttons: this._renderButtons,
				}}
				popupProps={{
					overlay: {
						enabled: true,
						closeOnClick: false,
					},
					onClose: () => {
						this.props.onClose();
					},
				}}
			/>
		);
	}
}

export default connect(
	(state, props) => {}, (dispatch) => ({
		onCopyCollection: (data) => dispatch(collectionsActions.copyCollection(data)),
	})
)(CopyCollectionForm)

