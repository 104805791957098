export default class ApiError extends Error {
  constructor(message) {
    super(message);

    this.name = 'ApiError';

    // https://medium.com/@xpl/javascript-deriving-from-error-properly-8d2f8f315801
    this.constructor = ApiError;
    this.__proto__ = ApiError.prototype;
  }
}

ApiError.prototype = Error.prototype;