import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


import ResolverSurfaceEditor from './ResolverSurfaceEditor';


const baseCssClassName = 'resolver-surface-editor-locator';
const globalContainerCssClassName = `${baseCssClassName}-global-container`;


export default class ResolverSurfaceEditorLocator extends Component {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		target: PropTypes.object.isRequired,
		values: PropTypes.arrayOf(PropTypes.string.isRequired),
		
		onChange: PropTypes.func,
		onClose: PropTypes.func,
	}
	
	constructor (props, context) {
		super(props, context);

		// Reference on the current global container.
		this._globalContainer = null;
		this._hoveredContainer = false;
	}
	
	componentDidUpdate () {
		this._updatePosition();
	}
	
	componentDidMount () {
		window.document.body.addEventListener('click', this._handleDocumentClick);
		window.addEventListener('scroll', this._handleScroll, true);
		
		this._updatePosition();
	}
	
	componentWillUnmount () {
		window.document.body.removeEventListener('click', this._handleDocumentClick);
		window.removeEventListener('scroll', this._handleScroll, true);
		
		if ( this._globalContainer ) {
			this._globalContainer.removeEventListener('mouseover', this._handleMouseOver);
			this._globalContainer.removeEventListener('mouseout', this._handleMouseOut);
			document.body.removeChild(this._globalContainer);
			
			this._globalContainer = null;
		}
	}
	
	_updatePosition () {
		if ( !this._globalContainer || !this.props.target ) {
			return;
		}

		const targetBox = this.props.target.getBoundingClientRect();
		const viewportRect = window.document.body.getBoundingClientRect();
		
		const targetCenterX = window.scrollX + (targetBox.left + (targetBox.right - targetBox.left) / 2);
		// const targetCenterY = window.scrollY + (targetBox.top + (targetBox.bottom - targetBox.top) / 2);
		const targetCenterY = window.scrollY + targetBox.top + 12;

		this._globalContainer.style.left = `${targetBox.left}px`;
		this._globalContainer.style.top = `${targetCenterY - (this._globalContainer.offsetHeight / 2)}px`;
		
		const globalContainerBox = this._globalContainer.getBoundingClientRect();
		
		const globalContainerTop = globalContainerBox.top;
		const globalContainerBottom = globalContainerTop + (globalContainerBox.bottom - globalContainerBox.top);

		if ( globalContainerBottom > window.scrollY + viewportRect.bottom ){
			this._globalContainer.style.top = `${parseInt(this._globalContainer.style.top, 10) - (globalContainerBottom - (window.scrollY + viewportRect.bottom))}px`;
		}
		
		if ( globalContainerTop < 0 ){
			this._globalContainer.style.top = `${parseInt(this._globalContainer.style.top, 10) + Math.abs(globalContainerTop)}px`;
		}
		
		this._globalContainer.style.opacity = 1;
	}
	
	_handleDocumentClick = () => {
		if ( this._hoveredContainer ) {
			return;
		}
		this.props.onClose();
	}
	
	_handleScroll = () => {
		this.props.onClose();
	}
	
	_handleMouseOver = () => {
		this._hoveredContainer = true;
	}
	
	_handleMouseOut = () => {
		this._hoveredContainer = false;
	}

	render () {
		const {
			target,
			...rest
		} = this.props;
		
		if ( !this._globalContainer ) {
			this._globalContainer = document.createElement('DIV');
			this._globalContainer.className = globalContainerCssClassName;
			this._globalContainer.addEventListener('mouseover', this._handleMouseOver);
			this._globalContainer.addEventListener('mouseout', this._handleMouseOut);
			document.body.appendChild(this._globalContainer);
		}
		
		return ReactDOM.createPortal(
			<ResolverSurfaceEditor {...rest} />,
			this._globalContainer
		);
	}
}
