import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';

import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import PrevHistoryStateButton from './PrevHistoryStateButton';
import NextHistoryStateButton from './NextHistoryStateButton';

import FirstImageButton from './FirstImageButton';
import LastImageButton from './LastImageButton';

import PrevImageButton from './PrevImageButton';
import NextImageButton from './NextImageButton';

import { DrawSecondaryBoxMode } from '../DisableLayout';

import { getDictionary } from '../../appUtils/locale';

import './styles/CanvasControls.css';
import imageUtils from "../../appUtils/imageUtils";
import FdaCompleteButton from "./FdaCompleteButton";


const i18n = getDictionary('canvas-controls');

const baseCssClassName = 'canvas-controls';
const imagePositionCssClassName = `${baseCssClassName}__image-position`;
const imagesNavigationCssClassName = `${baseCssClassName}__images-navigation`;
const controlsCssClassName = `${baseCssClassName}__controls`;
const historyCssClassName = `${baseCssClassName}__history`;
const historyButtonCssClassName = `${baseCssClassName}__history-button`;
const imagePositionInputCssClassName = `${baseCssClassName}__image-position-input`;


class CanvasControls extends Component {
	static propTypes = {
		imagesCount: PropTypes.number.isRequired,
		currentImagePosition: PropTypes.number.isRequired,
		shouldShowFirstButton: PropTypes.bool,
		shouldShowLastButton: PropTypes.bool,
	};

	static defaultProps = {
		shouldShowFirstButton: true,
		shouldShowLastButton: true,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		this.state = {
			imagePosition: props.currentImagePosition,
		};
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if ( nextProps.currentImagePosition !== this.props.currentImagePosition ) {
			this.setState({
				imagePosition: nextProps.currentImagePosition,
			});
		}
	}

	_goToImage () {
		const imagePosition = Number(this.state.imagePosition);

		if ( this._validateImagePosition(imagePosition) ) {
			const image = this.props.onGetImageByIndex(imagePosition);
			if ( image && this.props.currentImagePosition !== imagePosition) {
				imageUtils.checkHasUnsavedChanged(() => {
					const collectionId = this.context.router.route.match.params.id;
					this.context.router.history.replace(`/collections/${collectionId}/image/${image.hashname}`, { imageId: image.hashname });
				});
			}
		}
	}

	_validateImagePosition (imagePosition) {
		return (
			/^[0-9]+$/.test(imagePosition) === true &&
			!isNaN(imagePosition) &&
			imagePosition > 0 &&
			imagePosition <= this.props.imagesCount
		);
	}

	_handleImagePositionChange = (event) => {
		this.setState({
			imagePosition: event.target.value,
		});
	}

	_handleImagePositionKeyPress = (event) => {
		if ( event.key.toLowerCase() === 'enter' ) {
			this._goToImage();
		}
	}

	_handleImagePositionBlur = () => {
		this._goToImage();
	}

	render () {
		const {
			imagesCount
		} = this.props;

		const {
			imagePosition,
		} = this.state;

		return (
			<div className={baseCssClassName}>
				<div className={imagesNavigationCssClassName}>
					{this.props.shouldShowFirstButton === true && (
						<div className={controlsCssClassName}>
							<FirstImageButton />
						</div>
					)}
					<div className={controlsCssClassName}>
						<PrevImageButton />
					</div>
					<div className={imagePositionCssClassName}>
						<Input
							error={!this._validateImagePosition(imagePosition)}
							value={imagePosition}
							style={{ fontSize: 12, width: 25 }}
							inputProps={{
								style: {
									textAlign: 'right',
								},
							}}
							onChange={this._handleImagePositionChange}
							onBlur={this._handleImagePositionBlur}
							onKeyPress={this._handleImagePositionKeyPress}
						/> of {imagesCount}
					</div>
					<div className={controlsCssClassName}>
						<NextImageButton />
					</div>
					{this.props.shouldShowLastButton === true && (
						<div className={controlsCssClassName}>
							<LastImageButton />
						</div>
					)}
					<div className={controlsCssClassName}>
						<FdaCompleteButton/>
					</div>
				</div>

				<div className={historyCssClassName}>
					<div className={historyButtonCssClassName}>
						<PrevHistoryStateButton />
					</div>
					<div className={historyButtonCssClassName}>
						<NextHistoryStateButton />
					</div>
				</div>
				<DrawSecondaryBoxMode />
			</div>
		);
	}
}

export default connect((state) => {
	const images = imagesCollectionsSelectors.selectImagesIdsByCollectionId(state, {
		collectionId: editorSelectors.selectEditor(state).currentCollectionHashName,
	});

	return {
		imagesCount: images.length,
		currentImagePosition: images.indexOf(editorSelectors.selectCurrentImageId(state)) + 1,

		onGetImageByIndex: (imagePosition) => {
			return imagesCollectionsSelectors.selectImageInCollectionByIndex(state, { index: (imagePosition - 1) });
		},
	};
})(CanvasControls);
