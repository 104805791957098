import React from 'react';
import PropTypes from 'prop-types';
import DiagnosisSelect from '../DiagnosisSelect';

import { getDictionary } from '../../appUtils/locale';

import './styles/AddDiagnosisSelectBlock.css';

// @todo move to separate file
const i18n = getDictionary('tooth-info');

const baseCssClassName = 'add-diagnosis';
const addDiagnosisSelectCssClassName = `${baseCssClassName}__select`;
const addDiagnosisSelectHeaderClassName = `${baseCssClassName}__select-header`;
const wrapperCssClassName = `${baseCssClassName}__wrapper`;
const itemsCssClassName = `${baseCssClassName}__select-items`;


export default class AddDiagnosisSelectBlock extends React.Component {
	static propTypes = {
		availableLabels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		onSelectLabel: PropTypes.func.isRequired,
	};

	_getLabels = () => {
		return this.props.availableLabels.map((labelData) => ({
			value: labelData.classId,
			label: labelData.localizedClassName,
			hotKey: labelData.hotKey,
			requiredLabel: labelData.requiredLabel,
		}));
	}

	render() {
		return (
			<div>
				<div className={wrapperCssClassName}>
					<DiagnosisSelect
						className={addDiagnosisSelectCssClassName}
						headerClassName={addDiagnosisSelectHeaderClassName}
						itemsCssClassName={itemsCssClassName}
						emptyValue={i18n('select_finding')}
						value={this.props.label}
						items={this._getLabels()}
						onChange={this.props.onSelectLabel}
					/>
				</div>
			</div>
		)
	}
}
