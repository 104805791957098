import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonUtils from '../../appUtils/common';

import userSelectors from '../../selectors/userSelectors';

import Report from './Report';

import { getDictionary } from '../../appUtils/locale';

import collectionsActions from '../../actions/collectionsActions';
import collectionsSelectors from '../../selectors/collectionsSelectors';


const i18nShared = getDictionary('shared');


class ReportContainer extends Component {
	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,

		onGoToLandingPage: PropTypes.func.isRequired,
	}

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._isShared = !this.context.router.route.match.params.collectionId;

		this.state = {
			isSynced: false,
			isSyncing: false,
		};
	}

	UNSAFE_componentWillMount () {
		if ( !this._isShared ) {
			this._loadCollection();
		}
		else {
			this.setState({
				isSynced: true,
			});
		}
	}

	componentWillUnmount () {
		this.state = null;
	}

	_loadCollection () {
		const hashName = this.props.match.params.collectionId;

		if ( this.props.onIsSyncedCollection(hashName) ) {
			this.setState({
				isSynced: true,
			});
			this.props.onSetCurrentCollection({ collection: hashName });
			return;
		}

		this.setState({
			isSyncing: true,
		});

		this.props.onGetCollection({ hashName })
			.then(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSynced: true,
				});
			})
			.finally(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSyncing: false,
				});
			});
	}

	render () {
		if ( !this.state.isSynced ) {
			return (<div>{i18nShared('messages.wait')}</div>);
		}

		return (
			<Report
				imageHashName={this.props.match.params.imageId}
				collectionHashName={!this._isShared ? this.props.match.params.collectionId : null}
				isShared={this._isShared}
			/>
		);
	}
}

export default connect((state) => {
	return {
		isAuthenticated: userSelectors.selectIsAuthenticated(state),
		onIsSyncedCollection: (id) => collectionsSelectors.selectIsSyncedCollection(state, { id }) ,
	}
}, (dispatch) => ({
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
	onGetCollection: (data) => dispatch(collectionsActions.getCollection(data)),
	onSetCurrentCollection: (data) => dispatch(collectionsActions.setCurrentCollection(data)),
}))(ReportContainer);
