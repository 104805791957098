import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import { ResolverNavigation } from '../../../resolver/components/navigation';


export default withRouter(connect(
	(state, { history }) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});

		return {
			collection: collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			}),
			image: currentImage,
			shouldDisplayExaminations: null !== editorData.currentExamination,
			shouldShowThumbnailsGallery: false ,
			currentExamination: editorData.currentExamination,
			availableExaminations: currentCollectionSelectors.selectAvailableExaminations(state)
				.sort((a, b) => a.localeCompare(b, undefined, { numeric: true })),
			onGetLastOpenedExaminationImage: (examination) => {
				const images = currentCollectionSelectors.selectExaminationImages(state, { examination });
				images.sort((a, b) => b.last_opened_at - a.last_opened_at);
				return images[0];
			},
			onChangeVisit: ({ collection, examination }) => {
				history.push(`/collections/${collection.hashname}/visit/${examination}/tfv/${editorData.selectedToothKey}`);
			},
		};
	},
	() => ({})
)(ResolverNavigation));
