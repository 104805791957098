import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import imageUtils from '../../appUtils/imageUtils';
import { tryToHandleApiError } from '../../appUtils/errorUtils';
import labelsActions from '../../modules/labels/actions/labelsActions';

import imagesSelectors from '../../selectors/imagesSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from "../../selectors/userSelectors";

import ImageShare from '../ImageShare';
import UploadButton from './UploadButtonConnector';

import { getDictionary } from '../../appUtils/locale';
import { requestFeedback, closeFeedback } from '../../services/feedback';
import aclService from '../../services/acl';

import {
	USER_PERMISSION__UPLOAD_IMAGE,
	USER_PERMISSION__SHARE_IMAGE,
	USER_PERMISSION__CHANGE_TREATMENTPLAN,
	USER_PERMISSION__LIST_COLLECTIONS,
} from '../../constants/userPermissionsConstants';

import './styles/ImageControls.css';


const i18n = getDictionary('image-controls');

const baseCssClassName = 'image-controls';
const buttonCssClassName = `${baseCssClassName}__button`;


class ImageControls extends Component {
	static propTypes = {
		hasUnsavedChanges: PropTypes.bool.isRequired,
		currentImage: PropTypes.object,

		onSaveData: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	state = {
		showShareModal: false,
	};

	_handleShareImageButtonClick = () => {
		this.setState({
			showShareModal: true,
		});
	};

	_handleCloseImageShare = () => {
		this.setState({
			showShareModal: false,
		});
	};

	_handleCollectionButtonClick = () => {
		const store = this.context.store;

		imageUtils.checkHasUnsavedChanged(() => {
			requestFeedback({
				onReady: () => {
					closeFeedback();
					this.context.router.history.push('/');
				},
			});
		}, true);
	};

	_handleSaveData = () => {
		this.props.onSaveData()
			.catch(error => {
				tryToHandleApiError(error);
		  });
	};

	render () {
		const {
			hasUnsavedChanges,
			currentImage,
			isRegistryAvailable,
		} = this.props;

		const registryLinkPath = currentImage ? `${this.context.router.route.location.pathname}/registry` : '';
		const treatmentPlanLinkPath = currentImage ? `${this.context.router.route.location.pathname}/treatment_plan` : '';

		return (
			<div className={baseCssClassName}>
				{ currentImage && currentImage.isOwn && aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
					<button
						className={`${buttonCssClassName} ${buttonCssClassName}__m-collections`}
						title={i18n('buttons.collections')}
						onClick={this._handleCollectionButtonClick}
					/>
				) }
				{aclService.checkPermission(USER_PERMISSION__UPLOAD_IMAGE) && <UploadButton />}
				{isRegistryAvailable && <Link to={{pathname: registryLinkPath, state: { modal: true }}}>
					<button
						className={`${buttonCssClassName} ${buttonCssClassName}__m-open`}
						title={i18n('buttons.open_registry')}
					/>
				</Link>}
				{ currentImage && currentImage.isOwn && (
					<React.Fragment>
						<button
							className={`${buttonCssClassName} ${buttonCssClassName}__m-save`}
							title={i18n('buttons.save_changes')}
							disabled={!hasUnsavedChanges}
							onClick={this._handleSaveData}
						/>
						{
							aclService.checkPermission(USER_PERMISSION__SHARE_IMAGE) &&
							<button
								className={`${buttonCssClassName} ${buttonCssClassName}__m-share`}
								title={i18n('buttons.share_image')}
								onClick={this._handleShareImageButtonClick}
							/>
						}
						{
							this.state.showShareModal &&
							<ImageShare onClose={this._handleCloseImageShare} />
						}
					</React.Fragment>
				) }
				{aclService.checkPermission(USER_PERMISSION__CHANGE_TREATMENTPLAN) && (
					<button
						onClick={() => window.location.href = treatmentPlanLinkPath}
						className={`${buttonCssClassName} ${buttonCssClassName}__m-treatment-plan`}
						title={'Treatment plan'}
					/>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	hasUnsavedChanges: editorSelectors.selectEditor(state).hasUnsavedChanges,
	currentImage: imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	}),
	isRegistryAvailable: (
		userSelectors.selectUsesReaderStudyModeUi(state) !== true &&
		userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
	),
});

export default connect(mapStateToProps, (dispatch) => ({
	onSaveData: () => dispatch(labelsActions.saveLabels()),
}))(ImageControls);
