/**
 * @param {StoreState} state
 * @return {AnalysisStoreState}
 */
const selectAnalysis = (state) => state.analysis;

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionImageId} options.imageId
 * @return {boolean}
 */
const selectIsImageAnalyzed = (state, { imageId }) => selectAnalysis(state).analyzedImages.hasOwnProperty(imageId);

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionImageId} options.imageId
 * @return {boolean}
 */
const selectIsImageInAnalysis = (state, { imageId }) => selectAnalysis(state).imagesInAnalysis.hasOwnProperty(imageId);


export default {
	selectIsImageAnalyzed,
	selectIsImageInAnalysis,
};
