const ACTION_IMAGES_COLLECTIONS__SHIFT_IMAGE = 'ACTION_IMAGES_COLLECTIONS__SHIFT_IMAGE';
const ACTION_IMAGES_COLLECTIONS__PUSH_IMAGE = 'ACTION_IMAGES_COLLECTIONS__PUSH_IMAGE';
const ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE = 'ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE';
const ACTION_IMAGES_COLLECTIONS__SET_IMAGES = 'ACTION_IMAGES_COLLECTIONS__SET_IMAGES';


export default {
	ACTION_IMAGES_COLLECTIONS__SHIFT_IMAGE,
	ACTION_IMAGES_COLLECTIONS__PUSH_IMAGE,
	ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE,
	ACTION_IMAGES_COLLECTIONS__SET_IMAGES,
};
