import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import lodashDebounce from 'lodash/debounce';


import float, { appendAnchorPosition, FloatAxis, FloatPosition } from './float';


const noop = () => {};


/**
 * Parses an integer with the specified radix.
 * If parsing results in a NaN, returns the defaultValue.
 *
 * @see parseInt
 *
 * @param {String} value The string to parse.
 * @param {Number} [radix=10] The radix for parsing.
 * @param {Number} [defaultValue=undefined] The default value to return.
 * @return {Number} The parsed number or the default value.
 */
export function parseIntCustom (value, radix, defaultValue) {
	if (typeof radix !== 'number') { radix = 10; }
	value = parseInt(value, radix);
	if (Number.isNaN(value)) {
		value = defaultValue;
	}
	return value;
}

/**
 * Parses a decimal integer.
 * If parsing results in a NaN, returns the defaultValue.
 * The same as parseInt(value, 10, defaultValue)
 *
 * @param {String} value The string to parse.
 * @param {Number} [defaultValue=undefined] The default value to return.
 * @return {Number} The parsed number or the default value.
 */
export function parseDecimal (value, defaultValue) {
	return parseIntCustom(value, 10, defaultValue);
}

function isCursorOverElement (point, element) {
	const rect = element.getBoundingClientRect();

	return (
		point.x >= parseDecimal(rect.left) + window.scrollX - 1 &&
		point.x <= parseDecimal(rect.left) + window.scrollX + (rect.right - rect.left) + 1 &&
		point.y >= parseDecimal(rect.top) + window.scrollY - 1 &&
		point.y <= parseDecimal(rect.top) + window.scrollY + (rect.bottom - rect.top) + 1
	);
}


const InitialPositionPropType = PropTypes.shape({
	mainAxis: PropTypes.oneOf(Object.values(FloatAxis)),
	mainAxisPosition: PropTypes.oneOf(Object.values(FloatPosition)),
	secondaryAxisPosition: PropTypes.oneOf(Object.values(FloatPosition)),
	targetAnchorPosition: PropTypes.oneOf(Object.values(FloatPosition)),
	mainAxisOffset: PropTypes.number,
	secondaryAxisOffset: PropTypes.number,
	targetAnchorOffset: PropTypes.number,
});

const STAGES = {
	TEST: 1,
	COMPLETE: 2,
};

/**
 * @param {FloatPositionDescriptor[]} positions
 * @return {FloatPositionDescriptor}
 */
function defaultSelectionStrategy (positions) {
	let bestPosition = null;
	for (let i = 0; i < positions.length; i++) {
		const position = positions[i];

		if ( position.area === position.adjustedArea ) {
			bestPosition = position;
			break;
		}
	}

	if ( bestPosition === null ) {
		const sortedPositions = [ ...positions ].sort((a, b) => b.adjustedArea - a.adjustedArea);
		bestPosition = sortedPositions[0];
	}

	return bestPosition;
}

export default class UiFloat extends PureComponent {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.node,
			PropTypes.func,
		]).isRequired,
		target: PropTypes.instanceOf(window.HTMLElement).isRequired,
		targetPosition: PropTypes.object, // eslint-disable-line react/forbid-prop-types
		initialPosition: InitialPositionPropType,
		attachTo: PropTypes.instanceOf(window.HTMLElement),
		viewportLocation: PropTypes.oneOfType([
			PropTypes.shape({
				left: PropTypes.number.isRequired,
				top: PropTypes.number.isRequired,
				right: PropTypes.number.isRequired,
				bottom: PropTypes.number.isRequired,
			}),
			PropTypes.func,
		]),
		alternatePositions: PropTypes.arrayOf(InitialPositionPropType.isRequired),
		allowAlternatePositions: PropTypes.bool,
		allowToAdjust: PropTypes.bool,
		zIndex: PropTypes.number,
		selectionStrategy: PropTypes.func,
		onReady: PropTypes.func,
		onClose: PropTypes.func,
	};

	static defaultProps = {
		viewportLocation: null,
		alternatePositions: [],
		allowAlternatePositions: true,
		allowToAdjust: true,
		initialPosition: null,
		targetPosition: null,
		attachTo: window.document.body,
		zIndex: 99,
		selectionStrategy: defaultSelectionStrategy,
		onReady: noop,
		onClose: noop,
	};

	static STAGES = STAGES;

	_containerApi = {
		close: this.props.onClose,
		stage: STAGES.TEST,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_testContainerEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_presentationContainerEl = null;

	/**
	 * Used when props.attachTo changed to prevent wrong detach.
	 *
	 * @type {boolean}
	 * @private
	 */
	_forceDestroyed = false;

	_stage = STAGES.TEST;

	constructor (props) {
		super(props);

		this._debouncedHandleResize = lodashDebounce(this._handleResize, 200);
	}

	componentDidMount () {
		if ( null !== this._testContainerEl ) {
			this._render();
		}

		window.document.body.addEventListener('mouseup', this._handleMouseDown);
		window.addEventListener('resize', this._debouncedHandleResize);
		window.addEventListener('scroll', this._debouncedHandleResize, true);
		window.addEventListener('orientationchange', this._debouncedHandleResize);
	}

	componentWillReceiveProps (nextProps) {
		if ( nextProps.children !== this.props.children ) {
			this._stage = STAGES.TEST;
			this._containerApi.stage = STAGES.TEST;
			this.forceUpdate();
		}
	}

	componentDidUpdate (prevProps) {
		if ( prevProps.attachTo !== this.props.attachTo ) {
			this._forceDestroyed = true;
			this._destroy(prevProps.attachTo);
			this.props.onClose();
		}

		if (
			( this._stage === STAGES.TEST && this._forceDestroyed === false ) ||
			prevProps.target !== this.props.target ||
			prevProps.targetPosition !== this.props.targetPosition
		) {
			this._render();
		}
	}

	componentWillUnmount () {
		if ( false === this._forceDestroyed ) {
			this._destroy(this.props.attachTo);
		}
	}

	/**
	 * @param {HTMLElement|HTMLBodyElement} attachTo
	 * @private
	 */
	_destroy (attachTo) {
		if ( null !== this._testContainerEl ) {
			attachTo.removeChild(this._testContainerEl);
			this._testContainerEl = null;
		}

		if ( null !== this._presentationContainerEl ) {
			attachTo.removeChild(this._presentationContainerEl);
			this._presentationContainerEl = null;
		}

		window.document.body.removeEventListener('mouseup', this._handleMouseDown);
		window.removeEventListener('resize', this._debouncedHandleResize);
		window.removeEventListener('scroll', this._debouncedHandleResize, true);
		window.removeEventListener('orientationchange', this._debouncedHandleResize);
	}

	_createContainer () {
		const containerEl = window.document.createElement('DIV');
		containerEl.style.zIndex = String(this.props.zIndex);
		containerEl.style.position = 'absolute';
		containerEl.style.top = '0';
		containerEl.style.left = '0';

		return containerEl;
	}

	_render () {
		const positions = float({
			target: this.props.target,
			popup: this._testContainerEl,
			targetPosition: this.props.targetPosition,
			initialPosition: this.props.initialPosition,
			viewportLocation: 'function' === typeof this.props.viewportLocation ? this.props.viewportLocation() : this.props.viewportLocation,
			alternatePositions: this.props.alternatePositions,
			allowAlternatePositions: this.props.allowAlternatePositions,
			allowToAdjust: this.props.allowToAdjust,
		});

		const bestPosition = this.props.selectionStrategy(positions);

		appendAnchorPosition(bestPosition);

		this._stage = STAGES.COMPLETE;
		this._containerApi.stage = STAGES.COMPLETE;
		this._containerApi.bestPosition = bestPosition;
		this._containerApi.container = this._presentationContainerEl;
		this._presentationContainerEl.style.transform = `translate(${bestPosition.adjustedX}px,${bestPosition.adjustedY}px)`;
		this.props.onReady(bestPosition);
		this.forceUpdate();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updatePosition () {
		this._render();
	}

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleMouseDown = (event) => {
		if ( this._stage === STAGES.TEST ) {
			return;
		}

		if ( isCursorOverElement({ x: event.clientX, y: event.clientY }, this._presentationContainerEl) ) {
			return;
		}

		this.props.onClose();
	};

	_handleResize = () => {
		if ( null === this._testContainerEl ) {
			return;
		}

		this._stage = STAGES.TEST;
		this._containerApi.stage = STAGES.TEST;
		this.forceUpdate();
	};

	/**
	 * Renders the container in the global context(body) or in the passed element(attachTo).
	 *
	 * @private
	 */
	_renderContainer () {
		if ( true === this._forceDestroyed ) {
			return null;
		}

		if ( null === this._testContainerEl ) {
			this._testContainerEl = this._createContainer();
			this._testContainerEl.style.opacity = '0';
			this.props.attachTo.appendChild(this._testContainerEl);
		}

		if ( null === this._presentationContainerEl ) {
			this._presentationContainerEl = this._createContainer();
			this.props.attachTo.appendChild(this._presentationContainerEl);
		}

		// TODO ui-float по хорошему нужно сделать иначе
		return (
			<>
				{
					ReactDOM.createPortal(
						this._renderContent(),
						this._presentationContainerEl,
					)
				}
				{ this._stage !== STAGES.COMPLETE && (
					ReactDOM.createPortal(
						this._renderContent(),
						this._testContainerEl,
					)
				)}
			</>
		);
	}

	_renderContent () {
		let content = this.props.children;
		if ( 'function' === typeof this.props.children ) {
			content = this.props.children(this._containerApi);
		}

		return content;
	}

	render () {
		return this._renderContainer();
	}
}
