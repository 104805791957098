import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import  { confirm } from '../../../../services/popup';

import Float, { FloatAxis, FloatPosition } from '../../../../components/Float';

import FormCustomSelect from '../../../../components/FormCustomSelect';

import './style/TfvToothAction.css';


const baseCssClassName = 'tfv-tooth-action';
const toothKeyCssClassName = `${baseCssClassName}__tooth-key`;
const arrowCssClassName = `${baseCssClassName}__arrow`;
const menuCssClassName = `${baseCssClassName}__menu`;
const menuItemCssClassName = `${baseCssClassName}__menu-item`;
const removeActionCssClassName = `${baseCssClassName}__remove-action`;
const removeIconCssClassName = `${baseCssClassName}__remove-icon`;


export default class TfvToothAction extends PureComponent {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		localizedToothKey: PropTypes.string.isRequired,
		shiftedToothKey: PropTypes.string.isRequired,
		viewportLocation: PropTypes.shape({
			left: PropTypes.number.isRequired,
			top: PropTypes.number.isRequired,
			right: PropTypes.number.isRequired,
			bottom: PropTypes.number.isRequired,
		}).isRequired,
		imageHashName: PropTypes.string.isRequired,
		availableTeeth: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})),
		allowShifting: PropTypes.bool.isRequired,
		allowRemoving: PropTypes.bool,
		withConflict: PropTypes.bool,
		fontSize: PropTypes.number,
		onShiftTooth: PropTypes.func,
		onRemoveTooth: PropTypes.func,
	};

	static defaultProps = {
		fontSize: 14,
		availableTeeth: [],
		allowRemoving: false,
		withConflict: false,
		onMoveTeeth: () => {},
		onRemoveTooth: () => {},
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_menuEl = null;

	/**
	 * @type {number|null}
	 * @private
	 */
	_hideTimerId = null;

	state = {
		axis: FloatAxis.horizontal,
		pos: FloatPosition.start,
		isActive: false,
	};

	componentWillUnmount () {
		this._clearHideTimer();
		this._unsubscribe();
	}

	_getInitialPosition () {
		return {
			mainAxis: FloatAxis.vertical,
			mainAxisPosition: FloatPosition.end,
			mainAxisOffset: 10,
			secondaryAxisPosition: FloatPosition.start,
			secondaryAxisOffset: 10,
		};
	}

	_subscribe () {
		window.document.body.addEventListener('mousedown', this._handleDocumentClick, false);
		window.document.body.addEventListener('touchstart', this._handleDocumentTouchStart, false);
		window.addEventListener('scroll', this._handleScroll, false);
		window.addEventListener('mousewheel', this._handleScroll, false);
	}

	_unsubscribe () {
		window.document.body.removeEventListener('mousedown', this._handleDocumentClick, false);
		window.document.body.removeEventListener('touchstart', this._handleDocumentTouchStart, false);
		window.removeEventListener('scroll', this._handleScroll, false);
		window.removeEventListener('mousewheel', this._handleScroll, false);
	}

	_close () {
		this._hideTimerId = window.setTimeout(() => {
			this.setState({
				isActive: false,
			});
			this._unsubscribe();
		}, 50);
	}

	_clearHideTimer () {
		if ( this._hideTimerId !== null ) {
			window.clearTimeout(this._hideTimerId);
			this._hideTimerId = null;
		}
	}

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleRef = (element = null) => {
		if ( element === null || element === this._baseEl ) {
			return;
		}

		this._baseEl = element;
	};

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleMenuRef = (element = null) => {
		if ( element === null || element === this._menuEl ) {
			return;
		}

		this._menuEl = element;
	};

	/**
	 * @param {TouchEvent} event
	 * @private
	 */
	_handleDocumentTouchStart = (event) => {
		event.preventDefault();

		this._handleDocumentClick();
	};

	_handleDocumentClick = () => {
		if ( this._hideTimerId !== null ) {
			return;
		}
		this._close();
	};

	_handleScroll = () => {
		if ( this._hideTimerId !== null ) {
			return;
		}
		this._close();
	};

	_handleReady = (position) => {
		this.setState({
			axis: position.mainAxis,
			pos: position.mainAxisPosition,
		});
	};

	_handleSelectChange = (nextToothKey) => {
		this.props.onShiftTooth(this.props.toothKey, nextToothKey, this.props.imageHashName);
	};

	/**
	 * @param {TouchEvent} event
	 * @private
	 */
	_handleTouchStart = (event) => {
		event.preventDefault();

		this._handleClick();
	};

	_handleClick = () => {
		if ( this.state.isActive === true ) {
			this.setState({
				isActive: false,
			});
			return;
		}

		this.setState({
			isActive: true,
		});

		this._subscribe();
	};

	_handleRemoveButton = () => {
		this._clearHideTimer();
		this._unsubscribe();

		confirm({
			message: 'Do you want to remove the tooth from this image?',
			yesHandler: () => {
				this.props.onRemoveTooth({ toothKey: this.props.toothKey, imageHashName: this.props.imageHashName });
				this._close();
			},
			noHandler: () => {
				this._subscribe();
			},
		});
	};

	_renderMenu () {
		return (
			<div
				className={`${menuCssClassName} ${menuCssClassName}__m-axis-${this.state.axis}__m-pos-${this.state.pos}`}
				ref={this._handleMenuRef}
				onScroll={(event) => {
					event.nativeEvent.stopPropagation();
					this._clearHideTimer();
				}}
				onWheel={(event) => {
					event.nativeEvent.stopPropagation();
					this._clearHideTimer();
				}}
			>
				<FormCustomSelect
					items={this.props.availableTeeth}
					value={this.props.shiftedToothKey}
					onChange={this._handleSelectChange}
					initialOpen={true}
				/>
			</div>
		);
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				ref={this._handleRef}
				onTouchStart={(event) => {
					event.preventDefault();
					this._clearHideTimer();
				}}
				onMouseDown={() => {
					this._clearHideTimer();
				}}
			>
				<div
					className={classnames([
						toothKeyCssClassName,
						this.props.withConflict && `${toothKeyCssClassName}__m-with-conflict`,
					])}
					style={{ fontSize: this.props.fontSize }}
					onClick={this._handleClick}
					onTouchStart={this._handleTouchStart}
				>
					{this.props.localizedToothKey}
				</div>
				{this.props.allowRemoving === true && this.state.isActive === true && (
					<Float
						target={this._baseEl}
						initialPosition={{
							mainAxis: FloatAxis.horizontal,
							mainAxisPosition: FloatPosition.end,
							mainAxisOffset: -15,
							targetAnchorPosition: FloatPosition.center,
							secondaryAxisOffset: -8,
						}}
						allowAlternatePositions
						viewportLocation={this.props.viewportLocation}
						onReady={this._handleReady}
					>
						<div
							className={removeActionCssClassName}
							title={'Remove the tooth from this image'}
							onMouseDown={this._handleRemoveButton}
						>
							<div className={removeIconCssClassName} />
						</div>
					</Float>
				)}
				{this.props.allowShifting === true && this.state.isActive === true && (
					<Float
						target={this._baseEl}
						initialPosition={this._getInitialPosition()}
						allowAlternatePositions
						alternatePositions={[
							{
								mainAxis: FloatAxis.vertical,
								mainAxisPosition: FloatPosition.end,
								mainAxisOffset: 0,
							},
						]}
						viewportLocation={this.props.viewportLocation}
						onReady={this._handleReady}
					>
						{this._renderMenu()}
					</Float>
				)}
			</div>
		);
	}
}
