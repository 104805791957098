import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { isMissingTooth } from '../../../resolver/utils';


/**
 * @param {string} toothKey
 * @param {function(string):Label} toothGetter
 */
export const getNonEmptyPreviousToothKey = (toothKey, toothGetter) => {
	const row = teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth());
	const toothIndex = row.indexOf(toothKey);
	const previousKey = toothIndex > 0 ? row[toothIndex - 1] : null;
	if ( toothKey === null ) {
		return null;
	}

	if ( false === isMissingTooth(previousKey) ) {
		const tooth = toothGetter(previousKey);
		if ( tooth && Object.keys(tooth.shapes).length > 0 ) {
			return previousKey;
		}
	}

	return getNonEmptyPreviousToothKey(previousKey, toothGetter);
};

/**
 * @param {string} toothKey
 * @param {function(string):Label} toothGetter
 */
export const getNonEmptyNextToothKey = (toothKey, toothGetter) => {
	const row = teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth());
	const toothIndex = row.indexOf(toothKey);
	const nextKey = toothIndex < row.length - 1 ? row[toothIndex + 1] : null;
	if ( typeof nextKey === 'undefined' || toothKey === null ) {
		return null;
	}

	if ( false === isMissingTooth(nextKey) ) {
		const tooth = toothGetter(nextKey);
		if ( tooth && Object.keys(tooth.shapes).length > 0 ) {
			return nextKey;
		}
	}

	return getNonEmptyNextToothKey(nextKey, toothGetter);
};
