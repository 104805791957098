import lodashGet from 'lodash/get';
import lodashHas from 'lodash/has';
import lodashValues from 'lodash/values';

import mainConfig from '../configs/mainConfig';

import { getRuntimeConfig } from './runtimeConfig';

import cookiesUtils from './cookiesUtils';


const phrases = {};
let currentLocale = mainConfig.DEFAULT_LOCALE;


/**
 * Returns a current locale.
 *
 * @returns {string}
 */
export function locale () {
	return ( currentLocale || mainConfig.DEFAULT_LOCALE );
}

/**
 * Sets a new locale.
 *
 * @param {string} locale
 */
export function setLocale (locale) {
	currentLocale = locale;
}

/**
 * Sets locale to cookies.
 *
 * @param {string} locale
 */
export function setLocaleToCookies (locale) {
	cookiesUtils.write(getRuntimeConfig().locale_cookie_name, locale, Date.now() + ( 86400000 * 365 ), '/', getRuntimeConfig().locale_domain_name);
}

/**
 * Sets locale from cookies.
 */
export function setLocaleFromCookies () {
	const locale = cookiesUtils.read(getRuntimeConfig().locale_cookie_name);
	if (
		locale &&
		typeof locale === 'string' &&
		lodashValues(mainConfig.AVAILABLE_LOCALES).some((data) => data.key === locale)
	) {
		setLocale(locale);
		return;
	}

	const localeFromBrowser = getLocaleFromBrowser();

	if (
		localeFromBrowser &&
		typeof localeFromBrowser === 'string' &&
		lodashValues(mainConfig.AVAILABLE_LOCALES).some((data) => data.key === localeFromBrowser)
	) {
		setLocale(localeFromBrowser);
	}
}

/**
 * Returns a browser locale.
 *
 * @returns {string}
 */
function getLocaleFromBrowser () {
	return navigator.language && typeof navigator.language === 'string'
		? navigator.language.split('-')[0]
		: null;
}

function replacePlaceholders (key, phrase, placeholders) {
	let result = phrase;

	for (const placeholder in placeholders) {
		if ( placeholders.hasOwnProperty(placeholder) ) {
			const needle = `{${placeholder}}`;
			if ( result.indexOf(needle) === -1 ) {
				console.error(`replacePlaceholders: placeholder "${placeholder}" is missing key "${key}"`);
			}

			result = result.replace(new RegExp(needle, 'g'), placeholders[placeholder]);
		}
	}

	return result;
}

/**
 * Adds dictionary for specified language.
 *
 * @param {string} languageKey
 * @param {Object} dictionary
 */
export function addDictionary (languageKey, dictionary) {
	phrases[languageKey] = dictionary;
}

/**
 * Returns dictionary for current language.
 *
 * @param {string} category
 * @returns {function(string):string}
 */
export function getDictionary (category) {
	function tr (key, placeholders = null) {
		const value = (
			lodashGet(phrases, `${locale()}.${category}["${key}"]`, null) ||
			lodashGet(phrases, `${mainConfig.FALLBACK_LOCALE}.${category}["${key}"]`, null)
		);

		if ( value === null ) {
			console.error(`getDictionary: key "${key}" in category "${category}" not found.`);
			return `${category}.${key}`;
		}

		return !placeholders ? value : replacePlaceholders(key, value, placeholders);
	}

	tr.hasTranslation = (key) => {
		return (
			lodashHas(phrases, `${locale()}.${category}["${key}"]`) ||
			lodashHas(phrases, `${mainConfig.FALLBACK_LOCALE}.${category}["${key}"]`, null)
		);
	};

	return tr;
}
