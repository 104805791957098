/**
 * @param {Label} label
 * @return {LabelId}
 */
const getLabelId = (label) => label.labelId;

/**
 * @param {Label} label
 * @return {LabelClassId}
 */
const getLabelClassId = (label) => label.class_id;

/**
 * @param {Label} label
 * @return {Array}
 */
const getLabelRelations = (label) => (label.relations || []);

/**
 * @param {Label} label
 * @return {LabelShape}
 */
const getLabelShape = (label) => (label.shape || {});

/**
 * @param {Label} label
 * @return {LabelMeta}
 */
const getLabelMeta = (label) => (label.meta || {});

/**
 * @param {Label} label
 * @return {boolean}
 */
const getLabelIsConfirmed = (label) => (true === getLabelMeta(label).confirmed);

/**
 * @param {Label} label
 * @return {number}
 */
const getLabelMeasureOfConfidence = (label) => getLabelMeta(label).confidence_percent;

/**
 * @param {Label} label
 * @return {*}
 */
const getLabelSource = (label) => label.source;

/**
 * @param {Label} label
 * @return {string[]}
 */
const getLabelSurfaces = (label) => (label.surfaces || []);

/**
 * @param {Label} label
 * @return {LabelParam[]}
 */
const getLabelParams = (label) => (label.params || []);

/**
 * @param {Label} label
 * @return {string}
 */
const getLabelDate = (label) => label.date;

/**
 * @param {Label} label
 * @return {string[]}
 */
const getLabelConflicts = (label) => (label.conflicts || []);

/**
 * @param {Label} label
 * @return {string[]}
 */
const getLabelWarnings = (label) => (label.warnings || []);

/**
 * @param {Label} label
 * @return {boolean}
 */
const getLabelIsWatch = (label) => Boolean(label.is_watch);

/**
 * @param {Label} label
 * @return {string[]}
 */
const getLabelProcedureCodes = (label) => (label.procedure_codes || []);

export default {
	getLabelId,
	getLabelClassId,
	getLabelRelations,
	getLabelShape,
	getLabelMeta,
	getLabelIsConfirmed,
	getLabelMeasureOfConfidence,
	getLabelSource,
	getLabelSurfaces,
	getLabelParams,
	getLabelDate,
	getLabelConflicts,
	getLabelWarnings,
	getLabelIsWatch,
	getLabelProcedureCodes,
};
