import momentUtils from '../../appUtils/momentUtils';


export function getMinDate ({ years = 120, timezone } = {}) {
	return momentUtils.getDateFromTimestamp({
		timestamp: momentUtils.getNowMoment({ timezone }).startOf('day').subtract(Math.abs(years), 'years').valueOf(),
		timezone,
	});
}

export function getMaxDate ({ years, timezone } = {}) {
	if ( !years ) {
		return momentUtils.getTodayDate({ timezone });
	}

	return momentUtils.getDateFromTimestamp({
		timestamp: momentUtils.getNowMoment({ timezone }).startOf('day').add(Math.abs(years), 'years').valueOf(),
		timezone,
	});
}
