import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';

import constants from './OverlayConstants';
import utils from './OverlayUtils';

import './styles/Overlay.css';


/**
 * @typedef {Object} OverlayProps
 *
 * @property {ReactElement} [children]
 * @property {string} [theme=dark]
 * @property {function} [onClick]
 * @property {boolean} [scrollable=true]
 * @property {OverlayScrollProps} [scrollableProps]
 */

/**
 * @typedef {Object} OverlayScrollProps
 *
 * @property {boolean} [horizontalEnabled=true]
 * @property {boolean} [verticalEnabled=true]
 * @property {boolean} [fullscreen=false]
 */


/**
 * Component can be used to prevent to interact with any interface elements on the page
 * focusing attention of a user on the children component.
 */
class Overlay extends PureComponent {
	static propTypes = {
		children: PropTypes.node,
		theme: PropTypes.oneOf(lodashValues(constants.THEMES)),
		onClick: PropTypes.func,
		scrollable: PropTypes.bool,
		
		absolute: PropTypes.bool,
		zIndex: PropTypes.number,
		
		scrollProps: PropTypes.shape({
			horizontalEnabled: PropTypes.bool,
			verticalEnabled: PropTypes.bool,
			fullscreen: PropTypes.bool,
		}),
	}
	
	static defaultProps = {
		theme: constants.DEFAULT_THEME,
		scrollable: true,
		
		scrollProps: constants.DEFAULT_SCROLL_PROPS,
	}
	
	static themes = constants.THEMES;
	
	/**
	 * @param {OverlayProps} props
	 * @param {Object} context
	 */
	constructor (props, context) {
		super(props, context);
		
		this._wasMouseDownInsideOverlay = false;
	}
	
	_handleMouseDown = (event) => {
		const target = event.currentTarget;
		const verticalScrollWidth = target.offsetWidth - target.clientWidth;
		const horizontalScrollHeight = target.offsetHeight - target.clientHeight;
		
		if ( (
				verticalScrollWidth > 0 &&
				event.clientX >= ( target.offsetWidth - verticalScrollWidth )
			
			) ||
			
			(
				horizontalScrollHeight > 0 &&
				event.clientY >= ( target.offsetHeight - horizontalScrollHeight )
			)
		) {
			return;
		}
		
		this._wasMouseDownInsideOverlay = true;
	}
	
	_handleMouseUp = () => {
		const {
			onClick,
		} = this.props;
		
		if ( this._wasMouseDownInsideOverlay ) {
			if ( onClick ) {
				onClick();
			}
			this._wasMouseDownInsideOverlay = false;
		}
	}
	
	_handleMouseOver = () => {
		this._wasMouseDownInsideOverlay = false;
	}
	
	render () {
		return (
			<div
				className={utils.buildBaseClassName(this.props)}
				style={{
					zIndex: this.props.zIndex,
					...( this.props.scrollable ? utils.buildScrollStyle(this.props.scrollProps) : {} ),
				}}
				onMouseDown={this._handleMouseDown}
				onMouseUp={this._handleMouseUp}
				onMouseOver={this._handleMouseOver}
			>
				{this.props.children}
			</div>
		);
	}
}

Overlay.themes = constants.THEMES;

export default Overlay;
