import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import imageFiltersService from '../../services/image-filters';

import { sharpenImage } from '../../appUtils/filtersUtils';

import './styles/MagnifyingGlassImage.css';


const baseCssClassName = 'magnifying-glass-image';

const noop = () => {};

export default class UiAttachedImage extends Component {
	static propTypes = {
		url: PropTypes.string.isRequired,
		width: PropTypes.number,
		height: PropTypes.number,
		brightness: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		contrast: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		invert: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		sharpen: PropTypes.bool,
		preset: PropTypes.string,
		singleImage: PropTypes.bool,
		onStatusChanged: PropTypes.func,
	};

	static defaultProps = {
		width: null,
		height: null,
		brightness: 1,
		contrast: 1,
		invert: 0,
		sharpen: false,
		singleImage: false,
		onStatusChanged: noop,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	/**
	 * @type {Object<string, Image>}
	 * @private
	 */
	_images = {};

	/**
	 * @type {string|null}
	 * @private
	 */
	_currentImageName = null;

	/**
	 * @type {string|null} blob://
	 * @private
	 */
	_presetUrl = null;

	/**
	 * @type {string|null} data://
	 * @private
	 */
	_sharpenUrl = null;

	componentDidUpdate (prevProps) {
		const shouldUpdate = [ 'width', 'height', 'brightness', 'contrast', 'invert', 'sharpen', 'preset' ]
			.some((prop) => prevProps[prop] !== this.props[prop]);

		if ( shouldUpdate === true ) {
			this._setActualImage();
		}

		if ( prevProps.preset !== this.props.preset ) {
			if ( this.props.preset === null ) {
				this._presetUrl = null;
				this._setActualImage();
			}
			else {
				this._getImageWithPresetUrl();
			}
		}
	}

	componentWillUnmount () {
		this._detachImage(this._images[this._currentImageName]);
		this._images = null;
	}

	/**
	 * @param {HTMLElement} [element=null]
	 * @private
	 */
	_handleRef = (element = null) => {
		this._baseEl = element;

		if ( element !== null ) {
			this._images.main = this.props.image;
			this._images.sharpen = new Image();
			this._images.preset = this._images.sharpen;

			if ( this.props.preset !== null ) {
				this._getImageWithPresetUrl();
			}

			this._setActualImage();
		}
	}

	/**
	 * @param {HTMLImageElement} image
	 * @private
	 */
	_attachImage (image) {
		this._baseEl.appendChild(image);
	}

	/**
	 * @param {HTMLImageElement} image
	 * @private
	 */
	_detachImage (image) {
		this._baseEl.removeChild(image);
	}

	/**
	 * @param {HTMLImageElement} image
	 * @private
	 */
	_copyBaseStyles (image) {
		const style = {
			filter: `brightness(${this.props.brightness}) contrast(${this.props.contrast}) invert(${this.props.invert})`,
		};

		if ( this.props.width !== null ) {
			style.width = typeof this.props.width === 'number'
				? `${this.props.width}px`
				: this.props.width;
		}

		if ( this.props.height !== null ) {
			style.height = typeof this.props.height === 'number'
				? `${this.props.height}px`
				: this.props.height;
		}

		Object.keys(style).forEach((styleName) => {
			image.style[styleName] = style[styleName];
		});
	}

	/**
	 * @return {string} blob://
	 * @private
	 */
	_getSharpenImageUrl () {
		if ( this._sharpenUrl !== null ) {
			return this._sharpenUrl;
		}

		this._sharpenUrl = sharpenImage(this._images.main);

		return this._sharpenUrl;
	}

	_getImageWithPresetUrl () {
		// imageFiltersService.applyPreset(this._images.main, this.props.preset)
		// 	.then((presetUrl) => {
		// 		this._presetUrl = presetUrl;
		// 		this._setActualImage();
		// 	});
	}

	_setActualImage () {
		if ( this.props.sharpen === true && typeof this.props.preset !== 'string' ) {
			if ( this._currentImageName === 'sharpen' ) {
				this._copyBaseStyles(this._images.sharpen);
			}
			else {
				this._copyBaseStyles(this._images.sharpen);
				this._images.sharpen.src = this._getSharpenImageUrl();
				this._attachImage(this._images.sharpen);

				if ( this._currentImageName !== null ) {
					this._detachImage(this._images[this._currentImageName]);
				}

				this._currentImageName = 'sharpen';
			}
			return;
		}
		else if ( typeof this.props.preset === 'string' && this._presetUrl !== null ) {
			if ( this._currentImageName === 'preset' ) {
				this._copyBaseStyles(this._images.preset);
				this._images.preset.src = this._presetUrl;
			}
			else {
				this._copyBaseStyles(this._images.preset);
				this._images.preset.src = this._presetUrl;
				this._attachImage(this._images.preset);

				if ( this._currentImageName !== null ) {
					this._detachImage(this._images[this._currentImageName]);
				}

				this._currentImageName = 'preset';
			}
			return;
		}

		if ( this._currentImageName === 'main' ) {
			this._copyBaseStyles(this._images.main);
		}
		else {
			this._copyBaseStyles(this._images.main);
			this._attachImage(this._images.main);

			if ( this._currentImageName !== null ) {
				this._detachImage(this._images[this._currentImageName]);
			}

			this._currentImageName = 'main';
		}
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				ref={this._handleRef}
			/>
		);
	}
}
