import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
	UNIT_PIXEL,
	parseSize,
} from '../../appUtils/cssUtils';
import mainConfig from '../../configs/mainConfig';


class GridContainer extends Component {
	static propTypes = {
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		minWidth: PropTypes.number.isRequired,
		columns: PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.string.isRequired,
			width: PropTypes.oneOfType([
				PropTypes.number.isRequired,
				PropTypes.string.isRequired,
			]).isRequired,
		})).isRequired,
		data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		dataType: PropTypes.string,
		getHeaderRowRenderOptions: PropTypes.func.isRequired,
		getContentRowRenderOptions: PropTypes.func.isRequired,
		getCategoryRenderOptions: PropTypes.func,
		onScrolledToEnd: PropTypes.func,
		onCustomRender: PropTypes.func,
	}

	constructor (props) {
		super(props);

		this._wrapperEl = null;

		this.state = this._getState(props);
	}

	UNSAFE_componentWillReceiveProps (propsNext) {
		this.setState(this._getState(propsNext));
	}

	_handleRef = (el) => {
		if ( !el ) {
			return;
		}

		this._wrapperEl = el;

		this.setState(this._getState(this.props));
	}

	_parseColumnWidth = ({ width }) => {
		return parseSize(width);
	}

	_getRowWidth (minWidth, width) {
		return Math.max(minWidth, width);
	}

	_measureColumns (columns, rowWidth) {
		const columnsWidthInfo = columns.map(this._parseColumnWidth);

		const fixedColumnsWidth = columnsWidthInfo
			.filter(({ unit }) => (unit === UNIT_PIXEL))
			.reduce((result, { value }) => (result + value), 0);

		const remainingWidth = Math.max(0, rowWidth - fixedColumnsWidth);

		return columns.map((column, index) => {
			const { value, unit } = columnsWidthInfo[index];

			if ( unit === UNIT_PIXEL ) {
				return column;
			}

			return {
				...column,
				width: remainingWidth * value / 100,
			};
		});
	}

	_getState ({
		width,
		minWidth,
		columns,
		getHeaderRowRenderOptions,
		getContentRowRenderOptions,
		getCategoryRenderOptions,
		data,
		dataType,
	}) {
		if ( !this._wrapperEl ) {
			return {
				headerRows: [],
				rows: [],
			};
		}

		const rowWidth = this._getRowWidth(minWidth, width);
		const measuredColumns = this._measureColumns(columns, rowWidth);
		const result = {
			headerRows: getHeaderRowRenderOptions().render(measuredColumns),
		};

		switch (dataType) {
			case 'rows':
				result.rows = data.map((rowData, index) => {
					return getContentRowRenderOptions(rowData, index).render(measuredColumns);
				});
				break;

			case 'categories':
				result.rows = data.map((categoryData, index) => {
					return getCategoryRenderOptions(categoryData, index).render(categoryData.rows.map((rowData, index) => {
						return getContentRowRenderOptions(rowData, index).render(measuredColumns);
					}));
				});
				break;
		}

		return result;
	}

	render () {
		const {
			headerRows,
			rows,
		} = this.state;

		const content = this.props.onCustomRender
			? this.props.onCustomRender({ headerRows, rows })
			: (
				<Fragment>
					{headerRows}
					{rows}
				</Fragment>
			);
		
		return (
			<div
				ref={this._handleRef}
				className={mainConfig.MAIN_SCROLL_CSS_CLASS_NAME}
				style={{
					overflowX: 'auto',
					overflowY: 'auto',
					width: '100%',
					height: '100%',
				}}
			>
				{content}
			</div>
		);
	}
}

export default GridContainer;
