import React, { Component } from 'react';
import PropTypes from 'prop-types';


const LOADING_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	DONE: 'done',
	FAILED: 'failed',
};


export default class TfvImageLoader extends Component {
	static propTypes = {
		image: PropTypes.object.isRequired,
		
		onLoadImage: PropTypes.func.isRequired,
	};
	
	static LOADING_STATUS = LOADING_STATUS;

	state = {
		loadingStatus: LOADING_STATUS.IDLE,
	};

	UNSAFE_componentWillReceiveProps (nextProps) {
		if ( nextProps.image.hashname !== this.props.image.hashname ) {
			this._loadImage(nextProps.image);
		}
	}

	componentDidMount () {
		this._loadImage(this.props.image);
	}

	componentWillUnmount () {
		this.state = null;
	}

	_loadImage (image) {
		this.setState({
			loadingStatus: LOADING_STATUS.IN_PROGRESS,
		});
		this.props.onLoadImage(image)
			.then(() => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					loadingStatus: LOADING_STATUS.DONE,
				});
			})
			.catch(() => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					loadingStatus: LOADING_STATUS.FAILED,
				});
			});
	}

	render () {
		return this.props.children({ status: this.state.loadingStatus });
	}
}
