import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/ResolverFindingsBadge.css';


const baseCssClassName = 'resolver-findings-badge';


export default class ResolverFindingsBadge extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		backgroundColor: PropTypes.string,
		color: PropTypes.string,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		backgroundColor: '#AFB6C7',
		color: '#45464E',
		disabled: false,
		onClick: () => {},
	};

	_handleClick = (...params) => {
		if ( this.props.disabled === true ) {
			return;
		}

		this.props.onClick(...params);
	};

	render () {
		return (
			<div
				className={classnames([
					baseCssClassName,
					this.props.disabled === true && `${baseCssClassName}__m-disabled`,
				])}
				style={{
					backgroundColor: this.props.backgroundColor,
					color: this.props.color,
				}}
				onClick={this._handleClick}
			>
				{this.props.children}
			</div>
		);
	}
}
