import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import mainConfig from '../../configs/mainConfig';
import lodashIsEqual from 'lodash/isEqual';

import labelsUtils from '../../appUtils/labelsUtils';
import teethUtils from '../../appUtils/teeth/teethUtils';
import { tagIsSurface } from '../../modules/label-tags/utils';
import labelsActions from '../../modules/labels/actions/labelsActions';

import editorSelectors from '../../selectors/editorSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../modules/label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';

import imagesSelectors from '../../selectors/imagesSelectors';
import userSelectors from '../../selectors/userSelectors';
import labelChildrenSelectors from '../../modules/labels/selectors/labelChildrenSelectors';

import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';

import editorActions from '../../actions/editorActions';

import LabelListItem from './LabelListItem';

import analyseSelectors from '../../selectors/analyseSelectors';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import './styles/LabelList.css';
import {LABELS_STAGE_COMPLETED} from "../../constants/labelsStageConstants";


function isSelectedLabel (label, selectedLabelId) {
	if ( labelGetters.getLabelId(label) === selectedLabelId ) {
		return true;
	}

	if ( label.children && label.children.length > 0 ) {
		for (let i = 0, l = label.children.length; i < l; i++) {
			if ( isSelectedLabel(label.children[i], selectedLabelId) ) {
				return true;
			}
		}
	}

	return false;
}


const baseCssClassName = 'label-list';
const labelCssClassName = `${baseCssClassName}__label`;


class LabelList extends PureComponent {
	static propTypes = {
		labels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		toothlessLabels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		selectedToothKey: PropTypes.string,
		selectedLabel: PropTypes.object,
		allowEditing: PropTypes.bool.isRequired,
		showConfirmation: PropTypes.bool.isRequired,
		displayToothKeys: PropTypes.bool.isRequired,

		onSetConfirmed: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		onHighlightLabels: PropTypes.func.isRequired,
		onSelectLabel: PropTypes.func.isRequired,
		onLabelChange: PropTypes.func.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._labelsEls = {};
		this._labelsWrapper = {};

		this._lastSelectedLabelId = null;
	}

	componentDidUpdate (prevProps) {
		let currentSelectedLabelId = null;
		if ( this.props.selectedLabel ) {
			const selectedLabelId = labelGetters.getLabelId(this.props.selectedLabel);

			let label = this.props.selectedLabel;
			const parentLabelId = this.props.onGetParentLabel(selectedLabelId);

			if ( parentLabelId ) {
				const parentLabel = this.props.labels.find((_label) => labelGetters.getLabelId(_label) === parentLabelId);
				if ( parentLabel ) {
					label = parentLabel;
				}
			}

			currentSelectedLabelId = label && labelGetters.getLabelId(label);
			if (
				this._labelsWrapper &&
				(
					currentSelectedLabelId && currentSelectedLabelId !== this._lastSelectedLabelId ||
					!lodashIsEqual(prevProps.selectedLabel, this.props.selectedLabel)
				)
			) {
				const labelEl = this._labelsEls[currentSelectedLabelId];

				if ( !labelEl ) {
					return;
				}

				this._lastSelectedLabelId = currentSelectedLabelId;
				this._labelsWrapper.scrollTop = Math.max(0, labelEl.offsetTop);
			}

		}

		if ( currentSelectedLabelId === null ) {
			this._lastSelectedLabelId = null;
		}
	}

	_handleLabelOver = (label) => {
		const labels = [ labelGetters.getLabelId(label) ];

		if ( label.parentId ) {
			labels.push(label.parentId);
		}
		this.props.onHighlightLabels(labels);
	}

	_handleLabelOut = () => {
		this.props.onHighlightLabels([]);
	}

	_handleSelectLabel = (label) => {
		if ( !this.props.allowEditing ) {
			return;
		}

		this.props.onSelectLabel({
			label,
		});
	}

	_handleLabelsRef = (el) => {
		this._labelsWrapper = el;
	}

	_handleGetRef = (el, labelId) => {
		this._labelsEls[labelId] = el;
	}

	// _handleToothKeyOver = (label) => {
	// 	this.props.onHighlightLabels([ labelGetters.getLabelId(label) ]);
	// }
	//
	// _handleToothKeyOut = () => {
	// 	this.props.onHighlightLabels([]);
	// }

	render () {
		const {
			user,
		} = this.props;
		const content = [];

		this.props.labels.forEach((label) => {
			const labelId = labelGetters.getLabelId(label);

			const isSelected = Boolean(this.props.selectedLabel) && isSelectedLabel(label, labelGetters.getLabelId(this.props.selectedLabel));
			let toothKey = null;
			let displayedToothKey = null;

			const isTooth = labelsUtils.labelIsTooth(label);

			const notationType = user.notation_type;

			if ( isTooth ) {
				if ( this.props.tags[labelId] && this.props.tags[labelId].length > 0 ) {
					toothKey = labelTagGetter.getTagKey(this.props.tags[labelId][0]);
				}

				if ( this.props.displayToothKeys === true && toothKey ) {
					displayedToothKey = teethUtils.getLocalizedToothKey({
						toothKey,
						notationType,
					});
				}
			}

			content.push((
				<div
					key={labelId}
					className={classnames([
						labelCssClassName,
						isSelected && `${labelCssClassName}__m-selected`,
					])}
					ref={(el) => {this._handleGetRef(el, labelId)}}
				>
					<LabelListItem
						toothKey={toothKey}
						displayedToothKey={displayedToothKey}
						label={label}
						selectedLabel={this.props.selectedLabel}
						showControls={this.props.allowEditing}
						isEditMode={isSelected && this.props.allowEditing}
						showConfirmation={this.props.showConfirmation}
						tags={this.props.tags}

						onGetAvailableLabels={this.props.onGetAvailableLabels}
						onSelectLabel={this._handleSelectLabel}
						onLabelOver={this._handleLabelOver}
						onLabelOut={this._handleLabelOut}
						onSetConfirmed={this.props.onSetConfirmed}
						onRemove={this.props.onRemove}
						onLabelChange={this.props.onLabelChange}
						onAddLabel={this.props.onAddLabel}
					/>
				</div>
			));
		});

		return (
			<div
				ref={this._handleLabelsRef}
				className={`${baseCssClassName} ${mainConfig.MAIN_SCROLL_CSS_CLASS_NAME}`}
			>{content}</div>
		);
	}
}

export default connect((state) => {
	const showAllClasses = editorSelectors.selectShowAllFindings(state);
	const editorData = editorSelectors.selectEditor(state);
	const filteredClasses = editorSelectors.selectFilteredClassesForCurrentImage(state);
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const selectedLabel = editorData.selectedLabel;

	const isImageAnalyzed = analyseSelectors.selectIsImageAnalyzed(state, { imageId: currentImageId });

	let labels = [];
	const toothlessLabels = [];
	const result = {
		teeth: [],
		teethWithoutKey: [],
		findings: [],
	};
	const childParentMap = {};
	const preparedLabel = {};
	const aiPathologiesButtonAvailable = userSelectors.selectUsesComputerAidedDeviceUi(state);
	const aiPathologiesEnabled = currentImage.is_sequential_viewed === true;

	if ( !getRuntimeConfig()['auto-image-analysis'] || !isImageAnalyzed ) {
		const imageLabels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		});
		const imageLabelsMap = imageLabels.reduce((result, labelId) => {
			result[labelId] = true;
			return result;
		}, {});

		let labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		labelChildren = Object.keys(labelChildren)
			.reduce((result, labelId) => {
				if ( imageLabelsMap[labelId] === true ) {
					result[labelId] = labelChildren[labelId];
				}

				return result;
			}, {});

		const childrenLabels = {};

		Object.keys(labelChildren)
			.forEach((labelId) => {
				const label = {
					...labelsSelectors.selectLabelById(state, {
						labelId,
					}),
				};

				label.children = [];

				labelChildren[labelId].forEach((childLabelId) => {
					childrenLabels[childLabelId] = true;
					preparedLabel[childLabelId] = true;
					childParentMap[childLabelId] = labelId;
					const childLabel = labelsSelectors.selectLabelById(state, {
						labelId: childLabelId,
					});
					const classId = labelGetters.getLabelClassId(childLabel);
					const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);
					const source = labelGetters.getLabelSource(label);

					if (
						(
							showAllClasses ||
							true === filteredClasses[classId]
						) &&
						(
							typeof measureOfConfidence !== 'number' ||
							editorData.filteredConfidencePercent <= measureOfConfidence
						)
						&& mainConfig.BONE_LOSS_LINES.includes(classId) === false &&
						(
							aiPathologiesButtonAvailable === false ||
							aiPathologiesEnabled === true ||
							source === 'manual' ||
							( classId !== 'caries' && classId !== 'periodontitis' )
						)
					) {
						const tags = [];

						labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: childLabelId })
							.forEach((tagId) => {
								const tag = labelTagsSelectors.selectLabelTagById(state, { tagId });

								tags.push({
									id: labelTagGetter.getTagId(tag),
									key: labelTagGetter.getTagKey(tag),
									label: labelTagGetter.getLocalizedTagName(tag),
								});
							});

						label.children.push({
							...childLabel,
							parentId: labelGetters.getLabelId(label),
							tags,
						});
					}
				});

				preparedLabel[labelId] = true;

				if ( labelsUtils.labelIsTooth(label) ) {
					if (
						(label.children && label.children.length > 0) ||
						(selectedLabel && labelGetters.getLabelId(selectedLabel) === labelId) ||
						(!showAllClasses && true === filteredClasses[labelGetters.getLabelClassId(label)])
					) {
						labels.push(label);
					}

					return;
				}

				labels.push(label);
			});

		imageLabels
			.forEach((labelId) => {
				if ( preparedLabel[labelId] ) {
					return;
				}

				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});

				if ( labelsUtils.labelIsTooth(label) ) {
					if (
						(label.children && label.children.length > 0) ||
						(selectedLabel && labelGetters.getLabelId(selectedLabel) === labelId) ||
						(!showAllClasses && true === filteredClasses[labelGetters.getLabelClassId(label)])
					) {
						labels.push(label);
					}

					return;
				}

				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
				const classId = labelGetters.getLabelClassId(label);
				const source = labelGetters.getLabelSource(label);

				if (
					(
						showAllClasses ||
						true === filteredClasses[labelGetters.getLabelClassId(label)]
					) &&
					(
						typeof measureOfConfidence !== 'number' ||
						editorData.filteredConfidencePercent <= measureOfConfidence
					) &&
					mainConfig.BONE_LOSS_LINES.includes(classId) === false &&
					(
						aiPathologiesButtonAvailable === false ||
						aiPathologiesEnabled === true ||
						source === 'manual' ||
						( classId !== 'caries' && classId !== 'periodontitis' )
					)
				) {
					const tags = [];

					labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId })
						.forEach((tagId) => {
							const tag = labelTagsSelectors.selectLabelTagById(state, { tagId });

							tags.push({
								id: labelTagGetter.getTagId(tag),
								key: labelTagGetter.getTagKey(tag),
								label: labelTagGetter.getLocalizedTagName(tag),
							});
						});

					labels.push({
						...label,
						tags,
					});
				}
			});
	}

	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;
	const usesReaderStudyModeUi = userSelectors.selectUsesReaderStudyModeUi(state);
	const tags = {};
	function getTags (label, tags) {
		const labelId = labelGetters.getLabelId(label);
		labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId })
			.forEach((tagId) => {
				const tag = labelTagsSelectors.selectLabelTagById(state, { tagId });

				if ( !tags[labelId] ) {
					tags[labelId] = [];
				}

				const tagKey = labelTagGetter.getTagKey(tag);

				tags[labelId].push({
					id: labelTagGetter.getTagId(tag),
					key: tagKey,
					localizedKey: teethUtils.getLocalizedToothKey({ toothKey: tagKey, notationType }),
					label: labelTagGetter.getLocalizedTagName(tag),
					canEdit: (
						usesReaderStudyModeUi !== true ||
						tagIsSurface(tagKey) === false
					),
				});
			});

		if ( label.children ) {
			label.children.forEach((childLabel) => {
				getTags(childLabel, tags);
			});
		}
	}

	labels.forEach((label) => {
		getTags(label, tags);
	});

	labels.forEach((label) => {
		const labelTags = tags[labelGetters.getLabelId(label)];
		if ( labelsUtils.labelIsTooth(label) ) {
			if ( labelTags && labelTags.length > 0 ) {
				result.teeth.push(label);
			}
			else {
				result.teethWithoutKey.push(label);
			}
		}
		else {
			result.findings.push(label);
		}
	});

	result.teeth.sort((a, b) => {
		return teethUtils.sortTeeth(tags[labelGetters.getLabelId(a)][0].localizedKey, tags[labelGetters.getLabelId(b)][0].localizedKey);
	});
	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(state);

	return {
		labels: result.teeth.concat(result.teethWithoutKey).concat(result.findings),
		toothlessLabels,
		selectedToothKey: editorData.selectedToothKey,
		selectedLabel,
		availableLabels: labelsSelectors.selectAvailableLabelsByImageType(state, { type: currentImage.image_type, classId: 'tooth' })
			.filter((labelData) => labelData.classId !== 'tooth'),
		allowEditing: currentImage.isOwn && editorData.labelsStage !== LABELS_STAGE_COMPLETED,
		user: userSelectors.selectUserData(state),
		tags,
		showConfirmation: (
			!isFDAAnnotationEnabled &&
			userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
		),
		displayToothKeys: userSelectors.selectUsesComputerAidedDeviceUi(state) === false,
		onGetAvailableLabels: (options) =>
			labelsSelectors.selectAvailableLabelsByImageType(state, {
				type: currentImage.image_type,
				...options,
			}),
		onGetParentLabel: (labelId) => {
			return childParentMap[labelId];
		},
	};
}, (dispatch) => ({
	onSetConfirmed: (data) => dispatch(labelsActions.setConfirmed(data)),
	onRemove: (data) => dispatch(labelsActions.removeLabel(data)),
	onHighlightLabels: (data) => dispatch(editorActions.highlightLabels({
		data,
	})),
	onSelectLabel: (data) => dispatch(editorActions.selectLabel(data)),
	onLabelChange: (data) => dispatch(labelsActions.changeLabel(data)),
	onAddLabel: (data) => dispatch(editorActions.addLabel(data)),
}))(LabelList);
