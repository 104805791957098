import apiActionTypes from '../actions/apiActionTypes';
import imagesCollectionsActionTypes from '../actions/imagesCollectionsActionTypes';


const initialState = {};


export default function imagesCollectionReducer (state = initialState, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_COLLECTIONS_DATA:
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.imagesCollections || state );

		case imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__SET_IMAGES: {
			const collectionId = action.payload.collectionId;

			return {
				...state,
				[collectionId]: action.payload.images,
			};
		}
			
		case imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__SHIFT_IMAGE: {
			const collectionId = action.payload.collectionId;

			return {
				...state,
				[collectionId]: [action.payload.imageId].concat(( state[collectionId] || [] )),
			};
		}

		case imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__PUSH_IMAGE: {
			const collectionId = action.payload.collectionId;

			return {
				...state,
				[collectionId]: [( state[collectionId] || [] )].concat(action.payload.imageId),
			};

		}

		case imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE: {
			const collectionId = action.payload.collectionId;

			return {
				...state,
				[collectionId]: ( state[collectionId] || [] ).filter((imageId) => imageId !== action.payload.imageId),
			};
		}

		default:
			return state;
	}
}
