export default {
	'labels.first_name': 'Имя',
	'labels.last_name': 'Фамилия',
	'labels.country': 'Страна',
	'labels.clinic': 'Организация',
	'labels.language': 'Язык',
	'labels.notation': 'Система нумерации зубов',
	'labels.report_specialist_email': 'Email специалиста',
	
	'buttons.change_email': 'Сменить почту',
	'buttons.change_password': 'Сменить пароль',
	'buttons.upload_logo': `Загрузить<br />лого`,
};
