import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import commonUtils  from '../../appUtils/common';

import UiFloat, { FloatPosition, FloatAxis } from '../Float';

import './styles/Tooltip.css';


function getViewportRect (viewport) {
	if ( !viewport ) {
		return window.document.body.getBoundingClientRect();
	}

	return viewport.getBoundingClientRect();
}

const baseCssClassName = 'tooltip';
const globalContainerCssClassName = `${baseCssClassName}-global-container`;


class Tooltip extends PureComponent {
	static propTypes = {
		posX: PropTypes.string,
		posY: PropTypes.string,
		offsetX: PropTypes.number,
		offsetY: PropTypes.number,
		viewport: PropTypes.object,
	}

	static defaultProps = {
		posX: 'center',
		posY: 'bottom',
		offsetX: 0,
		offsetY: 10,
	}

	constructor (props, context) {
		super(props, context);

		// Reference on the current global container.
		this._globalContainer = null;
	}

	componentDidUpdate () {
		if ( this.props.isGlobal ) {
			this._updatePosition();
		}
	}

	componentDidMount () {
		if ( this.props.isGlobal ) {
			this._updatePosition();
		}
	}

	componentWillUnmount () {
		if ( this._globalContainer ) {
			document.body.removeChild(this._globalContainer);

			this._globalContainer = null;
		}
	}

	_updatePosition () {
		if ( !this._globalContainer || !this.props.target ) {
			return;
		}

		const scrollSize = commonUtils.getScrollSize();
		const targetBox = this.props.target.getBoundingClientRect();
		const viewportRect = getViewportRect(this.props.viewport);
		const viewportLeft = window.scrollX + viewportRect.left;
		const viewportRight = viewportLeft + (viewportRect.right - viewportRect.left);
		const viewportTop = window.scrollY + viewportRect.top;
		const viewportBottom = viewportTop + (viewportRect.bottom - viewportRect.top);

		const targetCenterX = window.scrollX + (targetBox.left + (targetBox.right - targetBox.left) / 2);
		const targetCenterY = window.scrollY + (targetBox.top + (targetBox.bottom - targetBox.top) / 2);
		
		const globalContainerBox = this._globalContainer.getBoundingClientRect();
		const globalContainerTop = window.scrollY + globalContainerBox.top;
		const globalContainerBottom = globalContainerTop + (globalContainerBox.bottom - globalContainerBox.top);
		const globalContainerLeft = window.scrollX + globalContainerBox.left;
		const globalContainerRight = globalContainerLeft + (globalContainerBox.right - globalContainerBox.left);
		const globalContainerWidth = globalContainerRight - globalContainerLeft;
		const globalContainerHeight = globalContainerBottom - globalContainerTop;
		
		// Bottom position
		let containerX = targetCenterX - (globalContainerWidth / 2);
		let containerY = this.props.offsetY + window.scrollY + targetBox.top + (targetBox.bottom - targetBox.top);

		// Top position
		if (
			containerY + globalContainerHeight > viewportBottom ||
			containerX < viewportLeft ||
			containerX + globalContainerWidth > viewportRight
		) {
			containerY = (window.scrollY + targetBox.top) - globalContainerHeight - this.props.offsetY;
			
			// Left position
			if (
				containerY < viewportTop ||
				containerX < viewportLeft ||
				containerX + globalContainerWidth > viewportRight
			) {
				containerX = (window.scrollX + targetBox.left) - globalContainerWidth - this.props.offsetY;
				containerY = targetCenterY - (globalContainerHeight / 2);
				
				// Right position
				if ( containerX < viewportLeft ) {
					containerX = (window.scrollX + targetBox.right) + this.props.offsetY;
					containerY = targetCenterY - (globalContainerHeight / 2);
				}
			}
		}

		this._globalContainer.style.left = `${containerX}px`;
		this._globalContainer.style.top = `${containerY}px`;
		this._globalContainer.style.opacity = 1;
	}

	/**
	 * Renders the tooltip in the global context(body).
	 *
	 * @private
	 */
	_renderGlobal () {
		if ( !this._globalContainer ) {
			this._globalContainer = document.createElement('DIV');
			this._globalContainer.className = globalContainerCssClassName;
			document.body.appendChild(this._globalContainer);
		}

		return ReactDOM.createPortal(
			this._renderBaseContent(),
			this._globalContainer
		);
	}

	_renderBaseContent () {
		const {
			top,
			left,
			width,
			height,
			children,
			isGlobal,
		} = this.props;

		return (
			<UiFloat
				target={this.props.target}
				initialPosition={{
					mainAxis: FloatAxis.vertical,
					mainAxisPosition: FloatPosition.end,
					targetAnchorPosition: FloatPosition.center,
				}}
				viewportLocation={this.props.viewport ? this.props.viewport.getBoundingClientRect() : undefined}
			>
				<div className={baseCssClassName}>
					{children}
				</div>
			</UiFloat>
		);
	}

	render () {
		if ( this.props.isGlobal ) {
			return this._renderGlobal();
		}

		return this._renderBaseContent();
	}
}

export default Tooltip;
