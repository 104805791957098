export default {
	'image.alt': 'Uploaded image',
	
	'labels.image_type': "Image type",
	'labels.patient_id': "Patient's ID",
	'labels.birthday': "Birth date",
	'labels.gender': "Gender",
	'labels.image_date': "Image date",

	'dialog.title': 'Image uploading',

	'wrong_extension.dialog.content': `
		Sorry, we couldn't upload the ﬁle. Please, check ﬁle format.<br />
		Supported file formats: {formats}.<br />
		Please upload another file.
	`,

	'notifications.ceph-type': 'Currently, the service supports analysis of only intraoral X-rays (bitewing, periapical) and panorama images. Seems like you are trying to upload a cephalometric image',
	'notifications.child-type': 'Seems like you are trying to upload a panorama image with mixed dentition. The service does not support analysis of them yet',
	'notifications.unknown-type': 'Currently, the service supports analysis of only intraoral X-rays (bitewing, periapical) and panorama images. Seems like you are trying to upload an image of a different modality or poor quality',
};
