import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import mainConfig from '../../configs/mainConfig';

import {
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__XRAY,
} from '../../constants/imageConstants';

import { USER_PERMISSION__SHOW_GRID, USER_PERMISSION__FLIP_IMAGE, USER_PERMISSION__ROTATE_IMAGE } from '../../constants/userPermissionsConstants';
import { DRAGGING_STATUS } from '../Canvas/CanvasConstants';
import aclService from '../../services/acl';
import { setCanvas, setViewportData } from '../../services/canvas';
import namedPolyHintController from '../../services/named-poly-hint';

import imageUtils from '../../appUtils/imageUtils';

import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import collectionsSelectors from '../../selectors/collectionsSelectors';

import Alignment from '../Alignment';
import Loading from '../Loading';
import AddShapeWizard from '../AddShapeWizard';
import CanvasControls from '../CanvasControls';
import Sidebar from '../Sidebar';
import { Toolbar } from '../Toolbar';
import Registry from '../Registry';
import Hint from '../Hint';
import SizeDetector from '../SizeDetector';
import CanvasCollectionImageLoader from '../Canvas/CanvasCollectionImageLoader';
import Viewport from '../Canvas/Viewport';
import CanvasGrid from '../Canvas/CanvasGrid';

import CanvasEditorConnector from '../Canvas/CanvasEditorConnector';
import MagnifyGlassCanvas from '../MagnifyingGlass/MagnifyGlassCanvas';
import MagnifyingGlass from '../MagnifyingGlass/MagnifyingGlassImageConnector';
import CanvasObjects from '../ImageShapes/CanvasObjects';
import MainShapes from './MainShapes';

import { ResolverImageNotification } from '../../modules/resolver/components/image-editor';

import { getDictionary } from '../../appUtils/locale';

import './styles/Main.css';


const i18n = getDictionary('image-boxes'); // @todo
const i18nShared = getDictionary('shared');

const baseCssClassName = 'main';
const mainCssClassName = `${baseCssClassName}__main`;
const mainCanvasCssClassName = `${baseCssClassName}__main-canvas`;
const mainCanvasControlsCssClassName = `${baseCssClassName}__main-canvas-controls`;
const sidebarCssClassName = `${baseCssClassName}__sidebar`;
const toolbarClassName = `${baseCssClassName}__toolbar`;
const notificationsCssClassName = `${baseCssClassName}__notifications`;


class Main extends PureComponent {
	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		userData: PropTypes.object,
		zoom: PropTypes.number.isRequired,
		editorMode: PropTypes.string.isRequired,
		showCanvasGrid: PropTypes.bool.isRequired,
		areFindingsMasksFromProfileEnabled: PropTypes.bool.isRequired,
		usesReaderStudyModeUi: PropTypes.bool.isRequired,
		areHeatMapsFromProfileEnabled: PropTypes.bool.isRequired,
		aiPathologiesEnabled: PropTypes.bool.isRequired,
		shouldShowAiNotificationBefore: PropTypes.bool.isRequired,
		shouldShowAiNotificationAfter: PropTypes.bool.isRequired,
		enhancedImageFilter: PropTypes.bool.isRequired,
		currentImageId: PropTypes.string,
		currentImage: PropTypes.object,
		onSetBonelossStages: PropTypes.func.isRequired,
		onDisableAiNotificationBefore: PropTypes.func.isRequired,
		onDisableAiNotificationAfter: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	_toolbarItems = null;

	state = {
		shouldNamedPolyTextDisplay: false,
		namedPolyText: null,
		imageLoadingStatus: false,
	};

	componentDidMount () {
		namedPolyHintController.events.on('text-changed', this._handleNamedPolyHintTextChanged);
		namedPolyHintController.events.on('show', this._handleNamedPolyHintShow);
		namedPolyHintController.events.on('hide', this._handleNamedPolyHintHide);
		window.addEventListener('beforeunload', this._handleBeforeUnload, false);

		if ( this.props.usesReaderStudyModeUi === true ) {
			this.props.onSetBonelossStages(false);
		}
	}

	componentWillMount () {
		namedPolyHintController.events.off('text-changed', this._handleNamedPolyHintTextChanged);
		namedPolyHintController.events.off('show', this._handleNamedPolyHintShow);
		namedPolyHintController.events.off('hide', this._handleNamedPolyHintHide);
		window.removeEventListener('beforeunload', this._handleBeforeUnload, false);
	}

	_handleNamedPolyHintTextChanged = (value) => {
		this.setState({
			namedPolyText: value,
		});
	};

	_handleNamedPolyHintShow = () => {
		this.setState({
			shouldNamedPolyTextDisplay: true,
		});
	};

	_handleNamedPolyHintHide = () => {
		this.setState({
			shouldNamedPolyTextDisplay: false,
		});
	};

	_handleImageLoadingStatusChanged = (status) => {
		this.setState({
			imageLoadingStatus: status,
		});
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleBeforeUnload = (event) => {
		if ( imageUtils.shouldWeShowWarningOnLeavingImage() === true ) {
			event.preventDefault();
			(event || window.event).returnValue = '';
			return '';
		}
	};

	_getToolbarConfig () {
		if ( null !== this._toolbarItems ) {
			return this._toolbarItems;
		}

		this._toolbarItems = mainConfig.TOOLBAR_ORDER
			.filter((item) => {
				if (
					item === mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL ||
					item === mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL
				) {
					return aclService.checkPermission(USER_PERMISSION__FLIP_IMAGE);
				}

				if (
					item === mainConfig.TOOLBAR_ITEM__ROTATE_LEFT ||
					item === mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT
				) {
					return aclService.checkPermission(USER_PERMISSION__ROTATE_IMAGE);
				}

				return true;
			});

		let keepGrid = false;

		if ( this.props.isAuthenticated ) {
			if (
				(
					this.props.currentImage.image_type === IMAGE_TYPE__BITEWING ||
					this.props.currentImage.image_type === IMAGE_TYPE__PERIAPICAL ||
					this.props.currentImage.image_type === IMAGE_TYPE__XRAY
				) && aclService.checkPermission(USER_PERMISSION__SHOW_GRID)
			) {
				keepGrid = true;
			}
		}

		if ( false === keepGrid ) {
			this._toolbarItems = this._toolbarItems.filter((item) => item !== mainConfig.TOOLBAR_ITEM__GRID);
		}

		if ( this.props.areFindingsMasksFromProfileEnabled === false ) {
			this._toolbarItems = this._toolbarItems.filter((item) => item !== mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS);
		}

		if ( this.props.areHeatMapsFromProfileEnabled === false ) {
			this._toolbarItems = this._toolbarItems.filter((item) => item !== mainConfig.TOOLBAR_ITEM__HEAT_MAP);
		}

		if ( this.props.usesReaderStudyModeUi === true ) {
			this._toolbarItems = this._toolbarItems.filter((item) => item !== mainConfig.TOOLBAR_ITEM__BONE_LEVELS);
		}

		return this._toolbarItems;
	}

	_renderHint (canvasObjectsApi) {
		if (
			canvasObjectsApi.closestObjectToPoint === null ||
			canvasObjectsApi.isSelectedObjectInTransformation === true ||
			(
				canvasObjectsApi.selectedObject !== null &&
				canvasObjectsApi.closestObjectToPoint.id === canvasObjectsApi.selectedObject.id
			) ||
			!this.props.currentImage.isOwn
		) {
			return null;
		}

		return (
			<Hint
				content={i18n('hint.content')}
				isGlobal
				top={82 - window.document.body.scrollTop}
				right={351}
			/>
		);
	}

	render () {
		const {
			currentImage,
			editorMode,
		} = this.props;

		const canShowImageShapes = (
			editorMode === mainConfig.EDITOR_MODE__SELECT_MODE ||
			editorMode === mainConfig.EDITOR_MODE__EDIT_MODE
		);

		const canShowAddWizard = (
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE ||
			editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH
		);

		return (
			<>
				<div className={toolbarClassName}>
					<Toolbar items={this._getToolbarConfig()} />
				</div>
				<div className={baseCssClassName}>
					<div className={mainCssClassName}>
						<div className={mainCanvasCssClassName}>
							{ this.state.shouldNamedPolyTextDisplay && (
								<Hint
									content={this.state.namedPolyText}
									top={-38}
									right={19}
								/>
							) }
							<div className={notificationsCssClassName}>
								{this.props.shouldShowAiNotificationBefore && (
									<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR} onClose={this.props.onDisableAiNotificationBefore}>
										<div style={{ fontSize: 14, padding: 2 }}>Please complete an unassisted review of the image before displaying AI findings</div>
									</ResolverImageNotification>
								)}
								{this.props.shouldShowAiNotificationAfter && (
									<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR} onClose={this.props.onDisableAiNotificationAfter}>
										<div style={{ fontSize: 14, padding: 2 }}>Confidence explains AI certainty, not the likelihood or extent of pathology</div>
									</ResolverImageNotification>
								)}
							</div>
							<div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
								<SizeDetector>
									{({ width: containerWidth, height: containerHeight }) => (
										<CanvasCollectionImageLoader
											image={currentImage}
											key={currentImage.hashname}
											useEnhancedImage={this.props.enhancedImageFilter}
											onStatusChanged={this._handleImageLoadingStatusChanged}
										>
											{({ status, image, retryLoad }) => {
												switch (status) {
													case CanvasCollectionImageLoader.STATUSES.FAILED:
														return (
															<Alignment
																horizontal={Alignment.horizontal.CENTER}
																vertical={Alignment.vertical.CENTER}
															>
															<span
																style={{ color: '#fff', fontSize: 12, cursor: 'pointer' }}
																onClick={retryLoad}
															>Reload image</span>
															</Alignment>
														);

													case CanvasCollectionImageLoader.STATUSES.LOADED: {
														const imageWidth = image.naturalWidth;
														const imageHeight = image.naturalHeight;
														return (
															<Viewport
																canvasWidth={imageWidth}
																canvasHeight={imageHeight}
																viewportWidth={containerWidth}
																viewportHeight={containerHeight}
																onGetZoom={(zoom, delta) => {
																	if ( zoom !== null ) {
																		return Math.min(
																			6,
																			Math.max(0.1, zoom)
																		);
																	}
																	else {
																		return imageUtils.getDefaultZoom({
																			canvasWidth: containerWidth,
																			canvasHeight: containerHeight,
																			imageWidth,
																			imageHeight,
																		});
																	}
																}}
																onGetInitialPosition={({ zoom }) => ({
																	x: (containerWidth / 2) - ((imageWidth * zoom) / 2),
																	y: (containerHeight / 2) - ((imageHeight) * zoom / 2),
																})}
															>
																{(canvasApi) => {
																	if ( canvasApi.viewport === null ) {
																		return null;
																	}

																	setCanvas(canvasApi.viewport);
																	setViewportData({
																		zoom: canvasApi.zoom,
																		offsetX: -canvasApi.offsetX,
																		offsetY: -canvasApi.offsetY,
																	});

																	return (
																		<Fragment>
																			<CanvasEditorConnector canvasApi={canvasApi} />
																			<MagnifyGlassCanvas
																				zoom={canvasApi.zoom}
																				draggingStatus={canvasApi.draggingStatus}
																			/>
																			<MagnifyingGlass
																				image={currentImage}
																				width={imageWidth * canvasApi.zoom}
																				height={imageHeight * canvasApi.zoom}
																				sharpen={!image.enhanced_image_url}
																			/>
																			{this.props.showCanvasGrid === true && (
																				<CanvasGrid
																					width={imageWidth}
																					height={imageHeight}
																					zoom={canvasApi.zoom}
																				/>
																			)}
																			{canShowImageShapes === true && (
																				<CanvasObjects
																					zoom={canvasApi.zoom}
																					isSelectable={this.props.currentImage.isOwn === true}
																					isVisible={canvasApi.draggingStatus === DRAGGING_STATUS.IDLE}
																				>
																					{(canvasObjectsApi) => {
																						return (
																							<Fragment>
																								<MainShapes
																									canvasApi={canvasApi}
																									canvasObjectsApi={canvasObjectsApi}
																									selectedObject={canvasObjectsApi.selectedObject}
																									closestObjectToPoint={canvasObjectsApi.closestObjectToPoint}
																									isSelectedObjectInTransformation={canvasObjectsApi.isSelectedObjectInTransformation}
																									imageWidth={imageWidth}
																									imageHeight={imageHeight}
																									showBoneLossStages={this.props.showBoneLossStages}
																									canEditShapes={this.props.currentImage.isOwn === true}
																								/>
																								{this._renderHint(canvasObjectsApi)}
																							</Fragment>
																						);
																					}}
																				</CanvasObjects>
																			)}
																			{canShowAddWizard === true && (
																				<AddShapeWizard
																					canvasApi={canvasApi}
																					zoom={canvasApi.zoom}
																					imageOriginalWidth={imageWidth}
																					imageOriginalHeight={imageHeight}
																				/>
																			)}
																		</Fragment>
																	);
																}}
															</Viewport>
														);
													}

													default:
														return (
															<Alignment
																horizontal={Alignment.horizontal.CENTER}
																vertical={Alignment.vertical.CENTER}
															>
																<Loading />
															</Alignment>
														);
												}
											}}
										</CanvasCollectionImageLoader>
									)}
								</SizeDetector>
							</div>
						</div>
						{
							currentImage.isOwn &&
							<div className={mainCanvasControlsCssClassName}>
								<CanvasControls
									shouldShowLastButton={typeof this.props.usesReaderStudyModeUi === 'boolean' ? this.props.usesReaderStudyModeUi === false : undefined}
								/>
							</div>
						}
					</div>
					<div className={sidebarCssClassName}>
						{this.state.imageLoadingStatus === CanvasCollectionImageLoader.STATUSES.LOADED && <Sidebar />}
					</div>
					{this.props.isAuthenticated && <Route path={'/collections/:id/image/:imageId/registry'} component={Registry} />}
				</div>
			</>
		);
	}
}

export default withRouter(connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const isAuthenticated = userSelectors.selectIsAuthenticated(state);
	const areHeatMapsFromProfileEnabled = userSelectors.selectAreHeatMapsEnabled(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const isCadUser = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
	return {
		isAuthenticated,
		userData: isAuthenticated ? userSelectors.selectUserData(state) : null,
		currentImageId,
		currentImage,
		zoom: editorData.zoom,
		editorMode: editorData.editorMode,
		showCanvasGrid: editorData.showCanvasGrid,
		showBoneLossStages: editorData.showBoneLossStages,
		areFindingsMasksFromProfileEnabled: areHeatMapsFromProfileEnabled === false && userSelectors.selectAreFindingsMasksEnabled(state),
		areHeatMapsFromProfileEnabled,
		usesReaderStudyModeUi: userSelectors.selectUsesReaderStudyModeUi(state),
		shouldShowAiNotificationBefore: (
			isCadUser === true &&
			currentImage.is_sequential_viewed === false &&
			editorData.shouldShowAiNotificationBefore === true
		),
		shouldShowAiNotificationAfter: editorData.shouldShowAiNotificationAfter === true,
		enhancedImageFilter: editorData.enhancedImageFilter,
		onGetCurrentCollection: () =>
			collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			}),
	};
}, (dispatch) => ({
	onSetBonelossStages: (value) => dispatch(editorActions.updateData({
		data: {
			showBoneLossStages: value,
		},
	})),
	onDisableAiNotificationBefore: () => dispatch(editorActions.updateData({
		data: {
			shouldShowAiNotificationBefore: false,
		},
	})),
	onDisableAiNotificationAfter: () => dispatch(editorActions.updateData({
		data: {
			shouldShowAiNotificationAfter: false,
		},
	})),
}))(Main));
