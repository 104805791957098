const baseCssClassName = 'alignment';
const contentCssClassName = `${baseCssClassName}__content`;

const HORIZONTAL_ALIGNMENT = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
};

const VERTICAL_ALIGNMENT = {
	TOP: 'top',
	CENTER: 'center',
	BOTTOM: 'bottom',
};


export default {
	baseCssClassName,
	contentCssClassName,
	HORIZONTAL_ALIGNMENT,
	VERTICAL_ALIGNMENT,
};
