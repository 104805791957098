import { getRuntimeConfig } from './runtimeConfig';

import cookiesUtils from './cookiesUtils';

import { getStorageService } from '../services/storage';


/**
 * @returns {boolean}
 */
export function areFindingsMasksEnabled () {
	let cookieValue = cookiesUtils.read(getRuntimeConfig().findings_masks_cookie_name);
	let storageValue = getStorageService().get('are_findings_masks_enabled');

	// @todo Remove later
	if ( typeof storageValue !== 'boolean' && (cookieValue === '1' || cookieValue === '0') ) {
		getStorageService().set('are_findings_masks_enabled', cookieValue === '1');
		cookiesUtils.remove(getRuntimeConfig().findings_masks_cookie_name);
	}

	return getStorageService().get('are_findings_masks_enabled');
}

/**
 * @param {boolean} value
 */
export function setFindingsMasks (value) {
	getStorageService().set('are_findings_masks_enabled', value);
}
