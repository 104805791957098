import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import authApi from '../../api/authApi';

import { SUBMIT_STATUSES } from './PasswordForgotConstants';
import PasswordForgot from './PasswordForgot';


export default class PasswordForgotHoc extends PureComponent {
	static propTypes = {
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
	};

	state = {
		email: '',
		submitStatus: SUBMIT_STATUSES.IDLE,
	};

	componentWillUnmount () {
		this.state = null;
	}

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleSubmit = (event) => {
		event.preventDefault();

		this._resetPassword();
	};

	/**
	 * @param {string} email
	 * @private
	 */
	_handleEmailChange = (email) => {
		this.setState({
			email,
		});
	};

	async _resetPassword () {
		try {
			this.setState({
				submitStatus: SUBMIT_STATUSES.IN_PROGRESS,
			});

			await authApi.resetPassword(this.state.email);

			if ( this.state === null ) {
				return;
			}

			this.setState({
				submitStatus: SUBMIT_STATUSES.SUCCESS,
			});
		}
		catch (error) {
			if ( this.state === null ) {
				return;
			}

			this.setState({
				submitStatus: SUBMIT_STATUSES.FAILED,
			});
		}
	}

	render () {
		return (
			<PasswordForgot
				email={this.state.email}
				submitStatus={this.state.submitStatus}
				onEmailChange={this._handleEmailChange}
				onSubmit={this._handleSubmit}
			/>
		);
	}
}
