import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import axios from 'axios';

import { initErrorCatcher } from './appUtils/errors';

import { setRuntimeConfig, getRuntimeConfig } from './appUtils/runtimeConfig';

import { setLocaleFromCookies } from './appUtils/locale';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import mainConfig from './configs/mainConfig';

import { createStore } from './services/store';
import { init as initLabelService } from './services/labels';
import { setStorageService, Storage } from './services/storage';
import { isSmallScreen } from './appUtils/mobileUtils'
import api from './api/instance'
import './services/events';

import Authorization from './components/Authorization';
import PasswordReset from './components/PasswordReset';
import PasswordForgot from './components/PasswordForgot';
import SignIn from './components/SignIn/SignInHoc';
import ExternalAuth from './components/ExternalAuth';
import Entry from './components/Entry';
import green from '@material-ui/core/colors/green';

import 'moment/locale/ru';

import i18nInit from './i18n';

import './index.css';


const theme = createMuiTheme({
	typography: {
		fontSize: 13,
	},
	palette: {
		primary: green,
		type: 'dark', // Switching the dark mode on is a single property value change.
	},
});


i18nInit();


// TODO add i18n trReact
// TODO wrap into a function and give a new chance to load config again
/**
 * Loads runtime config to setup the application.
 */
axios.get(`/configs/${process.env.REACT_APP_CONFIG_URL}?r=${Math.random()}`)
	.then((result) => {
		if ( typeof result.data !== 'object' ) {
			throw new Error('Error runtime config loading');
		}

		setRuntimeConfig(result.data);
		if ( getRuntimeConfig()['error-catcher'].enabled === true ) {
			initErrorCatcher();
		}

		setLocaleFromCookies();

		const storage = new Storage('dentiai__app');
		setStorageService(storage);
		
		if ( isSmallScreen() ) {
			const appContentEl = document.querySelector('#app-content');
			appContentEl.classList.add('m-small-screen');
		}
		
		const store = createStore();
		initLabelService(store);
		
		ReactDOM.render(
			(<Provider store={store}>
				<Entry>
					<MuiThemeProvider theme={theme}>
							<Router basename={mainConfig.URL_BASE_PATH}>
								<Switch>
									<Route path={'/external_auth/:token/:returnUrl?'} component={ExternalAuth} />
									<Route path={'/sign_in'} component={SignIn} />
									<Route path={'/reset_password'} component={PasswordReset}/>
									<Route path={'/forgot_password'} component={PasswordForgot}/>
									<Route component={Authorization} />
								</Switch>
							</Router>

					</MuiThemeProvider>
				</Entry>
			</Provider>),
			document.getElementById('app-content')
		);
	})
	.catch((error) => {
		console.error(error);
	});
