import React, { Component } from 'react';
import { connect } from 'react-redux';

import mainConfig from '../../configs/mainConfig';

import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';

import Overlay from '../Overlay';

import { getDictionary } from '../../appUtils/locale';

import './styles/DrawSecondaryBoxMode.css';


const i18n = getDictionary('editor');

const baseCssClassName = 'disable-layout-draw-secondary-box-mode';


class DrawSecondaryBoxMode extends Component {
	_handleDisableLayersClick = () => {
		this.props.onResetMode();
	};
	
	render () {
		if (
			this.props.editorMode !== mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX &&
			this.props.editorMode !== mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX &&
			this.props.editorMode !== mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE
		) {
			return null;
		}
		
		return (
			<div
				className={baseCssClassName}
				title={i18n('overlay.cancel_action')}
				onClick={this._handleDisableLayersClick}
			>
				<Overlay />
			</div>
		);
	}
}

export default connect((state) => ({
	editorMode: editorSelectors.selectEditor(state).editorMode,
}), (dispatch => ({
	onResetMode: () =>
		dispatch(editorActions.resetMode()),
})))(DrawSecondaryBoxMode);
