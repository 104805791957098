/**
 * @param {StoreState} state
 *
 * @return {ImagesLabelsStoreState}
 */
const selectImagesLabels = (state) => (state.imagesLabels || {});

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionImageId} options.imageId
 *
 * @return {LabelId[]}
 */
const selectImageLabelsByImageId = (state, options) => (selectImagesLabels(state)[options.imageId] || []);


export default {
	selectImagesLabels,
	selectImageLabelsByImageId,
};
