import imagesCollectionsSelectors from '../selectors/imagesCollectionsSelectors';
import imagesSelectors from '../selectors/imagesSelectors';


/**
 * @param {StoreState} state
 * @return {ImagesStoreState}
 */
const selectImages = (state) => ( state.images || {} );

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {string} options.id
 * @return {CollectionImage}
 */
const selectImageById = (state, options) => selectImages(state)[options.id];

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionImageHashName} options.hashname
 *
 * @return {CollectionImage|null}
 */
const selectImageByHashName = (state, options) => {
	const images = selectImages(state);

	for (let p in images) {
		if ( images.hasOwnProperty(p) ) {
			if ( images[p].hashname === options.hashname ) {
				return images[p];
			}
		}
	}

	return null;
};

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionHashName} options.collectionHashName
 * @param {CollectionImageHashName} options.imageHashName
 *
 * @return {CollectionImage|null}
 */
const selectImageByHashNameInCollection = (state, options) => {
	let result = null;

	imagesCollectionsSelectors.selectImagesIdsByCollectionId(state, {
		collectionId: options.collectionHashName,
	})
		.forEach((imageId) => {
			const image = imagesSelectors.selectImageById(state, { id: imageId });

			if ( image.hashname === options.imageHashName ) {
				result = image;
			}
		});

	return result;
};


export default {
	selectImages,
	selectImageById,
	selectImageByHashName,
	selectImageByHashNameInCollection,
};
