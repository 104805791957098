import React from 'react';
import { connect } from 'react-redux';

import labelsActions from '../../../../modules/labels/actions/labelsActions';
import editorActions from '../../../../actions/editorActions';
import { removeLabel, swapTeeth, updateLabelData, approveMissingTooth } from '../../actions';

import userSelectors from '../../../../selectors/userSelectors';
import proceduresSelectors from '../../../../selectors/proceduresSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';

import labelGetters from '../../../../modules/labels/selectors/labelGetters';

import { ResolverFindings } from '../findings';



export default connect((state, { teeth, teethLabelMap, labels }) => {
	const user = userSelectors.selectUserData(state);
	const isTreatmentPlanningEnabled = user.is_treatment_planning_enabled;
	const procedures = proceduresSelectors.selectProcedures(state);

	return {
		isTreatmentPlanningEnabled,
		procedures,
		onGetLabelIdByToothKey: (toothKey) => teethLabelMap[toothKey],
		onGetAvailableTeethToMove: (toothKey) => {
			return teeth
				.filter((tooth) => tooth.isMissing === false || tooth.toothKey === toothKey)
				.map((tooth) => ({
					value: tooth.toothKey,
					label: tooth.localizedToothKey,
				}));
		},
		onFindLabelTooth: (label) => {
			const toothLabelId = labelsSelectors.selectFindingParent(state, { labelId: labelGetters.getLabelId(label) });

			if ( typeof toothLabelId !== 'undefined' ) {
				return labelsSelectors.selectLabelById(state, { labelId: toothLabelId });
			}

			return undefined;
		},
	};
}, (dispatch) => ({
	onUpdateLabelData: (data) => dispatch(updateLabelData(data)),
	onRemoveLabel: (data) => dispatch(removeLabel(data)),
	onMoveLabel: (data) => {
		dispatch(labelsActions.moveLabel(data));
		dispatch(editorActions.updateData({
			data: {
				selectedToothKey: data.newToothKey,
			},
		}));
	},
	onSwapTeeth: (data) => {
		if ( !data.toothKey || !data.newToothKey ) {
			return;
		}
		dispatch(swapTeeth(data));
		dispatch(editorActions.updateData({
			data: {
				selectedToothKey: data.newToothKey,
			},
		}));
	},
	onApproveMissingTooth: (label) => {
		dispatch(approveMissingTooth({
			labelId: labelGetters.getLabelId(label),
			toothKey: label.toothKey,
		}));
	},
	onGetToothChildren: (tooth) => dispatch((dispatch, getState) => labelChildrenSelectors.selectLabelChildrenByLabelId(getState(), { labelId: labelGetters.getLabelId(tooth) })),
}))(ResolverFindings);
