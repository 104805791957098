import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getService } from '../../services/upload-image';
import UploadButton from '../../components/ImageControls/UploadButton';


export default class UploadButtonConnector extends PureComponent {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	_handleFileChange = (image) => {
		getService().uploadImage(image);
	};

	render () {
		return (
			<UploadButton onFileChange={this._handleFileChange} />
		);
	}
}
