export function formatPrice (price) {
	price = String(price);
	const length = price.length;
	const result = [];
	for (let i = length - 1, j = 1; 0 <= i; i--, j++) {
		if ( j === 4 ) {
			j = 1;
			result.push(',');
		}
		result.push(price[i]);
	}

	return `$${result.reverse().join('')}`;
}
