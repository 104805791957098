import lodashGet from 'lodash/get';


/**
 * @implements {HelpMessenger}
 */
export default class IntercomHelpMessenger {
	/**
	 * @param {Object} options
	 * @param {string} options.appId
	 */
	constructor (options = {}) {
		this._options = {
			...options,
		};
		this._loadScript()
			.catch(() => {
				// do nothing now
			});
	}

	setup (options) {
		window.intercomSettings = {
			hide_default_launcher: true,
		};

		this._exec('boot', {
			app_id: this._options.appId,
			custom_launcher_selector: lodashGet(options, 'launcherSelectorCssClassName'),
			user_id: lodashGet(options, 'user.username', null),
			email: lodashGet(options, 'user.email', null),
			name: `${lodashGet(options, 'user.first_name', '')} ${lodashGet(options, 'user.last_name', '')}`,
			organization: lodashGet(options, 'user.clinic', null),
			country: lodashGet(options, 'user.country', null),
		});
	}

	update (options) {
		window.intercomSettings = {
			custom_launcher_selector: options.cssClassName,
		};

		this._exec('update', {
			custom_launcher_selector: lodashGet(options, 'launcherSelectorCssClassName'),
			user_id: lodashGet(options, 'user.username', null),
			email: lodashGet(options, 'user.email', null),
			name: `${lodashGet(options, 'user.first_name', '')} ${lodashGet(options, 'user.last_name', '')}`,
			organization: lodashGet(options, 'user.clinic', null),
			country: lodashGet(options, 'user.country', null),
		});
	}

	teardown() {
		this._exec('shutdown');
	}

	/**
	 * @param {string} name
	 * @param {*} data
	 */
	sendEvent (name, data) {
		this._exec('trackEvent', name, data);
	}

	/**
	 * @param {string} imageLink
	 */
	sendAnalyzedEvent (imageLink) {
		this.sendEvent('clicked-analyze', { image_link: imageLink });
	}

	/**
	 * @param {string} imageLink
	 */
	sendUploadedImageEvent (imageLink) {
		this.sendEvent('uploaded-image', { image_link: imageLink });
	}

	/**
	 * @param {Object} options
	 */
	updateUserData (options) {
		this._exec('update', {
			user_id: lodashGet(options, 'username', null),
			email: lodashGet(options, 'email', null),
			name: `${lodashGet(options, 'first_name', '')} ${lodashGet(options, 'last_name', '')}`,
			organization: lodashGet(options, 'clinic', null),
			country: lodashGet(options, 'country', null),
		});
	}

	open () {}

	close () {}

	_exec () {
		window.Intercom.apply(window, arguments);
	}

	/**
	 * @return {Promise}
	 * @private
	 */
	_loadScript () {
		return new Promise((resolve, reject) => {
			const script = window.document.createElement('SCRIPT');
			script.innerHTML = `
				var APP_ID = "g0vzvxyd";
	
				window.intercomSettings = {
					app_id: APP_ID,
				};
				(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
			`;
			window.document.getElementsByTagName('HEAD')[0].appendChild(script);

			resolve();
		});
	}
}
