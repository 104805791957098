import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';
import { SHIFT_DIRECTION__LEFT, SHIFT_DIRECTION__RIGHT } from '../../constants/teethConstants';

import './styles/shiftNumberingButton.css'

const baseCssClassName = 'shift-numbering-button';
const buttonContentCssClassName = `${baseCssClassName}__content`;

export class ShiftNumberingButton extends PureComponent {
	static propTypes = {
	  direction: PropTypes.oneOf([SHIFT_DIRECTION__LEFT, SHIFT_DIRECTION__RIGHT]),
	};

	render () {
		const { direction, ...rest } = this.props;
		const contentClassNames = classnames(
			buttonContentCssClassName,
      direction === SHIFT_DIRECTION__LEFT ?
				`${buttonContentCssClassName}__m-left` :
				`${buttonContentCssClassName}__m-right`
    );
		return (
			<Button
				size={Button.AVAILABLE_SIZES.LARGE}
				theme={Button.AVAILABLE_THEMES.SECONDARY}
				onClick={this._handleClick}
				className={baseCssClassName}
				{...rest}
			>
				<div className={contentClassNames} />
			</Button>
		);
	}
}
