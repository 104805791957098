import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';


const propTypes = {
	modifiers: PropTypes.arrayOf(PropTypes.string.isRequired),
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	onDoubleClick: PropTypes.func,
};

const contextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};


function ContentRow (props, context) {
	const {
		modifiers,
		children,
		onClick,
		onDoubleClick,
		onMouseOver,
		onMouseOut,
		onRef,
		width,
		height,
	} = props;

	const style = {
		cursor: (onClick ? 'pointer' : 'ResolverFindings'),
		width,
		height,
	};

	const baseCssClassName = `${context.gridCssClassName}__content-row`;

	const className = classnames([ baseCssClassName ]
		.concat((modifiers || []).map((modifier) => `${baseCssClassName}__m-${modifier}`)));

	return (
		<div
			className={className}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onMouseEnter={onMouseOver}
			onMouseLeave={onMouseOut}
			ref={onRef}
			style={style}
		>
			{children}
		</div>
	);
}

ContentRow.propTypes = propTypes;
ContentRow.contextTypes = contextTypes;

export default ContentRow;
