export default class AclService {
	_permissions = {};

	setPermissions (permissions) {
		this._permissions = permissions;
	}

	/**
	 * @param {string} permission
	 * @return {boolean}
	 */
	checkPermission (permission) {
		return this._permissions[permission] === true;
	}
}

