import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRuntimeConfig } from '../../../../appUtils/runtimeConfig';

import { initService as initUploadImageService } from '../../../../services/upload-image';
import { get as getHelp } from '../../../../services/help';

import imagesActions from '../../../../actions/imagesActions';
import collectionsActions from '../../../../actions/collectionsActions';
import { changeFilters } from '../../actions';

import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';

import UploadImage from '../../../../components/UploadImage';


class ResolverUploadImageHoc extends Component {
	static propTypes = {
		onUploadImage: PropTypes.func.isRequired,
		onRemoveImage: PropTypes.func.isRequired,
		onSaveImageData: PropTypes.func.isRequired,
		onFlipImageHorizontal: PropTypes.func.isRequired,
		onFlipImageVertical: PropTypes.func.isRequired,
		onAddToCollection: PropTypes.func.isRequired,
		onGetCurrentCollection: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	_uploadImageService = null;

	constructor (props) {
		super(props);
		this._uploadImageService = initUploadImageService({
			uploadImage: this.props.onUploadImage,
			removeImage: this.props.onRemoveImage,
			saveImageData: this.props.onSaveImageData,
			flipImageHorizontal: this.props.onFlipImageHorizontal,
			flipImageVertical: this.props.onFlipImageVertical,
			rotateImageLeft: this.props.onRotateImageLeft,
			rotateImageRight: this.props.onRotateImageRight,
			addImageToCollection: this.props.onAddToCollection,
			getCurrentCollection: this.props.onGetCurrentCollection,
		});

		this._uploadImageService.getEvents().on('upload-file-saved', this._handleUploadFileSuccess);
	}

	componentWillUnmount () {
		if ( this._uploadImageService !== null ) {
			this._uploadImageService.getEvents().on('upload-file-saved', this._handleUploadFileSuccess);
			this._uploadImageService.destroy();
			this._uploadImageService = null;
		}
	}

	_handleUploadFileSuccess = ({ collectionHashName, imageHashName }) => {
		const help = getHelp();
		if ( help !== null ) {
			help.sendUploadedImageEvent(`${window.location.origin}/collections/${collectionHashName}/image/${imageHashName}/treatment_plan`);
		}
		window.location.href = `/collections/${collectionHashName}/image/${imageHashName}/treatment_plan`;
	};

	render () {
		return (
			<Fragment>
				{this.props.children}
				<UploadImage UploadService={this._uploadImageService} />
			</Fragment>
		);
	}
}

export default connect(() => ({
	onGetCurrentCollection: (storeState) => collectionsSelectors.selectCollectionById(storeState, {
		id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
	}),
}), (dispatch) => ({
	onSetFilters: (filters) => dispatch(changeFilters(filters)),
	onUploadImage: (image) => dispatch(imagesActions.uploadImage({ image })),
	onAddToCollection: (options) => dispatch(collectionsActions.addImageToCurrentCollection(options)),
	onSaveImageData: (options) => {
		return dispatch(imagesActions.saveImageData(options))
			.then(() => {
				if ( getRuntimeConfig()['auto-image-analysis'] ) {
					return dispatch(imagesActions.analyzeImage({
						id: options.imageId,
						isAnalysedByUser: false,
						skipResult: true,
					}))
						.catch((error) => {
							// do nothing
						});
				}

				return Promise.resolve();
			});
	},
	onRemoveImage: (options) => dispatch(imagesActions.removeImage(options)),
	onFlipImageHorizontal: (data) => dispatch(imagesActions.flipHorizontal(data)),
	onFlipImageVertical: (data) => dispatch(imagesActions.flipVertical(data)),
	onRotateImageLeft: (data) => dispatch(imagesActions.rotateLeft(data)),
	onRotateImageRight: (data) => dispatch(imagesActions.rotateRight(data)),
}))(ResolverUploadImageHoc);

