import mainConfig from '../../../configs/mainConfig';
import labelsUtils from '../../../appUtils/labelsUtils';
import { isShapeIsPresent } from '../../resolver/utils';

import currentCollectionSelectors from '../../../selectors/currentCollectionSelectors';
import imagesLabelsSelectors from './imagesLabelsSelectors';
import labelTagsSelectors from '../../label-tags/selectors/labelTagsSelectors';
import collectionsSelectors from '../../../selectors/collectionsSelectors';
import editorSelectors from '../../../selectors/editorSelectors';
import labelChildrenSelectors from "./labelChildrenSelectors";

import labelGetters from './labelGetters';
import labelTagGetter from '../../label-tags/selectors/labelTagGetter';


/**
 * @param {StoreState} state
 * @return {LabelsStoreState}
 */
const selectLabels = (state) => (state.labels || {});

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @return {Label}
 */
const selectLabelById = (state, options) => selectLabels(state)[options.labelId];

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {CollectionImageType} options.type
 * @param {boolean} options.onlyParents
 * @param {LabelClassId} options.classId
 * @param {boolean} options.excludeParent
 * @return {Array<{ classId: LabelClassId, localizedClassName: string, requiredLabel: boolean, hotKey: string }>}
 */
const selectAvailableLabelsByImageType = (state, options) => {
	const availableClasses = currentCollectionSelectors.selectAvailableLabelsForImageType(state, options);

	return availableClasses
		.filter((classId) => mainConfig.BONE_LOSS_LINES.includes(classId) === false)
		.map((classId) => ({
			classId,
			localizedClassName: labelsUtils.getLocalizedLabelNameByClassId(classId),
			requiredLabel: labelsUtils.isShapeRequiredForClass(classId),
			hotKey: labelsUtils.getHotKeyByClassId({
				storeState: state,
				classId,
				action: 'add_labels',
			}),
		}));
};

// @todo move to tags
/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelClassId} options.classId
 * @return {Array<{ key: LabelTag.key, label: string, hotKey: string, canAdd: boolean }>}
 */
const selectAvailableTagsByClassId = (state, options) => {
	const availableTags = currentCollectionSelectors.selectAvailableTagsForClass(state, {
		classId: options.classId,
	});

	return availableTags.map(({ tag, canAdd }) => {
		return {
			key: tag.key,
			label: tag.readable_name,
			hotKey: tag.hotkey,
			canAdd,
		};
	});
};

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {string} options.toothKey
 * @return {Label|null}
 */
const selectLabelByToothKey = (state, options) => {
	const labels = selectLabels(state);

	for (const labelId in labels) {
		if ( labels.hasOwnProperty(labelId) ) {
			const label = labels[labelId];

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
				if ( tags && tags.length > 0 && labelTagGetter.getTagKey(tags[0]) === options.toothKey ) {
					return label;
				}
			}
		}
	}

	return null;
};

/**
 * @param {StoreState} state
 * @param {Object} [params={}]
 * @param {boolean} [params.checkShape=true]
 * @return {Label|null}
 */
const selectTeeth = (state, params = {}) => {
	const { checkShape = true } = params;
	const currentCollection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});

	return currentCollection.images.reduce((result, image) => {
		result[image.hashname] = [];
		imagesLabelsSelectors.selectImageLabelsByImageId(state, { imageId: image.id })
			.forEach((labelId) => {
				const label = selectLabelById(state, { labelId });
				if ( labelGetters.getLabelClassId(label) !== 'tooth' ) {
					return;
				}

				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
				if ( tags && tags.length > 0 ) {
					const shape = label.shapes[image.hashname];
					if ( checkShape === false || isShapeIsPresent(shape) ) {
						result[image.hashname].push(Number(labelTagGetter.getTagKey(tags[0])));
					}
				}
			});

		return result;
	}, {});
};

/**
 * @param {StoreState} state
 * @param {string} labelId
 *
 * @return {Label|undefined}
 */
function selectFindingParent (state, { labelId }) {
	const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
	const labelParentMap = {};
	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	return labelParentMap[labelId];
}

export default {
	selectLabels,
	selectLabelById,
	selectAvailableLabelsByImageType,
	selectAvailableTagsByClassId,
	selectLabelByToothKey,
	selectTeeth,
	selectFindingParent,
};
