import mainConfig from '../../configs/mainConfig';


/**
 * @typedef {Object} CanvasViewportData
 *
 * @property {number} zoom
 * @property {number} offsetX
 * @property {number} offsetY
 */


let canvasEl = null;

/**
 * @type {CanvasViewportData}
 */
let viewportData = null;

export function setCanvas (el) {
	canvasEl = el;
}

export function getCanvas () {
	return canvasEl;
}

/**
 * @param {CanvasViewportData} data
 */
export function setViewportData (data) {
	viewportData = data;
}

/**
 * @returns {CanvasViewportData}
 */
export function getViewportData () {
	return viewportData;
}

export function isPointVisible ([ x, y ]) {
	const canvas = getCanvas();

	if ( !canvas ) {
		return false;
	}
	const canvasRect = canvas.getBoundingClientRect();
	const canvasWidth = canvasRect.right - canvasRect.left;
	const canvasHeight = canvasRect.bottom - canvasRect.top;

	const preparedX = x * viewportData.zoom - viewportData.offsetX;
	const preparedY = y * viewportData.zoom - viewportData.offsetY;

	return (
		preparedX > 0 &&
		preparedX < canvasWidth &&
		preparedY > 0 &&
		preparedY < canvasHeight
	);
}
