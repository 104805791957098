import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import gridUtils from '../../appUtils/gridUtils';

import { formatPrice } from './StatsUtils';

import Grid from '../Grid';

import { getDictionary } from '../../appUtils/locale';

import './styles/StatsGrid.css';


const baseCssClassName = 'stats-grid';
const collectionNameCssClassName = `${baseCssClassName}-collection-name`;

const getGridContentRowKey = (rowData, index) => `${rowData.collectionName}_${index}`;

const noop = () => {};

const i18n = getDictionary('stats');
const i18nFindings = getDictionary('findings');


class CollectionNameCell extends Component {
	static propTypes = {
		collectionHashName: PropTypes.string.isRequired,
		collectionName: PropTypes.string.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	_handleClick = () => {
		this.context.router.history.push(`/collections/${this.props.collectionHashName}`);
	}

	render () {
		return (
			<div className={collectionNameCssClassName} onClick={this._handleClick}>{this.props.collectionName}</div>
		);
	}
}

export default class StatsGrid extends Component {
	static propTypes = {
		mode: PropTypes.string.isRequired,
		columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	}

	_getColumns () {
		const columns = [];

		this.props.columns.forEach((column, i) => {
			switch (column) {
				case 'collection_name':
					columns.push(gridUtils.makeGridColumn({
						key: 'collection_name',
						width: 200,
						headerValue: i18n('headers.collection'),
						getValue: (rowData) => (
							<CollectionNameCell
								collectionHashName={rowData.collectionHashName}
								collectionName={rowData.collectionName}
							/>
						),
					}));
					break;

				case 'pan_images_count':
					columns.push(gridUtils.makeGridColumn({
						key: 'pan_images',
						width: 100,
						headerValue: i18n('headers.pan_images'),
						getValue: (rowData) => rowData.panImages,
					}));
					break;

				case 'xray_images_count':
					columns.push(gridUtils.makeGridColumn({
						key: 'xray_images',
						width: 100,
						headerValue: i18n('headers.xray_images'),
						getValue: (rowData) => rowData.xrayImages,
					}));
					break;

				default:
					if ( this.props.mode === 'pathologies' ) {
						columns.push(gridUtils.makeGridColumn({
							key: `${i}.value`,
							width: 100,
							headerValue: (
								<div>
									{i18nFindings(`labels.${column}`)}<br/>
									{i18n('headers.value')}
								</div>
							),
							headerContextTitle: '',
							getValue: (rowData) => formatPrice(lodashGet(rowData, `findings.${column}.potential_value`, 0)),
						}));
					}

					columns.push(gridUtils.makeGridColumn({
						key: `${i}.detected`,
						width: 100,
						headerValue: (
							<div>
								{i18nFindings(`labels.${column}`)}<br/>
								{i18n('headers.detected')}
							</div>
						),
						headerContextTitle: '',
						getValue: (rowData) => lodashGet(rowData, `findings.${column}.ai_found`, 0),
					}));
					columns.push(gridUtils.makeGridColumn({
						key: `${i}.dentist_added`,
						width: 100,
						headerValue: (
							<div>
								{i18nFindings(`labels.${column}`)}<br/>
								{i18n('headers.dentist_added')}
							</div>
						),
						headerContextTitle: '',
						getValue: (rowData) => lodashGet(rowData, `findings.${column}.human_added`, 0),
					}));
					columns.push(gridUtils.makeGridColumn({
						key: `${i}.removed`,
						width: 100,
						headerValue: (
							<div>
								{i18nFindings(`labels.${column}`)}<br/>
								{i18n('headers.removed')}
							</div>
						),
						headerContextTitle: '',
						getValue: (rowData) => lodashGet(rowData, `findings.${column}.ai_deleted`, 0),
					}));
					break;
			}
		});

		return columns;
	}

	render () {
		const { data } = this.props;

		return (
			<Grid
				cssClassName={baseCssClassName}
				minWidth={1200}
				columns={this._getColumns()}
				data={data}
				headerRowHeight={37}
				contentRowHeight={37}
				getContentRowKey={getGridContentRowKey}
				getContentRowModifiers={noop}
			/>
		);
	}
}

