import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import imageUrl from './images/logo.png';
import imageDarkUrl from './images/logo-dark.png';

import './styles/Logo.css';


const baseCssClassName  = 'logo';
const imageCssClassName  = `${baseCssClassName}__image`;

const themes = {
	light: 'light',
	dark: 'dark',
};

export default class Logo extends PureComponent {
	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		theme: PropTypes.oneOf(Object.values(themes).isRequired),
	};

	static themes = themes;

	static defaultProps = {
		width: null,
		height: 40,
		theme: themes.light,
	};

	render () {
		return (
			<div className={baseCssClassName}>
				<img
					className={imageCssClassName}
					src={this.props.theme === themes.light ? imageUrl : imageDarkUrl}
					width={this.props.width}
					height={this.props.height}
					alt={'Logo'}
				/>
			</div>
		);
	}
}
