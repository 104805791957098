import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashUniq from 'lodash/uniq';

import classnames from 'classnames';
import moment from 'moment';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';
import { locale, setLocaleToCookies } from '../../appUtils/locale';

import { IMAGE_TYPE__BITEWING, IMAGE_TYPE__PERIAPICAL } from '../../constants/imageConstants';

import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelsUtils from '../../appUtils/labelsUtils';

import mainConfig from '../../configs/mainConfig';
import commonUtils from '../../appUtils/common';

import dateUtils from '../../appUtils/dateUtils';
import pathUtils from '../../appUtils/pathUtils';

import userActions from '../../actions/userActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';

import userSelectors from '../../selectors/userSelectors';

import labelChildrenSelectors from '../../modules/labels/selectors/labelChildrenSelectors';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';

import currentCollectionSelectors from "../../selectors/currentCollectionSelectors";


import FormLabel from '../FormLabel';

import arrowDownImagePath from './images/arrow-down.png';
import arrowUpImagePath from './images/arrow-up.png';

import imageUtils from '../../appUtils/imageUtils';
import teethUtils from "../../appUtils/teeth/teethUtils";
import { getDictionary } from '../../appUtils/locale';

// import ReportHeader from './ReportHeader';
import { message } from '../../services/popup';
import ReportSpecialist from './ReportSpecialist';

import './styles/Report.css';


const CANVAS_OFFSET_Y = 16;

const i18n = getDictionary('report');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'report';
const wrapperCssClassName = `${baseCssClassName}-wrapper`;
const logoCssClassName = `${baseCssClassName}-logo`;
const snapshotLinkCssClassName = `${baseCssClassName}__snapshot-link`;
const reportLinkCssClassName = `${baseCssClassName}__report-link`;
const infoCssClassName = `${baseCssClassName}-info`;
const patientCssClassName = `${baseCssClassName}__patient`;
const patientNameCssClassName = `${baseCssClassName}__patient-name`;
const patientInfoCssClassName = `${baseCssClassName}__patient-info`;
const patientInfoItemCssClassName = `${baseCssClassName}__patient-info-item`;

const findingsTitleCssClassName = `${baseCssClassName}__findings-title`;
const findingsCssClassName = `${baseCssClassName}__findings`;
const findingCssClassName = `${baseCssClassName}__finding`;
const findingDiagnosisCssClassName = `${baseCssClassName}__finding-diagnosis`;
const findingCommentCssClassName = `${baseCssClassName}__finding-comment`;
const findingToothKeyCssClassName = `${baseCssClassName}__finding-tooth-key`;
const findingToothKeyControlCssClassName = `${baseCssClassName}__finding-tooth-key-control`;
const findingColumnCssClassName = `${baseCssClassName}__finding-column`;

const teethNumbersCssClassName = `${baseCssClassName}-teeth-numbers`;
const teethNumbersTitleCssClassName = `${teethNumbersCssClassName}__title`;
const teethNumbersControlCssClassName = `${teethNumbersCssClassName}__control`;

const preparedCssClassName = `${baseCssClassName}__prepared`;
const preparedHighlightCssClassName = `${baseCssClassName}__prepared-highlight`;
const poweredByCssClassName = `${baseCssClassName}__powered-by`;

const controlsCssClassName = `${baseCssClassName}-controls`;
const controlsPrintButtonCssClassName = `${controlsCssClassName}__print-button`;

const userInfoCssClassName = `${baseCssClassName}__user-info`;
const reportNameCssClassName = `${baseCssClassName}-name`;
const footerCommentCssClassName = `${baseCssClassName}-footer-comment`;

const specCssClassName = `${baseCssClassName}-spec`;
const specItemCssClassName = `${baseCssClassName}-spec-item`;
const specAddCssClassName = `${baseCssClassName}-spec-add`;
const specRemoveCssClassName = `${baseCssClassName}-spec-remove`;

const canvasWrapperCssClassName = `${baseCssClassName}__canvas-wrapper`;
const canvasCssClassName = `${baseCssClassName}__canvas`;

const summaryCssClassName = `${baseCssClassName}-summary`;
const summaryTitleCssClassName = `${summaryCssClassName}__title`;
const summaryTextCssClassName = `${summaryCssClassName}__text`;

const combinedFindingsCssClassName = `${baseCssClassName}-combined-findings`;


class ReportXRAY extends Component {
	static propTypes = {
		reportType: PropTypes.string.isRequired,
		labels: PropTypes.arrayOf(PropTypes.object).isRequired,
		imageId: PropTypes.string.isRequired,
		currentImageId: PropTypes.string,
		currentImage: PropTypes.object,
		isShared: PropTypes.bool.isRequired,
		defaultTeethNumbers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._screenImage = null;
		this._arrowDownImage = null;
		this._arrowUpImage = null;

		this._canvasEl = null;

		this._doctorsId = 0;

		this.state = {
			screenImageLoaded: false,
			arrowUpImageLoaded: false,
			arrowDownImageLoaded: false,
			doctors: [],
			patient: {
				fullName: '',
				age: '',
				screening_date: '',
			},
			showLinks: ( 'is_report_urls' in props.user
					? props.user.is_report_urls
					: true
			),
			teethLabelsMap: {},
		};
	}

	componentDidMount () {
		this._checkImage(this.props);
	}

	componentWillUnmount () {
		this.state = null;
	}

	componentDidUpdate () {
		const {
			screenImageLoaded,
			arrowDownImageLoaded,
			arrowUpImageLoaded
		} = this.state;

		if (!screenImageLoaded || !this._screenImage ||
			!arrowDownImageLoaded || !this._arrowDownImage ||
			!arrowUpImageLoaded || !this._arrowUpImage
		) {
			return null;
		}

		const {
			canvasWidth,
			canvasHeight,
			zoom,
		} = this.state;

		const canvas = this._canvasEl;
		const image = this._screenImage;
		const context = canvas.getContext('2d');

		canvas.width = canvasWidth;
		canvas.height = canvasHeight + (CANVAS_OFFSET_Y * 2);
		canvas.style.width = canvasWidth + 'px';
		canvas.style.height = canvasHeight + (CANVAS_OFFSET_Y * 2) + 'px';

		context.fillStyle = "#fff";
		context.fillRect(0, 0, canvasWidth, canvasHeight + (CANVAS_OFFSET_Y * 2));

		context.drawImage(
			image,
			0,
			CANVAS_OFFSET_Y,
			canvasWidth,
			canvasHeight,
		);

		const imageCenterY = ((canvasHeight + (CANVAS_OFFSET_Y * 2)) / 2);
		const topRow = [];
		const bottomRow = [];

		this.props.labels.forEach((label) => {
			const box = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(label));
			const boxCenterY = Math.floor((box.top + ((box.bottom - box.top) / 2)) * zoom) + CANVAS_OFFSET_Y;

			if ( boxCenterY < imageCenterY ) {
				topRow.push(label);
			}
			else {
				bottomRow.push(label);
			}
		});

		const keyIndicatorRadius = 7;

		const drawShapes = (row, position) => {
			const rowLength = row.length;
			const frameWidth = canvasWidth / rowLength;

			row.forEach((label, i) => {
				const box = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(label));

				const boxCenterX = Math.floor((box.left + ((box.right - box.left) / 2)) * zoom);
				const boxCenterY = Math.floor((box.top + ((box.bottom - box.top) / 2)) * zoom) + CANVAS_OFFSET_Y;

				const posX = Math.floor(i * frameWidth);

				let posY = CANVAS_OFFSET_Y / 2;

				if ( position === 'bottom' ) {
					posY = Math.floor(canvasHeight + CANVAS_OFFSET_Y + ( CANVAS_OFFSET_Y / 2));
				}

				const indicatorCenterX = posX + Math.floor(frameWidth / 2);

				context.beginPath();
				context.lineWidth = 1;
				context.strokeStyle = labelsUtils.getLabelColor(label);
				// context.moveTo(posX + frameWidth, posY);
				// context.lineTo(posX + frameWidth, posY + (keyIndicatorRadius * 2));

				context.moveTo(boxCenterX, boxCenterY);
				context.lineTo(indicatorCenterX, posY);

				context.closePath();
				context.stroke();


				// context.beginPath();
				// context.strokeStyle = box.isPathology ? '#d0011b' : '#b0db72';
				// context.moveTo(posX + frameWidth, posY);
				// context.lineTo(posX + frameWidth, posY + (keyIndicatorRadius * 2));

				// context.moveTo(posX, posY);
				// context.lineTo(posX + frameWidth, posY);
				// context.lineTo(posX + frameWidth, posY + (keyIndicatorRadius * 2));
				// context.lineTo(posX, posY + (keyIndicatorRadius * 2));
				//
				// context.closePath();
				// context.stroke();

				// context.drawImage(
				// 	this._arrowUpImage,
				// 	posX,
				// 	shapeCenterY + CANVAS_OFFSET_Y,
				// 	this._arrowUpImage.width,
				// 	this._arrowUpImage.height,
				// );



				context.beginPath();
				context.arc(indicatorCenterX, posY, keyIndicatorRadius, 0, 2 * Math.PI, false);
				context.fillStyle = '#fff';
				context.fill();

				context.lineWidth = 2;
				context.strokeStyle = labelsUtils.getLabelColor(label);
				context.stroke();

				context.closePath();

				context.font = 'bold 12px Monospace';
				context.fillStyle = '#000';
				context.fillText(String.fromCharCode(label.symbol), indicatorCenterX - 3, posY + 4);

				// const boxStartX = box.startX * zoom;
				// const boxStartY = (box.startY * zoom) + CANVAS_OFFSET_Y;
				// const boxWidth = Math.floor( box.endX - box.startX ) * zoom;
				// const boxHeight = Math.floor( box.endY - box.startY ) * zoom;

				// context.beginPath();
				// context.moveTo(boxStartX, boxStartY);
				// context.lineTo(boxStartX + boxWidth, boxStartY);
				// context.lineTo(boxStartX + boxWidth, boxStartY + boxHeight);
				// context.lineTo(boxStartX, boxStartY + boxHeight);
				// context.closePath();
				// context.stroke();
			});
		};

		drawShapes(topRow, 'top');
		drawShapes(bottomRow, 'bottom');
	}

	_handleLocaleChange = (event) => {
		const language = event.target.value;

		if ( this.props.isAuthenticated ) {
			this.props.onUpdateUserProfile({
				data: {
					...this.props.user,
					language,
				},
			})
				.then(() => {
					setLocaleToCookies(language);

					if ( !this.state ) {
						return;
					}

					window.location.reload(true);
				})
				.catch((error) => {
					if ( !this.state ) {
						return;
					}
					
					message({
						title: i18nShared('error.title'),
						titleIcon: 'error',
						message: i18n('errors.language'),
					});
				});
		}
		else {
			setLocaleToCookies(event.target.value);
			window.location.reload(true);
		}
	}

	_handlePatientFullNameChange = (event) => {
		this.setState({
			patient: {
				...this.state.patient,
				fullName: event.currentTarget.value,
			},
		});
	}

	_handlePatientAgeScreeningDateChange = (event) => {
		this.setState({
			patient: {
				...this.state.patient,
				screening_date: event.currentTarget.value,
			},
		});
	}

	_handlePrintButtonClick = () => {
		window.print();
	}

	_handleShowLinksChange = () => {
		this.setState({
			showLinks: !this.state.showLinks,
		});
	}

	_handleSpecAdd = () => {
		this.setState((prevState) => ({
			doctors: [
				...prevState.doctors,
				this._doctorsId++,
			],
		}));
	}

	_handleCanvasRef = (el) => {
		if ( !el ) {
			return;
		}

		this._canvasEl = el;

		this.setState({
			canvasWidth: this._canvasEl.offsetWidth,
			canvasHeight: this._canvasEl.offsetHeight,
		});
	}

	_handleImageLoad = (event) => {
		if ( !this.state ) {
			return;
		}

		const {
			canvasWidth,
			canvasHeight,
		} = this.state;

		let zoom = this.state.zoom;

		const nextState = {
			screenImageLoaded: true,
		};

		const image = this._screenImage;

		const imageNaturalWidth = image.naturalWidth;
		const imageNaturalHeight = image.naturalHeight;

		const newZoom = imageUtils.getDefaultZoom({
			canvasWidth,
			canvasHeight: Math.min(canvasHeight, 400),
			imageWidth: imageNaturalWidth,
			imageHeight: imageNaturalHeight,
		});

		if ( newZoom && zoom !== newZoom ) {
			zoom = newZoom;
		}

		nextState.imageOriginalWidth = imageNaturalWidth;
		nextState.imageOriginalHeight = imageNaturalHeight;
		nextState.zoom = zoom;

		if ( imageNaturalWidth < canvasWidth && imageNaturalHeight < canvasHeight ) {
			nextState.canvasWidth = imageNaturalWidth;
			nextState.canvasHeight = imageNaturalHeight;
		}
		else {
			nextState.canvasWidth = imageNaturalWidth * zoom;
			nextState.canvasHeight = imageNaturalHeight * zoom;
		}

		this.setState(nextState);
	}

	_handleError = () => {
		if ( !this.state ) {
			return;
		}

		// todo show error
	}

	_checkImage (props) {
		this._loadScreenImage();
		this._loadArrowsImage();
	}

	_loadScreenImage () {
		const image = new Image();
		image.src = this.props.currentImage.image_url;
		image.onload = () => {
			this._screenImage = image;
			this._handleImageLoad();
		};
	}

	_loadArrowsImage () {
		const arrowDownImage = new Image();
		arrowDownImage.onload = () => {
			this._arrowDownImage = arrowDownImage;
			this.setState({
				arrowDownImageLoaded: true,
			});
		};
		arrowDownImage.src = arrowDownImagePath;

		const arrowUpImage = new Image();
		arrowUpImage.onload = () => {
			this._arrowUpImage = arrowUpImage;
			this.setState({
				arrowUpImageLoaded: true,
			});
		};
		arrowUpImage.src = arrowUpImagePath;
	}

	_renderFindings () {
		return (
			<div>
				<div className={findingsTitleCssClassName}>
					<div className={findingColumnCssClassName}>{i18n('tooth.title')}</div>
					<div className={findingColumnCssClassName}>{i18n('finding.title')}</div>
				</div>
				<div className={findingsCssClassName}>{this._renderDetailReport()}</div>
			</div>
		);
	}

	_renderTeethNumbers () {
		return (
			<div className={teethNumbersCssClassName}>
				<div className={teethNumbersTitleCssClassName}>{i18n('teeth-numbers.title')}:</div>
				<div className={teethNumbersControlCssClassName}>
					<input type={'text'} defaultValue={this.props.defaultTeethNumbers.join(',')} />
				</div>
			</div>
		);
	}

	_renderDetailReport () {
		const {
			screenImageLoaded,
			arrowDownImageLoaded,
			arrowUpImageLoaded
		} = this.state;
		if (!screenImageLoaded || !this._screenImage ||
			!arrowDownImageLoaded || !this._arrowDownImage ||
			!arrowUpImageLoaded || !this._arrowUpImage
		) {
			return null;
		}

		const isShowMeasureOfConfidence = getRuntimeConfig().report.show_measure_of_confidence;

		return (
			this.props.labels.map((label, i) => {
				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
				const extra = (isShowMeasureOfConfidence && typeof measureOfConfidence === 'number' && measureOfConfidence !== 1)
					? ` (${Math.floor(measureOfConfidence * 100)}%)`
					: null;

				return (
					<div key={i} className={findingCssClassName}>
						<div className={findingColumnCssClassName}>
							<div
								className={classnames([
									findingToothKeyCssClassName,
								])}
								style={{
									borderColor: labelsUtils.getLabelColor(label),
								}}
							>
								<input
									type={'text'}
									className={findingToothKeyControlCssClassName}
									defaultValue={label.toothKey || ''}
									onBlur={(event) => {
										const value = event.currentTarget.value;

										if ( value && !(/^[0-9]+$/.test(value)) ) {
											return;
										}

										this.setState((prevState) => {
											return {
												teethLabelsMap: {
													...prevState.teethLabelsMap,
													[labelGetters.getLabelId(label)]: value,
												},
											};
										});
									}}
								/>
							</div>
						</div>
						<div className={findingColumnCssClassName}>
							<div
								className={classnames([
									findingToothKeyCssClassName,
								])}
								style={{
									borderColor: labelsUtils.getLabelColor(label),
								}}
							>
								{String.fromCharCode(label.symbol)}
							</div>
						</div>
						<div className={findingDiagnosisCssClassName}>{labelsUtils.getLocalizedLabelName(label)}{extra}</div>
						<div className={findingCommentCssClassName}>
							<div className={`${findingCommentCssClassName}__control`}>
								<input type={'text'} placeholder={i18n('comment.placeholder')} />
							</div>
						</div>
					</div>
				);
			})
		);
	}

	_renderSpecialists () {
		const {
			isAuthenticated,
			user,
		} = this.props;

		const content = [
			(
				<div key={'add'} className={specAddCssClassName} onClick={this._handleSpecAdd}>+</div>
			)
		];

		if ( isAuthenticated ) {
			content.push(
				<div key={'iam'} className={specItemCssClassName}>
					<ReportSpecialist
						name={`${user.first_name} ${user.last_name}`}
						readOnly
					/>
				</div>
			);
		}

		if ( this.state.doctors.length > 0 ) {
			this.state.doctors.forEach((id) => {
				content.push(
					<div key={id} className={specItemCssClassName}>
						<div className={specRemoveCssClassName} onClick={() => {
							this.setState({
								doctors: this.state.doctors.filter((_id) => _id !== id),
							});
						}}>X</div>
						<ReportSpecialist />
					</div>
				);
			})
		}

		return (
			<div className={specCssClassName}>
				{content}
			</div>
		);
	}

	_renderCanvas () {
		const {
			zoom,
			imageOriginalWidth,
			imageOriginalHeight
		} = this.state;

		const sizeStyle = {};

		if ( imageOriginalWidth > 0 ) {
			sizeStyle.width = ( imageOriginalWidth * zoom );
		}
		if ( imageOriginalHeight > 0 ) {
			sizeStyle.height = ( imageOriginalHeight * zoom );
		}

		return (
			<div className={canvasWrapperCssClassName}>
				<canvas className={canvasCssClassName} ref={this._handleCanvasRef} />
			</div>
		)
	}

	_renderCombinedBoxes () {
		const teeth = {};
		this.props.labels.forEach((label) => {
			if ( this.state.teethLabelsMap[labelGetters.getLabelId(label)] ) {
				const toothKey = this.state.teethLabelsMap[labelGetters.getLabelId(label)];
				if ( !teeth[toothKey] ) {
					teeth[toothKey] = [];
				}

				teeth[toothKey].push(label);
			}
		});

		return (
			<div className={combinedFindingsCssClassName}>
				{Object.keys(teeth).map((toothKey) => {
					const findings = [];

					teeth[toothKey].forEach((label) => {
						findings.push(label);
					});

					return (
						<div
							key={toothKey}
							className={findingCssClassName}
						>
							<div
								className={findingToothKeyCssClassName}
								style={{
									borderColor: labelsUtils.getLabelColor(findings[0]),
								}}
							>{toothKey}</div>
							{findings.map((finding, i) => (
								<div key={i} className={findingDiagnosisCssClassName}> {labelsUtils.getLocalizedLabelName(finding)}{( i < ( findings.length - 1) ? ',' : '' )}</div>
							))}
						</div>
					);
				})}
			</div>
		);
	}
	
	_renderReportName () {
		switch (this.props.reportType) {
			case IMAGE_TYPE__BITEWING:
				return i18n('report_name.bitewing');
				
			case IMAGE_TYPE__PERIAPICAL:
				return i18n('report_name.periapical');
			
			default:
				return i18n('report_name.x-ray');
		}
	}

	_renderSummary () {
		return (
			<div className={summaryCssClassName}>
				<div className={summaryTitleCssClassName}>{i18n('summary.title')}:</div>
				{this._renderCombinedBoxes()}
				<textarea className={summaryTextCssClassName}></textarea>
			</div>
		);
	}

	render () {
		const {
			currentImage,
			user,
			snapshotLink,
			reportLink,
		} = this.props;

		const {
			showLinks,
		} = this.state;

		const birthdayValue = this.state.patient.age
			? this.state.patient.age
			: currentImage && currentImage.birthday
				? dateUtils.getAge(currentImage.birthday)
				: '';

		const genderValue = currentImage ? currentImage.gender || 'unknown' : '';

		const screeningDateValue = ( this.state.patient.screening_date
			? this.state.patient.screening_date
			: currentImage && currentImage.image_date
				? moment(currentImage.image_date).format('MM/YYYY')
				: ''
		);

		return (
			<div className={wrapperCssClassName}>
				<div className={baseCssClassName}>
					<div className={controlsCssClassName}>
						<FormLabel
							element={(
								<div className={`${controlsCssClassName}__locale-element`}>
									<select defaultValue={locale()} onChange={this._handleLocaleChange}>
										{
											mainConfig.AVAILABLE_LOCALES.map((data) =>
											<option key={data.key} value={data.key}>{data.name}</option>)
										}
									</select>
								</div>
							) }
							text={(<div className={`${controlsCssClassName}__locale-label`}>{i18n('language.label')}</div>)}
							textPosition={FormLabel.textPosition.LEFT}
						/>
						<div>
							<FormLabel
								element={(
									<div className={`${controlsCssClassName}__show-links-element`}>
										<input type={'checkbox'} onChange={this._handleShowLinksChange} checked={showLinks} />
									</div>
								)}
								text={(
									<div className={`${controlsCssClassName}__show-links-label`}>
										{i18n('show_links.label')}
									</div>
								)}
								textPosition={FormLabel.textPosition.LEFT}
							/>
						</div>
						<div className={controlsPrintButtonCssClassName}>
							<button onClick={this._handlePrintButtonClick}>{i18n('print_button')}</button>
						</div>
					</div>
					<div className={preparedCssClassName}>
						{i18n('prepared')} <span className={poweredByCssClassName}>Denti.<span className={preparedHighlightCssClassName}>AI</span></span>
					</div>
					{
						user && user.full_logo_url &&
						<div className={logoCssClassName}>
							<img src={user.full_logo_url} alt={''} />
						</div>
					}
					<div className={reportNameCssClassName}>
						<div className={`${reportNameCssClassName}__value`}>{this._renderReportName()}</div>
					</div>

					<table className={infoCssClassName}>
						<tbody>
						<tr>
							<td>
								<div className={patientCssClassName}>
									<div className={patientNameCssClassName}>
										<div className={`${infoCssClassName}__value`}>
											{this.state.patient.fullName}
										</div>
										<div className={`${infoCssClassName}__control`}>
											<input
												type={'text'}
												placeholder={i18n('fields.full_name.label')}
												onChange={this._handlePatientFullNameChange}
												value={this.state.patient.fullName}
											/>
										</div>
									</div>
								</div>

								<div className={patientInfoCssClassName}>
									{
										currentImage.patient_id &&
										<div className={patientInfoItemCssClassName}>
											{i18n('fields.patient_id.label')}:&nbsp;
											<span>{currentImage.patient_id}</span>
										</div>
									}
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.age.label')}:&nbsp;
										<span>{birthdayValue}</span>
									</div>
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.gender.label')}:&nbsp;
										<span>{i18nShared(`gender.${genderValue}`)}</span>
									</div>
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.screening_date.label')}:&nbsp;
										{
											Boolean(screeningDateValue) &&
											<span className={`${infoCssClassName}__value`}>{screeningDateValue}</span>
										}
										<input
											type={'text'}
											className={`${infoCssClassName}__control`}
											placeholder={i18n('fields.screening_date.placeholder')}
											style={{ width: 90 }}
											onChange={this._handlePatientAgeScreeningDateChange}
											value={screeningDateValue}
										/>
									</div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>

					{this._renderCanvas()}
					{this._renderTeethNumbers()}
					{this._renderFindings()}
					{this._renderSummary()}
					{
						showLinks &&
						<div className={snapshotLinkCssClassName}>
							<span>{i18n('snapshot_link')}</span>: <a href={snapshotLink}>{snapshotLink}</a>
						</div>
					}
					{
						showLinks &&
						<div className={reportLinkCssClassName}>
							<span>{i18n('report_link')}</span>: <a href={reportLink}>{reportLink}</a>
						</div>
					}
					<table className={userInfoCssClassName}>
						<tbody>
							<tr>
								<td>{moment().locale(locale()).format('L')}</td>
								<td>
									{this._renderSpecialists()}
								</td>
							</tr>
						</tbody>
					</table>
					<div
						className={footerCommentCssClassName}
						dangerouslySetInnerHTML={{ __html: i18n('footer.comment') }}
					/>
					<div
						dangerouslySetInnerHTML={{ __html: `
							<svg height="50" width="50" xmlns="http://www.w3.org/2000/svg" version="1.1">
								<defs>
									<pattern id="absent-tooth" patternUnits="userSpaceOnUse" width="50" height="50">
										<image xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHlJREFUeNrs0jEKgFAMBNG9UI4XyJlzCQ12IoiCfLeYrbac4mk71t0RUVUmX4ZN82XYNF+GTfNl2IQtbGELW9jCFrawhS1sYQtb2MIWtrCFLWxhC1vYwtYntjLTzpZh08mWVZ+uTQ59umn6sU9Pmtb36VXTmr7ZLsAARQq6079U0sEAAAAASUVORK5CYII=" x="0" y="0" width="50" height="50"></image>
									</pattern>
								</defs>
							</svg>
						`  }}
					/>
				</div>
			</div>
		);
	}
}

export default connect((state, { isShared, sharedHashname }) => {
	const user = userSelectors.selectUserData(state);
	const showAllClasses = editorSelectors.selectShowAllFindings(state),
		currentImageId = editorSelectors.selectCurrentImageId(state),
		currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const filteredClasses = editorSelectors.selectFilteredClassesForCurrentImage(state);
	const notationType = user.notation_type;
	const currentImage = currentImageId ? imagesSelectors.selectImageById(state, {
		id: currentImageId,
	}) : null;

	const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
	const labelParentMap = {};
	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	let defaultTeethNumbers = [];
	let labels = [];

	if ( currentImageId ) {
		if ( currentImage ) {
			let symbol = 65;

			labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
				imageId: currentImageId,
			})
				.reduce((result, labelId) => {
					const label = labelsSelectors.selectLabelById(state, {
						labelId,
					});

					if (
						(
							showAllClasses ||
							true === filteredClasses[labelGetters.getLabelClassId(label)]
						) &&
						labelGetters.getLabelIsConfirmed(label) &&
						mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(label)) === false
					) {
						if ( labelsUtils.labelIsTooth(label) ) {
							let toothKey = null;
							const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
							if ( tags && tags.length > 0 ) {
								toothKey = labelTagGetter.getTagKey(tags[0]);
								defaultTeethNumbers.push(teethUtils.getLocalizedToothKey({ toothKey, notationType }));
							}

							result.push({
								...label,
								toothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
							});
						}
						else if (labelParentMap[labelId]) {
							const parentLabelId = labelParentMap[labelId];

							const parentLabel = labelsSelectors.selectLabelById(state, {
								labelId: parentLabelId,
							});

							if ( labelsUtils.labelIsTooth(parentLabel) ) {
								let toothKey = null;
								const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: parentLabelId });
								if ( tags && tags.length > 0 ) {
									toothKey = labelTagGetter.getTagKey(tags[0]);
									defaultTeethNumbers.push(teethUtils.getLocalizedToothKey({ toothKey, notationType }));
								}

								result.push({
									...label,
									toothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
								});
							}
						}
						else {
							result.push(label);
						}
					}

					return result;
				}, [])
				.sort((a, b) => {
					let rectA = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(a));
					let rectB = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(b));

					return rectA.left - rectB.left;
				})
				.map((label) => ({
					...label,
					symbol: symbol++,
				}));
		}
	}

	labels.sort((a, b) => {
		if ( a.toothKey && !b.toothKey ) {
			return -1;
		}

		if ( !a.toothKey && b.toothKey ) {
			return 1;
		}

		return teethUtils.sortTeeth(a.toothKey, b.toothKey);
	});
	defaultTeethNumbers.sort((a, b) => teethUtils.sortTeeth(a, b));

	console.dir(labels);

	return {
		labels,
		currentImageId,
		currentImage,
		isAuthenticated: userSelectors.selectIsAuthenticated(state),
		user,
		snapshotLink: pathUtils.getSnapshotLink( {
			sharedHashname,
			isShared,
			imageHashName: currentImage.hashname,
			collectionId: currentCollectionId,
		}),
		reportLink: pathUtils.getReportLink({
			sharedHashname,
			isShared,
			imageHashName: currentImage.hashname,
			collectionId: currentCollectionId,
		}),
		defaultTeethNumbers: lodashUniq(defaultTeethNumbers),
	};
}, (dispatch) => ({
	onUpdateUserProfile: (options) => dispatch(userActions.updateProfile(options)),
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
}))(ReportXRAY);
