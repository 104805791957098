import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import when from 'when';

import PopupDialog from '../PopupDialog';
import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';


const i18nShared = getDictionary('shared');

/**
 * @typedef {Object} PopupDialogConfirmProps
 *
 * @property {ReactElement|string} content Confirm content.
 * @property {ReactElement|string} [title] Confirm title.
 * @property {string} [confirmButtonText] Confirm button text.
 * @property {string} [cancelButtonText] Cancel button text.
 * @property {function} [onConfirm]
 * @property {function} [onCancel]
 * @property {PopupDialogProps} [popupDialogProps]
 */

/**
 * Displays confirm dialog.
 *
 * @param {PopupDialogConfirmProps} props
 * @returns {ReactElement}
 */
class PopupDialogConfirm extends PureComponent {
	static propTypes = {
		content: PropTypes.node.isRequired,
		title: PropTypes.node,
		icon: PropTypes.string,

		confirmButtonText: PropTypes.string,
		cancelButtonText: PropTypes.string,

		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,

		popupDialogProps: PropTypes.object,
	}

	constructor (props, context) {
		super(props, context);

		this.state = {
			confirmIsInProgress: false,
		};
	}

	componentWillUnmount () {
		this.state = null;
	}

	_handleConfirm = ({ popup: { close } }) => {
		const {
			onConfirm,
		} = this.props;

		if ( this.state.confirmIsInProgress ) {
			return;
		}

		if ( onConfirm ) {
			this.setState({
				confirmIsInProgress: true,
			});

			when(onConfirm())
				.then(() => {
					close();
				})
				.finally(() => {
					if ( !this.state ) {
						return;
					}

					this.setState({
						confirmIsInProgress: false,
					});
				});

			return;
		}

		close();
	}

	_handleCancel = ({ popup: { close } }) => {
		const {
			onCancel,
		} = this.props;

		if ( onCancel ) {
			onCancel();
		}

		close();
	}

	render () {
		return (
			<PopupDialog
				{...this.props.popupDialogProps}
				headerProps={{
					title: ( this.props.title || i18nShared('confirm.dialog.title') ),
					icon: ( this.props.icon || null ),
				}}
				content={this.props.content}
				footerProps={{
					buttons: (dialogInterface) => [
						(
							<Button
								key={'confirm_button'}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								onClick={() => this._handleConfirm(dialogInterface)}
							>{ ( this.props.confirmButtonText || i18nShared('confirm.buttons.yes') ) }</Button>
						),
						(
							<Button
								key={'cancel_button'}
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								disabled={this.state.confirmIsInProgress}
								onClick={() => this._handleCancel(dialogInterface)}
							>{( this.props.cancelButtonText || i18nShared('confirm.buttons.no') )}</Button>
						)
					]
				}}
			/>
		);
	}
}

export default PopupDialogConfirm;
