import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MODES } from './reportConsts';

import Logo from '../../../../components/Logo';

import ReportButton, { ResolverReportButtonWithIcon } from './ReportButton';


const baseCssClassName = 'resolver-report-header';
const clientLogoCssClassName = `${baseCssClassName}-client-logo`;
const buttonsCssClassName = `${baseCssClassName}-buttons`;
const dentiLogoCssClassName = `${baseCssClassName}-our-logo`;
const buttonCssClassName = `${baseCssClassName}__button`;


export default class ResolverReportHeader extends PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
		onModeChanged: PropTypes.func.isRequired,
		onPrint: PropTypes.func.isRequired,
	};

	_handleToggleMode = () => {
		this.props.onModeChanged(this.props.mode === MODES.EDITING ? MODES.PREVIEW : MODES.EDITING);
	};

	_handlePrintClickInEditingMode = () => {
		this.props.onModeChanged(this.props.mode === MODES.EDITING ? MODES.PREVIEW : MODES.EDITING);
		window.setTimeout(() => {
			this.props.onPrint();
		}, 500);
	};

	_renderButtons () {
		if ( this.props.mode === MODES.EDITING ) {
			return (
				<Fragment>
					<div className={buttonCssClassName}>
						<ReportButton onClick={this._handleToggleMode}>
							<ResolverReportButtonWithIcon icon={'preview'}>
								Preview
							</ResolverReportButtonWithIcon>
						</ReportButton>
					</div>
					<div className={buttonCssClassName}>
						<ReportButton onClick={this._handlePrintClickInEditingMode}>
							<ResolverReportButtonWithIcon icon={'print'}>
								Print
							</ResolverReportButtonWithIcon>
						</ReportButton>
					</div>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<div className={buttonCssClassName}>
					<ReportButton onClick={this._handleToggleMode}>
						<ResolverReportButtonWithIcon icon={'edit'}>
							Edit
						</ResolverReportButtonWithIcon>
					</ReportButton>
				</div>
				<div className={buttonCssClassName}>
					<ReportButton onClick={this.props.onPrint}>
						<ResolverReportButtonWithIcon icon={'print'}>
							Print
						</ResolverReportButtonWithIcon>
					</ReportButton>
				</div>
			</Fragment>
		);
	}

	_renderLogo () {
		let content = null;
		if ( 'string' === typeof this.props.user.full_logo_url && this.props.user.full_logo_url.length > 0 ) {
			content = (
				<div className={`${clientLogoCssClassName}__image`}>
					<img src={this.props.user.full_logo_url} height={70} style={{ display: 'block' }} alt={''} />
				</div>
			);
		}
		else {
			content = this.props.user.clinic;
		}

		return (
			<div className={clientLogoCssClassName}>
				{content}
			</div>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				{this._renderLogo()}
				<div className={buttonsCssClassName}>
					{this._renderButtons()}
				</div>
				<div className={dentiLogoCssClassName}>
					<Logo theme={Logo.themes.dark} />
				</div>
			</div>
		);
	}
}
