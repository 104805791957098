import storeUtils from '../appUtils/storeUtils';
import apiActionTypes from './apiActionTypes';


function setData (data) {
	return storeUtils.makeAction(apiActionTypes.ACTION_API__SET_DATA, data);
}

function setCollectionsData (data) {
	return storeUtils.makeAction(apiActionTypes.ACTION_API__SET_COLLECTIONS_DATA, data);
}


export default {
	setData,
	setCollectionsData
};
