import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormCustomSelect from '../FormCustomSelect';

import labelsUtils from '../../appUtils/labelsUtils';

import classnames from 'classnames';

import { getDictionary } from '../../appUtils/locale';

import './styles/DiagnosisSelect.css';


function getBox (classId) {
	const color = labelsUtils.getLabelColorById(classId);

	return (
		<svg xmlns={'http://www.w3.org/2000/svg'} width={'14'} height={'14'} viewBox={'0 0 24 24'}>
			<g fill={color} fillRule={'evenodd'}>
				<path d={'M3 3h5v2H3zM3 19h5v2H3zM10 3h4v2h-4zM10 19h4v2h-4zM16 3h5v2h-5zM16 19h5v2h-5z'} />
				<path d={'M19 21v-5h2v5zM19 14v-4h2v4zM19 8V3h2v5zM3 21v-5h2v5zM3 14v-4h2v4zM3 8V3h2v5z'} />
			</g>
		</svg>
	);
}

function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if (items[i].value === value) {
			return items[i];
		}
	}

	return null;
}


// @todo move to separate file
const i18n = getDictionary('tooth-info');

const baseCssClassName = 'diagnosis-select';
const headerCssClassName = `${baseCssClassName}__header`;
const headerValueCssClassName = `${baseCssClassName}__header-value`;
const itemCssClassName = `${baseCssClassName}__item`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const requiredBoxCssClassName = `${baseCssClassName}__required-box`;
const hotKeyCssClassName = `${baseCssClassName}__hot-key`;


class DiagnosisSelect extends PureComponent {
	static propTypes = {
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
			label: PropTypes.string.isRequired,
			hotKey: PropTypes.string,
			requiredLabel: PropTypes.bool,
		})),
		
		initialOpen: PropTypes.bool,
		headerClassName: PropTypes.string,
		emptyValue: PropTypes.string,
		disabled: PropTypes.bool,
		itemsCssClassName: PropTypes.string,
		className: PropTypes.string,
		onChange: PropTypes.func.isRequired,
	}

	_handleRenderItem = (item) => (
		<div className={classnames([
			itemCssClassName,
			this.props.className && `${this.props.className}-item`,
			item.requiredLabel && `${itemCssClassName}__m-required`,
			item.disabled && `${itemCssClassName}__m-disabled`,
		])}>
			{item.hotKey && <div className={hotKeyCssClassName}>{item.hotKey}</div>}
			<span>{item.label}</span>
			{item.requiredLabel && (<div className={requiredBoxCssClassName}>{getBox(item.value)}</div>)}
		</div>
	)

	_handleRenderHeader = ({ isOpened, indicatorElement }) => {
		const selectedItem = (this.props.value && this.props.items
			? (getItem(this.props.value, this.props.items) || {})
			: {}
		);

		return (
			<div className={classnames(headerCssClassName, this.props.headerClassName)}>
				{selectedItem.hotKey && <div className={hotKeyCssClassName}>{selectedItem.hotKey}</div>}
				<span className={headerValueCssClassName}>{selectedItem.label || this.props.emptyValue || i18n('add_diagnosis')}</span>
				<div className={indicatorCssClassName}>{indicatorElement}</div>
			</div>
		);
	}

	render () {
		return (
			<FormCustomSelect
				items={this.props.items}
				value={this.props.value}
				disabled={this.props.disabled}
				initialOpen={this.props.initialOpen}
				itemsCssClassName={this.props.itemsCssClassName}
				className={this.props.className ? this.props.className : baseCssClassName}
				onRenderHeader={this._handleRenderHeader}
				onRenderItem={this._handleRenderItem}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default DiagnosisSelect;
