export default {
	'labels.new_password': 'Новый пароль',
	'labels.confirmation': 'Повтор нового пароля',
	'labels.current_password': 'Старый пароль',
	
	'info': `
		Вы должны ввести старый пароль,<br />
		чтобы сменить на новый
	`,
	
	'dialog.title': 'Сменить пароль',
};
