import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';


const propTypes = {
	cellKey: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	contextTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
};

const contextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};


const ContentCell = (props, context) => {
	return (
		<div
			className={classnames([
				`${context.gridCssClassName}__content-cell`,
				`${context.gridCssClassName}__content-cell__m-${props.cellKey}`,
			])}
			style={{ width: props.width }}
			onClick={props.onClick}
		>
			<div className={`${context.gridCssClassName}__content-cell-content`} title={props.contextTitle}>
				{props.children}
			</div>
		</div>
	);
}

ContentCell.propTypes = propTypes;
ContentCell.contextTypes = contextTypes;

export default ContentCell;
