import React from 'react';
import { connect } from 'react-redux';
import DiagnosisSelect from '../DiagnosisSelect';
import LabelToothSelector from './LabelToothSelector';

import mainConfig from '../../configs/mainConfig';
import teethUtils from '../../appUtils/teeth/teethUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import userSelectors from '../../selectors/userSelectors';

import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';
import labelTagsAction from '../../modules/label-tags/actions/labelTagsAction';
import editorActions from '../../actions/editorActions';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import { getDictionary } from '../../appUtils/locale';

import './styles/LabelToothControls.css';


const i18n = getDictionary('tooth-info');

const baseCssClassName = 'label-tooth-controls';

const diagnosisSelectorCssClassName = `${baseCssClassName}-diagnosis-selector`;
const toothKeySelectCssClassName = `${baseCssClassName}__tooth-key-select`;
const toothControlsFormCssClassName = `${baseCssClassName}__form`;
const removeToothButtonCssClassName = `${baseCssClassName}__remove-tooth-button`;

const LabelToothControls = ({
	label,
	tag,
	classes,
	isEditMode,
	toothKey,
	availableTeethForPrimaryBox,
	onFindingChangeToothKey,
	onAddTag,
	onRemoveTooth,
	onAddDiagnosis,
}) => {
	if ( !isEditMode ) {
		return null;
	}

	const _handleNewDiagnosisChange = (classId) => {
		if ( classId ) {
			onAddDiagnosis({
				classId,
				editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
			});
		}
	};

	const _handleRemoveClick = () => {
		onRemoveTooth({
			labelId: labelGetters.getLabelId(label),
		});
	};

	return (
		<div className={baseCssClassName}>
			{(<div className={toothKeySelectCssClassName}>
				<LabelToothSelector
					items={availableTeethForPrimaryBox}
					value={toothKey}
					disabled={( availableTeethForPrimaryBox.length <= 1 )}
					onChange={(newToothKey) => {
						const tagData = labelsUtils.getLabelTagByKey(newToothKey, labelGetters.getLabelClassId(label));
						if ( toothKey ) {
							onFindingChangeToothKey({
								tagId: labelTagGetter.getTagId(tag),
								tag: {
									key: newToothKey,
									localizedName: tagData.readable_name,
									hotKey: tagData.hotkey,
								},
							});
						}
						else {
							onAddTag({
								labelId: labelGetters.getLabelId(label),
								tag: {
									key: newToothKey,
									localizedName: tagData.readable_name,
									hotKey: tagData.hotkey,
								},
							});
						}
					}}
				/>
			</div>)}
			<div className={toothControlsFormCssClassName}>
				<DiagnosisSelect
					className={diagnosisSelectorCssClassName}
					items={classes}
					disabled={isSmallScreen()}
					onChange={_handleNewDiagnosisChange}
				/>
			</div>
			<div
				className={removeToothButtonCssClassName}
				title={i18n('delete.alt')}
				onClick={_handleRemoveClick}
			>{i18n('delete.text')}</div>
		</div>
	);
};


export default connect((state, { label, toothKey }) => {
	const user = userSelectors.selectUserData(state);
	const tag = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) })[0];
	const notationType = user.notation_type;
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	const availableTeethForPrimaryBox = [
		{
			value: toothKey,
			label: teethUtils.getLocalizedToothKey({
				toothKey: toothKey,
				notationType,
			}),
		},
	];

	const toothKeyLabelMap = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
				if ( tags && tags.length > 0 ) {
					result[labelTagGetter.getTagKey(tags[0])] = label;
				}
			}

			return result;
		}, {});


	teethUtils.getTeeth().forEach((_toothKey) => {
		if ( toothKeyLabelMap[_toothKey] ) {
			return;
		}

		availableTeethForPrimaryBox.push({
			value: _toothKey,
			label: teethUtils.getLocalizedToothKey({
				toothKey: _toothKey,
				notationType,
			}),
		});
	});

	availableTeethForPrimaryBox.sort((a, b) => {
		return teethUtils.sortTeeth(a.label, b.label);
	});

	return {
		tag,
		availableTeethForPrimaryBox,
	};
}, (dispatch) => ({
	onFindingChangeToothKey: (data) => {
		dispatch(labelTagsAction.changeTag(data));
		dispatch(editorActions.updateData({
			data: {
				selectedToothKey: data.newTagKey,
			},
		}));
	},
	onAddTag: (data) => {
		dispatch(labelTagsAction.addTag(data));
		dispatch(editorActions.selectLabel({
			labelId: data.labelId,
		}));
	},
}))(LabelToothControls);
