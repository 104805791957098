import apiActionTypes from '../actions/apiActionTypes';
import imagesHistoryActionTypes from '../actions/imagesHistoryActionTypes';


export default function imagesHistoryReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA: {
			const imageHistory = action.payload.imageHistory;

			if ( !imageHistory ) {
				return state;
			}
			
			return {
				...state,
				[imageHistory.imageId]: imageHistory.data,
			};
		}
		case imagesHistoryActionTypes.ACTION_IMAGES_HISTORY__SET_HISTORY:
			return {
				...state,
				[action.payload.imageId]: action.payload.data,
			};

		default:
			return state;
	}
};
