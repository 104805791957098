import React from 'react';
import ReactDOM from 'react-dom';

import AbstractPopupController from './AbstractPopupController';

import PopupDialogConfirm from '../../components/PopupDialogConfirm';

import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';


/**
 * @typedef {Object} PopupDialogConfirmControllerOptions
 *
 * @property {string} title
 * @property {string} message
 * @property {string} [confirmButtonText]
 * @property {string} [cancelButtonText]
 * @property {PopupDialogProps} [popupDialogProps]
 * @property {boolean} [autoOpen]
 * @property {function()} [onConfirm]
 * @property {function()} [onCancel]
 * @property {function} [onOpen]
 * @property {function():boolean|undefined} [onBeforeClose]
 * @property {function} [onClose]
 */

class PopupDialogConfirmController extends AbstractPopupController {
	/**
	 * @param {PopupDialogConfirmControllerOptions} options
	 */
	constructor (options = {}) {
		super(options);

		this._componentProps = {
			title: this._options.title ? (<span
				dangerouslySetInnerHTML={{
					__html: this._options.title,
				}}
			/>) : null,
			content: (<div
				dangerouslySetInnerHTML={{
					__html: this._options.message,
				}}
			/>),
			popupDialogProps: this._options.popupDialogProps,
			confirmButtonText: this._options.confirmButtonText,
			cancelButtonText: this._options.cancelButtonText,
			onConfirm: this._options.onConfirm,
			onCancel: this._options.onCancel,
		};

		lodashSet(this._componentProps, 'popupDialogProps.popupProps', {
			...lodashGet(this._options, 'popupDialogProps.popupProps', {}),
			onBeforeClose: this._options.onBeforeClose,
			onClose: this._handleClose,
		});

		if ( this._options.autoOpen ) {
			this.open();
		}
	}

	_handleClose = () => {
		setTimeout(() => {
			this.close();
		}, 0);
	};

	setOption () {}

	render () {
		if ( !this._container ) {
			return;
		}

		ReactDOM.render(
			<PopupDialogConfirm {...this._componentProps} />,
			this._container,
		);
	}
}

export default PopupDialogConfirmController;
