import React from 'react';
import PropTypes from 'prop-types';
import TileFilter from './TileFilter';

import './styles/TileFilterCounter.css';


const baseCssClassName = 'ui-huddle-tile-filter-counter';
const countCssClassName = `${baseCssClassName}__count`;
const textCssClassName = `${baseCssClassName}__text`;


const TileFilterCounter = ({ isActive, count, text, disabled, onChange }) => {
	return (
		<TileFilter isActive={isActive} disabled={disabled} onChange={onChange}>
			<div className={countCssClassName}>{count}</div>
			<div className={textCssClassName}>{text}</div>
		</TileFilter>
	);
};

TileFilterCounter.propTypes = {
	...TileFilter.propTypes,
	count: PropTypes.number.isRequired,
	text: PropTypes.string,
};

export default TileFilterCounter;
