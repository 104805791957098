import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import mainConfig from '../../configs/mainConfig';

import UiFloat, { FloatPosition, FloatAxis } from '../Float';

import './styles/FormCustomSelect.css';


function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if (items[i].value === value) {
			return items[i];
		}
	}

	return null;
}


const baseCssClassName = 'form-custom-select';
const headerCssClassName = `${baseCssClassName}__header`;
const headerContentCssClassName = `${baseCssClassName}__header-content`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const indicatorWrapperCssClassName = `${baseCssClassName}__indicator-wrapper`;
const itemsCssClassName = `${baseCssClassName}__items`;
const itemCssClassName = `${baseCssClassName}__item`;
const itemContentCssClassName = `${baseCssClassName}__item-content`;


export default class FormCustomSelect extends PureComponent {
	static propTypes = {
		value: PropTypes.string,
		displayValue: PropTypes.node,
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			label: PropTypes.node,
			disabled: PropTypes.bool,
		})).isRequired,
		initialOpen: PropTypes.bool,
		headerCssClassName: PropTypes.string,
		itemsCssClassName: PropTypes.string,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		closeAfterSelection: PropTypes.bool,
		onRenderHeader: PropTypes.func,
		onRenderItem: PropTypes.func,
		onEmpty: PropTypes.func,
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		value: null,
		displayValue: null,
		initialOpen: false,
		headerCssClassName: null,
		itemsCssClassName: null,
		className: null,
		disabled: false,
		closeAfterSelection: true,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_targetEl = null;

	/**
	 * @type {{left: number, top: number, right: number, bottom: number, width: number, height: number}}|null}
	 * @private
	 */
	_targetPosition = null;

	/**
	 * @type {boolean}
	 * @private
	 */
	_isItemClicked = false;

	state = {
		isOpened: false,
		position: {
			mainAxis: FloatAxis.vertical,
			mainAxisPosition: FloatPosition.start,
		},
	};

	componentWillUnmount () {
		this._unsubscribeToCloseOutside();
	}

	_toggleOpen () {
		if ( this.props.disabled === true ) {
			return;
		}

		const nextIsOpened = !this.state.isOpened;

		if ( nextIsOpened === true ) {
			this._subscribeToCloseOutside();
		}
		else {
			this._unsubscribeToCloseOutside();
		}

		this.setState({
			isOpened: nextIsOpened,
		});
	}

	_subscribeToCloseOutside () {
		window.document.addEventListener('click', this._handleDocumentClick);
		window.addEventListener('scroll', this._handleWindowScroll, true);
	}

	_unsubscribeToCloseOutside () {
		window.document.removeEventListener('click', this._handleDocumentClick);
		window.removeEventListener('scroll', this._handleWindowScroll, true);
	}

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handleTargetRef = (element = null) => {
		if ( null === element || element === this._targetEl ) {
			return;
		}

		this._targetEl = element;
		if ( this.props.initialOpen === true ) {
			this._toggleOpen();
		}
	};

	_handleClick = () => {
		this._toggleOpen();
	};

	_selectItem (item) {
		this.props.onChange(item.value);
		if ( this.props.closeAfterSelection === true ) {
			this._toggleOpen();
			this._isItemClicked = false;
		}

	}

	_updatePosition () {
		if ( this._targetEl !== null ) {
			this._targetPosition = this._targetEl.getBoundingClientRect();
		}
	}

	_handleDocumentClick = () => {
		if ( this._isItemClicked === true ) {
			this._isItemClicked = false;
			return;
		}

		this._toggleOpen();
	};

	_handleWindowScroll = () => {
		if ( this._targetPosition === null ) {
			return;
		}

		const targetBox = this._targetEl.getBoundingClientRect();

		if (
			targetBox.left !== this._targetPosition.left ||
			targetBox.top !== this._targetPosition.top ||
			targetBox.right !== this._targetPosition.right ||
			targetBox.bottom !== this._targetPosition.bottom
		) {
			this._toggleOpen();
		}
	};

	_handleReady = (position) => {
		this.setState({
			axis: position.mainAxis,
			pos: position.mainAxisPosition,
		});
	};

	_renderIndicator = () => {
		return (
			<div className={indicatorCssClassName} />
		);
	}

	_renderDefaultHeader = ({ isOpened, indicatorElement }) => {
		const selectedItem = (this.props.value && this.props.items
			? (getItem(this.props.value, this.props.items) || {})
			: {}
		);

		const shouldUseDisplayValue = typeof this.props.displayValue !== 'undefined' && this.props.displayValue !== null;

		return (
			<div className={headerContentCssClassName}>
				<span>{shouldUseDisplayValue === true ? this.props.displayValue : selectedItem.label}</span>
				<div className={indicatorWrapperCssClassName}>{indicatorElement}</div>
			</div>
		);
	}

	_renderDefaultItem = (item) => {
		return (
			<div
				className={classnames([
					itemContentCssClassName,
					item.disabled === true && `${itemContentCssClassName}__m-disabled`,
				])}
			>
				{item.label}
			</div>
		);
	}

	_renderItems () {
		if ( this.state.isOpened === false ) {
			return null;
		}

		const {
			items,
			className,
			onRenderItem,
		} = this.props;
		const itemsToRender = items
			// .filter((item) => item.value !== this.props.value);

		if ( this._targetEl === null ) {
			return null;
		}

		return (
			<UiFloat
				target={this._targetEl}
				onReady={this._handleReady}
			>
				<div
					className={classnames([
						itemsCssClassName,
						this.props.itemsCssClassName,
						`${itemsCssClassName}__m-axis-${this.state.axis}__m-pos-${this.state.pos}`,
						mainConfig.MAIN_SCROLL_CSS_CLASS_NAME,
					])}
					style={{ width: this._targetEl.offsetWidth }}
				>
					{itemsToRender.length > 0 && itemsToRender
						.map((item) => (
								<div
									key={item.value}
									className={classnames([
										itemCssClassName,
										className && `${className}-item`,
										item.value === this.props.value && `${itemCssClassName}__m-selected`,
										className && item.value === this.props.value && `${className}-item__m-selected`,
									])}
									onClick={(event) => {
										event.stopPropagation();
										this._isItemClicked = true;
										if ( item.value === this.props.value || item.disabled === true ) {
											return;
										}

										this._selectItem(item);
									}}
								>
									{(onRenderItem || this._renderDefaultItem)(item)}
								</div>
							)
						)}
				</div>
			</UiFloat>
		);
	}

	render () {
		const {
			className,
			disabled,
			onRenderHeader,
		} = this.props;

		this._updatePosition();

		return (
			<div
				className={classnames([
					baseCssClassName,
					className,
					`${baseCssClassName}__m-axis-${this.state.axis}__m-pos-${this.state.pos}`,
					disabled === true && `${baseCssClassName}__m-disabled`,
					this.state.isOpened === true && `${baseCssClassName}__m-opened`,
				])}
				ref={this._handleTargetRef}
				onClick={this._handleClick}
			>
				<div
					className={classnames([
						headerCssClassName,
						this.props.headerCssClassName,
					])}
				>
					{(onRenderHeader || this._renderDefaultHeader)({
						isOpened: this.state.isOpened,
						indicatorElement: this._renderIndicator(),
					})}
				</div>
				{this._renderItems()}
			</div>
		);
	}
}
