import moment from 'moment';


function getYear (year) {
	const currentDate = moment().toDate();

	return ( year || currentDate.getFullYear() );
}

function getMonth (month) {
	const currentDate = moment().toDate();

	return ( addZeroToValue(Number(month)) || addZeroToValue(currentDate.getMonth() + 1) );
}

function addZeroToValue (value) {
	if ( value && value < 10 ) {
		return `0${value}`;
	}

	return value;
}

function checkMonth (month) {
	if ( month > 0 && month < 13 ) {
		return true;
	}

	return false;
}

function toISOFormat (date) {
	const [ month, day, year ] = date.split('/');
	return `${getYear(year)}-${getMonth(month)}-${addZeroToValue(Number(day))}`;
}

function getAge (date) {
	return moment().diff(date, 'years');
}

export default {
	getYear,
	getMonth,
	addZeroToValue,
	checkMonth,
	toISOFormat,
	getAge,
};
