import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


const baseCssClassName = 'resolver-report-button';
const contentCssClassName = `${baseCssClassName}__content`;
const iconCssClassName = `${baseCssClassName}__icon`;


export default class ResolverReportButton extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		onClick: PropTypes.func.isRequired,
	};

	render () {
		return (
			<div className={baseCssClassName} onClick={this.props.onClick}>
				{this.props.children}
			</div>
		);
	}
}

export class ResolverReportButtonWithIcon extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		icon: PropTypes.string.isRequired,
	};

	render () {
		return (
			<div className={contentCssClassName}>
				<div className={`${iconCssClassName} ${iconCssClassName}__m-icon-${this.props.icon}`}>

				</div>
				{this.props.children}
			</div>
		);
	}
}
