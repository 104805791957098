import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PopupDialogHeader } from '../PopupDialog';
import PopupDialogConfirm from '../PopupDialogConfirm';

import FormCustomSelect from '../FormCustomSelect';
import Slider from '../Slider';

import FormCheckbox from '../FormCheckbox/FormCheckbox';

import { getDictionary } from '../../appUtils/locale';

import './styles/LabelListWrapper.css';


const i18n = getDictionary('findings');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'label-list-wrapper';
const itemsCssClassName = `${baseCssClassName}__items`;
const showAllClassesToggleCssClassName = `${baseCssClassName}__show-all-classes-toggle`;
const confirmAllClassesToggleCssClassName = `${baseCssClassName}__confirm-all-classes-toggle`;
const removeAllClassesToggleCssClassNameToggleCssClassName = `${baseCssClassName}__remove-all-classes-toggle`;
const controlsCssClassName = `${baseCssClassName}__controls`;
const filtersCssClassName = `${baseCssClassName}__filters`;
const filterCssClassName = `${baseCssClassName}__filter`;
const filterLabelCssClassName = `${baseCssClassName}__filter-label`;
const filterValueCssClassName = `${baseCssClassName}__filter-value`;
const filterControlCssClassName = `${baseCssClassName}__filter-control`;
const classFilterDropdownCssClassName = `${baseCssClassName}-class-filter-dropdown`;
const classFilterDropdownItemCssClassName = `${classFilterDropdownCssClassName}__item`;
const classFilterDropdownItemControlCssClassName = `${classFilterDropdownCssClassName}__item-control`;


class LabelListWrapper extends Component {
	static propTypes = {
		filteredConfidencePercent: PropTypes.number.isRequired,
		shouldShowFindingsFilters: PropTypes.bool,
		shouldShowConfidenceFilter: PropTypes.bool,
		shouldShowVisibilityFilter: PropTypes.bool.isRequired,
		shouldShowConfirmButton: PropTypes.bool.isRequired,
		shouldShowDeleteAllButton: PropTypes.bool.isRequired,
		onFilteredConfidencePercentChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		shouldShowFindingsFilters: true,
	};

	state = {
		isConfirmRemoveAllDialogOpened: false,
	};

	componentWillUnmount () {
		this.state = null;
	}

	_getFilteredClassesCount () {
		return Object.keys(this.props.filteredClasses).reduce((result, classId) => {
			if ( true === this.props.filteredClasses[classId] ) {
				++result;
			}
			return result;
		}, 0);
	}

	_handleRemoveAllButtonClick = () => {
		this.setState({
			isConfirmRemoveAllDialogOpened: true,
		});
	}

	_handleConfirmRemoveAll = () => {
		this.setState({
			isConfirmRemoveAllDialogOpened: false,
		});
		this.props.onRemoveAllLabels();
	}

	_handleCancelRemoveAll = () => {
		this.setState({
			isConfirmRemoveAllDialogOpened: false,
		});
	}

	_handleRenderItem = (item) => {
		return (
			<div className={classFilterDropdownItemCssClassName}>
				<div className={classFilterDropdownItemControlCssClassName}>
					<FormCheckbox
						inputProps={{
							checked: true === this.props.filteredClasses[item.value],
						}}
					/>
				</div>
				{item.label}
			</div>
		);
	}

	_handleConfidenceFilterChange = (value) => {
		this.props.onFilteredConfidencePercentChange(value / 100);
	}

	render () {
		const {
			showAllFindings,
			children,
			onConfirmAllLabels,
			onToggleShowAllFindings,
		} = this.props;

		const shouldShowControls = (
			this.props.shouldShowVisibilityFilter === true ||
			this.props.shouldShowConfirmButton === true ||
			this.props.shouldShowDeleteAllButton === true
		);

		const shouldShowFilters = (
			this.props.shouldShowFindingsFilters === true ||
			this.props.shouldShowConfidenceFilter === true
		);

		return (
			<div className={baseCssClassName}>
				{shouldShowControls === true && (
					<div className={controlsCssClassName}>
						{this.props.shouldShowVisibilityFilter === true && (
							<div
								className={showAllClassesToggleCssClassName}
								onClick={onToggleShowAllFindings}
							>{ ( showAllFindings ? i18n('buttons.show_less') : i18n('buttons.show_all') ) }</div>
						)}
						{this.props.shouldShowConfirmButton === true && (
							<div
								className={confirmAllClassesToggleCssClassName}
								title={i18n('confirm_all.alt')}
								onClick={onConfirmAllLabels}
							>{i18n('confirm_all.text')}</div>
						) }
						{this.props.shouldShowDeleteAllButton === true && (
							<div
								className={removeAllClassesToggleCssClassNameToggleCssClassName}
								title={i18n('remove_all.alt')}
								onClick={this._handleRemoveAllButtonClick}
							>{i18n('remove_all.text')}</div>
						) }
					</div>
				)}
				{shouldShowFilters === true && (
					<div
						className={classnames([
							filtersCssClassName,
							shouldShowControls === false && `${filtersCssClassName}__m-with-offset`,
							this.props.shouldShowFindingsFilters === true && `${filtersCssClassName}__m-with-findings-filter`,
							this.props.shouldShowConfidenceFilter === true && `${filtersCssClassName}__m-with-confidence-filter`,
						])}
					>
						{this.props.shouldShowFindingsFilters === true && (
							<div className={filterCssClassName}>
								<FormCustomSelect
									displayValue={i18n('filtered-findings.label', {
										'n': this._getFilteredClassesCount(),
									})}
									items={this.props.availableLabels
										.map((label) => ({
											value: label.classId,
											label: label.localizedClassName,
										}))}
									className={classFilterDropdownCssClassName}
									closeAfterSelection={false}
									onRenderItem={this._handleRenderItem}
									onChange={(value) => {
										const item = this.props.availableLabels.find((label) => label.classId === value);
										const nextFilteredClasses = {
											...this.props.filteredClasses,
										};

										if ( true === nextFilteredClasses.hasOwnProperty(item.classId) ) {
											nextFilteredClasses[item.classId] = !nextFilteredClasses[item.classId];
										}
										else {
											nextFilteredClasses[item.classId] = true;
										}

										this.props.onSelectFilteredClasses({
											imageType: this.props.currentImage.image_type,
											classes: nextFilteredClasses,
										});
									}}
								/>
							</div>
						)}
						{this.props.shouldShowFindingsFilters === true &&  this.props.shouldShowConfidenceFilter === true && (
							<div style={{ width: 21, height: 1 }} />
						)}
						{this.props.shouldShowConfidenceFilter === true && (
							<div className={`${filterCssClassName} ${filterCssClassName}__m-confidence`}>
								<div className={filterLabelCssClassName}>{i18n('confidence')}</div>
								<div className={filterValueCssClassName}>{Math.trunc(this.props.filteredConfidencePercent * 100)}%</div>
								<div className={filterControlCssClassName}>
									<Slider
										value={Math.trunc(this.props.filteredConfidencePercent * 100)}
										min={0}
										max={100}
										wrapperStyle={{
											width: 150,
											height: 17,
										}}
										railStyle={{
											background: '#989898',
											borderRadius: 1,
											height: 2,
										}}
										handleStyle={{
											background: '#989898',
											border: '2px solid #45464E',
										}}
										trackStyle={{
											background: '#989898',
											height: 2,
										}}
										onChange={this._handleConfidenceFilterChange}
									/>
								</div>
							</div>
						)}
					</div>
				)}
				<div
					className={classnames([
						itemsCssClassName,
						shouldShowControls && `${itemsCssClassName}__m-with-controls`,
						shouldShowFilters && `${itemsCssClassName}__m-with-filters`,
					])}
				>
					{children}
				</div>
				{ this.state.isConfirmRemoveAllDialogOpened && (
					<PopupDialogConfirm
						title={i18n('dialog.remove_all.title')}
						icon={PopupDialogHeader.icons.ERROR}
						content={i18n('dialog.remove_all.confirm')}
						confirmButtonText={i18nShared('buttons.delete')}
						cancelButtonText={i18nShared('buttons.cancel')}
						onConfirm={this._handleConfirmRemoveAll}
						onCancel={this._handleCancelRemoveAll}
					/>
				) }
			</div>
		);
	}
}

export default LabelListWrapper;
