import React from "react";

export default {
	'title': 'Statistics',
	'labels.user': 'User',
	'headers.collection': 'Provider',
	'headers.pan_images': 'Pan images',
	'headers.xray_images': 'Xray images',
	'headers.value': 'value',
	'headers.detected': 'detected',
	'headers.dentist_added': 'dentist added',
	'headers.removed': 'dentist ruled out',
	'statuses.error': 'An error occurred. Please retry',
	'statuses.error.button': 'Try again',
	'tabs.pathologies': 'Pathologies',
	'tabs.previous_treatment': 'Previous treatment',
	'tabs.other': 'Other',
	'tabs.experimental': 'Experimental',
};

