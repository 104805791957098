import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import Button from '../Button';
import Loading from '../Loading';

import { SUBMIT_STATUSES } from './SignInConstants';

import { getDictionary } from '../../appUtils/locale';

import './styles/SignIn.css';


const i18n = getDictionary('sign-in');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'sign-in-form';
const wrapperCssClassName = `${baseCssClassName}__wrapper`;
const formCssClassName = `${baseCssClassName}__form`;
const headerCssClassName = `${baseCssClassName}__header`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const errorCssClassname = `${baseCssClassName}__error`;
const prefaceCssClassname = `${baseCssClassName}__preface`;


export default class SignInForm extends PureComponent {
	static propTypes = {
		username: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		submitStatus: PropTypes.oneOf(Object.values(SUBMIT_STATUSES)).isRequired,
		from: PropTypes.string,
		onFieldChange: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
	};


	/**
	 * @param {Event} event
	 * @private
	 */
	_handleUsernameChange = (event) => {
		this.props.onFieldChange('username', event.target.value);
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handlePasswordChange = (event) => {
		this.props.onFieldChange('password', event.target.value);
	};

	_renderForm () {
		return (
			<form className={formCssClassName} onSubmit={this.props.onSubmit}>
				<h2 className={headerCssClassName}>{i18n('title')}</h2>
				{this.props.from === 'password_reset' && (
					<div className={prefaceCssClassname}>
						Password has been successfully changed
					</div>
				)}
				<FormLabel
					element={
						(
							<FormInput
								name={'username'}
								value={this.props.username}
								type={'text'}
								block
								disabled={this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS}
								onChange={this._handleUsernameChange}
							/>
						)
					}
					text={i18n('labels.login')}
					textPosition={FormLabel.textPosition.TOP}
				/>
				<FormLabel
					element={
						(
							<FormInput
								name={'password'}
								value={this.props.password}
								type={'password'}
								block
								disabled={this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS}
								onChange={this._handlePasswordChange}
							/>
						)
					}
					text={i18n('labels.password')}
					textPosition={FormLabel.textPosition.TOP}
				/>
				{this.props.submitStatus === SUBMIT_STATUSES.FAILED && (
					<div className={errorCssClassname} dangerouslySetInnerHTML={{ __html: i18n('auth.error') }} />
				)}
				<div style={{ fontSize: 12 }}><a href={'/forgot_password'}>Forgot password?</a></div>
				<div className={buttonsCssClassName}>
					{this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
						? (<Loading width={40} height={40} />)
						: (
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.MEDIUM}
								type={Button.AVAILABLE_TYPES.SUBMIT}
								disabled={
									this.props.username.length === 0 ||
									this.props.password.length === 0 ||
									this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
								}
							>{i18nShared('buttons.sign_in')}</Button>
						)
					}
				</div>
			</form>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={wrapperCssClassName}>
					{this._renderForm()}
				</div>
			</div>
		);
	}
}
