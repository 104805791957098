import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/RoundButton.css';


const baseCssClassName = 'round-button';


export default class RoundButton extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
	}
	
	_handleClick = () => {
		if ( this.props.disabled ) {
			return;
		}
		
		if ( this.props.onClick ) {
			this.props.onClick();
		}
	}
	
	render () {
		return (
			<div
				className={classnames([
					baseCssClassName,
					this.props.disabled && `${baseCssClassName}__m-disabled`,
				])}
				onClick={this._handleClick}
			>
				{this.props.children}
			</div>
		);
	}
}
