import React from 'react';

import Button from '../Button';
import PopupDialog, { PopupDialogContentText } from '../PopupDialog';
import {getDictionary} from '../../appUtils/locale';
import commonUtils from '../../appUtils/common';


const i18n = getDictionary('editor');
const i18nShared = getDictionary('shared');


const handleShowLoginMessageBackButtonClick = () => {
	commonUtils.goToLandingPage();
};

const LoginMessage = ({ onAgreeButtonClick }) => {
	return (
		<PopupDialog
			headerProps={{
				title: i18n('login_message.dialog.title'),
			}}
			content={(
				<PopupDialogContentText>
					<div>
						<span
							style={{ lineHeight: 1.5 }}
							dangerouslySetInnerHTML={{__html: i18n('login_message.info', {
								terms_link_: '<a href="https://www.denti.ai/terms-of-use" target="_blank">',
								_terms_link: '</a>',
								privacy_link_: '<a href="https://www.denti.ai/privacy-policy" target="_blank">',
								_privacy_link: '</a>',
							})}}
						/>
					</div>
				</PopupDialogContentText>
			)}
			footerProps={{
				buttons: () => {
					return [
						(
							<Button
								key={'popup_agree_button'}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								onClick={onAgreeButtonClick}
							>{i18nShared('buttons.agree')}</Button>
						),
						(
							<Button
								key={'popup_close_button'}
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								onClick={handleShowLoginMessageBackButtonClick}
							>{i18nShared('buttons.cancel')}</Button>
						),
					];
				}
			}}
			popupProps={{
				overlay: {
					closeOnClick: false,
				},
				onClose: handleShowLoginMessageBackButtonClick,
			}}
		/>
	);
};

export default LoginMessage;
