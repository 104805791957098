import React, { Component } from 'react';
import { connect } from 'react-redux';
import { events } from '../../services/events';
import TextField from '@material-ui/core/TextField';

import PopupDialog, { PopupDialogHeader } from '../PopupDialog';

import Button from '../Button';
import { tryToHandleApiError } from '../../appUtils/errorUtils';
import { getDictionary } from '../../appUtils/locale';
import { trackEvent } from '../../integrations/mixpanel';

import collectionsActions from '../../actions/collectionsActions';

import './styles/EditCollectionForm.css'


const i18n = getDictionary('edit-collection-form');
const i18nShared = getDictionary('shared');


class EditCollectionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDefault: props.isDefault || false,
			name: props.name || '',
			summary: props.summary || '',
			hashname: props.hashname || ''
		}
	}

	UNSAFE_componentWillReceiveProps ({name, summary, hashname, isDefault}) {
		this.setState({
			name: name || '',
			summary: summary || '',
			hashname: hashname || '',
			isDefault: isDefault || false,
		})
	}

	_handleDataChanged = (e) => {
		const name = e.target.name;
		this.setState({
			[name]: e.target.value
		})
	}

	_handleCreateCollection = () => {
		this.props.onCreateCollection({
			name: this.state.name,
			description: this.state.summary,
			is_default: this.state.isDefault,
			labels: [],
			label_relations: []
		})
			.then(() => {
				trackEvent('Collection create');
				events.emit('collection-added');
			});
		this.props.onClose();
	}

	_handleEditCollection = () => {
		this.props.onEditCollection({
			hashname: this.props.hashname,
			name: this.state.name,
			description: this.state.summary,
		})
			.then(() => {
				trackEvent('Collection edited');
				events.emit('collection-edited');
			})
			.catch((error) => {
				tryToHandleApiError(error);
			});
		this.props.onClose();
	}

	_handleCloseClick = () => {
		this.props.onClose()
	}

	_renderContent() {
		return (
			<React.Fragment>
				<TextField
					required
					id="name"
					InputLabelProps={{
						shrink: true,
					}}
					name='name'
					value={this.state.name}
					label={i18n('name')}
					onChange={this._handleDataChanged}
					margin="dense"
					fullWidth
				/>
				<TextField
					id="summary"
					InputLabelProps={{
						shrink: true,
					}}
					name='summary'
					value={this.state.summary}
					label={i18n('description')}
					onChange={this._handleDataChanged}
					margin="dense"
					fullWidth
				/>
			</React.Fragment>
		);
	}

	_renderButtons = () => {
		const {
			hasErrorSavingImageData
		} = this.state;

		return hasErrorSavingImageData
			?  (
				<React.Fragment>
					<Button
						key={'popup_try_again_button'}
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						title={i18nShared('buttons.try_again')}
						onClick={this._handleTryAgainButtonClick}>
						{i18nShared('buttons.try_again')}
					</Button>
					<Button
						key={'popup_close_button'}
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						title={i18nShared('buttons.close')}
						onClick={this._handleRemoveImageButtonClick}>
						{i18nShared('buttons.close')}
					</Button>
				</React.Fragment>)
			: (
				<React.Fragment>
					<Button
						key={'popup_save_button'}
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this.props.isEdit ? this._handleEditCollection : this._handleCreateCollection }
						title={i18nShared('confirm.buttons.yes')}
						disabled={this.state.name.length === 0}>
						{i18nShared('confirm.buttons.yes')}
					</Button>
					<Button
						key={'popup_close_button'}
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						title={i18nShared('buttons.cancel')}
						onClick={this._handleCloseClick}
					>
						{i18nShared('buttons.cancel')}
					</Button>
				</React.Fragment>)
	}

	render() {
		const { hasErrorSavingImageData } = this.state;

		return (
			<PopupDialog
				headerProps={{
					title: this.props.isEdit ? i18n('dialog.title.edit'): i18n('dialog.title.new'),
					icon: !hasErrorSavingImageData
						? PopupDialogHeader.icons.SUCCESS
						: PopupDialogHeader.icons.ERROR
				}}
				content={dialogInterface => {
					return dialogInterface ? this._renderContent(dialogInterface) : null;
				}}
				footerProps={{
					buttons: this._renderButtons
				}}
				popupProps={{
					overlay: {
						enabled: true,
						closeOnClick: false
					},
					onClose: () => {
						this.props.onClose();
					}
				}}
			/>
		);
	}
}

export default connect(
	null,
	dispatch => ({
		onCreateCollection: (options) => dispatch(collectionsActions.addCollection(options)),
		onEditCollection: (options) => dispatch(collectionsActions.editCollection(options),)
	})
)(EditCollectionForm);
