import React, { PureComponent } from 'react';
import classnames from 'classnames';

import { getDictionary } from '../../appUtils/locale';

import './styles/ReportSpecialist.css';


const i18n = getDictionary('report');

const baseCssClassName = 'report-specialist';
const primaryCssClassName = `${baseCssClassName}-primary`;
const primaryControlCssClassName = `${primaryCssClassName}__control`;
const primaryValueCssClassName = `${primaryCssClassName}__value`;
const secondaryCssClassName = `${baseCssClassName}-secondary`;
const secondaryControlCssClassName = `${secondaryCssClassName}__control`;


class ReportSpecialist extends PureComponent {
	constructor (props, context) {
		super(props, context);

		this.state = {
			specId: 'specialist',
		};
	}

	_handleSelectChange = (event) => {
		this.setState({
			specId: event.currentTarget.value,
		});
	}

	_getMap () {
		return {
			'specialist': i18n('specialists.label.specialist'),
			'assistant': i18n('specialists.label.assistant'),
			'hygienist': i18n('specialists.label.hygienist'),
		};
	}

	_getOptions () {
		const map = this._getMap();

		return Object.keys(map).map((key) => {
			return (
				<option key={key} value={key}>{map[key]}</option>
			);
		})
	}

	render () {
		return (
			<div className={classnames([
				baseCssClassName,
				this.props.readOnly && `${baseCssClassName}__m-read-only`
			])}>
				<div className={primaryCssClassName}>
					<div className={primaryControlCssClassName}>
						<select onChange={this._handleSelectChange}>
							{this._getOptions()}
						</select>
					</div>
					<div className={primaryValueCssClassName}>
						{this._getMap()[this.state.specId]}:
					</div>
				</div>
				<div className={secondaryCssClassName}>
					<input
						type={'text'}
						placeholder={'name'}
						className={secondaryControlCssClassName}
						defaultValue={this.props.name || ''}
						readOnly={this.props.readOnly === true}
					/>
				</div>
			</div>
		);
	}
}

export default ReportSpecialist;
