import labelsTagsSelectors from './labelsTagsSelectors';
import labelTagGetter from './labelTagGetter';


/**
 * @param {StoreState} state
 * @return {Object<LabelTag.id,LabelTag>}
 */
const selectLabelTags = (state) => (state.labelTags || {});

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelTag.id} options.tagId
 * @return {LabelTag}
 */
const selectLabelTagById = (state, options) => selectLabelTags(state)[options.tagId];

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {LabelTag.key} options.tagKey
 * @return {boolean}
 */
const selectHasLabelTag = (state, options) => {
	let hasTag = false;

	labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: options.labelId })
		.forEach((tagId) => {
			const tag = selectLabelTagById(state, { tagId });

			if ( labelTagGetter.getTagKey(tag) === options.tagKey ) {
				hasTag = true;
			}
		});

	return hasTag;
};

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @return {LabelTag[]}
 */
const selectLabelTagsByLabelId = (state, options) => {
	return labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: options.labelId })
		.reduce((result, tagId) => {
			result.push(selectLabelTagById(state, { tagId }));

			return result;
		}, []);
};


export default {
	selectLabelTags,
	selectLabelTagById,
	selectHasLabelTag,
	selectLabelTagsByLabelId,
};
