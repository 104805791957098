import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DayPicker from 'react-day-picker';

import LocaleUtils from './DatepickerMomentAdapter';

import momentUtils from '../../appUtils/momentUtils';

import DatepickerCaption from './DatepickerCaption';

import './styles/Datepicker.css';


const baseCssClassName = 'datepicker';
const wrapperCssClassName = `${baseCssClassName}-wrapper`;

const navbarCssClassName = `${baseCssClassName}-navbar`;
const navbarButtonCssClassName = `${navbarCssClassName}__nav-button`;
const weekdaysCssClassName = `${baseCssClassName}-weekdays`;


const classNames = {
	container: baseCssClassName,
	wrapper: `${baseCssClassName}__inner`,
	interactionDisabled: `${baseCssClassName}__m-interaction-disabled`,
	month: `${baseCssClassName}-month`,

	navBar: navbarCssClassName,
	navButtonPrev: `${navbarButtonCssClassName} ${navbarButtonCssClassName}__m-prev`,
	navButtonNext: `${navbarButtonCssClassName} ${navbarButtonCssClassName}__m-next`,
	navButtonInteractionDisabled: `${navbarButtonCssClassName}__m-disabled`,

	weekdays: weekdaysCssClassName,
	weekdaysRow: `${weekdaysCssClassName}__row`,
	weekday: `${baseCssClassName}-weekday`,
	body: `${baseCssClassName}-body`,
	week: `${baseCssClassName}-week`,
	weekNumber: `${baseCssClassName}-week-number`,
	day: `${baseCssClassName}-day`,
	footer: `${baseCssClassName}-footer`,
	todayButton: `${baseCssClassName}-today-button`,

	today: 'today',
	selected: 'selected',
	disabled: 'disabled',
	outside: 'outside',
};


class Datepicker extends Component {
	static propTypes = {
		...DayPicker.propTypes,

		minDate: PropTypes.instanceOf(Date),
		maxDate: PropTypes.instanceOf(Date),

		numberOfYears: PropTypes.number,

		disabledDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
	}

	static defaultProps = {
		...DayPicker.defaultProps,
		locale: null,
		showTodayButton: false,
		enableOutsideDays: false,
		disabledDays: [],
	}

	constructor (props) {
		super(props);

		this.state = {
			month: ( props.month || momentUtils.getTodayDate({ timezone: this.props.timezone }) ),
		};
	}

	_handleCaptionChange = (date) => {
		const {
			onMonthChange,
		} = this.props;

		this.setState({
			month: date,
		});

		if ( onMonthChange ) {
			onMonthChange(date);
		}
	}

	_getBaseModifiers () {
		return {
			today: (date) => momentUtils.isTodayDate({ date, timezone: this.props.timezone }),
		};
	}

	_getCaptionProps () {
		const {
			minDate,
			maxDate,
			numberOfYears,
		} = this.props;

		return {
			minDate,
			maxDate,

			numberOfYears,

			onChange: this._handleCaptionChange,
		};
	}

	_getBaseProps = () => {
		const {
			minDate,
			maxDate,
			modifiers,
			enableOutsideDays,
		} = this.props;

		const props = {
			...this.props,
			locale: momentUtils.getLocale(this.props.locale),
			month: this.state.month,
			modifiers: {
				...this._getBaseModifiers(),
				...modifiers,
			},
			classNames: classNames,
			enableOutsideDays: enableOutsideDays,
			localeUtils: LocaleUtils,
			captionElement: (
				<DatepickerCaption
					{...this._getCaptionProps()}
				/>
			),
		};

		if ( minDate || maxDate ) {
			props.fromMonth = ( minDate || null );
			props.toMonth = ( maxDate || null );

			props.disabledDays = [
				...props.disabledDays,
				{
					before: ( minDate || null ),
					after: ( maxDate || null ),
				},
			];
		}

		return props;
	}

	render () {
		const props = this._getBaseProps();
		return (
			<div className={wrapperCssClassName}>
				<DayPicker {...props} />
			</div>
		);
	}
}

export default Datepicker;
