import analyseTypes from './analyseTypes';
import storeUtils from "../appUtils/storeUtils";
import editorSelectors from "../selectors/editorSelectors";


function setImageAnalyzed ({ imageId }) {
	return storeUtils.makeAction(analyseTypes.ACTION_ANALYSIS__SET_ANALYZED, imageId);
}

function unsetImageAnalyzed ({ imageId }) {
	return storeUtils.makeAction(analyseTypes.ACTION_ANALYSIS__UNSET_ANALYZED, imageId);
}

function startImageAnalysis ({ imageId }) {
	return storeUtils.makeAction(analyseTypes.ACTION_ANALYSIS__IMAGE_ANALYSIS_START, imageId);
}

function finishImageAnalysis ({ imageId }) {
	return storeUtils.makeAction(analyseTypes.ACTION_ANALYSIS__IMAGE_ANALYSIS_END, imageId);
}

function applyImageAnalysis () {
	return (dispatch, getState) => {
		return dispatch(unsetImageAnalyzed({ imageId: editorSelectors.selectCurrentImageId(getState()) }));
	};
}

export default {
	setImageAnalyzed,
	unsetImageAnalyzed,
	startImageAnalysis,
	finishImageAnalysis,
	applyImageAnalysis,
};


