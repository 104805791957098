import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import mainConfig from '../../configs/mainConfig';
import { connect } from 'react-redux';

import aclService from '../../services/acl';
import { message } from '../../services/popup';

import dateUtils from '../../appUtils/dateUtils';
import momentUtils from '../../appUtils/momentUtils';
import { tryToHandleApiError } from '../../appUtils/errorUtils';
import { trackEvent } from '../../integrations/mixpanel';

import editorActions from '../../actions/editorActions';
import imagesActions from '../../actions/imagesActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import userSelectors from '../../selectors/userSelectors';

import { DatepickerInput, getMaxDate, getMinDate } from '../Datepicker';

import {
	IMAGE_TYPE__CEPHALOMETRIC,
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__UNKNOWN,
	IMAGE_TYPE__XRAY
} from '../../constants/imageConstants';

import { USER_PERMISSION__CHANGE_IMAGE } from '../../constants/userPermissionsConstants';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '../FormLabel/FormLabel';

import { getDictionary } from '../../appUtils/locale';

import './styles/CurrentImageInfo.css';


const i18n = getDictionary('image');
const i18nShared = getDictionary('shared');


const baseCssClassName = 'current-image-info';
const shortCssClassName = `${baseCssClassName}__short`;
const shortContentCssClassName = `${baseCssClassName}__short-content`;
const shortContentItemCssClassName = `${baseCssClassName}__short-content-item`;
const dropdownCssClassName = `${baseCssClassName}__dropdown`;
const dropdownIndicatorWrapperCssClassName = `${baseCssClassName}__dropdown-indicator-wrapper`;
const dropdownIndicatorCssClassName = `${baseCssClassName}__dropdown-indicator`;
const itemCssClassName = `${baseCssClassName}__item`;
const valueWrapperCssClassName = `${baseCssClassName}__value-wrapper`;
const textCssClassName = `${baseCssClassName}__text`;


class CurrentImageInfo extends Component {
	static propTypes = {
		image: PropTypes.object, // @todo add shape
		panelFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		dropdownFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		availableImageTypes: PropTypes.array,
		firstColPadding: PropTypes.bool,
		shouldCheckImage: PropTypes.bool,
		onCloseImage: PropTypes.func.isRequired,
	};

	static defaultProps = {
		firstColPadding: true,
		availableImageTypes: mainConfig.AVAILABLE_IMAGE_TYPES,
		shouldCheckImage: false,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		let image = props.image;
		const patientId = (props.image.patient_id === null || props.image.patient_id === undefined)
			? ''
			: String(props.image.patient_id);

		this.state = {
			date: image.image_date ? moment(image.image_date).toDate() : undefined,
			patient_id: patientId,
			birthday: image.birthday ? moment(image.birthday).toDate() : undefined,
			age: image.birthday ? dateUtils.getAge(image.birthday) : null,
			gender: image.gender || '',
			image_type: image.image_type,
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		let image = nextProps.image;
		const patientId = image.patient_id === null || image.patient_id === undefined ?  '' : String(image.patient_id);
		this.setState({
			date:  image.image_date ? moment(image.image_date).toDate(): undefined,
			patient_id: patientId,
			birthday: image.birthday ? moment(image.birthday).toDate() : undefined,
			age: image.birthday ? dateUtils.getAge(image.birthday) : null,
			gender: image.gender || '',
			image_type: image.image_type,
		});
	}

	_savePatientData = () => {
		if ( !this._validateField() ) {
			const patientId = this.state.patient_id.trim();
			return this.props.onSaveImageData({
				data: {
					//patient_id: patientId === '' ? null : patientId.replace(/[^a-zA-Z0-9]+/g, ''),
					patient_id: patientId === '' ? null : patientId,
				},
			})
				.then(() => {
					trackEvent('Image patient id change');
				})
				.catch((error) => {
					tryToHandleApiError(error);
				});
		}
	}

	_saveData = (name) => {
		if ( !this._validateField() ) {
			return this.props.onSaveImageData({
				data: {
					[name]: this.state[name],
				},

			})
				.catch((error) => {
					tryToHandleApiError(error);
				});
		}
	}

	_validateField = () => {
		const check = mainConfig.FIELDS_PATTERNS['patient_id'];
		const value = this.state.patient_id;

		if ( typeof check === 'function' ) {
			return !check(value);
		}
		else {
			return !check.test(value);
		}
	}

	_setDateChange = (name, selectedDay) => {
		return this.props.onSaveImageData({
			data: {[name]: selectedDay ? momentUtils.getMomentForDate({
					date: selectedDay,
				}).format('YYYY-MM-DD') : null,
			}
		})
			.catch((error) => {
				tryToHandleApiError(error);
			});
	}

	_getFields (position) {
		const {
			date,
			patient_id,
			birthday,
			age,
			gender,
			image_type,
		} = this.state;

		const isEditDisabled = !aclService.checkPermission(USER_PERMISSION__CHANGE_IMAGE);

		let imageTypes = this.props.availableImageTypes;
		if ( image_type === IMAGE_TYPE__XRAY ) {
			imageTypes = imageTypes.concat(IMAGE_TYPE__XRAY);
		}

		return {
			patient_id: (
				<FormLabel
					element={
						(
							<div style={{ width: 55 }}>
								<Input
									error={this._validateField()}
									name='patient_id'
									value={patient_id}
									style={{fontSize: 12}}
									onChange={this._handleDataChange}
									onBlur={this._savePatientData}
									disabled={isEditDisabled}
								/>
							</div>
						)
					}
					text={<div className={textCssClassName}>Patient's ID</div>}
					textPosition={position}
				/>
			),
			image_date: (
				<FormLabel
					element={
						(
							<div className={valueWrapperCssClassName}>
								<DatepickerInput
									value={this.state.date}
									onDayChange={this._handleDayChange}
									dayPickerProps={{
										selectedDays: date,
									}}
									minDate={getMinDate()}
									maxDate={getMaxDate()}
									disabled={isEditDisabled}
								/>
							</div>
						)
					}
					text={(<div className={textCssClassName}>Date</div>)}
					textPosition={position}
				/>
			),
			image_hash: (
				<FormLabel
					element={(<div className={valueWrapperCssClassName}>{this.props.image.hashname}</div>)}
					text={(<div className={textCssClassName}>{i18n('image_id')}</div>)}
					textPosition={position}
				/>
			),
			image_type: (
				<FormLabel
					element={
						(
							<div className={valueWrapperCssClassName} style={{ width: 70 }}>
								<TextField
									id={'image_type'}
									name={'image_type'}
									select
									value={image_type}
									InputProps={{
										classes: {root: 'current-image-info__input'}
									}}
									onChange={this._handleDataChange}
									SelectProps={{
										native: true,
									}}
									margin={'dense'}
									fullWidth
									disabled={isEditDisabled}
								>
									{imageTypes.map(type => (
										<option
											style={{ color: 'black' }}
											key={type}
											value={type}>
											{i18nShared(`image_types.${type}`)}
										</option>
									))}
								</TextField>
							</div>
						)
					}
					text={(<div className={textCssClassName}>Type</div>)}
					textPosition={position}
				/>
			),
			gender: (
				<FormLabel
					element={
						(
							<div className={valueWrapperCssClassName}>
								<TextField
									id="gender"
									name="gender"
									select
									value={gender}
									InputProps={{
										classes: {root: 'current-image-info__input'}
									}}
									onChange={this._handleDataChange}
									SelectProps={{
										native: true,
									}}
									margin="dense"
									fullWidth
									disabled={isEditDisabled}
								>
									{mainConfig.GENDERS.map(type => (
										<option style={{color: 'black'}} key={type} value={type}>
											{i18nShared(`gender.${type}`)}
										</option>
									))}
								</TextField>
							</div>
						)
					}
					text={(<div className={textCssClassName}>{i18n('gender')}</div>)}
					textPosition={position}
				/>
			),
			birthday: (
				<FormLabel
					element={
						(
							<div className={valueWrapperCssClassName}>
								<DatepickerInput
									value={this.state.birthday}
									onDayChange={this._handleBirthdayChange}
									dayPickerProps={{
										selectedDays: birthday,
									}}
									minDate={new Date(momentUtils.getNowMoment().subtract('140', 'years').valueOf())}
									maxDate={getMaxDate()}
									isInvalid={this.state.age !== null ? this.state.age < 21 : false}
									disabled={isEditDisabled}
								/>
							</div>
						)
					}
					text={(<div className={textCssClassName}>{i18n('birthday')}</div>)}
					textPosition={position}
				/>
			),
			age: (
				!!age
					? (
						<FormLabel
							element={(<div className={valueWrapperCssClassName}>{age}</div>)}
							text={(<div className={textCssClassName}>{i18n('age')}</div>)}
							textPosition={position}
						/>
					)
					: null
			)
		};
	}

	_handleDayChange = (selectedDay) => {
		if (selectedDay) {
			this.setState({
				date: selectedDay,
			});
			trackEvent('Image date change');
			this._setDateChange('image_date',  selectedDay);
		}
	}

	_handleBirthdayChange = (selectedDay) => {
		if ( selectedDay ) {
			const age = selectedDay ? dateUtils.getAge(selectedDay) : null;
			this.setState({
				birthday: selectedDay,
				age,
			});

			if ( age >= 21 ) {
				trackEvent('Image patient birthday change');
				this._setDateChange('birthday', selectedDay);
			}
			else {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: (
						<span dangerouslySetInnerHTML={{
							__html: 'Patient should be older than 21',
						}} />
					),
				});
			}
		}
	}

	_handleDataChange = (event) => {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value
		}, () => {
			if ( name === 'gender' ) {
				trackEvent('Image gender change');
				this._saveData(name);
			}
			else if ( name === 'image_type' ) {
				trackEvent('Image type change');
				this._saveData(name);
			}
		})
	}

	_handleToggleDropdown = () => {
		this.setState({
			isOpened: !this.state.isOpened,
		});
	}

	_renderDropdown () {
		if ( !this.state.isOpened ) {
			return null;
		}

		const fields = this._getFields(FormLabel.textPosition.TOP);
		
		return (
			<div className={dropdownCssClassName}>
				{this.props.dropdownFields.map((field) => {
					const content = fields[field];

					if ( content === null ) {
						return null;
					}

					return (
						<div className={itemCssClassName}>
							{content}
						</div>
					);
				})}
			</div>
		);
	}

	render () {
		if ( !this.props.image ) {
			return null;
		}

		const fields = this._getFields(FormLabel.textPosition.LEFT);

		return (
			<div className={classnames([
				baseCssClassName,
				this.state.isOpened && `${baseCssClassName}__m-opened`,
			])}>
				<div className={shortCssClassName}>
					<div
						className={classnames([
							shortContentCssClassName,
							this.props.firstColPadding && `${shortContentCssClassName}__m-first-col-padding`
						])}
					>
						{this.props.panelFields.map((field, index) => (
							<div className={shortContentItemCssClassName} key={index}>
								{fields[field]}
							</div>
						))}
					</div>
					<div
						className={dropdownIndicatorWrapperCssClassName}
						onClick={this._handleToggleDropdown}
					><div className={dropdownIndicatorCssClassName} /></div>
				</div>
				{this._renderDropdown()}
			</div>
		);
	}
}

export default withRouter(connect((state) => {
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const usesComputerAidedDeviceUi = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
	let availableImageTypes = mainConfig.AVAILABLE_IMAGE_TYPES;

	if ( usesComputerAidedDeviceUi === true ) {
		availableImageTypes = availableImageTypes.filter((imageType) => (
			imageType !== IMAGE_TYPE__CEPHALOMETRIC &&
			imageType !== IMAGE_TYPE__PAN_CHILD &&
			imageType !== IMAGE_TYPE__UNKNOWN
		));
	}

	return {
		availableImageTypes,
		image: currentImage,
	};
}, (dispatch, props) => ({
	onCloseImage: () => dispatch(editorActions.unsetCurrentImage()),
	onSaveImageData: (options) => {
		return dispatch((dispatch, getState) => {
			dispatch(editorActions.updateData({
				data: {
					showCanvasGrid: false,
				},
			}));

			const storeState = getState();
			const currentExamination = editorSelectors.selectEditor(storeState).currentExamination;
			const oldImages = Object.values(imagesSelectors.selectImages(storeState))
				.filter((image) => {
					return image.examination === currentExamination && mainConfig.ALLOWED_IMAGE_TYPES.includes(image.image_type);
				});

			return dispatch(imagesActions.saveImageData(options))
				.then(() => {
					dispatch((dispatch, getState) => {
						if ( props.shouldCheckImage !== true ) {
							return;
						}

						const storeState = getState();

						if ( typeof currentExamination === 'string' && currentExamination.length > 0 ) {
							const images = Object.values(imagesSelectors.selectImages(storeState))
								.filter((image) => {
									return image.examination === currentExamination && mainConfig.ALLOWED_IMAGE_TYPES.includes(image.image_type);
								});

							if ( oldImages.length === images.length ) {
								return;
							}

							if ( images.length > 0 ) {
								images.sort((a, b) => b.last_opened_at - a.last_opened_at);
								props.history.push(`/collections/${images[0].collection_hashname}/image/${images[0].hashname}`);
							}
							else {
								const images = Object.values(imagesSelectors.selectImages(storeState))
									.filter((image) => {
										return mainConfig.ALLOWED_IMAGE_TYPES.includes(image.image_type);
									});

								if ( images.length > 0 ) {
									images.sort((a, b) => b.last_opened_at - a.last_opened_at);
									props.history.push(`/collections/${images[0].collection_hashname}/image/${images[0].hashname}`);
								}
								else {
									props.history.push(`/collection-list`);
								}
							}
						}
					});
				});
		});
	},
}))(CurrentImageInfo));
