export default {
	'messages.wait': 'Please wait',
	'messages.image_wait': 'Image uploading, please wait',
	'messages.loading_error': 'An error occurred while loading',
	'messages.error.with_retry': 'An error occurred. Please try again.',
	'messages.success': 'Success',

	'buttons.try_again': 'Try again',
	'buttons.change': 'Change',
	'buttons.ok': 'Ok',
	'buttons.agree': 'I agree',
	'buttons.back': 'Back',
	'buttons.delete': 'Delete',
	'buttons.cancel': 'Cancel',
	'buttons.copy': 'Copy',
	'buttons.close': 'Close',
	'buttons.save': 'Save',
	'buttons.continue': 'Continue',
	'buttons.download': 'Download',
	'buttons.sign_in': 'Sign in',
	'buttons.sign_up': 'Sign up',
	'buttons.logout': 'Logout',
	'buttons.free_trial': 'Free Trial',
	'buttons.analyze': 'Analyze',
	'buttons.report': 'Report',

	'confirm.dialog.title': 'Confirm',
	'confirm.buttons.yes': 'Yes',
	'confirm.buttons.no': 'No',

	'error.title': 'Error',
	'error.access-denied': 'Access denied',

	'warning.only_digits': 'Please use only letters, digits or underscore sign',

	'gender.female': 'Female',
	'gender.male': 'Male',
	'gender.unknown': 'Unknown',

	'header.error': 'Error',

	'image_types.pan': 'PAN',
	'image_types.xray': 'X-Ray',
	'image_types.bitewing': 'BW',
	'image_types.periapical': 'PA',
	'image_types.ceph': 'CEPH',
	'image_types.child_pan': 'Child',
	'image_types.extraoral_bitewing': 'Extraoral bitewing',
	'image_types.extraoral_bitewing_child': 'Extraoral bitewing child',
	'image_types.unknown': 'Unknown',

};
