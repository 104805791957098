import EventEmitter from '../../appUtils/event-emitter';


class NamedPolyHintController {
	_text = null;
	
	_displaying = false;
	
	events = new EventEmitter();
	
	text (value) {
		this._text = value;
		
		this.events.emit('text-changed', this._text);
	}
	
	destroy () {
		this.events.destroy();
	}
	
	show () {
		if ( this._displaying ) {
			return;
		}
		
		this._displaying = true;
		this.events.emit('show');
	}
	
	hide () {
		if ( !this._displaying ) {
			return;
		}
		
		this._displaying = false;
		this.events.emit('hide');
	}
}

export default new NamedPolyHintController();
