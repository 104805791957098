import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import labelsUtils from '../../../../appUtils/labelsUtils';
import imageUtils from '../../../../appUtils/imageUtils';

import labelGetters from '../../../labels/selectors/labelGetters';


export default class FmxViewerShapes extends PureComponent {
	static propTypes = {
		labels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		visibleLabelsIds: PropTypes.object.isRequired,
		closestObjectToPoint: PropTypes.object,
		selectedObject: PropTypes.object,
		isSelectedObjectInTransformation: PropTypes.bool,
		labelColorFilterFn: PropTypes.func.isRequired,
		imageHashName: PropTypes.string.isRequired,
		selectionController: PropTypes.object.isRequired,
		highlightedLabels: PropTypes.arrayOf(PropTypes.string.isRequired),
		onSetSelectedObject: PropTypes.func.isRequired,
	};

	static defaultProps = {
		closestObjectToPoint: null,
		selectedObject: null,
	};

	state = {
		selectedObject: null,
	};

	componentDidMount() {
		this.props.selectionController.listen((selectedObject) => {
			if ( selectedObject === null ) {
				this.props.onSetSelectedObject(null);
			}
			this.setState({
				selectedObject,
			});
		});
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			lodashGet(nextProps, 'selectedObject.id') !== lodashGet(this.props, 'selectedObject.id')
		) {
			// this.props.onSelectedObjectChanged(nextProps.selectedObject);
			this.props.selectionController.setObject(nextProps.selectedObject);
		}
		//
		// if ( nextProps.closestObjectToPoint !== this.props.closestObjectToPoint ) {
		// 	this.props.onClosestObjectToPointChanged(nextProps.closestObjectToPoint);
		// }
	}

	render () {
		const groups = [ { children: [] } ];
		this.props.labels.forEach((label) => {
			const labelId = labelGetters.getLabelId(label);
			const hasSelectedObject = this.state.selectedObject !== null;
			const isSelectedLabel = hasSelectedObject === true && this.state.selectedObject.id === labelId;
			const isInteracted = (
				this.props.closestObjectToPoint !== null &&
				this.props.closestObjectToPoint.id === labelId
			);
			const isHighlighted = (
				isInteracted ||
				this.props.highlightedLabels.includes(labelGetters.getLabelId(label))
			);

			const isVisible = (
				(hasSelectedObject === false && this.props.visibleLabelsIds[labelId] === true) ||
				isSelectedLabel === true ||
				isHighlighted === true
			);

			const shouldShowTooltip = (
				this.props.isSelectedObjectInTransformation === false &&
				(
					this.props.closestObjectToPoint !== null &&
					this.props.closestObjectToPoint.id === labelId
				) &&
				( this.props.selectedObject !== null
					? labelId !== this.props.selectedObject.id
					: true
				)
			);

			const api = {
				label,
				hasSelectedObject,
				isSelectedLabel,
				isVisible,
				isInteracted,
				isHighlighted,
				id: labelGetters.getLabelId(label),
				shape: label.shapes[this.props.imageHashName] || {},
				color: labelsUtils.getLabelColor(label, this.props.labelColorFilterFn),
				borderStyle: labelsUtils.getShapeBorderStyle(label),
				isConfirmed: labelGetters.getLabelIsConfirmed(label),
				shouldShowTooltip,
				onGetColor: ({ matrixValue }) => {
					return labelsUtils.getHeatMapColor(labelGetters.getLabelClassId(label), matrixValue, labelGetters.getLabelMeasureOfConfidence(label));
				},
				onGetHeatmapUrl: imageUtils.getHeatmapUrl,
			};

			if ( isHighlighted === true ) {
				if ( !groups[1] ) {
					groups[1] = { children: [] };
				}
				groups[1].children.push(this.props.children(api));
			}
			else if ( isSelectedLabel === true ) {
				groups[2] = {
					children: [ this.props.children(api) ],
					style: {
						pointerEvents: 'bounding-box',
					},
				};
			}
			else {
				groups[0].children.push(this.props.children(api));
			}
		});

		return groups.map(({ children, style }, index) => (
			<g key={index} style={style}>{children}</g>
		));
	}
}
