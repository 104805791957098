import React, { Component } from 'react';
import EditCollectionForm from './EditCollectionForm'
import RoundedButton from '../RoundedButton'

import { getDictionary } from '../../appUtils/locale';
import { trackEvent } from '../../integrations/mixpanel';

const i18n = getDictionary('collections');


export default class EditCollection extends Component {
	state = {
		showForm: false
	}

	_handleAddingCollection = () => {
		trackEvent('Create collection click');
		this.setState({ showForm: true });
	}

	_handleClose = () => {
		this.setState({showForm: false})
	}

	render() {
		return (
			<React.Fragment>
				<RoundedButton
					type={'add'}
					theme={RoundedButton.themes.secondary}
					title={i18n('add_collection')}
					onClick={this._handleAddingCollection}
				/>
				{this.state.showForm && <EditCollectionForm onClose={this._handleClose}/>}
			</React.Fragment>
		)
	}

}
