import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/FormInput.css';


const baseCssClassName = 'form-input';
const elementCssClassName = `${baseCssClassName}__element`;


const propTypes = {
	block: PropTypes.bool,
	
	onGetRef: PropTypes.func,
};

	

function FormInput (props) {
	const {
		block,
		isInvalid,
		onGetRef,
		...rest
	} = props;
	
	
	return (
		<div className={classnames([
			baseCssClassName,
			block && `${baseCssClassName}__m-block`,
			isInvalid && `${baseCssClassName}__m-invalid`,
		])}>
			<input
				{...rest}
				className={elementCssClassName}
				ref={onGetRef}
			/>
		</div>
	);
}

FormInput.propTypes = propTypes;

export default FormInput;
