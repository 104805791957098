import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { events } from '../../../../services/events';
import aclService from '../../../../services/acl';

import mainConfig from '../../../../configs/mainConfig';

import {
	USER_PERMISSION__IS_SHOW_BONE_LEVELS,
	USER_PERMISSION__IS_SHOW_EXISTING_CHART,
	USER_PERMISSION__IS_SHOW_NON_PATHOLOGICAL
} from '../../../../constants/userPermissionsConstants';

import { getDictionary } from '../../../../appUtils/locale';
import { confirm, message } from '../../../../services/popup';
// import imageFiltersService from '../../../../services/image-filters';

import SizeDetector from '../../../../components/SizeDetector';
import Header from '../../../resolver/components/header';
import Button from '../../../../components/Button';
import Popup from '../../../../components/Popup';
import Alignment from '../../../../components/Alignment';
import Loading from '../../../../components/Loading';
import TfvToolbar from '../toolbar';
import { DrawSecondaryBoxMode } from '../../../../components/DisableLayout';
import ResolverSidebar from '../../../resolver/components/controller/ResolverSidebar';
import ResolverSidebarPanel from '../../../resolver/components/sidebar-panel/ResolverSidebarPanelConnector';
import { ResolverImageNotification } from '../../../resolver/components/image-editor';
import TfvDentalNotationDiagram from '../dental-notation';
import { TfvSidebarButtons, TfvSidebarFindings } from '../sidebar';
import TfvList from '../list';
import UserMenu from '../../../../components/Editor/UserMenu';
import TfvNavigation from '../navigation';

import './styles/TfvMainLayout.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'tfv-main-layout';
const headerCssClassName = `${baseCssClassName}__header`;
const topPanelCssClassName = `${baseCssClassName}-top-panel`;
const mainContentCssClassName = `${baseCssClassName}-main-content`;
const bottomPanelCssClassName = `${baseCssClassName}-bottom-panel`;
const bottomContentPanelCssClassName = `${bottomPanelCssClassName}__content`;
const sidebarCssClassName = `${baseCssClassName}__sidebar`;
const sidebarPanelCssClassName = `${baseCssClassName}__sidebar-panel`;
const editorToolbarCssClassName = `${baseCssClassName}__editor-toolbar`;
const imageEditorCssClassName = `${baseCssClassName}__image-editor`;
const listCssClassName = `${baseCssClassName}__list`;
const navigationCssClassName = `${baseCssClassName}__navigation`;
const sequentialModeButtonClassName = `${baseCssClassName}__sequential-mode-button`;
const analyzeButtonClassName = `${baseCssClassName}__analyze-button`;
const notificationsCssClassName = `${baseCssClassName}__notifications`;

const SIDEBAR_PANEL_OFFSET_Y = 40;


export default class TfvMainLayout extends PureComponent {
	static propTypes = {
		currentCollection: PropTypes.object.isRequired,
		isUserMenuOpened: PropTypes.bool.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		areFindingsMasksFromProfileEnabled: PropTypes.bool.isRequired,
		areHeatMapsFromProfileEnabled: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,
		isSequentialModeEnabled: PropTypes.bool.isRequired,
		isSimpleUiEnabled: PropTypes.bool.isRequired,
		currentImageIsAnalyzing: PropTypes.bool.isRequired,
		teethConflicts: PropTypes.array.isRequired,
		findingsFilter: PropTypes.object.isRequired,
		shouldNotifyThatPatientTooYoung: PropTypes.bool.isRequired,
		onSetFindingsMasks: PropTypes.func.isRequired,
		onFindingsFilterChange: PropTypes.func.isRequired,
		onGoToFmx: PropTypes.func.isRequired,
		onGoToTfv: PropTypes.func.isRequired,
		onSaveImageData: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		onSetHeatMap: PropTypes.func.isRequired,
		onSetInitialFilters: PropTypes.func.isRequired,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_bottomPanelEl = null;

	state = {
		isSidebarExpanded: this.props.isSimpleUiEnabled === false,
		sidebarPanelTopOffset: SIDEBAR_PANEL_OFFSET_Y,
	};

	componentDidMount () {
		// imageFiltersService.init();
		this.props.onSetInitialFilters(this.props.currentCollection, this.props.isSequentialModeEnabled);
	}

	componentDidUpdate (prevProps, prevState) {
		if ( prevState.isSidebarExpanded !== this.state.isSidebarExpanded ) {
			events.emit('sidebar-collapse-changed');
		}

		if ( this.props.isSequentialModeEnabled === true && prevProps.isSequentialModeEnabled === false ) {
			this.props.onSetInitialFilters(this.props.currentCollection, this.props.isSequentialModeEnabled);
		}

		this._checkSidebarPanelSize();
	}

	// componentWillUnmount () {
	// 	imageFiltersService.teardown();
	// }

	/**
	 * @param {HTMLElement|null} element
	 * @private
	 */
	_handleBottomElRef = (element = null) => {
		this._bottomPanelEl = element;
	};

	_handleSidebarSizeChanged = () => {
		this._checkSidebarPanelSize();
	};

	_handleToggleSidebarExpanded = () => {
		if ( this.props.isSimpleUiEnabled === true ) {
			return;
		}

		this.setState({
			isSidebarExpanded: !this.state.isSidebarExpanded,
		});
	};

	_handleSequentialModeButtonClick = () => {
		this.props.onSaveImageData({
			data: {
				is_sequential_viewed: true,
			},
		})
			.then(() => {
				this.props.onFindingsFilterChange({
					pathological: true,
					treatment: false,
					existing: false,
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'An error occurred while saving image data. Please try again',
				});
			});
	};

	_handleAnalyzeButtonClick = () => {
		confirm({
			title: 'Warning',
			message: 'All manual changes will be deleted. Do you want to continue?',
			yesHandler: () => {
				this.props.onAnalyzeImage()
					.catch(() => {
						message({
							title: i18nShared('error.title'),
							titleIcon: 'error',
							message: 'An error occurred while analyzing image. Please try again',
						});
					});
			},
		})
	};

	_checkSidebarPanelSize () {
		const sidebarPanelEl = document.querySelector(`.${sidebarPanelCssClassName}`);
		let offset = 0;
		if ( sidebarPanelEl !== null ) {
			if ( sidebarPanelEl.firstChild !== null ) {
				if ( sidebarPanelEl.firstChild.clientHeight < sidebarPanelEl.clientHeight - (this.state.sidebarPanelTopOffset === 0 ? SIDEBAR_PANEL_OFFSET_Y : 0) ) {
					offset = SIDEBAR_PANEL_OFFSET_Y;
				}
			}
		}

		if ( this.state.sidebarPanelTopOffset !== offset ) {
			this.setState({
				sidebarPanelTopOffset: offset,
			});
		}
	}

	_renderSequentialMode () {
		if ( this.props.isSequentialModeEnabled === false ) {
			return null;
		}

		return (
			<div className={sequentialModeButtonClassName}>
				{this.props.isSequentialModeEnabled === true && (
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.SMALL}
						onClick={this._handleSequentialModeButtonClick}
					>
						Review Investigative Findings
					</Button>
				)}
			</div>
		);
	}

	_renderLayouts () {
		if ( this.props.currentImageIsAnalyzing ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'This app displays some regions of interest for a trained professional to examine possible pathologies',
						}}
					/>
				</Popup>
			);
		}
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={headerCssClassName}>
					<Header
						isFmxModeAvailable={true}
						isFmxModeEnabled={false}
						isTfvModeAvailable={true}
						isTfvModeEnabled={true}
						isTfvModeActive={true}
						onFmxButtonClick={this.props.onGoToFmx}
						onTfvButtonClick={this.props.onGoToTfv}
					/>
				</div>
				<div className={topPanelCssClassName}>
					<div className={editorToolbarCssClassName}>
						<TfvToolbar />
						{this.props.isSimpleUiEnabled === true && (
							<div className={analyzeButtonClassName}>
								<Button
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.SMALL}
									onClick={this._handleAnalyzeButtonClick}
								>Analyze Image</Button>
							</div>
						)}
						<DrawSecondaryBoxMode />
					</div>
				</div>
				<div className={mainContentCssClassName}>
					{this._renderSequentialMode()}
					<div className={imageEditorCssClassName}>
						<div className={notificationsCssClassName}>
							{this.props.teethConflicts.length > 0 && (
								<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR}>
									<div style={{ fontSize: 14, padding: 2 }}>You have unresolved teeth conflicts</div>
								</ResolverImageNotification>
							)}
							{this.props.shouldNotifyThatPatientTooYoung === true && (
								<ResolverImageNotification type={ResolverImageNotification.TYPES.ERROR}>
									<div style={{ fontSize: 14, padding: 2 }}>Patient should be older than 21</div>
								</ResolverImageNotification>
							)}
						</div>
						<div className={listCssClassName}>
							<TfvList />
						</div>
						<div className={navigationCssClassName} ref={this._handleBottomElRef}>
							<TfvNavigation />
						</div>
					</div>
					<div
						className={classnames([
							sidebarCssClassName,
							this.state.isSidebarExpanded === false && `${sidebarCssClassName}__m-collapsed`,
						])}
					>
						<SizeDetector onChange={this._handleSidebarSizeChanged}>
							{(size) => (
								<>
									{this.props.isSimpleUiEnabled === false && (
										<ResolverSidebar
											shouldShowImageInfo={false}
											DentalNotationDiagram={TfvDentalNotationDiagram}
											SidebarButtons={TfvSidebarButtons}
											Findings={TfvSidebarFindings}
											findingsFilter={this.props.findingsFilter}
											onFindingsFilterChange={() => {}}
										/>
									)}
									<div
										className={classnames([
											sidebarPanelCssClassName,
											this.state.isSidebarExpanded === false && `${sidebarPanelCssClassName}__m-bottom`,
											mainConfig.MAIN_SCROLL_CSS_CLASS_NAME,
										])}
										style={{
											top: this.state.sidebarPanelTopOffset,
											bottom: (this._bottomPanelEl !== null ? this._bottomPanelEl.getBoundingClientRect().height : 0),
										}}
									>
										<ResolverSidebarPanel
											allowHeight={size.height - (this._bottomPanelEl !== null ? this._bottomPanelEl.getBoundingClientRect().height : 0)}
											isExpanded={this.state.isSidebarExpanded}
											disabledChartedFindings={this.props.isSequentialModeEnabled === true}
											disabledPreExistingFindings={this.props.isSequentialModeEnabled === true}
											shouldRenderMagnifyingGlass={false}
											areHeatMapsEnabled={this.props.areHeatMapsEnabled}
											shouldRenderHeatMaps={this.props.areHeatMapsFromProfileEnabled}
											areFindingsMasksEnabled={this.props.areFindingsMasksEnabled}
											shouldRenderFindingsMasks={this.props.areFindingsMasksFromProfileEnabled}
											shouldRenderBoneStages={aclService.checkPermission(USER_PERMISSION__IS_SHOW_BONE_LEVELS)}
											shouldRenderPreExistingFindings={aclService.checkPermission(USER_PERMISSION__IS_SHOW_NON_PATHOLOGICAL)}
											shouldRenderChartedFindings={aclService.checkPermission(USER_PERMISSION__IS_SHOW_EXISTING_CHART)}
											shouldRenderShowHideControl={this.props.isSimpleUiEnabled === false}
											onSetFindingsMasks={this.props.onSetFindingsMasks}
											onSetHeatMaps={this.props.onSetHeatMap}
											onToggleExpand={this._handleToggleSidebarExpanded}
											onFindingsFilterChange={this.props.onFindingsFilterChange}
										/>
										<DrawSecondaryBoxMode />
									</div>
								</>
							)}
						</SizeDetector>
					</div>
				</div>
				{this.props.isUserMenuOpened && <UserMenu />}
				{this._renderLayouts()}
			</div>
		);
	}
}
