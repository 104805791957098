import apiUtils from '../appUtils/apiUtils';
import { getRuntimeConfig } from '../appUtils/runtimeConfig';
import commonUtils from '../appUtils/common';

import api from './instance';

import { APP_IMAGE_MODE } from '../constants/imageConstants';

import { getAppImageMode } from '../services/app';


function prepareUrl (url, collectionHashName, imageHashName) {
	return url.replace('{imageHashName}', imageHashName).replace('{collectionHashName}', collectionHashName)
}

function prepareCollectionPromise (query, collectionHashName, imageHashName) {
	const apiParams = apiUtils.getApiParams(query);

	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName));
}

function getAnnotations (collectionHashName, imageHashName, isFDAAnnotationEnabled) {
	const apiParams = getRuntimeConfig().api.urls.images[`${isFDAAnnotationEnabled ? 'fda/' : ''}annotations/read`];
	if ( APP_IMAGE_MODE.TREATMENT_PLAN === getAppImageMode() ) {
		const params = commonUtils.getUserApiParamsForUrl();

		if ( params !== null ) {
			apiParams.url += '?' + params;
		}
	}

	return prepareCollectionPromise(apiParams, collectionHashName, imageHashName);
}

function saveAnnotations (collectionHashName, imageHashName, data, isFDAAnnotationEnabled) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images[`${isFDAAnnotationEnabled ? 'fda/' : ''}annotations/write`]);
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName), data);
}

function getSharedImageByHashName (imageHashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['shared-image/read']);

	return api[apiParams.method](apiParams.url.replace('{imageHashName}', imageHashName));
}

function updateImageData ({ imageHashName, collectionHashName }, imageData) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['image/write']);

	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName), imageData);
}

function touchImage ({ imageHashName, collectionHashName }) {
  const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['image/touch']);
  return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName));
}

function analyzeImage ({ imageHashName, collectionHashName, isFDAAnnotationEnabled, params }) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images[`${isFDAAnnotationEnabled ? 'fda/' : ''}analyze`]);
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName), params);
}

function completeFDAStage ({ imageHashName, collectionHashName }) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images["fda/complete"]);
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName));
}

function uploadImage (options = {}) {
	if ( !options.image ) {
		// @todo add error_prefix
		throw new Error(`uploadImage: Option "image" is required.`);
	}

	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images.upload);
	const form = new FormData();
	for (const opt in options) {
		if ( options.hasOwnProperty(opt) ) {
			form.append(opt, options[opt]);
		}
	}

	return api[apiParams.method](
		apiParams.url,
		form,
		{
			'Content-Type': 'multipart/form-data',
		}
	);
}

function removeImage (collectionHashName, imageHashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['image/remove']);
	const url = prepareUrl(apiParams.url, collectionHashName, imageHashName);
	return api[apiParams.method](url, {});
}

function removeAnnotation (collectionHashName, imageHashName, annotationUuid, params) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['annotation/remove']);
	const url = prepareUrl(apiParams.url, collectionHashName, imageHashName, annotationUuid);
	return api[apiParams.method](url.replace('{annotationUuid}', annotationUuid), params);
}

function shareImage (collectionHashName, imageHashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['image/share']);
	const url = prepareUrl(apiParams.url, collectionHashName, imageHashName);

	return api[apiParams.method](url, {});
}

function getHistory (collectionHashName, imageHashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['history/read']);
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName));
}

function flip (collectionHashName, imageHashName, how, params) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['flip']);

	return api[apiParams.method](apiParams.url.replace('{imageHashName}', imageHashName), {
		...params,
		'how': how,
	});
}

export function getFeedback (collectionHashName, imageHashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['feedback/read']);
	
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName));
}

export function sendFeedback (collectionHashName, imageHashName, params) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['feedback/write']);
	
	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName), params);
}

export function shiftTooth (collectionHashName, imageHashName, params) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.images['tooth/shift']);

	return api[apiParams.method](prepareUrl(apiParams.url, collectionHashName, imageHashName), params);
}


export default {
	getAnnotations,
	saveAnnotations,
	getSharedImageByHashName,
	updateImageData,
	touchImage,
	analyzeImage,
	completeFDAStage,
	uploadImage,
	removeImage,
	removeAnnotation,
	shareImage,
	getHistory,
	flip,
	getFeedback,
	sendFeedback,
	shiftTooth,
};
