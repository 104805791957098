import collectionsSelectors from '../../selectors/collectionsSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';


class LabelsService {
	_store = null;
	
	/**
	 * @type {function|null}
	 * @private
	 */
	_unsubscribeHandler = null;
	
	/**
	 * @type {Collection|null}
	 * @private
	 */
	_currentCollection = null;
	
	/**
	 * @type {CollectionImage|null}
	 * @private
	 */
	_currentImage = null;
	
	/**
	 * @type {Object<LabelClassId,string>}
	 * @private
	 */
	_map = {};
	
	constructor ({ store }) {
		this._store = store;
		
		this._unsubscribeHandler = this._store.subscribe(this._handleStoreChanged);
	}
	
	destroy () {
		if ( this._unsubscribeHandler ) {
			this._unsubscribeHandler();
			this._unsubscribeHandler = null;
		}
	}
	
	/**
	 * @param {LabelClassId} classId
	 */
	getName (classId) {
		return this._map[classId];
	}
	
	/**
	 * @param {Collection} collection
	 * @param {CollectionImage} image
	 * @private
	 */
	_createMap (collection, image) {
		if ( !collection || !image ) {
			this._map = {};
			return;
		}
		
		this._map = (collection.labels || []).reduce((result, labelData) => {
			if ( labelData.image_type === image.image_type ) {
				result[labelData.class_id] = labelData.readable_name;

				const user = userSelectors.selectUserData(this._store.getState());
				const stage1LowerBoundary = typeof user.stage_1_ratio_threshold === 'number'
					? Math.round(user.stage_1_ratio_threshold * 100)
					: 0;

				switch (labelData.class_id) {
					case 'stage_0_bone_loss_line':
						result[labelData.class_id] = result[labelData.class_id].replace('MinRatio%', `${stage1LowerBoundary}%`);
						break;

					case 'stage_1_bone_loss_line':
						result[labelData.class_id] = result[labelData.class_id].replace('MinRatio%', `${stage1LowerBoundary}%`);
						break;
				}
			}
			
			return result;
		}, {});
	}
	
	_handleStoreChanged = () => {
		const storeState = this._store.getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const currentCollection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorData.currentCollectionHashName,
		});

		const currentImage = imagesSelectors.selectImageById(storeState, {
			id: editorData.currentImageId,
		});

		if ( this._currentCollection !== currentCollection || this._currentImage !== currentImage ) {
			this._currentCollection = currentCollection;
			this._currentImage = currentImage;
			this._createMap(currentCollection, currentImage);
		}
	};
}

let instance = null;

export function init (store) {
	instance = new LabelsService({ store });
}

/**
 * @param {LabelClassId} classId
 * @return {null|*}
 */
export function getLabelName (classId) {
	if ( !instance ) {
		return null;
	}
	
	return instance.getName(classId);
}
