import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';
import labelsUtils from '../../appUtils/labelsUtils';

import Tooltip from '../Tooltip';

import { getDictionary } from '../../appUtils/locale';

import './styles/ShapeTooltip.css';


const i18n = getDictionary('box-tooltip');

const baseCssClassName = 'box-tooltip';
const toothCssClassName = `${baseCssClassName}__tooth`;
const toothKeyCssClassName = `${baseCssClassName}__tooth-key`;
const findingsCssClassName = `${baseCssClassName}__findings`;
const findingsItemCssClassName = `${baseCssClassName}__findings-item`;
const findingsItemTagsCssClassName = `${baseCssClassName}__findings-item-tags`;
const findingsItemTagCssClassName = `${baseCssClassName}-findings-item-tag`;
const findingsItemTagIconCssClassName = `${findingsItemTagCssClassName}__icon`;
const findingsItemTagValueCssClassName = `${findingsItemTagCssClassName}__value`;


class ShapeTooltip extends Component {
	static propTypes = {
		component: PropTypes.func.isRequired,
		componentProps: PropTypes.object.isRequired,
		label: PropTypes.object.isRequired,
		viewport: PropTypes.object.isRequired,
		showToothKey: PropTypes.bool,
		labelColorFilterFn: PropTypes.func,
		withMeasureOfConfidence: PropTypes.bool,
		onGetToothKey: PropTypes.func,
	};

	static defaultProps = {
		labelColorFilterFn: () => true,
		withMeasureOfConfidence: true,
	};

	constructor(props, context) {
		super(props, context);

		this._componentEl = null;

		this.state = {};
	}

	componentWillUnmount () {
		this.state = null;
	}

	_handleComponentRef = (el) => {
		this._componentEl = el;
		this.forceUpdate();
	}

	_renderTooth () {
		if ( !this.props.onGetToothKey ) {
			return null;
		}

		return (
			<div className={toothCssClassName}>
				<div
					className={classnames([
						toothKeyCssClassName,
					])}
					style={{
						borderColor: labelsUtils.getLabelColor(this.props.label, this.props.labelColorFilterFn),
					}}
				>{this.props.onGetToothKey()}</div>
			</div>
		);
	}

	_renderFinding = (label) => {
		const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);

		const content = [(
			<div
				key={labelGetters.getLabelId(label)}
				className={findingsItemCssClassName}
			>
				{labelsUtils.getLocalizedLabelName(label)}
				{
					!labelsUtils.labelIsTooth(label) &&
					this.props.withMeasureOfConfidence === true &&
					labelsUtils.shouldShowConfidenceForClass(labelGetters.getLabelClassId(label)) === true &&
					typeof measureOfConfidence === 'number' &&
					measureOfConfidence !== 1 &&
					` (AI Confidence ${Math.floor(measureOfConfidence * 100)}%)`
				}
				{ !labelsUtils.labelIsTooth(label) && label.tags && label.tags.length > 0 && (
					<div className={findingsItemTagsCssClassName}>
						{label.tags.map((tag) => (
							<div className={findingsItemTagCssClassName} key={labelTagGetter.getTagId(tag)}>
								<div className={findingsItemTagIconCssClassName} />
								<div className={findingsItemTagValueCssClassName}>{labelTagGetter.getLocalizedTagName(tag)}</div>
							</div>
						))}
					</div>
				)}
			</div>
		)];

		if ( label.children ) {
			label.children.forEach((childLabel) => {
				content.push(this._renderFinding(childLabel));
			});
		}

		return content;
	}

	_renderFindings () {
		if ( labelsUtils.labelIsTooth(this.props.label) ) {
			return (this.props.label.children || []).map(this._renderFinding);
		}

		return this._renderFinding(this.props.label);
	}

	_renderTooltip () {
		if ( !this._componentEl ) {
			return null;
		}

		const label = this.props.label;
		const isTooth = labelsUtils.labelIsTooth(label);

		return (
			<Tooltip
				target={this._componentEl}
				viewport={this.props.viewport}
				isGlobal
			>
				<div className={baseCssClassName}>
					{isTooth && this._renderTooth()}
					<div className={findingsCssClassName}>
						{this._renderFindings()}
					</div>
				</div>
			</Tooltip>
		);
	}

	render () {
		const {
			component: Component,
			componentProps,
		} = this.props;

		return (
			<Fragment>
				<Component {...componentProps} onGetRef={this._handleComponentRef} />
				{this._renderTooltip()}
			</Fragment>);
	}
}

export default ShapeTooltip;
