const ACTION_EDITOR__CURRENT_IMAGE_SET_ID = 'ACTION_EDITOR__CURRENT_IMAGE_SET_ID';
const ACTION_EDITOR__CURRENT_IMAGE_UNSET_ID = 'ACTION_EDITOR__CURRENT_IMAGE_UNSET_ID';
const ACTION_EDITOR__CURRENT_IMAGE_IS_LOADED = 'ACTION_EDITOR__CURRENT_IMAGE_IS_LOADED';
const ACTION_EDITOR__FINDINGS_TOGGLE_SHOW_ALL = 'ACTION_EDITOR__FINDINGS_TOGGLE_SHOW_ALL';
const ACTION_EDITOR__UPDATE_DATA = 'ACTION_EDITOR__UPDATE_DATA';
const ACTION_EDITOR__CURRENT_IMAGE_ANALYZE = 'ACTION_EDITOR__CURRENT_IMAGE_ANALYZE';
const ACTION_EDITOR__CURRENT_IMAGE_ANALYZE_ERROR = 'ACTION_EDITOR__CURRENT_IMAGE_ANALYZE_ERROR';
const ACTION_EDITOR__SET_USER_MENU_VISIBILITY = 'ACTION_EDITOR__SET_USER_MENU_VISIBILITY';


export default {
	ACTION_EDITOR__CURRENT_IMAGE_SET_ID,
	ACTION_EDITOR__CURRENT_IMAGE_UNSET_ID,
	ACTION_EDITOR__CURRENT_IMAGE_IS_LOADED,
	ACTION_EDITOR__FINDINGS_TOGGLE_SHOW_ALL,
	ACTION_EDITOR__UPDATE_DATA,
	ACTION_EDITOR__CURRENT_IMAGE_ANALYZE,
	ACTION_EDITOR__CURRENT_IMAGE_ANALYZE_ERROR,
	ACTION_EDITOR__SET_USER_MENU_VISIBILITY,
};
