/**
 * @enum {string}
 */
export const FloatAxis = window.FloatAxis = {
	vertical: 'v',
	horizontal: 'h',
};

/**
 * @enum {string}
 */
export const FloatPosition = window.FloatPosition = {
	start: 'start',
	center: 'center',
	end: 'end',
};

export function appendAnchorPosition (position) {
	position.anchorPosition = {
		absolute: { x: position.anchorLeft, y: position.anchorTop },
		relative: {
			x: (
				position.adjustedX > position.anchorLeft
					? position.adjustedX - position.anchorLeft - position.targetAnchorOffset
					: position.anchorLeft - position.adjustedX - position.targetAnchorOffset
			),
			y: (
				position.adjustedY > position.anchorTop
					? position.adjustedY - position.anchorTop - position.targetAnchorOffset
					: position.anchorTop - position.adjustedY - position.targetAnchorOffset
			),
		},
	};

	position.anchorPosition.relative.x -= ( position.mainAxis === FloatAxis.vertical ? 0 : position.mainAxisOffset );
	position.anchorPosition.relative.y -= ( position.mainAxis === FloatAxis.horizontal ? 0 : position.mainAxisOffset );
}

/**
 * @param {FloatPositionDescriptor} position
 * @return {FloatPositionDescriptor[]}
 */
function generateDefaultAlternatePositions (position) {
	switch (position.mainAxis) {
		case FloatAxis.horizontal: {
			if ( FloatPosition.start === position.mainAxisPosition ) {
				return [
					{
						...position,
						mainAxisPosition: window.FloatPosition.end,
					},
					{
						...position,
						mainAxis: FloatAxis.vertical,
						mainAxisPosition: window.FloatPosition.start,
					},
					{
						...position,
						mainAxis: FloatAxis.vertical,
						mainAxisPosition: window.FloatPosition.end,
					},
				];
			}
			return [
				{
					...position,
					mainAxisPosition: window.FloatPosition.start,
				},
				{
					...position,
					mainAxis: FloatAxis.vertical,
					mainAxisPosition: window.FloatPosition.start,
				},
				{
					...position,
					mainAxis: FloatAxis.vertical,
					mainAxisPosition: window.FloatPosition.end,
				},
			];
		}

		case FloatAxis.vertical: {
			if ( FloatPosition.start === position.mainAxisPosition ) {
				return [
					{
						...position,
						mainAxisPosition: window.FloatPosition.end,
					},
					{
						...position,
						mainAxis: FloatAxis.horizontal,
						mainAxisPosition: window.FloatPosition.start,
					},
					{
						...position,
						mainAxis: FloatAxis.horizontal,
						mainAxisPosition: window.FloatPosition.end,
					},
				];
			}
			return [
				{
					...position,
					mainAxisPosition: window.FloatPosition.start,
				},
				{
					...position,
					mainAxis: FloatAxis.horizontal,
					mainAxisPosition: window.FloatPosition.start,
				},
				{
					...position,
					mainAxis: FloatAxis.horizontal,
					mainAxisPosition: window.FloatPosition.end,
				},
			];
		}

		default:
			return [];
	}
}

/**
 * @return {{ left: number, top: number, right: number, bottom: number }}
 */
function getDefaultViewportLocation () {
	const location = window.document.body.getBoundingClientRect();

	return {
		left: location.left,
		top: location.top,
		right: window.scrollX + location.left + (location.right - location.left),
		bottom: window.scrollY + location.top + (location.bottom - location.top),
	};
}

/**
 * @typedef {Object} FloatPositionDescriptor
 *
 * @property {FloatAxis} [mainAxis]
 * @property {FloatPosition} [mainAxisPosition]
 * @property {FloatPosition} [secondaryAxisPosition]
 * @property {FloatPosition} [targetAnchorPosition]
 * @property {number} [mainAxisOffset]
 * @property {number} [secondaryAxisOffset]
 * @property {number} [targetAnchorOffset]
 * @property {number} x
 * @property {number} y
 * @property {number} width
 * @property {number} height
 * @property {number} visibleWidth
 * @property {number} visibleHeight
 * @property {number} adjustedX
 * @property {number} adjustedY
 * @property {number} adjustedWidth
 * @property {number} adjustedHeight
 * @property {number} area
 * @property {number} visibleArea
 * @property {number} adjustedArea
 */

const defaultOptions = Object.freeze({
	initialPosition: {
		mainAxis: window.FloatAxis.vertical,
		mainAxisPosition: window.FloatPosition.end,
		secondaryAxisPosition: window.FloatPosition.center,
		targetAnchorPosition: window.FloatPosition.center,
		mainAxisOffset: 0,
		secondaryAxisOffset: 0,
		targetAnchorOffset: 0,
	},
	alternatePositions: [],
	allowAlternatePositions: true,
	allowToAdjust: true,
});

/**
 * @param {Object} options
 * @param {HTMLElement} options.target
 * @param {HTMLElement} options.popup
 * @param {{ left:number, top:number, right: number, bottom:number }} [options.viewportLocation]
 * @param {FloatPositionDescriptor} [options.initialPosition]
 * @param {FloatPositionDescriptor[]} [options.alternatePositions]
 * @param {boolean} [options.allowAlternatePositions]
 * @param {boolean} [options.allowToAdjust]
 */
export default function float (options) {
	const _options = {
		...defaultOptions,
		...options,
		initialPosition: {
			...defaultOptions.initialPosition,
			...options.initialPosition,
		},
	};

	if ( _options.allowAlternatePositions && 0 === _options.alternatePositions.length ) {
		_options.alternatePositions = generateDefaultAlternatePositions(_options.initialPosition);
	}

	if ( FloatPosition.center === _options.initialPosition.mainAxisPosition ) {
		throw new Error('You try to set "center" value for "mainAxisPosition". It\'s not supported. Choose "start" or "end".');
	}

	const scrollX = (window.pageXOffset || window.document.body.scrollLeft);
	const scrollY = (window.pageYOffset || window.document.body.scrollTop);
	const targetLocation = _options.targetPosition || _options.target.getBoundingClientRect();
	const popupLocation = _options.popup.getBoundingClientRect();
	const viewportLocation = (_options.viewportLocation || getDefaultViewportLocation());

	const viewportWidth = viewportLocation.right - viewportLocation.left;
	const viewportHeight = viewportLocation.bottom - viewportLocation.top;
	const viewportLeft = scrollX + viewportLocation.left;
	const viewportTop = scrollY + viewportLocation.top;
	const viewportRight = viewportLeft + viewportWidth;
	const viewportBottom = viewportTop + viewportHeight;

	const targetWidth = targetLocation.right - targetLocation.left;
	const targetLeft = scrollX + targetLocation.left;
	const targetRight = targetLeft + targetWidth;
	const targetHorizontalCenter = targetLeft + (targetWidth / 2);

	const targetHeight = targetLocation.bottom - targetLocation.top;
	const targetTop = scrollY + targetLocation.top;
	const targetBottom = targetTop + targetHeight;
	const targetVerticalCenter = targetTop + (targetHeight / 2);

	const popupWidth = popupLocation.right - popupLocation.left;
	const popupHeight = popupLocation.bottom - popupLocation.top;

	const anchorMap = {
		[FloatAxis.horizontal]: {
			[FloatPosition.start]: {
				[FloatPosition.start]: [ targetLeft, targetTop ],
				[FloatPosition.center]: [ targetLeft, targetVerticalCenter ],
				[FloatPosition.end]: [ targetLeft, targetBottom ],
			},
			[FloatPosition.end]: {
				[FloatPosition.start]: [ targetRight, targetTop ],
				[FloatPosition.center]: [ targetRight, targetVerticalCenter ],
				[FloatPosition.end]: [ targetRight, targetBottom ],
			},
		},
		[FloatAxis.vertical]: {
			[FloatPosition.start]: {
				[FloatPosition.start]: [ targetLeft, targetTop ],
				[FloatPosition.center]: [ targetHorizontalCenter, targetTop ],
				[FloatPosition.end]: [ targetRight, targetTop ],
			},
			[FloatPosition.end]: {
				[FloatPosition.start]: [ targetLeft, targetBottom ],
				[FloatPosition.center]: [ targetHorizontalCenter, targetBottom ],
				[FloatPosition.end]: [ targetRight, targetBottom ],
			},
		},
	};

	/**
	 * @param {FloatPositionDescriptor} position
	 */
	function getFloatPosition (position) {
		// Initial position.
		let popupLeft = targetLeft;
		let popupTop = targetTop;

		// Align by the main axis.
		switch (position.mainAxis) {
			case FloatAxis.horizontal:
				switch (position.mainAxisPosition) {
					case FloatPosition.start:
						popupLeft -= popupWidth;
						break;

					case FloatPosition.end:
						popupLeft += targetWidth;
						break;

					default:
						break;
				}
				break;

			case FloatAxis.vertical:
				switch (position.mainAxisPosition) {
					case FloatPosition.start:
						popupTop -= popupHeight;
						break;

					case FloatPosition.end:
						popupTop += targetHeight;
						break;

					default:
						break;
				}
				break;

			default:
				break;
		}

		// Calculate anchor position.
		const [ anchorLeft, anchorTop ] = anchorMap[position.mainAxis][position.mainAxisPosition][position.targetAnchorPosition || defaultOptions.initialPosition.targetAnchorPosition];

		// Align by the secondary axis.
		switch (position.mainAxis) {
			case FloatAxis.vertical: {
				switch (position.secondaryAxisPosition) {
					case FloatPosition.start: {
						const popupRight = popupLeft + popupWidth;
						popupLeft -= (popupRight - anchorLeft);
						break;
					}

					case FloatPosition.center: {
						const popupHorizontalCenter = popupLeft + (popupWidth / 2);
						popupLeft -= (popupHorizontalCenter - anchorLeft);
						break;
					}

					case FloatPosition.end: {
						popupLeft += anchorLeft - targetLeft;
						break;
					}

					default:
						break;
				}
				break;
			}

			case FloatAxis.horizontal: {
				switch (position.secondaryAxisPosition) {
					case FloatPosition.start: {
						const popupBottom = popupTop + popupHeight;
						popupTop -= (popupBottom - anchorTop);
						break;
					}

					case FloatPosition.center: {
						const popupVerticalCenter = popupTop + (popupHeight / 2);
						popupTop -= (popupVerticalCenter - anchorTop);
						break;
					}

					case FloatPosition.end: {
						popupTop += anchorTop - targetTop;
						break;
					}

					default:
						break;
				}
				break;
			}

			default:
				break;
		}


		// Apply the offset of the main axis.
		if ( 'number' === typeof position.mainAxisOffset ) {
			switch (position.mainAxis) {
				case FloatAxis.horizontal: {
					switch (position.mainAxisPosition) {
						case FloatPosition.start:
							popupLeft -= position.mainAxisOffset;
							break;

						case FloatPosition.end:
							popupLeft += position.mainAxisOffset;
							break;

						default:
							break;
					}
					break;
				}

				case FloatAxis.vertical: {
					switch (position.mainAxisPosition) {
						case FloatPosition.start:
							popupTop -= position.mainAxisOffset;
							break;

						case FloatPosition.end:
							popupTop += position.mainAxisOffset;
							break;

						default:
							break;
					}
					break;
				}

				default:
					break;
			}
		}

		// Apply the offset of the secondary axis.
		if ( 'number' === typeof position.secondaryAxisOffset ) {
			switch (position.mainAxis) {
				case FloatAxis.horizontal:
					popupTop += position.secondaryAxisOffset;
					break;

				case FloatAxis.vertical:
					popupLeft += position.secondaryAxisOffset;
					break;

				default:
					break;
			}
		}


		return { popupLeft, popupTop, anchorLeft, anchorTop };
	}

	/**
	 * @param {FloatPositionDescriptor} position
	 */
	function adjustPopup (position) {
		if ( position.adjustedX < viewportLeft ) {
			if ( position.mainAxis === FloatAxis.vertical ) {
				position.adjustedX = viewportLeft;
			}
			else {
				const prevX = position.adjustedX;
				position.adjustedX = viewportLeft;
				position.adjustedWidth -= viewportLeft - prevX;
			}
		}
		if ( position.adjustedX + position.adjustedWidth > viewportRight ) {
			if ( position.mainAxis === FloatAxis.vertical ) {
				const diff = (position.adjustedX + position.adjustedWidth) - viewportRight;
				if ( position.adjustedX - diff >= viewportLeft ) {
					position.adjustedX -= diff;
				}
				else {
					const prevX = position.adjustedX;
					position.adjustedX = viewportLeft;
					position.adjustedWidth -= prevX - viewportLeft;
				}
			}
			else {
				position.adjustedWidth -= (position.adjustedX + position.adjustedWidth) - viewportRight;
			}
		}

		if ( position.adjustedY < viewportTop ) {
			if ( position.mainAxis === FloatAxis.vertical ) {
				const prevY = position.adjustedY;
				position.adjustedY = viewportTop;
				position.adjustedHeight -= viewportTop - prevY;
			}
			else {
				position.adjustedY = viewportTop;
			}
		}
		if ( position.adjustedY + position.adjustedHeight > viewportBottom ) {
			if ( position.mainAxis === FloatAxis.vertical ) {
				position.adjustedHeight -= (position.adjustedY + position.adjustedHeight) - viewportBottom;
			}
			else {
				const diff = (position.adjustedY + position.adjustedHeight) - viewportBottom;
				if ( position.adjustedY - diff >= viewportTop ) {
					position.adjustedY -= diff;
				}
				else {
					const prevY = position.adjustedY;
					position.adjustedY = viewportTop;
					position.adjustedHeight -= (prevY - viewportTop);
				}
			}
		}

		position.adjustedArea = position.adjustedWidth * position.adjustedHeight;
	}

	/**
	 * @param {number} popupLeft
	 * @param {number} popupTop
	 */
	function isPopupInsideViewport (popupLeft, popupTop) {
		return (
			popupLeft >= viewportLeft &&
			popupTop >= viewportTop &&
			popupLeft + popupWidth <= viewportRight &&
			popupTop + popupHeight <= viewportBottom
		);
	}

	/**
	 * @param {FloatPositionDescriptor} position
	 * @return {FloatPositionDescriptor}
	 */
	function test (position) {
		const {
			popupLeft: x,
			popupTop: y,
			anchorLeft,
			anchorTop,
		} = getFloatPosition(position);
		position.x = x;
		position.y = y;
		position.width = popupWidth;
		position.height = popupHeight;
		position.visibleWidth = popupWidth;
		position.visibleHeight = popupHeight;
		position.adjustedX = x;
		position.adjustedY = y;
		position.adjustedWidth = popupWidth;
		position.adjustedHeight = popupHeight;
		position.anchorLeft = anchorLeft;
		position.anchorTop = anchorTop;

		if ( isPopupInsideViewport(x, y) === true ) {
			position.area = position.width * position.height;
			position.visibleArea = position.visibleWidth * position.visibleHeight;
			position.adjustedArea = position.adjustedWidth * position.adjustedHeight;

			return position;
		}

		if ( x < viewportLeft ) {
			position.visibleWidth -= viewportLeft - x;
		}

		if ( x + popupWidth > viewportRight ) {
			position.visibleWidth -= (x + popupWidth) - viewportRight;
		}

		if ( y < viewportTop ) {
			position.visibleHeight -= viewportTop - y;
		}

		if ( y + popupHeight > viewportBottom ) {
			position.visibleHeight -= (y + popupHeight) - viewportBottom;
		}

		position.area = position.width * position.height;
		position.visibleArea = position.visibleWidth * position.visibleHeight;
		position.adjustedArea = position.adjustedWidth * position.adjustedHeight;

		return position;
	}

	const result = [];
	const positions = [ _options.initialPosition ].concat(_options.alternatePositions);

	for (let i = 0; i < positions.length; i++) {
		const position = test(positions[i]);

		// @todo-player добавить проверку что целевой элемент внутри вьюпорта

		if ( _options.allowToAdjust === true ) {
			adjustPopup(position);
		}
		result.push(position);
	}

	return result;
}
