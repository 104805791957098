export default {
	'labels.new_email': 'New email',
	'labels.current_password': 'Current password',

	'info': `
		Confirmation e-mail will be send<br />
		to your old e-mail address.<br />
		Please follow the instructions.<br />

		<br />
		You should enter your current password.
	`,

	'dialog.title': 'Change email',
};
