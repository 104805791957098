import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import Button from '../Button';
import Loading from '../Loading';

import { SUBMIT_STATUSES } from './PasswordForgotConstants';

import './styles/PasswordForgot.css';


const baseCssClassName = 'password-forgot';
const wrapperCssClassName = `${baseCssClassName}__wrapper`;
const formCssClassName = `${baseCssClassName}__form`;
const headerCssClassName = `${baseCssClassName}__header`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const errorCssClassname = `${baseCssClassName}__error`;
const successCssClassname = `${baseCssClassName}__success`;


export default class PasswordForgot extends PureComponent {
	static propTypes = {
		email: PropTypes.string.isRequired,
		submitStatus: PropTypes.oneOf(Object.values(SUBMIT_STATUSES)).isRequired,
		onEmailChange: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleEmailChange = (event) => {
		this.props.onEmailChange(event.target.value);
	};

	_renderForm () {
		return (
			<form className={formCssClassName} onSubmit={this.props.onSubmit}>
				<h2 className={headerCssClassName}>Forgot password</h2>
				<FormLabel
					element={
						(
							<FormInput
								name={'email'}
								value={this.props.email}
								type={'email'}
								required
								block
								disabled={this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS}
								onChange={this._handleEmailChange}
							/>
						)
					}
					text={'Email'}
					textPosition={FormLabel.textPosition.TOP}
				/>
				{this.props.submitStatus === SUBMIT_STATUSES.FAILED && (
					<div
						className={errorCssClassname}
						dangerouslySetInnerHTML={{ __html: 'An error occurred when sending the link. Please try again.' }}
					/>
				)}
				<div className={buttonsCssClassName}>
					{this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
						? (<Loading width={40} height={40} />)
						: (
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.MEDIUM}
								type={Button.AVAILABLE_TYPES.SUBMIT}
								disabled={
									this.props.email.length === 0 ||
									this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
								}
							>Send reset link</Button>
						)
					}
				</div>
			</form>
		);
	}

	_renderContent () {
		switch (this.props.submitStatus) {
			case SUBMIT_STATUSES.SUCCESS:
				return (
					<div className={successCssClassname}>The link was successfully sent. Please check your email.</div>
				);

			default:
				return this._renderForm();
		}
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={wrapperCssClassName}>
					{this._renderContent()}
				</div>
			</div>
		);
	}
}
