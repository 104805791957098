import React from 'react';
import PropTypes from 'prop-types';
import Datepicker, { getMaxDate, getMinDate } from '../../../../components/Datepicker';
import momentUtils from '../../../../appUtils/momentUtils';
import Button from '../../../../components/Button';
import PopupDialog from '../../../../components/PopupDialog';
import HuddleInput, { HuddleInputContent } from '../input';

import './styles/HuddleDatepicker.css';

const baseCssClassName = 'ui-huddle-datepicker';
const datepickerIconCssClassName = `${baseCssClassName}__datepicker-icon`;


const HuddleDatepicker = (props) => {
	const [ isOpened, setIsOpened ] = React.useState(false);

	const handleInputClick = React.useCallback(() => {
		setIsOpened(true);
	}, []);

	const handleClose = React.useCallback(() => {
		setIsOpened(false);
	}, []);

	const handleDateChange = React.useCallback((date) => {
		props.onDayChange(date);
		setIsOpened(false);
	}, []);

	const nativeDate = new Date(props.date);

	return (
		<>
			<HuddleInput onClick={handleInputClick}>
				<HuddleInputContent>
					{momentUtils.isTodayDate({ date: nativeDate })
						? 'Today'
						: momentUtils.getMomentForDate({ date: nativeDate }).format('L')
					}
				</HuddleInputContent>
				<div className={datepickerIconCssClassName} />
			</HuddleInput>
			{isOpened && (
				<PopupDialog
					headerProps={{
						title: 'Select date',
					}}
					content={(
						<Datepicker
							month={nativeDate}
							selectedDays={[ nativeDate ]}
							minDate={new Date()}
							onDayClick={handleDateChange}
						/>
					)}
					footerProps={{
						buttons: ({ popup: { close } }) => {
							return [
								(
									<Button
										key={'popup_cancel'}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										onClick={close}
									>{'Cancel'}</Button>
								),
							];
						},
					}}
					popupProps={{
						onClose: handleClose,
					}}
				/>
			)}
		</>
	);
};

HuddleDatepicker.propTypes = {
	date: PropTypes.number.isRequired,
	onDayChange: PropTypes.func.isRequired,
};

export default HuddleDatepicker;
