import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import labelsUtils from '../../appUtils/labelsUtils';

import './styles/ImageShapes.css';


class Objects {
	_objects = {};

	_id = 0;

	destroy () {
		this._objects.length = 0;
	}

	/**
	 * @param {*} objectData
	 * @param {string} id
	 *
	 * @return {number}
	 */
	add (objectData, id= this._id++) {
		objectData.__id = id;
		this._objects[id] = objectData;
		return id;
	}

	/**
	 * @param {number} id
	 * @return {*|Array<*>}
	 */
	get (id) {
		if ( typeof id !== 'undefined' ) {
			return this._objects[id] || null;
		}

		return Object.values(this._objects);
	}

	/**
	 * @param {number} index
	 * @param {*} objectData
	 */
	update (index, objectData) {
		this._objects[index] = objectData;
	}

	/**
	 * @param {number} id
	 */
	remove (id) {
		delete this._objects[id];
	}
}

const baseCssClassName = 'canvas-objects';
const svgCssClassName = `${baseCssClassName}__svg`;


export default class CanvasObjects extends PureComponent {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		isSelectable: PropTypes.bool,
		isPointInObject: PropTypes.func,
		getBoundingRectForObject: PropTypes.func,
		isVisible: PropTypes.bool,
		onTouched: PropTypes.func,
	};

	static defaultProps = {
		isSelectable: true,
		isPointInObject: labelsUtils.isCursorInShape,
		getBoundingRectForObject: labelsUtils.getBoundingRectForShape,
		isVisible: true,
		onTouched: () => {},
	};

	_objects = new Objects();

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	state = {
		intersectingObjectsWithPoint: [],
		closestObjectToPoint: null,
		selectedObjectId: null,
		isSelectedObjectInTransformation: false,
	};

	componentWillUnmount () {
		this._baseEl.removeEventListener('mousemove', this._handleMouseMove);
		this._baseEl.removeEventListener('touchmove', this._handleTouchMove);
		this._baseEl.removeEventListener('mousedown', this._handleMouseDown);
		this._baseEl.removeEventListener('touchstart', this._handleTouchStart);
		this._baseEl.removeEventListener('mouseleave', this._handleMouseLeave);
		this._objects.destroy();
	}

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handleRef = (element) => {
		if ( element === null || element === undefined || element === this._baseEl ) {
			return;
		}

		this._baseEl = element;
		this._baseEl.addEventListener('mousemove', this._handleMouseMove);
		this._baseEl.addEventListener('touchmove', this._handleTouchMove);
		this._baseEl.addEventListener('mousedown', this._handleMouseDown);
		this._baseEl.addEventListener('touchstart', this._handleTouchStart);
		this._baseEl.addEventListener('mouseleave', this._handleMouseLeave);
		this.forceUpdate();
	};

	/**
	 * @param {number} x
	 * @param {number} y
	 * @return {number[]}
	 * @private
	 */
	_handleGetRelativeCoords = (x, y) => {
		return this._getRelativeCoords(x, y);
	};

	/**
	 * @param {number} x
	 * @param {number} y
	 * @return {number[]}
	 * @private
	 */
	_handleGetRealCoords = (x, y) => {
		return this._getRealCoords(x, y);
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleMouseMove = (event) => {
		if ( this.state.selectedObjectId !== null ) {
			return;
		}
		const [ intersectingObjectsWithPoint, closestObjectToPoint ] = this._getIntersectingObjectsWithPoint(event.clientX, event.clientY);
		this.setState({
			intersectingObjectsWithPoint,
			closestObjectToPoint,
		});
	};

	/**
	 * @param {TouchEvent} event
	 * @private
	 */
	_handleTouchStart = (event) => {
		event.preventDefault();
		const touch = event.touches[0];

		const [ intersectingObjectsWithPoint, closestObjectToPoint ] = this._getIntersectingObjectsWithPoint(touch.clientX, touch.clientY);
		const [ x, y ] = this._getRealCoords(touch.clientX, touch.clientY);
		if (
			closestObjectToPoint !== null &&
			this.props.isPointInObject(closestObjectToPoint, x, y) === true
		) {
			this.props.onTouched(closestObjectToPoint.__id);
			this.setState({
				selectedObjectId: closestObjectToPoint.__id,
			});
			return;
		}

		this.setState({
			selectedObjectId: null,
			closestObjectToPoint: null,
			intersectingObjectsWithPoint: [],
		});
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleMouseDown = (event) => {
		if ( this.props.isSelectable === false ) {
			return;
		}

		if ( this.state.closestObjectToPoint !== null ) {
			this.props.onTouched(this.state.closestObjectToPoint.__id);
			this.setState({
				selectedObjectId: this.state.closestObjectToPoint.__id,
				closestObjectToPoint: null,
				intersectingObjectsWithPoint: [],
			});
		}
		else {
			const [ x, y ] = this._getRealCoords(event.clientX, event.clientY);
			const selectedObject = this._objects.get(this.state.selectedObjectId);

			if (
				selectedObject !== null &&
				this.props.isPointInObject(selectedObject, x, y) === true
			) {
				this.props.onTouched(this.state.selectedObjectId);
				return;
			}
			this.setState({
				selectedObjectId: null,
			});
			this.props.onTouched(null);
		}
	};

	_handleMouseLeave = () => {
		if ( this.state.isSelectedObjectInTransformation === true ) {
			return;
		}

		this.setState({
			closestObjectToPoint: null,
			intersectingObjectsWithPoint: [],
		});
	};

	/**
	 * @param {boolean} value
	 * @private
	 */
	_handleSetSelectedObjectTransformation = (value) => {
		this.setState({
			isSelectedObjectInTransformation: value,
		});
	};

	/**
	 * @param {Object} [selectedObject]
	 * @private
	 */
	_handleSetSelectedObject = (selectedObject) => {
		this.setState({
			selectedObjectId: selectedObject !== null ? selectedObject.__id : null,
		});
	};

	/**
	 * @param {number} x
	 * @param {number} y
	 * @return {number[]}
	 * @private
	 */
	_getRelativeCoords = (x, y) => {
		const viewportRect = this._baseEl.getBoundingClientRect();

		return [ x - viewportRect.left, y - viewportRect.top ];
	};

	/**
	 * @param {number} x
	 * @param {number} y
	 * @return {number[]}
	 * @private
	 */
	_getRealCoords = (x, y) => {
		const result = this._getRelativeCoords(x, y);

		return [ result[0] / this.props.zoom, result[1] / this.props.zoom ];
	};

	/**
	 * @param {number} absX The absolute x coordinate.
	 * @param {number} absY The absolute y coordinate.
	 * @return {[][]}
	 * @private
	 */
	_getIntersectingObjectsWithPoint (absX, absY) {
		const [ x, y ] = this._getRealCoords(absX, absY);
		const matches = [];
		const objects = this._objects.get();
		for (let i = 0, l = objects.length; i < l; i++) {
			const object = objects[i];

			if ( this.props.isPointInObject(object, x, y) === true ) {
				matches.push({
					object,
					rect: this.props.getBoundingRectForObject(object),
				});
			}
		}

		if ( matches.length === 0 ) {
			return [ matches, null ];
		}
		if ( matches.length === 1 ) {
			return [ matches, matches[0].object ];
		}

		const closestBox = matches.reduce((closest, match, index) => {
			if ( index === 0 ) {
				return closest;
			}

			const rectCenterX = match.rect.left + ( ( match.rect.right - match.rect.left ) / 2 );
			const rectCenterY = match.rect.top + ( ( match.rect.bottom - match.rect.top ) / 2 ) ;

			const resultCenterX = closest.rect.left + ( ( closest.rect.right - closest.rect.left ) / 2 );
			const resultCenterY = closest.rect.top + ( ( closest.rect.bottom - closest.rect.top ) / 2 );

			// Find closest box.
			// Calculate distance between two points: http://www.mathwarehouse.com/algebra/distance_formula/index.php
			const distanceBetweenCenterAndPointOne = Math.sqrt(Math.pow(rectCenterX - x, 2) + Math.pow(rectCenterY - y, 2));
			const distanceBetweenCenterAndPointTwo = Math.sqrt(Math.pow(resultCenterX - x, 2) + Math.pow(resultCenterY - y, 2));
			if ( distanceBetweenCenterAndPointOne < distanceBetweenCenterAndPointTwo ) {
				return match;
			}

			return closest;
		}, matches[0]);

		return [ matches, closestBox.object ];
	}

	render () {
		const api = {
			viewport: this._baseEl,
			zoom: this.props.zoom,
			objects: this._objects,
			intersectingObjectsWithPoint: this.state.intersectingObjectsWithPoint,
			closestObjectToPoint: this.state.closestObjectToPoint,
			selectedObject: this.state.selectedObjectId !== null
				? this._objects.get(this.state.selectedObjectId)
				: null
			,
			isSelectedObjectInTransformation: this.state.isSelectedObjectInTransformation,
			getRelativeCoords: this._handleGetRelativeCoords,
			getRealCoords: this._handleGetRealCoords,
			setSelectedObjectTransformation: this._handleSetSelectedObjectTransformation,
			setSelectedObject: this._handleSetSelectedObject,
		};

		return (
			<div
				className={baseCssClassName}
				style={{
					position: 'absolute',
					inset: 0,
					display: this.props.isVisible === true ? 'block': 'none',
				}}
				ref={this._handleRef}
			>
				<svg xmlns={'http://www.w3.org/2000/svg'} className={svgCssClassName} style={{ width: '100%', height: '100%' }}>
					{this.props.children(api)}
				</svg>
			</div>
		);
	}
}
