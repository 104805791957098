let runtimeConfig = null;

/**
 * Sets a new runtime config.
 * @param {Object} config
 */
export function setRuntimeConfig (config) {
	runtimeConfig = config;
}

/**
 * Returns runtime config.
 * @returns {Object}
 */
export function getRuntimeConfig () {
	return runtimeConfig;
}
