import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMaxDate, getMinDate } from '../Datepicker';
import {
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__UNKNOWN,
	IMAGE_TYPE__CEPHALOMETRIC,
} from '../../constants/imageConstants';

import {
	USER_PERMISSION__FLIP_IMAGE,
	USER_PERMISSION__ROTATE_IMAGE,
} from '../../constants/userPermissionsConstants';

import aclService from '../../services/acl';

import ImagesCache from '../../services/images-cache';
import FormLabel from '../FormLabel';
import mainConfig from '../../configs/mainConfig';
import momentUtils from '../../appUtils/momentUtils';
import commonUtils from '../../appUtils/common';
import { FlipHorizontal, FlipVertical, RotateLeft, RotateRight } from '../Toolbar';
import TextField from '@material-ui/core/TextField';
import FormDatepicker from '../FormDatepicker';

import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';
import Loading from '../Loading';

import { getDictionary } from '../../appUtils/locale';
import { withStyles } from '@material-ui/core/styles';

import './styles/ImageUploadingForm.css';


const baseCssClassName = 'image-uploading-form';
const imageContainerCssClassName = `${baseCssClassName}__image-container`;
const imageCssClassName = `${baseCssClassName}__image`;
const formCssClassName = `${baseCssClassName}__form`;
const formRowCssClassName = `${baseCssClassName}__form-row`;
const formItemCssClassName = `${baseCssClassName}__form-item`;
const notificationCssClassName = `${baseCssClassName}__notification`;

const styles = theme => ({
	input: {
		fontSize: '16px'
	}
});

const i18n = getDictionary('image-uploading-form');
const i18nShared = getDictionary('shared');


class ImageUploadingContent extends React.PureComponent {
	static propTypes = {
		availableImageTypes: PropTypes.array,
		allowedImageTypes: PropTypes.array,
	};

	static defaultProps = {
		availableImageTypes: mainConfig.AVAILABLE_IMAGE_TYPES,
		allowedImageTypes: mainConfig.ALLOWED_IMAGE_TYPES,
	};

	_renderNotification () {
		let msg = null;

		if ( !this.props.predicted_type ) {
			return null;
		}

		switch (this.props.predicted_type.toLocaleLowerCase()) {
			case IMAGE_TYPE__CEPHALOMETRIC:
				msg = i18n('notifications.ceph-type');
				break;
				
			case IMAGE_TYPE__PAN_CHILD:
				msg = i18n('notifications.child-type');
				break;
			case IMAGE_TYPE__UNKNOWN:
				msg = i18n('notifications.unknown-type');
				break;

			default:
				return null;
		}

		return (
			<div className={formRowCssClassName}>
				<div className={notificationCssClassName}>{msg}</div>
			</div>
		);
	}
	
	render() {
		const {
			image_id,
			image_url,
			image_cache,
			image_type,
			image_date,
			patient_id,
			birthday,
			gender,
			sensor,
			custom_sensor,
			classes,
			isValid,
			validateField,
			handleFlipHorizontalClick,
			handleFlipVerticalClick,
			handleRotateLeftClick,
			handleRotateRightClick,
			handleValueChange,
			handleImageDateChange,
			handleBirthdayChange,
			handleLoad
		} = this.props;

		const isFlipAvailable = aclService.checkPermission(USER_PERMISSION__FLIP_IMAGE);
		const isRotateAvailable = aclService.checkPermission(USER_PERMISSION__ROTATE_IMAGE);

		return (
			<div className={baseCssClassName}>
				<div className={imageContainerCssClassName}>
					<img
						className={imageCssClassName}
						src={commonUtils.addParamToUrl(image_url, 'seed', ImagesCache.hasCache(image_id) ? String(ImagesCache.getCache(image_id)) : '')}
						alt={i18n('image.alt')}
						onLoad={handleLoad}
					/>
					{this.props.imageFlipping === image_url && <Loading />}
				</div>
				<div className={formCssClassName}>
					{this._renderNotification()}
					{(isFlipAvailable || isRotateAvailable) && (
						<div className={formRowCssClassName}>
							{isFlipAvailable && (
								<>
									<FlipHorizontal action={handleFlipHorizontalClick} color='light' />
									<FlipVertical action={handleFlipVerticalClick} color='light' />
								</>
							)}
							{isRotateAvailable && (
								<>
									<RotateLeft action={handleRotateLeftClick} color='light' />
									<RotateRight action={handleRotateRightClick} color='light' />
								</>
							)}
						</div>
					)}
					<div className={formRowCssClassName}>
						<div className={formItemCssClassName}>
							<TextField
								id='image_type'
								name='image_type'
								label={i18n('labels.image_type')}
								select
								value={image_type}
								required
								onChange={handleValueChange}
								InputLabelProps={{
									shrink: true,
									className: classes.input
								}}
								SelectProps={{
									native: true
								}}
							>
								<option value={''} style={{ color: 'black' }}>Select type</option>
								{this.props.availableImageTypes.map(type => {
									const isDisabled = this.props.allowedImageTypes.includes(type) === false;
									return (
										(
											<option
												style={{ color: isDisabled === false ? 'black' : '#8b8a8a' }}
												key={type}
												value={type}
												disabled={isDisabled}
											>
												{i18nShared(`image_types.${type}`)}
											</option>
										)
									);
								})}
							</TextField>
						</div>
						<div className={formItemCssClassName}>
							<FormLabel
								element={
									<FormDatepicker
										name={'image_date'}
										date={image_date}
										datepickerProps={{
											minDate: getMinDate(),
											maxDate: getMaxDate()
										}}
										onDateChange={handleImageDateChange}
									/>
								}
								labelWrapperStyle={{ marginTop: '5px' }}
								text={i18n('labels.image_date')}
								textPosition={FormLabel.textPosition.TOP}
							/>
						</div>
						<div className={formItemCssClassName}>
							<TextField
								id={'sensor'}
								name={'sensor'}
								label={'Sensor'}
								select
								value={sensor}
								InputLabelProps={{
									shrink: true,
									className: classes.input,
								}}
								SelectProps={{
									native: true,
								}}
								onChange={handleValueChange}
							>
								{this.props.sensors.map((data) => (
									<option
										key={data.id}
										value={data.id}
										style={{ color: 'black' }}
									>{data.name}</option>
								))}
							</TextField>
						</div>
						<div className={formItemCssClassName}>
							<TextField
								id='custom_sensor'
								InputLabelProps={{
									shrink: true,
									className: classes.input
								}}
								name={'custom_sensor'}
								label={'Custom sensor'}
								value={custom_sensor}
								disabled={sensor !== 'custom'}
								onChange={handleValueChange}
							/>
						</div>
					</div>
					<div className={formRowCssClassName}>
						<div className={formItemCssClassName}>
							<TextField
								error={validateField('patient_id') === false}
								id='patient_id'
								InputLabelProps={{
									shrink: true,
									className: classes.input
								}}
								name={'patient_id'}
								label={i18n('labels.patient_id')}
								value={patient_id.value}
								onChange={handleValueChange}
								helperText={isValid
									? (<span dangerouslySetInnerHTML={{ __html: 'Please use only letters, digits<br/>or underscore sign' }} />)
									: ''
								}
							/>
						</div>
						<div className={formItemCssClassName}>
							<TextField
								id='gender'
								name='gender'
								label={i18n('labels.gender')}
								select
								value={gender}
								onChange={handleValueChange}
								InputLabelProps={{
									shrink: true,
									className: classes.input
								}}
								SelectProps={{
									native: true
								}}
								fullWidth
							>
								{mainConfig.GENDERS.map(type => (
									<option style={{ color: 'black' }} key={type} value={type}>
										{i18nShared(`gender.${type}`)}
									</option>
								))}
							</TextField>
						</div>
						<div className={formItemCssClassName}>
							<FormLabel
								element={
									<FormDatepicker
										name={'birthday'}
										date={birthday}
										datepickerProps={{
											minDate: new Date(
												momentUtils
													.getNowMoment()
													.subtract('110', 'years')
													.valueOf()
											),
											maxDate: getMaxDate()
										}}
										onDateChange={handleBirthdayChange}
									/>
								}
								labelWrapperStyle={{ marginTop: '5px' }}
								text={i18n('labels.birthday')}
								textPosition={FormLabel.textPosition.TOP}
							/>
						</div>
					</div>
				</div>
				<div style={{ fontSize: 12, color: '#c9cbd2', padding: '2px 0' }}>* Required fields</div>
			</div>
		);
	}
}


export default
connect(
	state => {
		const editorData = editorSelectors.selectEditor(state);
		const usesComputerAidedDeviceUi = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
		let availableImageTypes = mainConfig.AVAILABLE_IMAGE_TYPES;

		if ( usesComputerAidedDeviceUi === true ) {
			availableImageTypes = availableImageTypes.filter((imageType) => (
				imageType !== IMAGE_TYPE__CEPHALOMETRIC &&
				imageType !== IMAGE_TYPE__PAN_CHILD &&
				imageType !== IMAGE_TYPE__UNKNOWN
			));
		}

		return {
			availableImageTypes,
			imageFlipping: editorData.imageFlipping
		};
	})
(withStyles(styles)(ImageUploadingContent));
