import lodashGet from 'lodash/get';


/**
 * @implements {HelpMessenger}
 */
export default class CrispHelpMessenger {
	setup (options) {
		this._loadScript()
			.then(() => {
				this.updateUserData(options);

				window.$crisp.push([ 'do', 'chat:close' ]);
				window.$crisp.push([ 'do', 'chat:hide' ]);
				window.$crisp.push([ 'on', 'chat:opened', () => {
					window.setTimeout(() => {
						window.$crisp.push([ 'do', 'chat:show' ]);
					}, 500);
				} ]);

				window.$crisp.push([ 'on', 'chat:closed', () => {
					window.$crisp.push([ 'do', 'chat:hide' ]);
				} ]);
			})
			.catch(() => {
				// do nothing now
			})
	}

	update (options= {}) {
		this.updateUserData(options.user);
	}

	teardown() {}

	/**
	 * @param {string} name
	 * @param {*} data
	 * @param {string} [color="green"]
	 */
	sendEvent (name, data, color = 'green') {
		window.$crisp.push([ 'set', 'session:event', [ [ [ name, data, color ] ] ] ]);
	}

	/**
	 * @param {string} imageLink
	 */
	sendAnalyzedEvent (imageLink) {
		this.sendEvent('clicked-analyze', { image_link: imageLink }, 'green');
	}

	/**
	 * @param {string} imageLink
	 */
	sendUploadedImageEvent (imageLink) {
		this.sendEvent('uploaded-image', { image_link: imageLink }, 'green');
	}

	/**
	 * @param {Object} options
	 */
	updateUserData (options) {
		const userEmail = lodashGet(options, 'email', null);
		if ( userEmail !== null ) {
			window.$crisp.push([ 'set', 'user:email', [ userEmail ] ]);
		}

		window.$crisp.push([ 'set', 'user:nickname', [ `${lodashGet(options, 'first_name', '')} ${lodashGet(options, 'last_name', '')}` ] ]);

		const userOrganization =  lodashGet(options, 'clinic', null);
		if ( userOrganization !== null ) {
			window.$crisp.push([ 'set', 'user:company', [ userOrganization ] ]);
		}
	}

	open () {
		window.$crisp.push([ 'do', 'chat:open' ]);
	}

	close () {
		window.$crisp.push([ 'do', 'chat:close' ]);
	}

	/**
	 * @return {Promise}
	 * @private
	 */
	_loadScript () {
		return new Promise((resolve, reject) => {
			const script = window.document.createElement('SCRIPT');
			script.innerHTML = 'window.$crisp=[];window.CRISP_WEBSITE_ID="908c7bcb-a460-43f7-857c-5ce3a0085879";(function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
			window.document.getElementsByTagName('HEAD')[0].appendChild(script);

			resolve();
		});
	}
}
