import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './styles/Hint.css';


const baseCssClassName = 'hint';
const contentCssClassName = `${baseCssClassName}__content`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const globalContainerCssClassName = `${baseCssClassName}-global-container`;


class Hint extends PureComponent {
	static propTypes = {
		content: PropTypes.node.isRequired,
		left: PropTypes.number,
		right: PropTypes.number,
		top: PropTypes.number,
		bottom: PropTypes.number,
		
		isGlobal: PropTypes.bool,
	}

	constructor (props, context) {
		super(props, context);

		this._el = null;

		// Reference on the current global container.
		this._globalContainer = null;
	}

	componentDidMount () {
		if ( this.props.isGlobal ) {
			this._renderGlobal();
		}

		if ( this.props.onRender && this._el ) {
			this.props.onRender(this._el);
		}
	}

	componentWillUnmount () {
		if ( this._globalContainer ) {
			document.body.removeChild(this._globalContainer);
			this._globalContainer = null;
		}
	}

	_handleRef = (el) => {
		this._el = el;
	}

	/**
	 * Renders the tooltip in the global context(body).
	 */
	_renderGlobal () {
		if ( !this._globalContainer ) {
			this._globalContainer = document.createElement('DIV');
			this._globalContainer.className = globalContainerCssClassName;
			document.body.appendChild(this._globalContainer);
		}

		return ReactDOM.createPortal(
			this._renderBaseContent(),
			this._globalContainer,
		);
	}

	_renderBaseContent () {
		const {
			left,
			right,
			top,
			bottom,
			content,
		} = this.props;
		return (
			<div
				className={baseCssClassName}
				style={{
					left,
					right,
					top,
					bottom,
				}}
			>
				<div className={contentCssClassName}>{content}</div>
				<div className={indicatorCssClassName}/>
			</div>
		);
	}

	render () {
		if ( this.props.isGlobal ) {
			return this._renderGlobal();
		}

		return this._renderBaseContent();
	}
}

export default Hint;
