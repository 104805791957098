import React from 'react';
import { connect } from 'react-redux';

import mainConfig from '../../configs/mainConfig';

import { checkBoneLossLine } from '../../appUtils/bonelossUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import teethUtils from '../../appUtils/teeth/teethUtils';
import { prepareBonelosses } from '../../modules/labels/utils';

import editorSelectors from '../../selectors/editorSelectors';
import labelChildrenSelectors from '../../modules/labels/selectors/labelChildrenSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../modules/label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';
import userSelectors from '../../selectors/userSelectors';

import ShapeTooltip from './ShapeTooltip';



export default connect((state, props) => {
	let label = {
		...props.label,
		tags: [],
	};

	const editorData = editorSelectors.selectEditor(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const showAllClasses = editorSelectors.selectShowAllFindings(state);
	const filteredClasses = editorSelectors.selectFilteredClassesForCurrentImage(state);
	const showToothKey = labelsUtils.labelIsTooth(label);
	const aiPathologiesButtonAvailable = userSelectors.selectUsesComputerAidedDeviceUi(state);
	const aiPathologiesEnabled = currentImage.is_sequential_viewed === true;

	const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
	if ( children.length > 0 ) {
		label.children = [];
		children.forEach((childLabelId) => {
			const childLabel = {
				...labelsSelectors.selectLabelById(state, {
					labelId: childLabelId,
				}),
				tags: [],
			};
			const classId = labelGetters.getLabelClassId(childLabel);
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);
			const source = labelGetters.getLabelSource(label);

			labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(childLabel) })
				.forEach((tagId) => {
					childLabel.tags.push(labelTagsSelectors.selectLabelTagById(state, { tagId }));
				});

			if ( mainConfig.BONE_LOSS_LINES_VISIBLE.includes(classId) === true ) {
				if ( checkBoneLossLine({
					label: childLabel,
					showBoneLossStages: editorData.showBoneLossStages,
					allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
				}) ) {
					label.children.push(childLabel);
				}
			}
			else if (
				(
					showAllClasses ||
					true === filteredClasses[classId]
				) && (
					typeof measureOfConfidence !== 'number' ||
					editorData.filteredConfidencePercent <= measureOfConfidence
				) && (
					aiPathologiesButtonAvailable === false ||
					aiPathologiesEnabled === true ||
					source === 'manual' ||
					( classId !== 'caries' && classId !== 'periodontitis' )
				)
			) {
				label.children.push(childLabel);
			}
		});
		label.children = prepareBonelosses(label.children, { filterGreenLines: true, filterNotPathologies: true });
	}
	else {
		labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) })
			.forEach((tagId) => {
				label.tags.push(labelTagsSelectors.selectLabelTagById(state, { tagId }));
			});
	}

	const preparedLabels = prepareBonelosses([ label ], { filterGreenLines: false, filterNotPathologies: false });
	label = preparedLabels[preparedLabels.length - 1];

	return {
		...props,
		label,
		showToothKey,
		onGetToothKey: () => {
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( tags && tags.length > 0 ) {
				return teethUtils.getLocalizedToothKey({
					toothKey: labelTagGetter.getTagKey(tags[0]),
					notationType: userSelectors.selectUserData(state).notation_type,
				});
			}
			return '-';
		},
	};
}, null)(ShapeTooltip);
