import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import lodashIsEqual from 'lodash/isEqual';
import classnames from 'classnames';

import { getUniquePoints, addMarginToPoly, addMarginToPolyWithOnePoint } from '../utils/shapesUtils';

import './styles/ImageShapePolygon.css';


/**
 * @param {number[]} points
 * @returns {number[]}
 */
function addPointsToCompare (points) {
	if ( points.length > 1 ) {
		points = getUniquePoints(points);
		// Closing a polygon
		if ( points.length === 1 ) {
			return addMarginToPolyWithOnePoint(points);
		}
		else if ( points.length > 2 ) {
			points = [ ...points, points[0] ];
		}
		points = addMarginToPoly(points, 20);
	}

	return points;
}

const CONTROL_POINT_RADIUS = 3;
const ADD_CONTROL_POINT_RADIUS = 5;

const baseCssClassName = 'image-shape-polygon';
const controlsCssClassName = `${baseCssClassName}__controls`;
const resizeControlCssClassName = `${baseCssClassName}__resize-control`;


class ImageShapePolygon extends PureComponent {
	static propTypes = {
		id: PropTypes.string,
		shape: PropTypes.object.isRequired,
		color: PropTypes.string.isRequired,
		borderStyle: PropTypes.string.isRequired,
		showControls: PropTypes.bool.isRequired,
		isHighlighted: PropTypes.bool.isRequired,
		allowEditing: PropTypes.bool.isRequired,
		isVisible: PropTypes.bool,

		zoom: PropTypes.number.isRequired,
		imageWidth: PropTypes.number,
		imageHeight: PropTypes.number,

		allowAdd: PropTypes.bool,
		allowRemove: PropTypes.bool,

		canvasObjectsApi: PropTypes.object,

		onRenderChildren: PropTypes.func,
		onLabelChange: PropTypes.func,
		onSetEditing: PropTypes.func,
		onGetRef: PropTypes.func,
		onGetCanvasRect: PropTypes.func,
	};

	static defaultProps = {
		allowAdd: true,
		allowRemove: true,
		isVisible: true,
		canvasObjectsApi: null,
	};


	/**
	 * @type {number|null}
	 * @private
	 */
	_objectId = null;

	constructor (props, context) {
		super(props, context);

		this._beginX = null;
		this._beginY = null;

		this._isMoving = false;
		this._isResizing = false;

		this._selectedPointIndex = null;

		this._points = props.shape.points;

		this.state = {
			mode: 'move',
		};
	}

	componentDidMount () {
		window.document.addEventListener('keyup', this._handleKeyUp);
		// window.document.addEventListener('mousedown', this._handleMouseDown);

		if ( this.props.canvasObjectsApi !== null ) {
			this._objectId = this.props.canvasObjectsApi.objects.add({
				id: this.props.id,
				...this.props.shape,
				points: addPointsToCompare(this._points),
			}, this.props.id);
		}
	}

	componentDidUpdate (prevProps) {
		if ( this.props.allowEditing === false && lodashIsEqual(this.props.shape, prevProps.shape) === false ) {
			this._points = this.props.shape.points;
			if ( this.props.canvasObjectsApi !== null ) {
				this.props.canvasObjectsApi.objects.update(this._objectId, {
					id: this.props.id,
					...this.props.shape,
					points: addPointsToCompare(this._points),
				});
			}
			this.forceUpdate();
		}
	}

	componentWillUnmount () {
		window.document.removeEventListener('keyup', this._handleKeyUp);
		// window.document.removeEventListener('mousedown', this._handleMouseDown);

		if ( this.props.canvasObjectsApi !== null && this._objectId !== null ) {
			this.props.canvasObjectsApi.objects.remove(this._objectId);
			this._objectId = null;
		}
	}

	_saveLabel () {
		this.props.onLabelChange({
			...this.props.shape,
			points: [
				...this._points,
			],
		});
	}

	_handleRef = (el) => {
		if ( this.props.onGetRef ) {
			this.props.onGetRef(el);
		}
	}

	_handleKeyUp = (event) => {
		if ( typeof event.key !== 'string' ) {
			return;
		}

		switch (event.key.toLowerCase()) {
			case 'r':
				if ( !this.props.allowRemove ) {
					return;
				}
				this.props.onSetEditing(true);
				this.setState({
					mode: 'remove',
				});
				break;

			case 'm':
				this.props.onSetEditing(false);
				this.setState({
					mode: 'move',
				});
				break;

			case 'a':
				if ( !this.props.allowAdd ) {
					return;
				}
				this.props.onSetEditing(true);
				this.setState({
					mode: 'add',
				});
				break;

			default:
				break;
		}
	}

	_handleMouseDown = (event) => {
		if ( this.state.mode !== 'add' ) {
			return;
		}

		const canvasRect = this.props.onGetCanvasRect();

		const zoom = this.props.zoom;
		const diff = (
			zoom === 1 ? 1 : ( 1 / zoom )
		);

		this._points.push([
			( event.clientX - canvasRect.left ) * diff,
			( event.clientY - canvasRect.top ) * diff,
		]);
		this.forceUpdate();
	}

	_handleControlMouseDown = (event) => {
		if ( !this.props.showControls || !this.props.allowEditing ) {
			return;
		}

		const resizeControlPointIndex = event.target.dataset.controlPointIndex;

		switch (this.state.mode) {
			case 'remove': {
				console.dir(resizeControlPointIndex);
				if ( !resizeControlPointIndex ) {
					return;
				}

				this._points.splice(resizeControlPointIndex, 1);
				this._saveLabel();
				this.forceUpdate();

				break;
			}

			case 'add': {
				if (
					!event.target.dataset.controlPointType ||
					event.target.dataset.controlPointType !== 'add'
				) {
					break;
				}
				const pointParent = Number(event.target.dataset.controlPointParent);
				const centerX = Number(event.target.dataset.controlPointX);
				const centerY = Number(event.target.dataset.controlPointY);

				this._points.splice(pointParent + 1, 0, [ centerX, centerY ]);
				this._saveLabel();
				this.forceUpdate();
				break;
			}

			case 'move' : {
				if ( resizeControlPointIndex ) {
					this._isResizing = true;
					this._selectedPointIndex = resizeControlPointIndex;
				}
				else {
					this._isMoving = true;
				}

				this._beginX = event.clientX;
				this._beginY = event.clientY;

				this.props.onSetEditing(true);

				window.document.addEventListener('mousemove', this._handleDocumentMouseMove);
				window.document.addEventListener('mouseup', this._handleDocumentMouseUp);

				break;
			}
		}
	}

	_handleDocumentMouseMove = (event) => {
		const {
			zoom,
			imageWidth,
			imageHeight,
		} = this.props;

		const diff = (
			zoom === 1 ? 1 : ( 1 / zoom )
		);

		const deltaX = event.clientX - this._beginX ;
		const deltaY = event.clientY - this._beginY;

		this._beginX += deltaX;
		this._beginY += deltaY;

		if ( this._isMoving ) {
			const nextPoints = [];

			for (let i = 0, l = this._points.length; i < l; i++) {
				const currPoint = this._points[i];
				const nextPointX = currPoint[0] + (deltaX * diff);
				const nextPointY = currPoint[1] + (deltaY * diff);

				if (
					nextPointX < 0 || nextPointX > imageWidth ||
					nextPointY < 0 || nextPointY > imageHeight
				) {
					return;
				}

				nextPoints.push([ nextPointX, nextPointY ]);
			}

			this._points = nextPoints;

		}
		else if ( this._isResizing ) {
			const currPoint = this._points[this._selectedPointIndex];
			const nextPointX = currPoint[0] + (deltaX * diff);
			const nextPointY = currPoint[1] + (deltaY * diff);

			if (
				nextPointX < 0 || nextPointX > imageWidth ||
				nextPointY < 0 || nextPointY > imageHeight
			) {
				return;
			}

			currPoint[0] = nextPointX;
			currPoint[1] = nextPointY;
		}
		else {
			return;
		}

		this.forceUpdate();
	}

	_handleDocumentMouseUp = () => {
		window.document.removeEventListener('mousemove', this._handleDocumentMouseMove);
		window.document.removeEventListener('mouseup', this._handleDocumentMouseUp);

		this._beginX = null;
		this._beginY = null;

		this._isMoving = false;
		this._isResizing = false;

		this.props.canvasObjectsApi.objects.update(this._objectId, {
			id: this.props.id,
			...this.props.shape,
			points: addPointsToCompare(this._points),
		});

		this._saveLabel();

		this.props.onSetEditing(false);

		this.forceUpdate();
	}

	_renderAddControls () {
		if ( this.state.mode !== 'add' ) {
			return null;
		}

		const {
			color,
			zoom,
		} = this.props;

		const result = [];

		for (let i = 0, l = this._points.length; i < l; i++) {
			let firstPoint = this._points[i];
			let secondPoint = this._points[i + 1];
			if ( !secondPoint ) {
				secondPoint = this._points[0];
			}

			const left = Math.min(firstPoint[0], secondPoint[0]);
			const right = Math.max(firstPoint[0], secondPoint[0]);
			const top = Math.min(firstPoint[1], secondPoint[1]);
			const bottom = Math.max(firstPoint[1], secondPoint[1]);

			const centerX = (left + ( right - left) / 2) * zoom;
			const centerY = (top + ( bottom - top) / 2) * zoom;

			result.push(
				<g key={i}>
					<circle
						data-control-point-parent={i}
						data-control-point-x={centerX / zoom}
						data-control-point-y={centerY / zoom}
						data-control-point-type={'add'}
						className={resizeControlCssClassName}
						cx={centerX}
						cy={centerY}
						r={ADD_CONTROL_POINT_RADIUS}
						fill={color}
						stroke={'#000'}
						strokeWidth={2}
					/>
					<path
						d={`M ${centerX - ADD_CONTROL_POINT_RADIUS} ${centerY} ${centerX + ADD_CONTROL_POINT_RADIUS} ${centerY}`}
						stroke={'#000'}
						strokeWidth={2}
					/>
					<path
						d={`M ${centerX} ${centerY - ADD_CONTROL_POINT_RADIUS} ${centerX} ${centerY + ADD_CONTROL_POINT_RADIUS}`}
						stroke={'#000'}
						strokeWidth={2}
					/>
				</g>
			);
		}

		return result;
	}

	_renderResizeControls () {
		if ( this.state.mode !== 'move' && this.state.mode !== 'remove' ) {
			return null;
		}

		const {
			color,
			zoom,
		} = this.props;

		return this._points.map((point, i) => {
			const centerX = point[0] * zoom;
			const centerY = point[1] * zoom;
			return (
				<g key={i}>
					<circle
						data-control-point-index={i}
						data-control-point-type={'marker'}
						className={classnames([
							resizeControlCssClassName,
							this.props.allowEditing === false && `${resizeControlCssClassName}__m-deny-edit`,

						])}
						cx={centerX}
						cy={centerY}
						r={CONTROL_POINT_RADIUS}
						fill={color}
						stroke={'#000'}
						strokeWidth={2}
					/>
					{this.state.mode === 'remove' && (
						<path
							d={`M ${centerX - CONTROL_POINT_RADIUS} ${centerY} ${centerX + CONTROL_POINT_RADIUS} ${centerY}`}
							stroke={'#000'}
							strokeWidth={2}
						/>
					)}
				</g>
			);
		})
	}

	_renderControls () {
		if ( !this.props.showControls ) {
			return null;
		}

		return (
			<g
				className={classnames([
					controlsCssClassName,
					this.state.mode === 'move' && this.props.allowEditing && `${controlsCssClassName}__m-move`,
				])}
				onMouseDown={this._handleControlMouseDown}
			>
				{this._renderResizeControls()}
				{this._renderAddControls()}
			</g>
		);
	}

	render () {
		const {
			color,
			borderStyle,
			zoom,
			showControls,
			isHighlighted,
			isVisible,
		} = this.props;

		if ( isVisible === false ) {
			return null;
		}

		const pointsLength = this._points.length;

		const props = {
			fill: 'none',
			className: classnames([
				baseCssClassName,
				showControls && isHighlighted && `${baseCssClassName}__m-selected`,
				isHighlighted && `${baseCssClassName}__m-highlighted`,
			]),
			stroke: color,
			strokeWidth: (showControls || isHighlighted ? 3 : 2),
			d: this._points.map((point, i) => {
				const x = point[0] * zoom;
				const y = point[1] * zoom;

				if ( i === 0 ) {
					return `M ${x} ${y}`;
				}
				else if ( i === (pointsLength - 1) ) {
					return `L ${x} ${y} Z`;
				}
				else {
					return `L ${x} ${y}`;
				}
			}).join(' '),
		};

		if ( borderStyle === 'dashed') {
			props.strokeDasharray = '4 4';
		}

		return (
			<Fragment>
				<path ref={this._handleRef} {...props} />
				{this._renderControls()}
				{this.props.onRenderChildren && this.props.onRenderChildren({
					points: this._points,
				})}
			</Fragment>
		);
	}
}
export default ImageShapePolygon;
