import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import when from 'when';

import globalPopupController from '../../services/popup/globalPopupControllerInstance';

import Alignment from '../Alignment';
import Overlay from '../Overlay';

import './styles/Popup.css';


function noop () {}

const baseCssClassName = 'popup';
const contentCssClassName = `${baseCssClassName}__content`;
const globalContainerCssClassName = `${baseCssClassName}-global-container`;


/**
 * @typedef {Object} PopupProps
 *
 * @property {ReactElement|function(PopupInterface):ReactElement} children
 * @property {boolean} [isGlobal=true] If true, the popup will be open in the global(body) context.
 * @property {Object} style Popup style.
 * @property {Object} contentStyle Popup content style.
 * @property {boolean} [closeOnEsc=true] If true, the popup can be closed by the key ESC.
 * @property {function:boolean|function:Promise} [onBeforeClose] Called before popup tries to close. If false is returned popup will not close.
 * @property {function} [onClose] Called when the popup is about to be closed.
 *
 * @property {AlignmentProps} [alignmentProps={horizontal:'center',vertical:'center'}]
 * @property {PopupOverlayProps} [overlay={enabled:true,closeOnClick:true}]
 * @property {OverlayProps} [overlayProps={theme: 'dark', scrollable: true, scrollProps: {horizontalEnabled: true, verticalEnabled: true}}]
 */

/**
 * @typedef {Object} PopupOverlayProps
 *
 * @property {boolean} [closeOnClick=true] Close the popup when a user clicks on the overlay.
 */

/**
 * @typedef {Object} PopupInterface
 *
 * @property {Object} popup
 * @property {function} popup.close Closes the popup.
 */


/**
 * Combines the overlay component and the alignment one to display modal window.
 */
class Popup extends Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.node,
			PropTypes.func,
		]).isRequired,

		isGlobal: PropTypes.bool,
		style: PropTypes.object,
		contentStyle: PropTypes.object,
		closeOnEsc: PropTypes.bool, // Not used now because there is a problem to detect which popup element is focused.
		overlay: PropTypes.object,

		onBeforeClose: PropTypes.func,
		onClose: PropTypes.func,

		alignmentProps: PropTypes.object,
		overlayProps: PropTypes.object,
	}

	static defaultProps = {
		isGlobal: true,
		overlay: {
			closeOnClick: true,
		},
		overlayProps: {
			theme: Overlay.themes.DARK,
			scrollable: true,
			scrollProps: {
				horizontalEnabled: true,
				verticalEnabled: true,
			},
		},
	}

	_globalPopupId = null;


	/**
	 * @param {PopupProps} props
	 * @param {Object} context
	 */
	constructor (props, context) {
		super(props, context);

		// Reference on the current global container.
		this._globalContainer = null;

		this._chidlrenOptions = {
			popup: {
				close: this._tryToClose,
			},
		};
	}

	componentDidUpdate () {
		if ( this.props.isGlobal ) {
			this._renderGlobal();
		}
	}

	componentDidMount () {
		if ( this.props.isGlobal ) {

			this._globalPopupId = globalPopupController.add();

			this._renderGlobal();
		}
	}

	componentWillUnmount () {
		if ( this._globalContainer ) {
			document.body.removeChild(this._globalContainer);
			this._globalContainer = null;
		}

		if ( this._globalPopupId ) {
			globalPopupController.remove(this._globalPopupId);
			this._globalPopupId = null;
		}
	}

	/**
	 * Renders the popup in the global context(body).
	 */
	_renderGlobal () {
		if ( !this._globalContainer ) {
			this._globalContainer = document.createElement('DIV');
			this._globalContainer.className = globalContainerCssClassName;
			document.body.appendChild(this._globalContainer);
		}

		return ReactDOM.createPortal(
			this._renderBaseContent(),
			this._globalContainer,
		);
	}

	_getChildrenOptions () {
		return this._chidlrenOptions;
	}

	_tryToClose = () => {
		const {
			onBeforeClose,
			onClose,
		} = this.props;

		if ( typeof onBeforeClose === 'function' ) {
			when(onBeforeClose())
				.then((result) => {
					if ( result !== false ) {
						if ( onClose ) {
							onClose();
						}
					}
				});

			return;
		}

		if ( onClose ) {
			onClose();
		}
	}

	_handleOverlayClick = () => {
		this._tryToClose();
	}

	_handleContentMouseDown = (event) => {
		event.stopPropagation();
	}

	_renderChildren () {
		const {
			children,
		} = this.props;

		if ( typeof children === 'function' ) {
			return children(this._getChildrenOptions());
		}

		return children;
	}

	_renderBaseContent () {
		const {
			contentStyle,
			overlayProps,
			overlay,
			style
		} = this.props;
		const alignmentProps = {
			horizontal: Alignment.horizontal.CENTER,
			vertical: Alignment.vertical.CENTER,
			...this.props.alignmentProps,
		};

		let baseContent = (
			<Alignment {...alignmentProps}>
				<div
					className={contentCssClassName}
					onMouseDown={this._handleContentMouseDown}
					style={( contentStyle || {} )}
				>
					{this._renderChildren()}
				</div>
			</Alignment>
		);
		
		if ( this.props.overlay ) {
			const overlayProps = { ...(this.props.overlayProps || {}) };
			overlayProps.scrollProps = { ...(overlayProps.scrollProps || {}) };
			overlayProps.scrollProps.fullscreen = overlayProps.scrollProps.hasOwnProperty('fullscreen')
				? overlayProps.scrollProps.fullscreen
				: ( this.props.isGlobal === true );
			
			baseContent = (
				<Overlay
					{...overlayProps}
					onClick={( this.props.overlay.closeOnClick ? this._handleOverlayClick: noop )}
				>
					{baseContent}
				</Overlay>
			);
		}

		return (
			<div
				className={baseCssClassName}
				style={( style || {} )}
			>{baseContent}</div>
		);
	}

	render () {
		if ( this.props.isGlobal ) {
			return this._renderGlobal();
		}

		return this._renderBaseContent();
	}
}

export default Popup;
