/**
 * @typedef {[ x: number, y: number ]} GeometryPoint
 * @example
 * const [ x, y ] = point;
 */


import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DRAGGING_STATUS } from './CanvasConstants';

import './styles/Canvas.css';


const baseCssClassName = 'viewport';
const canvasCssClassName = `${baseCssClassName}__canvas`;


/**
 * Adds the X and Y scroll to the point.
 *
 * @param {GeometryPoint} point
 * @return {GeometryPoint}
 */
const addScroll = ([ x = 0, y = 0 ]) => ([
	x + (window.pageXOffset || window.document.body.scrollLeft),
	y + (window.pageYOffset || window.document.body.scrollTop),
]);

/**
 * Calculates the distance between two touches.
 *
 * @param {{pageX: number, pageY: number}} a
 * @param {{pageX: number, pageY: number}} b
 * @return {number}
 */
const getDistanceBetweenTouches = (a, b) => Math.sqrt((a.pageX - b.pageX) ** 2 + (a.pageY - b.pageY) ** 2);


export default class Viewport extends PureComponent {
	static propTypes = {
		canvasWidth: PropTypes.number.isRequired,
		canvasHeight: PropTypes.number.isRequired,
		viewportWidth: PropTypes.number,
		viewportHeight: PropTypes.number,
		isInteractive: PropTypes.bool,
		allowToMoveOutside: PropTypes.bool,
		canZoom: PropTypes.bool,
		onGetZoom: PropTypes.func,
		onGetInitialPosition: PropTypes.func,
	};

	static defaultProps = {
		isInteractive: true,
		allowToMoveOutside: false,
		canZoom: true,
		onGetZoom: () => 1,
		onGetInitialPosition: () => ({ x: 0, y: 0 }),
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_viewportEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_canvasEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_lastTouchTime = null;

	/**
	 * @type {number}
	 * @private
	 */
	_zoomAppliedTimes = 0;

	/**
	 * @type {boolean}
	 * @private
	 */
	_canDragCanvas = true;

	/**
	 * The initial or stored(from previous calculations) coordinates.
	 *
	 * @type {GeometryPoint}
	 * @private
	 */
	_point = [ 0, 0 ];

	/**
	 * @type {Array<{pageX: number, pageY: number}>}
	 * @private
	 */
	_startTouches = [];

	/**
	 * @type {number}
	 * @private
	 */
	_lastScale = 1;

	state = {
		draggingStatus: DRAGGING_STATUS.IDLE,
		zoom: 1,
		offsetX: 0,
		offsetY: 0,
	};

	componentDidMount () {
		this._setInitialPosition(this.props);
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			nextProps.viewportWidth !== this.props.viewportWidth ||
			nextProps.viewportHeight !== this.props.viewportHeight ||
			nextProps.canvasWidth !== this.props.canvasWidth ||
			nextProps.canvasHeight !== this.props.canvasHeight
		) {
			this._setInitialPosition(nextProps);
		}
	}

	componentWillUnmount () {
		this.state = null;
		this._viewportEl.removeEventListener('wheel', this._handleWheel, false);
		this._viewportEl.removeEventListener('mousedown', this._handleMouseDown, false);
		this._viewportEl.removeEventListener('touchstart', this._handleTouchStart, false);

		this._unsubscribe();
	}

	/**
	 * @param {HTMLElement|null} [element=null]
	 * @private
	 */
	_handleViewportRef = (element = null) => {
		if ( null === element || element === this._viewportEl ) {
			return;
		}

		this._viewportEl = element;
		element.addEventListener('mousedown', this._handleMouseDown, false);
		element.addEventListener('wheel', this._handleWheel, { passive: false });
		element.addEventListener('touchstart', this._handleTouchStart, { passive: false });
		this.forceUpdate();
	};

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handleCanvasRef = (element) => {
		if ( element === null || element === undefined || element === this._canvasEl ) {
			return;
		}

		this._canvasEl = element;
	};

	/**
	 * @param {number} factor
	 * @private
	 */
	_handleZoom = (factor) => {
		if ( false === this.props.canZoom ) {
			return;
		}

		const viewportRect = this._viewportEl.getBoundingClientRect();
		this._setZoom(
			addScroll([
				viewportRect.left + (viewportRect.right - viewportRect.left) / 2,
				viewportRect.top + (viewportRect.bottom - viewportRect.top) / 2,
			]),
			this._getNextZoom(this.state.zoom * factor)
		);
	};

	/**
	 * @param {MouseWheelEvent} event
	 * @private
	 */
	_handleWheel = (event) => {
		if (
			false === this.props.isInteractive ||
			false === this.props.canZoom ||
			false === this._isControlledNode(event.target)
		) {
			return;
		}

		event.preventDefault();

		if ( 'number' === typeof event.deltaY ) {
			this._setZoom(
				[ event.pageX, event.pageY ],
				this._getNextZoom(this.state.zoom * (0.999 ** event.deltaY))
			);
		}
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleMouseDown = (event) => {
		event.preventDefault();

		if (
			false === this.props.isInteractive ||
			false === this._canDragCanvas ||
			false === this._isControlledNode(event.target)
		) {
			return;
		}

		this._point = [ event.pageX, event.pageY ];
		window.document.addEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.addEventListener('mouseup', this._handleDocumentMouseUp, false);
	};

	/**
	 * @param {TouchEvent} event
	 * @private
	 */
	_handleTouchStart = (event) => {
		event.preventDefault();

		if (
			false === this.props.isInteractive ||
			false === this._isControlledNode(event.target)
		) {
			return;
		}

		if ( 1 === event.touches.length ) {
			if ( false === this._canDragCanvas ) {
				return;
			}

			const touch = event.touches[0];
			this._point = [ touch.pageX, touch.pageY ];
		}
		else if ( 2 === event.touches.length ) {
			if ( false === this.props.canZoom ) {
				return;
			}

			this._startTouches = Array.from(event.touches).map(({ pageX, pageY }) => ({
				pageX,
				pageY,
			}));
			this._lastScale = 1;
		}

		this._viewportEl.addEventListener('touchmove', this._handleTouchMove, { passive: false });
		window.document.addEventListener('touchend', this._handleDocumentMouseUp, { passive: false });
		window.document.addEventListener('touchcancel', this._handleDocumentMouseUp, { passive: false });
	};

	/**
	 * @param {TouchEvent} event
	 * @private
	 */
	_handleTouchMove = (event) => {
		if ( 1 === event.touches.length ) {
			if ( false === this._canDragCanvas ) {
				return;
			}
			const touch = event.touches[0];
			this._processDragging([ touch.pageX - this._point[0], touch.pageY - this._point[1] ]);
			this._point = [ touch.pageX, touch.pageY ];
		}
		else if ( 2 === event.touches.length && 2 === this._startTouches.length ) {
			const newScale = getDistanceBetweenTouches(event.touches[0], event.touches[1]) / getDistanceBetweenTouches(this._startTouches[0], this._startTouches[1]);
			const scale = newScale / this._lastScale;
			this._lastScale = newScale;
			const center = Array.from(event.touches).reduce((result, touch) => {
				result[0] += touch.pageX;
				result[1] += touch.pageY;
				return result;
			}, [ 0, 0 ]);
			const viewportRect = this._viewportEl.getBoundingClientRect();
			const viewportPosition = addScroll([ viewportRect.left, viewportRect.top ]);
			this._setZoom(
				[
					(center[0] / event.touches.length) - viewportPosition[0],
					(center[1] / event.touches.length) - viewportPosition[1],
				],
				this._getNextZoom(this.state.zoom * scale)
			);
		}
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleDocumentMouseMove = (event) => {
		if ( false === this._canDragCanvas ) {
			return;
		}

		this._processDragging([ event.pageX - this._point[0], event.pageY - this._point[1] ]);
		this._point = [ event.pageX, event.pageY ];
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleDocumentMouseUp = (event) => {
		this._finishDragging(event);
	};

	_handleResetPosition = () => {
		this._setInitialPosition(this.props);
	};

	/**
	 * @param {boolean} value
	 * @private
	 */
	_handleCanDragCanvas = (value) => {
		this._canDragCanvas = value;
	};

	_setInitialPosition (props) {
		const zoom = props.onGetZoom(null);
		const { x, y } = props.onGetInitialPosition({
			viewportWidth: props.viewportWidth,
			viewportHeight: props.viewportHeight,
			zoom,
		});
		this.setState({
			zoom,
			offsetX: x,
			offsetY: y,
		});
	}

	/**
	 * @param {number} zoom
	 * @return {number}
	 * @private
	 */
	_getNextZoom (zoom) {
		return this.props.onGetZoom(zoom);
	}

	/**
	 * @return {{width: number, height: number}}
	 * @private
	 */
	_getViewportSize () {
		if ( null === this._viewportEl ) {
			return { width: 0, height: 0 };
		}

		const viewportRect = this._viewportEl.getBoundingClientRect();
		return {
			width: viewportRect.right - viewportRect.left,
			height: viewportRect.bottom - viewportRect.top,
		};
	}

	/**
	 * @param {GeometryPoint} point Point on the canvas
	 * @param {number} nextZoom
	 * @private
	 */
	_setZoom (point, nextZoom) {
		this.setState((prevState) => {
			const zoom = prevState.zoom;
			const viewportRect = this._viewportEl.getBoundingClientRect();
			const viewportPosition = addScroll([ viewportRect.left, viewportRect.top ]);
			const curX = -prevState.offsetX + (point[0] - viewportPosition[0]);
			const curY = -prevState.offsetY + (point[1] - viewportPosition[1]);
			const nextX = (curX / zoom) * nextZoom;
			const nextY = (curY / zoom) * nextZoom;

			const [ nextOffsetX, nextOffsetY ] = this._limitOffset(
				[ prevState.offsetX - (nextX - curX), prevState.offsetY - (nextY - curY) ],
				nextZoom
			);

			return {
				zoom: nextZoom,
				offsetX: nextOffsetX,
				offsetY: nextOffsetY,
			};
		});
	}

	/**
	 * @param {GeometryPoint} point
	 * @param {number} zoom
	 *
	 * @return {GeometryPoint}
	 * @private
	 */
	_limitOffset (point, zoom) {
		const { width: viewportWidth, height: viewportHeight } = this._getViewportSize();

		let nextOffsetX = point[0];
		let nextOffsetY = point[1];

		if ( this.props.canvasWidth * zoom > viewportWidth ) {
			if ( nextOffsetX > 0 ) {
				nextOffsetX = 0;
			}

			if ( -nextOffsetX + viewportWidth > this.props.canvasWidth * zoom ) {
				nextOffsetX = -(this.props.canvasWidth * zoom - viewportWidth);
			}
		}
		else {
			nextOffsetX = Math.min(
				viewportWidth - this.props.canvasWidth * zoom,
				Math.max(
					0,
					nextOffsetX
				)
			);
		}

		if ( this.props.canvasHeight * zoom > viewportHeight ) {
			if ( nextOffsetY > 0 ) {
				nextOffsetY = 0;
			}

			if ( -nextOffsetY + viewportHeight > this.props.canvasHeight * zoom ) {
				nextOffsetY = -(this.props.canvasHeight * zoom - viewportHeight);
			}
		}
		else {
			nextOffsetY = Math.min(
				viewportHeight - this.props.canvasHeight * zoom,
				Math.max(
					0,
					nextOffsetY
				)
			);
		}

		return [ nextOffsetX, nextOffsetY ];
	}

	/**
	 * @param {GeometryPoint} delta
	 * @private
	 */
	_processDragging (delta) {
		this.setState((prevState) => {
			const [ deltaX, deltaY ] = delta;
			const nextState = {};

			const [ nextOffsetX, nextOffsetY ] = this._limitOffset(
				[ prevState.offsetX + deltaX, prevState.offsetY + deltaY ],
				prevState.zoom
			);

			nextState.offsetX = nextOffsetX;
			nextState.offsetY = nextOffsetY;

			if ( prevState.draggingStatus !== DRAGGING_STATUS.IN_PROGRESS ) {
				nextState.draggingStatus = DRAGGING_STATUS.IN_PROGRESS;
			}

			return nextState;
		});
	}

	_finishDragging () {
		this._unsubscribe();

		this._point = [ 0, 0 ];
		this._startTouches = [];
		this._lastScale = 1;

		this.setState({
			draggingStatus: DRAGGING_STATUS.IDLE,
		});
	}

	_unsubscribe () {
		window.document.removeEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.removeEventListener('mouseup', this._handleDocumentMouseUp, false);
		this._viewportEl.removeEventListener('touchmove', this._handleTouchMove, false);
		window.document.removeEventListener('touchend', this._handleDocumentMouseUp, false);
		window.document.removeEventListener('touchcancel', this._handleDocumentMouseUp, false);
	}

	/**
	 * @param {Node} node
	 * @return {boolean}
	 * @private
	 */
	_isControlledNode (node) { // eslint-disable-line no-unused-vars
		// return (
		// 	true === checkIsCanvasParent(node, this._canvasEl) ||
		// 	node === this._viewportEl
		// );

		return true;
	}

	render () {
		const zoom = this.state.zoom;
		const sizeStyle = {
			width: this.props.canvasWidth * zoom,
			height: this.props.canvasHeight * zoom,
		};

		const canvasAPI = {
			viewport: this._viewportEl,
			canvas: this._canvasEl,
			draggingStatus: this.state.draggingStatus,
			zoom,
			offsetX: this.state.offsetX,
			offsetY: this.state.offsetY,
			canvasSize: sizeStyle,
			resetPosition: this._handleResetPosition,
			zoomIn: this._handleZoom,
			zoomOut: this._handleZoom,
			canDragCanvas: this._handleCanDragCanvas,
		};

		return (
			<div
				className={baseCssClassName}
				style={{
					width: this.props.viewportWidth > 0 ? this.props.viewportWidth : null,
					height: this.props.viewportHeight > 0 ? this.props.viewportHeight : null,
				}}
				ref={this._handleViewportRef}
			>
				<div
					className={classnames([
						canvasCssClassName,
						this.state.draggingStatus === DRAGGING_STATUS.IN_PROGRESS && `${canvasCssClassName}__m-moving`,
					])}
					style={{
						...sizeStyle,
						transform: `translate(${this.state.offsetX}px, ${this.state.offsetY}px)`,
						transformOrigin: 'left top',
					}}
					ref={this._handleCanvasRef}
				>
					{this.props.children(canvasAPI)}
				</div>
			</div>
		);
	}
}
