import lodashGet from 'lodash/get';
import teethUtils from '../../../../../appUtils/teeth/teethUtils';


/**
 * @param {Object} options
 * @param {Object<string,Object<string,{ toothKey: string, existing: boolean }>>} options.imageTeeth
 * @param {string[]} options.teeth
 * @param {string} options.notationType
 * @return {function(Object<string,string>, CollectionImage): Object<string,string>}
 */
export function getTitleRenderer ({ imageTeeth, teeth, notationType }) {
	const getLocalizedTooth = (toothKey) => {
		return teethUtils.getLocalizedToothKey({ toothKey, notationType });
	};

	/**
	 * @param {Object<string, CollectionImage>} result
	 * @param {CollectionImage} image
	 *
	 * @return {Object<string, CollectionImage>}
	 */
	return (result, image) => {
		let firstEntry = null;
		let prevEntry = null;
		let count = 0;
		result[image.hashname] = [];
		teeth.forEach((toothKey) => {
			const existing = lodashGet(imageTeeth, `${image.hashname}.${toothKey}.existing`, false) === true;
			if ( existing === true ) {
				if ( firstEntry === null ) {
					firstEntry = toothKey;
				}
				prevEntry = toothKey;
				++count;
			}
			else {
				if ( firstEntry !== null ) {
					if ( count === 1 ) {
						result[image.hashname].push(getLocalizedTooth(firstEntry));
					}
					else if ( count === 2 ) {
						result[image.hashname].push(`${getLocalizedTooth(firstEntry)}, ${getLocalizedTooth(prevEntry)}`);
					}
					else {
						result[image.hashname].push(`${getLocalizedTooth(firstEntry)}-${getLocalizedTooth(prevEntry)}`);
					}

					firstEntry = null;
					prevEntry = null;
					count = 0;
				}
			}
		});

		if ( firstEntry !== null ) {
			let content;
			if ( count === 1 ) {
				content = getLocalizedTooth(firstEntry);
			}
			else if ( count === 2 ) {
				content = `${getLocalizedTooth(firstEntry)},${getLocalizedTooth(prevEntry)}`;
			}
			else {
				content = `${getLocalizedTooth(firstEntry)}-${getLocalizedTooth(prevEntry)}`;
			}
			result[image.hashname].push(content);
		}
		result[image.hashname] = result[image.hashname].join(', ');

		return result;
	};
}


/**
 * @param {Object} options
 * @param {Object<string,Object<string,{ toothKey: string, existing: boolean }>>} options.imageTeeth
 * @param {string[]} options.teeth
 * @param {Object} options.imagesTeeth
 * @return {function(CollectionImage, CollectionImage): number}
 */
export function sortImages ({ teeth, imageTeeth, imagesTeeth }) {
	const getFirstToothKey = (image) => {
		let result = null;

		for (let i = 0; i < teeth.length; i++) {
			const toothKey = teeth[i];


			if ( Array.isArray(imagesTeeth[image.hashname]) === true ) {
				const teethMeta = teethUtils.sortTeethMeta(
					teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()),
					imagesTeeth[image.hashname]
				);

				return teeth.indexOf(String(teethMeta[0]));
			}

			const existing = lodashGet(imageTeeth, `${image.hashname}.${toothKey}.existing`, false) === true;

			if ( existing === true ) {
				return i;
			}
		}

		return result;
	};

	/**
	 * @param {CollectionImage} imageA
	 * @param {CollectionImage} imageB
	 * @return {number}
	 */
	return (imageA, imageB) => {
		return getFirstToothKey(imageA) - getFirstToothKey(imageB);
	};
}
