const ACTION_LABEL_TAGS__ADD_TAG = 'ACTION_LABEL_TAGS__ADD_TAG';
const ACTION_LABEL_TAGS__REPLACE_TAG = 'ACTION_LABEL_TAGS__REPLACE_TAG';
const ACTION_LABEL_TAGS__REMOVE_TAG = 'ACTION_LABEL_TAGS__REMOVE_TAG';


export default {
	ACTION_LABEL_TAGS__ADD_TAG,
	ACTION_LABEL_TAGS__REPLACE_TAG,
	ACTION_LABEL_TAGS__REMOVE_TAG,
};
