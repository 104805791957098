import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import { LABELS_STAGE_COMPLETED } from '../../constants/labelsStageConstants';

import urlCache from '../../services/url-cache';

import mainConfig from '../../configs/mainConfig';
import { checkBoneLossLine } from '../../appUtils/bonelossUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import imageUtils from '../../appUtils/imageUtils';

import editorActions from '../../actions/editorActions';
import labelsActions from '../../modules/labels/actions/labelsActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import userSelectors from '../../selectors/userSelectors';
import labelChildrenSelectors from '../../modules/labels/selectors/labelChildrenSelectors';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import analyseSelectors from '../../selectors/analyseSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';

import ImageShapeBox from '../ImageShapes/shapes/box';
import ImageShapePolygon from '../ImageShapes/shapes/polygon';
import ImageShapeBoneloss from '../ImageShapes/shapes/boneloss';
import { ShapeTooltipConnector as ShapeTooltip } from '../ShapeTooltip';


class MainShape extends PureComponent {
	static propTypes = {
		canvasApi: PropTypes.object.isRequired,
		canvasObjectsApi: PropTypes.object.isRequired,
		labels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		visibleLabelsIds: PropTypes.object.isRequired,
		highlightedLabelsIds: PropTypes.object.isRequired,
		closestObjectToPoint: PropTypes.object,
		selectedObject: PropTypes.object,
		selectedLabel: PropTypes.object,
		editorMode: PropTypes.string.isRequired,
		isSelectedObjectInTransformation: PropTypes.bool,
		imageWidth: PropTypes.number.isRequired,
		imageHeight: PropTypes.number.isRequired,
		labelsStage: PropTypes.string,
		isFDAAnnotationEnabled: PropTypes.bool.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,
		canEditShapes: PropTypes.bool,
		onSelectLabel: PropTypes.func.isRequired,
		onHighlightLabels: PropTypes.func.isRequired,
		onRemoveLabel: PropTypes.func.isRequired,
		onUpdateShape: PropTypes.func.isRequired,
		canEditShape: PropTypes.func.isRequired,
	};

	static defaultProps = {
		closestObjectToPoint: null,
		selectedObject: null,
		selectedLabel: null,
		canEditShapes: true,
	};

	UNSAFE_componentWillReceiveProps (nextProps) {
		if ( nextProps.selectedObject !== this.props.selectedObject ) {
			this.props.onSelectLabel({
				labelId: nextProps.selectedObject !== null ? nextProps.selectedObject.id : null,
			});
		}

		if ( nextProps.closestObjectToPoint !== this.props.closestObjectToPoint ) {
			this.props.onHighlightLabels(nextProps.closestObjectToPoint !== null ? [ nextProps.closestObjectToPoint.id ] : []);
		}

		// Sync a selected label with the canvas objects.
		if ( nextProps.selectedLabel !== this.props.selectedLabel ) {
			if ( nextProps.selectedLabel === null ) {
				if ( this.props.selectedObject !== null ) {
					this.props.canvasObjectsApi.setSelectedObject(null);
				}
			}
			else {
				const labelId = labelGetters.getLabelId(nextProps.selectedLabel);

				if (
					nextProps.selectedObject === null ||
					nextProps.selectedObject.id !== labelId
				) {
					const object = this.props.canvasObjectsApi.objects.get().find((object) => (
						object.id === labelId
					));

					if ( typeof object !== 'undefined' ) {
						this.props.canvasObjectsApi.setSelectedObject(object);
					}
				}
			}
		}

		if ( nextProps.selectedObject === null && nextProps.selectedLabel !== null ) {
			this.props.onSelectLabel({
				labelId: null,
			});
		}
	}

	/**
	 * @param {Object} options
	 * @param {Object} options.shapeApi
	 * @param {Object} options.canvasObjectsApi
	 * @return {JSX.Element|null}
	 * @private
	 */
	_renderShape ({ shapeApi, canvasObjectsApi }) {
		const props = {
			id: shapeApi.id,
			shape: shapeApi.shape,
			color: shapeApi.color,
			borderStyle: shapeApi.borderStyle,
			showControls: shapeApi.isSelectedLabel === true,
			isHighlighted: shapeApi.isHighlighted === true,
			allowEditing: (
				shapeApi.hasRightsToEdit === true && (
					shapeApi.isSelectedLabel === true &&
					this.props.labelsStage !== LABELS_STAGE_COMPLETED
				)
			),
			showConfirmation: (
				shapeApi.hasRightsToEdit === true && (
					shapeApi.isHoveredLabel === true ||
					shapeApi.isSelectedLabel === true
				)
			),
			allowDeleting: (
				shapeApi.hasRightsToEdit === true && (
					shapeApi.isHoveredLabel === true ||
					shapeApi.isSelectedLabel === true ||
					(
						this.props.labelsStage !== LABELS_STAGE_COMPLETED &&
						this.props.isFDAAnnotationEnabled === true
					)
				)
			),
			labelsStage: this.props.labelsStage, // todo required?
			zoom: canvasObjectsApi.zoom,
			imageWidth: this.props.imageWidth,
			imageHeight: this.props.imageHeight,
			isVisible: shapeApi.isVisible === true,
			canvasObjectsApi,
			showMask: this.props.areFindingsMasksEnabled === true,
			showHeatMap: this.props.areHeatMapsEnabled === true,
			onSetEditing: (value) => {
				this.props.canvasApi.canDragCanvas(!value);
			},
			onLabelChange: (shape) => {
				this.props.onUpdateShape({
					labelId: shapeApi.id,
					data: {
						...shapeApi.shape,
						...shape,
					},
				});
			},
			onLabelRemove: () => this.props.onRemoveLabel({ labelId: shapeApi.id }),
			onGetHeatmapUrl: (shape) => {
				if ( urlCache.hasCache(shapeApi.id) ) {
					return urlCache.getCache(shapeApi.id);
				}

				const url = shapeApi.onGetHeatmapUrl(shape, shapeApi.onGetColor);
				urlCache.setCache(shapeApi.id, url);

				return url;
			},
		};

		let Component = null;

		switch (shapeApi.shape.type) {
			case 'box':
				Component = ImageShapeBox;
				break;

			case 'poly':
				Component = ImageShapePolygon;
				break;

			case 'named_poly':
				Component = ImageShapeBoneloss;
				break;

			default:
				return null;
		}

		if ( shapeApi.shouldShowTooltip === true ) {
			return (
				<ShapeTooltip
					key={`shape_${shapeApi.id}`}
					label={shapeApi.label}
					viewport={this.props.canvasApi.viewport}
					component={Component}
					componentProps={props}
				/>
			);
		}

		return (<Component {...props} key={shapeApi.id} />);
	}

	render () {
		const groups = [ { children: [] }, { children: [] } ];
		this.props.labels.forEach((label) => {
			const labelId = labelGetters.getLabelId(label);
			const hasSelectedLabel = this.props.selectedLabel !== null;
			const isSelectedLabel = hasSelectedLabel === true && labelGetters.getLabelId(this.props.selectedLabel) === labelId;
			const isHighlighted = this.props.highlightedLabelsIds[labelId] === true;
			const isHoveredLabel = this.props.closestObjectToPoint !== null && this.props.closestObjectToPoint.id === labelId;
			const isVisible = (
				this.props.visibleLabelsIds[labelId] === true ||
				isSelectedLabel === true ||
				isHighlighted === true
			);

			const shouldShowTooltip = (
				this.props.isSelectedObjectInTransformation === false &&
				isHoveredLabel === true &&
				( this.props.selectedLabel !== null
						? labelId !== labelGetters.getLabelId(this.props.selectedLabel)
						: true
				)
			);

			const api = {
				label,
				hasSelectedLabel,
				isSelectedLabel,
				isVisible,
				isHighlighted,
				isHoveredLabel,
				id: labelGetters.getLabelId(label),
				shape: labelGetters.getLabelShape(label),
				color: labelsUtils.getLabelColor(label),
				borderStyle: labelsUtils.getShapeBorderStyle(label),
				isConfirmed: labelGetters.getLabelIsConfirmed(label),
				shouldShowTooltip,
				hasRightsToEdit: this.props.canEditShapes && this.props.canEditShape(label),
				onGetColor: ({ matrixValue }) => {
					return labelsUtils.getHeatMapColor(labelGetters.getLabelClassId(label), matrixValue, labelGetters.getLabelMeasureOfConfidence(label));
				},
				onGetHeatmapUrl: imageUtils.getHeatmapUrl,
			};

			const renderedShape = this._renderShape({ shapeApi: api, canvasObjectsApi: this.props.canvasObjectsApi });

			if ( isSelectedLabel === true ) {
				groups[2] = {
					children: [ renderedShape ],
					style: {
						pointerEvents: 'bounding-box',
					},
				};
			}
			else if ( isHighlighted === true ) {
				if ( groups[1])
					groups[1].children.push(renderedShape);
			}
			else {
				groups[0].children.push(renderedShape);
			}
		});

		return groups.map(({ children, style }, index) => (
			<g key={index} style={style}>{children}</g>
		));
	}
}

export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const showAllClasses = editorSelectors.selectShowAllFindings(state);
	const highlightedLabelsIds = Object.values( editorData.highlightedLabels || [])
		.reduce((result, labelId) => {
			result[labelId] = true;
			return result;
		}, {});
	const filteredClasses = editorSelectors.selectFilteredClassesForCurrentImage(state);
	const visibleLabelsIds = {};
	const selectedLabel = editorData.selectedLabel;
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const aiPathologiesButtonAvailable = userSelectors.selectUsesComputerAidedDeviceUi(state);
	const aiPathologiesEnabled = currentImage.is_sequential_viewed === true;
	const usesComputerAidedDeviceUi = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
	let labels = [];

	if ( editorData.showFindingsOnImage === true ) {
		const isImageAnalyzed = analyseSelectors.selectIsImageAnalyzed(state, { imageId: currentImageId });

		if ( !getRuntimeConfig()['auto-image-analysis'] || !isImageAnalyzed ) {
			const preLabels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
				imageId: currentImageId,
			}).reduce((result, labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});

				const isSelected = selectedLabel && labelGetters.getLabelId(selectedLabel) === labelId;

				if ( labelsUtils.labelIsTooth(label) ) {
					result[labelId] = label;
					return result;
				}

				const classId = labelGetters.getLabelClassId(label);
				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);

				const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
				const labelParentMap = {};
				Object.keys(labelChildren)
					.forEach((labelId) => {
						labelChildren[labelId].forEach((childLabelId) => {
							labelParentMap[childLabelId] = labelId;
						});
					});

				const parentLabelId = labelParentMap[labelId];
				const parentLabel = labelsSelectors.selectLabelById(state, { labelId: parentLabelId });
				const source = labelGetters.getLabelSource(label);

				if ( mainConfig.BONE_LOSS_LINES_INVISIBLE.includes(classId) === true ) {
					if (
						highlightedLabelsIds[labelId] === true ||
						isSelected ||
						(selectedLabel && parentLabel && labelGetters.getLabelId(selectedLabel) === labelGetters.getLabelId(parentLabel)) ||
						highlightedLabelsIds[parentLabelId] === true ||
						(
							usesComputerAidedDeviceUi === true &&
							checkBoneLossLine({
								label,
								showBoneLossStages: editorData.showBoneLossStages,
								allowBoneLossLines: mainConfig.BONE_LOSS_LINES_INVISIBLE,
							})
						)
					) {
						result[labelId] = label;
					}
				}
				else if ( mainConfig.BONE_LOSS_LINES_VISIBLE.includes(classId) === true ) {
					if (
						highlightedLabelsIds[labelId] === true ||
						isSelected ||
						(selectedLabel && parentLabel && labelGetters.getLabelId(selectedLabel) === labelGetters.getLabelId(parentLabel)) ||
						highlightedLabelsIds[parentLabelId] === true ||
						checkBoneLossLine({
							label,
							showBoneLossStages: editorData.showBoneLossStages,
							allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
						})
					) {
						result[labelId] = label;
					}
				}
				else if (
					(
						highlightedLabelsIds[labelId] === true ||
						isSelected ||
						showAllClasses ||
						filteredClasses[classId] === true
					) && (
						typeof measureOfConfidence !== 'number' ||
						editorData.filteredConfidencePercent <= measureOfConfidence
					) &&
					(
						aiPathologiesButtonAvailable === false ||
						aiPathologiesEnabled === true ||
						source === 'manual' ||
						( classId !== 'caries' && classId !== 'periodontitis' )
					)
				) {
					result[labelId] = label;
				}

				return result;
			}, {});

			labels = Object.keys(preLabels).reduce((result, labelId) => {
				const label = preLabels[labelId];
				const isSelected = selectedLabel ? labelGetters.getLabelId(selectedLabel) === labelId : false;
				result.push(label);

				if (
					(
						( labelsUtils.labelIsTooth(label) || 'no_bone_loss_line' === labelGetters.getLabelClassId(label) ) &&
						!(
							highlightedLabelsIds[labelId] === true ||
							(isSelected === true) ||
							(!showAllClasses && (true === filteredClasses[labelGetters.getLabelClassId(label)]))
						)
					) ||
					(
						editorData.editorMode === mainConfig.EDITOR_MODE__EDIT_MODE &&
						isSelected === false &&
						highlightedLabelsIds[labelId] !== true
					)
				) {
					return result;
				}

				visibleLabelsIds[labelId] = true;

				return result;
			}, []);
		}
	}

	return {
		labels,
		highlightedLabelsIds,
		visibleLabelsIds,
		selectedLabel,
		editorMode: editorData.editorMode,
		labelsStage: editorData.labelsStage,
		isFDAAnnotationEnabled: userSelectors.selectIsFDAAnnotationEnabled(state),
		currentImage: imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}),
		areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
		areHeatMapsEnabled: editorData.areHeatMapsEnabled,
		canEditShape: (label) => usesComputerAidedDeviceUi === false || mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(label)) === false,
	};
}, (dispatch) => ({
	onHighlightLabels: (data) => dispatch(editorActions.highlightLabels({
		data,
	})),
	onSelectLabel: (data) => dispatch(editorActions.selectLabel(data)),
	onRemoveLabel: (data) => dispatch(labelsActions.removeLabel(data)),
	onSetConfirmed: (data) => dispatch(labelsActions.setConfirmed(data)),
	onUpdateShape: (data) => dispatch(labelsActions.updateLabelShape(data)),
}))(MainShape);
