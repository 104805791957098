import blurRGBA from 'glur';


function getCanvas (width, height) {
	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;

	return canvas;
}

function getPixels (image) {
	let canvas, context;

	if (image.getContext) {
		canvas = image;
		try {
			context = canvas.getContext('2d');
		}
		catch(error) {
			// do nothing
		}
	}
	if ( !context ) {
		canvas = getCanvas(image.naturalWidth, image.naturalHeight);
		context = canvas.getContext('2d');
		context.drawImage(image, 0, 0);
	}
	return context.getImageData(0, 0, canvas.width, canvas.height);
}

function createImageData (width, height) {
	return getCanvas(width, height).getContext('2d').createImageData(width, height);
}

function convolute (pixels, weights, opaque) {
	const side = Math.round(Math.sqrt(weights.length));
	const halfOfSide = Math.floor(side / 2);
	const src = pixels.data;
	const sw = pixels.width;
	const sh = pixels.height;
	// pad output by the convolution matrix
	const w = sw;
	const h = sh;
	const output = createImageData(w, h);
	const dst = output.data;
	// go through the destination image pixels
	const alphaFac = opaque ? 1 : 0;
	for (let y = 0; y < h; y++) {
		for (let x = 0; x < w; x++) {
			const sy = y;
			const sx = x;
			const dstOff = (y * w + x) * 4;
			// calculate the weighed sum of the source image pixels that
			// fall under the convolution matrix
			let r = 0, g = 0, b = 0, a = 0;
			for (let cy = 0; cy < side; cy++) {
				for (let cx = 0; cx < side; cx++) {
					const scy = sy + cy - halfOfSide;
					const scx = sx + cx - halfOfSide;
					if (scy >= 0 && scy < sh && scx >= 0 && scx < sw) {
						const srcOff = (scy * sw + scx) * 4;
						const wt = weights[cy * side + cx];
						r += src[srcOff] * wt;
						g += src[srcOff + 1] * wt;
						b += src[srcOff + 2] * wt;
						a += src[srcOff + 3] * wt;
					}
				}
			}
			dst[dstOff] = r;
			dst[dstOff + 1] = g;
			dst[dstOff + 2] = b;
			dst[dstOff + 3] = a + alphaFac * (255 - a);
		}
	}
	return output;
}

function addMatrix (a, b) {
	for (let i = 0, l = a.length; i < l; i += 4) {
		a[i] = Math.min(255, Math.max(0, a[i] + b[i]));
		a[i + 1] = Math.min(255, Math.max(0, a[i + 1] + b[i + 1]));
		a[i + 2] = Math.min(255, Math.max(0, a[i + 2] + b[i + 2]));
	}

	return a;
}

function subtractMatrix (a, b) {
	for (let i = 0, l = a.length; i < l; i += 4) {
		a[i] = Math.min(255, Math.max(0, a[i] - b[i]));
		a[i + 1] = Math.min(255, Math.max(0, a[i + 1] - b[i + 1]));
		a[i + 2] = Math.min(255, Math.max(0, a[i + 2] - b[i + 2]));
	}

	return a;
}

function multiplyMatrix (a, amount) {
	for (let i = 0, l = a.length; i < l; i += 4) {
		a[i] = Math.min(255, Math.max(0, a[i] * amount));
		a[i + 1] = Math.min(255, Math.max(0, a[i + 1] * amount));
		a[i + 2] = Math.min(255, Math.max(0, a[i + 2] * amount));
	}

	return a;
}

function cloneImageData (imageData) {
	return new Uint8ClampedArray(imageData);
}

export function sharpenImage (image) {
	const canvas = getCanvas(image.naturalWidth, image.naturalHeight);
	const context = canvas.getContext('2d');

	const imageData = getPixels(image).data;
	const origImageData = cloneImageData(imageData);
	const blurredImageData = cloneImageData(imageData);
	blurRGBA(blurredImageData, canvas.width, canvas.height, 3);

	const result = addMatrix(origImageData, multiplyMatrix(subtractMatrix(cloneImageData(origImageData), blurredImageData), 2));

	context.putImageData(new ImageData(result, canvas.width, canvas.height), 0, 0);

	return canvas.toDataURL();
}
