import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import imagesSelectors from '../../selectors/imagesSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';

import LabelList from '../LabelList';
import DentalNotationDiagram from '../DentalNotationDiagram';
import SidebarFooter from './SidebarFooter';
import { DrawSecondaryBoxMode } from '../DisableLayout';
import TopPane from '../TopPane';

import './styles/Sidebar.css';

const baseCssClassName = 'sidebar';
const upperBlockCssClassName = `${baseCssClassName}__sidebar_upper-block`;
const dentalNotationDiagramCssClassName = `${baseCssClassName}__dental-notation-diagram`;
const findingsDiagramCssClassName = `${baseCssClassName}__findings`;

const propTypes = {
	currentImage: PropTypes.object,
	isOwnImage: PropTypes.bool.isRequired,
	showReportButton: PropTypes.bool.isRequired,
	usesComputerAidedDeviceUi: PropTypes.bool.isRequired,
};


const Sidebar = ({
	isOwnImage,
	currentImage,
	showReportButton,
	reportImageUrl,
	usesComputerAidedDeviceUi
}) => {
	return (
		<div className={baseCssClassName}>
			<TopPane />
			{usesComputerAidedDeviceUi === false && (
				<div
					className={classnames([
						upperBlockCssClassName,
						dentalNotationDiagramCssClassName,
					])}
				>
					<DentalNotationDiagram />
				</div>
			)}
			<div className={findingsDiagramCssClassName}>
				<LabelList />
			</div>
			{currentImage && (
				<SidebarFooter
					isOwnImage={isOwnImage}
					showReportButton={showReportButton}
					reportImageUrl={reportImageUrl}
				/>
			)}
			<DrawSecondaryBoxMode />
		</div>
	);
};

Sidebar.propTypes = propTypes;


export default connect((state) => {
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;

	return {
		currentImage,
		currentCollectionId,
		isOwnImage: currentImage.isOwn,
		reportImageUrl: `${window.location.href}/report`,
		showReportButton: true,
		usesComputerAidedDeviceUi: userSelectors.selectUsesComputerAidedDeviceUi(state) === true,
	};
})(Sidebar);
