import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import when from 'when';

import PopupDialog, { getHeaderCloseButton } from '../PopupDialog';
import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';


const i18nShared = getDictionary('shared');


/**
 * @typedef {Object} PopupDialogPromptProps
 *
 * @property {function(PopupDialogPromptOptions):ReactElement} User content(form fields, captions, etc).
 * @property {PopupDialogPromptFieldsOptions} fields Fields description
 * @property {ReactElement|string} [title] Dialog title.
 * @property {string} [confirmButtonText] Confirm button text.
 * @property {string} [cancelButtonText] Cancel button text.
 * @property {function} [onConfirm]
 * @property {function} [onCancel]
 * @property {PopupDialogProps} [popupDialogProps]
 */

/**
 *
 * @typedef {Object} PopupDialogPromptOptions
 *
 * @property {function} onSetFieldValue
 * @property {{value:*, errorKey:string}} fields
 */


/**
 * Displays confirm dialog.
 *
 * @param {PopupDialogPromptProps} props
 * @returns {ReactElement}
 */
class PopupDialogPrompt extends PureComponent {
	/**
	 * @typedef {Object<key, {initialValue: any, validators: Array<{key: string, fn: function}>}>} PopupDialogPromptFieldsOptions
	 */
	static propTypes = {
		content: PropTypes.func.isRequired,
		
		fields: PropTypes.shape({
			initialValue: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			validators: PropTypes.arrayOf(PropTypes.object),
		}).isRequired,
		
		title: PropTypes.node,
		
		confirmButtonText: PropTypes.string,
		cancelButtonText: PropTypes.string,
		
		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,
		
		popupDialogProps: PropTypes.object,
	};
	
	constructor (props, context) {
		super(props, context);

		this.state = this._getInitialState(props.fields);
	}

	componentWillUnmount () {
		this.state = null;
	}

	/**
	 * @param {PopupDialogPromptFieldsOptions} fields
	 * @returns {{fields: Array<string>, fieldsValues: Object<key, any>, fieldsErrorsKeys: Object<key, string>, fieldsValidators: Object<key, function(value: any):boolean}}
	 * @private
	 */
	_getInitialState (fields) {
		const state = {
			fields: [],
			fieldsValues: {},
			fieldsErrorsKeys: {},
			fieldsValidators: {},
			confirmIsInProgress: false,
		};

		Object.keys(fields).forEach((fieldName) => {
			const field = fields[fieldName];

			state.fields.push(fieldName);
			state.fieldsValues[fieldName] = field.initialValue;
			state.fieldsErrorsKeys[fieldName] = null;
			state.fieldsValidators[fieldName] = ( field.validators || [] );
		});

		return state;
	}

	_handleSetFieldValue = ({ fieldName, value }) => {
		this.setState({
			fieldsValues: {
				...this.state.fieldsValues,
				[fieldName]: value,
			},
		});
	};

	_handleConfirm = ({ popup: { close } }) => {
		const {
			onConfirm,
		} = this.props;

		if ( this.state.confirmIsInProgress ) {
			return;
		}

		const fieldsWithError = this._validate();

		if ( Object.keys(fieldsWithError).length > 0 ) {
			this.setState({
				fieldsErrorsKeys: fieldsWithError,
			});

			return;
		}

		if ( Object.keys(this.state.fieldsErrorsKeys).length > 0 ) {
			this.setState({
				fieldsErrorsKeys: {},
			});
		}

		if ( onConfirm ) {
			this.setState({
				confirmIsInProgress: true,
			});

			when(onConfirm({
				...this.state.fieldsValues,
			}))
				.then(() => {
					close();
				})
				.finally(() => {
					if ( !this.state ) {
						return;
					}

					this.setState({
						confirmIsInProgress: false,
					});
				});

			return;
		}

		close();
	};

	_handleCancel = ({ popup: { close } }) => {
		const {
			onCancel,
		} = this.props;

		if ( onCancel ) {
			onCancel();
		}

		close();
	};

	/**
	 * Validates fields values. Returns an object with errors if any validator returned false.
	 * @private
	 */
	_validate () {
		const {
			fields,
			fieldsValues,
			fieldsValidators,
		} = this.state;

		return fields.reduce((errors, fieldName) => {
			fieldsValidators[fieldName].forEach(({ key, fn }) => {
				if ( typeof fn !== 'function' || errors[fieldName] ) {
					return;
				}

				if ( fn(fieldsValues[fieldName]) === false ) {
					errors[fieldName] = key;
				}
			});

			return errors;
		}, {});
	}

	/**
	 * Returns fields state.
	 *
	 * @returns {Object<key, {value: any,errorKey: string}>}
	 * @private
	 */
	_getFields = () => {
		const {
			fieldsValues,
			fieldsErrorsKeys,
		} = this.state;

		return this.state.fields.reduce((fields, fieldName) => {
			fields[fieldName] = {
				value: fieldsValues[fieldName],
				errorKey: fieldsErrorsKeys[fieldName],
			};

			return fields;
		}, {});
	}

	_renderContent () {
		return this.props.content({
			onSetFieldValue: this._handleSetFieldValue,
			fields: this._getFields(),
		});
	}

	render () {
		return (
			<PopupDialog
				{...this.props.popupDialogProps}
				headerProps={{
					title: ( this.props.title || i18nShared('confirm.dialog.title') ),
					buttons: (dialogInterface) => {
						return [
							getHeaderCloseButton(dialogInterface),
						];
					},
				}}
				content={this._renderContent()}
				footerProps={{
					buttons: (dialogInterface) => [
						(
							<Button
								key={'confirm_button'}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								progress={this.state.confirmIsInProgress}
								onClick={() => this._handleConfirm(dialogInterface)}
							>{( this.props.confirmButtonText || i18nShared('confirm.buttons.yes') )}</Button>
						),
						(
							<Button
								key={'cancel_button'}
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								disabled={this.state.confirmIsInProgress}
								onClick={() => this._handleCancel(dialogInterface)}
							>{( this.props.cancelButtonText || i18nShared('confirm.buttons.no') )}</Button>
						),
					],
				}}
			/>
		);
	}
}

export default PopupDialogPrompt;
