import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageShapePolygon from '../polygon';

import labelsUtils from '../../../../appUtils/labelsUtils';

import { isPointVisible } from '../../../../services/canvas';

import './styles/ImageShapeBoneloss.css';


const baseCssClassName = 'image-shape-boneloss';
const labelCssClassName = `${baseCssClassName}__label`;

const LABEL_MAX_LENGTH = 4;
const LABEL_WIDTH = 6;
const LABEL_HEIGHT = 7;

const RECT_WIDTH = LABEL_MAX_LENGTH * LABEL_WIDTH;
const RECT_HEIGHT = 10;
const RECT_HALF_WIDTH = RECT_WIDTH / 2;
const RECT_HALF_HEIGHT = RECT_HEIGHT / 2;


/*
function area (a, b, c) {
	return (b[0] - a[0]) * (c[1] - a[1]) - (b[1] - a[1]) * (c[0] - a[0]);
}

function intersect_1 (a, b, c, d) {
	if ( a > b ) {
		[ a, b ] = [ b, a ];
	}
	if ( c > d ) {
		[ c, d ] = [ d, c ];
	}
	return Math.max(a, c) <= Math.min(b, d);
}

function intersect (a, b, c, d) {
	return (
		intersect_1 (a[0], b[0], c[0], d[0]) &&
		intersect_1 (a[1], b[1], c[1], d[1]) &&
		area(a,b,c) * area(a,b,d) <= 0 &&
		area(c,d,a) * area(c,d,b) <= 0
	);
}
*/

function buildRects ([ x, y ], zoom) {
	const x1 = x * zoom - RECT_HALF_WIDTH;
	const y1 = (y * zoom - RECT_HEIGHT - RECT_HALF_HEIGHT);

	const x2 = x * zoom - RECT_HALF_WIDTH;
	const y2 = (y * zoom + RECT_HALF_HEIGHT);

	const x3 = (x * zoom - RECT_WIDTH) - RECT_HALF_HEIGHT;
	const y3 = y * zoom - RECT_HALF_HEIGHT;

	const x4 = (x * zoom) + RECT_HALF_HEIGHT;
	const y4 = y * zoom - RECT_HALF_HEIGHT;

	return [
		[
			[ x1, y1 ],
			[ x1 + RECT_WIDTH, y1 ],
			[ x1 + RECT_WIDTH, y1 + RECT_HEIGHT ],
			[ x1, y1 + RECT_HEIGHT ],
		],
		[
			[ x2, y2 ],
			[ x2 + RECT_WIDTH, y2 ],
			[ x2 + RECT_WIDTH, y2 + RECT_HEIGHT ],
			[ x2, y2 + RECT_HEIGHT ],
		],
		[
			[ x3, y3 ],
			[ x3 + RECT_WIDTH, y3 ],
			[ x3 + RECT_WIDTH, y3 + RECT_HEIGHT ],
			[ x3, y3 + RECT_HEIGHT ],
		],
		[
			[ x4, y4 ],
			[ x4 + RECT_WIDTH, y4 ],
			[ x4 + RECT_WIDTH, y4 + RECT_HEIGHT ],
			[ x4, y4 + RECT_HEIGHT ],
		],
	];
}

function findRect ({
	point,
	shape,
	zoom,
	imageWidth,
	imageHeight,
}) {
	const rects = buildRects(point, zoom);
	let resultedRect = null;
	let resultedRectIndex = null;
	// console.dir('point');
	// console.dir(point);
	// console.dir(rects);

	const matches = [];
	const zoomedImageWidth = imageWidth * zoom;
	const zoomedImageHeight = imageHeight * zoom;

	// console.dir(rects);
	// console.dir(zoomedImageWidth);
	// console.dir(zoomedImageHeight);

	outer:
	for (let i = 0; i < 4; i++) {
		const rect = rects[i];
		// console.warn(`rect ` + i);
		// const [ x, y ] = rect[0];
		// console.dir(x);
		// console.dir(y);
		/*if (
			x < 0 ||
			x + RECT_WIDTH> zoomedImageWidth ||
			y < 0 ||
			y + RECT_HEIGHT > zoomedImageHeight )
		{
			// debugger;
			continue;
		}*/

		matches[i] = 0;

		for (let j = 0; j < 4; j++) {
			const [ x, y ] = rect[j];

			// console.dir('point ' + j);
			// console.dir(x);
			// console.dir(y);
			if (
				!isPointVisible([ x / zoom, y / zoom ]) ||
				(
					x < 0 ||
					x > zoomedImageWidth ||
					y < 0 ||
					y > zoomedImageHeight
				)
			) {
				// debugger;
				// console.dir('out of image');
				matches[i] = null;
				continue outer;
			}

			if ( labelsUtils.isCursorInShape(shape, x / zoom, y / zoom) ) {
				// console.dir('in shape');
				continue;
			}
			matches[i] = matches[i] + 1;
		}

		// resultedRect = rects[i];
		resultedRectIndex = i;
	}

	if ( !resultedRect ) {
		let rectIndex = 0;
		let rectCount = -1;
		for (let i = 0; i < 4; i++) {
			if ( typeof matches[i] === 'number' && matches[i] > rectCount ) {
				rectIndex = i;
				rectCount = matches[i];
			}
		}
		// console.dir('picked index ' + rectIndex);
		resultedRect = rects[rectIndex];
		resultedRectIndex = rectIndex;
	}

	return {
		rects,
		rect: resultedRect,
		rectIndex: resultedRectIndex,
	};
}

class ImageShapeBoneloss extends PureComponent {
	static propTypes = {
		shape: PropTypes.object.isRequired,
		color: PropTypes.string.isRequired,
		borderStyle: PropTypes.string.isRequired,
		showControls: PropTypes.bool.isRequired,
		isHighlighted: PropTypes.bool.isRequired,
		allowEditing: PropTypes.bool.isRequired,

		zoom: PropTypes.number.isRequired,
		imageWidth: PropTypes.number,
		imageHeight: PropTypes.number,

		onLabelChange: PropTypes.func,
		onSetEditing: PropTypes.func,
	}

	_getTextBox ([ x, y ], label) {
		return [
			x + ((RECT_WIDTH - (label.length * LABEL_WIDTH)) / 2),
			y + RECT_HEIGHT - ((RECT_HEIGHT - LABEL_HEIGHT) / 2),
		];
	}

	_handleRenderChildren = ({ points }) => {
		if ( !this.props.showControls && !this.props.isHighlighted ) {
			return null;
		}
		const {
			zoom,
		} = this.props;

		const result = [];
		const labels = this.props.shape.labels;
		const length = points.length;

		for ( let i = 0; i < length; i++ ) {
			if ( !labels[i] ) {
				continue;
			}

			const { rects, rect } = findRect({
				point: points[i],
				shape: {
					...this.props.shape,
					points,
				},
				imageWidth: this.props.imageWidth,
				imageHeight: this.props.imageHeight,
				zoom,
			});

			const [ x, y ] = this._getTextBox(rect[0], labels[i]);

			// rects.forEach((_rect, j) => {
			// 	const _points = _rect.map((point) => {
			// 		return `${point[0]},${point[1]}`;
			// 	}).join(' ');
			// 	result.push(
			// 		<polygon key={`${i},${j}`} points={_points} fill={_rect === rect ? 'red' : 'black'} />
			// 	);
			// });

			// const [ x, y ] = findRect({
			// 	point: points[i],
			// 	shape: this.props.shape,
			// });
			//
			result.push(
				<text key={i} x={x} y={y} className={labelCssClassName}>{labels[i]}</text>
			);
			// result.push(
			// 	<rect key={i + 700} x={x * zoom} y={y * zoom} width={20} height={20} />
			// )
		}

		return result;
	}

	render () {
		return (
			<Fragment>
				<ImageShapePolygon
					{...this.props }
					allowAdd={false}
					allowRemove={false}
					showControls={this.props.showControls || this.props.isHighlighted}
					onRenderChildren={this._handleRenderChildren}
				/>
			</Fragment>
		);
	}
}

export default ImageShapeBoneloss;
