import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonUtils from '../../../../appUtils/common';
import { APP_IMAGE_MODE } from '../../../../constants/imageConstants';
import { setAppImageMode } from '../../../../services/app';

import { loadImage } from '../../actions';
import imagesActions from '../../../../actions/imagesActions';
import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';


import Loading from '../../../../components/Loading';
import Report from './Report';


const STATUSES = {
	IDLE: 'idle',
	IN_PROGRESS: 'in-progress',
	DONE: 'done',
	ERROR: 'error',
};


class ReportImageHoc extends Component {
	static propTypes = {
		currentImageId: PropTypes.string,
		imageId: PropTypes.string.isRequired,
		currentCollectionId: PropTypes.string,
		collectionId: PropTypes.string,
		isShared: PropTypes.bool.isRequired,

		onGetImageFromCollection: PropTypes.func.isRequired,
		onLoadImage: PropTypes.func.isRequired,
		onLoadSharedImage: PropTypes.func.isRequired,
		onSetCurrentImage: PropTypes.func.isRequired,
		onGoToLandingPage: PropTypes.func.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		this._imageProccessed = false;
		this._router = context.router;
		this._arrowUpImage = null;

		this.state = {
			status: STATUSES.IDLE,
		};
	}

	componentDidMount () {
		setAppImageMode(APP_IMAGE_MODE.TREATMENT_PLAN);
		this._checkImage(this.props);
	}

	componentWillUnmount () {
		this.state = null;
	}

	_checkImage (props) {
		if ( this._imageProccessed === false ) {
			this._imageProccessed = true;

			const image = this.props.onGetImageFromCollection({
				collectionHashName: this.props.collectionId,
				imageHashName: this.props.imageId,
			});

			this.setState({
				status: STATUSES.IN_PROGRESS
			});

			if ( image ) {
				this.props.onLoadImage({
					collectionHashName: this.props.collectionId,
					imageHashName: this.props.imageId,
				})
					.then((result) => {
						if ( !this.state ) {
							return;
						}

						this.props.onSetCurrentImage({
							id: result.id,
						});

						this.setState({
							status: STATUSES.DONE
						});
					})
					.catch((error) => {
						if ( !this.state ) {
							return;
						}

						console.error(error);

						this.setState({
							status: STATUSES.ERROR
						});
					});
			}
			else {
				this.props.onLoadSharedImage({
					hashName: props.imageId,
				})
					.then(({ image, collection }) => {
						if ( !this.state ) {
							return;
						}

						if ( collection.col_hash && collection.img_hash ) {
							this._router.history.push(`/collections/${collection.col_hash}/image/${collection.img_hash}/treatment_plan/report`);
							window.location.reload();
							return;
						}

						this.setState({
							status: STATUSES.DONE
						});
					})
					.catch((error) => {
						if ( !this.state ) {
							return;
						}

						console.error(error);
						this.setState({
							status: STATUSES.ERROR
						});
					});
			}
		}
	}

	render () {
		let content = null;
		if ( this.state.status === STATUSES.IDLE || this.state.status === STATUSES.IN_PROGRESS ) {
			content = (<Loading />);
		}
		else if ( this.state.status === STATUSES.ERROR ) {
			content = (<div>An error occurred. Please try again</div>);
		}
		else {
			content = (
				<Report
					isShared={this.props.isShared}
					collectionId={this.props.collectionId}
					imageId={this.props.imageId}
				/>
			);
		}

		return (
			<Fragment>
				{content}
				<style
					type={'text/css'}
					dangerouslySetInnerHTML={{
						__html: `
							body, html {
								background-color: #fff !important;
							}
						`
					}}
				/>
			</Fragment>
		);
	}
}

export default connect((state, props) => {
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;

	return {
		currentImageId,
		currentCollectionId,
		onGetImageFromCollection: (data) => imagesSelectors.selectImageByHashNameInCollection(state, data),
	};
}, (dispatch) => ({
	onLoadImage: (data) => dispatch(loadImage(data)),
	onLoadSharedImage: (data) => dispatch(imagesActions.loadSharedImage(data)),
	onSetCurrentImage: (data) => dispatch(editorActions.setCurrentImage(data)),
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
}))(ReportImageHoc);
