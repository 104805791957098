import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import RoundButton from './RoundButton';
import Input from '@material-ui/core/Input';

import './styles/Pagination.css';



const baseCssClassName = 'pagination';
const positionCssClassName = `${baseCssClassName}__position`;
const controlCssClassName = `${baseCssClassName}__control`;
const iconCssClassName = `${baseCssClassName}__icon`;


export default class Pagination extends PureComponent {
	static propTypes = {
		currentPage: PropTypes.number,
		totalPages: PropTypes.number,
		
		firstPageDisabled: PropTypes.bool.isRequired,
		prevPageDisabled: PropTypes.bool.isRequired,
		nextPageDisabled: PropTypes.bool.isRequired,
		lastPageDisabled: PropTypes.bool.isRequired,
		
		onGoFirstPage: PropTypes.func.isRequired,
		onGoPrevPage: PropTypes.func.isRequired,
		onGoNextPage: PropTypes.func.isRequired,
		onGoLastPage: PropTypes.func.isRequired,
		onGoToPage: PropTypes.func.isRequired,
	};
	
	constructor (props) {
		super(props);
		
		this.state = {
			currentPage: props.currentPage,
		};
	}
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		if ( nextProps.currentPage !== this.props.currentPage ) {
			this.setState({
				currentPage: nextProps.currentPage,
			});
		}
	}
	
	_goToPage () {
		const currentPage = Number(this.state.currentPage);
		
		if ( this._validatePage(currentPage) ) {
			this.props.onGoToPage(currentPage);
		}
	}
	
	_validatePage (page) {
		return (
			/^[0-9]+$/.test(page) === true &&
			!isNaN(page) &&
			page >= 1 &&
			page <= this.props.totalPages
		);
	}
	
	_handleImagePositionChange = (event) => {
		this.setState({
			currentPage: event.target.value,
		});
	};
	
	_handleImagePositionKeyPress = (event) => {
		if ( event.key.toLowerCase() === 'enter' ) {
			this._goToPage();
		}
	};
	
	_handleImagePositionBlur = () => {
		this._goToPage();
	};
	
	render () {
		return (
			<div className={baseCssClassName}>
				<div className={controlCssClassName}>
					<RoundButton
						disabled={this.props.firstPageDisabled}
						onClick={this.props.onGoFirstPage}
					>
						<div className={`${iconCssClassName} ${iconCssClassName}__m-first`} />
					</RoundButton>
				</div>
				<div className={controlCssClassName}>
					<Button
						size={Button.AVAILABLE_SIZES.LARGE}
						disabled={this.props.prevPageDisabled}
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						onClick={this.props.onGoPrevPage}
					>
						<div className={`${iconCssClassName} ${iconCssClassName}__m-prev`} />Prev
					</Button>
				</div>
				<div className={positionCssClassName}>
					<div><Input
						error={!this._validatePage(this.state.currentPage)}
						value={this.state.currentPage}
						style={{ fontSize: 14, width: 25 }}
						inputProps={{
							style: {
								textAlign: 'right',
							},
						}}
						onChange={this._handleImagePositionChange}
						onBlur={this._handleImagePositionBlur}
						onKeyPress={this._handleImagePositionKeyPress}
					/> of {this.props.totalPages}</div>
				</div>
				<div className={controlCssClassName}>
					<Button
						size={Button.AVAILABLE_SIZES.LARGE}
						disabled={this.props.nextPageDisabled}
						theme={Button.AVAILABLE_THEMES.SECONDARY}
						onClick={this.props.onGoNextPage}
					>
						Next<div className={`${iconCssClassName} ${iconCssClassName}__m-next`} />
					</Button>
				</div>
				<div className={controlCssClassName}>
					<RoundButton
						disabled={this.props.lastPageDisabled}
						onClick={this.props.onGoLastPage}
					>
						<div className={`${iconCssClassName} ${iconCssClassName}__m-last`} />
					</RoundButton>
				</div>
			</div>
		);
	}
}
