export default {
	'labels.first_name': 'First name',
	'labels.last_name': 'Last name',
	'labels.country': 'Country',
	'labels.clinic': 'Clinic',
	'labels.language': 'Language',
	'labels.notation': 'Dental Notation System',
	'labels.report_specialist_email': 'Email of specialist to display in the report',

	'buttons.change_email': 'Change email',
	'buttons.change_password': 'Change password',
	'buttons.upload_logo': `Upload<br />logo`,
};
