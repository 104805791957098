import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import mainConfig from '../../../../configs/mainConfig';

import { trackEvent } from '../../../../integrations/mixpanel';

import { USER_PERMISSION__USE_ANALYSIS } from '../../../../constants/userPermissionsConstants';

import aclService from '../../../../services/acl';

import editorActions from '../../../../actions/editorActions';
import imagesActions from '../../../../actions/imagesActions';
import {
	updateLabelShape,
	removeLabelShape,
	analyzeImage,
} from '../../actions';
import labelsActions from '../../../labels/actions/labelsActions';

import labelsUtils from '../../../../appUtils/labelsUtils';
import { isSequentialModeEnabledForVisit } from '../../utils';
import { checkFindingsFilter, getFindingCategory, isExtraFinding } from '../../utils/findings-filter';
import { isBonelossLine } from '../../../../appUtils/bonelossUtils';

import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';

import labelGetters from '../../../labels/selectors/labelGetters';

import FmxViewer from './FmxViewer';


export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;
	const currentImageId = editorData.currentImageId;
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const currentCollection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
	const findingsFilter = editorData.treatmentPlanFilters;
	const images = currentCollection.images.reduce((result, image) => {
		if ( image.examination !== currentImage.examination ) {
			return result;
		}

		if ( Array.isArray(result[image.image_type]) === false ) {
			result[image.image_type] = [];
		}

		result[image.image_type].push(imagesSelectors.selectImageByHashName(state, { hashname: image.hashname }));

		return result;
	}, {});
	const labels = {};
	const visibleLabelsIds = {};
	const selectedLabel = editorData.selectedLabel;
	const teethMeta = labelsSelectors.selectTeeth(state);

	if ( editorData.showFindingsOnImage === true ) {
		Object.values(images)
			.reduce((result, images) => {
				return result.concat(images);
			}, [])
			.forEach((image) => {
				imagesLabelsSelectors.selectImageLabelsByImageId(state, {
					imageId: image.id,
				})
					.forEach((labelId) => {
						const label = labelsSelectors.selectLabelById(state, {
							labelId,
						});
						const shape = lodashGet(label, `shapes.[${image.hashname}]`, {});
						if ( typeof shape.type !== 'string' ) {
							return;
						}

						const classId = labelGetters.getLabelClassId(label);
						const labelCategory = getFindingCategory({ label });
						if ( selectedLabel && labelId !== labelGetters.getLabelId(selectedLabel) && editorData.highlightedLabels.includes(labelId) === false ) {
							return;
						}
						else if ( isBonelossLine(classId) === true ) {
							if (
								editorData.showBoneLossStages === false ||
								mainConfig.BONE_LOSS_LINES_VISIBLE.includes(classId) === false
							) {
								return;
							}
						}
						else if (
							labelsUtils.labelIsTooth(label) === true ||
							checkFindingsFilter({
								findingsFilter,
								label,
							}) === false ||
							isExtraFinding({ label }) === true ||
							(
								user.is_simple_ui_enabled === true && labelCategory !== 'pathological'
							)
						) {
							return;
						}

						if ( 'no_bone_loss_line' !== labelGetters.getLabelClassId(label) ) {
							visibleLabelsIds[labelGetters.getLabelId(label)] = true;
						}
						if ( Array.isArray(labels[image.hashname]) === false ) {
							labels[image.hashname] = [];
						}
						labels[image.hashname].push(label);
					});
		});
	}

	const shouldDisplayTeethNumbers = (
		editorData.showFindingsOnImage &&
		user.is_simple_ui_enabled !== true
	);

	return {
		images,
		notationType,
		labels,
		visibleLabelsIds,
		teethConflicts: editorData.teethConflicts,
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
		teethMeta,
		areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
		showFindingsOnImage: editorData.showFindingsOnImage,
		shouldDisplayTeethNumbers,
		shouldDisplayTeethNumbersUnderImages: shouldDisplayTeethNumbers === false,
		areHeatMapsEnabled: editorData.areHeatMapsEnabled,
		notAnalyzedImages: editorData.notAnalyzedImages,
		canAnalyze: (
			userSelectors.selectUserData(state).username === currentCollection.username &&
			aclService.checkPermission(USER_PERMISSION__USE_ANALYSIS)
		),
		selectedLabel: editorData.selectedLabel,
		highlightedLabels: editorData.highlightedLabels || [],
		allowShiftTooth: currentImage.isOwn === true,
		allowRemoveTooth: currentImage.isOwn === true,
		enhancedImageFilter: editorData.enhancedImageFilter,
	};
}, (dispatch) => ({
	onImageRedirect: () => dispatch(editorActions.updateData({
		data: {
			isFmxModeEnabled: false,
		},
	})),
	onUpdateLabelShape: (data) => dispatch(updateLabelShape(data)),
	onLabelShapeRemove: (data) => {
		if ( typeof data.toothKey === 'string' ) {
			trackEvent('FMX Remove Tooth', data);
		}
		else {
			trackEvent('FMX Remove Shape', data);
		}
		return dispatch(removeLabelShape(data));
	},
	onRotateLeft: (data) => dispatch(imagesActions.rotateLeft(data))
		// .then(() => dispatch(removeShapesFromImage(data)))
		.then(() => {
			trackEvent('FMX Rotate Image', { direction: 'left', hashname: data.imageHashName });
			return dispatch(imagesActions.markImageAsNotAnalyzed(data));
		}),
	onRotateRight: (data) => dispatch(imagesActions.rotateRight(data))
		// .then(() => dispatch(removeShapesFromImage(data)))
		.then(() => {
			trackEvent('FMX Rotate Image', { direction: 'right', hashname: data.imageHashName });
			return dispatch(imagesActions.markImageAsNotAnalyzed(data))
		}),
	onSelectLabel: (data) => {
		if ( data.labelId !== null ) {
			trackEvent('FMX Selected Label', data);
		}
		else {
			trackEvent('FMX Deselected Label');
		}

		return dispatch(editorActions.selectLabel(data));
	},
	onShiftTooth: (data) => {
		trackEvent('FMX Teeth Shift', data);
		return dispatch(labelsActions.shiftTooth(data))
	},
	onAnalyzeImage: () => dispatch(analyzeImage()),
}))(FmxViewer);
