/**
 * @param {StoreState} state
 * @return {Country[]}
 */
const selectCountries = (state) => ( state.countries || [] );


export default {
	selectCountries,
};
