import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Select, { components, createFilter } from "react-select";
import {CODE_MAPPING} from "../../constants/recommendedTreatmentCodes";
import OptionalTooltip from "../../../../components/Tooltip/OptionalTooltip";

const customStyles = {
	multiValue: (provided, state) => ({
		...provided,
		backgroundColor: '#AEBAB8',
		borderRadius: '4px',
		color: '#45464E',
		background: '#AEBAB8',
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '10px',
		lineHeight: '12px',
		width: 'fit-content'

	}),
	multiValueLabel: (provided, state) => ({
		...provided,
		fontSize: '10px'
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	input: (provided, state) => ({
		...provided,
		color: '#ffffff',
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: '#ffffff',
	}),
	menuList: (provided, state) => ({
		...provided,
		paddingTop: '4px',
		maxHeight: 172,
		'::-webkit-scrollbar': {
			width: '7px',
			height: '7px'
		},
		'::-webkit-scrollbar-thumb':  {
			background: 'rgba(255, 255, 255, .3)'
		}
	}),
	groupHeading: (provided, state) => ({
		...provided,
		paddingLeft: 0,
	}),
	group: (provided, state) => ({
		...provided,
		padding: 0,
		margin: 0,
	}),
	menuPlacer: (provided, state) => ({
		...provided,
		padding: '8px',
	}),
	option: (provided, state) => {
		let newStyles = {
			...provided,
			padding: '2px 4px',
			minHeight: '16px',
			background: '#AEBAB8',
			borderRadius: '4px',
			margin: '4px 0px',
			textAlign: 'left',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: '10px',
			lineHeight: '12px',
			color: '#45464E',
			width: 'fit-content',
			cursor: 'pointer'
		};
		if (state.value.toLowerCase() === 'watch') {
			newStyles = {
				...newStyles,
				background: 'none',
				color: '#ffffff',
				fontSize: '12px',
				lineHeight: '16px',
				fontWeight: '400',
				paddingLeft: '23px',
				':active': {
					backgroundColor: 'none'
				},
				'::before':  {
					'content': '""',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					width: '12px',
					height: '12px',
					margin: '11px 4px',
					border: '1px solid #00AD66',
				}
			}
		}
		return newStyles;
	},
	multiValueRemove: (provided, state) => ({
		...provided,
		color: '#45464E',
		backgroundColor: '#AEBAB8',
		marginLeft: '-3px',
		borderRadius: '4px',

		'&:hover': {
			backgroundColor: '#AEBAB8',
			color: '#25272F',
			cursor: 'pointer',
		}
	}),
	control: (provided, state) => ({
		...provided,
		borderWidth: 0,
		padding: '8px',
		backgroundColor: '#25272F',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
		borderRadius: '4px',

		'&:hover': {
			borderWidth: 0,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
			borderRadius: '4px',
		}
	}),
	menu: (provided, state) => ({
		...provided,
		marginTop: '-6px',
		marginBottom: '-6px',
		padding: '0 1px 0 8px',
		backgroundColor: '#25272F',
		borderRadius: state.placement === 'top' ? '4px 4px 0 0' : '0 0 4px 4px',
		boxShadow: state.placement === 'top' ? '0px -1px 4px rgba(0, 0, 0, 0.45)' : '0px 1px 4px rgba(0, 0, 0, 0.45)',
		'::before': state.placement === 'bottom' && {
			'content': '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			margin: '0 8px',
			borderTop: '1px solid #989898',
			zIndex: '-1'
		},
		'::after': state.placement === 'top' && {
			'content': '""',
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			margin: '0 8px',
			borderTop: '1px solid #989898',
			zIndex: '-1'
		},
	})
}

const Option = (props) => {
	return (
		<components.Option {...props}>
			<span>{props.children}</span>
			<OptionalTooltip content={props.data.label} style={{
				fontWeight: 400,
				overflow: "hidden",
				maxWidth: 223,
				display: "block",
				height: 16,
				float: "right",
				wordBreak: "break-all",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis"
			}}>
				{props.data.value.toLowerCase() != 'watch' && `: ${props.data.label}`}
			</OptionalTooltip>
		</components.Option>
	);
};

const formatGroupLabel = (data) => {
	if (data.label ) {
		return <div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}}>
			<span style={{
				paddingTop: '5px',
				color: '#ffffff',
				fontWeight: 500,
				fontSize: '12px',
				lineHeight: '14px',
				textTransform: 'none'
			}}>{data.label}</span>
		</div>;
	}
};


export default class TreatmentButton extends PureComponent {
	static propTypes = {
		is_watch_allowed: PropTypes.bool,
		is_watch_enabled: PropTypes.bool,
		procedure_codes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		procedures: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		label: PropTypes.object,
		onProceduresBlur: PropTypes.func.isRequired,
		onProceduresChange: PropTypes.func.isRequired,
	};

	constructor (props, context) {
		super(props, context);

		const { is_watch_enabled, procedure_codes, procedures} = this.props;


		this.state = {
			selectedOption: [],
			changed: false,
		};

		procedure_codes.forEach( code => {
			const fullOption = procedures.find(procedure => procedure.value === code);
			if (fullOption) {
				this.state.selectedOption.push(fullOption)
			} else {
				this.state.selectedOption.push({value: code, label: code})
			}
		});

		if (is_watch_enabled) {
			this.state.selectedOption.unshift({ value: 'Watch', label: 'Watch' });
		}

	}

	getGroupedOptions = () => {
		const { is_watch_allowed, procedures } = this.props;
		const allCodesGroup = [{label: 'All codes',options: procedures}];
		const recommendedCodes = CODE_MAPPING[this.props.label.class_id] || [];
		const fullOptions = procedures.filter(procedure => recommendedCodes.includes(procedure.value));
		allCodesGroup.unshift({label: 'Recommended', options: fullOptions});
		if (is_watch_allowed) {
			allCodesGroup.unshift({label: '', options: [{ value: 'Watch', label: 'Watch' }]});
		}
		return allCodesGroup;
	}

	_handleChange = (selectedOption) => {
		this.setState({ selectedOption, changed: true });
	};
	_handleBlur = () => {
		const watch = this.state.selectedOption.find(x => x.value.toLowerCase() === 'watch');

		if (this.state.changed) {
			this.props.onProceduresChange(
				this.state.selectedOption.filter(x => x.value.toLowerCase() !== 'watch').map(i => i.value),
				Boolean(watch)
			)
		}

		this.props.onProceduresBlur();
	};

	render () {
		const { selectedOption } = this.state;

		return (<div
				onClick={event => event.stopPropagation()}
				style={{
					padding: '8px',
					position: 'absolute',
					zIndex: '9999',
					marginLeft: '-202px',
					marginTop: '-5px',
					width: '277px',
				}}>
				<Select
					value={selectedOption}
					autoFocus
					isMulti
					menuPlacement={'auto'}
					styles={customStyles}
					isSearchable
					placeholder={'Search by treatment or code'}
					onChange={this._handleChange}
					onBlur={this._handleBlur}
					formatGroupLabel={formatGroupLabel}
					isClearable={false}
					dropdownIndicator={false}
					components={{ Option, IndicatorSeparator: null, DropdownIndicator : null}}
					options={this.getGroupedOptions()}
					menuIsOpen={true}
					getOptionLabel={option => `${option.value}`}
					filterOption={createFilter({
						stringify: option => `${option.data.label} ${option.data.value}`,
					})}
				/>
			</div>);
	}
}
