import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDictionary } from '../../../../appUtils/locale';


const i18n = getDictionary('report');

const baseCssClassName = 'resolver-report-bottom';
const linksCssClassName = `${baseCssClassName}__links`;
const linkCssClassName = `${baseCssClassName}__link`;
const infoCssClassName = `${baseCssClassName}__info`;
const infoLeftCssClassName = `${baseCssClassName}__info-left`;
const infoRightCssClassName = `${baseCssClassName}__info-right`;
const logoStartCssClassName = `${baseCssClassName}__logo-start`;
const logoEndCssClassName = `${baseCssClassName}__logo-end`;


export default class ResolverReportBottom extends PureComponent {
	static propTypes = {
		showLinks: PropTypes.bool.isRequired,
		snapshotLink: PropTypes.string.isRequired,
		reportLink: PropTypes.string.isRequired,
	};

	render () {
		return (
			<div className={baseCssClassName}>
				{this.props.showLinks === true && (
					<div className={linksCssClassName}>
						<div className={linkCssClassName}>
							<span>{i18n('report_link')}</span>: <a href={this.props.reportLink}>{this.props.reportLink}</a>
						</div>
					</div>
				)}
				<div className={infoCssClassName}>
					<div className={infoLeftCssClassName}>
						The assessment report is tentative, interpretation by a certified professional is required
					</div>
					<div className={infoRightCssClassName}>
						Prepared using <div className={logoStartCssClassName}>Denti</div><div className={logoEndCssClassName}>.AI</div>
					</div>
				</div>
			</div>
		);
	}
}
