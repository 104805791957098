import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageShapePolygon from '../ImageShapes/shapes/polygon';

import './styles/DrawPolygon.css';

const baseCssClassName = 'draw-polygon';
const svgCssClassName = `${baseCssClassName}__svg`;


class DrawPolygon extends Component {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		imageOriginalWidth: PropTypes.number.isRequired,
		imageOriginalHeight: PropTypes.number.isRequired,

		onStart: PropTypes.func.isRequired,
		onFinish: PropTypes.func.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._el = null;

		this.state = {
			mode: 'draw',
			points: [],
		};
	}

	componentWillUnmount () {
		window.document.removeEventListener('keydown', this._handleKeyDown, false);
	}

	_getDiff () {
		const {
			zoom,
		} = this.props;

		return (
			zoom === 1
				? 1
				: ( 1 / zoom )
		);
	}

	_handleRef = (el) => {
		this._el = el;
	}

	_handleMouseDown = (event) => {
		this.props.onStart();
		const diff = this._getDiff();

		const el = this._el.getBoundingClientRect();

		const x = ( event.clientX - el.left ) * diff;
		const y = ( event.clientY - el.top ) * diff;

		if ( x < 0 || y < 0 || x > this.props.imageOriginalWidth || y > this.props.imageOriginalHeight ) {
			return;
		}

		this.setState((prevState) => ({
			points: [
				...prevState.points,
				[ x, y ],
			],
		}));

		if ( this.state.points.length === 0 ) {
			window.document.addEventListener('keydown', this._handleKeyDown, false);
		}
	}

	_handleKeyDown = (event) => {
		if ( event.key.toLowerCase() === 'enter' ) {
			this.props.onFinish({
				type: 'poly',
				points: this.state.points,
			});

			window.document.removeEventListener('keydown', this._handleKeyDown, false);
		}
	}

	_renderShape () {
		const {
			zoom,
			labelShape,
		} = this.props;

		if ( this.state.points.length === 0 ) {
			return null;
		}

		let color = '#fff';
		let borderStyle = 'solid';

		if ( labelShape ) {
			color = (labelShape.color || color);
			borderStyle = (labelShape.border_style || borderStyle);
		}

		const props = {
			shape: {
				type: 'poly',
				points: this.state.points,
			},
			color: color,
			borderStyle: borderStyle,
			showControls: true,
			isHighlighted: false,
			allowEditing: false,
			zoom,
		};

		return (
			<ImageShapePolygon {...props} />
		);
	}

	render () {
		return (
			<div
				className={baseCssClassName}
				onMouseDown={this._handleMouseDown}
				ref={this._handleRef}
			>
				<svg xmlns={'http://www.w3.org/2000/svg'} className={svgCssClassName}>
					{this._renderShape()}
				</svg>
			</div>
		);
	}
}

export default DrawPolygon;
