import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Float, { FloatAxis, FloatPosition } from '../../../../components/Float';
import ResolverFindingViewer from './ResolverFindingViewerConnector';


const baseCssClassName = 'resolver-shape-viewer';


export default class ResolverShapeViewer extends PureComponent {
	static propTypes = {
		component: PropTypes.func.isRequired,
		componentProps: PropTypes.object.isRequired,
		label: PropTypes.object.isRequired,
		viewport: PropTypes.object,
		showOnlyComponent: PropTypes.bool,
	};

	static defaultProps = {
		showOnlyComponent: false,
		viewport: window.document.body,
	};

	state = {
		axis: FloatAxis.horizontal,
		pos: FloatPosition.start,
	};

	_componentEl = null;

	componentDidMount () {
		this._viewportLocation = this.props.viewport.getBoundingClientRect();
		if ( this.props.viewport === window.document.body ) {
			// Preventing the header overlap.
			this._viewportLocation = {
				...this._viewportLocation,
				top: this._viewportLocation.top + 76,
				left: this._viewportLocation.left,
				right: this._viewportLocation.right,
				bottom: this._viewportLocation.bottom,
				width: this._viewportLocation.width,
				height: this._viewportLocation.height,
			};
		}
	}

	_handleReady = (position) => {
		this.setState({
			axis: position.mainAxis,
			pos: position.mainAxisPosition,
		});
	};

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleComponentRef = (element) => {
		this._componentEl = element;
		this.forceUpdate();
	};

	render () {
		const Component = this.props.component;

		return (
			<Fragment>
				<Component
					{...this.props.componentProps}
					onGetRef={this._handleComponentRef}
				/>
				{this._componentEl !== null && this.props.showOnlyComponent === false && (
					<Float
						target={this._componentEl}
						initialPosition={{
							mainAxis: FloatAxis.horizontal,
							mainAxisPosition: FloatPosition.start,
							mainAxisOffset: 24,
						}}
						viewportLocation={this._viewportLocation}
						onReady={this._handleReady}
					>
						<div className={`${baseCssClassName} ${baseCssClassName}__m-axis-${this.state.axis}__m-pos-${this.state.pos}`}>
							<ResolverFindingViewer
								label={this.props.label}
							/>
						</div>
					</Float>
				)}
			</Fragment>
		);
	}
}
