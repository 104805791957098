import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getService as getUploadImageService } from '../../../../services/upload-image';

import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import imagesCollectionsSelectors from '../../../../selectors/imagesCollectionsSelectors';

import ResolverUploadImageHoc from '../upload-image-hoc';

import CollectionPage from '../../../../components/Collection/CollectionPage';


class ResolverCollectionPageConnector extends PureComponent {
	static propTypes = {
		collection: PropTypes.object.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	_handleFileChange = (image) => {
		getUploadImageService().uploadImage(image);
	};

	_handleOpenLastImage = ({ collectionHashName, imageHashName }) => {
		this.context.router.history.push(`/collections/${this.props.collection.hashname}/image/${imageHashName}/treatment_plan`);
	};

	render () {
		return (
			<ResolverUploadImageHoc>
				<CollectionPage
					{...this.props}
					onFileChange={this._handleFileChange}
					onOpenLastImage={this._handleOpenLastImage}
				/>
			</ResolverUploadImageHoc>
		);
	}
}

export default connect(
	(state, { history }) => {
		return {
			collection: collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			}),
			lastImage: imagesCollectionsSelectors.selectLastOpenedImageInCollection(state),
		};
	},
	() => ({})
)(ResolverCollectionPageConnector);



