export default {
	'snapshot_link': 'Ссылка на снимок',
	'report_link': 'Ссылка на отчет',

	'normal_appearance.title': 'Присутствующие зубы',

	'findings.title': 'Находки',
	'findings.confirmed': 'подтвержденные',

	'finding.title': 'Находка',
	'tooth.title': 'Зуб',

	'summary.title': 'Резюме',

	'teeth-numbers.title': 'Номера зубов',

	'legend.labels.normal': 'Нормальные',
	'legend.labels.normal-with-findings': 'Нормальные с находками',
	'legend.labels.pathology': 'Патологии',
	'legend.labels.missing': 'Отсутствующие',
	
	'missing_teeth.title': 'Отсутствующие зубы',
	
	'prepared': 'Подготовлено с помощью',
	
	'language.label': 'Язык',
	'show_links.label': 'Показывать ссылки',
	'print_button': 'Печать',

	'comment.placeholder': 'Комментарий',

	'specialist.label': 'Специалист',

	'fields.patient_id.label': 'ID пациента',
	'fields.full_name.label': 'Ф.И.О.',
	'fields.age.label': 'Возраст',
	'fields.gender.label': 'Пол',
	'fields.screening_date.label': 'Дата снимка',
	'fields.screening_date.placeholder': 'Дата',
	'fields.report_name.label': 'Название отчета',
	
	'report_name': 'Анализ ортопантомограммы',
	'report_name.x-ray': 'Анализ внутриротового снимка зубов',

	'footer.comment': 'Результаты анализа предварительные, необходима консультация врача-стоматолога',

	'errors.language': 'Не удалось изменить язык, пожалуйста, попробуйте еще раз',

	'specialists.label.specialist': 'Специалист',
	'specialists.label.assistant': 'Ассистент',
	'specialists.label.hygienist': 'Гигиенист',
};
