import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PopupDialog  from '../PopupDialog';
import Button from '../Button';
import FormCheckbox from '../FormCheckbox';
import FormLabel from '../FormLabel';


function PathologiesFindingConfirm (props) {
	const [ dontAsk, setDontAsk ] = useState(false);
	return (
		<PopupDialog
			headerProps={{
				title: 'Please confirm',
			}}
			content={(
				<>
					<p>Please confirm that you have evaluated the image without assistance before enabling AI findings.</p>
					<FormLabel
						element={(
							<FormCheckbox
								inputProps={{
									checked: dontAsk,
									onChange: (event) => setDontAsk(event.currentTarget.checked),
								}}
							/>
						)}
						text={'Don’t ask me again.'}
						textPosition={FormLabel.textPosition.RIGHT}
						disableSpacing
					/>
				</>
			)}
			footerProps={{
				buttons: [
					(
						<Button
							key={'cancel'}
							onClick={() => props.onCancel(dontAsk)}
							theme={Button.AVAILABLE_THEMES.SECONDARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							title={'Cancel'}
						>Cancel</Button>
					),
					(
						<Button
							key={'Confirm'}
							onClick={() => props.onConfirm(dontAsk)}
							theme={Button.AVAILABLE_THEMES.PRIMARY}
							size={Button.AVAILABLE_SIZES.LARGE}
							title={'Confirm'}
						>Confirm</Button>
					),
				],
			}}
		/>
	);
}

PathologiesFindingConfirm.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

export default PathologiesFindingConfirm;
