import axios from 'axios';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';
import commonUtils from '../appUtils/common';
import AccessDeniedApiError from './errors/AccessDeniedApiError';
import ApiError from './errors/ApiError';
import authApi from '../api/authApi';


let instance = null;

export default {
	_generalGetParams: null,

	init: function () {
		if ( !instance ) {
			instance = axios.create({
				baseURL: getRuntimeConfig().api.urls.base,
				timeout: 300000,
				responseType: 'json',
				headers: {
					'Content-Type': 'application/json',
				},
				validateStatus: function (status) {
					return ( status >= 200 && status < 300 ) || status === 401 || status === 403;
				},
			});
		}
	},

	request: function (method, url, ...params) {
		this.init();

		if ( this._generalGetParams !== null && Object.keys(this._generalGetParams).length > 0 ) {
			const params = Object.keys(this._generalGetParams).reduce((result, name) => {
				result.push(`${name}=${this._generalGetParams[name]}`);
				return result;
			}, []);

			url += '?' + params.join('&');
		}

		return instance[method](url, ...params)
			.then((result) => {
				const {
					data,
					status,
				} = result;

				// Invalid token
				if ( status === 401 ) {
					authApi.removeToken();
					commonUtils.goToLoginPage();
					return null;
				}

				if (status === 403) {
					throw new AccessDeniedApiError(data.log);
        		}

				if ( data.success === false ) {
					throw new ApiError(typeof data.error === 'object'
						? `API error: code: ${data.error.code}; text: ${data.error.text}`
						: 'Unknown API error');
				}
				
				return ( typeof data === 'object' && data.data
					? data.data
					: data
				);
			});
	},
	
	fullRequest: function (method, ...params) {
		this.init();

		return instance[method](...params)
			.then((result) => {
				const {
					data,
					status,
				} = result;
				
				// Invalid token
				if ( status === 401 ) {
					authApi.removeToken();
					commonUtils.goToLoginPage();
					return null;
				}
				
				if (status === 403) {
					throw new AccessDeniedApiError(data.log);
				}
				
				if ( data.success === false ) {
					throw new ApiError(typeof data.error === 'object'
						? `API error: code: ${data.error.code}; text: ${data.error.text}`
						: 'Unknown API error');
				}
				
				return data;
			});
	},

	get: function (url, config = {}) {
		return this.request('get', url, config);
	},

	head: function (url, config = {}) {
		return this.request('head', url, config);
	},

	options: function (url, config = {}) {
		return this.request('get', url, config);
	},

	delete: function (url, config = {}) {
		return this.request('delete', url, config);
	},

	post: function (url, data = null, config = {}) {
		return this.request('post', url, data, config);
	},

	put: function (url, data = null, config = {}) {
		return this.request('put', url, data, config);
	},

	patch: function (url, data = null, config = {}) {
		return this.request('patch', url, data, config);
	},
	setAuthToken: function (token) {
		this.init();

		instance.defaults.headers.common['Authorization'] = `Token ${token}`;
	},

	/**
	 * @param {Object<string, string>} params
	 */
	setGeneralGetParams: function (params) {
		this._generalGetParams = params;
	},
}
