import apiActionTypes from '../../../actions/apiActionTypes';
import labelsActionTypes  from '../actions/labelsActionTypes';


/**
 * @typedef {Object<string,Label>} LabelsStoreState
 */

/**
 * @param {LabelsStoreState} state
 * @param {ReduxAction} action
 * @return {LabelsStoreState}
 */
export default function labelsReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return (action.payload.labels || state);

		case labelsActionTypes.ACTION_LABELS__ADD_LABEL: {
			return {
				...state,
				[action.payload.labelId]: action.payload.data,
			}
		}

		case labelsActionTypes.ACTION_LABELS__REMOVE_LABEL: {
			const nextState = {
				...state,
			};

			delete nextState[action.payload];

			return nextState;
		}

		case labelsActionTypes.ACTION_LABELS__UPDATE_DATA: {
			const id = action.payload.labelId;
			return {
				...state,
				[id]: {
					...state[id],
					...action.payload.data,
				},
			};
		}

		case labelsActionTypes.ACTION_LABELS__SET_CONFIRMED: {
			const id = action.payload.labelId;
			const nextLabel = {
				...state[id],
			};

			if ( !nextLabel.meta ) {
				nextLabel.meta = {};
			}

			nextLabel.meta.confirmed = action.payload.value;

			return {
				...state,
				[id]: nextLabel,
			};
		}

		default:
			return state;
	}
}
