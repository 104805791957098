import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';


class TfvParamsChecker extends Component {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		image: PropTypes.object,
		onSelectToothKey: PropTypes.func.isRequired,
	};

	render () {
		if ( typeof this.props.toothKey !== 'string' || /^\d+$/.test(this.props.toothKey) === false ) {
			return (
				<div>Wrong tooth</div>
			);
		}

		return typeof this.props.image !== 'undefined'
			? this.props.children({ image: this.props.image, toothKey: this.props.toothKey })
			: <div>Wrong visit</div>
	}
}

export default withRouter(
	connect(
		(state, props) => {
			const images = currentCollectionSelectors.selectExaminationImages(state, { examination: props.match.params.visit });
			images.sort((a, b) => b.last_opened_at - a.last_opened_at);

			return {
				image: images[0],
				toothKey: props.match.params.tooth,
			};
		},
		() => ({}),
	)(TfvParamsChecker)
);
