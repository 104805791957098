import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonUtils from '../../appUtils/common';
import imageUtils from '../../appUtils/imageUtils';

import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__XRAY,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
} from '../../constants/imageConstants';

import imagesActions from '../../actions/imagesActions';
import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';

import userSelectors from '../../selectors/userSelectors';

import { getDictionary } from '../../appUtils/locale';

import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';

import ReportPAN from './ReportPAN';
import ReportXRAY from './ReportXRAY';


const i18nShared = getDictionary('shared');


class Report extends Component {
	static propTypes = {
		currentImageId: PropTypes.string,
		currentCollectionId: PropTypes.string,
		isAuthenticated: PropTypes.bool,
		isShared: PropTypes.bool,
		user: PropTypes.object,
		imageType: PropTypes.string,
	}

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._imagePrepared = false;
		this._store = context.store;
		this._router = context.router;
		this._arrowUpImage = null;

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount () {
		this._checkImage(this.props);
	}

	componentWillUnmount () {
		this.state = null;
	}

	_checkImage (props) {
		if ( props.currentImageId && !props.currentImage ) {
			props.onGoToLandingPage();
			return;
		}

		if ( !this._imagePrepared ) {
			this._imagePrepared = true;

			const image = imagesSelectors.selectImageByHashNameInCollection(this._store.getState(), {
				collectionHashName: props.collectionHashName,
				imageHashName: props.imageHashName,
			});

			if ( image ) {
				this._store.dispatch(imagesActions.loadImage({
					collectionHashName: this.props.collectionHashName,
					imageHashName: this.props.imageHashName,
				}))
					.then((result) => {
						if ( !this.state ) {
							return;
						}

						this._store.dispatch(editorActions.setCurrentImage({
							id: result.id,
						}));

						this.setState({
							isLoaded: true,
						});
					})
					.catch((error) => {
						console.error(error);
					});
			}
			else {
				this._store.dispatch(imagesActions.loadSharedImage({
					hashName: props.imageHashName,
				}))
					.then(({ image, collection }) => {
						if ( !this.state ) {
							return;
						}

						if ( collection.col_hash && collection.img_hash ) {
							this._router.history.push(`/collections/${collection.col_hash}/image/${collection.img_hash}/report`);
							window.location.reload();
							return;
						}

						this.setState({
							isLoaded: true,
						});
					})
					.catch((error) => {
						props.onGoToLandingPage();
					});
			}
		}
	}

	render () {
		let content = null;
		if ( !this.state.isLoaded ) {
			content = (
				<div>
					{i18nShared('messages.wait')}
				</div>
			);
		}
		else {
			switch (this.props.imageType) {
				case IMAGE_TYPE__PAN:
					content = (<ReportPAN
						imageId={this.props.currentImageId}
						collectionId={this.props.currentCollectionId}
						isShared={this.props.isShared}
						sharedHashname={this.props.imageHashName}
					/>);
					break;
				case IMAGE_TYPE__XRAY:
				case IMAGE_TYPE__BITEWING:
				case IMAGE_TYPE__PERIAPICAL:
					content = (<ReportXRAY
						reportType={this.props.imageType}
						imageId={this.props.currentImageId}
						collectionId={this.props.currentCollectionId}
						isShared={this.props.isShared}
						sharedHashname={this.props.imageHashName}
					/>);
					break;
				default:
					break;
			}
		}

		return (
			<Fragment>
				{content}
				<style
					type={'text/css'}
					dangerouslySetInnerHTML={{
						__html: `
							body {
								background-color: #fff !important;
							}
						`
					}}
				/>
			</Fragment>
		);
	}
}

export default connect((state) => {
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;

	const currentImage = currentImageId ? imagesSelectors.selectImageById(state, {
		id: currentImageId,
	}) : null;

	let imageType = null;

	if ( currentImage ) {
		imageType = imageUtils.getGeneralType(imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}));
	}

	return {
		currentImageId,
		currentCollectionId,
		isAuthenticated: userSelectors.selectIsAuthenticated(state),
		user: userSelectors.selectUserData(state),
		imageType,
	};
}, (dispatch) => ({
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
}))(Report);
