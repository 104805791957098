export default {
	'drawing_mode.on': '允许绘制功能',
	'drawing_mode.off': '关闭绘制功能',
	'zoom_in': '放大',
	'zoom_out': '缩小',
	'default_zoom': '默认缩放',
	'brightness': '亮度',
	'contrast': '对比度',
	'flip.horizontal': '左右翻转',
	'flip.vertical': '上下翻转',
};
