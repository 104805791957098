import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	LABEL_VALIDATION_ERROR__BOX_REQUIRED,
	LABEL_VALIDATION_ERROR__DATE_REQUIRED,
	LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS,
	LABEL_VALIDATION_ERROR__PARAMS_REQUIRED,
	LABEL_VALIDATION_ERROR__SURFACE_REQUIRED,
	LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH,
} from '../../../labels/constants';

import { USER_PERMISSION__SKIP_CONFIRMATION_POPUP } from '../../../../constants/userPermissionsConstants';

import mainConfig from '../../../../configs/mainConfig';

import { trackEvent } from '../../../../integrations/mixpanel';
import aclService from '../../../../services/acl';

import { checkFindingsFilter, getFindingCategory, isExtraFinding } from '../../utils/findings-filter';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';

import editorActions from '../../../../actions/editorActions';
import labelsActions from '../../../labels/actions/labelsActions';

import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../../label-tags/selectors/labelsTagsSelectors';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';

import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';

import PopupDialog, { PopupDialogContentText, PopupDialogContentWithoutPadding } from '../../../../components/PopupDialog';
import Button from '../../../../components/Button';
import ResolverUndo from '../controller/ResolverUndo';
import ResolverRedo from '../controller/ResolverRedo';
import ResolverFindings from './AutoChartResolverFindingsConnector';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormLabel from '../../../../components/FormLabel';

import './styles/ResolverAutoChart.css';
import Loading from "../../../../components/Loading";


const baseCssClassName = 'resolver-auto-chart';
const findingsCssClassName = `${baseCssClassName}__findings`;
const findingsItemsCssClassName = `${baseCssClassName}__findings-items`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonsPrimaryCssClassName = `${baseCssClassName}__buttons-primary`;
const buttonsSecondaryCssClassName = `${baseCssClassName}__buttons-secondary`;
const buttonCssClassName = `${baseCssClassName}__button`;
const loaderCssClassName = `${baseCssClassName}__loader`;

class ResolverAutoChart extends PureComponent {
	static propTypes = {
		categories: PropTypes.array.isRequired,
		labels: PropTypes.array.isRequired,
		teeth: PropTypes.array.isRequired,
		teethLabelMap: PropTypes.object.isRequired,
		findingsFilter: PropTypes.object.isRequired,
		isEnabledExportButton: PropTypes.bool.isRequired,
		isAutoChartShortFilter: PropTypes.bool.isRequired,
		hasUnsavedChanges: PropTypes.bool.isRequired,
		isSavingChanges: PropTypes.bool.isRequired,
		skipConfirmation: PropTypes.bool.isRequired,
		onConfirm: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		onSetShortFilter: PropTypes.func.isRequired,
		onSaveData: PropTypes.func.isRequired,
	};

	state = {
		selectedLabel: null,
		isDelayedConfirm: false,
	};

	componentDidMount () {
		if ( this.props.skipConfirmation ) {
			this.props.onConfirm({ isAutoChartShortFilter: false });
		}
	}

	componentDidUpdate(prevProps) {
		if ( this.state.isDelayedConfirm === true && prevProps.hasUnsavedChanges === true && this.props.hasUnsavedChanges === false ) {
			this.props.onConfirm();
		}
	}

	_handleSelectedLabel = ({ label }) => {
		this.setState({
			selectedLabel: label,
		});
	};

	_handleChange = () => {
		trackEvent('Auto-Chart Short Filter Set', { value: !this.props.isAutoChartShortFilter });
		this.props.onSetShortFilter(!this.props.isAutoChartShortFilter);
	};

	_handleConfirmClick = () => {
		if ( this.props.isSavingChanges === true ) {
			this.setState({isDelayedConfirm: true});
			return;
		}

		if ( this.props.hasUnsavedChanges === true ) {
			this.setState({isDelayedConfirm: true});
			this.props.onSaveData();
			return;
		}

		this.props.onConfirm();
	};

	_renderContent ({ popup: { close } }) {
		return (
			<div className={baseCssClassName}>
				<div className={findingsCssClassName}>
					<div className={findingsItemsCssClassName}>
						<ResolverFindings
							selectedLabel={this.state.selectedLabel}
							categories={this.props.categories}
							labels={this.props.labels}
							teeth={this.props.teeth}
							dataType={this.props.isAutoChartShortFilter === true ? 'rows' : 'categories'}
							teethLabelMap={this.props.teethLabelMap}
							findingsFilter={this.props.findingsFilter}
							onSelectLabel={this._handleSelectedLabel}
						/>
					</div>
				</div>
				<div style={{ padding: '10px 0' }}>
					<FormLabel
						element={(
							<FormCheckbox
								inputProps={{
									checked: this.props.isAutoChartShortFilter,
									onChange: this._handleChange,
								}}
							/>
						)}
						text={'Chart only past restorative treatment, “watch” and treatment plan'}
						textPosition={FormLabel.textPosition.RIGHT}
						disableSpacing
					/>
				</div>
				<div className={buttonsCssClassName}>
					<div className={buttonsPrimaryCssClassName}>
						<div className={buttonCssClassName}>
							<ResolverUndo />
						</div>
						<div className={buttonCssClassName}>
							<ResolverRedo />
						</div>
					</div>
					<div className={buttonsSecondaryCssClassName}>
						<div className={buttonCssClassName}>
							<Button
								size={Button.AVAILABLE_SIZES.MEDIUM}
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								title={'Cancel'}
								onClick={close}
							>Cancel</Button>
						</div>
						<div className={buttonCssClassName}>
							<Button
								size={Button.AVAILABLE_SIZES.MEDIUM}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								title={this.props.isEnabledExportButton === true
									? 'Confirm'
									: 'You must complete all required fields (dates of service, surfaces, materials, etc.) before using this function'
								}
								disabled={this.props.isEnabledExportButton === false}
								onClick={this._handleConfirmClick}
							>
								{this.state.isDelayedConfirm && <Loading className={loaderCssClassName} height={16} width={16}/>}
								Confirm
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render () {
		return (
			<PopupDialog
				headerProps={{
					title: 'Auto-Chart Preview',
				}}
				content={(dialogInterface) => (
					<PopupDialogContentWithoutPadding>
						<PopupDialogContentText>
							{this._renderContent(dialogInterface)}
						</PopupDialogContentText>
					</PopupDialogContentWithoutPadding>
				)}
				popupProps={{
					onClose: this.props.onClose,
				}}
			/>
		);
	}
}

export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const currentImageId = editorData.currentImageId;

	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;

	const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
	const labelParentMap = {};
	const isAutoChartShortFilter = editorData.isAutoChartShortFilter;

	const categories = isAutoChartShortFilter === false ? [
		{
			title: 'Visit Level Pathologies',
			allowExpand: false,
			rows: [],
		},
		{
			title: 'Visit Level Pre-Existing',
			allowExpand: false,
			rows: [],
		},
		{
			title: 'Visit Level Existing',
			allowExpand: false,
			rows: [],
		},
	] : null;

	Object.keys(labelChildren)
		.forEach((labelId) => {
			labelChildren[labelId].forEach((childLabelId) => {
				labelParentMap[childLabelId] = labelId;
			});
		});

	let labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.map((labelId) => {
			const label = {
				...labelsSelectors.selectLabelById(state, {
					labelId,
				}),
			};

			label.category = getFindingCategory({ label });
			const isToothLabel = labelsUtils.labelIsTooth(label);

			if ( isAutoChartShortFilter ) {
				if ( !isToothLabel &&
					!checkFindingsFilter({
						findingsFilter: {
							pathological: false,
							treatment: true,
							existing: false,
						},
						label,
						showBoneLossStages: false,
						allowBoneLossLines: mainConfig.BONE_LOSS_LINES,
					}) &&
					!labelGetters.getLabelIsWatch(label) &&
					(labelGetters.getLabelProcedureCodes(label) || []).length === 0
				) {
					return false;
				}
			}
			else {
				if ( !isToothLabel && (
					!checkFindingsFilter({
						findingsFilter: {
							pathological: true,
							treatment: true,
							existing: true,
						},
						label,
						showBoneLossStages: false,
						allowBoneLossLines: mainConfig.BONE_LOSS_LINES,
					}) ||
					isExtraFinding({ label })
				) ) {
					return false;
				}
			}

			if ( isToothLabel ) {
				const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
				if ( tags.length > 0 ) {
					label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
					label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
				}

				const conflicts = labelGetters.getLabelConflicts(label);

				if (
					!conflicts.includes(LABEL_VALIDATION_ERROR__BOX_REQUIRED) &&
					!conflicts.includes(LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS)
				) {
					return false;
				}
			}
			else {
				const parentLabelId = labelParentMap[labelId];

				label.parentLabelId = parentLabelId;

				if ( parentLabelId ) {
					const parentLabel = labelsSelectors.selectLabelById(state, {
						labelId: parentLabelId,
					});

					if ( labelsUtils.labelIsTooth(parentLabel) ) {
						const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: parentLabelId });
						if ( tags.length > 0 ) {
							label.toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
							label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: label.toothKey, notationType });
						}
					}
				}
			}

			return label;
		}).filter(Boolean)

	labels.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

	const teethLabelMap = {};

	const teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.reduce((result, labelId) => {
			const label = {
				...labelsSelectors.selectLabelById(state, {
					labelId,
				}),
			};

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
				if ( tags.length > 0 ) {
					const toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));

					let isMissingTooth = false;

					if ( labelChildren[labelId] ) {
						labelChildren[labelId].forEach((childLabelId) => {
							const childLabel = labelsSelectors.selectLabelById(state, {
								labelId: childLabelId,
							});

							if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
								isMissingTooth = true;
							}
						});
					}

					result.push({
						toothKey,
						localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
						isMissing: isMissingTooth,
					});
					teethLabelMap[toothKey] = labelId;
				}
			}

			return result;
		}, []);

	teeth.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

	let hasConflicts = false;

	labels = labels.filter((label) => {
		const classId = labelGetters.getLabelClassId(label);
		const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);

		if (
			labelsUtils.getLabelAvailabilityOptions(classId).is_show_on_export === false ||
			(
				!labelsUtils.labelIsTooth(label) && (
					typeof measureOfConfidence === 'number' &&
					editorData.filteredConfidencePercent > measureOfConfidence
				)
			)
		) {
			return false;
		}

		const conflicts = labelGetters.getLabelConflicts(label);

		if ( conflicts.length > 0 ) {
			if (
				conflicts.includes(LABEL_VALIDATION_ERROR__BOX_REQUIRED) ||
				conflicts.includes(LABEL_VALIDATION_ERROR__DATE_REQUIRED) ||
				conflicts.includes(LABEL_VALIDATION_ERROR__SURFACE_REQUIRED) ||
				conflicts.includes(LABEL_VALIDATION_ERROR__PARAMS_REQUIRED) ||
				conflicts.includes(LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH) ||
				conflicts.includes(LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS)
			) {
				hasConflicts = true;
			}
		}

		if ( isAutoChartShortFilter === true ) {
			return true;
		}

		if ( label.category === 'pathological' ) {
			categories[0].rows.push(label);
		}
		else if ( label.category === 'treatment' ) {
			categories[1].rows.push(label);
		}

		return true;
	});

	return {
		isAutoChartShortFilter,
		isEnabledExportButton:
			hasConflicts === false && (
				labels.length > 0 ||
				( Array.isArray(categories) && categories.some((category) => category.rows.length > 0) )
			),
		labels,
		teeth,
		teethLabelMap,
		categories,
		skipConfirmation: aclService.checkPermission(USER_PERMISSION__SKIP_CONFIRMATION_POPUP),
		hasUnsavedChanges: editorData.hasUnsavedChanges,
		isSavingChanges: editorData.isSavingChanges,
	};
}, (dispatch) => ({
	onSetShortFilter: (value) => dispatch(editorActions.updateData({
		data: {
			isAutoChartShortFilter: value,
		},
	})),
	onSaveData: () => dispatch(labelsActions.saveLabels()),
}))(ResolverAutoChart);

