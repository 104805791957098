import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import editorActions from '../../../../actions/editorActions';

import ResolverEntry from '../entry';


function FrontController (props) {
	useEffect(() => {
		props.onSetFmxMode(true);
		return () => {
			props.onSetFmxMode(false);
		};
	}, []);
	return (
		<ResolverEntry defaultFmxModeEnabled />
	);
}

FrontController.propTypes = {
	onSetFmxMode: PropTypes.func.isRequired,
};

export default connect(() => {},
	(dispatch) => ({
		onSetFmxMode: (value) => dispatch(editorActions.updateData({
			data: {
				isFmxModeEnabled: value,
				zoom: 1,
			},
		})),
	})
)(FrontController);
