import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { init as initHelp, get as getHelp } from '../../services/help';

import commonUtils from '../../appUtils/common';
import { getDictionary } from '../../appUtils/locale';

import userSelectors from '../../selectors/userSelectors';
import collectionsSelectors from '../../selectors/collectionsSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import './styles/Messenger.css';


const i18nMessenger = getDictionary('messenger');

const buttonCssClassName = 'messenger__button';


class Messenger extends PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		isAuthenticated: PropTypes.bool.isRequired,
		onGetCollection: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);

		const help = initHelp();
		const options = {
			user: props.user,
			getCollection: this.props.onGetCollection,
		};

		const userApiParams = commonUtils.getUserApiParams();
		if ( Object.keys(userApiParams).length > 0 ) {
			options.extra = userApiParams;
		}

		options.extra = {
			dtr_op: 'ope1',
			dtr_provider: 'prov1',
		};

		if ( help !== null ) {
			help.setup(options);
		}
	}

	componentDidMount () {
		const help = getHelp();

		if ( help !== null ) {
			const options = {
				launcherSelectorCssClassName: `.${buttonCssClassName}`,
			};

			if ( this.props.isAuthenticated === true ) {
				options.user = this.props.user;
			}
			help.update(options);
		}
	}

	_handleClick = () => {
		getHelp().open();
	};

	render () {
		if ( getHelp() === null ) {
			return null;
		}

		return (
			<div
				className={buttonCssClassName}
				title={i18nMessenger('help.title')}
				onClick={this._handleClick}
			>{i18nMessenger('help.text')}</div>
		);
	}
}

export default connect(
	(state) => {
		const isAuthenticated = userSelectors.selectIsAuthenticated(state);
		return {
			isAuthenticated,
			user: isAuthenticated ? userSelectors.selectUserData(state) : null,
			onGetCollection: () =>
				collectionsSelectors.selectCollectionById(state, {
					id: editorSelectors.selectEditor(state).currentCollectionHashName,
				}),
		};
	},
	() => ({}),
)(Messenger);
