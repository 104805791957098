import React from 'react';
import PropTypes from 'prop-types';

import './styles/Avatar.css';


const baseCssClassName = 'avatar';
const imageCssClassName = `${baseCssClassName}__image`;

const propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,

	url: PropTypes.string,
	sign: PropTypes.string,
};

const DEFAULT_BACKGROUND_COLOR = '#4c4d54';


function Avatar (props) {
	const {
		url,
		sign,
		width,
		height,
		backgroundColor,
	} = props;
	
	return (
		<div
			className={baseCssClassName}
			style={{
				width,
				height,
				backgroundColor: ( backgroundColor || DEFAULT_BACKGROUND_COLOR ),
			}}
		>
			{ url &&
				(
					<img
						src={url}
						className={imageCssClassName}
						alt={''}
					/>
				)
			}
			{ !url && sign && 
				(
					<div>{sign}</div>	
				)
			}
		</div>
	)
}

Avatar.propTypes = propTypes;

export default Avatar;
