import React from 'react';

import mainConfig from '../../configs/mainConfig';
import { getDictionary } from '../../appUtils/locale';
import './styles/Toolbar.css';

const baseCssClassName = 'toolbar';
const itemCssClassName = `${baseCssClassName}__item`;
const iconCssClassName = `${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL}`;
const i18n = getDictionary('toolbar');

const FlipVertical = ({ action, color='dark' }) => {
	const className = `${itemCssClassName} ${itemCssClassName}__${color} ${iconCssClassName}`;
	return (
		<div
			key={mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL}
			className={className}
			title={i18n('flip.vertical')}
			onClick={action}
		/>
	);
};

export default FlipVertical;
