import React from 'react';

import PopupDialogConfirm from '../PopupDialogConfirm';

import { getDictionary } from '../../appUtils/locale';

const i18n = getDictionary('unsaved-changes');
const i18nShared = getDictionary('shared');


function PopupDialogUnsavedChanges (props) {
	return (
		<PopupDialogConfirm
			content={i18n('dialog.confirm')}
			title={i18n('dialog.title')}

			confirmButtonText={i18nShared('buttons.continue')}
			cancelButtonText={i18nShared('buttons.cancel')}

			onConfirm={props.onConfirm}
			onCancel={props.onCancel}
		/>
	);
}

export default PopupDialogUnsavedChanges;
