import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesHistorySelectors from '../../selectors/imagesHistorySelectors';

import Button from '../Button';

import { getDictionary } from '../../appUtils/locale';

import './styles/HistoryStateButton.css';


const i18n = getDictionary('canvas-controls');

const baseCssClassName = 'history-state-button';
const iconCssClassName = `${baseCssClassName}__icon-undo`;


class PrevHistoryStateButton extends Component {
	static propTypes = {
		onSetPrevImageHistoryState: PropTypes.func.isRequired,
	}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	_handleClick = () => {
		this.props.onSetPrevImageHistoryState();
	}

	render () {
		return (
			<Button
				size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.LARGE}
				disabled={!this.props.isAvailable}
				theme={Button.AVAILABLE_THEMES.SECONDARY}
				onClick={this._handleClick}
				title={i18n('buttons.prev_history.alt')}
			><span className={iconCssClassName}>{i18n('buttons.prev_history.text')}</span></Button>
		);
	}
}

export default connect((state) => {
	const editorState = editorSelectors.selectEditor(state);
	const currentImageId = editorState.currentImageId;
	const currentImageHistoryIndex = editorState.currentImageHistoryIndex;
	const imageHistoryLength = ( imagesHistorySelectors.selectImageHistoryByImageId(state, {
		imageId: currentImageId,
	}) || [] ).length;

	return {
		isAvailable: (
			imageHistoryLength > 0 &&
			currentImageHistoryIndex !== 0
		),
	};
}, (dispatch) => ({
	onSetPrevImageHistoryState: () => dispatch(editorActions.setPrevImageHistoryState()),
}))(PrevHistoryStateButton);
