import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/HuddleInput.css';


const baseCssClassName = 'ui-huddle-input';
const contentCssClassName = `${baseCssClassName}__content`;
const arrowCssClassName = `${baseCssClassName}__arrow`;
const itemCssClassName = `${baseCssClassName}__item`;
const txCodeCssClassName = `${baseCssClassName}__tx-code`;


export const HuddleInputArrow = () => {
	return (
		<div className={arrowCssClassName} />
	);
};

export const HuddleInputContent = (props) => {
	return (
		<div className={contentCssClassName}>
			{props.children}
		</div>
	);
};

HuddleInputContent.propTypes = {
	children: PropTypes.node.isRequired,
};

export const HuddleInputTxCode = (props) => {
	return (
		<div className={txCodeCssClassName}>
			{props.code}
		</div>
	);
};

HuddleInputTxCode.propTypes = {
	code: PropTypes.node.isRequired,
};

const HuddleInput = (props) => {
	return (
		<div
			className={classnames([
				baseCssClassName,
				props.active && `${baseCssClassName}__m-active`,
			])}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	);
};

HuddleInput.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
};

export const HuddleInputItem = (props) => {
	return (
		<div
			className={classnames([
				itemCssClassName,
				props.selected && `${itemCssClassName}__m-selected`,
				props.disabled && `${itemCssClassName}__m-disabled`,
			])}
		>
			{props.children}
		</div>
	);
};

HuddleInputItem.propTypes = {
	selected: PropTypes.bool,
	disabled: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default HuddleInput;
