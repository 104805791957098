export default {
	'hint.first': 'Upload image.',
	'hint.second': 'Supported file formats',

	'buttons.back': 'Back',
	'buttons.open_registry': 'Open registry',
	'buttons.open_stats': 'Open stats',
	'buttons.save_changes': 'Save changes',
	'buttons.share_image': 'Share image',
	'buttons.collections': 'Go to collections',

	'dialog.uploading_image_error.title': 'Image uploading error',
	'dialog.uploading_image_error.text': `
		The server has returned an error. Please, try again.<br/>

		If an error persists, please, send an email to <a href={'support@denti.ai'}>support@denti.ai</a> and attach the image that is not processed correctly
	`,
};
