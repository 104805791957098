import React from 'react';
import { connect } from 'react-redux';
import ToothSelect from './ToothSelect';

import teethUtils from '../../appUtils/teeth/teethUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import userSelectors from '../../selectors/userSelectors';

import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';
import editorActions from '../../actions/editorActions';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import labelsActions from '../../modules/labels/actions/labelsActions';
import labelTagsAction from "../../modules/label-tags/actions/labelTagsAction";


const MoveLabelSelector = ({
	label,
	parentLabel,
	classes,
	isEditMode,
	toothKey,
	availableTeeth,
	onGetNewParentLabel,
	onMoveLabel,
}) => {
	if ( !isEditMode ) {
		return null;
	}

	return (
		<ToothSelect
			items={availableTeeth}
			value={toothKey}
			disabled={availableTeeth.length === 0}
			onChange={(newToothKey) => onMoveLabel({
				labelId: labelGetters.getLabelId(label),
				parentLabelId: parentLabel && labelGetters.getLabelId(parentLabel),
				newParentLabelId: labelGetters.getLabelId(onGetNewParentLabel(newToothKey)),
				newToothKey,
			})}
		/>
	);
};


export default connect((state) => {
	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	const availableTeeth = [];

	const toothKeyLabelMap = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
				if ( tags && tags.length > 0 ) {
					result[labelTagGetter.getTagKey(tags[0])] = label;
				}
			}

			return result;
		}, {});

	teethUtils.getTeeth().forEach((_toothKey) => {
		if ( !toothKeyLabelMap[_toothKey] ) {
			return;
		}

		availableTeeth.push({
			value: _toothKey,
			label: teethUtils.getLocalizedToothKey({
				toothKey: _toothKey,
				notationType,
			}),
		});
	});

	return {
		availableTeeth,
		onGetNewParentLabel: (newToothKey) => {
			return toothKeyLabelMap[newToothKey];
		},
	};
}, (dispatch) => ({
	onMoveLabel: (data) => {
		dispatch(labelsActions.moveLabel(data));
		dispatch(editorActions.updateData({
			data: {
				selectedToothKey: data.newToothKey,
			},
		}));
	},
}))(MoveLabelSelector);
