import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import collectionsActions from '../../../../actions/collectionsActions';
import editorActions from '../../../../actions/editorActions';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import Loading from '../../../../components/Loading';


const SYNCING_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	DONE: 'done',
	FAILED: 'failed',
};


class ResolverCollectionHoc extends Component {
	static propTypes = {
		collectionIdFromParams: PropTypes.string,
		
		onIsSyncedCollection: PropTypes.func.isRequired,
		onGetCollection: PropTypes.func.isRequired,
		onSetCurrentCollection: PropTypes.func.isRequired,
		onSetConfidencePercent: PropTypes.func.isRequired,
		onSetFilters: PropTypes.func.isRequired,
		onMarkNotAnalyzedImages: PropTypes.func.isRequired,
	};
	
	constructor (props, context) {
		super(props, context);
		
		this.state = {
			syncingStatus: SYNCING_STATUS.IDLE,
		};
	}
	
	componentDidMount () {
		this._loadCollection();
	}
	
	componentWillUnmount () {
		this.state = null;
		
		this.props.onSetCurrentCollection({ collection: null });
	}
	
	_loadCollection () {
		const hashName = this.props.collectionIdFromParams;
		
		if ( !hashName ) {
			window.location.href = '/';
			return;
		}
		
		if ( this.props.onIsSyncedCollection(hashName) ) {
			this.setState({
				syncingStatus: SYNCING_STATUS.DONE,
			});
			this.props.onSetCurrentCollection({ collection: hashName });
			return;
		}
		
		this.setState({
			syncingStatus: SYNCING_STATUS.IN_PROGRESS,
		});
		
		this.props.onGetCollection({ hashName, params: { config_format: 'treatment' } })
			.then((collection) => {
				if ( !this.state ) {
					return;
				}

				if ( typeof collection.filter_list === 'object' && collection.filter_list !== null ) {
					// this.props.onSetConfidencePercent(collection.filter_list.threshold || 0)
					this.props.onSetConfidencePercent(0);
				}

				const notAnalyzedImages = collection.images.filter((image) => image.reanalyze_required === true);
				if ( notAnalyzedImages.length > 0 ) {
					this.props.onMarkNotAnalyzedImages(notAnalyzedImages.map((image) => image.id));
				}
				
				this.setState({
					syncingStatus: SYNCING_STATUS.DONE,
				});
			})
			.catch((error) => {
				if ( !this.state ) {
					return;
				}

				// this.setState({
				// 	syncingStatus: SYNCING_STATUS.FAILED,
				// });

				window.location.href = '/';
			});
	}
	
	render () {
		return this.state.syncingStatus === SYNCING_STATUS.DONE
			? this.props.children
			: <Loading />
	}
}

export default withRouter(
	connect(
		(state, props) => {
			return {
				collectionIdFromParams: props.match.params.collectionId,
				onIsSyncedCollection: (id) => collectionsSelectors.selectIsSyncedCollection(state, { id }) ,
			};
		},
		(dispatch) => ({
			onGetCollection: (data) => dispatch(collectionsActions.getCollection(data)),
			onSetCurrentCollection: (data) => dispatch(collectionsActions.setCurrentCollection(data)),
			onSetConfidencePercent: (value) => dispatch(editorActions.updateData({
				data: {
					filteredConfidencePercent: value,
				},
			})),
			onSetFilters: (filters) => dispatch(editorActions.updateData({
				data: {
					treatmentPlanFilters: filters,
				},
			})),
			onMarkNotAnalyzedImages: (images) => dispatch(editorActions.updateData({
				data: {
					notAnalyzedImages: images,
				},
			})),
		}),
	)(ResolverCollectionHoc)
);
