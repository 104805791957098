import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Switch from '../../../../components/Switch';
import { trackEvent } from '../../../../integrations/mixpanel';

import './styles/ResolverSidebarPanel.css';


const baseCssClassName = 'resolver-sidebar-panel';
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonCssClassName = `${baseCssClassName}__button`;
const textCssClassName = `${baseCssClassName}__text`;
const iconCssClassName = `${baseCssClassName}__icon`;
const switchCssClassName = `${baseCssClassName}__switch`;


export default class ResolverSidebarPanel extends PureComponent {
	static propTypes = {
		allowHeight: PropTypes.number,
		shouldShowChartedFindings: PropTypes.bool,
		shouldRenderChartedFindings: PropTypes.bool,
		disabledChartedFindings: PropTypes.bool,
		onToggleChartedFindings: PropTypes.func,

		shouldShowPreExistingFindings: PropTypes.bool,
		shouldRenderPreExistingFindings: PropTypes.bool,
		disabledPreExistingFindings: PropTypes.bool,
		onTogglePreExistingFindings: PropTypes.func,

		shouldHideFindings: PropTypes.bool,
		shouldRenderHideFindings: PropTypes.bool,
		onToggleHideFindings: PropTypes.func,

		areHeatMapsEnabled: PropTypes.bool,
		shouldRenderHeatMaps: PropTypes.bool,
		onSetHeatMaps: PropTypes.func,

		areFindingsMasksEnabled: PropTypes.bool,
		shouldRenderFindingsMasks: PropTypes.bool,
		onSetFindingsMasks: PropTypes.func,

		isActiveMagnifyingGlass: PropTypes.bool,
		shouldRenderMagnifyingGlass: PropTypes.bool,
		disabledMagnifyingGlass: PropTypes.bool,
		onToggleMagnifyingGlass: PropTypes.func,
		
		shouldShowBoneStages: PropTypes.bool,
		shouldRenderBoneStages: PropTypes.bool,
		disabledBoneStages: PropTypes.bool,
		onChaneBoneStages: PropTypes.func,

		shouldRenderShowHideControl: PropTypes.bool,

		isExpanded: PropTypes.bool.isRequired,
		onToggleExpand: PropTypes.func.isRequired,
	};
	
	static defaultProps = {
		disabledChartedFindings: false,
		disabledPreExistingFindings: false,
		disabledBoneStages: false,
		disabledMagnifyingGlass: false,
		shouldRenderBoneStages: true,
		shouldRenderMagnifyingGlass: true,
		shouldRenderHideFindings: true,
		shouldRenderPreExistingFindings: true,
		shouldRenderFindingsMasks: false,
		shouldRenderShowHideControl: true,
	};

	_handleToggleExpand = () => {
		trackEvent('Sidebar Panel Toggle', { value: this.props.isExpanded });
		this.props.onToggleExpand(!this.props.isExpanded);
	};

	_handleToggleMagnifyingGlass = () => {
		if ( this.props.disabledMagnifyingGlass === true ) {
			return;
		}
		trackEvent('Sidebar Panel Magnifying Glass Toggle', { value: !this.props.isActiveMagnifyingGlass });
		this.props.onToggleMagnifyingGlass();
	};

	_handleToggleHeatMaps = () => {
		trackEvent('Sidebar Panel Heat Maps Toggle', { value: !this.props.areHeatMapsEnabled });
		this.props.onSetHeatMaps(!this.props.areHeatMapsEnabled);
	};

	_handleToggleFindingsMasks = () => {
		trackEvent('Sidebar Panel Findings Masks Toggle', { value: !this.props.areFindingsMasksEnabled });
		this.props.onSetFindingsMasks(!this.props.areFindingsMasksEnabled);
	};

	_renderHeatMaps () {
		if ( this.props.shouldRenderHeatMaps === false ) {
			return null;
		}

		return (
			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-no-bottom-padding ${buttonCssClassName}__m-pointer`}
				onClick={this._handleToggleHeatMaps}
			>
				<span
					className={textCssClassName}
					dangerouslySetInnerHTML={{
						__html: this.props.areHeatMapsEnabled ? 'Hide<br />heatmap' : 'Show<br />heatmap'
					}}
				/>
				<br />
				<span className={`${iconCssClassName} ${iconCssClassName}__m-masks`} />
			</div>
		);
	}

	_renderMagnifyingGlass () {
		if ( this.props.shouldRenderMagnifyingGlass === false ) {
			return null;
		}

		return (
			<div
				className={classnames([
					buttonCssClassName,
					`${buttonCssClassName}__m-no-bottom-padding`,
					`${buttonCssClassName}__m-pointer`,
					this.props.disabledMagnifyingGlass === true && `${buttonCssClassName}__m-disabled`,
				])}
				onClick={this._handleToggleMagnifyingGlass}
			>
				<span className={textCssClassName}>Magnify</span>
				<br />
				<span
					className={classnames([
						iconCssClassName,
						`${iconCssClassName}__m-magnifying-glass`,
						this.props.disabledMagnifyingGlass === true && `${iconCssClassName}__m-disabled`,
					])}
				/>
			</div>
		);
	}

	_renderHideFindings () {
		if ( this.props.shouldRenderHideFindings === false ) {
			return null;
		}

		return (
			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-no-bottom-padding ${buttonCssClassName}__m-pointer`}
				onClick={() => {
					trackEvent('Sidebar Panel Hide Findings Toggle', { value: !this.props.shouldHideFindings });
					this.props.onToggleHideFindings();
				}}
			>
				<span className={textCssClassName}>
					{this.props.shouldHideFindings ? 'Show all' : 'Hide all'}
				</span>
				<br />
				<span className={`${iconCssClassName} ${iconCssClassName}__m-hide-findings`} />
			</div>
		);
	}

	_renderPreExistingFindings () {
		if ( this.props.shouldRenderPreExistingFindings === false ) {
			return null;
		}

		return (
			<div className={buttonCssClassName}>
				<span className={textCssClassName}>Past restorative treatment</span>
				<div style={{ paddingTop: 4 }} />
				<div className={switchCssClassName}>
					<Switch
						disabled={this.props.disabledPreExistingFindings === true}
						checked={Boolean(this.props.shouldShowPreExistingFindings)}
						onChange={() => {
							trackEvent('Sidebar Panel Past Restorative Treatment Toggle', { value: !this.props.shouldShowPreExistingFindings });
							this.props.onTogglePreExistingFindings();
						}}
					/>
				</div>
			</div>
		);
	}

	_renderChartedFindings () {
		if ( this.props.shouldRenderChartedFindings === false ) {
			return null;
		}

		return (
			<div className={buttonCssClassName}>
				<span className={textCssClassName}>Existing Chart</span>
				<div style={{ paddingTop: 4 }} />
				<div className={switchCssClassName}>
					<Switch
						disabled={this.props.disabledChartedFindings === true}
						checked={Boolean(this.props.shouldShowChartedFindings)}
						onChange={() => {
							trackEvent('Sidebar Panel Existing Chart Toggle', { value: !this.props.shouldShowChartedFindings });
							this.props.onToggleChartedFindings();
						}}
					/>
				</div>
			</div>
		);
	}

	_renderBoneStages () {
		if ( this.props.shouldRenderBoneStages === false ) {
			return null;
		}

		return (
			<div className={buttonCssClassName}>
				<span className={textCssClassName}>Bone<br />levels</span>
				<div style={{ paddingTop: 4 }} />
				<div className={switchCssClassName}>
					<Switch
						disabled={this.props.disabledBoneStages === true}
						checked={Boolean(this.props.shouldShowBoneStages)}
						onChange={() => {
							trackEvent('Sidebar Panel Bone Levels Toggle', { value: !this.props.shouldShowBoneStages });
							this.props.onChaneBoneStages();
						}}
					/>
				</div>
			</div>
		);
	}

	_renderMasks () {
		if ( this.props.shouldRenderFindingsMasks === false ) {
			return null;
		}

		return (
			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-no-bottom-padding ${buttonCssClassName}__m-pointer`}
				onClick={this._handleToggleFindingsMasks}
			>
				<span className={textCssClassName}>
					{this.props.areFindingsMasksEnabled ?  'Hide masks' : 'Show masks'}
				</span>
				<br />
				<span className={`${iconCssClassName} ${iconCssClassName}__m-masks`} />
			</div>
		);
	}

	_renderShowHide () {
		if ( this.props.shouldRenderShowHideControl === false ) {
			return;
		}

		const content = this.props.isExpanded === true
			? (
				<div>
					<span className={textCssClassName}>Hide chart panel</span>
					<br />
					<span className={`${iconCssClassName} ${iconCssClassName}__m-arrow-right`}/>
				</div>
			)
			: (
				<div>
					<span className={textCssClassName}>Show chart panel</span>
					<br />
					<span className={`${iconCssClassName} ${iconCssClassName}__m-arrow-left`}/>
				</div>
			);

		return (
			<div
				className={`${buttonCssClassName} ${buttonCssClassName}__m-no-bottom-padding ${buttonCssClassName}__m-pointer`}
				onClick={this._handleToggleExpand}
			>
				{content}
			</div>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={buttonsCssClassName}>
					{this._renderMagnifyingGlass()}
					{this._renderHideFindings()}
					{this._renderHeatMaps()}
					{this._renderMasks()}
					{this._renderBoneStages()}
					{this._renderPreExistingFindings()}
					{this._renderChartedFindings()}
					{this._renderShowHide()}
				</div>
			</div>
		);
	}
}
