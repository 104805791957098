import React from 'react';
import PropTypes from 'prop-types';

import './styles/Loading.css';
import classnames from "classnames";


const baseCssClassName = 'loading';
const indicatorCssClassName = `${baseCssClassName}__indicator`;


const Loading = (props) => {
	return (
		<div className={classnames([baseCssClassName, props.className])} style={{ width: props.width, height: props.height }}>
			<div className={indicatorCssClassName} />
		</div>
	);
};

Loading.propTypes = {
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
};

Loading.defaultProps = {
	width: 60,
	height: 60,
};

export default Loading;
