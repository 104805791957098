export const LOADING_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'progress',
	LOADED: 'loaded',
	FAILED: 'failed',
};

export const DRAGGING_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'progress',
};
