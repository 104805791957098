const ACTION_USER__SIGN_IN = 'ACTION_USER__SIGN_IN';
const ACTION_USER__SIGN_OUT = 'ACTION_USER__SIGN_OUT';
const ACTION_USER__SET_DATA = 'ACTION_USER__SET_DATA';
const ACTION_USER__UPDATE_DATA = 'ACTION_USER__UPDATE_DATA';


export default {
	ACTION_USER__SIGN_IN,
	ACTION_USER__SIGN_OUT,
	ACTION_USER__SET_DATA,
	ACTION_USER__UPDATE_DATA,
};
