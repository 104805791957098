import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MODES } from './reportConsts';

import mainConfig from '../../../../configs/mainConfig';

import momentUtils from '../../../../appUtils/momentUtils';
import dateUtils from '../../../../appUtils/dateUtils';

import TextField from '@material-ui/core/TextField';
import PopupDialog from '../../../../components/PopupDialog';
import Datepicker, { getMaxDate, getMinDate } from '../../../../components/Datepicker';

import Button from '../../../../components/Button';
import { getDictionary } from '../../../../appUtils/locale';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'resolver-report-patient-data';
const captionCssClassName = `${baseCssClassName}__caption`;
const rowCssClassName = `${baseCssClassName}__row`;
const cellCssClassName = `${baseCssClassName}__cell`;
const fieldCssClassName = `${baseCssClassName}-field`;

class ResolverReportPatientDataField extends PureComponent {
	static propTypes = {
		mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
		label: PropTypes.string.isRequired,
		placeholder: PropTypes.string.isRequired,
		value: PropTypes.string,
		initialValue: PropTypes.string,
		readOnly: PropTypes.bool,
		select: PropTypes.bool,
	};

	static defaultProps = {
		readOnly: false,
		select: false,
	};

	state = {
		value: this.props.initialValue,
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleChange = (event) => {
		this.setState({
			value: event.target.value,
		});
	};

	render () {
		return (
			<div className={fieldCssClassName}>
				{this.props.mode === MODES.PREVIEW && (
					<Fragment>
						<div className={`${fieldCssClassName}__label`}>
							{this.props.label}
						</div>
						<div className={`${fieldCssClassName}__value`}>
							{this.props.value || this.state.value}
						</div>
					</Fragment>
				)}
				{this.props.mode === MODES.EDITING && (
					<div className={`${fieldCssClassName}__form`}>
						<Fragment>
							{this.props.select === false && (
								<TextField
									value={this.props.value || this.state.value}
									label={this.props.label}
									placeholder={this.props.placeholder}
									margin={'dense'}
									fullWidth
									InputLabelProps={{
										shrink: true,
										className: `${fieldCssClassName}__label`,
									}}
									InputProps={{
										className: `${fieldCssClassName}__input`,
										readOnly: this.props.readOnly,
									}}
									onChange={this._handleChange}
								/>
							)}
							{this.props.select === true && (
								<TextField
									value={this.props.value || this.state.value}
									label={this.props.label}
									placeholder={this.props.placeholder}
									margin={'dense'}
									fullWidth
									select
									InputLabelProps={{
										shrink: true,
										className: `${fieldCssClassName}__label`,
									}}
									SelectProps={{
										native: true,
										className: `${fieldCssClassName}__input`,
									}}
									onChange={this._handleChange}
								>
									{mainConfig.GENDERS.map((type, index) => (
										<option style={{color: 'black'}} key={index} value={type}>
											{i18nShared(`gender.${type}`)}
										</option>
									))}
								</TextField>
							)}
						</Fragment>
					</div>
				)}
			</div>
		);
	}
}

export default class ResolverReportPatientData extends PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		currentImage: PropTypes.object.isRequired,
		isAuthenticated: PropTypes.bool.isRequired,
		mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
	};

	state = {
		screeningDate: this._getInitialScreeningDate(),
		isDatepickerOpened: false,
	};

	_handleDateClick = () => {
		this.setState({
			isDatepickerOpened: true,
		});
	};

	_handleDateChange = (date) => {
		this.setState({
			screeningDate: momentUtils.getMomentForDate({ date }).format('YYYY-MM-DD'),
			isDatepickerOpened: false,
		});
	};

	_renderDatepicker () {
		if ( false === this.state.isDatepickerOpened ) {
			return null;
		}

		const date = this.state.screeningDate || null;

		return (
			<PopupDialog
				headerProps={{
					title: 'Select date',
				}}
				content={(
					<Datepicker
						selectedDays={date ? [ momentUtils.moment({ args: [ date ]}).toDate() ] : null}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						onDayClick={this._handleDateChange}
					/>
				)}
				footerProps={{
					buttons: ({ popup: { close } }) => {
						return [
							(
								<Button
									key={'popup_cancel'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={close}
								>{'Cancel'}</Button>
							),
						];
					},
				}}
				popupProps={{
					onClose: () => {
						this.setState({
							isDatepickerOpened: false,
						});
					},
				}}
			/>
		);
	}

	_getSpecialistName () {
		if ( true === this.props.isAuthenticated ) {
			return `${this.props.user.first_name} ${this.props.user.last_name}`;
		}

		return '';
	}

	_getSpecialistEmail () {
		if ( true === this.props.isAuthenticated ) {
			return this.props.user.report_email;
		}

		return '';
	}

	_getAge () {
		if ( typeof this.props.currentImage.birthday === 'string' ) {
			return dateUtils.getAge(this.props.currentImage.birthday);
		}

		return '';
	}

	_getGender () {
		if ( typeof this.props.currentImage.gender === 'string' ) {
			return this.props.currentImage.gender;
		}

		return '';
	}

	_getInitialScreeningDate () {
		if ( typeof this.props.currentImage.image_date === 'string' ) {
			return this.props.currentImage.image_date;
		}

		return '';
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={captionCssClassName}>
					Patient Visit Report
				</div>
				<div className={rowCssClassName}>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-long`}>
						<div style={{ padding: '0 40px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Specialist Name'}
								placeholder={'Type your name'}
								initialValue={this._getSpecialistName()}
							/>
						</div>
					</div>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-medium`}>
						<div style={{ padding: '0 40px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Specialist e-mail'}
								placeholder={'Type your e-mail'}
								initialValue={this._getSpecialistEmail()}
							/>
						</div>
					</div>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-medium`}>
						<div style={{ padding: '0 40px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Specialist phone'}
								placeholder={'Type your phone'}
							/>
						</div>
					</div>
				</div>

				<div className={rowCssClassName}>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-long`}>
						<div style={{ padding: '0 40px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Patient name'}
								placeholder={'Type patient name'}
							/>
						</div>
					</div>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-short`}>
						<div style={{ padding: '0 10px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Age'}
								placeholder={'Age'}
								initialValue={this._getAge()}
							/>
						</div>
					</div>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-short`}>
						<div style={{ padding: '0 10px 0 0' }}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Gender'}
								placeholder={'Gender'}
								initialValue={this._getGender()}
								select
							/>
						</div>
					</div>
					<div className={`${cellCssClassName} ${cellCssClassName}__m-short`}>
						<div style={{ padding: '0 10px 0 0' }} onClick={this._handleDateClick}>
							<ResolverReportPatientDataField
								mode={this.props.mode}
								label={'Date of screening'}
								placeholder={'YYYY-MM-DD'}
								value={this.state.screeningDate}
								readOnly
							/>
						</div>
					</div>
				</div>
				{this._renderDatepicker()}
			</div>
		);
	}
}
