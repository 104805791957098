import { connect } from 'react-redux';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import labelsUtils from '../../appUtils/labelsUtils';

import editorActions from '../../actions/editorActions';
import labelsActions from '../../modules/labels/actions/labelsActions';

import imagesSelectors from '../../selectors/imagesSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import userSelectors from '../../selectors/userSelectors';

import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';

import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../modules/label-tags/selectors/labelTagGetter';
import labelChildrenSelectors from '../../modules/labels/selectors/labelChildrenSelectors';

import analyseSelectors from '../../selectors/analyseSelectors';

import DentalNotationDiagram from './DentalNotationDiagram';


export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const showAllClasses = editorSelectors.selectShowAllFindings(state);
	const highlightedLabels = ( editorData.highlightedLabels || [] );
	const filteredClasses = editorSelectors.selectFilteredClassesForCurrentImage(state);
	
	let teeth = {};
	const teethAvailableToShift = {};
	let selectedLabel = editorData.selectedLabel;
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const isImageAnalyzed = analyseSelectors.selectIsImageAnalyzed(state, { imageId: currentImageId });
	
	if ( !getRuntimeConfig()['auto-image-analysis'] || !isImageAnalyzed ) {
		teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.reduce((result, labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});
				
				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
					if ( tags && tags.length > 0 ) {
						const toothKey = labelTagGetter.getTagKey(tags[0]);
						
						teethAvailableToShift[toothKey] = true;
						result[toothKey] = {
							...label,
							children: [],
						};
					}
				}
				
				return result;
			}, {});
		
		Object.keys(teeth).forEach((toothKey) => {
			const label = teeth[toothKey];
			
			const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( children.length > 0 ) {
				children.forEach((childLabelId) => {
					const childLabel = labelsSelectors.selectLabelById(state, {
						labelId: childLabelId,
					});

					label.children.push({
						...childLabel,
						parentId: labelGetters.getLabelId(label),
					});
				});
			}
		});
	}
	
	return {
		selectedToothKey: editorData.selectedToothKey,
		teethAvailableToShift,
		teeth,
		editorMode: editorData.editorMode,
		currentImage: imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}),
		user: userSelectors.selectUserData(state),
	};
}, (dispatch) => ({
	onHighlightLabels: (options) => {
		dispatch(editorActions.highlightLabels(options))
	},
	onShiftTeeth: (data) => dispatch(labelsActions.shiftTeeth(data)),
	onSelectLabel: (data) => dispatch(editorActions.selectLabel(data)),
	onSelectToothKey: (data) => dispatch(editorActions.selectToothKey(data)),
}))(DentalNotationDiagram);
