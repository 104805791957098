import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';


import './styles/ImageBox.css';


const baseCssClassName = 'image-box';
const controlsCssClassName = `${baseCssClassName}-controls`;
const resizeControlsLeftTopCssClassName = `${baseCssClassName}-resize-controls__left-top`;
const resizeControlsRightTopCssClassName = `${baseCssClassName}-resize-controls__right-top`;
const resizeControlsLeftBottomCssClassName = `${baseCssClassName}-resize-controls__left-bottom`;
const resizeControlsRightBottomCssClassName = `${baseCssClassName}-resize-controls__right-bottom`;


class ImageBox extends Component {
	static propTypes = {
		labelId: PropTypes.string.isRequired,
		hasPathologies: PropTypes.bool.isRequired,
		top: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		isPrimary: PropTypes.bool.isRequired,
		isSelected: PropTypes.bool,
		isVisible: PropTypes.bool,
		isHighlighted: PropTypes.bool,
		boxColor: PropTypes.string,
		borderStyle: PropTypes.string,

		onClick: PropTypes.func,
		onMoveStart: PropTypes.func,
		onGetRef: PropTypes.func,
	}
	
	static RESIZE_CONTROLS_LEFT_TOP = 'left-top';
	static RESIZE_CONTROLS_RIGHT_TOP = 'right-top';
	static RESIZE_CONTROLS_LEFT_BOTTOM = 'left-bottom';
	static RESIZE_CONTROLS_RIGHT_BOTTOM = 'right-bottom';

	static BORDER_STYLES = { DASHED: 'dashed', SOLID: 'solid' };

	_handleControlsMouseDown = (event) => {
		if ( event.target !== event.currentTarget ) {
			return;
		}
		
		const {
			onMoveStart,
		} = this.props;
		
		if ( onMoveStart ) {
			onMoveStart(event);
		}
	}

	_handleRef = (el) => {
		const {
			labelId,
			onGetRef,
		} = this.props;

		if ( onGetRef ) {
			onGetRef({ labelId, el });
		}
	}

	_startResizing = (event, control) => {
		const {
			onResizeStart,
		} = this.props;
		
		if ( onResizeStart ) {
			onResizeStart(event, control);
		}
	}
	
	render () {
		return (
			<div
				className={classnames([
					baseCssClassName,
					this.props.hasPathologies
						? `${baseCssClassName}__m-has-pathologies`
						: `${baseCssClassName}__m-no-pathologies`,
					this.props.isPrimary
						? `${baseCssClassName}__m-primary`
						: `${baseCssClassName}__m-secondary`,
					this.props.isSelected && `${baseCssClassName}__m-selected`,
					this.props.isHighlighted && `${baseCssClassName}__m-highlighted`,
					this.props.isVisible ? `${baseCssClassName}__m-visible` : `${baseCssClassName}__m-invisible`,
				])}
				style={{
					top: this.props.top,
					left: this.props.left,
					width: this.props.width,
					height: this.props.height,
					borderColor: this.props.boxColor,
					borderStyle: (this.props.borderStyle || ImageBox.BORDER_STYLES.DASHED),
				}}
				onClick={this.props.onClick}
				ref={this._handleRef}
			>
				<div
					className={controlsCssClassName}
					onMouseDown={this._handleControlsMouseDown}
				>
					<div
						className={resizeControlsLeftTopCssClassName}
						style={{ backgroundColor: this.props.boxColor }}
						onMouseDown={(event) => this._startResizing(event, ImageBox.RESIZE_CONTROLS_LEFT_TOP)}
					/>
					<div
						className={resizeControlsRightTopCssClassName}
						style={{ backgroundColor: this.props.boxColor }}
						onMouseDown={(event) => this._startResizing(event, ImageBox.RESIZE_CONTROLS_RIGHT_TOP)}
					/>
					<div
						className={resizeControlsLeftBottomCssClassName}
						style={{ backgroundColor: this.props.boxColor }}
						onMouseDown={(event) => this._startResizing(event, ImageBox.RESIZE_CONTROLS_LEFT_BOTTOM)}
					/>
					<div
						className={resizeControlsRightBottomCssClassName}
						style={{ backgroundColor: this.props.boxColor }}
						onMouseDown={(event) => this._startResizing(event, ImageBox.RESIZE_CONTROLS_RIGHT_BOTTOM)}
					/>
				</div>
			</div>
		);
	}
}

export default connect((state, props) => {
	let hasPathologies = false;


	return {
		hasPathologies,
		boxColor: props.boxColor,
	};
})(ImageBox);
