import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { events } from '../../services/events';
import { DRAGGING_STATUS } from '../Canvas/CanvasConstants';


export default class MagnifyGlassCanvas extends PureComponent {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		draggingStatus: PropTypes.string.isRequired,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_baseEl = null;

	componentDidUpdate (prevProps) {
		if ( prevProps.draggingStatus !== DRAGGING_STATUS.IDLE && this.props.draggingStatus === DRAGGING_STATUS.IDLE ) {
			events.emit('canvas.dragging.finished');
		}
		else if ( prevProps.draggingStatus !== DRAGGING_STATUS.IN_PROGRESS && this.props.draggingStatus === DRAGGING_STATUS.IN_PROGRESS ) {
			events.emit('canvas.dragging.started');
		}
	}

	componentWillUnmount () {
		window.document.removeEventListener('mousemove', this._handleMouseMove, false);
	}

	/**
	 * @param {HTMLElement} element
	 * @private
	 */
	_handleRef = (element) => {
		if ( element === null || element === undefined || element === this._baseEl ) {
			return;
		}

		this._baseEl = element;
		window.document.addEventListener('mousemove', this._handleMouseMove, false);
	}

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleMouseMove = (event) => {
		if ( this._baseEl === null ) {
			return;
		}

		const rect = this._baseEl.getBoundingClientRect();

		if (
			event.clientX + window.scrollX >= rect.left + window.scrollX &&
			event.clientX + window.scrollX <= rect.right + window.scrollX &&
			event.clientY + window.scrollY >= rect.top + window.scrollY &&
			event.clientY + window.scrollY <= rect.bottom + window.scrollY
		) {
			events.emit('canvas.pointer.moved', {
				x: ((event.clientX + window.scrollX) - (rect.left + window.scrollX)) / this.props.zoom,
				y: ((event.clientY + window.scrollY) - (rect.top + window.scrollY)) / this.props.zoom,
			});
		}
	};
	
	render () {
		return (
			<div
				style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
				ref={this._handleRef}
			/>
		)
	}
}
