const syncStatuses = {
	MODULE_SYNC_WITH_API_STATUS_IDLE: 'MODULE_SYNC_WITH_API_STATUS_IDLE',
	MODULE_SYNC_WITH_API_STATUS_SYNCING: 'MODULE_SYNC_WITH_API_STATUS_SYNCING',
	MODULE_SYNC_WITH_API_STATUS_FAILED: 'MODULE_SYNC_WITH_API_STATUS_FAILED',
	MODULE_SYNC_WITH_API_STATUS_SYNCED: 'MODULE_SYNC_WITH_API_STATUS_SYNCED',
};

export default {
	syncStatuses,
	availableSyncStatuses: Object.keys(syncStatuses),
};
