import mainConfig from '../../../configs/mainConfig';
import labelsUtils from '../../../appUtils/labelsUtils';
import { getStore } from '../../../services/store';

import labelTagsSelectors from '../../label-tags/selectors/labelTagsSelectors';
import labelGetters from '../selectors/labelGetters';
import labelTagGetter from '../../label-tags/selectors/labelTagGetter';
import userSelectors from "../../../selectors/userSelectors";


const classWeights = {
	periodontitis: 1,
	caries: 0,
};

/**
 * @param {Label[]} labels
 * @param {Object} options
 * @param {boolean} options.filterGreenLines
 * @param {boolean} options.filterNotPathologies
 * @returns {Label[]}
 */
export function prepareBonelosses (labels, options) {
	const state = getStore().getState();
	let boneLosses = labels.filter((label) => mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(label)) === true);

	if ( options.filterGreenLines === true ) {
		boneLosses = labels.filter((label) => mainConfig.BONE_LOSS_LINES_VISIBLE.includes(labelGetters.getLabelClassId(label)) === true);
	}

	if ( options.filterNotPathologies === true ) {
		labels = labels
			.filter((label) =>
				labelsUtils.getLabelAvailabilityOptions(labelGetters.getLabelClassId(label)).findings_group === 'pathological'
			);
	}

	labels.sort((a, b) => (classWeights[labelGetters.getLabelClassId(b)] || 0) - (classWeights[labelGetters.getLabelClassId(a)] || 0));

	if ( boneLosses.length > 0 ) {
		const records = []
		const state = getStore().getState();
		const user = userSelectors.selectUserData(state);

		boneLosses.reduce((result, label) => {
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
			const shouldShowConfidence = (
				labelsUtils.shouldShowConfidenceForClass(labelGetters.getLabelClassId(label)) === true &&
				typeof measureOfConfidence === 'number' &&
				measureOfConfidence !== 1
			);
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( Array.isArray(tags) && tags.length > 0 ) {
				const mm = tags.find((tag) => labelTagGetter.getTagKey(tag).includes('mm') === true);
				const type = tags.find((tag) => labelTagGetter.getTagKey(tag).includes('distal') === true || labelTagGetter.getTagKey(tag).includes('mesial'));
				const stage1LowerBoundary = typeof user.stage_1_ratio_threshold === 'number'
					? Math.round(user.stage_1_ratio_threshold * 100)
					: 0;
				let percent = '';

				switch (labelGetters.getLabelClassId(label)) {
					case 'stage_0_bone_loss_line':
						percent = `0-${stage1LowerBoundary}%`;
						break;

					case 'stage_1_bone_loss_line':
						percent = stage1LowerBoundary !== 15 ? `${stage1LowerBoundary}-15%` : '15%';
						break;

					case 'stage_2_bone_loss_line':
						percent = '15-33%';
						break;

					case 'stage_3_bone_loss_line':
						percent = '33-66%';
						break

					case 'stage_4_bone_loss_line':
						percent = '66%+';
						break;
				}

				result.push(`${typeof type !== 'undefined' ? labelTagGetter.getLocalizedTagName(type) : ''} ${typeof mm !== 'undefined' ? labelTagGetter.getLocalizedTagName(mm) : ''} ${percent}${shouldShowConfidence === true ? ' (AI Confidence ' + measureOfConfidence * 100 + '%)' : ''}`);
			}

			return result;
		}, records);

		if ( records.length > 0 ) {
			labels.push({
				class_id: `RBL ${records.join(', ')}`,
				labelId: `RBL ${records.join(', ')}`,
				shape: null,
				meta: {
					confidence_percent: null,
				},
				tags: [],
			});
		}
	}

	return labels;
}
