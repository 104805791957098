import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mainConfig from '../../configs/mainConfig';

import imagesActions from '../../actions/imagesActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';

import FormInput from '../FormInput';
import Button from '../Button';

import PopupDialog, { PopupDialogHeader } from '../PopupDialog';

import { getDictionary } from '../../appUtils/locale';


const i18n = getDictionary('image-share');
const i18nShared = getDictionary('shared');


class ImageShare extends Component {
	static propTypes = {
		image: PropTypes.object.isRequired,
		imageUrl: PropTypes.string.isRequired,
		onClose: PropTypes.func.isRequired,
	}
	
	constructor (props, context) {
		super(props, context);

		this._inputRef = null;

		this.state = {
			linkCopied: false,
			hasCopyLinkError: false,
			isSharing: true,
			isShared: false,
			hasSharingError: false,
			sharedHashname: null
		};
	}

	componentWillUnmount () {
		this.state = null;
	}

	componentDidMount () {
		this._shareImage();

		if ( this.state.isShared ) {
			this._selectLink();
		}
	}

	_handleInputRef = (el) => {
		if ( !el ) {
			return;
		}

		this._inputRef = el;
	}

	_handleCopyButtonClick = () => {
		this.setState({
			linkCopied: false,
			hasCopyLinkError: false,
		});

		if ( this._inputRef && this._inputRef.select ) {
			this._selectLink();

			try {
				document.execCommand('copy');

				this.setState({
					linkCopied: true,
				});
			}
			catch (e) {
				this.setState({
					linkCopied: false,
					hasCopyLinkError: true,
				});
			}
		}
		else {
			this.setState({
				linkCopied: false,
				hasCopyLinkError: true,
			});
		}
	}

	_handleTryAgainButtonClick = () => {
		this._shareImage();
	}
	
	async _shareImage () {
		this.setState({
			isSharing: true,
			hasSharingError: false,
		});

		try {
			const result = await this.props.onShareImage({
				hashName: this.props.image.hashname,
			});

			if ( !this.state ) {
				return;
			}

			this.setState({
				isSharing: false,
				isShared: true,
				sharedHashname: result.shared_hashname
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isSharing: false,
				hasSharingError: true,
			});
		}
	}

	_selectLink () {
		if ( this._inputRef && this._inputRef.select ) {
			this._inputRef.select();
		}
	}

	_renderContent () {
		if ( this.state.isSharing ) {
			return (
				<div>{i18nShared('messages.wait')}</div>
			);
		}

		if ( this.state.hasSharingError ) {
			return (
				<div>{i18nShared('messages.error.with_retry')}</div>
			);
		}

		return (
			<div>
				<p>{i18n('preface')}:</p>
				<div>
					<FormInput
						type={'text'}
						defaultValue={`${window.location.origin}${mainConfig.URL_BASE_PATH}shared/${this.state.sharedHashname}`}
						readOnly
						block
						onGetRef={this._handleInputRef}
					/>
				</div>
			</div>
		);
	}

	_renderButtons = ({ popup: { close } }) => {
		const {
			hasSharingError,
			isShared,
		} = this.state;

		const buttons = [];

		if ( hasSharingError ) {
			buttons.push((
				<Button
					key={'popup_try_again_button'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					onClick={this._handleTryAgainButtonClick}
				>{i18nShared('buttons.try_again')}</Button>
			));
		}

		if ( isShared ) {
			buttons.push((
				<Button
					key={'popup_copy_button'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					title={i18nShared('buttons.copy')}
					onClick={this._handleCopyButtonClick}
				>{i18nShared('buttons.copy')}</Button>
			));
		}

		buttons.push(
			(
				<Button
					key={'popup_close_button'}
					theme={Button.AVAILABLE_THEMES.SECONDARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					title={i18nShared('buttons.close')}
					onClick={close}
				>{i18nShared('buttons.close')}</Button>
			)
		);

		return buttons;
	}

	//@todo add state of copy link operation
	render() {
		return (
			<PopupDialog
				headerProps={{
					title: i18n('dialog.title'),
					icon: PopupDialogHeader.icons.SUCCESS
				}}
				content={this._renderContent()}
				footerProps={{
					buttons: this._renderButtons,
				}}
				popupProps={{
					onClose: this.props.onClose,
				}}
			/>
		);
	}
}

export default connect((state) => {
	const image = imagesSelectors.selectImageById(state, {
		id:  editorSelectors.selectCurrentImageId(state),
	});
	
	return {
		image,
		imageUrl: `${window.location.origin}${mainConfig.URL_BASE_PATH}image/${image.hashname}`,
	};
}, (dispatch) => ({
	onShareImage: (data) => dispatch(imagesActions.shareImage(data)),
}))(ImageShare);
