import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/RoundedButton.css';


const baseCssClassName = 'rounded-button';

const AVAILABLE_SIZES = {
	LARGE: 'large',
	MEDIUM: 'medium',
	SMALL: 'small',
};

const THEMES = {
	'primary': 'primary',
	'secondary': 'secondary',
};

export default class RoundedButton extends PureComponent {
	static propTypes = {
		type: PropTypes.string.isRequired,
		title: PropTypes.string,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
		theme: PropTypes.oneOf(Object.values(THEMES)),
		size: PropTypes.oneOf(Object.values(AVAILABLE_SIZES)),
	};

	static defaultProps = {
		disabled: false,
		theme: THEMES.primary,
		size: AVAILABLE_SIZES.LARGE,
	};

	static themes = THEMES;

	static sizes = AVAILABLE_SIZES;

	render () {
		return (
			<button
				className={classnames([
					baseCssClassName,
					`${baseCssClassName}__m-type-${this.props.type}`,
					`${baseCssClassName}__m-theme-${this.props.theme}`,
					`${baseCssClassName}__m-size-${this.props.size}`,
					this.props.disabled === true && `${baseCssClassName}__m-disabled`,
				])}
				title={this.props.title}
				onClick={this.props.onClick}
			/>
		);
	}
}
