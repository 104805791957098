import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Tooltip from '../../../../components/Tooltip';


import './styles/ResolverFindingsValidationWrapper.css';


const ICON_POSITIONS = {
	LEFT: 'left',
	RIGHT: 'right',
	RIGHT_NEAR: 'right-near',
};

const THEMES = {
	red: 'red',
	gray: 'gray',
};

const baseCssClassName = `resolver-findings-validation-wrapper`;
const contentCssClassName = `${baseCssClassName}__content`;
const iconCssClassName = `${baseCssClassName}__icon`;


export default class ResolverFindingsValidationWrapper extends PureComponent {
	static propTypes = {
		children: PropTypes.node,
		iconPosition: PropTypes.oneOf(Object.values(ICON_POSITIONS)),
		tooltip: PropTypes.node,
		hasError: PropTypes.bool.isRequired,
		theme: PropTypes.oneOf(Object.values(THEMES)),
		onClick: PropTypes.func,
	};
	
	static defaultProps = {
		iconPosition: ICON_POSITIONS.RIGHT,
		theme: THEMES.red,
	};
	
	static ICON_POSITIONS = ICON_POSITIONS;
	static THEMES = THEMES;

	constructor (props) {
		super(props);
		
		this._iconEl = null;
		
		this.state = {
			iconHovered: false,
		};
	}
	
	_handleClick = (event) => {
		if ( this.props.onClick ) {
			this.props.onClick({ event, hasError: this.props.hasError });
		}
	}
	
	_handleIconRef = (el) => {
		this._iconEl = el;
	}
	
	_handleIconOver = () => {
		this.setState({
			iconHovered: true,
		});
	}
	
	_handleIconOut = () => {
		this.setState({
			iconHovered: false,
		});
	}
	
	render () {
		const icon = (
			<div
				className={classnames([
					iconCssClassName,
					`${iconCssClassName}__m-position-${this.props.iconPosition}`,
					`${iconCssClassName}__m-theme-${this.props.theme}`,
				])}
				ref={this._handleIconRef}
				onMouseOver={this._handleIconOver}
				onMouseOut={this._handleIconOut}
			/>
		);
		
		const leftIcon = this.props.iconPosition === ResolverFindingsValidationWrapper.ICON_POSITIONS.LEFT
			? icon
			: null;
		
		const rightIcon = (
			this.props.iconPosition === ResolverFindingsValidationWrapper.ICON_POSITIONS.RIGHT ||
			ResolverFindingsValidationWrapper.ICON_POSITIONS.RIGHT_NEAR
		)
			? icon
			: null;
		
		return (
			<div className={baseCssClassName} onClick={this._handleClick}>
				<div className={contentCssClassName}>
					{this.props.hasError && leftIcon}
					{this.props.children}
					{this.props.hasError && rightIcon}
				</div>
				
				{this.props.tooltip && this.props.hasError && this.state.iconHovered && (
					<Tooltip
						target={this._iconEl}
						isGlobal
					>
						{this.props.tooltip}
					</Tooltip>
				)}
			</div>
		);
	}
}
