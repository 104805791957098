export default class EventEmitter {
	/**
	 * @type {Object<string,Array<function>>}
	 * @private
	 */
	_handlers = {};
	
	/**
	 * @param {string} eventName
	 * @param {function} handler
	 */
	on (eventName, handler) {
		if ( !this._handlers[eventName] ) {
			this._handlers[eventName] = [];
		}
		
		this._handlers[eventName].push(handler);
	}
	
	/**
	 * @param {string} eventName
	 * @param {function} handler
	 */
	off (eventName, handler) {
		if ( !this._handlers[eventName] ) {
			return;
		}
		
		for (let i = 0, l = this._handlers[eventName].length; i < l; i++) {
			if ( this._handlers[eventName][i] === handler ) {
				this._handlers[eventName].splice(i, 1);
				return;
			}
		}
	}
	
	emit (eventName, ...args) {
		if ( !this._handlers[eventName] ) {
			return;
		}
		
		for (let i = 0, l = this._handlers[eventName].length; i < l; i++) {
			this._handlers[eventName][i].apply(null, args);
		}
	}
	
	destroy () {
		this._handlers = null;
	}
}
