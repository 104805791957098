export default {
	'analyzing.dialog.title': 'Информация',
	'analyzing.progress': 'Приложение находит подозрительные регионы для исключения возможных патологий профессионалом',
	'analyzing.success': 'Анализ текущего снимка',
	
	'login_message.dialog.title': 'Внимание',
	'hint.select_tooth': 'Выберите зуб на схеме или',
	'hint.draw_secondary_box': 'Отметить патологию здесь',
	
	'overlay.cancel_action': 'Отменить действие',
	
	'buttons.open_last_image': 'Открыть последний снимок',
	'buttons.upload_image': 'Загрузить снимок',
	
	'select_tooth.placeholder': 'исп. другой тип',
	'select_tooth.no_tooth_key': 'Без номера',

	'image_flipping': 'Переворот изображения',

	'unsaved_changes.confirm': 'У вас есть несохраненные изменения. Сохранить их?',
	'unsaved_changes.saving': 'Сохранение изменений. Пожалуйста, подождите',
	'unsaved_changes.title': 'Не сохранные изменения',
	'unsaved_changes.buttons.save': 'Сохранить',
	'unsaved_changes.buttons.dont_save': 'Не сохранять',
	'unsaved_changes.buttons.cancel': 'Отмена',
};
