export default class ImagesCache {
	_imagesMap = {};

	generateCache (imageId) {
		const cache = String(Math.random());

		this._imagesMap[imageId] = cache;

		return cache;
	}

	hasCache (imageId) {
		return this._imagesMap.hasOwnProperty(imageId);
	}

	getCache (imageId) {
		return this._imagesMap[imageId] || null;
	}

	removeCache (imageId) {
		delete this._imagesMap[imageId];
	}
}
