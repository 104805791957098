import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { events } from '../../services/events';

import { trackEvent } from '../../integrations/mixpanel';

import { getDictionary } from '../../appUtils/locale';

import collectionsActions from "../../actions/collectionsActions";
import PopupDialogConfirm from "../PopupDialogConfirm";

import { PopupDialogHeader } from '../PopupDialog';



const i18n = getDictionary('remove-collection-form');
const i18nShared = getDictionary('shared');


class DeleteCollectionForm extends PureComponent {
	_handleDeleteClick = () => {
		this.props.onRemoveCollection({
			collectionId: this.props.hashname
		})
			.then(() => {
				trackEvent('Collection removed');
				events.emit('collection-removed');
			});
		
		this.props.onClose();
	}

	_handleCloseClick = () => {
		this.props.onClose();
	}

	render() {
		return (
			<PopupDialogConfirm
				title={i18n('header')}
				icon={PopupDialogHeader.icons.SUCCESS}
				content={(<div style={{padding: 20}}>{i18n('text')}</div>)}
				confirmButtonText={i18nShared('confirm.buttons.yes')}
				cancelButtonText={i18nShared('buttons.cancel')}
				onConfirm={this._handleDeleteClick}
				onCancel={this._handleCloseClick}
			/>
		);
	}
}

export default connect(
	null,
	dispatch => ({
		onRemoveCollection: (options) => dispatch(collectionsActions.removeCollection(options)),
	})
)(DeleteCollectionForm);
