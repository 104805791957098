import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mainConfig from '../../configs/mainConfig';
import labelsUtils from '../../appUtils/labelsUtils';

import editorActions from '../../actions/editorActions';

import editorSelectors from '../../selectors/editorSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';

import DrawBox from '../DrawBox';
import DrawPolygon from '../DrawPolygon';
import DrawBoneloss from '../DrawBoneloss';


const STEPS = {
	DRAW_BOX: 'draw_box',
	SELECT_TOOTH: 'select_tooth',
};


class AddShapeWizard extends Component {
	static propTypes = {
		zoom: PropTypes.number.isRequired,
		selectedToothKey: PropTypes.string,
		selectedClassId: PropTypes.string,
		primaryBox: PropTypes.object,
		labelShape: PropTypes.object,
		imageOriginalWidth: PropTypes.number.isRequired,
		imageOriginalHeight: PropTypes.number.isRequired,
		canvasApi: PropTypes.object.isRequired,

		onAddNewBox: PropTypes.func.isRequired,
		onSetNewBox: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
	}

	state = {
		currentStep: STEPS.DRAW_BOX,
		shape: null,
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			nextProps.selectedToothKey &&
			nextProps.selectedToothKey !== this.props.selectedToothKey &&
			this.state.currentStep === STEPS.SELECT_TOOTH
		) {
			this.props.onAddNewBox();
		}
	}


	_handleStartDraw = () => {
		this.props.canvasApi.canDragCanvas(false);
	};

	_handleFinishDraw = (shape) => {
		this.props.canvasApi.canDragCanvas(true);
		this.setState({
			shape,
			currentStep: STEPS.SELECT_TOOTH,
		});

		if ( this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ) {
			window.scrollTo(0, 0);
		}

		this.props.onSetNewBox(shape);
	};
	
	_handleBonelossFinishDraw = (shape) => {
		this.props.canvasApi.canDragCanvas(true);
		if ( shape.points.length === 0 ) {
			return this.props.onReset();
		}
		
		this._handleFinishDraw(shape);
	};

	render () {
		const {
			zoom,
			primaryBox,
			labelShape,
			selectedClassId,
			imageOriginalWidth,
			imageOriginalHeight,
		} = this.props;

		switch (this.state.currentStep) {
			case STEPS.DRAW_BOX:
			case STEPS.SELECT_TOOTH: {
				if ( labelShape ) {
					switch (labelShape.type) {
						case 'poly':
							return (
								<DrawPolygon
									zoom={zoom}
									labelShape={labelShape}
									imageOriginalWidth={imageOriginalWidth}
									imageOriginalHeight={imageOriginalHeight}
									onStart={this._handleStartDraw}
									onFinish={this._handleFinishDraw}
								/>
							);

						case 'named_poly':
							return (
								<DrawBoneloss
									zoom={zoom}
									selectedClassId={selectedClassId}
									labelShape={labelShape}
									imageOriginalWidth={imageOriginalWidth}
									imageOriginalHeight={imageOriginalHeight}
									onStart={this._handleStartDraw}
									onFinish={this._handleBonelossFinishDraw}
								/>
							);

						default:
							return (
								<DrawBox
									zoom={zoom}
									selectedClassId={selectedClassId}
									labelShape={labelShape}
									imageOriginalWidth={imageOriginalWidth}
									imageOriginalHeight={imageOriginalHeight}
									onStart={this._handleStartDraw}
									onFinish={this._handleFinishDraw}
								/>
							);
					}
				}

				return (
					<DrawBox
						zoom={zoom}
						primaryBox={primaryBox}
						selectedClassId={selectedClassId}
						imageOriginalWidth={imageOriginalWidth}
						imageOriginalHeight={imageOriginalHeight}
						onStart={this._handleStartDraw}
						onFinish={this._handleFinishDraw}
					/>
				);
			}

			default:
				return null;
		}
	}
}

export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const selectedBox = editorData.selectedBox;

	let labelShape = editorData.classIdForNewLabel ? labelsUtils.getShape(editorData.classIdForNewLabel) : null;
	
	if ( editorData.selectedLabel && editorData.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE ) {
		labelShape = labelsUtils.getShape(labelGetters.getLabelClassId(editorData.selectedLabel));
	}
	
	return {
		selectedToothKey: editorData.selectedToothKey,
		selectedClassId: editorData.classIdForNewLabel,
		editorMode: editorData.editorMode,
		primaryBox: ( selectedBox && selectedBox.primary ? selectedBox : null ),
		labelShape,
	}
}, (dispatch) => ({
	onSetNewBox: (data) => dispatch(editorActions.setNewBox(data)),
	onAddNewBox: () => dispatch(editorActions.addNewBox()),
	onReset: () => dispatch(editorActions.resetMode()),
}))(AddShapeWizard);
