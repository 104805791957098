import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { events } from '../../../../services/events';
import commonUtils from '../../../../appUtils/common';
import {
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
} from '../../../../constants/imageConstants';

import { LOADING_STATUS } from '../entry/ResolverImageHoc';

import { getDictionary } from '../../../../appUtils/locale';

import ExaminationSelector from './ExaminationSelector';

import './styles/Navigation.css';


const i18nShared = getDictionary('shared');

const MIN_OFFSET_BETWEEN_IMAGES = 8;
const MAX_IMAGE_WIDTH = 163;
const MAX_IMAGE_HEIGHT = 88;

const baseCssClassName = 'resolver-navigation';
const topPanelCssClassName = `${baseCssClassName}-top-panel`;
const topPanelNameCssClassName = `${topPanelCssClassName}__name`;
const topPanelSeparatorCssClassName = `${topPanelCssClassName}__separator`;
const topPanelMainCssClassName = `${topPanelCssClassName}__main`;
const topPanelSecondaryCssClassName = `${topPanelCssClassName}__secondary`;
const topPanelToggleButtonCssClassName = `${topPanelCssClassName}__toggle-button`;
const topPanelLabelCssClassName = `${topPanelCssClassName}__label`;
const previewsCssClassName = `${baseCssClassName}-previews`;
const contentCssClassName = `${baseCssClassName}-content`;
const previewsItemsCssClassName = `${previewsCssClassName}__items`;
const previewsItemCssClassName = `${previewsCssClassName}-item`;
const previewsItemContentCssClassName = `${previewsItemCssClassName}__content`;
const previewsItemImageCssClassName = `${previewsItemCssClassName}__image`;
const previewsItemDescriptionCssClassName = `${previewsItemCssClassName}__description`;
const previewsItemDescriptionContentCssClassName = `${previewsItemCssClassName}__description-content`;
const previewsControlCssClassName = `${previewsCssClassName}-control`;
const previewsControlArrowCssClassName = `${previewsControlCssClassName}__arrow`;
const scrollCssClassName = `${baseCssClassName}-scroll`;


class ResolverNavigationThumbnailsGallery extends PureComponent {
	static propTypes = {
		collection: PropTypes.object.isRequired,
		image: PropTypes.object.isRequired,
		shouldShowThumbnailsGallery: PropTypes.bool.isRequired,
		shouldDisplayExaminations: PropTypes.bool.isRequired,
		imageLoadingStatus: PropTypes.string.isRequired,

		examinationImages: PropTypes.array.isRequired,
		imagesTitles: PropTypes.object.isRequired,

		currentExamination: PropTypes.string,
		availableExaminations: PropTypes.arrayOf(PropTypes.string.isRequired),
		showPanelToggle: PropTypes.bool,
		onChangeThumbnailsDisplaying: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	static defaultProps = {
		showPanelToggle: false,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_previewsEl = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_previewsItemsEl = null;

	/**
	 * @type {number}
	 * @private
	 */
	_previewsNum = 0;

	/**
	 * @type {number}
	 * @private
	 */
	_offsetBetweenImages = 0;

	state = {
		seed: null,
	};

	componentDidMount () {
		window.addEventListener('resize', this._handleResize, false);
		window.addEventListener('orientationchange', this._handleResize, false);
		window.document.body.addEventListener('keyup', this._handleKeyUp, false);
		events.on('image.flip.finished', this._handleImageFlipFinished);
		events.on('sidebar-collapse-changed', this._handleResize);
		window.setTimeout(() => {
			if ( this.state === null ) {
				return;
			}

			this._scrollToPreview(this.props.image, 'begin');
		}, 200);
	}

	componentWillUnmount () {
		this.state = null;
		window.removeEventListener('resize', this._handleResize, false);
		window.removeEventListener('orientationchange', this._handleResize, false);
		window.document.body.removeEventListener('keyup', this._handleKeyUp, false);
		events.removeListener('image.flip.finished', this._handleImageFlipFinished);
		events.removeListener('sidebar-collapse-changed', this._handleResize);
	}

	/**
	 * @param {CollectionImage} image
	 * @private
	 */
	_buildPreviewUrl (image) {
		const url = image.image_url;
		if ( this.state.seed !== null ) {
			return commonUtils.addParamToUrl(url, 'seed', this.state.seed);
		}
		return url;
	}

	/**
	 * @param {CollectionImage} image
	 * @private
	 */
	_goToImage = (image) => {
		const collectionId = this.context.router.route.match.params.collectionId;
		this.context.router.history.push(`/collections/${collectionId}/image/${image.hashname}/treatment_plan`);
	};

	_findAndGoToImage (direction = 'prev') {
		const currentImageHashName = this.props.image.hashname;
		const currentImageIndex = this.props.examinationImages.reduce((result, image, index) => {
			if ( currentImageHashName === image.hashname ) {
				result = index;
			}

			return result;
		}, -1);

		const image = this.props.examinationImages[direction === 'prev' ? currentImageIndex - 1 : currentImageIndex + 1];
		if ( typeof image !== 'undefined' ) {
			this._scrollToPreview(image, 'end');
			this._goToImage(image);
		}
	}

	/**
	 * @param {number} value
	 * @private
	 */
	_scrollTo = (value) => {
		this._previewsEl.scrollLeft = value;
	};

	/**
	 * @param {CollectionImage} image
	 * @param {string} location
	 * @private
	 */
	_scrollToPreview (image, location = 'begin') {
		if ( this._previewsItemsEl === null ) {
			return;
		}

		const previewEl = Array.from(this._previewsItemsEl.querySelectorAll(`.${previewsItemCssClassName}`)).find((item) => {
			return item.dataset.hashname === image.hashname;
		});

		if ( typeof previewEl === 'undefined' || previewEl === null ) {
			return;
		}

		const previewsPosition = this._previewsEl.getBoundingClientRect();
		const previewPosition = previewEl.getBoundingClientRect();

		if ( previewPosition.left >= previewsPosition.left && previewPosition.right <= previewsPosition.right ) {
			return;
		}

		// const nextPosition = location === 'end'
		// 	? (this._previewsEl.scrollLeft + previewPosition.left) - previewPosition.width
		// 	: this._previewsEl.scrollLeft + previewPosition.left;

		const nextPosition = this._previewsEl.scrollLeft + previewPosition.left;
		this._scrollTo(nextPosition);
	}

	_setPreviewsWidth () {
		if ( null === this._previewsEl ) {
			return;
		}

		const scrollLeft = this._previewsEl.scrollLeft;
		const fragment = document.createDocumentFragment();
		fragment.appendChild(this._previewsEl.firstChild);

		const getSize = (imagesNum) => {
			if ( imagesNum === undefined ) {
				imagesNum = Math.floor(this._previewsEl.clientWidth / MAX_IMAGE_WIDTH);
			}

			const offsetBetweenImages = ((this._previewsEl.clientWidth - (imagesNum * MAX_IMAGE_WIDTH)) / (imagesNum + 1));

			return {
				offsetBetweenImages,
				imagesNum,
			};
		};

		this._previewsEl.style.width = '';
		const size = getSize();
		let offsetBetweenImages = size.offsetBetweenImages;
		let imagesNum = size.imagesNum;

		if ( offsetBetweenImages < MIN_OFFSET_BETWEEN_IMAGES ) {
			const size = getSize(imagesNum - 1);
			offsetBetweenImages = size.offsetBetweenImages;
			imagesNum = size.imagesNum;
		}

		this._offsetBetweenImages = offsetBetweenImages;
		this._previewsNum = imagesNum;

		this._previewsEl.style.width = `${this._previewsEl.clientWidth}px`;
		this._previewsEl.appendChild(fragment.firstChild);
		this._previewsEl.scrollLeft = Math.min(this._previewsItemsEl.clientWidth - this._previewsEl.clientWidth, scrollLeft);
	}

	_handleResize = () => {
		this._setPreviewsWidth();
	};

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handlePreviewsRef = (element) => {
		this._previewsEl = element || null;
		this._setPreviewsWidth();
		if ( null !== this._previewsEl ) {
			this._previewsEl.addEventListener('scroll', () => {
				this.forceUpdate();
			});
		}
		this.forceUpdate();
	};

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handlePreviewsItemsRef = (element) => {
		this._previewsItemsEl = element || null;
		this.forceUpdate();
	};

	_handleImageFlipFinished = () => {
		this.setState({
			seed: String(Math.random()),
		});
	};

	_handlePrevArrowClick = () => {
		this._findAndGoToImage('prev');
	};

	_handleNextArrowClick = () => {
		this._findAndGoToImage('right');
	};

	/**
	 * @param {KeyboardEvent} event
	 * @private
	 */
	_handleKeyUp = (event) => {
		if (
			event.target !== window.document.body ||
			typeof event.key !== 'string' ||
			this.props.imageLoadingStatus !== LOADING_STATUS.LOADED
		) {
			return;
		}

 		switch (event.key.toLowerCase()) {
			case 'arrowleft':
				this._findAndGoToImage('prev');
				break;
			case 'arrowright':
				this._findAndGoToImage('next');
				break;
			default:
				break;
		}
	};

	_renderPreviews (previews) {
		/*		let hasItemsPadding = previews.length > 1;

				if ( null !== this._previewsItemsEl && null !== this._previewsEl ) {
					hasItemsPadding = this._previewsItemsEl.clientWidth > this._previewsEl.clientWidth
				}*/

		return (
			<div
				className={`${previewsCssClassName} ${scrollCssClassName}`}
				ref={this._handlePreviewsRef}
			>
				<div
					className={previewsItemsCssClassName}
					style={{ paddingRight: previews.length > 1 ? this._offsetBetweenImages : 0 }}
					ref={this._handlePreviewsItemsRef}
				>
					{previews.map((image, i) => (
						<div
							className={classnames([
								previewsItemCssClassName,
								this.props.image.hashname === image.hashname && `${previewsItemCssClassName}__m-selected`,
							])}
							key={image.hashname}
							data-hashname={image.hashname}
							style={{
								paddingLeft: previews.length > 1
									? null !== this._previewsItemsEl && null !== this._previewsEl && this._previewsItemsEl.clientWidth > this._previewsEl.clientWidth ? this._offsetBetweenImages : MIN_OFFSET_BETWEEN_IMAGES
									: 0
							}}
						>
							<div
								className={previewsItemContentCssClassName}
								onClick={() => this._goToImage(image)}
							>
								<div
									className={previewsItemImageCssClassName}
									title={image.hashname}
									style={{
										width: MAX_IMAGE_WIDTH,
										height: MAX_IMAGE_HEIGHT,
									}}
								>
									<img
										src={this._buildPreviewUrl(image)}
										style={{
											height: MAX_IMAGE_HEIGHT,
											margin: 'auto',
										}}
										alt={''}
										crossOrigin={'anonymous'}
									/>
								</div>
								<div className={previewsItemDescriptionCssClassName}>
									<div className={previewsItemDescriptionContentCssClassName}>
										{
											image.image_type === IMAGE_TYPE__PERIAPICAL || image.image_type === IMAGE_TYPE__BITEWING
												? (this.props.imagesTitles[image.hashname] || '-')
												: i18nShared(`image_types.${image.image_type}`)
										}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	_renderPreviewControls () {
		const currentImageHashName = this.props.image.hashname;
		const currentImageIndex = this.props.examinationImages.reduce((result, image, index) => {
			if ( currentImageHashName === image.hashname ) {
				result = index;
			}

			return result;
		}, -1);
		const displayArrows = this.props.examinationImages.length > 0;
		const prevDisabled = currentImageIndex === 0;
		const nextDisabled = currentImageIndex === this.props.examinationImages.length - 1;

		return (
			<Fragment>
				<div
					className={classnames([
						previewsControlCssClassName,
						`${previewsControlCssClassName}__m-prev`,
						displayArrows === false && `${previewsControlCssClassName}__m-hidden`,
						displayArrows === false && `${previewsControlCssClassName}__m-hidden`,
						prevDisabled === true && `${previewsControlCssClassName}__m-disabled`,
					])}
					onClick={prevDisabled === false ? this._handlePrevArrowClick : null}
				>
					<div className={previewsControlArrowCssClassName} />
				</div>
				<div
					className={classnames([
						previewsControlCssClassName,
						`${previewsControlCssClassName}__m-next`,
						displayArrows === false && `${previewsControlCssClassName}__m-hidden`,
						nextDisabled === true && `${previewsControlCssClassName}__m-disabled`,
					])}
					onClick={nextDisabled === false ? this._handleNextArrowClick : null}
				>
					<div className={previewsControlArrowCssClassName} />
				</div>
			</Fragment>
		);
	}

	render () {
		let previews = this.props.examinationImages;
		// for ( let i = 0; i < 10; i++) {
		// 	previews = previews.concat(this.props.examinationImages);
		// }

		/*if ( this.props.examinationImages.length > 2) {
			previews.push(this.props.examinationImages[0]);
			previews.push(this.props.examinationImages[1]);
			previews.push(this.props.examinationImages[2]);
			previews.push(this.props.examinationImages[0]);
			previews.push(this.props.examinationImages[1]);
		}*/

		return (
			<div className={contentCssClassName}>
				{this._renderPreviews(previews)}
				{this._renderPreviewControls()}
			</div>
		);
	}
}

export default class ResolverNavigation extends PureComponent {
	static propTypes = {
		collection: PropTypes.object.isRequired,
		image: PropTypes.object.isRequired,
		shouldShowThumbnailsGallery: PropTypes.bool.isRequired,
		shouldDisplayExaminations: PropTypes.bool.isRequired,
		imageLoadingStatus: PropTypes.string.isRequired,

		examinationImages: PropTypes.array.isRequired,
		imagesTitles: PropTypes.object.isRequired,
		isFmxModeEnabled: PropTypes.bool.isRequired,

		currentExamination: PropTypes.string,
		availableExaminations: PropTypes.arrayOf(PropTypes.string.isRequired),
		onGetLastOpenedExaminationImage: PropTypes.func.isRequired,
		onChangeThumbnailsDisplaying: PropTypes.func.isRequired,
		onChangeVisit: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	/**
	 * @param {CollectionImage} image
	 * @param {string} examination
	 * @private
	 */
	_goToImage = ({ image, examination }) => {
		this.props.onChangeVisit({ collection: this.props.collection, image, examination, isFmxModeEnabled: this.props.isFmxModeEnabled });
	};

	_handleThumbnailsDisplayingToggleClick = () => {
		this.props.onChangeThumbnailsDisplaying(!this.props.shouldShowThumbnailsGallery);
	};

	/**
	 * @param {string} examination
	 * @private
	 */
	_handleExaminationChange = (examination) => {
		const image = this.props.onGetLastOpenedExaminationImage(examination);
		if ( image ) {
			this._goToImage({ image, examination });
		}
	};

	_renderTopPanel () {
		return (
			<div className={topPanelCssClassName}>
				<div className={topPanelMainCssClassName}>
					<div className={topPanelNameCssClassName}>
						<span className={topPanelLabelCssClassName}>Collection:</span> {this.props.collection.name}
					</div>
					{this.props.shouldDisplayExaminations === true && (
						<Fragment>
							<span className={topPanelSeparatorCssClassName}>/</span>
							<span className={topPanelLabelCssClassName}>Select visit:</span>
							<ExaminationSelector
								value={this.props.currentExamination}
								items={this.props.availableExaminations.map((examination) => ({
									key: examination,
									value: examination,
								}))}
								onChange={this._handleExaminationChange}
							/>
						</Fragment>
					)}
				</div>
				<div className={topPanelSecondaryCssClassName}>
					{this.props.showPanelToggle === true && (<div
						className={classnames([
							topPanelToggleButtonCssClassName,
							true === this.props.shouldShowThumbnailsGallery ? `${topPanelToggleButtonCssClassName}__m-hide` : `${topPanelToggleButtonCssClassName}__m-show`,
						])}
						title={true === this.props.shouldShowThumbnailsGallery ? `Hide thumbnails` : `Show thumbnails`}
						onClick={this._handleThumbnailsDisplayingToggleClick}
					/>)}
				</div>
			</div>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				{this._renderTopPanel()}
				{this.props.shouldShowThumbnailsGallery === true && (
					<ResolverNavigationThumbnailsGallery
						collection={this.props.collection}
						image={this.props.image}
						shouldShowThumbnailsGallery={this.props.shouldShowThumbnailsGallery}
						shouldDisplayExaminations={this.props.shouldDisplayExaminations}
						imageLoadingStatus={this.props.imageLoadingStatus}

						examinationImages={this.props.examinationImages}
						imagesTitles={this.props.imagesTitles}

						currentExamination={this.props.currentExamination}
						availableExaminations={this.props.availableExaminations}
						onChangeThumbnailsDisplaying={this.props.onChangeThumbnailsDisplaying}
					/>
				)}
			</div>
		);
	}
}
