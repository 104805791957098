/**
 * @param {StoreState} state
 * @return {ProcedureOption[]}
 */
const selectProcedures = (state) => ( state.procedures || [] ).map((procedure) => ({value: procedure.code, label: procedure.description}));


export default {
	selectProcedures,
};
