const ACTION_IMAGES__SET_IMAGES = 'ACTION_IMAGES__SET_IMAGES';
const ACTION_IMAGES__ADD_IMAGE = 'ACTION_IMAGES__ADD_IMAGE';
const ACTION_IMAGES__REMOVE_IMAGE = 'ACTION_IMAGES__REMOVE_IMAGE';
const ACTION_IMAGES__UPDATE_IMAGE = 'ACTION_IMAGES__UPDATE_IMAGE';

export default {
	ACTION_IMAGES__SET_IMAGES,
	ACTION_IMAGES__ADD_IMAGE,
	ACTION_IMAGES__REMOVE_IMAGE,
	ACTION_IMAGES__UPDATE_IMAGE,
};
