import teethUtils from '../../appUtils/teeth/teethUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import reportTeethConfig from './reportTeethConfig';


function getToothSvg (options = {}) {
	const {
		number,
		toothData,
	} = options;

	if ( typeof number !== 'string' ) {
		throw new Error('Argument "options.number" should be non empty string.');
	}

	let toothStyle = null;

	if ( !toothData ) {
		toothStyle = reportTeethConfig.getToothStyle({
			type: 'missing',
		});
	}
	else {
		if ( !toothData.children || ( toothData.children && toothData.children.length === 0 ) ) {
			toothStyle = reportTeethConfig.getToothStyle({
				type: 'normal',
			});
		}
		else {
			toothStyle = reportTeethConfig.getToothStyle({
				type: 'finding',
				color: labelsUtils.getLabelColor(toothData),
			});
		}
	}

	return teethUtils.getToothSvg({
		number,
		params: toothStyle,
	});
}

export default {
	getToothSvg,
};
