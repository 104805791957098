import { getHeaderCloseButton, getFooterCloseButton } from '../../components/PopupDialog';

import PopupDialogController from './PopupDialogController';
import PopupDialogConfirmController from './PopupDialogConfirmController';
import PopupDialogPromptController from './PopupDialogPromptController';


/**
 * @typedef {Object} DlgPopupDialogCommonOptions
 *
 * @property {string} options.message Dialog message HTML.
 * @property {number|string} [options.width=600] The dialog width.
 * @property {number|string} [options.height='auto'] The dialog height.
 * @property {Function} [options.onOpen] Function to execute when the dialog is opened.
 * @property {Function} [options.onClose] Function to execute when the dialog is closed.
 * @property {Function} [options.onCancel] Function to execute when the dialog is cancelled. If returns false, the dialog won't close.
 * @property {boolean} [options.autoOpen=true] Open the dialog right after its creation.
 * @property {PopupProps} popupProps
 */


/**
 * Displays message dialog.
 *
 * @param {Object} options
 * @param {string} [options.title] Dialog title HTML.
 * @param {string} [options.titleIcon] Dialog title icon.
 * @param {string} [options.message] Dialog message HTML.
 * @param {Object} [options.buttons] Custom buttons.
 * @param {string} [options.classes] Space-separated list of classes for the dialog.
 * @param {number|string} [options.width='auto'] The dialog width.
 * @param {number|string} [options.height='auto'] The dialog height.
 * @param {string} [options.id] Dialog identifier. Dialog with the same identifier will be silently removed.
 * @param {Function} [options.onOpen] Function to execute when the dialog is opened.
 * @param {Function} [options.onClose] Function to execute when the dialog is closed.
 * @param {Function} [options.onCancel] Function to execute when the dialog is cancelled. If returns false, the dialog won't close.
 * @param {boolean} [options.autoOpen=true] Open the dialog right after its creation.
 * @return {PopupDialogController}
 */
export function message (options = {}) {
	let footerButtons;
	if ( options.buttons ) {
		footerButtons = options.buttons;
	}
	else {
		footerButtons = (popupInterface) => {
			return [
				getFooterCloseButton(popupInterface),
			];
		};
	}
	
	options.popupProps = ( options.popupProps || {} );
	
	options = {
		...options,
		cssClassNames: options.classes,
		headerProps: {
			title: options.title,
			icon: options.titleIcon,
			buttons: (options.headerButtons || []),
		},
		footerProps: {
			buttons: footerButtons,
		},
		popupProps: {
			...options.popupProps,
			contentStyle: {
				...options.popupProps.contentStyle,
				width: ( options.width || 600 ),
				height: ( options.height || 'auto' ),
				maxWidth: '100%',
			},
		},
		onOpen: options.onOpen,
		onBeforeClose: options.onCancel,
		onClose: options.onClose,
	};
	
	return new PopupDialogController(options);
}

/**
 * Shows modal confirmation dialog with 2 buttons: Yes and No.
 *
 * @param {Object} options
 * @param {string} [options.title] Dialog title HTML.
 * @param {string} [options.message] Dialog message HTML.
 * @param {string} [options.classes] Space-separated list of classes for the dialog.
 * @param {number|string} [options.width='auto'] The dialog width.
 * @param {number|string} [options.height='auto'] The dialog height.
 * @param {string} [options.yes] "Yes" button text.
 * @param {string} [options.no] "No" button text.
 * @param {Function} [options.onOpen] Function to execute when the dialog is opened.
 * @param {Function} [options.onClose] Function to execute when the dialog is closed.
 * @param {Function} [options.yesHandler] Function to execute when 'Yes' button is pressed. If returns false, the dialog won't close.
 * @param {Function} [options.noHandler] Function to execute when 'No' button is pressed. If returns false, the dialog won't close.
 * @param {Function} [options.onCancel] Function to execute when the dialog is cancelled. If returns false, the dialog won't close.
 * @param {boolean} [options.autoOpen=true] Open the dialog right after its creation.
 * @return {PopupDialogConfirmController}
 */
export function confirm (options) {
	let cancelBeforeClose = false;
	let shouldCallBeforeClose = true;
	
	options = {
		...options,
		confirmButtonText: options.yes,
		cancelButtonText: options.no,
		onConfirm: () => {
			if ( options.yesHandler ) {
				const result = options.yesHandler();
				if ( result === false ) {
					cancelBeforeClose = true;
				}
				else {
					shouldCallBeforeClose = false;
				}
				return result;
			}
			
			shouldCallBeforeClose = false;
			return undefined;
		},
		onCancel: () => {
			if ( options.noHandler && options.noHandler() === false ) {
				cancelBeforeClose = true;
			}
			shouldCallBeforeClose = false;
		},
		popupDialogProps: {
			cssClassNames: options.classes || '',
			popupProps: {
				contentStyle: {
					width: ( options.width || 600 ),
					height: ( options.height || 'auto' ),
					maxWidth: '100%',
				},
			},
		},
		onOpen: options.onOpen,
		onBeforeClose: () => {
			if ( cancelBeforeClose ) {
				cancelBeforeClose = false;
				return false;
			}
			
			if ( shouldCallBeforeClose && options.onCancel ) {
				return options.onCancel();
			}
			
			return undefined;
		},
		onClose: options.onClose,
	};
	
	return new PopupDialogConfirmController(options);
}

/**
 * Shows modal prompt dialog with 2 buttons: OK and Cancel.
 *
 * @param {Object} options
 * @param {string} [options.title] Dialog title HTML.
 * @param {string} [options.message] Dialog message HTML.
 * @param {string} [options.classes] Space-separated list of classes for the dialog.
 * @param {number|string} [options.width=500] The dialog width.
 * @param {number|string} [options.height='auto'] The dialog height.
 * @param {string} [options.type='text'] The text field type: text, email and textarea.
 * @param {string} [options.value] The initial value for the text field.
 * @param {Function} [options.onOpen] Function to execute when the dialog is opened.
 * @param {Function} [options.onClose] Function to execute when the dialog is closed.
 * @param {Function} [options.yesHandler] Function to execute when 'Yes' button is pressed. If returns false, the dialog won't close. If returns Promise, the dialog disables all controls and closes after the deferred is resolved; when it is rejected, the dialog remains open and the controls are enabled.
 * @param {Function} [options.noHandler] Function to execute when 'No' button is pressed. If returns false, the dialog won't close.
 * @param {Function} [options.onCancel] Function to execute when the dialog is cancelled. If returns false, the dialog won't close.
 * @param {Function} [options.preprocessor] Function to preprocess the input before passing it to validator and other callbacks.
 * @param {Function} [options.validator] Function to validate the input. If throws an Error object, the dialog won't close and the error message is displayed in a tooltip.
 * @param {string} [options.errorText] Displayed when a validation error occurs.
 * @param {boolean} [options.autoOpen=true] Open the dialog right after its creation.
 * @return {PopupDialogPromptController}
 */
export function prompt (options) {
	let cancelBeforeClose = false;
	let shouldCallBeforeClose = true;
	
	options = {
		...options,
		confirmButtonText: options.yes,
		cancelButtonText: options.no,
		onConfirm: (fieldValue) => {
			if ( options.yesHandler ) {
				const result = options.yesHandler(fieldValue);
				if ( result === false ) {
					cancelBeforeClose = true;
				}
				return result;
			}
			
			shouldCallBeforeClose = false;
			return undefined;
		},
		onCancel: () => {
			if ( options.noHandler && options.noHandler() === false ) {
				cancelBeforeClose = true;
			}
			shouldCallBeforeClose = false;
		},
		popupDialogProps: {
			cssClassNames: options.classes || '',
			popupProps: {
				contentStyle: {
					width: ( options.width || 600 ),
					height: ( options.height || 'auto' ),
					maxWidth: '100%',
				},
			},
		},
		onOpen: options.onOpen,
		onBeforeClose: () => {
			if ( cancelBeforeClose ) {
				cancelBeforeClose = false;
				return false;
			}
			
			if ( shouldCallBeforeClose && options.onCancel ) {
				return options.onCancel();
			}
			
			return undefined;
		},
		onClose: options.onClose,
	};
	
	return new PopupDialogPromptController(options);
}
