import React, { PureComponent } from 'react';
import globalPopupController from '../../../../services/popup/globalPopupControllerInstance';

import ResolverFindingViewer from './ResolverFindingViewer';


export default class ResolverFindingViewerController extends PureComponent {
	state = {
		shouldShowContent: globalPopupController.hasActivePopups() === false,
	};

	componentDidMount () {
		globalPopupController.getEvents().on('changed', this._handlePopupControllerChanged);
	}

	componentWillUnmount() {
		globalPopupController.getEvents().removeListener('changed', this._handlePopupControllerChanged);
	}

	_handlePopupControllerChanged = () => {
		this.setState({
			shouldShowContent: globalPopupController.hasActivePopups() === false,
		});
	};

	render () {
		if ( this.state.shouldShowContent === false ) {
			return null;
		}

		return (
			<ResolverFindingViewer {...this.props} />
		);
	}
}
