function getColumnValueWithEmptyFallback (value) {
	if ( value === undefined || value === null ) {
		return '-';
	}

	return value;
}


function makeGridColumn ({
	key,
	width,
	headerValue,
	getValue,
	headerContextTitle,
}) {
	return {
		key,
		width,
		headerValue,
		headerContextTitle: headerContextTitle !== undefined ? headerContextTitle : headerValue,
		getValue: function (eventData, index) {
			return getColumnValueWithEmptyFallback(getValue(eventData, index));
		},
		getContextTitle: function (eventData, index) {
			const value = this.getValue(eventData, index);

			if ( typeof value === 'string' ) {
				return value;
			}

			return '';
		},
	};
}


export default {
	getColumnValueWithEmptyFallback,
	makeGridColumn,
};
