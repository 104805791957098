import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/Switch.css';


const baseCssClassName = 'switch';
const handleCssClassName = `${baseCssClassName}__handle`;


export default class Switch extends PureComponent {
	static propTypes = {
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		checked: false,
		disabled: false,
	};

	_handleClick = () => {
		if ( 'function' !== typeof this.props.onChange || this.props.disabled === true ) {
			return;
		}

		this.props.onChange();
	};

	render () {
		return (
			<div
				className={classnames([
					baseCssClassName,
					this.props.checked === true && `${baseCssClassName}__m-checked`,
					this.props.disabled === true && `${baseCssClassName}__m-disabled`,
				])}
				onClick={this._handleClick}
			>
				<div className={handleCssClassName} />
			</div>
		);
	}
}
