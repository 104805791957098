import storeUtils from '../appUtils/storeUtils';
import { events } from '../services/events';
import imagesHistoryActionTypes from './imagesHistoryActionTypes';
import editorActions from './editorActions';
import labelsActions from '../modules/labels/actions/labelsActions';
import userSelectors from '../selectors/userSelectors';


let shouldSaveData = false;
let isSavingData = false;
let inQueue = false;

let timeoutId = null;

function saveImageData (options, dispatch, getState) {
	if ( shouldSaveData ) {
		shouldSaveData = false;
	}

	const timer = userSelectors.selectAutosaveTiming(getState()) || 1000;

	timeoutId = setTimeout (async () => {
		timeoutId = null;
		isSavingData = true;
		inQueue = false;
		try {
			await dispatch(labelsActions.saveLabels({ markHasNoChanges: false }));
			isSavingData = false;
			events.emit('labels.saved');
		}
		catch (error) {
			isSavingData = false;
			// do nothing
		}

		if ( shouldSaveData ) {
			inQueue = true;
			saveImageData(options, dispatch, getState);
			return;
		}

		dispatch(editorActions.updateData({
			data: {
				hasUnsavedChanges: false,
			},
		}));
	}, timer);
}

/**
 * @param {Object} options
 * @param {CollectionImageId} options.imageId
 * @param {Object} options.data
 * @param {boolean} options.preventSave
 * @return {function}
 */
function setHistory (options) {
	return (dispatch, getState) => {
		dispatch(storeUtils.makeAction(imagesHistoryActionTypes.ACTION_IMAGES_HISTORY__SET_HISTORY, {
			imageId: options.imageId,
			data: options.data,
		}));

		if ( !userSelectors.selectIsAutosaveEnabled(getState()) || true === options.preventSave ) {
			return;
		}

		if ( inQueue ) {
			shouldSaveData = true;
			return;
		}
		inQueue = true;
		shouldSaveData = true;

		if ( isSavingData ) {
			return;
		}

		saveImageData(options, dispatch, getState);
	};
}

function stopSaving () {
	if ( timeoutId ) {
		window.clearTimeout(timeoutId);
		timeoutId = null;
	}
}

export default {
	setHistory,
	stopSaving,
};
