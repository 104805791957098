import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { STATUSES } from './MagnifyingGlassConstants';
import { sharpenImage } from '../../appUtils/filtersUtils';

import './styles/MagnifyingGlassImage.css';


const baseCssClassName = 'magnifying-glass-image';


const noop = () => {};

export default class MagnifyingGlassImage extends PureComponent {
	static propTypes = {
		url: PropTypes.string.isRequired,
		width: PropTypes.number,
		height: PropTypes.number,
		brightness: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		contrast: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		invert: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		sharpen: PropTypes.bool,
		singleImage: PropTypes.bool,
		onStatusChanged: PropTypes.func,
	};

	static defaultProps = {
		width: null,
		height: null,
		brightness: 1,
		contrast: 1,
		invert: 0,
		sharpen: false,
		singleImage: false,
		onStatusChanged: noop,
	};

	constructor (props) {
		super(props);

		this._imageEl = null;
		
		this._cachedSharpenImage = null;

		this.state = {
			status: STATUSES.IN_PROGRESS,
		};

		props.onStatusChanged(STATUSES.IN_PROGRESS);
	}

	componentDidUpdate (prevProps) {
		if ( prevProps.url !== this.props.url ) {
			this._cachedSharpenImage = null;
			this.props.onStatusChanged(STATUSES.IN_PROGRESS);
			this.setState({
				status: STATUSES.IN_PROGRESS,
			});
		}
	}

	_getSharpenImageUrl () {
		if ( this._cachedSharpenImage !== null ) {
			return this._cachedSharpenImage;
		}

		this._cachedSharpenImage = sharpenImage(this._imageEl);

		return this._cachedSharpenImage;
	}

	/**
	 * @param {HTMLImageElement} [el]
	 * @private
	 */
	_handleImageRef = (el) => {
		this._imageEl = el || null;
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleLoad = (event) => {
		if ( event.currentTarget.src !== this.props.url ) {
			return;
		}

		const imageSize = {
			width: event.currentTarget.naturalWidth,
			height: event.currentTarget.naturalHeight,
		};

		this.setState({
			status: STATUSES.LOADED,
		}, () => this.props.onStatusChanged(STATUSES.LOADED, imageSize));
	};

	_handleError = () => {
		this.setState({
			status: STATUSES.ERROR,
		}, () => this.props.onStatusChanged(STATUSES.ERROR));
	};

	render () {
		const style = {
			filter: `brightness(${this.props.brightness}) contrast(${this.props.contrast}) invert(${this.props.invert})`,
			opacity: ( this.state.status === STATUSES.IN_PROGRESS ? 0 : 1 ),
		};

		if ( this.props.width !== null ) {
			style.width = this.props.width;
		}

		if ( this.props.height !== null ) {
			style.height = this.props.height;
		}

		return (
			<div className={baseCssClassName}>
				<img
					className={baseCssClassName}
					src={this.props.url}
					style={this.props.singleImage === false
						? style
						: ({
							...style,
							display: this.props.sharpen === true ? 'none': null,
						})
					}
					alt={''}
					ref={this._handleImageRef}
					crossOrigin={'anonymous'}
					onLoad={this._handleLoad}
					onError={this._handleError}
				/>
				{this.state.status === STATUSES.LOADED && this.props.sharpen === true && (
					<img
						src={this._getSharpenImageUrl()}
						style={{
							...style,
							position: 'absolute',
							inset: 0,
						}}
						alt={''}
						crossOrigin={'anonymous'}
					/>
				)}
			</div>
		);
	}
}
