import lodashIsNumber from 'lodash/isNumber';
import lodashIsString from 'lodash/isString';

export default {
	write: function write(name, value, expires, path, domain, secure, sameSite) {
		var cookie = [];
		cookie.push(name + '=' + encodeURIComponent(value));

		if (lodashIsNumber(expires)) {
			cookie.push('expires=' + new Date(expires).toGMTString());
		}

		if (lodashIsString(path)) {
			cookie.push('path=' + path);
		}

		if (lodashIsString(domain)) {
			cookie.push('domain=' + domain);
		}

		if (secure === true) {
			cookie.push('secure');
		}

		if (sameSite) {
			cookie.push('samesite=' + sameSite);
		}

		document.cookie = cookie.join('; ');
	},

	read: function read(name) {
		var match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
		return (match ? decodeURIComponent(match[3]) : null);
	},

	remove: function remove(name) {
		this.write(name, '', Date.now() - 86400000);
	}
};
