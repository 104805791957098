import React, { PureComponent } from 'react';
import Tootltip from './Tooltip';

import './styles/ControlledTooltip.css';


const baseCssClassName = 'controlled-tooltip';


export default class ControlledTooltip extends PureComponent {
	_el = null;
	
	state = {
		isHovered: false,
	}

	_handleMouseOver = () => {
		this.setState({
			isHovered: true,
		});
	}
	
	_handleMouseOut = () => {
		this.setState({
			isHovered: false,
		});
	}
	
	_handleRef = (el) => {
		this._el = el;
	}
	
	render () {
		return (
			<div
				className={baseCssClassName}
				onMouseOver={this._handleMouseOver}
				onMouseOut={this._handleMouseOut}
				ref={this._handleRef}
			>
				{this.props.children}
				{this.state.isHovered && (
					<Tootltip
						target={this._el}
						isGlobal
					>{this.props.content}</Tootltip>
				)}
			</div>
		);
	}
}
