import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import editorSelectors from '../../../../selectors/editorSelectors';

import { Route } from 'react-router-dom';
import Registry from '../registry';
import ResolverCollectionHoc from './ResolverCollectionHoc';
import ResolverHeader from '../header';
import CollectionPage from './ResolverCollectionPageConnector';
import UserMenu from '../../../../components/Editor/UserMenu';

import './styles/ResolverCollection.css';


const baseCssClassName = 'resolver-collection';
const headerCssClassName = `${baseCssClassName}__header`;
const mainCssClassName = `${baseCssClassName}__main`;


class ResolverCollection extends PureComponent {
	static propTypes = {
		isUserMenuOpened: PropTypes.bool.isRequired,
	};

	render () {
		return (
			<ResolverCollectionHoc>
				<div className={baseCssClassName}>
					<div className={headerCssClassName}>
						<ResolverHeader />
					</div>
					<div className={mainCssClassName}>
						<CollectionPage />
					</div>
				</div>
				{this.props.isUserMenuOpened && <UserMenu />}
				<Route path={'/collections/:collectionId/treatment_plan/registry'} component={Registry} />
			</ResolverCollectionHoc>
		);
	}
}

export default connect(
	(state, { history }) => {
		const editorData = editorSelectors.selectEditor(state);
		return {
			isUserMenuOpened: editorData.isUserMenuOpened,
		};
	},
	() => ({})
)(ResolverCollection);
