export default {
	'buttons.next_history.alt': 'Вернуть изменение',
	'buttons.next_history.text': 'Вернуть',
	'buttons.prev_history.alt': 'Отменить изменение',
	'buttons.prev_history.text': 'Отменить',
	'buttons.next_image.title': 'Следующий снимок',
	'buttons.next_image.text': 'След',
	'buttons.prev_image.alt': 'Предыдущий снимок',
	'buttons.prev_image.text': 'Пред',
	'buttons.first_image.alt': 'Первый снимок',
	'buttons.last_image.alt': 'Последний снимок',
};
