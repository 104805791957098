const ACTION_ANALYSIS__SET_ANALYZED = 'ACTION_ANALYSIS__SET_ANALYZED';
const ACTION_ANALYSIS__UNSET_ANALYZED = 'ACTION_ANALYSIS__UNSET_ANALYZED';
const ACTION_ANALYSIS__IMAGE_ANALYSIS_START = 'ACTION_ANALYSIS__IMAGE_ANALYSIS_START';
const ACTION_ANALYSIS__IMAGE_ANALYSIS_END = 'ACTION_ANALYSIS__IMAGE_ANALYSIS_END';


export default {
	ACTION_ANALYSIS__SET_ANALYZED,
	ACTION_ANALYSIS__UNSET_ANALYZED,
	ACTION_ANALYSIS__IMAGE_ANALYSIS_START,
	ACTION_ANALYSIS__IMAGE_ANALYSIS_END,
};
