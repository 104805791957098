import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashMerge from 'lodash/merge';
import { getRuntimeConfig, setRuntimeConfig } from '../../../../appUtils/runtimeConfig';

import commonUtils from '../../../../appUtils/common';

import editorActions from '../../../../actions/editorActions';
import collectionsActions from '../../../../actions/collectionsActions';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import Loading from '../../../../components/Loading';
import ReportImageHoc from './ReportImageHoc';


const STATUSES = {
	IDLE: 'idle',
	IN_PROGRESS: 'in-progress',
	DONE: 'done',
	ERROR: 'error',
};

class ResolverReportContainer extends Component {
	static propTypes = {
		isShared: PropTypes.bool.isRequired,
		collectionIdFromParams: PropTypes.string,
		imageIdFromParams: PropTypes.string,

		onGoToLandingPage: PropTypes.func.isRequired,
		onSetConfidencePercent: PropTypes.func.isRequired,
	}

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this.state = {
			status: STATUSES.IDLE,
		};
	}

	UNSAFE_componentWillMount () {
		const config = getRuntimeConfig();
		config.api = lodashMerge(config.api, config.treatment_plan.api);
		setRuntimeConfig(lodashMerge(config));

		if ( this.props.isShared === false ) {
			this._loadCollection();
		}
		else {
			this.setState({
				status: STATUSES.DONE,
			});
		}
	}

	componentWillUnmount () {
		this.state = null;
	}

	_loadCollection () {
		const hashName = this.props.collectionIdFromParams;

		if ( this.props.onIsSyncedCollection(hashName) ) {
			this.setState({
				status: STATUSES.DONE,
			});
			this.props.onSetCurrentCollection({ collection: hashName });
			return;
		}

		this.setState({
			status: STATUSES.IN_PROGRESS,
		});

		this.props.onGetCollection({ hashName, params: { config_format: 'treatment' } })
			.then((collection) => {
				if ( !this.state ) {
					return;
				}

				if ( typeof collection.filter_list === 'object' && collection.filter_list !== null ) {
					this.props.onSetConfidencePercent(collection.filter_list.threshold || 0)
				}

				this.setState({
					status: STATUSES.DONE,
				});
			})
			.catch(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					status: STATUSES.ERROR,
				});
			});
	}

	render () {
		if ( this.state.status === STATUSES.IDLE || this.state.status === STATUSES.IN_PROGRESS ) {
			return (<Loading />);
		}
		if ( this.state.status === STATUSES.ERROR ) {
			return (
				<div>An error occurred. Please try again</div>
			);
		}

		return (
			<ReportImageHoc
				collectionId={this.props.isShared === false ? this.props.collectionIdFromParams : null}
				imageId={this.props.imageIdFromParams}
				isShared={this.props.isShared}
			/>
		);
	}
}

export default connect((state, props) => {
	return {
		isShared: Boolean(props.match.params.collectionId) === false,
		collectionIdFromParams: props.match.params.collectionId,
		imageIdFromParams: props.match.params.imageId,
		onIsSyncedCollection: (id) => collectionsSelectors.selectIsSyncedCollection(state, { id }) ,
	}
}, (dispatch) => ({
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
	onGetCollection: (data) => dispatch(collectionsActions.getCollection(data)),
	onSetCurrentCollection: (data) => dispatch(collectionsActions.setCurrentCollection(data)),
	onSetConfidencePercent: (value) => dispatch(editorActions.updateData({
		data: {
			filteredConfidencePercent: value,
		},
	})),
}))(ResolverReportContainer);
