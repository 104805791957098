import React from 'react';
import PropTypes from 'prop-types';
import lodashValues from 'lodash/values';
import classnames from 'classnames';

import './styles/FormLabel.css';


const baseCssClassName = 'form-label';
const textPosition = {
	TOP: 'top',
	LEFT: 'left',
	RIGHT: 'right',
	BOTTOM: 'bottom',
};
const propTypes = {
	element: PropTypes.node.isRequired,
	textPosition: PropTypes.oneOf(lodashValues(textPosition)).isRequired,
	text: PropTypes.node,
};


function FormLabel(props) {
	const {
		text,
		element,
		textPosition,
		labelWrapperStyle,
		disableSpacing
	} = props;

	return (
		<label
			className={classnames(
				`${baseCssClassName}`,
				`${baseCssClassName}__m-${textPosition}`,
				disableSpacing && `${baseCssClassName}__m-no-padding`
			)}>
			<div style={labelWrapperStyle} className={classnames(`${baseCssClassName}__wrapper-element`, `${baseCssClassName}__wrapper-element__m-${textPosition}`)}>
				{element}
			</div>
			{text && (
				<div className={classnames(`${baseCssClassName}__wrapper-label-text`, `${baseCssClassName}__wrapper-label-text__m-${textPosition}`)}>
					{text}
				</div>
			)}
		</label>
	);
};
FormLabel.propTypes = propTypes;
FormLabel.textPosition = textPosition;
FormLabel.defaultProps = {
	disableSpacing: false,
};

export default FormLabel;
