import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles/ResolverImageNotification.css';


const TYPES = {
	NORMAL: 'normal',
	ERROR: 'error',
};

const baseCssClassName = 'resolver-image-notification';
const contentCssClassName = `${baseCssClassName}__content`;
const closeCssClassName = `${baseCssClassName}__close`;
const closeButtonCssClassName = `${closeCssClassName}-button`;


export default class ResolverImageNotification extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		type: PropTypes.node.isRequired,
		onClose: PropTypes.func,
	};

	static defaultProps = {
		type: TYPES.NORMAL,
	};

	static TYPES = TYPES;
	
	render () {
		return (
			<div className={`${baseCssClassName} ${baseCssClassName}__m-type-${this.props.type}`}>
				<div className={contentCssClassName}>{this.props.children}</div>
				{typeof this.props.onClose === 'function' && (
					<div className={closeCssClassName}>
						<div className={closeButtonCssClassName} onClick={this.props.onClose} />
					</div>
				)}
			</div>
		);
	}
}
