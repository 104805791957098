import apiUtils from '../appUtils/apiUtils';
import commonUtils from '../appUtils/common';

import api from './instance';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';


function getOverallStats () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.stats['overall/read']);

	return api[apiParams.method](apiParams.url);
}

function getUsers () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.users.list);

	return api[apiParams.method](apiParams.url);
}

function getUserStats ({ userId }) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.stats['overall/read']);

	return api[apiParams.method](`${apiParams.url}?username=${userId}`);
}


function getProviders () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls['providers/get']);

	return api[apiParams.method](apiParams.url);
}

/**
 *
 * @param {string} [code]
 * @return {*}
 */
function getAppointments (code, dateFrom, dateTo, provider, codes, findings, pageSize = 20) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls['appointments/get']);
	let url = apiParams.url;
	if ( typeof code !== 'undefined' ) {
		url += '/' + code;
	}

	if ( url[url.length - 1] !== '/' ) {
		url += '/';
	}

	if ( typeof dateFrom === 'string' ) {
		url = commonUtils.addParamToUrl(url, 'date_from', dateFrom);
	}

	if ( typeof dateTo === 'string' ) {
		url = commonUtils.addParamToUrl(url, 'date_to', dateTo);
	}

	if ( typeof provider === 'string' ) {
		url = commonUtils.addParamToUrl(url, 'provider', provider);
	}

	if ( Array.isArray(codes) ) {
		codes.forEach((code) => {
			url = commonUtils.addParamToUrl(url, 'ops[]', code);
		});
	}

	if ( Array.isArray(findings) && findings.length > 0 ) {
		url = commonUtils.addParamToUrl(url, 'findings', findings.join(','));
	}

	return api[apiParams.method](url, { params: { page_size: pageSize } });
}

export default {
	getOverallStats,
	getUsers,
	getUserStats,
	getProviders,
	getAppointments,
};
