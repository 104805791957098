import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { message } from '../../services/popup';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import Alignment from '../Alignment';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';
import Overlay from '../Overlay';
import Popup from '../Popup';

import './styles/Feedback.css';


const theme = createMuiTheme({
	typography: {
		fontSize: 13,
	},
	palette: {
		primary: green,
		type: 'dark', // Switching the dark mode on is a single property value change.
	},
});

const STEPS = {
	VOTE: 'vote',
	COMMENT: 'comment',
};

const baseCssClassName = 'feedback';
const voteCssClassName = `${baseCssClassName}-vote`;
const voteTextCssClassName = `${voteCssClassName}__text`;
const votesButtonCssClassName = `${voteCssClassName}__votes`;
const voteButtonCssClassName = `${voteCssClassName}__vote-button`;
const commentCssClassName = `${baseCssClassName}-comment`;
const commentContentCssClassName = `${commentCssClassName}__content`;
const commentButtonsCssClassName = `${commentCssClassName}__buttons`;
const commentButtonCssClassName = `${commentCssClassName}__button`;
const commentLabelCssClassName = `${commentCssClassName}__label`;
const commentInputCssClassName = `${commentCssClassName}__input`;


export default class Feedback extends PureComponent {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		onSendFeedback: PropTypes.func.isRequired,
	};
	
	state = {
		step: STEPS.VOTE,
		isInProgress: false,
		comment: '',
	};
	
	componentWillUnmount () {
		this.state = null;
	}
	
	async setAnswer () {
		try {
			this.setState({
				isInProgress: true,
			});
			await this.props.onSendFeedback({
				is_positive: this.state.vote === 'like',
				comment: this.state.comment,
			});
			
			if ( !this.state ) {
				return;
			}
			
			this.setState({
				isInProgress: false,
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isInProgress: false,
			});

			throw error;
		}
	}

	showError () {
		message({
			title: 'Error',
			titleIcon: 'error',
			message: 'An error occurred when sending feedback. Please try again.',
		});
	}

	_handleCommentChanged = (event) => {
		this.setState({
			comment: event.currentTarget.value,
		})
	};
	
	_handleClose = () => {
		if ( this.state.isInProgress ) {
			return;
		}
		
		this.props.onClose();
	};

	_handleLikeClick = () => {
		this.setState({
			vote: 'like',
			step: STEPS.COMMENT,
		}, async () => {
			try {
				await this.setAnswer();
			}
			catch (error) {
				this.showError();
			}
		});

	};

	_handleDislikeClick = () => {
		this.setState({
			vote: 'dislike',
			step: STEPS.COMMENT,
		}, async () => {
			try {
				await this.setAnswer();
			}
			catch (error) {
				this.showError();
			}
		});
	};

	_handleSendComment = async () => {
		try {
			await this.setAnswer();
			this.props.onClose();
		}
		catch (error) {
			this.showError();
		}
	};

	_renderStep () {
		switch (this.state.step) {
			case STEPS.VOTE:
				return (
					<div className={voteCssClassName}>
						<div className={voteTextCssClassName}>
							Satisfied with this image’s analysis?
						</div>
						<div className={votesButtonCssClassName}>
							<div
								className={`${voteButtonCssClassName} ${voteButtonCssClassName}__m-like`}
								onClick={this._handleLikeClick}
							/>
							<div
								className={`${voteButtonCssClassName} ${voteButtonCssClassName}__m-dislike`}
								onClick={this._handleDislikeClick}
							/>
						</div>
					</div>
				);

			case STEPS.COMMENT:
				return (
					<div className={commentCssClassName}>
						<div className={commentContentCssClassName}>
							<TextField
								id={'comment'}
								InputLabelProps={{
									shrink: true,
									className: commentLabelCssClassName,
								}}
								InputProps={{
									classes: { input: commentInputCssClassName },
								}}
								name={'comment'}
								value={this.state.comment}
								placeholder={'Optional Comment'}
								fullWidth
								onChange={this._handleCommentChanged}
							/>
						</div>
						<div className={commentButtonsCssClassName}>
							<div
								className={`${commentButtonCssClassName} ${commentButtonCssClassName}__m-agree`}
								onClick={this._handleSendComment}
							/>
							<div
								className={`${commentButtonCssClassName} ${commentButtonCssClassName}__m-disagree`}
								onClick={this._handleClose}
							/>
						</div>
					</div>
				);

			default:
				return null;
		}
	}

	render () {
		return (
			<MuiThemeProvider theme={theme}>
				<div className={baseCssClassName}>
					{this._renderStep()}
				</div>
			</MuiThemeProvider>
		)
	}
}
