export default {
	'name': 'Collection\'s name',
	'description': 'Description',
	'owner': 'Owner',

	'buttons.open_last_image': 'Open last image',
	'buttons.upload_image': 'Upload image',

	'error.set-as-default.general': 'An error occurred when make default collection. Please try again.',
	'error.switch-share.general': 'An error occurred when sharing/unsharing collection. Please try again.',
	'error.reset_annotations.general': 'An error occurred during annotations reset. Please try again.',

	'menu.more': 'More',
	'menu.edit': 'Edit',
	'menu.make_default': 'Make default',
	'menu.remove': 'Remove',
	'menu.copy': 'Copy',
	'menu.share': 'Share',
	'menu.unshare': 'Unshare',
	'menu.reset_annotations': 'Reset annotations',

	'header': 'created {date} for {user}',

	'badges.num-images': 'Images: {num}',
	'badges.default': 'Default',
	'badges.deleted': 'Deleted',
};
