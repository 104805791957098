import storeUtils from '../appUtils/storeUtils';
import commonApi from '../api/common';
import commonActionTypes from './commonActionTypes';


function getCountries () {
	return (dispatch) => {
		return commonApi.getCountries()
			.then((data) => {
				dispatch(storeUtils.makeAction(commonActionTypes.ACTION_COUNTRIES__SET_DATA, data));
			})
	};
}
function getAllProcedures () {
	const PAGE_SIZE = 1000;
	const PAGE_NUMBER = 1;

	return (dispatch) => {
		return commonApi.getProcedures(PAGE_NUMBER, PAGE_SIZE)
			.then((data) => {
				dispatch(storeUtils.makeAction(commonActionTypes.ACTION_PROCEDURES__SET_DATA, data));
			})
	};
}


export default {
	getCountries,
	getAllProcedures,
};
