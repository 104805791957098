import React from 'react';
import lodashGet from 'lodash/get';
import { LOADING_STATUS } from '../../config';
import PropTypes from 'prop-types';
import FormCustomSelect from '../../../../components/FormCustomSelect';
import HuddleInput, {
	HuddleInputArrow,
	HuddleInputContent,
	HuddleInputItem,
	HuddleInputTxCode,
} from '../../../../modules/huddle/ui/input';
import HuddleDatepicker from '../../../../modules/huddle/ui/datepicker';
import momentUtils from '../../../../appUtils/momentUtils';
import FormCheckbox from '../../../../components/FormCheckbox';
import { TileFilterCounter } from '../../../../modules/huddle/ui/tile-filter';
import HuddleGrid from '../../../../modules/huddle/ui/grid';
import Popup from '../../../../components/Popup';
import Loading from '../../../../components/Loading';
import Slider from '../../../../components/Slider';

import './styles/HuddleLayout.css';


const baseCssClassName = 'ui-huddle-layout';
const topRowCssClassName = `${baseCssClassName}__top-row`;
const filtersCssClassName = `${baseCssClassName}__filters`;
const filterCssClassName = `${baseCssClassName}__filter`;
const patientsCssClassName = `${baseCssClassName}__patients`;
const cardsCssClassName = `${baseCssClassName}__cards`;
const itemsCssClassName = `${baseCssClassName}__items`;
const emptyCssClassName = `${baseCssClassName}__empty`;
const sliderHeaderCssClassName = `${baseCssClassName}__slider-header`;

const HuddleLayout = ({
	filter,
	appointments,
	procedures,
	providers,
	loadingStatus,
	setFilter,
	onPeriodontitisChange,
	onTxCodeChange,
	onCariesChange,
	onProviderChange,
	onDuePeriochartChange,
	onDueRadiographChange,
}) => {
	const patientsNum = (new Set(appointments.map((appointment) => appointment.patient_name))).size;
	const num = appointments.reduce((result, item) => {
		result.caries += (item.last_visit_summary || []).reduce((result, summary) => {
			if ( summary.class_id !== 'caries' ) {
				return result;
			}

			return result + summary.items.length;
		}, 0);
		result.periodontitis += (item.last_visit_summary || []).reduce((result, summary) => {
			if ( summary.class_id !== 'periodontitis' ) {
				return result;
			}

			return result + summary.items.length;
		}, 0);
		result.due_periochart += (item.last_visit_summary || []).reduce((result, summary) => {
			if ( summary.type !== 'due_periochart' ) {
				return result;
			}

			return result + 1;
		}, 0);
		result.due_radiograph += (item.last_visit_summary || []).reduce((result, summary) => {
			if ( summary.type !== 'due_radiograph' ) {
				return result;
			}

			return result + 1;
		}, 0);
		// result.caries += lodashGet(item, 'last_examination_summary.Caries', 0);
		// result.periodontitis += lodashGet(item, 'last_examination_summary.Apical lesions', 0);

		return result;
	}, { caries: 0, periodontitis: 0, due_periochart: 0, due_radiograph: 0 });

	return (
		<div className={baseCssClassName}>
			<div className={topRowCssClassName}>
				<div className={patientsCssClassName}>
					{patientsNum === 1 ? '1 Patient' : `${patientsNum} Patients`}
				</div>
				<div className={filtersCssClassName}>
					<div className={filterCssClassName}>
						<FormCustomSelect
							items={[ { value: '-1', label: 'All' } ].concat(providers.map((provider) => ({
								value: provider.provider_code,
								// label: provider.provider_name,
								label: provider.provider_code,
							})))}
							className={'ui-huddle-input-bg'}
							headerCssClassName={'ui-huddle-input-header '}
							itemsCssClassName={'ui-huddle-input-items'}
							value={filter.provider}
							onRenderHeader={({ isOpened }) => {
								const provider = providers.find((provider) => provider.provider_code === filter.provider);
								return (
									<HuddleInput active={isOpened}>
										<HuddleInputContent>{typeof provider !== 'undefined' ? provider.provider_code : 'Select provider'}</HuddleInputContent>
										<HuddleInputArrow />
									</HuddleInput>
								);
							}}
							onRenderItem={(item) => {
								return (
									<HuddleInputItem disabled={item.disabled} selected={filter.provider === item.value}>
										{item.label}
									</HuddleInputItem>
								);
							}}
							onChange={onProviderChange}
						/>
					</div>
					<div className={filterCssClassName}>
						<HuddleDatepicker
							date={filter.date}
							onDayChange={(date) => {
								setFilter((prevFilter) => ({
									...prevFilter,
									date: momentUtils.getMomentForDate({ date })
										.unix() * 1000,
								}))
							}}
						/>
					</div>
					{/*<div className={filterCssClassName}>
						<FormCustomSelect
							items={procedures.map((procedure) => ({
								value: procedure.code,
								// label: provider.provider_name,
								label: procedure.description,
							}))}
							className={'ui-huddle-input-bg'}
							headerCssClassName={'ui-huddle-input-header '}
							itemsCssClassName={'ui-huddle-input-items'}
							closeAfterSelection={false}
							onRenderHeader={({ isOpened }) => {
								return (
									<HuddleInput active={isOpened}>
										<HuddleInputContent>
											{filter.txCodes.length > 0
												? filter.txCodes.length > 3
													? `${filter.txCodes.length} selected`
													: filter.txCodes.map((code) => (<HuddleInputTxCode code={code} />))
												:'Tx Codes'}
										</HuddleInputContent>
										<HuddleInputArrow />
									</HuddleInput>
								);
							}}
							onRenderItem={(item) => {
								return (
									<HuddleInputItem disabled={item.disabled}>
										<FormCheckbox inputProps={{ checked: filter.txCodes.includes(item.value) }} /><div style={{ width: 8 }} /><HuddleInputTxCode code={item.value} /> {item.label}
									</HuddleInputItem>
								);
							}}
							onChange={onTxCodeChange}
						/>
					</div>*/}
					<div className={filterCssClassName}>
						<div className={sliderHeaderCssClassName}>
							<div style={{ flex: '1 1 auto', marginBottom: 5 }}>
								Confidence level
							</div>
							<div style={{ width: 60, textAlign: 'right' }}>
								>= {filter.confidence}%
							</div>
						</div>
						<Slider
							value={filter.confidence}
							min={0}
							max={100}
							wrapperStyle={{
								height: 17,
							}}
							railStyle={{
								background: '#999',
								borderRadius: 1,
								height: 2,
							}}
							handleStyle={{
								background: '#FFF',
								width: 16,
								height: 16,
							}}
							trackStyle={{
								background: '#383B47',
								height: 2,
							}}
							onChange={(percent) => {
								setFilter((prevFilter) => ({
									...prevFilter,
									confidence: percent,
								}));
							}}
						/>
					</div>
				</div>
			</div>
			<div className={cardsCssClassName}>
				<div style={{ flex: '1 1 auto', marginRight: 10 }}>
					<TileFilterCounter
						count={num.due_radiograph}
						text={'Due for radiograph (Bitewing, Pano)'}
						isActive={filter.due_radiograph}
						onChange={onDueRadiographChange}
					/>
				</div>
				<div style={{ flex: '1 1 auto', marginRight: 10 }}>
					<TileFilterCounter
						count={num.due_periochart}
						text={'Due for Perio Chart'}
						isActive={filter.due_periochart}
						onChange={onDuePeriochartChange}
					/>
				</div>
				<div style={{ flex: '1 1 auto', marginRight: 10 }}>
					<TileFilterCounter
						count={num.periodontitis}
						text={'Potential Periodontal Condition'}
						isActive={filter.periodontitis}
						onChange={onPeriodontitisChange}
					/>
				</div>
				<div style={{ flex: '1 1 auto' }}>
					<TileFilterCounter
						count={num.caries}
						text={'Potential Caries'}
						isActive={filter.caries}
						onChange={onCariesChange}
					/>
				</div>
			</div>
			<div className={itemsCssClassName}>
				{appointments.length > 0 && (
					<HuddleGrid
						data={appointments}
					/>
				)}
				{appointments.length === 0 && (
					<div className={emptyCssClassName}>There no appointments found</div>
				)}
			</div>
			{loadingStatus === LOADING_STATUS.IN_PROGRESS && (
				<Popup isGlobal>
					<Loading />
				</Popup>
			)}
		</div>
	);
};

HuddleLayout.propTypes = {
	filter: PropTypes.object.isRequired,
	appointments: PropTypes.arrayOf(PropTypes.object).isRequired,
	procedures: PropTypes.arrayOf(PropTypes.object).isRequired,
	providers: PropTypes.arrayOf(PropTypes.object).isRequired,
	loadingStatus: PropTypes.string.isRequired,
	onProviderChange: PropTypes.func.isRequired,
	setFilter: PropTypes.func.isRequired,
	onTxCodeChange: PropTypes.func.isRequired,
	onPeriodontitisChange: PropTypes.func.isRequired,
	onCariesChange: PropTypes.func.isRequired,
	onDuePeriochartChange: PropTypes.func.isRequired,
	onDueRadiographChange: PropTypes.func.isRequired,
};

export default HuddleLayout;
