import when from 'when';

import api from './instance';
import apiUtils from '../appUtils/apiUtils';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';

import collectionApi from './collectionApi';


const ERROR_PREFIX = 'api/common';

/**
 * Loads collection image data.
 *
 * @returns {Promise.<Object>}
 */
export function loadCollectionsData () {
	return when.all([
		collectionApi.getList(),
	])
		.then(([
			collectionList,
		]) => {
			return {
				collectionList,
			};

		})
		.catch((err) => {
			const error = new Error(`${ERROR_PREFIX}#loadData: Loading collections data failed.`);
			error.inner = err;

			throw error;
		});
}

function getCountries () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.countries);
	return api[apiParams.method](apiParams.url);
}

function getProcedures (page, page_size) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.procedures);
	return api[apiParams.method](apiParams.url, {params:{ page, page_size }});
}

function getSensors () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls['sensors/get']);
	return api[apiParams.method](apiParams.url);
}


export default {
	getCountries,
	getProcedures,
	getSensors,
};
