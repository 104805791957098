import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { trackEvent } from '../../../../integrations/mixpanel';

import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';


class TfvLayoutWrapper extends PureComponent {
	static propTypes = {
		toothKey: PropTypes.string.isRequired,
		currentExamination: PropTypes.string.isRequired,
		selectedToothKey: PropTypes.string.isRequired,
		onSelectToothKey: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);
		props.onSelectToothKey(this.props.toothKey);
		this._track(props);
	}

	componentWillReceiveProps (nextProps) {
		if ( nextProps.selectedToothKey !== nextProps.toothKey ) {
			if ( nextProps.selectedToothKey === null ) {
				nextProps.onSelectToothKey(nextProps.toothKey);
			}
		}

		if ( nextProps.selectedToothKey !== nextProps.toothKey || nextProps.currentExamination !== this.props.currentExamination ) {
			this._track(nextProps);
		}
	}

	_track (props) {
		trackEvent('TFV Open', { toothKey: props.toothKey, visit: props.currentExamination });
	}

	render () {
		return this.props.children;
	}
}



export default connect(
	(state) => {
		const editorData =  editorSelectors.selectEditor(state);
		return {
			selectedToothKey: editorData.selectedToothKey,
			currentExamination: editorData.currentExamination,
		};
	},
	(dispatch) => ({
		onSelectToothKey: (toothKey) => dispatch(editorActions.selectToothKey({ toothKey })),
	}),
)(TfvLayoutWrapper);
