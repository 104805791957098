import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getDictionary } from '../../appUtils/locale';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CollectionCard from '../CollectionCard';

import EditCollectionForm from '../EditCollection/EditCollectionForm';
import DeleteCollectionForm from '../DeleteCollectionForm';
import CopyCollectionForm from '../CopyCollectionForm';



const i18n = getDictionary('collections');

const noCollectionsClass = 'collections__no-items';


const styles = theme => ({
	root: {
		width: '100%',
		flexWrap: 'wrap',
		backgroundColor: '#1b1d24',
	},
	flex: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	gridList: {
		width: "100%",
		minWidth: 812,
		margin: 0,
        boxSizing: 'border-box',
		padding: window.innerWidth < 1600 ? '0 0' : '0 5%',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)',
	},
	titleBar: {
		background:
			'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
			'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	icon: {
		color: 'white',
	},
});

class AdvancedGridList extends React.Component {
	state = {
		isShowCollectionEditForm: false,
		isShowDeleteConfirm: false,
		isShowCopyCollection: false,
		isShowCopyCollectionForm: false,
		name: null,
		summary: null,
		hashname: null,
		isDefault: false
	}

	_handleEditClick = (data = {}) => {
		this.setState({
			isShowCollectionEditForm: true,
			name: data.name,
			summary: data.summary,
			hashname: data.hashname,
		});
	}

	_handleRemoveClick = (data = {}) => {
		this.setState({
			isShowDeleteConfirm: true,
			hashname: data.hashname,
		})
	}

	_handleOpenCopyCollection = (data) => {
		this.setState({
			isShowCopyCollectionForm: true,
			hashname: data.hashname,
			collectionName: data.name,
			collectionDescription: data.description,
		});
	}

	_handleClose = () => {
		this.setState({
			isShowCollectionEditForm: false,
			isShowDeleteConfirm: false,
			isShowCopyCollectionForm: false,
			name: null,
			summary: null,
			hashname: null,
			isDefault: false
		})
	}

	render() {
		const {
			classes,
			collections,
		} = this.props;

		const {
			isShowCollectionEditForm,
			isShowDeleteConfirm,
			isShowCopyCollectionForm,
			name,
			summary,
			hashname,
			collectionName,
			collectionDescription,
		} = this.state;

		return (
			collections.length > 0
				? (
					<div className={classes.root}>
						<div className={classes.flex}>
							<GridList cellHeight={'16vw'} spacing={0} className={classes.gridList} cols={3}>
								{collections.map((collection, index) => (
									<GridListTile key={collection.hashname} cols={1} rows={1}>
										<CollectionCard
											collection={collection}
											key={collection.hashname}
											handleEditClick={this._handleEditClick}
											handleRemoveClick={this._handleRemoveClick}
											onOpenCopyCollection={this._handleOpenCopyCollection}
										/>
									</GridListTile>
								))}
							</GridList>
						</div>
						{ isShowCollectionEditForm && (
							<EditCollectionForm
								isEdit={true}
								hashname={hashname}
								name={name}
								summary={summary}
								onClose={this._handleClose}
							/>
						) }
						{ isShowDeleteConfirm && (
							<DeleteCollectionForm
								isEdit={true}
								hashname={hashname}
								name={name}
								summary={summary}
								onClose={this._handleClose}
							/>
						) }
						{ isShowCopyCollectionForm && (
							<CopyCollectionForm
								hashname={hashname}
								name={collectionName}
								description={collectionDescription}
								onClose={this._handleClose}
							/>
						) }
					</div>
				)
				: (<div className={noCollectionsClass}>{i18n('no_collections')} </div>)
		);
	}
}

AdvancedGridList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(null, () => ({}))(withStyles(styles)(AdvancedGridList));
