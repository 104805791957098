const ACTION_COLLECTIONS__SET_COLLECTIONS = 'ACTION_COLLECTIONS__SET_COLLECTIONS';
const ACTION_COLLECTIONS__MARK_AS_SYNCED = 'ACTION_COLLECTIONS__MARK_AS_SYNCED';
const ACTION_COLLECTIONS__SET_CURRENT_COLLECTION = 'ACTION_COLLECTIONS__SET_CURRENT_COLLECTION';
const ACTION_COLLECTIONS__UPDATE_COLLECTION = 'ACTION_COLLECTIONS__UPDATE_COLLECTION';


export default {
	ACTION_COLLECTIONS__SET_COLLECTIONS,
	ACTION_COLLECTIONS__MARK_AS_SYNCED,
	ACTION_COLLECTIONS__SET_CURRENT_COLLECTION,
	ACTION_COLLECTIONS__UPDATE_COLLECTION,
};
