export default {
	'dialog.remove_tooth.title': '删除选区牙齿',
	'dialog.remove_tooth.confirm': '您确定需要永久删除选区牙齿？',
	'dialog.remove_all.title': '删除所有病理和非病理的发现',
	'dialog.remove_all.confirm': '是否确认永久删除所有病理和非病理的发现',

	'buttons.show_all': '显示所有病理和非病理的发现',
	'buttons.show_less': '减少显示',

	'confirm_all.alt': '确认所有病理和非病理的发现',
	'confirm_all.text': '确认所有',

	'remove_all.alt': '删除所有病理和非病理的发现',
	'remove_all.text': '删除所有',

	'iso': '国际标准化系统',
	'uni': '通用记录法',

	'labels.caries': '龋齿',
	'labels.periodontitis': '根尖牙周炎',
	'labels.crown': '牙冠',
	'labels.implant': '种植牙/牙移植',
	'labels.bridge': '牙桥',
	'labels.x': '未知',
	'labels.md': '(正中)额外牙',
	'labels.endodontic_treatment': '牙(齿)髓治疗',
	'labels.restoration': '牙齿修复（间接/直接）',
	'labels.apical_perforation': '牙根穿孔',
	'labels.overfilling': '根部充填过度/充填突出/堵塞',
	'labels.underfilling': '充填不足',
	'labels.ledge': '牙质楔入/凿出',
	'labels.void': '牙空缺/牙空洞',
	'labels.other': '其他',
	'labels.caries_proximal': 'Caries Proximal',
	'labels.caries_non_proximal': 'Caries Non-proximal',
};
