import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import imageUtils from '../../../../appUtils/imageUtils';
import { isSequentialModeEnabledForVisit } from '../../../resolver/utils';

import editorActions from '../../../../actions/editorActions';
import imagesActions from '../../../../actions/imagesActions';
import { changeFilters, analyzeImage } from '../../../resolver/actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';

import TfvMainLayout from './TfvMainLayout';


export default withRouter(connect(
	(state, { history }) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const currentCollectionId = editorData.currentCollectionHashName;
		const collection = collectionsSelectors.selectCollectionById(state, { id: currentCollectionId });
		const user = userSelectors.selectUserData(state);
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
		const areHeatMapsFromProfileEnabled = userSelectors.selectAreHeatMapsEnabled(state);
		const findingsFilter = editorData.treatmentPlanFilters;
		return {
			currentCollection: collection,
			isUserMenuOpened: editorData.isUserMenuOpened,
			isSimpleUiEnabled: user.is_simple_ui_enabled === true,
			isSequentialModeEnabled,
			currentImageIsAnalyzing: editorData.currentImageIsAnalyzing,
			areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
			areFindingsMasksFromProfileEnabled: areHeatMapsFromProfileEnabled === false && userSelectors.selectAreFindingsMasksEnabled(state),
			areHeatMapsFromProfileEnabled,
			areHeatMapsEnabled: editorData.areHeatMapsEnabled,
			teethConflicts: editorData.teethConflicts,
			shouldNotifyThatPatientTooYoung: editorData.currentExamination
				? true === collection.images
				.filter((image) => image.examination === editorData.currentExamination)
				.some((image) => imageUtils.isImagePatientOlder(image) === false)
				: imageUtils.isImagePatientOlder(currentImage) === false,
			findingsFilter,
			onGoToFmx: () => {
				history.push(`/collections/${currentCollectionId}/image/${currentImage.hashname}/fmx`);
			},
			onGoToTfv: () => {
				history.push(`/collections/${currentCollectionId}/image/${currentImage.hashname}/treatment_plan`);
			},
		};
	},
	(dispatch) => ({
		onSetFindingsMasks: (value) => dispatch(editorActions.setFindingsMasks(value)),
		onFindingsFilterChange: (filters) => dispatch(changeFilters(filters)),
		onSaveImageData: (options) => dispatch(imagesActions.saveImageData(options)),
		onAnalyzeImage: () => dispatch(analyzeImage()),
		onSetHeatMap: (value) => dispatch(editorActions.updateData({
			data: {
				areHeatMapsEnabled: value,
			},
		})),
		onSetInitialFilters: (currentCollection, isSequentialModeEnabled) => {
			if ( isSequentialModeEnabled === true ) {
				dispatch(editorActions.updateData({
					data: {
						treatmentPlanFilters: {
							pathological: false,
							treatment: true,
							existing: false,
						},
					},
				}));
			}
			else {
				if ( typeof currentCollection.filter_list === 'object' && currentCollection.filter_list !== null ) {
					dispatch(editorActions.updateData({
						data: {
							treatmentPlanFilters: (currentCollection.filter_list.export_classes || [])
								.filter((className) => className !== 'perio')
								.reduce((result, classId) => {
									result[classId] = true;
									return result;
								}, {}),
						},
					}));
				}
			}
		},
	})
)(TfvMainLayout));
