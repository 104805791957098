import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
} from '../../../../constants/imageConstants';

import teethUtils from '../../../../appUtils/teeth/teethUtils';
import labelsUtils from '../../../../appUtils/labelsUtils';

import editorActions from '../../../../actions/editorActions';
import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import { getTitleRenderer, sortImages } from './utils/NavigationUtils';

import ResolverNavigation from './Navigation';


export default withRouter(connect(
	(state, props) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const images = currentCollectionSelectors.selectExaminationImages(state, { examination: editorData.currentExamination });
		const imageTeeth = labelsUtils.getTeethInfo({ state });
		const teeth = teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth());
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const imagesTeeth = labelsSelectors.selectTeeth(state);

		const imagesPAN = [];
		const imagesBW = [];
		const imagesPA = [];
		const imagesOthers = [];

		images.forEach((image) => {
			if ( image.image_type === IMAGE_TYPE__PAN ) {
				imagesPAN.push(image);
			}
			else if ( image.image_type === IMAGE_TYPE__BITEWING ) {
				imagesBW.push(image);
			}
			else if ( image.image_type === IMAGE_TYPE__PERIAPICAL ) {
				imagesPA.push(image);
			}
			else {
				imagesOthers.push(image);
			}
		});

		// Prepare titles
		let imagesTitles = images.reduce(getTitleRenderer({ imageTeeth, teeth, notationType }), {});

		const imageSort = sortImages({
			teeth: teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()),
			imageTeeth,
			imagesTeeth,
		});

		imagesBW.sort(imageSort);
		imagesPA.sort(imageSort);

		return {
			collection: collectionsSelectors.selectCollectionById(state, {
				id: editorData.currentCollectionHashName,
			}),
			image: currentImage,
			shouldDisplayExaminations: null !== editorData.currentExamination,
			shouldShowThumbnailsGallery: (
				typeof props.shouldShowThumbnailsGallery === 'boolean'
					? props.shouldShowThumbnailsGallery !== false
					: editorData.showThumbnailsGallery === true && editorData.isFmxModeEnabled === false
			),
			examinationImages: [
				...imagesPAN,
				...imagesBW,
				...imagesPA,
				...imagesOthers,
			],
			currentExamination: editorData.currentExamination,
			availableExaminations: currentCollectionSelectors.selectAvailableExaminations(state)
				.sort((a, b) => a.localeCompare(b, undefined, { numeric: true })),
			imagesTitles,
			isFmxModeEnabled: editorData.isFmxModeEnabled,
			onGetLastOpenedExaminationImage: (examination) => {
				const images = currentCollectionSelectors.selectExaminationImages(state, { examination });
				images.sort((a, b) => b.last_opened_at - a.last_opened_at);
				return images[0];
			},
		};
	},
	(dispatch, { history }) => ({
		onChangeThumbnailsDisplaying: (value) => {
			dispatch(editorActions.updateData({
				data: {
					showThumbnailsGallery: value,
				},
			}));
		},
		onChangeVisit: ({ collection, image, isFmxModeEnabled }) => {
			if ( isFmxModeEnabled ) {
				history.push(`/collections/${collection.hashname}/image/${image.hashname}/fmx`);
				return;
			}
			history.push(`/collections/${collection.hashname}/image/${image.hashname}/treatment_plan`);
		},
	})
)(ResolverNavigation));
