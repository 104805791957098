import _moment from 'moment';
import { parseDate } from 'react-day-picker/moment';


import { locale } from './locale';


function getDefaultTimezone () {
	return _moment().format('zz');
}

function getTimezone (timezone) {
	return ( timezone || getDefaultTimezone() );
}

function getDefaultLocale () {
	return locale();
}

function getLocale (locale) {
	return ( locale || getDefaultLocale() );
}

function moment ({ timezone, locale, args = [] }) {
	return _moment(...args).locale(getLocale(locale));
}

function getNowMoment ({ timezone, locale } = {}) {
	return moment({ timezone, locale });
}

function getTodayDate ({ timezone } = {}) {
	const nowMoment = getNowMoment({ timezone });

	return new Date(nowMoment.year(), nowMoment.month(), nowMoment.date());
}

function isTodayDate ({ date, timezone }) {
	const nowMoment = getNowMoment({ timezone });

	return (
		date.getFullYear() === nowMoment.year() &&
		date.getMonth() === nowMoment.month() &&
		date.getDate() === nowMoment.date()
	);
}

function getDateFromTimestamp ({ timestamp, timezone }) {
	const timestampMoment = moment({ timezone, args: [ timestamp ] });
	return new Date(timestampMoment.get('year'), timestampMoment.get('month'), timestampMoment.get('date'));
}

function getMomentForDate ({ date, timezone, locale }) {
	return moment({ timezone, locale })
		.year(date.getFullYear())
		.month(date.getMonth())
		.date(date.getDate())
		.startOf('day');
}

const momentUtils = {
	getDefaultTimezone,
	getTimezone,
	getDefaultLocale,
	getLocale,
	getNowMoment,
	getTodayDate,
	isTodayDate,
	getDateFromTimestamp,
	getMomentForDate,
	moment,
};

export default momentUtils;
