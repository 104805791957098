import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';

import moment from 'moment';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';
import { locale, setLocaleToCookies } from '../../appUtils/locale';

import mainConfig from '../../configs/mainConfig';
import commonUtils from '../../appUtils/common';
import teethUtils from '../../appUtils/teeth/teethUtils';
import dateUtils from '../../appUtils/dateUtils';
import pathUtils from "../../appUtils/pathUtils";

import reportTeethUtils from './reportTeethUtils';
import reportTeethConfig from './reportTeethConfig';

import imagesActions from '../../actions/imagesActions';
import editorActions from '../../actions/editorActions';
import userActions from '../../actions/userActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import userSelectors from '../../selectors/userSelectors';
import syncWithApiSelectors from '../../selectors/syncWithApiSelectors';

import FormLabel from '../FormLabel';

import arrowDownImagePath from './images/arrow-down.png';
import arrowUpImagePath from './images/arrow-up.png';

import { getDictionary } from '../../appUtils/locale';

import currentCollectionSelectors from "../../selectors/currentCollectionSelectors";
import analyseSelectors from "../../selectors/analyseSelectors";
import imagesLabelsSelectors from "../../modules/labels/selectors/imagesLabelsSelectors";
import labelsSelectors from "../../modules/labels/selectors/labelsSelectors";
import labelsUtils from "../../appUtils/labelsUtils";
import labelGetters from "../../modules/labels/selectors/labelGetters";
import labelTagGetter from "../../modules/label-tags/selectors/labelTagGetter";
import labelTagsSelectors from "../../modules/label-tags/selectors/labelTagsSelectors";
import labelChildrenSelectors from "../../modules/labels/selectors/labelChildrenSelectors";
import labelsTagsSelectors from "../../modules/label-tags/selectors/labelsTagsSelectors";

import './styles/Report.css';
import { message } from "../../services/popup";

const i18n = getDictionary('report');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'report';
const wrapperCssClassName = `${baseCssClassName}-wrapper`;
const logoCssClassName = `${baseCssClassName}-logo`;
const snapshotLinkCssClassName = `${baseCssClassName}__snapshot-link`;
const reportLinkCssClassName = `${baseCssClassName}__report-link`;
const infoCssClassName = `${baseCssClassName}-info`;
const patientCssClassName = `${baseCssClassName}__patient`;
const patientNameCssClassName = `${baseCssClassName}__patient-name`;
const patientInfoCssClassName = `${baseCssClassName}__patient-info`;
const patientInfoItemCssClassName = `${baseCssClassName}__patient-info-item`;
const doctorInfoCssClassName = `${baseCssClassName}-doctor-info`;

const normalAppearanceTitleCssClassName = `${baseCssClassName}__normal-appearance-title`;
const normalAppearanceCssClassName = `${baseCssClassName}__normal-appearance`;
const findingsTitleCssClassName = `${baseCssClassName}__findings-title`;
const findingsCssClassName = `${baseCssClassName}__findings`;
const findingCssClassName = `${baseCssClassName}__finding`;
const findingCommentCssClassName = `${baseCssClassName}__finding-comment`;
const findingToothKeyCssClassName = `${baseCssClassName}__finding-tooth-key`;
const findingDiagnosisCssClassName = `${baseCssClassName}__finding-diagnosis`;
const missingTeethCssClassName = `${baseCssClassName}__missing-teeth`;
const missingTeethTitleCssClassName = `${baseCssClassName}__missing-teeth-title`;

const teethCssClassName = `${baseCssClassName}__teeth`;
const teethRowCssClassName = `${baseCssClassName}__teeth-row`;
const toothCssClassName = `${baseCssClassName}__tooth`;
const teethRowKeysCssClassName = `${baseCssClassName}__teeth-row-keys`;
const teethRowKeysKeyCssClassName = `${baseCssClassName}__teeth-row-keys-key`;

const legendCssClassName = `${baseCssClassName}__legend`;
const legendItemCssClassName = `${baseCssClassName}__legend-item`;
const legendItemTitleCssClassName = `${baseCssClassName}__legend-item-title`;
const statusIndicatorCssClassName = `${baseCssClassName}__status-indicator`;

const preparedCssClassName = `${baseCssClassName}__prepared`;
const preparedHighlightCssClassName = `${baseCssClassName}__prepared-highlight`;
const poweredByCssClassName = `${baseCssClassName}__powered-by`;

const screensCssClassName = `${baseCssClassName}__screens`;
const screenCssClassName = `${baseCssClassName}-screen`;
const screenImageCssClassName = `${baseCssClassName}-screen__image`;
const screenContentCssCLassName = `${baseCssClassName}-screen__content`;
const screenToothKeyCssClassName = `${baseCssClassName}-screen__tooth-key`;
const screenFindingsCssClassName = `${baseCssClassName}-screen__findings`;
const screenFindingCssClassName = `${baseCssClassName}-screen-finding`;

const controlsCssClassName = `${baseCssClassName}-controls`;
const controlsPrintButtonCssClassName = `${controlsCssClassName}__print-button`;

const userInfoCssClassName = `${baseCssClassName}__user-info`;
const reportNameCssClassName = `${baseCssClassName}-name`;
const footerCommentCssClassName = `${baseCssClassName}-footer-comment`;


class ReportPAN extends Component {
	static propTypes = {
		teeth: PropTypes.objectOf(PropTypes.object).isRequired,
		imageId: PropTypes.string.isRequired,
		currentImageId: PropTypes.string,
		currentImage: PropTypes.object,
		isShared: PropTypes.bool.isRequired,
	}

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._screenImage = null;
		this._arrowDownImage = null;
		this._arrowUpImage = null;

		this.state = {
			screenImageLoaded: false,
			arrowUpImageLoaded: false,
			arrowDownImageLoaded: false,
			patient: {
				fullName: '',
				age: '',
				screening_date: '',
			},
			showLinks: ( 'is_report_urls' in props.user
				? props.user.is_report_urls
				: true
			),
		};
	}

	componentDidMount () {
		this._checkImage(this.props);
	}

	componentWillUnmount () {
		this.state = null;
	}

	_handleLocaleChange = (event) => {
		const language = event.target.value;

		if ( this.props.isAuthenticated ) {
			this.props.onUpdateUserProfile({
				data: {
					...this.props.user,
					language,
				},
			})
				.then(() => {
					setLocaleToCookies(language);

					if ( !this.state ) {
						return;
					}

					window.location.reload(true);
				})
				.catch((error) => {
					if ( !this.state ) {
						return;
					}
					
					message({
						title: i18nShared('error.title'),
						titleIcon: 'error',
						message: i18n('errors.language'),
					});
				});
		}
		else {
			setLocaleToCookies(event.target.value);
			window.location.reload(true);
		}
	}

	_handlePatientFullNameChange = (event) => {
		this.setState({
			patient: {
				...this.state.patient,
				fullName: event.currentTarget.value,
			},
		});
	}

	_handlePatientAgeScreeningDateChange = (event) => {
		this.setState({
			patient: {
				...this.state.patient,
				screening_date: event.currentTarget.value,
			},
		});
	}

	_handlePrintButtonClick = () => {
		window.print();
	}

	_handleShowLinksChange = () => {
		this.setState({
			showLinks: !this.state.showLinks,
		});
	}

	_checkImage () {
		this._loadScreenImage();
		this._loadArrowsImage();
	}

	_loadScreenImage () {
		const image = new Image();
		image.src = this.props.currentImage.image_url;
		image.onload = () => {
			this._screenImage = image;
			this.setState({
				screenImageLoaded: true,
			});
		};
	}

	_loadArrowsImage () {
		const arrowDownImage = new Image();
		arrowDownImage.onload = () => {
			this._arrowDownImage = arrowDownImage;
			this.setState({
				arrowDownImageLoaded: true,
			});
		};
		arrowDownImage.src = arrowDownImagePath;

		const arrowUpImage = new Image();
		arrowUpImage.onload = () => {
			this._arrowUpImage = arrowUpImage;
			this.setState({
				arrowUpImageLoaded: true,
			});
		};
		arrowUpImage.src = arrowUpImagePath;
	}

	_renderNormalAppearance() {
		const {
			teeth,
		} = this.props;

		const teethWithBoxes = Object.keys(teeth).reduce((result, toothKey) => {
			const tooth = teeth[toothKey];

			if ( tooth.children || tooth.children.length > 0 ) {
				result.push(toothKey);
			}

			return result;
		}, []);

		const result = teethUtils.getTeeth().reduce((result, toothKey) => {
			if ( teethWithBoxes.indexOf(toothKey) !== -1 ) {
				result.push(teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type }));
			}

			return result;
		}, []);

		if ( result.length === 0 ) {
			return null;
		}

		result.sort((a, b ) => teethUtils.sortTeeth(a, b));

		return (
			<div>
				<div className={normalAppearanceTitleCssClassName}>{i18n('normal_appearance.title')}:</div>
				<div className={normalAppearanceCssClassName}>{result.join(', ')}</div>
			</div>
		);
	}

	_renderTeeth () {
		const {
			teeth,
		} = this.props;

		return (
			<div className={teethCssClassName}>
				<div className={`${teethRowKeysCssClassName} ${teethRowKeysCssClassName}__m-top`}>
					{teethUtils.getOrderedTopTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={teethRowKeysKeyCssClassName}
						>{teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type })}</div>
					))}
				</div>

				<div className={`${teethRowCssClassName} ${teethRowCssClassName}__m-top`}>
					{teethUtils.getOrderedTopTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={`${toothCssClassName} ${toothCssClassName}__m-key-${toothKey}`}
							dangerouslySetInnerHTML={{
								__html: reportTeethUtils.getToothSvg({
									number: toothKey,
									toothData: teeth[toothKey],
								}),
							}}
						/>
					))}
				</div>
				<div className={`${teethRowCssClassName} ${teethRowCssClassName}__m-bottom`}>
					{teethUtils.getOrderedBottomTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={`${toothCssClassName} ${toothCssClassName}__m-key-${toothKey}`}
							dangerouslySetInnerHTML={{
								__html: reportTeethUtils.getToothSvg({
									number: toothKey,
									toothData: teeth[toothKey],
								})
							}}
						/>
					))}
				</div>
				<div className={`${teethRowKeysCssClassName} ${teethRowKeysCssClassName}__m-bottom`}>
					{teethUtils.getOrderedBottomTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={teethRowKeysKeyCssClassName}
						>{teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type })}</div>
					))}
				</div>
			</div>
		);
	}

	_renderFindings() {
		const {
			teeth,
		} = this.props;

		const nodes = [];
		const isShowMeasureOfConfidence = getRuntimeConfig().report.show_measure_of_confidence;

		const notationType = this.props.user.notation_type;
		const sortedTeeth = Object.keys(teeth)
			.map((toothKey) => ({
				toothKey,
				localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
			}))
			.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		sortedTeeth.forEach((toothData) => {
			const tooth = teeth[toothData.toothKey];
			if ( tooth.children && tooth.children.length ) {
				nodes.push((
					<div
						key={toothData.toothKey}
						className={findingCssClassName}
					>
						<div
							className={findingToothKeyCssClassName}
							style={{
								borderColor: labelsUtils.getLabelColor(tooth),
							}}
						>{toothData.localizedToothKey}</div>
						{ tooth.children.map((childLabel, i) => {
							const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);

							return (<div key={i} className={findingDiagnosisCssClassName}> {labelsUtils.getLocalizedLabelName(childLabel)}{ isShowMeasureOfConfidence && typeof measureOfConfidence === 'number' ? ` (${Math.floor(measureOfConfidence * 100)}%)` : null }{( i < ( tooth.children.length - 1) ? ',' : '' )}</div>);
						}) }
						<div className={findingCommentCssClassName}>
							<div className={`${findingCommentCssClassName}__control`}>
								<input type={'text'} placeholder={i18n('comment.placeholder')} />
							</div>
						</div>
					</div>
				));
			}
		});

		if ( nodes.length === 0 ) {
			return null;
		}

		return (
			<div>
				<div className={findingsTitleCssClassName}>{i18n('findings.title')}:</div>
				<div className={findingsCssClassName}>{nodes}</div>
			</div>
		);
	}

	_renderLegend () {
		return (
			<div className={legendCssClassName}>
				{reportTeethConfig.getIndicators().map((indicator) => (
					<div
						key={indicator.key}
						className={legendItemCssClassName}
					>
						<div
							className={statusIndicatorCssClassName}
							dangerouslySetInnerHTML={{
								__html: reportTeethConfig.TEETH_STATUS_INDICATORS[indicator.key],
							}}
						/>
						<div className={legendItemTitleCssClassName}>{indicator.title}</div>
					</div>
				))}
			</div>
		);
	}

	_renderMissingTeeth () {
		const {
			teeth,
		} = this.props;

		const result = teethUtils.getTeeth().reduce((result, toothKey) => {
			if ( !teeth[toothKey] ) {
				result.push(teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type }));
			}

			return result;
		}, []);

		if ( result.length === 0 ) {
			return null;
		}

		result.sort((a, b ) => teethUtils.sortTeeth(a, b));

		return (
			<div>
				<div className={missingTeethTitleCssClassName}>{i18n('missing_teeth.title')}:</div>
				<div className={missingTeethCssClassName}>{result.join(', ')}</div>
			</div>
		);
	}


	_renderScreen (toothData) {
		const toothKey = toothData.toothKey;
		const tooth = this.props.teeth[toothKey];

		if ( !tooth.children || tooth.children.length === 0 ) {
			return null;
		}

		const toothStyle = {
			borderColor: labelsUtils.getLabelColor(tooth),
		};

		const isShowMeasureOfConfidence = getRuntimeConfig().report.show_measure_of_confidence;

		const renderChild = (label, i) => {
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
			const extra = (isShowMeasureOfConfidence && typeof measureOfConfidence === 'number')
				? ` (${Math.floor(measureOfConfidence * 100)}%)`
				: null;

			return (
				<div key={i} className={screenFindingCssClassName}>
					{labelsUtils.getLocalizedLabelName(label)}
					{ extra }
					{ tooth.children.length - 1 > i ? ',' : ''}
				</div>
			)
		};

		return (
			<div className={screenCssClassName} key={toothKey}>
				<div className={screenImageCssClassName}>
					{this._getImage(toothKey)}
				</div>
				<div className={screenContentCssCLassName}>
					<div className={screenToothKeyCssClassName}>
						<div
							className={findingToothKeyCssClassName}
							style={toothStyle}
						>
							{toothData.localizedToothKey}
						</div>
					</div>
					<div className={screenFindingsCssClassName}>
						{ tooth.children && tooth.children.length > 0 &&
						tooth.children.map(renderChild) }
					</div>
				</div>
			</div>
		);
	}

	_getImage (toothKey) {
		const tooth = this.props.teeth[toothKey];
		return (<canvas
			key={toothKey}
			ref={(canvas) => {
				if ( !canvas ) {
					return;
				}

				canvas = findDOMNode(canvas);

				const primaryBox = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(tooth));
				const image = this._screenImage;
				const context = canvas.getContext('2d');

				const primaryBoxWidth = ( primaryBox.right - primaryBox.left );
				const primaryBoxHeight = ( primaryBox.bottom - primaryBox.top );
				let zoom = 1;

				if ( primaryBoxWidth * 3 > 90 ) {
					zoom = 90 / ( primaryBoxWidth * 3 );
				}

				if ( primaryBoxHeight * 1.7 > 90 ) {
					zoom = 90 / ( primaryBoxHeight * 1.7 );
				}

				const imageWidth = ( image.width * zoom );
				const imageHeight = ( image.height * zoom );

				const offsetLeft = 45 - ( primaryBoxWidth * zoom / 2 );
				const offsetTop = 45 - ( primaryBoxHeight * zoom / 2 );

				const primaryBoxStartX = ( ( 90 / 2 ) -  ( primaryBoxWidth * zoom / 2 ) );
				const primaryBoxStartY = ( ( 90 / 2 ) -  ( primaryBoxHeight * zoom / 2 ) );

				context.drawImage(
					image,
					-( primaryBox.left * zoom ) + offsetLeft,
					-( primaryBox.top * zoom ) + offsetTop,
					imageWidth,
					imageHeight,
				);

				tooth.children.forEach((label) => {
					const box = labelsUtils.getBoundingRectForShape(labelGetters.getLabelShape(label));

					const boxWidth = Math.floor( box.right - box.left );
					const boxHeight = Math.floor( box.bottom - box.top );

					const boxStartX = Math.floor( primaryBoxStartX + ( box.left - primaryBox.left ) * zoom );
					const boxStartY = Math.floor( primaryBoxStartY + ( box.top - primaryBox.top ) * zoom );
					const boxEndY = Math.floor( boxStartY + boxHeight * zoom );

					const boxCenterX = Math.floor( boxStartX + ( boxWidth / 2 ) * zoom );
					const boxCenterY = Math.floor( boxStartY + ( boxHeight / 2 ) * zoom );

					const boxShorterSide = Math.floor( boxWidth > boxHeight  ? boxHeight : boxWidth ) * zoom;

					context.beginPath();
					context.fillStyle = 'red';

					if ( ( 90 - boxCenterY ) > 45 ) {
						const posY = Math.floor( boxStartY + boxShorterSide / 2 / 2);

						// context.fillRect(boxCenterX, posY - 3, 2, -12);

						// context.moveTo(boxCenterX, posY);
						// context.lineTo(boxCenterX - 3, posY - 9);
						// context.lineTo(boxCenterX, posY - 7);
						// context.lineTo(boxCenterX + 2, posY - 7);
						// context.lineTo(boxCenterX + 5, posY - 9);
						// context.lineTo(boxCenterX + 1, posY);
						// context.lineTo(boxCenterX, posY);

						context.drawImage(
							this._arrowDownImage,
							boxCenterX - ( this._arrowDownImage.width / 2 ),
							posY - this._arrowDownImage.height,
							this._arrowDownImage.width,
							this._arrowDownImage.height,
						);
					}
					else {
						const posY = Math.floor( boxEndY - boxShorterSide / 2 / 2 );

						// context.moveTo(boxCenterX - 1, posY + 3);

						// context.fillRect(boxCenterX, posY + 3, 2, 12);

						// context.moveTo(boxCenterX, posY);
						// context.lineTo(boxCenterX - 3, posY + 9);
						// context.lineTo(boxCenterX, posY + 7);
						// context.lineTo(boxCenterX + 2, posY + 7);
						// context.lineTo(boxCenterX + 5, posY + 9);
						// context.lineTo(boxCenterX + 1, posY);
						// context.lineTo(boxCenterX, posY);

						context.drawImage(
							this._arrowUpImage,
							boxCenterX - ( this._arrowUpImage.width / 2 ) + 1,
							posY,
							this._arrowUpImage.width,
							this._arrowUpImage.height,
						);
					}

					context.closePath();
					context.fill();

					// context.beginPath();
					// context.moveTo(boxStartX, boxStartY);
					// context.lineTo(boxStartX + boxWidth * zoom, boxStartY);
					// context.lineTo(boxStartX + boxWidth * zoom, boxStartY + boxHeight * zoom);
					// context.lineTo(boxStartX, boxStartY + boxHeight * zoom);
					// context.closePath();
					// context.stroke();
				});

				// context.beginPath();
				// context.moveTo(primaryBoxStartX, primaryBoxStartY);
				// context.lineTo(primaryBoxStartX + primaryBoxWidth * zoom, primaryBoxStartY);
				// context.lineTo(primaryBoxStartX + primaryBoxWidth * zoom, primaryBoxStartY + primaryBoxHeight * zoom);
				// context.lineTo(primaryBoxStartX, primaryBoxStartY + primaryBoxHeight * zoom);
				// context.closePath();
				// context.stroke();
			}}
			width={90}
			height={90}
		/>);
	}

	_renderDetailReport () {
		const {
			screenImageLoaded,
			arrowDownImageLoaded,
			arrowUpImageLoaded
		} = this.state;
		if (!screenImageLoaded || !this._screenImage ||
			!arrowDownImageLoaded || !this._arrowDownImage ||
			!arrowUpImageLoaded || !this._arrowUpImage
		) {
			return null;
		}

		const notationType = this.props.user.notation_type;
		const sortedTeeth = Object.keys(this.props.teeth)
			.map((toothKey) => ({
				toothKey,
				localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
			}))
			.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));



		return (
			<div className={screensCssClassName}>
				{sortedTeeth.map((toothData) => {
					const tooth = this.props.teeth[toothData.toothKey];

					if ( !tooth.children || tooth.children.length === 0 ) {
						return null;
					}

					return this._renderScreen(toothData);
				})}
			</div>
		);
	}

	render () {
		const {
			currentImage,
			isAuthenticated,
			user,
			snapshotLink,
			reportLink,
		} = this.props;

		const {
			showLinks,
		} = this.state;

		const birthdayValue = this.state.patient.age
			? this.state.patient.age
			: currentImage && currentImage.birthday
				? dateUtils.getAge(currentImage.birthday)
				: '';

		const genderValue = currentImage ? currentImage.gender || 'unknown' : '';

		const screeningDateValue = ( this.state.patient.screening_date
				? this.state.patient.screening_date
				: currentImage && currentImage.image_date
					? moment(currentImage.image_date).format('MM/YYYY')
					: ''
		);

		return (
			<div className={wrapperCssClassName}>
				<div className={baseCssClassName}>
					<div className={controlsCssClassName}>
						<FormLabel
							element={(
								<div className={`${controlsCssClassName}__locale-element`}>
									<select defaultValue={locale()} onChange={this._handleLocaleChange}>
										{
											mainConfig.AVAILABLE_LOCALES.map((data) =>
												<option key={data.key} value={data.key}>{data.name}</option>)
										}
									</select>
								</div>
							) }
							text={(<div className={`${controlsCssClassName}__locale-label`}>{i18n('language.label')}</div>)}
							textPosition={FormLabel.textPosition.LEFT}
						/>
						<div>
							<FormLabel
								element={(
									<div className={`${controlsCssClassName}__show-links-element`}>
										<input type={'checkbox'} onChange={this._handleShowLinksChange} checked={showLinks} />
									</div>
								)}
								text={(
									<div className={`${controlsCssClassName}__show-links-label`}>
										{i18n('show_links.label')}
									</div>
								)}
								textPosition={FormLabel.textPosition.LEFT}
							/>
						</div>
						<div className={controlsPrintButtonCssClassName}>
							<button onClick={this._handlePrintButtonClick}>{i18n('print_button')}</button>
						</div>
					</div>
					<div className={preparedCssClassName}>
						{i18n('prepared')} <span className={poweredByCssClassName}>Denti.<span className={preparedHighlightCssClassName}>AI</span></span>
					</div>
					{
						user && user.full_logo_url &&
						<div className={logoCssClassName}>
							<img src={user.full_logo_url} alt={''} />
						</div>
					}
					<div className={reportNameCssClassName}>
						<div className={`${reportNameCssClassName}__value`}>{i18n('report_name')}</div>
					</div>

					<table className={infoCssClassName}>
						<tbody>
						<tr>
							<td>
								<div className={patientCssClassName}>
									<div className={patientNameCssClassName}>
										<div className={`${infoCssClassName}__value`}>
											{this.state.patient.fullName}
										</div>
										<div className={`${infoCssClassName}__control`}>
											<input
												type={'text'}
												placeholder={i18n('fields.full_name.label')}
												onChange={this._handlePatientFullNameChange}
												value={this.state.patient.fullName}
											/>
										</div>
									</div>
								</div>

								<div className={patientInfoCssClassName}>
									{
										currentImage.patient_id &&
										<div className={patientInfoItemCssClassName}>
											{i18n('fields.patient_id.label')}:&nbsp;
											<span>{currentImage.patient_id}</span>
										</div>
									}
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.age.label')}:&nbsp;
										<span>{birthdayValue}</span>
									</div>
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.gender.label')}:&nbsp;
										<span>{i18nShared(`gender.${genderValue}`)}</span>
									</div>
									<div className={patientInfoItemCssClassName}>
										{i18n('fields.screening_date.label')}:&nbsp;
										{
											Boolean(screeningDateValue) &&
											<span className={`${infoCssClassName}__value`}>{screeningDateValue}</span>
										}
										<input
											type={'text'}
											className={`${infoCssClassName}__control`}
											placeholder={i18n('fields.screening_date.placeholder')}
											style={{ width: 90 }}
											onChange={this._handlePatientAgeScreeningDateChange}
											value={screeningDateValue}
										/>
									</div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
					{this._renderTeeth()}
					{this._renderLegend()}
					{this._renderNormalAppearance()}
					{this._renderMissingTeeth()}
					{this._renderFindings()}
					{this._renderDetailReport()}
					{
						showLinks &&
						<div className={snapshotLinkCssClassName}>
							<span>{i18n('snapshot_link')}</span>: <a href={snapshotLink}>{snapshotLink}</a>
						</div>
					}
					{
						showLinks &&
						<div className={reportLinkCssClassName}>
							<span>{i18n('report_link')}</span>: <a href={reportLink}>{reportLink}</a>
						</div>
					}
					<table className={userInfoCssClassName}>
						<tbody>
						<tr>
							<td>{moment().locale(locale()).format('L')}</td>
							<td>
								{
									isAuthenticated &&
									<div className={doctorInfoCssClassName}>
										<div>{i18n('specialist.label')}: {user.first_name} {user.last_name}</div>
										{
											user.report_email &&
											<div>
												<a href={`mailto:${user.report_email}`}>{user.report_email}</a>
											</div>
										}
									</div>
								}
							</td>
						</tr>
						</tbody>
					</table>
					<div
						className={footerCommentCssClassName}
						dangerouslySetInnerHTML={{ __html: i18n('footer.comment') }}
					/>
					<div
						dangerouslySetInnerHTML={{ __html: `
							<svg height="50" width="50" xmlns="http://www.w3.org/2000/svg" version="1.1">
								<defs>
									<pattern id="absent-tooth" patternUnits="userSpaceOnUse" width="50" height="50">
										<image xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHlJREFUeNrs0jEKgFAMBNG9UI4XyJlzCQ12IoiCfLeYrbac4mk71t0RUVUmX4ZN82XYNF+GTfNl2IQtbGELW9jCFrawhS1sYQtb2MIWtrCFLWxhC1vYwtYntjLTzpZh08mWVZ+uTQ59umn6sU9Pmtb36VXTmr7ZLsAARQq6079U0sEAAAAASUVORK5CYII=" x="0" y="0" width="50" height="50"></image>
									</pattern>
								</defs>
							</svg>
						`  }}
					/>
				</div>
			</div>
		);
	}
}

export default connect((state, { isShared, sharedHashname }) => {
	const showAllClasses = editorSelectors.selectShowAllFindings(state);
	const currentCollectionId = editorSelectors.selectEditor(state).currentCollectionHashName;
	const currentImageId = editorSelectors.selectCurrentImageId(state);

	const filteredClasses = currentImageId
		? editorSelectors.selectFilteredClassesForCurrentImage(state)
		: {};

	const currentImage = currentImageId
		? imagesSelectors.selectImageById(state, {
			id: currentImageId,
		})
		: null;

	const isImageAnalyzed = analyseSelectors.selectIsImageAnalyzed(state, { imageId: currentImageId });

	let labels = {};

	if ( currentImageId && ( !getRuntimeConfig()['auto-image-analysis'] || !isImageAnalyzed ) ) {
		labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		})
			.reduce((result, labelId) => {
				const label = labelsSelectors.selectLabelById(state, {
					labelId,
				});

				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
					if ( tags && tags.length > 0 ) {
						result[labelTagGetter.getTagKey(tags[0])] = label;
					}
				}

				if (
					labelsUtils.labelIsTooth(label) &&
					(
						showAllClasses ||
						true === filteredClasses[labelGetters.getLabelClassId(label)]
					)
				) {
					const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
					if ( tags && tags.length > 0 ) {
						result[labelTagGetter.getTagKey(tags[0])] = {
							...label,
							children: [],
						};
					}
				}

				return result;
			}, {});

		Object.keys(labels).forEach((toothKey) => {
			const label = labels[toothKey];

			const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( children.length > 0 ) {
				children.forEach((childLabelId) => {
					const childLabel = labelsSelectors.selectLabelById(state, {
						labelId: childLabelId,
					});

					if (
						(
							showAllClasses ||
							true === filteredClasses[labelGetters.getLabelClassId(childLabel)]
						) &&
						labelGetters.getLabelIsConfirmed(childLabel) &&
						mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(childLabel)) === false
					) {
						label.children.push({
							...childLabel,
							parentId: labelGetters.getLabelId(label),
						});
					}
				});
			}
		});
	}

	return {
		teeth: labels,
		currentImageId,
		currentImage,
		isAuthenticated: userSelectors.selectIsAuthenticated(state),
		user: userSelectors.selectUserData(state),
		snapshotLink: pathUtils.getSnapshotLink( {
			sharedHashname,
			isShared,
			imageHashName: currentImage.hashname,
			collectionId: currentCollectionId,
		}),
		reportLink: pathUtils.getReportLink({
			sharedHashname,
			isShared,
			imageHashName: currentImage.hashname,
			collectionId: currentCollectionId,
		}),
	};
}, (dispatch) => ({
	onUpdateUserProfile: (options) => dispatch(userActions.updateProfile(options)),
	onGoToLandingPage: () => commonUtils.goToLandingPage(),
}))(ReportPAN);
