import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import mainConfig from '../../configs/mainConfig';
import imageUtils from '../../appUtils/imageUtils';

import { LABELS_STAGE_COMPLETED } from '../../constants/labelsStageConstants';

import editorActions from '../../actions/editorActions';
import labelsActions from '../../modules/labels/actions/labelsActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import userSelectors from '../../selectors/userSelectors';

import LabelListWrapper from './LabelListWrapper';
import LabelList from './LabelList';


class Wrapper extends PureComponent {
	render () {
		return (
			<LabelListWrapper
				filteredClasses={this.props.filteredClasses}
				availableLabels={this.props.availableLabels}
				currentImage={this.props.currentImage}
				showAllFindings={this.props.showAllFindings}
				filteredConfidencePercent={this.props.filteredConfidencePercent}
				shouldShowFindingsFilters={this.props.shouldShowFindingsFilters}
				shouldShowConfidenceFilter={this.props.shouldShowConfidenceFilter}
				shouldShowVisibilityFilter={this.props.shouldShowVisibilityFilter}
				shouldShowConfirmButton={this.props.shouldShowConfirmButton}
				shouldShowDeleteAllButton={
					this.props.labelsStage !== LABELS_STAGE_COMPLETED &&
					this.props.currentImage.isOwn
				}

				onToggleShowAllFindings={this.props.onToggleShowAllFindings}
				onConfirmAllLabels={this.props.onConfirmAllLabels}
				onRemoveAllLabels={this.props.onRemoveAllLabels}
				onSelectFilteredClasses={this.props.onSelectFilteredClasses}
				onFilteredConfidencePercentChange={this.props.onFilteredConfidencePercentChange}
			>
				<LabelList />
			</LabelListWrapper>
		);
	}
}

export default connect((state) => {
	const showAllFindings = editorSelectors.selectShowAllFindings(state);
	const editorData = editorSelectors.selectEditor(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(state);

	return {
		showAllFindings,
		currentImage,
		labelsStage: editorData.labelsStage,
		isFDAAnnotationEnabled,
		type: imageUtils.getGeneralType(currentImage),
		filteredClasses: editorSelectors.selectFilteredClassesForCurrentImage(state),
		availableLabels:
			labelsSelectors.selectAvailableLabelsByImageType(state, { type: currentImage.image_type })
				.sort((a, b) => a.classId.localeCompare(b.classId)),
		shouldShowFindingsFilters: (
			userSelectors.selectUsesReaderStudyModeUi(state) !== true &&
			userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
		),
		filteredConfidencePercent: editorData.filteredConfidencePercent,
		shouldShowVisibilityFilter: (
			userSelectors.selectUsesReaderStudyModeUi(state) !== true &&
			userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
		),
		shouldShowConfidenceFilter: (
			!isFDAAnnotationEnabled &&
			userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
		),
		shouldShowConfirmButton: (
			!isFDAAnnotationEnabled &&
			currentImage.isOwn &&
			userSelectors.selectUsesComputerAidedDeviceUi(state) !== true
		),
	};
}, (dispatch) => ({
	onToggleShowAllFindings: () => dispatch(editorActions.toggleShowAllFindings()),
	onConfirmAllLabels: () => dispatch(labelsActions.confirmAllLabels()),
	onRemoveAllLabels: () => dispatch(labelsActions.removeAllLabels()),
	onSelectFilteredClasses: (data) => dispatch(editorActions.setFilteredClasses(data)),
	onFilteredConfidencePercentChange: (data) => dispatch(editorActions.setFilteredConfidencePercent(data)),
}))(Wrapper);
