import React from 'react';
import ReactDOM from 'react-dom';
import PopupDialog, { getFooterCloseButton } from '../components/PopupDialog';
import { getDictionary } from '../appUtils/locale';
import AccessDeniedApiError from '../api/errors/AccessDeniedApiError';
import ApiError from '../api/errors/ApiError';
import { message } from '../services/popup';


const i18nShared = getDictionary('shared');


export function showAccessDeniedPopup(error) {
  const node = window.document.createElement('DIV');
  window.document.body.appendChild(node);

  ReactDOM.render(
    (
      <PopupDialog
        headerProps={{
          title: i18nShared('header.error'),
        }}
        content={error.message || i18nShared('error.access-denied')}
        footerProps={{
          buttons: (dialogInterface) => getFooterCloseButton(dialogInterface),
        }}
        popupProps={{
          onClose: () => {
            ReactDOM.unmountComponentAtNode(node);
            window.document.body.removeChild(node);
          },
        }}
      />
    ),
    node
  );
}

export function tryToHandleApiError(error) {
	if (error instanceof AccessDeniedApiError) {
		showAccessDeniedPopup(error);
		return true;
	}

	if (error instanceof ApiError) {
		message({
		  title: i18nShared('error.title'),
		  titleIcon: 'error',
		  message: error.message,
		});
		return true;
	}

  return false;
}
