import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import lodashIsEqual from 'lodash/isEqual';

import classnames from 'classnames';

import { MIN_BOX_HEIGHT, MIN_BOX_WIDTH } from '../../../../constants/imageConstants';

import mainConfig from '../../../../configs/mainConfig';

import commonUtils from '../../../../appUtils/common';
import imageUtils from '../../../../appUtils/imageUtils';
import { addMarginToShape } from '../utils/shapesUtils';

import './styles/ImageShapeBox.css';


const baseCssClassName = 'image-shape-box';
const controlsCssClassName = `${baseCssClassName}__controls`;
const resizeControlCssClassName = `${baseCssClassName}__resize-control`;
const actionControlCssClassName = `${baseCssClassName}__action-control`;
const RESIZE_CONTROL_RADIUS = 3;

const RESIZE_CONTROLS_LEFT_TOP = 'left-top';
const RESIZE_CONTROLS_RIGHT_TOP = 'right-top';
const RESIZE_CONTROLS_LEFT_BOTTOM = 'left-bottom';
const RESIZE_CONTROLS_RIGHT_BOTTOM = 'right-bottom';


export default class ImageShapeBox extends Component {
	static propTypes = {
		id: PropTypes.string,
		shape: PropTypes.object.isRequired,
		color: PropTypes.string.isRequired,
		borderStyle: PropTypes.string.isRequired,
		showControls: PropTypes.bool,
		showConfirmation: PropTypes.bool,
		isHighlighted: PropTypes.bool,
		isConfirmed: PropTypes.bool,
		allowEditing: PropTypes.bool,
		allowDeleting: PropTypes.bool,
		isVisible: PropTypes.bool,

		zoom: PropTypes.number.isRequired,
		imageWidth: PropTypes.number,
		imageHeight: PropTypes.number,

		showMask: PropTypes.bool,
		showHeatMap: PropTypes.bool,

		canvasObjectsApi: PropTypes.object,

		onLabelChange: PropTypes.func,
		onLabelRemove: PropTypes.func,
		onSetConfirmed: PropTypes.func,
		onGetRef: PropTypes.func,
		onSetEditing: PropTypes.func,
		onGetHeatmapUrl: PropTypes.func,
	};

	static defaultProps = {
		isVisible: true,
		canvasObjectsApi: null,
		allowEditing: false,
		allowDeleting: false,
		showMask: false,
		showHeatMap: false,
	};

	state = {
		heatMapUrl: null,
	};

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_removeControlEl = null;

	/**
	 * @type {number|null}
	 * @private
	 */
	_objectId = null;

	constructor (props, context) {
		super(props, context);

		this._beginX = null;
		this._beginY = null;

		this._isResizing = false;
		this._isMoving = false;

		this._resizeControlPosition = null;

		this._shape = {
			...props.shape,
		};
	};

	componentDidMount () {
		if ( this.props.canvasObjectsApi !== null ) {
			this._objectId = this.props.canvasObjectsApi.objects.add({
				id: this.props.id,
				...this.props.shape,
				...addMarginToShape(this.props.shape, this.props.zoom),
			}, this.props.id);
		}
	}

	componentDidUpdate (prevProps) {
		if ( this.props.allowEditing === false && lodashIsEqual(this.props.shape, prevProps.shape) === false ) {
			this._shape = {
				...this.props.shape,
			};
			if ( this.props.canvasObjectsApi !== null ) {
				this.props.canvasObjectsApi.objects.update(this._objectId, {
					id: this.props.id,
					...this.props.shape,
					...addMarginToShape(this._shape, this.props.zoom),
				});
			}
			this.forceUpdate();
		}
	}

	componentWillUnmount () {
		if ( this.props.canvasObjectsApi !== null && this._objectId !== null ) {
			this.props.canvasObjectsApi.objects.remove(this._objectId);
			this._objectId = null;
		}

		if ( this._removeControlEl !== null ) {
			this._removeControlEl.removeEventListener('mousedown', this._handleControlReject);
			this._removeControlEl = null;
		}
	}

	_handleRef = (el) => {
		if ( this.props.onGetRef ) {
			this.props.onGetRef(el);
		}
	};

	/**
	 * @param {HTMLElement} [element]
	 * @private
	 */
	_handleRemoveControlRef = (element) => {
		if ( element === null || element === undefined || element === this._removeControlEl ) {
			return;
		}
		this._removeControlEl = element;
		this._removeControlEl.addEventListener('mousedown', this._handleControlReject);
	};

	_handleControlAccept = (event) => {
		event.stopPropagation();
		this.props.onSetConfirmed(!this.props.isConfirmed);
	}

	_handleControlReject = (event) => {
		event.stopPropagation();
		this.props.onLabelRemove();
	}
	_handleControlMouseDown = (event) => {
		if ( !this.props.showControls || !this.props.allowEditing ) {
			return;
		}

		const resizeControlPosition = event.target.dataset.resizeControlPosition;

		if ( resizeControlPosition ) {
			this._isResizing = true;
			this._resizeControlPosition = resizeControlPosition;
		}
		else {
			this._isMoving = true;
		}

		this._beginX = event.clientX;
		this._beginY = event.clientY;

		this.props.onSetEditing(true);

		window.document.addEventListener('mousemove', this._handleDocumentMouseMove);
		window.document.addEventListener('mouseup', this._handleDocumentMouseUp);
	}

	_handleDocumentMouseMove = (event) => {
		const {
			zoom,
			imageWidth,
			imageHeight,
		} = this.props;

		const diff = (
			zoom === 1 ? 1 : ( 1 / zoom )
		);

		const deltaX = event.clientX - this._beginX;
		const deltaY = event.clientY - this._beginY;

		this._beginX += deltaX;
		this._beginY += deltaY;

		if ( this._isMoving ) {
			const boxWidth = this._shape.endX - this._shape.startX;
			const boxHeight = this._shape.endY - this._shape.startY;

			let nextStartX = this._shape.startX + (deltaX * diff);
			let nextStartY = this._shape.startY + (deltaY * diff);
			let nextEndX = this._shape.endX + (deltaX * diff);
			let nextEndY = this._shape.endY + (deltaY * diff);

			let xMovingEnabled = true;
			let yMovingEnabled = true;

			this._shape.startX = nextStartX;
			if ( nextStartX < 0 ) {
				xMovingEnabled = false;

				this._shape.startX = 0;
				this._shape.endX = boxWidth;
			}

			this._shape.startY = nextStartY;
			if ( nextStartY < 0 ) {
				yMovingEnabled = false;

				this._shape.startY = 0;
				this._shape.endY = boxHeight;
			}

			if ( xMovingEnabled ) {
				this._shape.endX = nextEndX;
				if ( nextEndX > imageWidth ) {
					this._shape.startX = imageWidth - boxWidth;
					this._shape.endX = imageWidth;
				}
			}

			if ( yMovingEnabled ) {
				this._shape.endY = nextEndY;
				if ( nextEndY > imageHeight ) {
					this._shape.startY = imageHeight - boxHeight;
					this._shape.endY = imageHeight;
				}
			}
		}
		else if ( this._isResizing ) {
			const minBoxWidth = mainConfig.IMAGE_BOX_MIN_WIDTH;
			const minBoxHeight = mainConfig.IMAGE_BOX_MIN_HEIGHT;

			switch (this._resizeControlPosition) {
				case RESIZE_CONTROLS_LEFT_TOP: {
					let newStartX = Math.max(0, this._shape.startX + (deltaX * diff));
					let newStartY = Math.max(0, this._shape.startY + (deltaY * diff));

					this._shape.startX = ( ( this._shape.endX - newStartX ) > minBoxWidth )
						? newStartX
						: this._shape.endX - minBoxWidth;

					this._shape.startY = ( ( this._shape.endY - newStartY )  > minBoxHeight )
						? newStartY
						: this._shape.endY - minBoxHeight;

					break;
				}
				case RESIZE_CONTROLS_RIGHT_TOP: {
					const newEndX = Math.min(imageWidth, this._shape.endX + (deltaX * diff));
					const newStartY = Math.max(0, this._shape.startY + (deltaY * diff));

					this._shape.endX = ( ( newEndX - this._shape.startX ) > minBoxWidth )
						? newEndX
						: this._shape.startX + minBoxWidth;

					this._shape.startY = ( ( this._shape.endY - newStartY ) > minBoxHeight )
						? newStartY
						: this._shape.endY - minBoxHeight;

					break;
				}
				case RESIZE_CONTROLS_LEFT_BOTTOM: {
					const newStartX = Math.max(0, this._shape.startX + (deltaX * diff));
					const newEndY = Math.min(imageHeight, this._shape.endY + (deltaY * diff));

					this._shape.startX = ( ( this._shape.endX - newStartX ) >= minBoxWidth )
						? newStartX
						: ( this._shape.endX - minBoxWidth );

					this._shape.endY = ( ( newEndY - this._shape.startY ) >= minBoxHeight )
						? newEndY
						: ( this._shape.startY + minBoxHeight );

					break;
				}
				case RESIZE_CONTROLS_RIGHT_BOTTOM: {
					const newEndX = Math.min(imageWidth, this._shape.endX + (deltaX * diff));
					const newEndY = Math.min(imageHeight, this._shape.endY + (deltaY * diff));

					this._shape.endX = ( ( newEndX - this._shape.startX )  >= minBoxWidth )
						? newEndX
						: ( this._shape.startX + minBoxWidth );

					this._shape.endY = ( ( newEndY - this._shape.startY ) >= minBoxHeight )
						? newEndY
						: ( this._shape.startY + minBoxHeight );

					break;
				}
				default:
					break;
			}
		}
		else {
			return;
		}

		this.forceUpdate();
	}

	_handleDocumentMouseUp = () => {
		window.document.removeEventListener('mousemove', this._handleDocumentMouseMove);
		window.document.removeEventListener('mouseup', this._handleDocumentMouseUp);

		this._beginX = null;
		this._beginY = null;

		this._isMoving = false;
		this._isResizing = false;

		// эти штуки надо считать на этапе ресайза
		this._shape.endX = Math.max(this._shape.endX, this._shape.startX+MIN_BOX_WIDTH);
		this._shape.endY = Math.max(this._shape.endY, this._shape.startY+MIN_BOX_HEIGHT);

		this.props.canvasObjectsApi.objects.update(this._objectId, {
			id: this.props.id,
			...this.props.shape,
			...addMarginToShape(this._shape, this.props.zoom),
		});

		this.props.onLabelChange({
			...this.props.shape,
			...this._shape,
		});

		this.props.onSetEditing(false);

		this.forceUpdate();
	}

	_renderControls () {
		const {
			color,
			zoom,
			showControls,
			showConfirmation,
			allowDeleting,
			isConfirmed,
			isHighlighted,
		} = this.props;

		if ( !showControls && !showConfirmation  ) {
			return null;
		}

		const x1 = this._shape.startX * zoom;
		const x2 = x1 + ((this._shape.endX - this._shape.startX) * zoom);
		const y1 = this._shape.startY * zoom;
		const y2 = y1 + ((this._shape.endY - this._shape.startY) * zoom);

		const fill = color;

		// const acceptBoxPath = `M ${x1+3},${y1+3} l 17,0 l 0,17 l -17,0 z`;
		// const acceptPath = `m 4 7.5 l 4 4 l 5.5 -7 l -5.5 7`;

		// const crossBoxPath = `M ${x2-20},${y1+3} l 17,0 l 0,17 l -17,0 z`;
		// const crossPath = `m 4 4 l 9 9 m 0 -9 l -9 9`;
		const crossPathOnly = `M ${x2-4.5},${y1-4.5} l 9 9 m 0 -9 l -9 9`;
		return (
			<g
				className={controlsCssClassName}
				onMouseDown={this._handleControlMouseDown}
			>
				{showControls && <Fragment>
					<circle
						data-resize-control-position={RESIZE_CONTROLS_LEFT_TOP}
						className={resizeControlCssClassName}
						cx={x1}
						cy={y1}
						r={RESIZE_CONTROL_RADIUS}
						fill={fill}
						stroke={'#000'}
						strokeWidth={2}
					/>
					<circle
						data-resize-control-position={RESIZE_CONTROLS_RIGHT_TOP}
						className={resizeControlCssClassName}
						cx={x2}
						cy={y1}
						r={RESIZE_CONTROL_RADIUS}
						fill={fill}
						stroke={'#000'}
						strokeWidth={2}
					/>
					<circle
						data-resize-control-position={RESIZE_CONTROLS_LEFT_BOTTOM}
						className={resizeControlCssClassName}
						cx={x1}
						cy={y2}
						r={RESIZE_CONTROL_RADIUS}
						fill={fill}
						stroke={'#000'}
						strokeWidth={2}
					/>
					<circle
						data-resize-control-position={RESIZE_CONTROLS_RIGHT_BOTTOM}
						className={resizeControlCssClassName}
						cx={x2}
						cy={y2}
						r={RESIZE_CONTROL_RADIUS}
						fill={fill}
						stroke={'#000'}
						strokeWidth={2}
					/>
				</Fragment>}
				{showConfirmation && allowDeleting && (
					<g ref={this._handleRemoveControlRef}>
						<circle
							cx={x2}
							cy={y1}
							r={8}
							fill={fill}
							stroke={fill}
							strokeWidth={2}
							className={actionControlCssClassName}
						/>
						<path
							d={`${crossPathOnly}`}
							// stroke="#ef5350"
							stroke={'#45464E'}
							// fill="#ffffff"
							fill={'transparent'}
							strokeWidth={2}
							strokeLinejoin={'round'}
							className={actionControlCssClassName}
						/>
						{/*<path*/}
						{/*	stroke="#00AD66"*/}
						{/*	// fill="transparent"*/}
						{/*	fill="#ffffff"*/}
						{/*	strokeWidth="2"*/}
						{/*	strokeLinejoin="round"*/}
						{/*	className={actionControlCssClassName}*/}
						{/*	onClick={this._handleControlAccept}*/}
						{/*	onMouseDown = {(event) => event.stopPropagation()}*/}
						{/*	onMouseMove = {(event) => event.stopPropagation()}*/}
						{/*	onMouseUp = {(event) => event.stopPropagation()}*/}
						{/*	onMouseLeave = {(event) => event.stopPropagation()}*/}
						{/*	d={isConfirmed ? `${acceptBoxPath} ${acceptPath}` : acceptBoxPath}*/}
						{/*/>*/}
					</g>
				)}
			</g>
		);
	}

	_renderMaskImpl (mask) {
		const { zoom, color } = this.props;
		const props = {
			fill: color,
			fillOpacity: .3,
			stroke: color,
			strokeWidth: 1,
			ref: this._handleRef,
			d: mask.map((point, i) => {
				const x = point[0] * zoom;
				const y = point[1] * zoom;

				if ( i === 0 ) {
					return `M ${x} ${y}`;
				}
				else if ( i === (mask.length - 1) ) {
					return `L ${x} ${y} Z`;
				}
				else {
					return `L ${x} ${y}`;
				}
			}).join(' '),
		};

		return (
			<path {...props} />
		);
	}

	_renderMask () {
		if ( Array.isArray(this._shape.mask[0][0]) === true ) {
			return this._shape.mask.map((mask) => this._renderMaskImpl(mask));
		}

		return this._renderMaskImpl(this._shape.mask);
	}

	_renderHeatMap () {
		if ( typeof this.props.onGetHeatmapUrl !== 'function' ) {
			return null;
		}

		const width = typeof this._shape.matrix === 'string' ? this._shape.height : this._shape.matrix[0].length;
		const height = typeof this._shape.matrix === 'string' ? this._shape.width : this._shape.matrix.length;

		return (
			<image
				ref={this._handleRef}
				href={this.props.onGetHeatmapUrl(this._shape)}
				width={width * this.props.zoom}
				height={height * this.props.zoom}
				x={this._shape.startX * this.props.zoom}
				y={this._shape.startY * this.props.zoom}
			/>
		);
	}

	render () {
		const {
			color,
			borderStyle,
			zoom,
			showControls,
			isHighlighted,
			isVisible,
		} = this.props;

		if ( isVisible === false ) {
			return null;
		}

		const props = {
			className: classnames([
				baseCssClassName,
				showControls && `${baseCssClassName}__m-selected`,
				isHighlighted && `${baseCssClassName}__m-highlighted`,
			]),

			fill: 'none',
			stroke: color,
			strokeWidth: (showControls || isHighlighted ? 3 : 1.5),

			x: this._shape.startX * zoom,
			y: this._shape.startY * zoom,
			width: (this._shape.endX - this._shape.startX) * zoom,
			height: (this._shape.endY - this._shape.startY) * zoom,
		};

		if ( borderStyle === 'dashed' ) {
			props.strokeDasharray = '0.75 2';
			props.strokeLinecap = 'round';
		}

		const shouldShowHeatMap = (this.props.showHeatMap === true && ( Array.isArray(this._shape.matrix) === true || typeof this._shape.matrix === 'string' ));
		const shouldShowMask = (shouldShowHeatMap === false && this.props.showMask === true && Array.isArray(this._shape.mask) === true);

		return (
			<Fragment>
				{shouldShowMask === false && shouldShowHeatMap === false && (<rect ref={this._handleRef} {...props} />)}
				{shouldShowMask === true && this._renderMask()}
				{shouldShowHeatMap === true && this._renderHeatMap()}
				{this._renderControls()}
			</Fragment>
		);
	}
}
