import PropTypes from 'prop-types';


export const UploadImageServicePropType = PropTypes.shape({
	destroy: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	removeImage: PropTypes.func.isRequired,
	saveImageData: PropTypes.func.isRequired,
	flipImageHorizontal: PropTypes.func.isRequired,
	flipImageVertical: PropTypes.func.isRequired,
	getEvents: PropTypes.func.isRequired,
});
