const selectCollections = (state) => (state.collections || {});
const selectCollectionItems = (state) => (selectCollections(state).collections || {});

const selectSyncedCollections = (state) => (selectCollections(state).syncedCollections || {});
const selectOrderedCollections = (state) => (selectCollections(state).orderedCollections || []);

const selectCollectionById = (state, options) => selectCollectionItems(state)[options.id];

const selectIsSyncedCollection = (state, options) => (selectSyncedCollections(state)[options.id] === true);

export default {
	selectCollections,
	selectCollectionItems,
	selectCollectionById,
	selectOrderedCollections,
	selectIsSyncedCollection,
};
