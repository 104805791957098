import React from 'react';
import ReactDOM from 'react-dom';

import AbstractPopupController from './AbstractPopupController';

import PopupDialog from '../../components/PopupDialog';

import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';


const noop = () => {};

/**
 * @typedef {Object} PopupDialogControllerOptions
 *
 * @property {string} message
 * @property {string} [cssClassNames]
 * @property {PopupDialogHeaderProps} [headerProps]
 * @property {PopupDialogFooterProps} [footerProps]
 * @property {PopupProps} [popupProps]
 * @property {boolean} [autoOpen]
 * @property {function(Element)} [onOpen]
 * @property {function():boolean|undefined} [onBeforeClose]
 * @property {function(Element)} [onClose]
 */


class PopupDialogController extends AbstractPopupController {
	/**
	 * @param {PopupDialogControllerOptions} options
	 */
	constructor (options = {}) {
		super(options);

		this._options.prevOnOpen = ( this._options.onOpen || noop );
		this._options.prevOnClose = ( this._options.onClose || noop );
		this._options.onOpen = this._handleOnOpen;
		this._options.onClose = this._handleOnClose;

		this._dialogContentEl = null;

		this._componentProps = {
			content: (<div>{this._options.message}</div>),
			cssClassNames: this._options.cssClassNames,
			headerProps: {
				...( this._options.headerProps || {} ),
				title: this._options.title ? (<span
					dangerouslySetInnerHTML={{
						__html: this._options.title,
					}}
				/>) : null,
			},
			footerProps: ( this._options.footerProps || {} ),
			popupProps: {
				...( this._options.popupProps || {} ),
				onBeforeClose: this._options.onBeforeClose,
				onClose: this._handleClose,
			},
			onGetContentRef: this._handleGetContentRef,
		};

		if ( this._options.autoOpen ) {
			this.open();
		}
	}

	setOption (name, value) {
		lodashSet(this._componentProps, `popupProps.contentStyle`, {
			...lodashGet(this._componentProps, `popupProps.contentStyle`),
			[name]: value,
		});

		this.render();
	}

	_handleGetContentRef = (el) => {
		this._dialogContentEl = el;
	}

	getDialogContentElement () {
		return this._dialogContentEl;
	}

	_handleOnOpen = () => {
		this._options.prevOnOpen(this.getDialogContentElement());
	}

	_handleOnClose = () => {
		this._options.prevOnClose(this.getDialogContentElement());
	}

	_handleClose = () => {
		setTimeout(() => {
			this.close();
		}, 0);
	}

	render () {
		if ( !this._container ) {
			return;
		}

		ReactDOM.render(
			<PopupDialog {...this._componentProps} />,
			this._container,
		);
	}
}

export default PopupDialogController;
