/**
 * @type {APP_IMAGE_MODE|null}
 */
let app_image_mode = null;


/**
 * @return {APP_IMAGE_MODE}
 */
export function getAppImageMode () {
	return app_image_mode;
}

/**
 * @param {APP_IMAGE_MODE} mode
 */
export function setAppImageMode (mode) {
	app_image_mode = mode;
}
