import React from "react";

export default {
	'title': 'Статистика',
	'labels.user': 'Пользователь',
	'headers.pan_images': 'Панорамных снимков',
	'headers.xray_images': 'Прицельных снимков',
	'headers.ai_added': 'добавлено AI',
	'headers.human_added': 'добавлено специалистом',
	'headers.ai_deleted': 'удалено AI',
	'headers.total': 'всего боксов',
	'statuses.error': 'Произошла ошибка. Пожалуйста, повторите',
	'statuses.error.button': 'Повторить',
};

