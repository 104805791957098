// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
/**
 * @param {string} text
 * @return {Promise}
 */
function fallbackCopyTextToClipboard (text) {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '-99999';
	textArea.style.left = '-99999';
	textArea.style.position = 'absolute';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	return new Promise((resolve, reject) => {
		try {
			document.execCommand('copy');
			resolve();
		} catch (err) {
			reject();
		}
		document.body.removeChild(textArea);
	});
}

/**
 * @param {string} text
 * @return {Promise}
 */
export function copyTextToClipboard (text) {
	if ( !navigator.clipboard ) {
		return fallbackCopyTextToClipboard(text);
	}

	try {
		return navigator.clipboard.writeText(text);
	}
	catch (error) {
		return fallbackCopyTextToClipboard(text);
	}
}
