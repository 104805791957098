import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import imageUtils from '../../appUtils/imageUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import editorSelectors from '../../selectors/editorSelectors';

import { getDictionary } from '../../appUtils/locale';

import './styles/CanvasControls.css';


const i18n = getDictionary('canvas-controls');

const baseCssClassName = 'canvas-controls';
const controlsButtonCssClassName = `${baseCssClassName}__controls-button`;


class LastImageButton extends PureComponent {
	static propTypes = {
		lastImage: PropTypes.object.isRequired,
		disabled: PropTypes.bool.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	_handleClick = () => {
		const {
			lastImage,
		} = this.props;

		if ( lastImage ) {
			imageUtils.checkHasUnsavedChanged( () => {
				const collectionId = this.context.router.route.match.params.id;
				this.context.router.history.replace(`/collections/${collectionId}/image/${lastImage.hashname}`, {imageId: lastImage.hashname});
			});
		}
	};

	render () {
		return (
			<button
				className={classnames([
					controlsButtonCssClassName,
					`${controlsButtonCssClassName}__m-last-image`,
					isSmallScreen() && `${controlsButtonCssClassName}__m-small`,
					this.props.disabled === true && `${controlsButtonCssClassName}__m-disabled`,
				])}
				title={i18n('buttons.last_image.alt')}
				disabled={this.props.disabled === true}
				onClick={this._handleClick}
			/>
		);
	}
}

export default connect((state) => {
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const lastImage = imagesCollectionsSelectors.selectFirstImageInCollection(state);

	return {
		lastImage,
		disabled: currentImage && lastImage? lastImage.hashname === currentImage.hashname : false,
	};
})(LastImageButton);
