import { LABELS_STAGE_UNAIDED } from '../constants/labelsStageConstants';

import imagesSelectors from './imagesSelectors';
import collectionsSelectors from './collectionsSelectors';


const selectEditor = (state) => state.editor;

const selectCurrentImageId = (state) => selectEditor(state).currentImageId;

const selectCurrentImageIsLoaded = (state) => selectEditor(state).currentImageIsLoaded;

const selectShowAllFindings = (state) => selectEditor(state).showAllFindings;

const selectLabelsStage = (state) => {
	const editorData = selectEditor(state);
	return (editorData.labelsStage || LABELS_STAGE_UNAIDED);
};


const selectFilteredClassesForCurrentImage = (state) => {
	const editorData = selectEditor(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: editorData.currentImageId,
	});

	return (editorData.filteredClasses[currentImage.image_type] || {});
};

const selectCurrentCollectionAndImage = (state) => ({
	currentImage: imagesSelectors.selectImageById(state, {
		id: selectCurrentImageId(state),
	}),
	currentCollection: collectionsSelectors.selectCollectionById(state, {
		id: selectEditor(state).currentCollectionHashName,
	}),
});

export default {
	selectEditor,
	selectLabelsStage,
	selectCurrentImageId,
	selectCurrentImageIsLoaded,
	selectShowAllFindings,
	selectFilteredClassesForCurrentImage,
	selectCurrentCollectionAndImage,
};
