import editorActionTypes from '../../../actions/editorActionTypes';

import helpers from '../../../appUtils/helpers';
import storeUtils from '../../../appUtils/storeUtils';
import { events } from '../../../services/events';

import imageApi from '../../../api/imageApi';

import apiActions from '../../../actions/apiActions';
import editorActions from '../../../actions/editorActions';


import editorSelectors from '../../../selectors/editorSelectors';
import labelsSelectors from '../selectors/labelsSelectors';
import imagesSelectors from '../../../selectors/imagesSelectors';
import labelTagsSelectors from '../../label-tags/selectors/labelTagsSelectors';
import labelGetters from '../selectors/labelGetters';
import labelTagGetter from '../../label-tags/selectors/labelTagGetter';
import apiActionTypes from "../../../actions/apiActionTypes";
import labelsActionTypes from "../actions/labelsActionTypes";

let shiftStatus = 0;
let isEnabled = false;

export const disable = () => {
	isEnabled = false;
};

export const enable = () => {
	isEnabled = true;
};

const teethConflictsMiddleware = ({ getState, dispatch }) => (next) => (action) => {
	next(action);

	if ( isEnabled === false ) {
		return;;
	}

	if ( shiftStatus === 1 ) {
		return;
	}

	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
		case labelsActionTypes.ACTION_LABELS__REMOVE_LABEL:
		case labelsActionTypes.ACTION_LABELS__UPDATE_DATA: {
			const storeState = getState();
			const teethToShift = editorSelectors.selectEditor(storeState).teethToShift;

			teethToShift.forEach((descriptor) => {
				const tooth = labelsSelectors.selectLabelByToothKey(storeState, { toothKey: descriptor.from });
				if ( tooth === null ) {
					const nextTeethToShift =  teethToShift.filter((_descriptor) => _descriptor.from !== descriptor.from);
					dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
						data: {
							teethToShift: nextTeethToShift,
						},
					}));
				}
				else {
					const shape = tooth.shapes[descriptor.imageHashName] || {};
					if ( shape.type !== 'box' ) {
						const nextTeethToShift = teethToShift.filter((_descriptor) => _descriptor.from !== descriptor.from);
						dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
							data: {
								teethToShift: nextTeethToShift,
							},
						}));
					}
				}
			});

			break;
		}
	}

	const storeState = getState();
	const { currentCollection } = editorSelectors.selectCurrentCollectionAndImage(storeState);
	const editorData = editorSelectors.selectEditor(storeState);
	const teethToShift = editorData.teethToShift;
	const teethConflicts = editorData.teethConflicts;
	const nextTeethConflicts = [];
	const currentImageId = editorData.currentImageId;
	const imageTeethMap = {};

	if ( shiftStatus === 1 ) {
		return;
	}

	Object.values(labelsSelectors.selectLabels(storeState)).forEach((label) => {
		if ( labelGetters.getLabelClassId(label) !== 'tooth' ) {
			return false;
		}

		const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId: labelGetters.getLabelId(label) });

		if ( tags && tags.length > 0 ) {
			Object.keys(label.shapes).forEach((imageHashName) => {
				const shape = label.shapes[imageHashName];

				if ( shape.type === 'box' ) {
					if ( Array.isArray(imageTeethMap[imageHashName]) === false ) {
						imageTeethMap[imageHashName] = [];
					}
					imageTeethMap[imageHashName].push(labelTagGetter.getTagKey(tags[0]));
				}
			});
		}

		return false;
	});

	teethToShift.forEach((descriptor) => {
		if ( Array.isArray(imageTeethMap[descriptor.imageHashName]) === true ) {
			const firstIndex = imageTeethMap[descriptor.imageHashName].indexOf(descriptor.from);
			if ( firstIndex !== -1 ) {
				imageTeethMap[descriptor.imageHashName].splice(firstIndex, 1);
			}
			imageTeethMap[descriptor.imageHashName].push(descriptor.to);
		}
	});

	Object.keys(imageTeethMap).forEach((imageHashName) => {
		const nextTeethMap = {};
		imageTeethMap[imageHashName].forEach((toothKey) => {
			if ( nextTeethMap[toothKey] === true ) {
				nextTeethConflicts.push({ imageHashName, toothKey });
			}
			nextTeethMap[toothKey] = true;
		});
	});

	if ( nextTeethConflicts.length > 0 || nextTeethConflicts.length !== teethConflicts.length ) {
		next(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				teethConflicts: nextTeethConflicts,
			},
		}));

		if ( nextTeethConflicts.length > 0 ) {
			return;
		}
	}

	if ( teethToShift.length > 0 ) {
		const requestsByHashName = {};

		teethToShift.forEach((descriptor) => {
			if ( Array.isArray(requestsByHashName[descriptor.imageHashName]) === false ) {
				requestsByHashName[descriptor.imageHashName] = [];
			}

			requestsByHashName[descriptor.imageHashName].push(descriptor);
		});

		const lastPromise = Object.keys(requestsByHashName).reduce((promise, imageHashName) => {
			return promise.then(() => {
				console.log('pm');
				const storeState = getState();
				const image = imagesSelectors.selectImageByHashName(storeState, { hashname: imageHashName });
				const data = helpers.toApiStructure({
					storeState,
					imageId: image.id,
				});
				const shift = requestsByHashName[imageHashName];
				const shifts = shift.reduce((result, descriptor) => {
					result[descriptor.from] = descriptor.to;
					return result;
				}, {})
				const apiData = { findings: [], shifts };

				shift.forEach((descriptor) => {
					const toothKey = descriptor.from;
					const nextToothKey = descriptor.to;
					const tooth = data.labels.find((label) => Array.isArray(label.tags) && label.tags[0] === toothKey);
					const childrenOfTooth = data.labels.filter((label) => Array.isArray(label.relations) && label.relations.length > 0 && label.relations[0].label_id === tooth.id);
					let nextTooth = data.labels.find((label) => Array.isArray(label.tags) && label.tags[0] === nextToothKey);
					let childrenOfNextTooth = [];

					if ( typeof nextTooth === 'undefined' ) {
						const netToothId = helpers.generateLabelId();
						nextTooth = {
							id: netToothId,
							class_id: 'tooth',
							tags: [ nextToothKey ],
							source: 'manual',
							meta: {
								confirmed: false,
								confidence_percent: 1,
							},
							shape: null,
							relations: [],
							date: null,
							params: [],
							surfaces: [],
							image_shapes: [ { id: helpers.generateLabelId(), image_hashname: imageHashName, type: 'none', tags: [ nextToothKey ] }],
						};

						childrenOfNextTooth.push({
							id: helpers.generateLabelId(),
							class_id: 'missing_tooth',
							tags: null,
							source: 'manual',
							meta: {
								confirmed: false,
								confidence_percent: 1,
							},
							shape: { type: 'none' },
							relations: [ { type: 'child', label_id: netToothId } ],
							date: null,
							params: [],
							surfaces: [],
							image_shapes: [ { id: helpers.generateLabelId(), image_hashname: imageHashName, type: 'none', tags: [ nextToothKey ] }],
						});
					}
					else {
						childrenOfNextTooth = data.labels.filter((label) => Array.isArray(label.relations) && label.relations.length > 0 && label.relations[0].label_id === nextTooth.id);
					}

					apiData.findings.push(tooth, ...childrenOfTooth);
					apiData.findings.push(nextTooth, ...childrenOfNextTooth);
				});

				const findingIds = {};
				apiData.findings = apiData.findings.reduce((result, finding) => {
					if ( findingIds[finding.id] === true ) {
						return result;
					}

					result.push(finding);
					findingIds[finding.id] = true;
					return result;
				}, []);

				return imageApi.shiftTooth(currentCollection.hashname, imageHashName, apiData)
					.then(({ findings: nextFindings }) => {
						console.log('done');
						dispatch(editorActions.putInImageHistory(async () => {
							let nextState = getState();
							apiData.findings.forEach((label) => {
								nextState = {
									...helpers.removeLabel({
										storeState: nextState,
										labelId: label.id,
									}),
								};
							});

							nextState = helpers.toStateStructure({
								storeState: {
									...getState(),
									...nextState,
								},
								annotation: {
									labels: nextFindings,
								},
								image,
								mergeStrategy: 'merge',
							});
							delete nextState.editor;

							dispatch(apiActions.setData(nextState));
						}));
					});
			});
		}, Promise.resolve());

		shiftStatus = 1;
		events.emit('teeth-shift.status.changed', 'in_progress');
		lastPromise
			.then(() => {
				shiftStatus = 0;
				events.emit('teeth-shift.status.changed', 'done');
				dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
					data: {
						teethToShift: [],
					},
				}));
			})
			.catch((error) => {
				events.emit('teeth-shift.status.changed', 'failed');
				shiftStatus = 0;
				console.log(error);
			});
	}
};

export default teethConflictsMiddleware;
