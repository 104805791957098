import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FormCustomSelect from '../../../../../components/FormCustomSelect';

import './styles/ExaminationSelector.css';


/**
 * @param {string} value
 * @param {Array<{ key: string, value: string }>}items
 * @return {string|null}
 */
function getItem (value, items) {
	for (let i = 0, l = items.length; i < l; i++) {
		if (items[i].value === value) {
			return items[i];
		}
	}
	
	return null;
}

const baseCssClassName = 'examination-select';
const headerCssClassName = `${baseCssClassName}__header`;
const headerValueCssClassName = `${baseCssClassName}__header-value`;
const indicatorCssClassName = `${baseCssClassName}__indicator`;
const itemCssClassName = `${baseCssClassName}__item`;


export default class ExaminationSelector extends PureComponent {
	static propTypes = {
		value: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})),
		className: FormCustomSelect.propTypes.className,
		
		initialOpen: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
	};
	
	_handleRenderItem = (item) => (
		<div
			className={classnames([
				itemCssClassName,
				this.props.className && `${this.props.className}-item`,
			])}
		>{item.value}</div>
	);
	
	_handleRenderHeader = ({ isOpened }) => {
		const selectedItem = (this.props.value && this.props.items
			? (getItem(this.props.value, this.props.items) || {})
			: {}
		);
		
		return (
			<div
				className={classnames([
					headerCssClassName,
					isOpened && `${headerCssClassName}__m-opened`
				])}
				title={selectedItem.value}
			>
				<span className={headerValueCssClassName}>{selectedItem.value}</span>
				<div className={indicatorCssClassName} />
			</div>
		);
	};
	
	render () {
		return (
			<FormCustomSelect
				items={this.props.items}
				value={this.props.value}
				disabled={this.props.items.length === 1}
				initialOpen={false}
				className={baseCssClassName}
				onRenderHeader={this._handleRenderHeader}
				onRenderItem={this._handleRenderItem}
				onChange={this.props.onChange}
			/>
		);
	}
}
