import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
	USER_PERMISSION__CHANGE_COLLECTION,
	USER_PERMISSION__DELETE_COLLECTION,
	USER_PERMISSION__COPY_COLLECTION,
	USER_PERMISSION__SHARE_COLLECTION,
	USER_PERMISSION__IS_FMX_VIEW_ENABLED,
} from '../../constants/userPermissionsConstants';

import aclService from '../../services/acl';
import { message } from '../../services/popup';
import { trackEvent } from '../../integrations/mixpanel';
import collectionsActions from '../../actions/collectionsActions';

import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';
import collectionsSelectors from '../../selectors/collectionsSelectors';
import userSelectors from '../../selectors/userSelectors';

import { withStyles } from '@material-ui/core/styles';

import image from './images/fake_image.jpg';

import { getDictionary } from '../../appUtils/locale';

import './styles/CollectionCard.css';


const i18n = getDictionary('collection');
const i18nShared = getDictionary('shared');

const styles = () => ({
	media: {
		height: 0,
		paddingTop: '46.25%', // 16:9
	},
});
const ITEM_HEIGHT = 48;

const baseCssClassName = 'collection-card';
const badgesCssClassName = `${baseCssClassName}__badges`;
const imagesNumBadgeCssClassName = `${baseCssClassName}__images-num-badge`;
const deletedBadgeCssClassName = `${baseCssClassName}__deleted-badge`;
const defaultBadgeCssClassName = `${baseCssClassName}__default-badge`;
const sharedBadgeCssClassName = `${baseCssClassName}__shared-badge`;


class CollectionCard extends React.Component {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	static propTypes = {
		user: PropTypes.object.isRequired,
		collectionId: PropTypes.string.isRequired,
		isSyncedCollection: PropTypes.bool.isRequired,
		lastImage: PropTypes.object,
		isFDAAnnotationEnabled: PropTypes.bool.isRequired,
		onSetCollectionAsDefault: PropTypes.func.isRequired,
		onGetCollection: PropTypes.func.isRequired,
		onSwitchShareCollection: PropTypes.func.isRequired,
		onResetAnnotations: PropTypes.func.isRequired,
	};

	state = {
		anchorEl: null,
		isLoading: false,
	}

	componentWillUnmount () {
		this.state = null;
	}

	_loadCollection () {
		this.setState({
			isSyncing: true,
		});

		this.props.onGetCollection({ hashName: this.props.collectionId })
			.then(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSynced: true,
				});
				this._setRoutePage();
			})
			.finally(() => {
				if ( !this.state ) {
					return;
				}

				this.setState({
					isSyncing: false,
				});
			});
	}

	_setRoutePage = () => {
		const {
			collection,
			lastImage
		} = this.props;

		if ( lastImage ) {
			if ( this.props.user.is_treatment_default === true ) {
				if ( aclService.checkPermission(USER_PERMISSION__IS_FMX_VIEW_ENABLED) === true ){
					window.location.href = `/collections/${collection.hashname}/image/${lastImage.hashname}/fmx`;
				}
				else {
					window.location.href = `/collections/${collection.hashname}/image/${lastImage.hashname}/treatment_plan`;
				}
			}
			else {
				this.context.router.history.push(`/collections/${collection.hashname}/image/${lastImage.hashname}`);
			}
		}
		else {
			if ( this.props.user.is_treatment_default === true ) {
				window.location.href = `/collections/${collection.hashname}/treatment_plan`;
			}
			else {
				this.context.router.history.push(`/collections/${collection.hashname}`);
			}
		}
	}

	_handleLoadCollection = () => {
		if ( !this.state.anchorEl ) {
			if ( !this.props.isSyncedCollection ) {
				this._loadCollection();
			}
			else {
				this._setRoutePage();
			}
		}
	}

	_handleMenuClick = event => {
		event.stopPropagation();
		this.setState({ anchorEl: event.currentTarget });
	}

	_handleEditClick = () => {
		const {
			hashname,
			name,
			description,
			is_default,
		} = this.props.collection;

		this.props.handleEditClick({
			hashname,
			name,
			summary: description,
			isDefault: is_default,
		});
		trackEvent('Collection edit click');
		this._handleMenuClose();
	}

	_handleSetCollectionAsDefaultClick = () => {
		trackEvent('Collection make default click');
		this.props.onSetCollectionAsDefault({
			hashname: this.props.collection.hashname,
		})
			.then(() => {
				trackEvent('Collection made default');
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: i18n('error.set-as-default.general'),
				});
			});

		this._handleMenuClose();
	}

	_handleRemoveClick = () => {
		const {
			collection,
			handleRemoveClick,
		} = this.props;

		trackEvent('Collection remove click');

		handleRemoveClick({
			hashname: collection.hashname,
		});
		this._handleMenuClose();
	}

	_handleMenuClose = () => {
		this.setState({ anchorEl: null });
	}

	_handleOpenCopyClick = () => {
		const {
			collection,
			onOpenCopyCollection,
		} = this.props;

		trackEvent('Collection copy click');

		onOpenCopyCollection({
			hashname: collection.hashname,
			name: collection.name,
			description: collection.description,
		});

		this._handleMenuClose();
	};

	_handleSwitchShareClick = () => {
		trackEvent('Collection share click');

		this.props.onSwitchShareCollection({
			hashname: this.props.collection.hashname,
		})
			.then(() => {
				trackEvent('Collection shared');
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'An error occurred when sharing/unsharing collection',
				});
			});

		this._handleMenuClose();
	};

	_handleResetAnnotationsClick = () => {
		trackEvent('Collection reset annotation click');

		this.props.onResetAnnotations({
			hashname: this.props.collection.hashname,
		})
			.then(() => {
				window.location.reload(true);
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: i18n('error.reset_annotations.general'),
				});
			});

		this._handleMenuClose();
	}


	render() {
		const {
			hashname,
			name,
			description,
			created_at,
			username,
			thumbnail,
			num_images,
			is_deleted,
			is_default,
			is_shared_within_company,
			has_resettable_annotations,
		} = this.props.collection;

		const {
			classes,
			isFDAAnnotationEnabled,
		} = this.props;
		const { anchorEl } = this.state;

		const isShowDeleteItem = (
			!is_deleted
				&& aclService.checkPermission(USER_PERMISSION__DELETE_COLLECTION)
		  );
		const isShowMakeDefaultItem = (
			!is_deleted
				&& aclService.checkPermission(USER_PERMISSION__CHANGE_COLLECTION)
			);

		const isShowChangeItem = aclService.checkPermission(USER_PERMISSION__CHANGE_COLLECTION);
		const isCopyAvailable = aclService.checkPermission(USER_PERMISSION__COPY_COLLECTION) && !is_deleted;
		const hasResettableAnnotations = has_resettable_annotations && isFDAAnnotationEnabled;
		const isMenuAvailable = isShowChangeItem || isShowMakeDefaultItem || isShowDeleteItem || isCopyAvailable || hasResettableAnnotations;
		const isSharingAvailable = aclService.checkPermission(USER_PERMISSION__SHARE_COLLECTION);

		return (
			<div key={hashname}>
				<Card className={baseCssClassName} onClick={this._handleLoadCollection}>
					<CardHeader
						style={{ padding: '3px 20px 3px 10px', fontSize: 14 }}
						action={
							isMenuAvailable && (
								<IconButton
									aria-label={i18n('menu.more')}
									aria-owns={anchorEl ? 'long-menu' : null}
									aria-haspopup={'true'}
									onClick={this._handleMenuClick}
								>
									<MoreVertIcon />
								</IconButton>
							)
						}
						title={name}
						titleTypographyProps={{
							style: {
								fontSize: 14,
							},
						}}
						subheader={
							<React.Fragment>
								<span>{i18n('header', {
									'date': moment.unix(created_at).format('MM/DD/YYYY'),
									'user': username,
								})}</span>
							</React.Fragment>
						}
					/>
					<CardMedia
						style={{ position: 'relative' }}
						className={classes.media}
						image={thumbnail || image}
						title={name}
					>
						<div className={badgesCssClassName}>
							<div className={imagesNumBadgeCssClassName}>
								{i18n('badges.num-images', {
									'num': num_images,
								})}
							</div>
							{ is_default && (
								<div className={defaultBadgeCssClassName}>
									{i18n('badges.default')}
								</div>
							) }

							{ is_shared_within_company && (
								<div className={sharedBadgeCssClassName}>
									Shared
								</div>
							) }

							{ is_deleted &&
								<div className={deletedBadgeCssClassName}>
									{i18n('badges.deleted')}
								</div>
							}
						</div>
					</CardMedia>
					<CardContent style={{ padding: '0 0' }} className={'collection-card__description'}>
						<div className={'collection-card__description-content'} title={description}>
							<div className={'collection-card__description-content-text'}>{description}</div>
						</div>
					</CardContent>
					{isMenuAvailable &&
						<Menu
							id={'long-menu'}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={this._handleMenuClose}
							PaperProps={{
								style: {
									width: 200,
								},
							}}
						>
							{
								isShowChangeItem &&
								<MenuItem key={0} onClick={this._handleEditClick}>
									{i18n('menu.edit')}
								</MenuItem>
							}
							{ isShowMakeDefaultItem && (
								<MenuItem key={1} onClick={this._handleSetCollectionAsDefaultClick}>
									{i18n('menu.make_default')}
								</MenuItem>
							) }
							{ isCopyAvailable &&
								<MenuItem key={3} onClick={this._handleOpenCopyClick}>
									{i18n('menu.copy')}
								</MenuItem>
							}
							{ isShowDeleteItem &&
								<MenuItem key={2} onClick={this._handleRemoveClick}>
									{i18n('menu.remove')}
								</MenuItem>
							}
							{ hasResettableAnnotations && (
								<MenuItem key={1} onClick={this._handleResetAnnotationsClick}>
									{i18n('menu.reset_annotations')}
								</MenuItem>
							) }
						</Menu>
					}
					{this.state.isSyncing && <LinearProgress className={'collectionCard_loader'} />}
				</Card>
			</div>
		)
	}
}

export default connect(
	(state, props) => {
		const collectionId = props.collection.hashname;
		const lastImage = imagesCollectionsSelectors.selectLastOpenedImageInCollection(state, { collectionId } );

		return {
			user: userSelectors.selectUserData(state),
			collectionId,
			isSyncedCollection: collectionId ? collectionsSelectors.selectIsSyncedCollection(state, { id: collectionId }) : false,
			lastImage,
		    isFDAAnnotationEnabled: userSelectors.selectIsFDAAnnotationEnabled(state),
		};
	}, (dispatch) => ({
		onSetCollectionAsDefault: (options) => dispatch(collectionsActions.setCollectionAsDefault(options)),
		onGetCollection: (data) => dispatch(collectionsActions.getCollection(data)),
		onSwitchShareCollection: (data) => dispatch(collectionsActions.switchShareCollection(data)),
		onResetAnnotations: (data) => dispatch(collectionsActions.resetAnnotations(data)),
	})
)(withStyles(styles)(CollectionCard))
