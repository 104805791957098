export default {
	'drawing_mode.on': 'Включить режим рисования',
	'drawing_mode.off': 'Отключить режим рисования',
	'zoom_in': 'Увеличить масштаб',
	'zoom_out': 'Уменьшить масштаб',
	'default_zoom': 'Масштаб по умолчанию',
	'brightness': 'Яркость',
	'contrast': 'Контраст',
	'flip.horizontal': 'Горизонтальный переворот',
	'flip.vertical': 'Вертикальный переворот',
	'rotate.left': 'Повернуть влево',
	'rotate.right': 'Повернуть вправо',
	'invert': 'Инвертировать цвета',
};
