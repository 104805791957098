export default {
	'name': 'Имя коллекции',
	'description': 'Описание',
	'owner': 'Владелец',

	'buttons.open_last_image': 'Открыть последний снимок',
	'buttons.upload_image': 'Загрузить снимок',

	'error.set-as-default.general': 'Возникла ошибка при выборе коллекции по умолчанию. Попробуйте еще раз.',
	'error.reset_annotations.general': 'Возникла ошибка при сбросе аннотаций. Попробуйте еще раз.',

	'menu.more': 'Eщё',
	'menu.edit': 'Редактировать',
	'menu.make_default': 'Сделать по умолчанию',
	'menu.remove': 'Удалить',
	'menu.copy': 'Копировать',
	'menu.reset_annotations': 'Сбросить аннотации',

	'header': 'создано {date} для {user}',

	'badges.num-images': 'Снимков: {num}',
	'badges.default': 'По умолчанию',
	'badges.deleted': 'Удалено',
};
