import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { events } from '../../services/events';
import ImagesCache from '../../services/images-cache';
import commonUtils from '../../appUtils/common';

import editorSelectors from '../../selectors/editorSelectors';

import MagnifyingGlassImage from './MagnifyingGlassImage';


export default class MagnifyingGlassImageConnector extends PureComponent {
	static propTypes = {
		seed: PropTypes.string,
		image: PropTypes.object.isRequired,
		width: PropTypes.number,
		height: PropTypes.number,
		isPreview: PropTypes.bool,
		singleImage: PropTypes.bool,
		sharpen: PropTypes.bool,
		View: PropTypes.node,

		onStatusChanged: PropTypes.func,
	};
	
	static contextTypes = {
		store: PropTypes.object.isRequired,
	};

	static defaultProps = {
		isPreview: false,
		View: MagnifyingGlassImage,
	};

	constructor (props, context) {
		super(props, context);

		this._storeUnsubscribeCallback = null;

		this.state = {
			...this._getFilters(),
			seed: ImagesCache.getCache(this.props.image.id),
		};
	}

	componentDidMount () {
		this._storeUnsubscribeCallback = this.context.store.subscribe(this._handleStoreChange);
		events.on('image.flip.finished', this._handleImageFlipFinished);
	}

	componentWillUnmount () {
		this.state = null;

		events.removeListener('image.flip.finished', this._handleImageFlipFinished);

		if ( this._storeUnsubscribeCallback !== null ) {
			this._storeUnsubscribeCallback();
			this._storeUnsubscribeCallback = null;
		}
	}

	_handleImageFlipFinished = (image) => {
		if ( this.props.image.hashname === image.hashname ) {
			this.setState({
				seed: ImagesCache.getCache(image.id),
			});
		}
	};

	_getFilters () {
		const state = this.context.store.getState();
		const editorData = editorSelectors.selectEditor(state);

		return {
			brightness: editorData.imageBrightness,
			contrast: editorData.imageContrast,
			invert: editorData.imageInvert,
			sharpen: editorData.filterSharpen === true,
			enhancedImageFilter: editorData.enhancedImageFilter,
		};
	}

	_getUrl () {
		if ( this.props.isPreview === false ) {
			const url = this.state.enhancedImageFilter && this.props.image.enhanced_image_url
				? this.props.image.enhanced_image_url
				: this.props.image.image_url;
			if ( this.state.seed !== null ) {
				return commonUtils.addParamToUrl(url, 'seed', this.state.seed);
			}

			return url;
		}
	}

	_handleStoreChange = () => {
		if ( null === this._storeUnsubscribeCallback ) {
			return;
		}

		this.setState(this._getFilters());
	};

	render () {
		const View = this.props.View;
		console.log(this.props.sharpen);
		return (
			<View
				key={this.props.image.hashname || undefined}
				url={this._getUrl()}
				image={this.props.image}
				width={this.props.width}
				height={this.props.height}
				brightness={this.state.brightness}
				contrast={this.state.contrast}
				invert={this.state.invert}
				sharpen={typeof this.props.sharpen === 'boolean'
					? this.props.sharpen
					: this.state.enhancedImageFilter
						? !this.props.image.enhanced_image_url
						: false
				}
				singleImage={this.props.singleImage}
				onStatusChanged={this.props.onStatusChanged}
			/>
		);
	}
}
