import { connect } from 'react-redux';
import teethUtils from '../../../../appUtils/teeth/teethUtils';

import TfvToothAction from './TfvToothAction';

import userSelectors from '../../../../selectors/userSelectors';
import editorSelectors from "../../../../selectors/editorSelectors";
import imagesSelectors from "../../../../selectors/imagesSelectors";


export default connect((state, { toothKey, localizedToothKey, imageHashName }) => {
	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;
	const image = imagesSelectors.selectImageByHashName(state, { hashname: imageHashName })
	const availableTeeth = teethUtils.getTeeth().reduce((result, toothKey) => {
		result.push({
			value: toothKey,
			label: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
		});

		return result;
	}, []);
	const teethToShift = editorSelectors.selectEditor(state).teethToShift;
	const teethConflicts = editorSelectors.selectEditor(state).teethConflicts;
	const requestedToShift = teethToShift.find((descriptor) => (
		descriptor.imageHashName === image.hashname &&
		descriptor.from === toothKey
	));
	let shiftedToothKey;
	let withConflict;

	if ( typeof requestedToShift !== 'undefined' && requestedToShift !== null ) {
		const conflict = teethConflicts.find((conflict) => (
			conflict.imageHashName === image.hashname &&
			conflict.toothKey === requestedToShift.to
		));
		localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey: requestedToShift.to, notationType });
		shiftedToothKey = requestedToShift.to;
		withConflict = (typeof conflict !== 'undefined' && conflict !== null);
	}
	else {
		const conflict = teethConflicts.find((conflict) => (
			conflict.imageHashName === image.hashname &&
			conflict.toothKey === toothKey
		));
		localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey, notationType });
		shiftedToothKey = toothKey;
		withConflict = (typeof conflict !== 'undefined' && conflict !== null);
	}

	availableTeeth.sort((a, b) => teethUtils.sortTeeth(a.label, b.label));

	return {
		localizedToothKey,
		shiftedToothKey,
		withConflict,
		availableTeeth,
	};
}, () => ({}))(TfvToothAction);
