import teethUtils from '../../../appUtils/teeth/teethUtils';
import labelsUtils from '../../../appUtils/labelsUtils';
import helpers from '../../../appUtils/helpers';

import {
	LABEL_VALIDATION_ERROR__BOX_REQUIRED,
	LABEL_VALIDATION_ERROR__DATE_REQUIRED,
	LABEL_VALIDATION_ERROR__PARAMS_REQUIRED,
	LABEL_VALIDATION_ERROR__SURFACE_REQUIRED,
	LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH,
	LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS,
} from '../../labels/constants';

import { getMissingTooth, isShapeIsPresent } from '../utils';

import { SHIFT_DIRECTION__LEFT } from '../../../constants/teethConstants';

import labelTagsSelectors from '../../label-tags/selectors/labelTagsSelectors';
import imagesLabelsSelectors from '../../labels/selectors/imagesLabelsSelectors';
import editorSelectors from '../../../selectors/editorSelectors';
import labelsSelectors from '../../labels/selectors/labelsSelectors';
import labelChildrenSelectors from '../../labels/selectors/labelChildrenSelectors';
import labelsTagsSelectors from '../../label-tags/selectors/labelsTagsSelectors';
import imagesSelectors from '../../../selectors/imagesSelectors';

import labelTagGetter from '../../label-tags/selectors/labelTagGetter';
import labelGetters from '../../labels/selectors/labelGetters';


export function shiftAndSwapTeeth ({
	storeState,
	selectedToothKey,
	toothKeysToShift,
	direction,
 }) {
	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
	};
	
	toothKeysToShift.forEach((toothKey) => {
		imagesLabelsSelectors.selectImageLabelsByImageId(storeState, {
			imageId: editorSelectors.selectCurrentImageId(storeState),
		})
			.forEach((labelId) => {
				const label = result.labels[labelId];
				
				if ( labelsUtils.labelIsTooth(label) ) {
					const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId });
					if ( tags && tags.length > 0 && labelTagGetter.getTagKey(tags[0]) === toothKey ) {
						const newToothKey = (direction === SHIFT_DIRECTION__LEFT)
							? teethUtils.getPreviousToothKey(toothKey)
							: teethUtils.getNextToothKey(toothKey);
						
						// Checks if the next tooth is missing
						const toothToSwap = getMissingTooth(newToothKey);
						
						result.labelTags[labelTagGetter.getTagId(tags[0])] = {
							...tags[0],
							key: newToothKey,
						};

						if ( label.hasOwnProperty('shapes') && label.shapes !== null ) {
							result.labels[labelId] = {
								...result.labels[labelId],
								shapes: Object.keys(label.shapes).reduce((acc, imageHashName) => {
									acc[imageHashName] = {
										...result.labels[labelId].shapes[imageHashName],
										__data: {
											...result.labels[labelId].shapes[imageHashName].__data,
											tags: [ newToothKey ],
										},
									};

									return acc;
								}, {}),
							};
						}
						
						// Swap the next missing tooth with the current
						if ( toothToSwap !== null ) {
							const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId: labelGetters.getLabelId(toothToSwap) });
							if ( tags && tags.length > 0 ) {
								result.labelTags[labelTagGetter.getTagId(tags[0])] = {
									...tags[0],
									key: selectedToothKey,
								};
							}
						}
					}
				}
			});
	});
	
	return result;
}

export function swapTeeth ({
	storeState,
	toothKey,
	newToothKey,
}) {
	const result = {
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
	};
	
	function setNextToothKey (toothKey, newToothKey) {
		const label = labelsSelectors.selectLabelByToothKey(storeState, { toothKey });
		if ( label ) {
			const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId: labelGetters.getLabelId(label)});
			if ( tags && tags.length > 0 ) {
				result.labelTags[labelTagGetter.getTagId(tags[0])] = {
					...tags[0],
					key: newToothKey,
				};
			}
		}
	}
	
	setNextToothKey(toothKey, newToothKey);
	setNextToothKey(newToothKey, toothKey);

	return result;
}

export function approveMissingTooth ({
	storeState,
	labelId,
	imageId,
}) {
	let result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
		imagesLabels: {
			...imagesLabelsSelectors.selectImagesLabels(storeState),
		},
		labelTags: {
			...labelTagsSelectors.selectLabelTags(storeState),
		},
		labelsTags: {
			...labelsTagsSelectors.selectLabelsTags(storeState),
		},
		labelChildren: {
			...labelChildrenSelectors.selectLabelChildren(storeState),
		},
	};

	labelChildrenSelectors.selectLabelChildren(storeState)[labelId]
		.forEach((childLabelId) => {
			const childLabel = labelsSelectors.selectLabelById(storeState, {
				labelId: childLabelId,
			});
			
			if ( labelGetters.getLabelClassId(childLabel) !== 'missing_tooth' ) {
				result = {
					...helpers.removeLabel({
						storeState: result,
						labelId: childLabelId,
						imageId,
					}),
				};
			}
		});
	
	return result;
}

export function checkConflicts ({
	storeState,
	label,
}) {
	const currentImageId = editorSelectors.selectCurrentImageId(storeState);
	const currentImage = imagesSelectors.selectImageById(storeState, {
		id: currentImageId,
	});
	const conflicts = labelGetters.getLabelConflicts(label);
	
	if ( conflicts.length === 0 ) {
		return label;
		
	}
	
	const nextLabel = {
		...label,
	};

	nextLabel.conflicts = conflicts.reduce((result, conflict) => {
		switch (conflict) {
			case LABEL_VALIDATION_ERROR__SURFACE_REQUIRED: {
				const surfaces = labelGetters.getLabelSurfaces(nextLabel);
				if ( surfaces.length === 0 ) {
					result.push(conflict);
				}
				break;
			}
			
			case LABEL_VALIDATION_ERROR__DATE_REQUIRED: {
				const date = labelGetters.getLabelDate(nextLabel);
				if ( !date ) {
					result.push(conflict);
				}
				break;
			}
			
			case LABEL_VALIDATION_ERROR__PARAMS_REQUIRED: {
				const params = labelGetters.getLabelParams(nextLabel);
				if ( params.length === 0 ) {
					result.push(conflict);
				}
				break;
			}
			
			case LABEL_VALIDATION_ERROR__BOX_REQUIRED: {
				const shape = labelGetters.getLabelShape(nextLabel);
				if ( !shape || !shape.type ) {
					result.push(conflict);
				}
				break;
			}
			
			case LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH: {
				const labelId = labelGetters.getLabelId(label);
				const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
				const labelParentMap = {};
				Object.keys(labelChildren)
					.forEach((labelId) => {
						labelChildren[labelId].forEach((childLabelId) => {
							labelParentMap[childLabelId] = labelId;
						});
					});
				
				const parentLabelId = labelParentMap[labelId];
				const parentLabel = labelsSelectors.selectLabelById(storeState, {
					labelId: parentLabelId,
				});

				if (
					isShapeIsPresent(parentLabel.shapes[currentImage.hashname]) &&
					labelChildren[parentLabelId].length > 1
				) {
					result.push(conflict);
				}
				
				break;
			}
			
			case LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS: {
				const labelId = labelGetters.getLabelId(label);
				const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState)[labelId];
				
				if ( labelChildren ) {
					let isMissingTooth = false;
					let missingLabel = null;
					
					labelChildren.forEach((childLabelId) => {
						const childLabel = labelsSelectors.selectLabelById(storeState, {
							labelId: childLabelId,
						});
						
						if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
							isMissingTooth = true;
							missingLabel = childLabel;
						}
					});
					
					// const shape = labelGetters.getLabelShape(nextLabel);

					if ( isMissingTooth && labelChildren.length > 1 /*&& shape && shape.type*/ ) {
						result.push(conflict);
					}
				}
				break;
			}
			
			default:
				break;
			
		}
		return result;
	}, []);
	
	return nextLabel;
}


/**
 * @param {Object} params.storeState
 * @param {string} params.imageId
 *
 * @return {StoreState}
 */
export function removeShapesFromImage (params) {
	const {
		storeState,
		imageId,
	} = params;

	const result = {
		labels: {
			...labelsSelectors.selectLabels(storeState),
		},
	};

	const image = imagesSelectors.selectImageById(storeState, {
		id: imageId,
	});

	imagesLabelsSelectors.selectImageLabelsByImageId(storeState, {
		imageId,
	})
		.map((labelId) => {
			const label = labelsSelectors.selectLabelById(storeState, {
				labelId,
			});

			const nextShapes = {
				...label.shapes,
			};

			delete nextShapes[image.hashname];

			result.labels[labelId] = {
				...label,
				shapes: nextShapes,
			};
		});

	return result;
}
