import React from 'react';

import PropTypes from 'prop-types';


const propTypes = {
	children: PropTypes.node.isRequired,
};

const contextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};


function HeaderRow (props, context) {
	return (
		<div className={`${context.gridCssClassName}__header-row`} style={{ height: props.height }}>
			{props.children}
		</div>
	);
}

HeaderRow.propTypes = propTypes;
HeaderRow.contextTypes = contextTypes;

export default HeaderRow;
