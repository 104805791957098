import React from 'react';

import { LOADING_STATUS } from '../config';

import statsApi from '../../../api/statsApi';
import commonApi from '../../../api/common';

import momentUtils from '../../../appUtils/momentUtils';

import Popup from '../../../components/Popup';
import Loading from '../../../components/Loading';
import HuddleLayout from './layout';
import lodashGet from 'lodash/get';


const HuddleHoc = () => {
	const [ loadingStatus, setLoadingStatus ] = React.useState(LOADING_STATUS.IN_PROGRESS);
	const [ providers, setProviders ] = React.useState(null);
	const [ appointments, setAppointments ] = React.useState(null);
	const [ procedures, setProcedures ] = React.useState(null);
	const isLoadedOnce = React.useRef(false);
	const today = momentUtils.getNowMoment().startOf('day')
	const [ filter, setFilter ] = React.useState({
		provider: null,
		date: today.unix() * 1000,
		txCodes: [],
		periodontitis: false,
		caries: false,
		due_periochart: false,
		due_radiograph: false,
		confidence: 0,
	});

	const loadProviders = React.useCallback(async () => {
		const result = await statsApi.getProviders();

		setProviders(result);
	}, []);

	const loadAppointments = React.useCallback(async (dateFrom, dateTo, provider, codes, findings) => {
		const result = await statsApi.getAppointments(
			undefined,
			typeof dateFrom === 'number'
				? momentUtils.getMomentForDate({ date: new Date(dateFrom) }).format('YYYY-MM-DD')
				: undefined,
			typeof dateTo === 'number'
				? momentUtils.getMomentForDate({ date: new Date(dateTo) }).format('YYYY-MM-DD')
				: undefined,
			provider || undefined,
			codes.length > 0 ? codes : undefined,
			findings.length > 0 ? findings : undefined,
			1000
		);

		setAppointments(result);
	}, []);

	const loadProcedures = React.useCallback(async () => {
		const result = await commonApi.getProcedures();

		setProcedures(result);
	}, []);

	const loadAll = React.useCallback(async () => {
		try {
			setLoadingStatus(LOADING_STATUS.IN_PROGRESS);
			await loadProviders();
			await loadAppointments(filter.date, filter.date, filter.provider, filter.txCodes, []);
			await loadProcedures();

			isLoadedOnce.current = true;

			setLoadingStatus(LOADING_STATUS.DONE);
		}
		catch (error) {
			setLoadingStatus(LOADING_STATUS.FAILED);
		}
	}, [ loadProviders, loadAppointments ]);

	const loadItems = React.useCallback(async (from, to, provider, txCodes, findings) => {
		if ( !isLoadedOnce.current ) {
			return;
		}

		try {
			setLoadingStatus(LOADING_STATUS.IN_PROGRESS);
			await loadAppointments(from, to, provider, txCodes, findings);
			setLoadingStatus(LOADING_STATUS.DONE);
		}
		catch (error) {
			setLoadingStatus(LOADING_STATUS.FAILED);
		}
	}, []);

	const handleProviderChange = React.useCallback((value) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			provider: value === '-1' ? null : value,
		}));
	}, []);

	const handleTxCodeChange = React.useCallback((value) => {
		setFilter((prevFilter) => {
			let nextCodes = [ ...prevFilter.txCodes ];

			if ( nextCodes.includes(value) ) {
				nextCodes = nextCodes.filter((code) => code !== value);
			}
			else {
				nextCodes.push(value);
			}

			return {
				...prevFilter,
				txCodes: nextCodes,
			};
		})
	}, []);

	const handleCariesChange = React.useCallback((value) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			caries: value,
		}));
	}, []);

	const handlePeriodontitisChange = React.useCallback((value) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			periodontitis: value,
		}));
	}, []);

	const handleDuePeriochartChange = React.useCallback((value) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			due_periochart: value,
		}));
	}, []);

	const handleDueRadiographChange = React.useCallback((value) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			due_radiograph: value,
		}));
	}, []);

	React.useEffect(() => {
		loadAll();
	}, []);

	React.useEffect(() => {
		const findings = [];

		loadItems(filter.date, filter.date, filter.provider, filter.txCodes, findings);
	}, [ filter.provider, filter.date, filter.txCodes ]);

	if ( !isLoadedOnce.current ) {
		if (
			loadingStatus === LOADING_STATUS.IN_PROGRESS ||
			loadingStatus === LOADING_STATUS.IDLE
		) {
			return (
				<Popup isGlobal>
					<Loading />
				</Popup>
			);
		}
		else if ( loadingStatus === LOADING_STATUS.FAILED ) {
			return (
				<div>An error occurred while loading. Please try again later.</div>
			);
		}
	}
	else {
		return (
			<HuddleLayout
				filter={filter}
				appointments={appointments
					.filter((item, i) => {
						if ( !item ) {
							return false;
						}

						let result = !filter.caries && !filter.periodontitis && !filter.due_periochart && !filter.due_radiograph;

						if ( result ) {
							return true;
						}

						const nextSummary = item.last_visit_summary.filter((summary) => {
							if ( summary.class_id === 'periodontitis' ) {
								return filter.periodontitis;
							}
							else if ( summary.class_id === 'caries' ) {
								return filter.caries;
							}
							else if ( summary.type === 'due_periochart' ) {
								return filter.due_periochart;
							}
							else if ( summary.type === 'due_radiograph' ) {
								return filter.due_radiograph;
							}
						});

						return nextSummary.length > 0;
					})
					.map((item) => {
						if ( filter.confidence === 0 || !Array.isArray(item.last_visit_summary) ) {
							return item;
						}

						const nextSummary = item.last_visit_summary.map((summary) => {
							const nextItems = summary.items.filter((item) => (
								typeof item.probability === 'undefined' ||
								item.probability === null ||
								item.probability * 100 >= filter.confidence
							));

							if ( nextItems.length === 0 ) {
								return false;
							}

							return ({
								...summary,
								items: nextItems,
							});
						}).filter(Boolean);

						// if ( nextSummary.length === 0 ) {
						// 	return false;
						// }

						return {
							...item,
							last_visit_summary: nextSummary,
						};
					})
				}
				procedures={procedures}
				providers={providers}
				loadingStatus={loadingStatus}
				onProviderChange={handleProviderChange}
				setFilter={setFilter}
				onTxCodeChange={handleTxCodeChange}
				onPeriodontitisChange={handlePeriodontitisChange}
				onCariesChange={handleCariesChange}
				onDuePeriochartChange={handleDuePeriochartChange}
				onDueRadiographChange={handleDueRadiographChange}
			/>
		);
	}
};

export default HuddleHoc;
