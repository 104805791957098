import userActionTypes from '../actions/userActionTypes';

/**
 * @typedef {Object} User
 */

/**
 * @typedef {Object} UserStoreState
 *
 * @property {boolean} isAuthenticated
 * @property {User} data
 */


const initialState = {
	isAuthenticated: false,
	data: {},
};

/**
 * @param {UserStoreState} state
 * @param {ReduxAction} action
 * @return {UserStoreState}
 */
export default function userReducer (state = initialState, action) {
	switch (action.type) {
		case userActionTypes.ACTION_USER__SIGN_IN:
			return {
				...state,
				isAuthenticated: true,
				data: ( action.payload.data || {} ),
			};

		case userActionTypes.ACTION_USER__SIGN_OUT:
			return {
				...state,
				isAuthenticated: false,
				data: {},
			};
		
		case userActionTypes.ACTION_USER__SET_DATA:
			return {
				...state,
				data: action.payload,
			};

		case userActionTypes.ACTION_USER__UPDATE_DATA:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
				},
			};

		default:
			return state;
	}
}
