import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormSelect from '../FormSelect';


const baseCssClassName = 'datepicker-caption';
const controlsCssClassName = `${baseCssClassName}__controls`;
const selectorCssClassName = `${baseCssClassName}__selector`;


function buildState (props) {
	const {
		date,
		locale,
		localeUtils,
		numberOfYears,
		minDate,
		maxDate,
	} = props;

	const currentYear = date.getFullYear();
	const currentMonth = date.getMonth();
	const minDateYearMonth = minDate ? new Date(minDate.getFullYear(), minDate.getMonth()) : null;
	const maxDateYearMonth = maxDate ? new Date(maxDate.getFullYear(), maxDate.getMonth()) : null;

	const months = localeUtils.getMonths(locale).map((month, i) => {
		const newDate = new Date(currentYear, i);

		return {
			label: String(month),
			value: i,
			disabled: ( minDateYearMonth && newDate < minDateYearMonth || maxDateYearMonth && newDate > maxDateYearMonth ),
		};
	});

	const startYear = (minDate ? minDate.getFullYear() : (currentYear - numberOfYears));
	const endYear = (maxDate ? maxDate.getFullYear() : (currentYear + numberOfYears));

	const years = [];
	for (let i = startYear; i <= endYear; i += 1) {
		const newDate = new Date(i, currentMonth);

		years.push({
			label: String(i),
			value: i,
			disabled: ( minDateYearMonth && newDate < minDateYearMonth || maxDateYearMonth && newDate > maxDateYearMonth ),
		});
	}

	return {
		currentMonth: date.getMonth(),
		monthsOptions: months,
		currentYear: date.getFullYear(),
		yearsOptions: years,
	};
}

class UiDatepickerCaption extends PureComponent {
	static propTypes = {
		date: PropTypes.instanceOf(Date),
		locale: PropTypes.string,
		localeUtils: PropTypes.object,
		numberOfYears: PropTypes.number,
		onChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		numberOfYears: 10,
	}

	constructor (props, context) {
		super(props, context);

		this.state = buildState(props);
	}

	componentWillReceiveProps (nextProps) {
		this.setState(buildState(nextProps));
	}

	_handleMonthChange = (event) => {
		this.props.onChange(new Date(this.props.date.getFullYear(), event.currentTarget.value));
	}

	_handleYearChange = (event) => {
		this.props.onChange(new Date(event.currentTarget.value, this.props.date.getMonth()));
	}

	_handleControlsKeyDown = (event) => {
		event.stopPropagation();
	}

	render () {
		const {
			currentMonth,
			monthsOptions,
			currentYear,
			yearsOptions,
		} = this.state;

		return (
			<div className={baseCssClassName}>
				<div
					className={controlsCssClassName}
					onKeyDown={this._handleControlsKeyDown}
				>
					<div className={selectorCssClassName}>
						<FormSelect
							value={currentMonth}
							items={monthsOptions}
							onChange={this._handleMonthChange}
						/>
					</div>
					<div className={selectorCssClassName}>
						<FormSelect
							value={currentYear}
							items={yearsOptions}
							onChange={this._handleYearChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default UiDatepickerCaption;
