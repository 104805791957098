import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import mainConfig from '../../configs/mainConfig';
import { USER_PERMISSION__UPLOAD_IMAGE } from '../../constants/userPermissionsConstants';
import aclService from '../../services/acl';
import Alignment from "../Alignment";

import { getDictionary } from '../../appUtils/locale';

import './styles/Collection.css';


const i18nImageControls = getDictionary('image-controls');
const i18n = getDictionary('collection');

const baseCssClassName = 'collection';

const controlsCssClassName = `${baseCssClassName}__controls`;
const controlsItemsCssClassName = `${baseCssClassName}__controls-items`;
const controlsItemCssClassName = `${baseCssClassName}__controls-item`;
const controlsItemIconCssClassName = `${baseCssClassName}__controls-item-icon`;


export default class CollectionPage extends PureComponent {
	static propTypes = {
		lastImage: PropTypes.object,
		onFileChange: PropTypes.func.isRequired,
		onOpenLastImage: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired
	};

	_handleOpenLastImage = () => {
		const { lastImage } = this.props;

		if ( !lastImage ) {
			return;
		}

		const collectionId = this.context.router.route.match.params.id;

		this.props.onOpenLastImage({
			collectionHashName: collectionId,
			imageHashName: lastImage.hashname,
		});
	};

	_handleFileChange = (event) => {
		const inputFile = event.target;
		this.props.onFileChange(inputFile.files && inputFile.files[0]);
		inputFile.value = null;
	};

	render() {
		return (
			<div className={controlsCssClassName}>
				<Alignment
					horizontal={Alignment.horizontal.CENTER}
					vertical={Alignment.horizontal.CENTER}
				>
					<div className={controlsItemsCssClassName}>
						{
							this.props.lastImage &&
							<div
								className={controlsItemCssClassName}
								onClick={this._handleOpenLastImage}
							>
								<div
									className={`${controlsItemIconCssClassName} ${controlsItemIconCssClassName}__m-open-last-image`}
								/>
								<div

									dangerouslySetInnerHTML={{
										__html: i18n('buttons.open_last_image')
									}}
								/>
							</div>
						}
						{
							aclService.checkPermission(USER_PERMISSION__UPLOAD_IMAGE) &&
							<label
								title={`${i18nImageControls(
									'hint.first'
								)}\n${i18nImageControls(
									'hint.second'
								)}: ${mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS.join(
									', '
								)}`}
							>
								<div className={controlsItemCssClassName}>
									<input
										type={'file'}
										style={{ display: 'none' }}
										onChange={this._handleFileChange}
									/>
									<div
										className={`${controlsItemIconCssClassName} ${controlsItemIconCssClassName}__m-upload-image`}
									/>
									<div
										dangerouslySetInnerHTML={{
											__html: i18n('buttons.upload_image')
										}}
									/>
								</div>
							</label>
						}
					</div>
				</Alignment>
			</div>
		);
	}
}

