export default {
	'snapshot_link': '屏幕截图/快照链接',
	'report_link': '报告链接',

	'normal_appearance.title': '正常牙齿（未叠加复位、病理和治疗）',

	'findings.title': '发现',
	'findings.confirmed': '已确认',

	'legend.labels.normal': '完整健康的',
	'legend.labels.normal-with-findings': '未发现病理现象',
	'legend.labels.pathology': '病理',
	'legend.labels.missing': '缺失',
	
	'missing_teeth.title': '缺失牙齿',
	
	'prepared': '正在运行算法',
	
	'language.label': '语言',
	'show_links.label': '在报告中显示链接',
	'print_button': '打印',

	'comment.placeholder': '您的评论',

	'specialist.label': '专家',

	'fields.patient_id.label': '图像标识号ID',
	'fields.full_name.label': '病人全名',
	'fields.age.label': '年龄',
	'fields.gender.label': '性别',
	'fields.screening_date.label': '拍片日期',
	'fields.screening_date.placeholder': '日期',
	'fields.report_name.label': '报告类型',

	'report_name': 'OPG曲面体层摄影影像分析报告',

	'footer.comment': '在线机器只能做出试验性分析，需牙科专家提供及添加解读',
};
