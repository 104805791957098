import apiUtils from '../appUtils/apiUtils';

import api from './instance';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';


/**
 * @returns {Promise}
 */
function getList (options = {}) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections.list);

	return api.fullRequest(apiParams.method, apiParams.url, options);
}

function getCollection ({ hashName, params }) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['collection/read']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName), { params });
}

function createCollection (options = {}) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections.create);
	return api[apiParams.method](apiParams.url, options);
}

function saveCollection (hashName, data) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['collection/write']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName), data);
}

function removeCollection (options = {}) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections.remove);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', options.collectionId), {});
}

function copyCollection (hashName, data) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections.copy);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName), data);
}

function switchShareCollection (hashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['switch-share']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName));
}

function resetAnnotations (hashName) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['reset-annotations']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName));
}

function markReAnalyze (hashName, examinationId) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['examination/mark_reanalyze']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName).replace('{examinationId}', examinationId));
}

function unmarkReAnalyze (hashName, examinationId) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.collections['examination/unmark_reanalyze']);

	return api[apiParams.method](apiParams.url.replace('{collectionHashName}', hashName).replace('{examinationId}', examinationId));
}

export default {
	getList,
	getCollection,
	createCollection,
	saveCollection,
	removeCollection,
	copyCollection,
	switchShareCollection,
	resetAnnotations,
	markReAnalyze,
	unmarkReAnalyze,
};
