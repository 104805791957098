import apiActionTypes from '../../../actions/apiActionTypes';
import labelsTagsActionTypes from '../actions/labelsTagsActionTypes';


/**
 * @typedef {Object<LabelId, LabelTagId[]>} LabelsTagsStoreState
 */

/**
 *
 * @param {LabelsTagsStoreState} state
 * @param {ReduxAction} action
 * @return {LabelsTagsStoreState}
 */
export default function labelsTagsReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.labelsTags || state );

		case labelsTagsActionTypes.ACTION_LABELS_TAGS__ADD_TAG: {
			const labelId = action.payload.labelId;

			return {
				...state,
				[labelId]: (state[labelId] || []).concat(action.payload.tagId),
			};
		}

		case labelsTagsActionTypes.ACTION_LABELS_TAGS__REMOVE_TAG: {
			const labelId = action.payload.labelId;

			return {
				...state,
				[labelId]: state[labelId].filter((tagId) => tagId !== action.payload.tagId),
			};
		}

		default:
			return state;
	}
}
