import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
	LABELS_STAGE_COMPLETED,
	LABELS_STAGE_UNAIDED
} from '../../constants/labelsStageConstants';

import {
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__XRAY
} from '../../constants/imageConstants';

import mainConfig from '../../configs/mainConfig';

import { events } from '../../services/events';
import { get as getHelp } from '../../services/help';
import { trackEvent } from '../../integrations/mixpanel';

import editorActions from '../../actions/editorActions';
import imagesActions from '../../actions/imagesActions';

import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import userSelectors from '../../selectors/userSelectors';
import analyseSelectors from '../../selectors/analyseSelectors';

import {
	USER_PERMISSION__FLIP_IMAGE,
	USER_PERMISSION__ROTATE_IMAGE,
} from '../../constants/userPermissionsConstants';
	
import aclService from '../../services/acl';

import imageUtils from '../../appUtils/imageUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import { tryToHandleApiError } from '../../appUtils/errorUtils';

import FormCustomSelect from '../FormCustomSelect';
import Slider from '../Slider';
import Button from '../Button';
import FlipHorizontal from './FlipHorizontalButton';
import AddDiagnosisSelectBlock from './AddDiagnosisSelectBlock';
import EnhancedImageFilter from './EnhancedImageFilter';

import { getDictionary } from '../../appUtils/locale';

import './styles/Toolbar.css';


const i18n = getDictionary('toolbar');

const baseCssClassName = 'toolbar';
const itemsCssClassName = `${baseCssClassName}__items`;
const itemCssClassName = `${baseCssClassName}__item`;
const itemGroupCssClassName = `${baseCssClassName}__item-group`;
const itemResetButtonCssClassName = `${baseCssClassName}__item-reset-button`;


class Toolbar extends PureComponent {
	static propTypes = {
		currentImageId: PropTypes.string,
		editorMode: PropTypes.string.isRequired,
		filterSharpen: PropTypes.bool.isRequired,
		showCanvasGrid: PropTypes.bool.isRequired,
		showBoneLossStages: PropTypes.bool.isRequired,
		showFindingsOnImage: PropTypes.bool.isRequired,
		magnifyingGlassEnabled: PropTypes.bool.isRequired,
		isImageInAnalysis: PropTypes.bool.isRequired,
		isFDAAnnotationEnabled: PropTypes.bool.isRequired,
		labelsStage: PropTypes.string.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,

		onZoomIn: PropTypes.func.isRequired,
		onZoomOut: PropTypes.func.isRequired,
		onZoomDefault: PropTypes.func.isRequired,
		onChangeEditorMode: PropTypes.func.isRequired,
		onFlipImageHorizontal: PropTypes.func.isRequired,
		onFlipImageVertical: PropTypes.func.isRequired,
		onToggleShowFindings: PropTypes.func.isRequired,
		onToggleMagnifyingGlass: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		onSetFindingsMasks: PropTypes.func.isRequired,
		onSetHeatMap: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
	}
	
	state = {
		newClassId: null,
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.classIdForNewLabel !== this.state.newClassId &&
			this.props.availableLabels.some((labelData) => labelData.classId === nextProps.classIdForNewLabel)
		) {
			this.setState({
				newClassId: nextProps.classIdForNewLabel,
			});
		}
	}

	_getSelectModeItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__SELECT_MODE}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${
						mainConfig.TOOLBAR_ITEM__SELECT_MODE
					}`,
					this.props.editorMode ===
						mainConfig.EDITOR_MODE__SELECT_MODE &&
						`${itemCssClassName}__m-selected`
				])}
				onClick={() =>
					this.props.onChangeEditorMode({
						mode: mainConfig.EDITOR_MODE__SELECT_MODE
					})
				}
			/>
		);
	}

	_getZoomInItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__ZOOM_IN}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__ZOOM_IN}`,
				])}
				title={i18n('zoom_in')}
				onClick={this._handleZoomIn}
			/>
		);
	}

	_getZoomOutItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__ZOOM_OUT}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__ZOOM_OUT}`,
				])}
				title={i18n('zoom_out')}
				onClick={this._handleZoomOut}
			/>
		);
	}

	_getZoomDefaultItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__ZOOM_DEFAULT}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__ZOOM_DEFAULT}`,
				])}
				title={i18n('default_zoom')}
				onClick={this._handleZoomDefault}
			/>
		);
	}

	_getDrawModeItem () {
		const disabled = this.props.labelsStage === LABELS_STAGE_COMPLETED;
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__DRAW_MODE}
				className={classnames([
					itemGroupCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemGroupCssClassName}__m-${mainConfig.TOOLBAR_ITEM__DRAW_MODE}`
				])}
			>
				<div
					className={classnames([
						itemCssClassName,
						isSmallScreen() && `${itemCssClassName}__m-small`,
						disabled && `${itemCssClassName}__m-disabled`,
						`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__DRAW_MODE}`,
						(
							this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
							this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
							this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE
						) && `${itemCssClassName}__m-selected`,
					])}
					onClick={disabled ? ()=>{} : this._handleAddLabel}
				/>
				<AddDiagnosisSelectBlock
					label={this.state.newClassId}
					availableLabels={this.props.availableLabels}
					onSelectLabel={this._handleSelectLabel}
				/>
			</div>
		);
	}

	_getBrightnessItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__BRIGHTNESS}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__BRIGHTNESS}`
				])}
			>
				<div
					className={`${itemCssClassName}-label ${itemCssClassName}-label__m-${
						mainConfig.TOOLBAR_ITEM__BRIGHTNESS
					}`}
				>
					{i18n('brightness')}
					{this.props.imageBrightness !== 1 && (
						<div
							className={itemResetButtonCssClassName}
							onClick={() => {
								this.props.onSetImageBrightness({
									brightness: 1,
								});
							}}
						/>
					)}
				</div>
				<div>
					<Slider
						value={this.props.imageBrightness * 100}
						min={0}
						max={500}
						wrapperStyle={{
							width: isSmallScreen() ? 50 : 100,
							height: 17,
						}}
						railStyle={{
							background: '#989898',
							borderRadius: 1,
							height: 2,
						}}
						handleStyle={{
							background: '#989898',
							border: '2px solid #45464E',
						}}
						trackStyle={{
							background: '#989898',
							height: 2,
						}}
						onChange={this._handleBrightnessSliderChange}
					/>
				</div>
			</div>
		);
	}

	_getContrastItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__CONTRAST}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__CONTRAST}`
				])}
			>
				<div className={`${itemCssClassName}-label ${itemCssClassName}-label__m-${mainConfig.TOOLBAR_ITEM__CONTRAST}`}>
					{i18n('contrast')}
					{this.props.imageContrast !== 1 && (
						<div
							className={itemResetButtonCssClassName}
							onClick={() => {
								this.props.onSetImageContrast({
									contrast: 1,
								});
							}}
						/>
					)}
				</div>
				<div>
					<Slider
						value={this.props.imageContrast * 100}
						min={0}
						max={500}
						wrapperStyle={{
							width: isSmallScreen() ? 50 : 100,
							height: 17,
						}}
						railStyle={{
							background: '#989898',
							borderRadius: 1,
							height: 2,
						}}
						handleStyle={{
							background: '#989898',
							border: '2px solid #45464E',
						}}
						trackStyle={{
							background: '#989898',
							height: 2,
						}}
						onChange={this._handleContrastSliderChange}
					/>
				</div>
			</div>
		);
	}

	_getInvertItem () {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__INVERT}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__INVERT}`,
					this.props.imageInvert === 1 && `${itemCssClassName}__m-selected`,
				])}
				title={i18n('invert')}
				onClick={this._handleInvertChange}
			/>
		);
	}

	_getFlipHorizontalItem () {
		return (
			<div
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL}`
				])}
				title={i18n('flip.horizontal')}
				onClick={this._handleFlipHorizontalClick}
			/>
		);
	}

	_getFlipVerticalItem = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL}`
				])}
				title={i18n('flip.vertical')}
				onClick={this._handleFlipVerticalClick}
			/>
		);
	}

	_getRotateLeftItem = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__ROTATE_LEFT}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__ROTATE_LEFT}`
				])}
				title={i18n('rotate.left')}
				onClick={this._handleRotateLeftClick}
			/>
		);
	}

	_getRotateRightItem = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT}`
				])}
				title={i18n('rotate.right')}
				onClick={this._handleRotateRightClick}
			/>
		);
	}

	_getImageSharpen = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__IMAGE_SHARPEN}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__IMAGE_SHARPEN}`,
					this.props.filterSharpen && `${itemCssClassName}__m-selected`,
				])}
				title={i18n('sharpen')}
				onClick={this._handleImageSharpenClick}
			/>
		);
	}
	
	_getCanvasGrid = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__GRID}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__GRID}`,
					this.props.showCanvasGrid && `${itemCssClassName}__m-selected`,
				])}
				title={'Show grid'}
				onClick={this._handleToggleCanvasGridClick}
			/>
		);
	}

	_getBoneLevels = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__BONE_LEVELS}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__BONE_LEVELS}`,
					this.props.showBoneLossStages && `${itemCssClassName}__m-selected`,
				])}
				title={this.props.showBoneLossStages ? 'Hide bone levels' : 'Show bone levels'}
				onClick={this._handleToggleBoneLevelsClick}
			/>
		);
	};

	_getHideFindings = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__HIDE_FINDINGS}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__HIDE_FINDINGS}`,
					false === this.props.showFindingsOnImage && `${itemCssClassName}__m-selected`,
				])}
				title={false === this.props.showFindingsOnImage ? 'Show all' : 'Hide all'}
				onClick={() => {
					trackEvent('Toolbar Toggle Show Findings', { value: !this.props.showFindingsOnImage });
					this.props.onToggleShowFindings();
				}}
			/>
		);
	};

	_getFindingsMaskToggle = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS}`,
					true === this.props.areFindingsMasksEnabled && `${itemCssClassName}__m-selected`,
				])}
				title={false === this.props.areFindingsMasksEnabled ? 'Show masks' : 'Hide masks'}
				onClick={() => {
					trackEvent('Toolbar Findings Masks', { value: !this.props.areFindingsMasksEnabled });
					this.props.onSetFindingsMasks(!this.props.areFindingsMasksEnabled);
				}}
			/>
		);
	};

	_getHeatmapToggle = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__HEAT_MAP}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__HEAT_MAP}`,
					true === this.props.areHeatMapsEnabled && `${itemCssClassName}__m-selected`,
				])}
				title={false === this.props.areHeatMapsEnabled ? 'Show heatmap' : 'Hide heatmap'}
				onClick={() => {
					trackEvent('Toolbar Heatmap', { value: !this.props.areHeatMapsEnabled });
					this.props.onSetHeatMap(!this.props.areHeatMapsEnabled);
				}}
			/>
		);
	};

	_getEnhancedImageFilter = () => {
		return (
			<EnhancedImageFilter
				className={itemCssClassName}
				isSmall={isSmallScreen()}
			/>
		);
	};

	_getMagnifyingGlass = () => {
		return (
			<div
				key={mainConfig.TOOLBAR_ITEM__MAGNIFYING_GLASS}
				className={classnames([
					itemCssClassName,
					isSmallScreen() && `${itemCssClassName}__m-small`,
					`${itemCssClassName}__m-${mainConfig.TOOLBAR_ITEM__MAGNIFYING_GLASS}`,
					true === this.props.magnifyingGlassEnabled && `${itemCssClassName}__m-selected`,
				])}
				title={false === this.props.magnifyingGlassEnabled ? 'Magnifying glass on' : 'Magnifying glass off'}
				onClick={this.props.onToggleMagnifyingGlass}
			/>
		);
	};

	_getItem = itemKey => {
		const { isOwnImage } = this.props;
		const isFlipAvailable = aclService.checkPermission(USER_PERMISSION__FLIP_IMAGE);
		const isRotateAvailable = aclService.checkPermission(USER_PERMISSION__ROTATE_IMAGE);
		switch (itemKey) {
			case mainConfig.TOOLBAR_ITEM__SELECT_MODE:
				return isOwnImage ? this._getSelectModeItem() : null;

			case mainConfig.TOOLBAR_ITEM__ZOOM_IN:
				return this._getZoomInItem();

			case mainConfig.TOOLBAR_ITEM__ZOOM_OUT:
				return this._getZoomOutItem();

			case mainConfig.TOOLBAR_ITEM__ZOOM_DEFAULT:
				return this._getZoomDefaultItem();

			case mainConfig.TOOLBAR_ITEM__DRAW_MODE:
				return isOwnImage && !isSmallScreen() ? this._getDrawModeItem() : null;

			case mainConfig.TOOLBAR_ITEM__BRIGHTNESS:
				return this._getBrightnessItem();

			case mainConfig.TOOLBAR_ITEM__CONTRAST:
				return this._getContrastItem();

			case mainConfig.TOOLBAR_ITEM__INVERT:
				return this._getInvertItem();

			case mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL:
				return isOwnImage && isFlipAvailable ? (
					<FlipHorizontal
						key={mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL}
						action={this._handleFlipHorizontalClick}
					/>
				) : null;

			case mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL:
				return isOwnImage && isFlipAvailable
					? this._getFlipVerticalItem()
					: null;

			case mainConfig.TOOLBAR_ITEM__ROTATE_LEFT:
				return isOwnImage && isRotateAvailable
					? this._getRotateLeftItem()
					: null;

			case mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT:
				return isOwnImage && isRotateAvailable
					? this._getRotateRightItem()
					: null;

			case mainConfig.TOOLBAR_ITEM__IMAGE_SHARPEN:
				return this._getImageSharpen();
			
			case mainConfig.TOOLBAR_ITEM__GRID:
				return this._getCanvasGrid();

			case mainConfig.TOOLBAR_ITEM__BONE_LEVELS:
				return this._getBoneLevels();

			case mainConfig.TOOLBAR_ITEM__HIDE_FINDINGS:
				return this._getHideFindings();

			case mainConfig.TOOLBAR_ITEM__MAGNIFYING_GLASS:
				return this._getMagnifyingGlass();

			case mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS:
				return this._getFindingsMaskToggle();

			case mainConfig.TOOLBAR_ITEM__HEAT_MAP:
				return this._getHeatmapToggle();

			case mainConfig.TOOLBAR_ITEM__ENHANCED_IMAGE_FILTER:
				return this._getEnhancedImageFilter();

			default:
				return null;
		}
	}

	_handleBrightnessSliderChange = percent => {
		trackEvent('Toolbar Brightness Change');
		this.props.onSetImageBrightness({
			brightness: percent / 100,
		});
	};
	
	_handleAddLabel = () => {
		trackEvent('Toolbar Add Finding');
		const classId = this.state.newClassId;
		if ( !classId ) {
			return;
		}

		if (
			this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
			this.props.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
			this.props.editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH
		) {
			this.props.onChangeEditorMode({
				mode: mainConfig.EDITOR_MODE__SELECT_MODE,
			});
			return;
		}

		this.props.onAddDiagnosis({
			classId,
			editorMode: classId === 'tooth'
				? mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX
				: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
			noParent: true,
			requiredShape: labelsUtils.isShapeRequiredForClass(classId),
		});
	}

	_handleSelectLabel = (newClassId) => {
		this.setState({
			newClassId,
		});
	}

	_handleInvertChange = () => {
		trackEvent('Toolbar Invert Click', { value: this.props.imageInvert === 1 ? 0 : 1 });
		this.props.onSetImageInvert({
			invert: this.props.imageInvert === 1 ? 0 : 1,
		});
	};

	_handleContrastSliderChange = percent => {
		trackEvent('Toolbar Contrast Changed', { value: percent / 100 });
		this.props.onSetImageContrast({
			contrast: percent / 100,
		});
	};

	_handleFlipHorizontalClick = () => {
		trackEvent('Toolbar Horizontal Flip');
		imageUtils.checkHasUnsavedChanged( () => {
			this.props.onFlipImageHorizontal({
				imageId: this.props.currentImageId,
			});
		}, true);
	};

	_handleFlipVerticalClick = () => {
		trackEvent('Toolbar Vertical Flip');

		imageUtils.checkHasUnsavedChanged(() => {
			this.props.onFlipImageVertical({
				imageId: this.props.currentImageId,
			});
		}, true);
	}

	_handleRotateLeftClick = () => {
		trackEvent('Toolbar Rotate Left');

		imageUtils.checkHasUnsavedChanged(() => {
			this.props.onRotateLeft({
				imageId: this.props.currentImageId,
			});
		}, true);
	};

	_handleRotateRightClick = () => {
		trackEvent('Toolbar Rotate Right');

		imageUtils.checkHasUnsavedChanged(() => {
			this.props.onRotateRight({
				imageId: this.props.currentImageId,
			});
		}, true);
	};

	_handleImageSharpenClick = () => {
		trackEvent('Toolbar Sharpen', { value: !this.props.filterSharpen });

		this.props.onToggleFilterSharpen();
	};
	
	_handleToggleCanvasGridClick = () => {
		trackEvent('Toolbar Grid', { value: !this.props.showCanvasGrid });

		this.props.onToggleShowGrid();
	};

	_handleToggleBoneLevelsClick = () => {
		trackEvent('Toolbar Bone Levels', { value: !this.props.showBoneLossStages });

		this.props.onToggleBoneLevels();
	};

	_handleZoomIn = () => {
		trackEvent('Toolbar Zoom In');

		this.props.onZoomIn();
		events.emit('toolbar.zoom.in.requested');
	};

	_handleZoomOut = () => {
		trackEvent('Toolbar Zoom Out');

		this.props.onZoomOut();
		events.emit('toolbar.zoom.out.requested');
	};

	_handleZoomDefault = () => {
		trackEvent('Toolbar Zoom Default');

		this.props.onZoomDefault();
		events.emit('toolbar.zoom.default.requested');
	};

	_handleFDAAnalyzeClick = () => {
		trackEvent('Toolbar FDA Analyze');

		imageUtils.checkHasUnsavedChanged(async () => {
			try {
				if (this.props.isFDAAnnotationEnabled) {
					await imageUtils.showFirstStateConfirmation();
				}

				const help = getHelp();
				if ( help !== null ) {
					help.sendAnalyzedEvent(window.location.href);
				}

				this.props.onAnalyzeImage().catch(error => {
					tryToHandleApiError(error);
				});
			}
			catch (error) {
				// do nothing
			}
		},
		false, true);
	};

	renderItems = () => {
		return this.props.items.map(itemKey => this._getItem(itemKey));
	}

	renderFDAControls = () => {
		const {labelsStage, imageType, isOwnImage} = this.props;

		const isAnalyzeAvailable = (
			isOwnImage &&
			(
				imageType === IMAGE_TYPE__PAN ||
				imageType === IMAGE_TYPE__XRAY ||
				imageType === IMAGE_TYPE__BITEWING ||
				imageType === IMAGE_TYPE__PERIAPICAL
			)
		);

		if (!isAnalyzeAvailable) {
			return null;
		}

		return <div
			key={'fda_analyze_button'}
			className={classnames([
				itemCssClassName,
				isSmallScreen() && `${itemCssClassName}__m-small`,
				`${itemCssClassName}__m-analyze`,
			])}
		>
			{labelsStage === LABELS_STAGE_UNAIDED ?
				<Button
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.SMALL}
					disabled={this.props.isImageInAnalysis }
					title={'Enable AI Predictions'}
					onClick={this._handleFDAAnalyzeClick}
				>
					Enable AI Predictions
				</Button>  :
				<Button
					theme={Button.AVAILABLE_THEMES.SECONDARY}
					size={Button.AVAILABLE_SIZES.SMALL}
					disabled
				>
					{labelsStage === LABELS_STAGE_COMPLETED ? 'Image Analysis Completed' : 'Computer-aided Evaluation'}
				</Button>
			}
		</div>
	}

	render() {
		return (
			<div className={baseCssClassName}>
				{this.props.currentImageId && (
					<div className={itemsCssClassName}>
						{this.renderItems()}
						{this.props.isFDAAnnotationEnabled && this.renderFDAControls()}
					</div>
				)}
			</div>
		);
	}
}

export default connect(
	state => {
		const { imageBrightness, imageContrast, imageInvert } = editorSelectors.selectEditor(
			state
		);

		const currentImage = imagesSelectors.selectImageById(state, {
			id: editorSelectors.selectCurrentImageId(state)
		});

		const editorData = editorSelectors.selectEditor(state);

		return {
			availableLabels: labelsSelectors.selectAvailableLabelsByImageType(state, {
				type: currentImage.image_type,
				onlyParents: true,
			}),
			imageType: currentImage.image_type,
			currentImageId: editorSelectors.selectCurrentImageId(state),
			editorMode: editorData.editorMode,
			classIdForNewLabel: editorData.classIdForNewLabel,
			isOwnImage: currentImage ? currentImage.isOwn : false,
			isFDAAnnotationEnabled: userSelectors.selectIsFDAAnnotationEnabled(state),
			isImageInAnalysis: analyseSelectors.selectIsImageInAnalysis(state, { imageId: editorSelectors.selectCurrentImageId(state)}),
			labelsStage: editorSelectors.selectLabelsStage(state),
			type: currentImage ? imageUtils.getGeneralType(currentImage) : null,
			filterSharpen: editorData.filterSharpen,
			showCanvasGrid: editorData.showCanvasGrid,
			showBoneLossStages: editorData.showBoneLossStages,
			showFindingsOnImage: editorData.showFindingsOnImage,
			imageBrightness,
			imageContrast,
			imageInvert,
			magnifyingGlassEnabled: editorData.magnifyingGlassEnabled,
			areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
			areHeatMapsEnabled: editorData.areHeatMapsEnabled,
		};
	},
	(dispatch) => ({
		onZoomIn: () => dispatch(editorActions.zoomIn()),
		onZoomOut: () => dispatch(editorActions.zoomOut()),
		onZoomDefault: () => dispatch(editorActions.zoomDefault()),
		onAddLabel: (data) => dispatch(editorActions.addLabel(data)),
		onChangeEditorMode: data =>
			dispatch(editorActions.changeEditorMode(data)),
		onSetImageBrightness: data =>
			dispatch(editorActions.setImageBrightness(data)),
		onSetImageContrast: data =>
			dispatch(editorActions.setImageContrast(data)),
		onSetImageInvert: data =>
			dispatch(editorActions.setImageInvert(data)),
		onFlipImageHorizontal: (data) => dispatch(imagesActions.flipHorizontal(data)),
		onFlipImageVertical: (data) => dispatch(imagesActions.flipVertical(data)),
		onRotateLeft: (data) => dispatch(imagesActions.rotateLeft(data)),
		onRotateRight: (data) => dispatch(imagesActions.rotateRight(data)),
		onToggleFilterSharpen: () => dispatch(editorActions.toggleFilterSharpen()),
		onToggleShowGrid: () => dispatch(editorActions.toggleShowCanvasGrid()),
		onToggleBoneLevels: () => dispatch(editorActions.toggleShowBoneLossStages()),
		onAddDiagnosis: (data) => dispatch(editorActions.addLabel(data)),
		onToggleShowFindings: (data) => dispatch(editorActions.toggleShowFindings(data)),
		onToggleMagnifyingGlass: (data) => dispatch(editorActions.toggleMagnifyingGlass(data)),
		onAnalyzeImage: () => dispatch(editorActions.analyzeCurrentImage()),
		onSetFindingsMasks: (value) => dispatch(editorActions.setFindingsMasks(value)),
		onSetHeatMap: (value) => dispatch(editorActions.updateData({
			data: {
				areHeatMapsEnabled: value,
			},
		})),
	})
)(Toolbar);
