import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import Button from '../Button';
import Loading from '../Loading';

import { SUBMIT_STATUSES } from './PasswordResetConstants';

import './styles/PasswordReset.css';


const baseCssClassName = 'password-reset';
const wrapperCssClassName = `${baseCssClassName}__wrapper`;
const formCssClassName = `${baseCssClassName}__form`;
const headerCssClassName = `${baseCssClassName}__header`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const errorCssClassname = `${baseCssClassName}__error`;
const successCssClassname = `${baseCssClassName}__success`;


export default class PasswordReset extends PureComponent {
	static propTypes = {
		password: PropTypes.string.isRequired,
		passwordConfirm: PropTypes.string.isRequired,
		submitStatus: PropTypes.oneOf(Object.values(SUBMIT_STATUSES)).isRequired,
		errors: PropTypes.arrayOf(PropTypes.string).isRequired,
		onFieldChange: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handlePasswordChange = (event) => {
		this.props.onFieldChange('password', event.target.value);
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handlePasswordConfirmChange = (event) => {
		this.props.onFieldChange('passwordConfirm', event.target.value);
	};

	_renderForm () {
		const isAllowedPassword = (
			this.props.password.length > 0 &&
			this.props.password === this.props.passwordConfirm
		);

		return (
			<form className={formCssClassName} onSubmit={this.props.onSubmit}>
				<h2 className={headerCssClassName}>Password Reset</h2>
				<FormLabel
					element={
						(
							<FormInput
								name={'password'}
								value={this.props.password}
								type={'password'}
								isInvalid={this.props.errors.length > 0}
								autofocus
								block
								disabled={this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS}
								onChange={this._handlePasswordChange}
							/>
						)
					}
					text={'New password'}
					textPosition={FormLabel.textPosition.TOP}
				/>
				<FormLabel
					element={
						(
							<FormInput
								name={'passwordConfirm'}
								value={this.props.passwordConfirm}
								type={'password'}
								block
								disabled={this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS}
								isInvalid={this.props.passwordConfirm.length > 0 && false === isAllowedPassword}
								onChange={this._handlePasswordConfirmChange}
							/>
						)
					}
					text={'Confirm password'}
					textPosition={FormLabel.textPosition.TOP}
				/>
				{this._renderErrors()}
				{(this.props.passwordConfirm.length > 0 && false === isAllowedPassword) && (
					<div className={errorCssClassname}>
						The passwords should be equal.
					</div>
				)}
				<div className={buttonsCssClassName}>
					{this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
						? (<Loading width={40} height={40} />)
						: (
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.MEDIUM}
								type={Button.AVAILABLE_TYPES.SUBMIT}
								disabled={
									false === isAllowedPassword ||
									this.props.submitStatus === SUBMIT_STATUSES.IN_PROGRESS
								}
							>Reset password</Button>
						)
					}
				</div>
			</form>
		);
	}

	_renderErrors () {
		if ( this.props.submitStatus === SUBMIT_STATUSES.FAILED ) {
			if ( this.props.errors.length > 0 ) {
				return (
					<div className={errorCssClassname}>
						{this.props.errors.map((error, index) => {
							let message = error;
							// switch (error) {
							// 	case 'PASSWORD_IS_TOO_SHORT':
							// 		message = 'This password is too short. It must contain at least 8 characters.';
							// 		break;
							//
							// 	case 'PASSWORD_IS_TOO_COMMON':
							// 		message = 'This password is too common.'
							// 		break;
							//
							// 	case 'PASSWORD_IS_ENTIRELY_NUMERIC':
							// 		message = 'This password is entirely numeric.';
							// 		break;
							//
							// 	case 'PASSWORD_IS_TOO_SIMILAR':
							// 		message = 'This password is similar to login or email';
							// 		break;
							//
							// 	default:
							// 		message = error;
							// 		break;
							// }
							return (<div key={index} style={{ padding: `5px 0` }}>{message}</div>);
						})}
					</div>
				);
			}

			return (
				<div className={errorCssClassname}>
					An error occurred when sending request. Please try again.
				</div>
			);
		}

		return null;
	}

	_renderSuccess () {
		return (
			<div className={successCssClassname}>
				Password has been successfully changed.
			</div>
		);
	}

	render () {
		let content;
		switch (this.props.submitStatus) {
			case SUBMIT_STATUSES.SUCCESS:
				content = this._renderSuccess();
				break;

			default:
				content = this._renderForm();
				break;
		}

		return (
			<div className={baseCssClassName}>
				<div className={wrapperCssClassName}>
					{content}
				</div>
			</div>
		);
	}
}
