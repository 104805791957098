import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles/ResolverConfirmPopup.css';


const baseCssClassName = 'resolver-config-popup';
const headingCssClassName = `${baseCssClassName}__heading`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonCssClassName = `${baseCssClassName}__button`;


export default class ResolverConfirmPopup extends Component {
	static propTypes = {
		onAgree: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}
	
	static defaultProps = {
		values: [],
	}
	
	_handleAgreeButtonClick = () => {
		this.props.onAgree();
	}
	
	_handleCancelButtonClick = () => {
		this.props.onClose();
	}
	
	render () {
		return (
			<div className={baseCssClassName}>
				<div className={headingCssClassName}>
					Click to confirm this tooth is missing.<br/>
					Warning: All findings attached to this tooth will be removed.
				</div>
				<div className={buttonsCssClassName}>
					<div
						className={`${buttonCssClassName} ${buttonCssClassName}__m-agree`}
						title={'Confirm'}
						onClick={this._handleAgreeButtonClick}
					/>
					<div
						className={`${buttonCssClassName} ${buttonCssClassName}__m-cancel`}
						title={'Cancel'}
						onClick={this._handleCancelButtonClick}
					/>
				</div>
			</div>
		);
	}
}
