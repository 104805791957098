import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/FormSelect.css';


const baseCssClassName = 'form-select';
const elementCssClassName = `${baseCssClassName}__element`;
const visibleCssClassName = `${baseCssClassName}__visible`;
const visibleTextCssClassName = `${baseCssClassName}__visible-text`;
const visibleControlCssClassName = `${baseCssClassName}__visible-control`;


class FormSelect extends PureComponent {
	static propTypes = {
		block: PropTypes.bool,
		disabled: PropTypes.bool,
		isInvalid: PropTypes.bool,
		items: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
			label: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
			disabled: PropTypes.bool,
		})).isRequired,
		placeholder: PropTypes.string,

		onChange: PropTypes.func,
		onGetRef: PropTypes.func,
	}

	_handleChange = (event) => {
		const {
			onChange,
		} = this.props;

		if ( onChange ) {
			onChange(event);
		}
	}
	
	_getLabel () {
		const {
			value,
			placeholder,
		} = this.props;

		const item = this.props.items.filter((item) => item.value === value)[0];
		
		return item ? item.label : placeholder || null;
	}

	_renderSelect () {
		const {
			items,
			block,
			...selectProps
		} = this.props;

		return (
			<select
				{...selectProps}
				className={elementCssClassName}
				onChange={this._handleChange}
			>
				{items.map((item, i) => (<option key={i} value={item.value} disabled={item.disabled}>{item.label}</option>))}
			</select>
		);
	}
	
	_renderVisible () {
		return (
			<div className={visibleCssClassName}>
				<div className={visibleTextCssClassName}>{this._getLabel()}</div>
				<div className={visibleControlCssClassName} />
			</div>
		);
	}

	render () {
		const {
			block,
			disabled,
			isInvalid,
		} = this.props;

		return (
			<div className={classnames([
				baseCssClassName,
				block && `${baseCssClassName}__m-block`,
				disabled && `${baseCssClassName}__m-disabled`,
				isInvalid && `${baseCssClassName}__m-invalid`,
			])}>
				{this._renderSelect()}
				{this._renderVisible()}
			</div>
		);
	}
}

export default FormSelect;
