import lodashGet from 'lodash/get';

import collectionsSelectors from '../selectors/collectionsSelectors';
import editorSelectors from '../selectors/editorSelectors';
import imagesSelectors from '../selectors/imagesSelectors';
import labelGetters from '../modules/labels/selectors/labelGetters';
import imagesLabelsSelectors from "../modules/labels/selectors/imagesLabelsSelectors";
import labelsSelectors from '../modules/labels/selectors/labelsSelectors';
import labelTagGetter from '../modules/label-tags/selectors/labelTagGetter';
import labelTagsSelectors from '../modules/label-tags/selectors/labelTagsSelectors';


const selectCurrentCollectionHotKeys = (state, { action }) => {
	const image = imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	});
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});

	return lodashGet(collection, [ 'hotkeys', action, image.image_type ], {});
};

const selectAvailableLabelsForImageType = (state, options) => {
	const result = [];
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const labels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});

			result[labelGetters.getLabelClassId(label)] = true;

			return result;
		}, {});

	collection.labels.forEach((labelData) => {
		if ( labelData.image_type !== options.type ) {
			return;
		}

		const classId = labelGetters.getLabelClassId(labelData);
		const relations = selectParentClass(state, { classId });

		if ( options.onlyParents ) {
			if ( relations.length > 0 ) {
				return result;
			}
		}
		else if ( options.classId ) {
			if ( options.onlyInRelation ) {
				if (
					!relations.some((relation) => relation.subject_label === options.classId)
				) {
					return result;
				}
			}
			else {
				if (
					relations.length > 0 &&
					!relations.some((relation) => relation.subject_label === options.classId)
				) {
					return result;
				}
			}
		}

		if ( labelData.is_unique_for_image && labels[classId] ) {
			return result;
		}

		if ( options.excludeParent && classId === options.classId ) {
			return result;
		}

		result.push(classId);
	});

	return result;
};

const selectAvailableTagsForClass = (state, options) => {
	const result = [];
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	const existedTags = {};
	imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.forEach((labelId) => {
			labelTagsSelectors.selectLabelTagsByLabelId(state, {
				labelId,
			})
				.forEach((tag) => {
					const tagKey = labelTagGetter.getTagKey(tag);
					existedTags[tagKey] = true;
				});
		}, {});

	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});

	const classId = options.classId;

	collection.labels.forEach((labelData) => {
		if (
			labelData.image_type === currentImage.image_type &&
			labelData.class_id === classId &&
			labelData.attributes
		) {
			labelData.attributes.forEach((attrData) => {
				let canAdd = true;
				if ( attrData.key ) {
					if ( labelData.is_attributes_unique && existedTags[attrData.key] ) {
						canAdd = false;
					}

					result.push({
						tag: attrData,
						canAdd,
					});
				}
			});
		}
	});

	return result;
};

const selectParentClass = (state, { classId }) => {
	const result = [];
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});

	const imageType = currentImage.image_type;

	(collection.label_relations || []).forEach((item) => {
		if ( item.image_type !== imageType ) {
			return;
		}

		if ( item.relation && item.relation.object_label === classId ) {
			result.push(item.relation);
		}
	});

	return result;
};

const selectChildrenClasses = (state, { classId }) => {
	const result = [];
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	const imageType = currentImage.image_type;

	(collection.label_relations || []).forEach((item) => {
		if ( item.image_type !== imageType ) {
			return;
		}

		if ( item.relation && item.relation.subject_label === classId ) {
			result.push(item.relation);
		}
	});

	return result;
};

/**
 * @param {StoreState} state
 * @return {string[]}
 */
const selectAvailableExaminations = (state) => {
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	return Object.keys((collection.images || []).reduce((result, image) => {
		if ( true !== result[image.examination] ) {
			result[image.examination] = true;
		}
		
		return result;
	}, {}));
};

/**
 * @param {StoreState} state
 * @param {Object} options
 * @param {string} options.examination
 *
 * @return {CollectionImage[]}
 */
const selectExaminationImages = (state, { examination }) => {
	const collection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});
	return (collection.images || [])
		.filter((image) => image.examination === examination)
		.map((image) => imagesSelectors.selectImageByHashName(state, { hashname: image.hashname }));
};


export default {
	selectAvailableLabelsForImageType,
	selectAvailableTagsForClass,
	selectCurrentCollectionHotKeys,
	selectParentClass,
	selectChildrenClasses,
	selectAvailableExaminations,
	selectExaminationImages,
};
