import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import queryString from 'qs';
import { events } from '../../services/events';
import { trackEvent } from '../../integrations/mixpanel';
import editorActions from '../../actions/editorActions';
import collectionsActions from '../../actions/collectionsActions';
import collectionsSelectors from '../../selectors/collectionsSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import Collections from './Collections';
import Loading from '../Loading';
import Pagination from '../Pagination';
import Button from '../Button';
import Alignment from '../Alignment';

import './styles/Collections.css';


const LOADING_STATE = {
	IDLE: 'idle',
	LOADING: 'loading',
	ERROR: 'error',
};

const baseCssClassName = 'collections';
const paginationCssClassName = `${baseCssClassName}__pagination`;
const statusIndicatorCssClassName = `${baseCssClassName}__status-indicator`;


export class CollectionsHoc extends Component {
	static propTypes = {
		collectionSearch: PropTypes.string.isRequired,
		onLoadCollections: PropTypes.func.isRequired,
		onSetCollectionSearch: PropTypes.func.isRequired,
	};
	
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		
		let search = props.location.search.indexOf('?') === 0 ? props.location.search.substr(1) : props.location.search;
		let currentPage = parseInt(lodashGet(queryString.parse(search), 'page'), 10);

		if ( isNaN(currentPage) ) {
			currentPage = 1;
		}
		
		this.state = {
			loadingState: LOADING_STATE.IDLE,
			image: null,
			currentPage,
			totalPages: 1,
		};
	}
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		let search = nextProps.location.search.indexOf('?') === 0 ? nextProps.location.search.substr(1) : nextProps.location.search;
		const nextPage = parseInt(lodashGet(queryString.parse(search), 'page'), 10);

		if ( !isNaN(nextPage) && this.state.currentPage !== nextPage ) {
			this.setState({
				currentPage: nextPage,
			}, () => {
				this._loadCollections({ page: this.state.currentPage });
			});
		}
	}
	
	componentDidMount () {
		trackEvent('Collections screen opened');
		this._loadCollections({ page: this.state.currentPage });
		events.on('collection-removed', this._handleReloadCollections);
		events.on('collection-added', this._handleReloadCollections);
		events.on('collection-edited', this._handleReloadCollections);
		events.on('collection-button-click', this._handleCollectionsButtonClick);
	}

	componentWillUnmount () {
		this.props.onSetCollectionSearch('');
		
		this.state = null;
		
		events.removeListener('collection-removed', this._handleReloadCollections);
		events.removeListener('collection-added', this._handleReloadCollections);
		events.removeListener('collection-edited', this._handleReloadCollections);
		events.removeListener('collection-button-click', this._handleCollectionsButtonClick);
	}
	
	_handleReloadCollections = () => {
		if ( !this.state ) {
			return;
		}

		this._loadCollections({ page: this.state.currentPage });
	};
	
	_handleCollectionsButtonClick = () => {
		this._loadCollections({ page: this.state.currentPage });
	};
	
	_loadCollections ({ page }) {
		if ( this.state.loadingState === LOADING_STATE.LOADING ) {
			return;
		}

		this.setState({
			loadingState: LOADING_STATE.LOADING,
		});

		this.props.onLoadCollections({
			params: {
				page,
				page_size: 6,
				search: this.props.collectionSearch.length > 0 ? this.props.collectionSearch : null,
			},
		})
			.then((data) => {
				if ( !this.state ) {
					return;
				}
				
				const meta = data.meta;
				
				this.setState({
					loadingState: LOADING_STATE.IDLE,
					totalPages: meta.total_pages,
				});
			})
			.catch((error) => {
				if ( !this.state ) {
					return;
				}
				
				if ( lodashGet(error, 'response.data.detail') === 'Invalid page.' ) {
					this.context.router.history.push('/');
					return;
				}
				
				console.dir();
				//Invalid page.

				this.setState({
					loadingState: LOADING_STATE.ERROR,
				});
			});
	}
	
	_setPage (page) {
		this.context.router.history.push('/collection-list/?page=' + page);
	}
	
	_handleLoadFirstPage = () => {
		trackEvent('Collection pagination first page click');
		this._setPage(1);
	};
	
	_handleLoadPrevPage = () => {
		trackEvent('Collection pagination prev page click');
		this._setPage(Math.max(0, this.state.currentPage - 1));
	};
	
	_handleLoadNextPage = () => {
		trackEvent('Collection pagination next page click');
		this._setPage(Math.min(this.state.totalPages, this.state.currentPage + 1));
	};
	
	_handleLoadLastPage = () => {
		trackEvent('Collection pagination last page click');
		this._setPage(this.state.totalPages);
	};
	
	_handleTryAgainLoading = () => {
		this._loadCollections({ page: this.state.currentPage });
	};
	
	_handleGoToPage = (page) => {
		trackEvent('Collection pagination page entered');
		this._setPage(page);
	};
	
	_renderContent () {
		if ( this.state.loadingState === LOADING_STATE.ERROR ) {
			return (
				<div className={statusIndicatorCssClassName}>
					<div>
						<div style={{ paddingBottom: 32 }}>
							An error occurred when loading. Please try again.
						</div>
						<Alignment horizontal={Alignment.horizontal.CENTER}>
							<Button
								size={Button.AVAILABLE_SIZES.LARGE}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								onClick={this._handleTryAgainLoading}
							>Try again</Button>
						</Alignment>
					</div>
				</div>
			);
		}
		
		if ( this.state.loadingState === LOADING_STATE.IDLE ) {
			const index = (this.state.currentPage - 1) * 6;
			const collections = this.props.collections.slice(index, index + 6);
			return (
				<Fragment>
					<Collections collections={collections} />
					{collections.length > 0 &&
						<div className={paginationCssClassName}>
							<Pagination
								currentPage={this.state.currentPage}
								totalPages={this.state.totalPages}
								firstPageDisabled={!(this.state.currentPage !== 1)}
								prevPageDisabled={!(this.state.currentPage > 1)}
								nextPageDisabled={!(this.state.currentPage < this.state.totalPages)}
								lastPageDisabled={!(this.state.currentPage !== this.state.totalPages)}
								onGoFirstPage={this._handleLoadFirstPage}
								onGoPrevPage={this._handleLoadPrevPage}
								onGoNextPage={this._handleLoadNextPage}
								onGoLastPage={this._handleLoadLastPage}
								onGoToPage={this._handleGoToPage}
							/>
						</div>
					}
				</Fragment>
			);
		}
		
		return (
			<div className={statusIndicatorCssClassName}>
				<Loading />
			</div>
		);
	}

	render() {
		return (
			<div className={baseCssClassName}>
				{this._renderContent()}
			</div>
		);
	}
}

export default connect(
	(state) => {
		const collections = collectionsSelectors.selectCollectionItems(state);
		const editorData = editorSelectors.selectEditor(state);
		return {
			collections: collectionsSelectors.selectOrderedCollections(state).map((collectionHashName) => (collections[collectionHashName])),
			collectionSearch: editorData.collectionSearch,
		};
	},
	(dispatch) => ({
		onLoadCollections: (params) => dispatch(collectionsActions.getList(params)),
		onSetCollectionSearch: (value) => dispatch(editorActions.setCollectionSearch(value)),
	})
)(CollectionsHoc);
