import editorActions from '../../../actions/editorActions';
import helpers from '../../../appUtils/helpers';
import apiActions from '../../../actions/apiActions';


/**
 * Adds a new tag to label.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {LabelTag} options.tag
 * @return {Function}
 */
function addTag (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.addLabelTag({
				storeState,
				labelId: options.labelId,
				tag: options.tag,
			});

			dispatch(apiActions.setData(data));
		}));
	};
}

/**
 * Removes a tag from label.
 *
 * @param {Object} options
 * @param {LabelId} options.labelId
 * @param {LabelTagId} options.tagId
 * @return {Function}
 */
function removeTag (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.removeLabelTag({
				storeState,
				labelId: options.labelId,
				tagId: options.tagId,
			});

			dispatch(apiActions.setData(data));
		}));
	};
}

/**
 * Changes a tag to another.
 *
 * @param {Object} options
 * @param {LabelTagId} options.tagId
 * @param {LabelTag} options.tag
 * @return {Function}
 */
function changeTag (options) {
	return (dispatch, getState) => {
		dispatch(editorActions.putInImageHistory(async () => {
			const storeState = getState();

			const data = helpers.changeLabelTag({
				storeState,
				tagId: options.tagId,
				tag: options.tag,
			});

			dispatch(apiActions.setData(data));
		}));
	};
}


export default {
	addTag,
	removeTag,
	changeTag,
};
