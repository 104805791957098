import commonActionTypes from '../actions/commonActionTypes';

/**
 * @typedef {Object} Country
 *
 * @property {string} code
 * @property {string} name
 */

/**
 * @typedef {Country[]} CountriesStoreState
 */

/**
 *
 * @param {CountriesStoreState} state
 * @param {ReduxAction} action
 * @return {CountriesStoreState}
 */
export default function countriesReducer (state = {}, action) {
	switch (action.type) {
		case commonActionTypes.ACTION_COUNTRIES__SET_DATA:
			return action.payload;

		default:
			return state;
	}
}
