import React, { PureComponent } from 'react';
import reportTeethConfig from '../../../../components/Report/reportTeethConfig';


const baseCssClassName = 'resolver-report-dental-chart-legend';
const legendItemCssClassName = `${baseCssClassName}__item`;
const legendItemTitleCssClassName = `${baseCssClassName}__title`;
const legendIndicatorCssClassName = `${baseCssClassName}__indicator`;


export default class ResolverReportDentalChartLegend extends PureComponent {
	render () {
		return (
			<div className={baseCssClassName}>
				{reportTeethConfig.getIndicators().map((indicator) => (
					<div
						key={indicator.key}
						className={legendItemCssClassName}
					>
						<div
							className={legendIndicatorCssClassName}
							dangerouslySetInnerHTML={{
								__html: reportTeethConfig.TEETH_STATUS_INDICATORS[indicator.key],
							}}
						/>
						<div className={legendItemTitleCssClassName}>{indicator.title}</div>
					</div>
				))}
			</div>
		);
	}
}
