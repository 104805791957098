import teethConfig from '../../configs/teethConfig';


const PARAMS_PLACEHOLDER = teethConfig.PARAMS_PLACEHOLDER;

const TEETH = {
	'11': {
		width: 21,
		height: 38,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.927 37c-3.455 0-5.528-.58-6.713-1.737-1.185-1.256-1.284-2.896-1.185-4.73.1-2.701 1.877-5.308 3.16-7.24.492-.77.887-1.35 1.085-1.833.691-1.64 2.764-9.071 3.456-11.967.197-.869.295-1.931.493-2.992.394-2.897.593-5.212 1.975-5.501h.198l.197.096c1.283.386 1.579 2.412 1.973 4.922l.1.773c.295 1.736 1.184 4.825 1.876 7.045.296.868.493 1.544.591 2.026.198.774.297 1.738.395 2.8.198 1.64.296 3.377.89 4.44 1.086 1.93 2.763 5.114 2.566 8.3-.297 3.668-1.876 5.405-9.872 5.598H8.927z"/>`,
	},
	'12': {
		width: 15,
		height: 39,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.536 38c-.565 0-1.037 0-1.413-.095h-.754c-1.224 0-2.826-.096-3.862-1.24C1.847 35.902 1 34.854 1 33.424c0-.764 0-1.431.094-2.1 0-2.67.565-3.718 1.978-6.007 1.79-2.765 2.167-11.062 1.978-15.83-.095-1.24-.377-2.574-.565-3.719-.47-2.098-.753-3.816.188-4.577C4.862 1.095 5.05 1 5.24 1c1.13 0 2.638 2.097 4.428 6.293 1.507 3.434 1.694 10.395 1.883 14.114 0 .764.094 1.335.094 1.812.094.858.378 1.43.754 2.193.754 1.24 1.602 2.86 1.602 6.58 0 2.003-.472 3.434-1.414 4.482-.848 1.05-2.26 1.526-4.05 1.526"/>`,
	},
	'13': {
		width: 19,
		height: 41,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.693 40c-1.027 0-2.24-.19-3.173-.572-1.586-.668-5.32-2.384-5.506-5.149-.187-2.384 1.586-6.294 2.893-8.01.932-1.24 1.399-7.913 1.493-11.823V8.248C5.306 4.34 5.027 1.48 6.986 1h.373c.934 0 3.268-.476 5.414 16.402.654 5.051 1.493 8.867 2.334 10.106.28.381.559.764.84 1.145C16.88 29.893 18 31.419 18 33.516c0 2.574-2.427 4.005-4.852 5.34-.375.19-.654.38-1.028.572-.654.381-1.493.572-2.427.572"/>`,
	},
	'14': {
		width: 18,
		height: 37,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.517 36c-1.662 0-4.062-.759-5.722-1.802-.924-.569-1.57-1.802-1.756-3.32-.184-1.897.28-3.603 1.387-4.837 1.29-1.422 1.568-4.553 1.845-9.959.093-2.372 0-5.123-.092-7.494-.185-4.647-.185-6.734.737-7.304.279-.189.555-.284.832-.284C7.686 1 9.07 4.509 10.64 9.537c1.106 3.319 1.292 6.07 1.57 9.01.091.854.183 1.803.276 2.751.091 1.139 1.016 2.941 1.753 3.7l.276.189c1.293 1.233 2.864 2.751 2.401 4.933-.923 5.121-7.292 5.786-8.03 5.88h-.37z"/>`,
	},
	'15': {
		width: 18,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.686 35c-2.274 0-6.155-1.632-7.198-4.514-1.136-3.073-.094-5.954 1.137-7.492.19-.192.379-.384.473-.577.662-.767.662-.767.285-2.689-.285-1.44 0-2.4.283-3.648.285-1.25.758-2.882.947-5.763.19-2.978-.283-4.899-.567-6.244-.286-1.249-.57-2.401.567-2.976C4.71 1 4.898 1 4.993 1c1.325 0 3.693 2.402 5.492 6.723 2.18 5.282 2.369 10.086 2.369 11.91 0 .48.19 1.632.853 2.689.094.192.377.48.662.768 1.137 1.248 3.03 3.458 2.557 6.147-.473 3.17-3.695 5.475-8.05 5.763h-.19z"/>`,
	},
	'16': {
		width: 24,
		height: 35,
		content: `<path ${PARAMS_PLACEHOLDER} d="M3.925 33.904c-.667-.094-1.335-.475-1.81-1.14-1.05-1.522-1.335-4.09-.954-5.801.478-2.284 1.62-3.424 3.718-5.516l.667-.665c1.429-1.427 1.048-7.325.858-10.462 0-.665-.096-1.237-.096-1.616 0-.38 0-.857-.094-1.237-.191-2.948-.191-5.041.57-5.896.287-.285.573-.38.955-.38 1.714.095 2.669 3.138 3.716 7.227l.096.38c.191.762.382 1.806.571 2.759.287 1.521.764 4.563 1.43 4.563 1.144 0 2.573-2.757 2.86-6.847.095-1.71-.19-3.328-.381-4.564-.286-1.711-.573-3.138.571-3.614.191-.095.382-.095.572-.095 1.716 0 3.05 3.52 3.717 5.516.285.95.953 6.657-.761 11.221-.479 1.142-.383 2.188-.287 3.33.096 1.235 1.048 2.757 1.812 3.802.38.572.761 1.141.953 1.523.761 1.712.381 3.803-.858 4.85-.763.666-1.81 1.236-2.955 1.806a5.564 5.564 0 0 1-2.479.572c-.858 0-1.62-.19-2.193-.667-.761-.665-1.237-.95-2.19-.95h-.478c-.953.095-1.526.285-2.859 1.045-1.334.666-2.382.952-3.527.952-.38 0-.763 0-1.144-.096z"/>`,
	},
	'17': {
		width: 24,
		height: 33,
		content: `<path ${PARAMS_PLACEHOLDER} d="M15.646 31.707c-1.161 0-2.323-.49-3.193-.979-.484-.196-.871-.39-1.161-.488-.291 0-.775.292-1.259.488-.676.392-1.644.88-2.613.88H6.259c-1.256 0-2.225-.292-3.676-1.661C.55 27.99.646 25.154 1.905 23.49c1.45-1.954 2.42-3.519 2.71-4.497.29-1.272-.87-7.823-1.645-10.757-1.065-3.716-1.548-5.77-.484-6.65.29-.196.58-.294.967-.294 1.064 0 2.226.88 2.806 2.151 1.258 2.446 2.226 5.672 2.613 7.237.485 1.859 1.84 4.695 2.516 5.184.58-.98.774-5.966.677-8.9 0-.49-.096-1.075-.194-1.564-.193-1.564-.387-3.13.291-3.913.194-.195.58-.488 1.161-.488 1.646 0 2.903 2.64 3.29 4.499.581 2.248.775 3.714 1.064 7.823.195 2.933 2.226 6.259 3.581 7.726C22.806 22.808 23 24.47 23 25.937c0 2.053-1.548 2.836-3 3.52-.29.099-.677.49-1.064.881-.677.685-1.548 1.466-2.807 1.662-.194-.293-.387-.293-.483-.293"/>`,
	},
	'18': {
		width: 22,
		height: 33,
		content: `<path ${PARAMS_PLACEHOLDER} d="M6.99 32c-2.714-.293-4.12-1.857-4.213-4.888-.094-2.153.56-3.424 1.029-4.108.282-.392.563-.685.843-.979.656-.586 1.03-.978.938-2.25-.095-2.932-.563-4.791-1.593-6.356-.375-.684-.75-1.27-1.03-1.954C1.654 8.432.435 2.466 1.28 2.174c1.591-.587 2.81 2.347 2.81 2.347 3.182 5.672 5.711 8.704 6.46 9.388.657.587 1.124.88 1.405.88h.093c.47-.293.937-3.324.375-6.846v-.097c-.656-4.206-.843-6.26.28-6.748.188-.098.282-.098.47-.098 2.153 0 4.306 5.476 4.495 8.997 0 .782.094 1.565.094 2.347.093 3.228.28 6.553 1.123 8.215l.375.585c1.124 2.056 2.247 4.109 1.499 6.749-.75 2.738-1.874 3.814-4.12 3.814h-.282c-.937 0-1.593-.39-2.155-.685-.467-.293-.843-.488-1.498-.587h-.093c-.563 0-1.686.294-2.622.684C9.05 31.61 7.926 32 7.27 32h-.28z"/>`,
	},
	'21': {
		width: 21,
		height: 38,
		content: `<path ${PARAMS_PLACEHOLDER} d="M12.074 37c3.455 0 5.529-.58 6.713-1.737 1.184-1.256 1.283-2.896 1.184-4.73-.098-2.701-1.875-5.308-3.16-7.24-.492-.77-.887-1.35-1.085-1.833-.691-1.64-2.764-9.071-3.456-11.967-.197-.869-.295-1.931-.493-2.992-.394-2.897-.592-5.212-1.974-5.501h-.197l-.198.096c-1.284.386-1.58 2.412-1.974 4.922l-.099.773c-.296 1.736-1.184 4.825-1.876 7.045-.297.868-.494 1.544-.592 2.026-.198.774-.296 1.738-.395 2.8-.198 1.64-.296 3.377-.89 4.44-1.085 1.93-2.763 5.114-2.566 8.3.297 3.668 1.876 5.405 9.872 5.598h1.186z"/>`,
	},
	'22': {
		width: 15,
		height: 39,
		content: `<path ${PARAMS_PLACEHOLDER} d="M6.347 38c.553 0 1.015 0 1.384-.095h.737c1.198 0 2.766-.096 3.78-1.24.646-.763 1.752-1.811 1.752-3.241 0-.764 0-1.431-.092-2.1 0-2.67-.83-3.718-2.213-6.007-1.752-2.765-2.121-11.062-1.936-15.83.093-1.24.369-2.574.553-3.719.46-2.098.737-3.816-.184-4.577C9.943 1.095 9.759 1 9.574 1 8.468 1 6.992 3.097 5.24 7.293c-1.475 3.434-1.66 10.395-1.843 14.114 0 .764-.093 1.335-.093 1.812-.091.858-.37 1.43-.737 2.193C1.83 26.652 1 28.272 1 31.992c0 2.003.46 3.434 1.383 4.482C3.213 37.524 4.596 38 6.347 38"/>`,
	},
	'23': {
		width: 19,
		height: 41,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.307 40c1.026 0 2.24-.19 3.172-.572 1.587-.668 5.32-2.384 5.507-5.149.187-2.384-1.586-6.294-2.893-8.01-.933-1.24-1.4-7.913-1.493-11.823V8.248c.093-3.908.373-6.768-1.587-7.247h-.372c-.934 0-3.268-.476-5.414 16.402-.654 5.051-1.493 8.867-2.333 10.106l-.84 1.145C2.12 29.893 1 31.419 1 33.516c0 2.574 2.428 4.005 4.853 5.34.374.19.654.38 1.027.572.56.381 1.4.572 2.427.572"/>`,
	},
	'24': {
		width: 18,
		height: 37,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.483 36c1.661 0 4.061-.759 5.723-1.802.923-.569 1.569-1.802 1.754-3.32.185-1.897-.277-3.603-1.385-4.837-1.291-1.422-1.57-4.553-1.846-9.959-.092-2.372 0-5.123.092-7.494.184-4.647.184-6.734-.738-7.304-.277-.189-.555-.284-.831-.284-1.938 0-3.322 3.509-4.893 8.537-1.107 3.319-1.293 6.07-1.569 9.01-.093.854-.185 1.803-.276 2.751-.093 1.139-1.016 2.941-1.755 3.7l-.276.189C2.19 26.42.62 27.938 1.083 30.12c.922 5.121 7.292 5.786 8.03 5.88h.37z"/>`,
	},
	'25': {
		width: 18,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.314 35c2.273 0 6.156-1.632 7.197-4.514 1.138-3.073.095-5.954-1.137-7.492-.189-.192-.378-.384-.472-.577-.663-.767-.663-.767-.284-2.689.284-1.44 0-2.4-.285-3.648-.284-1.25-.757-2.882-.947-5.763-.19-2.978.284-4.899.568-6.244.285-1.249.569-2.401-.568-2.976C13.292 1 13.103 1 13.007 1c-1.326 0-3.693 2.402-5.493 6.723-2.177 5.282-2.367 10.086-2.367 11.91 0 .48-.19 1.632-.853 2.689-.095.192-.378.48-.662.768-1.136 1.248-3.03 3.458-2.558 6.147.473 3.17 3.694 5.475 8.051 5.763h.19z"/>`,
	},
	'26': {
		width: 24,
		height: 35,
		content: `<path ${PARAMS_PLACEHOLDER} d="M18.93 34c.382 0 .763 0 1.145-.096.666-.094 1.334-.475 1.81-1.14 1.05-1.522 1.334-4.09.954-5.801-.478-2.284-1.62-3.424-3.718-5.516l-.666-.665c-1.43-1.427-1.05-7.325-.859-10.462 0-.665.095-1.237.095-1.616 0-.38 0-.857.096-1.237.19-2.948.19-5.041-.572-5.896-.285-.285-.572-.38-.953-.38-1.716.095-2.669 3.138-3.718 7.227l-.095.38c-.19.762-.381 1.806-.572 2.759-.285 1.521-.762 4.563-1.429 4.563-1.145 0-2.574-2.757-2.86-6.847-.095-1.71.19-3.328.38-4.564.287-1.711.574-3.138-.57-3.614C7.208 1 7.016 1 6.825 1 5.11 1 3.775 4.52 3.108 6.516c-.285.95-.953 6.657.763 11.221.477 1.142.381 2.188.285 3.33-.095 1.235-1.048 2.757-1.81 3.802-.381.572-.763 1.141-.953 1.523-.763 1.712-.382 3.803.857 4.85.764.666 1.811 1.236 2.955 1.806.763.381 1.62.572 2.478.572.859 0 1.62-.19 2.193-.667.762-.665 1.238-.95 2.192-.95h.476c.953.095 1.526.285 2.86 1.045 1.334.666 2.287.952 3.527.952"/>`,
	},
	'27': {
		width: 24,
		height: 44,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.353 31.707c1.163 0 2.324-.49 3.194-.979.485-.196.87-.39 1.161-.488.29 0 .775.292 1.258.488.677.392 1.645.88 2.613.88h1.161c1.258 0 2.225-.292 3.678-1.661 2.032-1.957 1.935-4.793.678-6.456-1.453-1.954-2.42-3.519-2.71-4.497-.29-1.272.87-7.823 1.644-10.757 1.066-3.716 1.549-5.77.484-6.65a1.62 1.62 0 0 0-.967-.294c-1.065 0-2.226.88-2.807 2.151-1.257 2.446-2.226 5.672-2.612 7.237-.485 1.859-1.84 4.695-2.517 5.184-.58-.98-.774-5.966-.677-8.9 0-.49.098-1.075.194-1.564.193-1.564.387-3.13-.291-3.913A1.621 1.621 0 0 0 10.677 1C9.03 1 7.774 3.64 7.386 5.499c-.581 2.248-.773 3.714-1.064 7.823-.195 2.933-2.227 6.259-3.581 7.726C1.192 22.808 1 24.47 1 25.937c0 2.053 1.547 2.836 3 3.52.289.099.676.49 1.064.881.677.685 1.549 1.466 2.806 1.662.193-.293.29-.293.483-.293"/>`,
	},
	'28': {
		width: 22,
		height: 33,
		content: `<path ${PARAMS_PLACEHOLDER} d="M14.728 32h.28c2.717-.293 4.121-1.857 4.215-4.888.093-2.153-.562-3.424-1.03-4.108-.28-.392-.562-.685-.843-.979-.656-.586-1.03-.978-.936-2.25.093-2.932.562-4.791 1.591-6.356.376-.684.75-1.27 1.031-1.954 1.311-3.033 2.528-8.999 1.686-9.291-1.593-.587-2.81 2.347-2.81 2.347-3.184 5.672-5.713 8.704-6.461 9.388-.656.587-1.124.88-1.405.88h-.093c-.47-.293-.937-3.324-.376-6.846v-.097c.656-4.206.843-6.26-.28-6.748C9.108 1 9.016 1 8.828 1 6.674 1 4.52 6.476 4.332 9.997c0 .782-.093 1.565-.093 2.347-.094 3.228-.281 6.553-1.122 8.215l-.376.585C1.617 23.2.493 25.253 1.243 27.893c.749 2.738 1.874 3.814 4.12 3.814h.28c.937 0 1.593-.39 2.155-.685.468-.293.843-.488 1.498-.587h.094c.563 0 1.685.294 2.622.684.937.491 1.967.881 2.716.881"/>`,
	},
	'31': {
		width: 15,
		height: 38,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.407 36.806c.193 0 .578-.096.87-.387.964-.967 1.06-3.58.964-5.032 0-1.259.194-2.516.484-3.968.386-1.742.772-3.967.868-7.064.194-5.516.773-7.645 1.739-11.129V9.13c.58-2.226 1.063-3.872.194-5.227-.87-1.355-2.704-2.129-6.471-2.806C6.573 1 6.088 1 5.605 1c-1.93 0-2.992.774-3.572 1.548C1.067 3.71.874 5.355 1.067 6.421c.29 2.031.676 3.676 2.317 6.192 1.45 2.322 1.644 11.709 1.74 15.194v.29C5.22 31.387 6.572 36.71 8.116 37c.097-.194.193-.194.29-.194"/>`,
	},
	'32': {
		width: 15,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M7.726 35c2.134-.19 1.67-7.481 1.67-7.576-.092-1.136.277-2.937.557-4.926.37-2.082.741-4.356.835-6.346 0-2.177.742-3.692 1.576-5.302a18.567 18.567 0 0 0 1.39-3.41c.28-1.326.466-2.652-.277-3.693-.741-1.136-2.318-1.8-5.38-2.273-.37-.095-.74-.095-1.02-.19C6.428 1.096 5.78 1 4.853 1c-.463 0-1.021.095-1.576.19-.93.189-1.578.757-1.949 1.704-.742 1.8-.185 4.64 1.299 6.725 1.576 2.272 1.763 9.376 1.855 13.638 0 1.136.093 2.083.093 2.746C4.574 26.95 5.593 35 7.726 35"/>`,
	},
	'33': {
		width: 18,
		height: 39,
		content: `<path ${PARAMS_PLACEHOLDER} d="M12.138 38c.09 0 .18 0 .273-.097 1.004-.385 1.004-2.125.912-7.149 0-1.93-.09-3.864 0-5.41.365-5.892.365-8.404.365-10.143v-.966c0-.772.548-1.642 1.55-2.609 1.186-1.062 2.098-2.802 1.643-4.54-.822-3.284-3.376-4.83-8.301-5.893C8.124 1.097 7.668 1 7.21 1c-2.188 0-3.647 1.739-4.56 2.706C1.465 5.155.92 6.893 1.01 7.86c.09 1.16.728 2.222 1.55 3.574.365.581.73 1.16 1.094 1.836.457.87.548 2.51.73 4.444.182 2.994.548 6.665 2.098 10.433C8.215 32.686 10.77 38 12.138 38"/>`,
	},
	'34': {
		width: 18,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.535 35c.39 0 .782-.192.977-.383.587-.671.88-2.012.782-4.406-.195-3.64.391-7.47.978-10.918.293-2.204.683-4.31.78-6.034.1-1.436.98-2.298 1.957-3.257.78-.67 1.465-1.341 1.856-2.393.293-.767.099-1.63-.488-2.586C15.106 3.01 11.98 1.478 8.655 1h-.782C5.136 1 3.279 2.82 2.497 3.873 1.617 4.927.835 6.555 1.03 7.32c.294 1.054.488 1.917 2.054 3.353.782.766 1.759 6.704 2.346 10.248.39 2.106.684 4.023.88 4.884.194.767.39 1.724.683 2.875C7.775 33.084 8.362 35 9.535 35"/>`,
	},
	'35': {
		width: 18,
		height: 34,
		content: `<path ${PARAMS_PLACEHOLDER} d="M9.523 33c.194 0 .387-.096.581-.19.969-.57 1.162-2.564.679-7.407-.29-2.944.483-4.937 1.356-6.93.483-1.14.87-2.185 1.066-3.42.29-2.374 1.063-3.134 1.84-4.083.483-.57 1.065-1.235 1.645-2.28.484-.949.388-2.088-.194-3.228C15.238 3.183 12.041 1.474 8.555 1H7.78C3.906 1 1 4.798 1 6.792c0 1.613.677 3.23 1.937 4.938.775 1.045.969 4.083 1.162 7.122.194 2.659.388 5.318.969 7.311C6.134 30.151 7.973 33 9.523 33"/>`,
	},
	'36': {
		width: 25,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M11.335 35c.67 0 1.15-.48 1.15-1.15 0-.67-.192-1.436-.48-2.586-.383-1.34-.861-3.16-1.15-5.555-.574-4.501 0-7.757 1.342-8.045 1.533 0 4.886 5.46 6.228 9.386.096.288.191.671.383 1.053.863 2.683 1.821 5.747 3.546 5.747.575 0 .958-.287 1.15-.479.766-.957.574-2.777-.096-6.99-.576-3.736-1.63-6.034-2.491-7.759-.192-.383-.288-.67-.48-.958-.958-2.107-.861-5.65.862-8.044.96-1.245 1.246-2.49.96-3.736-.385-1.533-1.63-2.777-3.834-3.83-.767-.384-1.532-.575-2.3-.575-1.437 0-2.49.671-3.353 1.15-.383.191-.67.478-.958.478-.192 0-.384.095-.575.095-.672 0-1.245-.382-1.915-.861-.673-.48-1.343-1.053-2.396-1.245-.192 0-.384-.096-.672-.096-2.3 0-4.694 2.586-5.078 4.884-.48 2.3 0 3.066 1.534 5.173.67.957.861 2.873.958 4.309 0 .575.095 1.15.191 1.532 0 .287.096.766.096 1.438.191 2.872.574 8.14 2.682 12.928C7.79 33.371 9.994 35 11.335 35"/>`,
	},
	'37': {
		width: 24,
		height: 33,
		content: `<path ${PARAMS_PLACEHOLDER} d="M20.69 32c1.213 0 1.959-1.53 2.24-4.496.373-4.21-.748-8.516-2.987-12.153-1.399-2.2-1.212-3.922.56-5.358.934-.764 1.493-1.914 1.586-3.253.093-1.435-.466-2.774-1.306-3.731C19.663 1.86 17.518 1 15.559 1c-.747 0-1.4.095-1.866.382-.654.287-1.493.479-2.612.479-1.12 0-2.426-.192-4.105-.48-.466-.095-.84-.095-1.213-.095-2.706 0-4.572 1.723-4.757 4.21-.094 1.723.932 3.157 1.865 4.497.652.862 1.213 1.627 1.306 2.296.094.48.094 1.436.094 2.49-.094 2.678-.094 6.41 1.678 9.47.56.862 4.852 7.082 7.37 7.082h.374c.373-.096.652-.288.84-.67.466-1.054-.374-2.871-1.68-5.837-.653-1.34-1.307-2.679-1.586-3.732-.466-1.626-.373-3.157.187-4.114.374-.574.84-.956 1.492-.956h.093c1.4 0 3.826 3.636 5.038 7.463.187.573.467 1.817.654 3.062.839 4.401 1.12 5.167 1.773 5.358 0 .095.092.095.186.095"/>`,
	},
	'38': {
		width: 26,
		height: 27,
		content: `<path ${PARAMS_PLACEHOLDER} d="M24.86 24.934c.57-.872-.665-1.841-2.377-3.391a57.663 57.663 0 0 1-2.755-2.616c-1.995-2.036-2.375-6.3-1.9-7.85a4.23 4.23 0 0 1 .95-1.454c.76-.967 1.71-2.13 1.521-4.457C20.013 2.646 18.017 1 15.072 1a9.21 9.21 0 0 0-2.282.29c-1.14.291-1.995.485-2.85.485-.95 0-2.09-.194-3.61-.485-.476-.096-.95-.194-1.425-.194-2.567 0-3.897 1.94-3.897 3.684-.095 2.034.664 3.487 2.376 5.91.379.581.95 2.423 1.425 4.263.665 2.23 1.236 4.458 1.995 5.717C7.09 21.25 9.94 26 11.747 26h.284c.285-.096.475-.29.665-.581.475-.872-.095-2.811-1.236-5.62-.57-1.455-.854-4.846.287-4.168.19.195.664 1.067.949 1.552.284.484.95 1.453 1.236 1.938.95 1.453 3.99 4.36 6.461 5.522.95.678 3.897 1.26 4.466.29"/>`,
	},
	'41': {
		width: 15,
		height: 38,
		content: `<path ${PARAMS_PLACEHOLDER} d="M6.593 36.806c-.193 0-.58-.096-.87-.387-.964-.967-1.061-3.58-.964-5.032 0-1.259-.193-2.516-.485-3.968-.385-1.742-.771-3.967-.867-7.064-.193-5.516-.774-7.645-1.739-11.129V9.13c-.58-2.226-1.062-3.872-.194-5.227.87-1.355 2.704-2.129 6.471-2.806C8.427 1 8.911 1 9.393 1c1.932 0 2.995.774 3.573 1.548.967 1.162 1.16 2.807.967 3.873-.29 2.031-.676 3.676-2.318 6.192-1.448 2.322-1.642 11.709-1.738 15.194v.29c-.098 3.29-1.45 8.613-2.995 8.903-.096-.194-.193-.194-.289-.194"/>`,
	},
	'42': {
		width: 15,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M7.273 35c-2.134-.19-1.669-7.481-1.669-7.576.093-1.136-.278-2.937-.557-4.926-.371-2.082-.741-4.356-.834-6.346 0-2.177-.742-3.692-1.577-5.302a18.569 18.569 0 0 1-1.391-3.41c-.28-1.326-.465-2.652.278-3.693.741-1.136 2.317-1.8 5.38-2.273.37-.095.74-.095 1.018-.19C8.571 1.096 9.22 1 10.148 1c.464 0 1.02.095 1.577.19.927.189 1.576.757 1.948 1.704.742 1.8.185 4.64-1.299 6.725-1.576 2.272-1.762 9.376-1.856 13.638 0 1.136-.092 2.083-.092 2.746 0 .947-1.02 8.997-3.153 8.997"/>`,
	},
	'43': {
		width: 18,
		height: 39,
		content: `<path ${PARAMS_PLACEHOLDER} d="M5.863 38c-.09 0-.183 0-.273-.097-1.004-.385-1.004-2.125-.914-7.149 0-1.93.092-3.864 0-5.41-.363-5.892-.363-8.404-.363-10.143v-.966c0-.772-.549-1.642-1.551-2.609C1.576 10.564.663 8.824 1.12 7.086c.82-3.284 3.375-4.83 8.3-5.893.456-.096.911-.193 1.368-.193 2.19 0 3.648 1.739 4.56 2.706 1.187 1.449 1.733 3.187 1.642 4.153-.091 1.16-.73 2.222-1.55 3.574-.365.581-.73 1.16-1.095 1.836-.456.87-.546 2.51-.729 4.444-.182 2.994-.548 6.665-2.098 10.433C9.785 32.686 7.23 38 5.863 38"/>`,
	},
	'44': {
		width: 18,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.466 35c-.39 0-.783-.192-.979-.383-.585-.671-.878-2.012-.78-4.406.195-3.64-.391-7.47-.978-10.918-.294-2.204-.683-4.31-.782-6.034-.098-1.436-.977-2.298-1.955-3.257-.782-.67-1.467-1.341-1.858-2.393-.292-.767-.097-1.63.49-2.586C2.894 3.01 6.022 1.478 9.346 1h.781c2.737 0 4.594 1.82 5.377 2.873.88 1.054 1.661 2.682 1.466 3.447-.293 1.054-.488 1.917-2.054 3.353-.78.766-1.758 6.704-2.344 10.248-.391 2.106-.685 4.023-.88 4.884-.196.767-.392 1.724-.684 2.875-.88 4.404-1.369 6.32-2.542 6.32"/>`,
	},
	'45': {
		width: 18,
		height: 34,
		content: `<path ${PARAMS_PLACEHOLDER} d="M8.477 33c-.193 0-.387-.096-.58-.19-.969-.57-1.163-2.564-.679-7.407.29-2.944-.484-4.937-1.356-6.93-.484-1.14-.871-2.185-1.066-3.42-.29-2.374-1.065-3.134-1.84-4.083-.483-.57-1.066-1.235-1.646-2.28-.484-.949-.387-2.088.193-3.228C2.763 3.183 5.958 1.474 9.446 1h.774C14.096 1 17 4.798 17 6.792c0 1.613-.677 3.23-1.936 4.938-.776 1.045-.968 4.083-1.162 7.122-.194 2.659-.388 5.318-.97 7.311C11.867 30.151 10.026 33 8.477 33"/>`,
	},
	'46': {
		width: 25,
		height: 36,
		content: `<path ${PARAMS_PLACEHOLDER} d="M13.665 35c-.67 0-1.15-.48-1.15-1.15 0-.67.192-1.436.48-2.586.383-1.34.862-3.16 1.148-5.555.576-4.501 0-7.757-1.341-8.045-1.533 0-4.886 5.46-6.228 9.386-.095.288-.191.671-.383 1.053-.862 2.683-1.821 5.747-3.546 5.747-.573 0-.958-.287-1.15-.479-.765-.957-.573-2.777.097-6.99.575-3.736 1.63-6.034 2.491-7.759.192-.383.287-.67.48-.958.957-2.107.862-5.65-.863-8.044-.958-1.245-1.245-2.49-.958-3.736.384-1.533 1.628-2.777 3.832-3.83.768-.384 1.533-.575 2.301-.575 1.437 0 2.49.671 3.353 1.15.382.191.67.478.958.478.192 0 .384.095.575.095.672 0 1.245-.382 1.915-.861.672-.48 1.342-1.053 2.397-1.245.191 0 .383-.096.67-.096 2.3 0 4.695 2.586 5.079 4.884.48 2.3 0 3.066-1.534 5.173-.67.957-.861 2.873-.958 4.309 0 .575-.096 1.15-.191 1.532 0 .287-.097.766-.097 1.438-.191 2.872-.573 8.14-2.683 12.928C17.211 33.371 15.006 35 13.665 35"/>`,
	},
	'47': {
		width: 24,
		height: 33,
		content: `<path ${PARAMS_PLACEHOLDER} d="M3.31 32c-1.212 0-1.959-1.53-2.24-4.496-.372-4.21.747-8.516 2.987-12.153 1.4-2.2 1.211-3.922-.56-5.358-.934-.764-1.493-1.914-1.586-3.253-.094-1.435.466-2.774 1.306-3.731C4.337 1.86 6.482 1 8.441 1c.746 0 1.4.095 1.865.382.655.287 1.493.479 2.614.479 1.119 0 2.425-.192 4.104-.48.466-.095.84-.095 1.213-.095 2.706 0 4.57 1.723 4.757 4.21.094 1.723-.932 3.157-1.865 4.497-.652.862-1.213 1.627-1.307 2.296-.093.48-.093 1.436-.093 2.49.093 2.678.093 6.41-1.679 9.47-.56.862-4.851 7.082-7.37 7.082h-.374c-.372-.096-.652-.288-.839-.67-.467-1.054.373-2.871 1.68-5.837.652-1.34 1.305-2.679 1.586-3.732.466-1.626.372-3.157-.187-4.114-.374-.574-.84-.956-1.492-.956h-.093c-1.4 0-3.826 3.636-5.039 7.463-.187.573-.466 1.817-.654 3.062-.839 4.401-1.118 5.167-1.772 5.358 0 .095-.092.095-.186.095"/>`,
	},
	'48': {
		width: 26,
		height: 27,
		content: `<path ${PARAMS_PLACEHOLDER} d="M1.14 24.934c-.569-.872.665-1.841 2.377-3.391.759-.68 1.71-1.55 2.755-2.616 1.996-2.036 2.375-6.3 1.9-7.85a4.243 4.243 0 0 0-.95-1.454c-.76-.967-1.71-2.13-1.52-4.457C5.986 2.646 7.982 1 10.929 1c.76 0 1.52.096 2.28.29 1.14.291 1.996.485 2.851.485.95 0 2.09-.194 3.61-.485.476-.096.952-.194 1.426-.194 2.566 0 3.896 1.94 3.896 3.684.096 2.034-.665 3.487-2.375 5.91-.38.581-.95 2.423-1.426 4.263-.665 2.23-1.235 4.458-1.995 5.717-.285.581-3.136 5.33-4.942 5.33h-.285c-.285-.096-.475-.29-.665-.581-.476-.872.096-2.811 1.236-5.62.57-1.455.855-4.846-.286-4.168-.19.195-.664 1.067-.95 1.552-.284.484-.95 1.453-1.235 1.938-.951 1.453-3.99 4.36-6.463 5.522-.949.678-3.895 1.26-4.466.29"/>`,
	},
};

export default {
	TEETH,
};
