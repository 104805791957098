import when from 'when';

import imageApi from '../api/imageApi';

import storeUtils from '../appUtils/storeUtils';
import { get as getHelp } from '../services/help';
import userActionTypes from './userActionTypes';
import authApi from '../api/authApi';
import commonActions from '../actions/commonActions';

import userSelectors from '../selectors/userSelectors';
import commonUtils from '../appUtils/common';
import { setConfidencePercentToStorage } from '../modules/resolver/utils';
import {getStorageService} from "../services/storage";


function processData (data) {
	// data.is_patient_age_restricted = false;

	if ( data.are_findings_heatmaps_enabled === true ) {
		return {
			...data,
			are_findings_masks_enabled: false,
		};
	}

	return data;
}

function signIn (options) {
	return (dispatch) => {
		return authApi.signIn({
			username: options.username,
			password: options.password,
		})
			.then((data) => {
				authApi.setToken(data.token);
			});
	};
}

function autoSignIn () {
	return (dispatch) => {
		return when.promise((resolve, reject) => {
			const token = authApi.getTokenFromCookie();
			
			if ( token ) {
				authApi.setToken(token);

				when.all([
					authApi.getProfile(),
					dispatch(commonActions.getCountries()),
					dispatch(commonActions.getAllProcedures()),
				])
					.then(([ profile ]) => {
						dispatch(storeUtils.makeAction(userActionTypes.ACTION_USER__SIGN_IN, {
							data: processData(profile),
						}));

						resolve();
					})
					.catch((er) => {
						reject(new Error('An error occurred when authenticated'));
					});
			}
			else {
				reject();
			}
		});
	};
}

function signOut () {
	const help = getHelp();
	if ( help !== null ) {
		help.teardown();
	}

	return (dispatch) => {
		return Promise.resolve()
			.then(() => {
				authApi.removeToken();
				setConfidencePercentToStorage(0);
				dispatch(storeUtils.makeAction(userActionTypes.ACTION_USER__SIGN_OUT));
			});
	};
}

function getProfile () {
	return (dispatch) => {
		return authApi.getProfile()
			.then((data) => {
				dispatch(storeUtils.makeAction(userActionTypes.ACTION_USER__SET_DATA, processData(data)));
			});
	};
}

function updateProfile (options = {}) {
	return (dispatch, getState) => {
		return authApi.updateProfile(options.data)
			.then(() => {
				const help = getHelp();
				if ( help !== null ) {
					help.updateUserData({
						...userSelectors.selectUserData(getState()),
						...options.data,
					});
				}
				dispatch(storeUtils.makeAction(userActionTypes.ACTION_USER__UPDATE_DATA, options.data));
			});
	};
}

function changeEmail (options) {
	return (dispatch) => {
		return dispatch(updateProfile({
			data: options,
		}));
	};
}

function changePassword (options) {
	return (dispatch) => {
		return when.promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 300);
		});
	};
}

function uploadClinicLogo (options) {
	return async (dispatch) => {
		const {
			image,
		} = options;

		if ( !image ) {
			// @todo add error_prefix
			throw new Error(`uploadClinicLogo: Option "image" is required.`);
		}

		try {
			const result = await imageApi.uploadImage({
				image: options.image,
				is_logo: true,
			});

			dispatch(dispatch(storeUtils.makeAction(userActionTypes.ACTION_USER__UPDATE_DATA, {
				full_logo_url: result.logo_url,
			})));
		}
		catch (error) {
			throw new Error(error);
		}
	};
}

export default {
	signIn,
	autoSignIn,
	signOut,
	changeEmail,
	changePassword,
	uploadClinicLogo,
	getProfile,
	updateProfile,
};
