import React, { PureComponent } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Events from 'events';
import lodashGet from 'lodash/get';

import {
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PAN,
} from '../../../../constants/imageConstants';

import { DRAGGING_STATUS } from '../../../../components/Canvas/CanvasConstants';

import { setCanvas, setViewportData } from '../../../../services/canvas';
import urlCache from '../../../../services/url-cache';
import { message } from '../../../../services/popup';
import { events } from '../../../../services/events';
import { confirm } from '../../../../services/popup';

import { trackEvent } from '../../../../integrations/mixpanel';

import teethUtils from '../../../../appUtils/teeth/teethUtils';

import ToothActions from '../tooth-actions';

import labelGetters from '../../../labels/selectors/labelGetters';

import Alignment from '../../../../components/Alignment';
import Loading from '../../../../components/Loading';

import ImageShapeBox from '../../../../components/ImageShapes/shapes/box';
import ImageShapePolygon from '../../../../components/ImageShapes/shapes/polygon';
import ImageShapeBoneloss from '../../../../components/ImageShapes/shapes/boneloss';
import Popup from '../../../../components/Popup';

import ShapeTooltip from '../shape-tooltip';

import MagnifyingGlassImage from '../../../../components/MagnifyingGlass/MagnifyingGlassImageConnector';
import UiAttachedImage from '../../../../components/MagnifyingGlass/UiAttachedImage';
import SizeDetector from '../../../../components/SizeDetector';
import CanvasCollectionImageLoader from '../../../../components/Canvas/CanvasCollectionImageLoader';
import Viewport from '../../../../components/Canvas/Viewport';
import CanvasObjects from '../../../../components/ImageShapes/CanvasObjects';
import CanvasEditorConnector from '../../../../components/Canvas/CanvasEditorConnector';
import FmxViewerShapes from './FmxViewerShapes';

import FmxViewerRotationButtons from './FmxViewerRotationButtons';

import { getDictionary } from '../../../../appUtils/locale';

import './styles/FmxViewer.css';


const baseCssClassName = 'fmx-viewer';
const canvasCssClassName = `${baseCssClassName}-canvas`;
const rowCssClassName = `${baseCssClassName}-row`;
const cellCssClassName = `${baseCssClassName}-cell`;
const imageCssClassName = `${baseCssClassName}-image`;
const imagePreviewCssClassName = `${imageCssClassName}__preview`;
const imagePreviewWrapperCssClassName = `${imageCssClassName}__preview-wrapper`;
const imageTeethCssClassName = `${imageCssClassName}__teeth`;
const imageShapesCssClassName = `${imageCssClassName}__shapes`;
const imageShapesInnerCssClassName = `${imageCssClassName}__shapes-inner`;
const rotationButtonsContainerCssClassName = `${baseCssClassName}-rotation-buttons-container`;
const HighlightedImageCssClassName = `${baseCssClassName}-highlighted-image`;
const HighlightedImageWithErrorCssClassName = `${HighlightedImageCssClassName}__m-error`;

const IMAGE_PADDING = 10;
const IMAGE_TITLE_HEIGHT = 17;

const PROGRESS_STATUS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in_progress',
	FAILED: 'failed',
	DONE: 'done',
};

const i18nShared = getDictionary('shared');


class SelectionController {
	_selectedObject = null

	_events = new Events();

	setObject (selectedObject) {
		if (
			selectedObject === null && this._selectedObject !== null ||
			selectedObject !== null && this._selectedObject === null ||
			(
				selectedObject !== null && this._selectedObject !== null &&
				selectedObject.id !== this._selectedObject.id
			)
		) {
			this._selectedObject = selectedObject;
			this._events.emit('changed', selectedObject);
		}
	}

	getObject () {
		return this._selectedObject;
	}

	listen (callback) {
		this._events.on('changed', callback);
	}

	destroy () {
		this._events.removeAllListeners();
		this._events = null;
	}
}


/**
 * @param {number[]} teeth
 * @param {{ image: CollectionImage, teeth: string[] }} a
 * @param {{ image: CollectionImage, teeth: string[] }} b
 * @returns {number}
 */
const imagesComparer = (teeth, a, b) => {
	let aIndex = null;
	let bIndex = null;

	for (let i = 0; i < teeth.length; i++) {
		if ( aIndex !== null && bIndex !== null ) {
			break;
		}

		const toothKey = Number(teeth[i]);

		if ( toothKey === a.teeth[0] ) {
			aIndex = i;
		}

		if ( toothKey === b.teeth[0] ) {
			bIndex = i;
		}
	}

	const diff = aIndex - bIndex;

	if ( diff === 0 ) {
		return a.image.name.localeCompare(b.image.name);
	}

	return diff;
}

/**
 * @param {number[]} imageTeeth
 * @returns {number[]}
 */
const topTeethSort = (imageTeeth) => {
	return teethUtils.sortTeethMeta(teethUtils.getOrderedTopTeeth(), imageTeeth);
};

/**
 * @param {CollectionImage} a
 * @param {CollectionImage} b
 * @returns {number}
 */
const topTeethImagesComparer = (a, b) => {
	return imagesComparer(teethUtils.getOrderedTopTeeth(), a, b);
};

/**
 * @param {number[]} imageTeeth
 * @returns {number[]}
 */
const bottomTeethSort = (imageTeeth) => {
	return teethUtils.sortTeethMeta(teethUtils.getOrderedBottomTeeth(), imageTeeth);
};

/**
 * @param {CollectionImage} a
 * @param {CollectionImage} b
 * @returns {number}
 */
const bottomTeethImagesComparer = (a, b) => {
	return imagesComparer(teethUtils.getOrderedBottomTeeth(), a, b);
};


/**
 * @param {number[]} imageTeeth
 * @returns {number[]}
 */
const bw1and4hSort = (imageTeeth) => {
	return teethUtils.sortTeethMeta(
		teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()).filter((toothKey) => toothKey[0] === '1' || toothKey[0] === '4'),
		imageTeeth
	);
};

/**
 * @param {number[]} imageTeeth
 * @returns {number[]}
 */
const bw2and3hSort = (imageTeeth) => {
	return teethUtils.sortTeethMeta(
		teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()).filter((toothKey) => toothKey[0] === '2' || toothKey[0] === '3'),
		imageTeeth
	);
};

/**
 * @param {CollectionImage} a
 * @param {CollectionImage} b
 * @returns {number}
 */
const bwImagesComparer = (a, b) => {
	return imagesComparer(teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()), a, b);
};


export default class FmxViewer extends PureComponent {
	static propTypes = {
		images: PropTypes.object.isRequired,
		labels: PropTypes.object.isRequired,
		visibleLabelsIds: PropTypes.object.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		shouldDisplayTeethNumbers: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,
		notationType: PropTypes.string,
		labelColorFilterFn: PropTypes.func,
		teethMeta: PropTypes.object,
		notAnalyzedImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		selectedLabel: PropTypes.object,
		highlightedLabels: PropTypes.arrayOf(PropTypes.string.isRequired),
		allowShiftTooth: PropTypes.bool,
		allowRemoveTooth: PropTypes.bool,
		teethConflicts: PropTypes.arrayOf(PropTypes.object),
		enhancedImageFilter: PropTypes.bool.isRequired,
		canAnalyze: PropTypes.bool.isRequired,
		onImageRedirect: PropTypes.func.isRequired,
		onUpdateLabelShape: PropTypes.func.isRequired,
		onLabelShapeRemove: PropTypes.func.isRequired,
		onRotateLeft: PropTypes.func.isRequired,
		onRotateRight: PropTypes.func.isRequired,
		onSelectLabel: PropTypes.func.isRequired,
		onShiftTooth: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
	};

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	static defaultProps = {
		labelColorFilterFn: () => true,
		shouldDisplayTeethNumbersUnderImages: true,
	};

	/**
	 * @type {Object<string, boolean>}
	 * @private
	 */
	_usedImageHashes = {};

	/**
	 * @type {Array}
	 * @private
	 */
	_calculatedSize = [];

	_canvasApi = null;

	/**
	 * @type {HTMLElement|null}
	 * @private
	 */
	_selectedImageEl = null;

	_leaveImageTimerId = null;

	_imagesRatio = {};

	/**
	 * @type {number}
	 * @private
	 */
	_lastTouchTime = 0;

	state = {
		selectedImageHashName: null,
		buttonsHovered: false,
		teethMoveStatus: PROGRESS_STATUS.IDLE,
		findingRemoveStatus: PROGRESS_STATUS.IDLE,
	};

	_selectionController = new SelectionController();

	componentDidMount () {
		window.document.body.addEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.body.addEventListener('keydown', this._handleDocumentKeyDown, false);

		events.on('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
		this._selectionController.listen((selectedObject) => {
			this.props.onSelectLabel(selectedObject ? { labelId: selectedObject.id } : { labelId: null });
		});

		ReactDom.findDOMNode(this).addEventListener('mousedown', this._handleLayoutMouseDown, false);

		trackEvent('FMX opened', {
			hashname: this.props.imageHashName,
		});

		if ( this.props.canAnalyze === true ) {
			let newCount = 0;
			let oldCount = 0;

			Object.keys(this.props.images).forEach((type) => {
				const images = this.props.images[type];
				images.forEach((image) => {
					if ( this.props.teethMeta[image.hashname].length === 0 ) {
						if ( this.props.notAnalyzedImages.includes(image.hashname) ) {
							++newCount;
							return;
						}
						++oldCount;
					}
				});
			});

			if ( newCount > 0 || oldCount > 0 ) {
				const message = [];

				// if ( newCount > 0 ) {
				// 	message.push(`${newCount} new images were uploaded since your last visit. Click Re-Analyze to see them`);
				// }

				if ( oldCount > 0 ) {
					message.push(`${oldCount} image${oldCount > 1 ? 's are' : ' is'} currently not present on FMX. Click Re-Analyze to see them`);
				}

				confirm({
					message: message.join('<br /><br />'),
					yes: 'Re-Analyze',
					no: 'Cancel',
					yesHandler: () => {
						this.props.onAnalyzeImage();
					},
				});
			}
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			lodashGet(nextProps, 'selectedLabel.labelId') !== lodashGet(this.props, 'selectedLabel.labelId') &&
			nextProps.selectedLabel
		) {
			this._selectionController.setObject({
				id: lodashGet(nextProps, 'selectedLabel.labelId'),
				...labelGetters.getLabelShape(nextProps.selectedLabel)
			});
		}
	}

	componentWillUnmount() {
		if ( this._selectionController !== null ) {
			this._selectionController.destroy();
			this._selectionController = null;
		}
		window.document.body.removeEventListener('mousemove', this._handleDocumentMouseMove, false);
		window.document.body.removeEventListener('keydown', this._handleDocumentKeyDown, false);
		events.off('teeth-shift.status.changed', this._handleTeethShiftStatusChanged);
		ReactDom.findDOMNode(this).removeEventListener('mousedown', this._handleLayoutMouseDown, false);
	}

	_handleImageMouseEnter = (event) => {
		const target = event.currentTarget;
		if ( target.dataset.imageHashname !== undefined && target.dataset.imageHashname.length > 0 ) {
			this.setState({
				selectedImageHashName: target.dataset.imageHashname,
			});
			this._selectedImageEl = target;
			events.emit('fmx.image.selected.changed', target.dataset.imageHashname);
			if ( this._leaveImageTimerId !== null ) {
				clearTimeout(this._leaveImageTimerId);
				this._leaveImageTimerId = null;
			}
		}
	};

	_handleImageMouseLeave = () => {
		this._leaveImageTimerId = setTimeout(() => {
			if ( this.state.rotateButtonsHovered ) {
				return;
			}

			this.setState({
				selectedImageHashName: null,
			});
			this._selectedImageEl = null;
			events.emit('fmx.image.selected.changed', null);
			this._leaveImageTimerId = null;
		}, 50);
	};

	/**
	 * @param {MouseEvent} event
	 * @private
	 */
	_handleDocumentMouseMove = (event) => {
		if ( this._selectedImageEl === null ) {
			return;
		}

		const image = this._selectedImageEl;
		const imagePosition = image.getBoundingClientRect();
		const zoom = this._imagesRatio[image.dataset.imageHashname];
		events.emit('canvas.pointer.moved', {
			x: (event.clientX - imagePosition.left) / zoom,
			y: (event.clientY - imagePosition.top) / zoom,
		});
	};

	/**
	 * @param {KeyboardEvent} event
	 * @private
	 */
	_handleDocumentKeyDown = (event) => {
		if ( event.key.toLowerCase() === 'escape' ) {
			this._selectionController.setObject(null);
		}
	};

	_handleTouchedObject = (object) => {
		this._touchedObject = object;
	};

	_handleLayoutMouseDown = () => {
		if ( this._touchedObject === null ) {
			this._selectionController.setObject(null);
		}
		this._touchedObject = null;
	};

	_handleTeethShiftStatusChanged = (teethMoveStatus) => {
		this.setState({
			teethMoveStatus,
		});
	};

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_handleMoveTeeth = (toothKey, nextToothKey, imageHashName) => {
		this._moveTeeth(toothKey, nextToothKey, imageHashName, true);
	};

	/**
	 * @param {string} toothKey
	 * @param {string} imageHashName
	 * @private
	 */
	_handleRemoveTooth = ({ toothKey, imageHashName }) => {
		this._removeShape({
			toothKey,
			imageHashName,
		});
	};

	/**
	 * @param {CollectionImage.image_type} imageType
	 * @param {string[]} teeth
	 * @param {number} [max=2]
	 * @param {function:number[]} [teethMetaSort]
	 * @param {function:number} [imagesComparer]
	 * @returns {JSX.Element}
	 * @private
	 */
	_findImages (
		imageType,
		teeth,
		teethMetaSort = (a, b) => a - b,
		imagesComparer = (a, b) => {
			const diff = a.teeth[0] - b.teeth[b.id][0];

			if ( diff === 0 ) {
				return a.image.name.localeCompare(b.image.name);
			}

			return diff;
		},
		max = 20
	) {
		if ( Array.isArray(this.props.images[imageType]) === false ) {
			return [];
		}

		const images = this.props.images[imageType].reduce((result, image) => {
			if ( Array.isArray(this.props.teethMeta[image.hashname]) === false || this._usedImageHashes[image.hashname] === true ) {
				return result;
			}
			outer: for (let i = 0; i < this.props.teethMeta[image.hashname].length; i++) {
				for (let j = 0; j < teeth.length; j++) {
					if ( String(this.props.teethMeta[image.hashname][i]) === teeth[j] ) {
						this._usedImageHashes[image.hashname] = true;
						result.push({ image, teeth: teethMetaSort(this.props.teethMeta[image.hashname]) });
						break outer;
					}
				}
			}

			return result;
		}, []);

		return images.slice(0, max).sort(imagesComparer);
	}

	_findPan () {
		if ( Array.isArray(this.props.images[IMAGE_TYPE__PAN]) === false ) {
			return [];
		}

		const image = this.props.images[IMAGE_TYPE__PAN][0];

		return [ {
			image,
			teeth: teethUtils.sortTeethMeta(
				teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()),
				this.props.teethMeta[image.hashname]
			),
		}];
	}

	/**
	 * @param {CollectionImage} image
	 * @private
	 */
	_goToImage = (image) => {
		const collectionId = this.context.router.route.match.params.collectionId;
		this.context.router.history.push(`/collections/${collectionId}/image/${image.hashname}/treatment_plan`);
		// this.props.onImageRedirect();
	};

	/**
	 * @param {number} canvasWidth
	 * @param {number} canvasHeight
	 * @private
	 */
	_calculateSize (canvasWidth, canvasHeight) {
		function getAbsoluteValue (a, b) {
			return a / 100 * b;
		}

		this._calculatedSize = [];

		const firstRowHeight = getAbsoluteValue(canvasHeight, 30);
		const thirdRowHeight = getAbsoluteValue(canvasHeight, 30);
		const secondRowHeight = (canvasHeight - firstRowHeight) - thirdRowHeight - 20;

		const firstRowFirstCellWidth = getAbsoluteValue(canvasWidth, 34);
		const firstRowThirdCellWidth = getAbsoluteValue(canvasWidth, 34);
		const firstRowSecondCellWidth = (canvasWidth - firstRowFirstCellWidth) - firstRowThirdCellWidth;

		this._calculatedSize.push(
			[
				canvasWidth,
				firstRowHeight,
				[
					[
						firstRowFirstCellWidth,
						firstRowHeight,
					],
					[
						firstRowSecondCellWidth,
						firstRowHeight,
					],
					[
						firstRowThirdCellWidth,
						firstRowHeight
					],
				],
			],
			[
				canvasWidth,
				secondRowHeight,
				[
					[
						firstRowFirstCellWidth,
						secondRowHeight,
					],
					[
						firstRowSecondCellWidth,
						secondRowHeight,
					],
					[
						firstRowThirdCellWidth,
						secondRowHeight
					],
				],
			],
			[
				canvasWidth,
				thirdRowHeight,
				[
					[
						firstRowFirstCellWidth,
						thirdRowHeight,
					],
					[
						firstRowSecondCellWidth,
						thirdRowHeight,
					],
					[
						firstRowThirdCellWidth,
						thirdRowHeight
					],
				],
			],
		);
	}

	_removeShape (params) {
		this.setState({
			findingRemoveStatus: PROGRESS_STATUS.IN_PROGRESS,
		});
		this.props.onLabelShapeRemove(params)
			.then(() => {
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'Finding is not removed. Please try again.',
				});
				this.setState({
					findingRemoveStatus: PROGRESS_STATUS.DONE,
				});
			});
	}

	/**
	 * @param {string} toothKey
	 * @param {string} nextToothKey
	 * @param {string} imageHashName
	 * @return {Promise}
	 * @private
	 */
	_moveTeeth (toothKey, nextToothKey, imageHashName) {
		return this.props.onShiftTooth({
			toothKey,
			nextToothKey,
			imageHashName,
		});
	}

	/**
	 * @param {CollectionImage[]} images
	 * @param {number} cellWidth
	 * @param {number} cellHeight
	 * @param {string} [textOrientation="top"]
	 * @returns {JSX.Element}
	 * @private
	 */
	_renderImages (images, cellWidth, cellHeight, textOrientation = 'top') {
		const imageContainerWidth = cellWidth / images.length;
		const imageContainerHeight = cellHeight;

		return images.map(({ image, teeth }, i) => {
			return (
				<div
					className={`${imageCssClassName} ${imageCssClassName}__m-text-orientation-${textOrientation}`}
					style={{ padding: `0 ${IMAGE_PADDING}px` }}
					key={image.hashname}
					title={image.name}
					onDoubleClick={() => this._goToImage(image)}
					onTouchStart={(event) => {
						if ( event.touches.length === 1 ) {
							if ( this._lastTouchTime === 0 ) {
								this._lastTouchTime = event.timeStamp;
							}
							else if ( event.timeStamp <= this._lastTouchTime + 400 ) {
								event.preventDefault();
								this._goToImage(image);
								this._lastTouchTime = 0;
							}
							else {
								this._lastTouchTime = event.timeStamp;
							}
						}
					}}
				>
					<div
						className={classnames([
							imagePreviewCssClassName,
							`${imagePreviewCssClassName}__m-loaded`,
						])}
					>
						<div className={imagePreviewWrapperCssClassName}>
							<CanvasCollectionImageLoader image={image} key={image.hashname} useEnhancedImage={this.props.enhancedImageFilter}>
								{({ status, image: imageEl, retryLoad }) => {
									const containerWidth = imageContainerWidth - (IMAGE_PADDING * 2);
									const containerHeight = imageContainerHeight - (Array.isArray(this.props.teethMeta[image.hashname]) === true ? IMAGE_TITLE_HEIGHT : 0);
									switch (status) {
										case CanvasCollectionImageLoader.STATUSES.FAILED:
											return (
												<Alignment
													horizontal={Alignment.horizontal.CENTER}
													vertical={Alignment.vertical.CENTER}
												>
													<span
														style={{ color: '#fff', fontSize: 12, cursor: 'pointer' }}
														onClick={retryLoad}
													>Reload image</span>
												</Alignment>
											);

										case CanvasCollectionImageLoader.STATUSES.LOADED: {
											const imageWidth = imageEl.naturalWidth;
											const imageHeight = imageEl.naturalHeight;
											const ratio = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);
											this._imagesRatio[image.hashname] = ratio;
											return (
												<div
													style={{
														position: 'absolute',
														left: (containerWidth / 2) - ((imageWidth * ratio) / 2),
														top: (containerHeight / 2) - ((imageHeight * ratio) / 2),
														width: imageWidth * ratio,
														height: imageHeight * ratio,
													}}
													className={classnames([
														this.props.notAnalyzedImages.includes(image.id) === true && HighlightedImageCssClassName,
														this.props.teethConflicts.find(({ imageHashName }) => imageHashName === image.hashname ) && HighlightedImageWithErrorCssClassName,
													])}
												>
													{this.state.selectedImageHashName === image.hashname && this._renderRotationButtons()}
													<MagnifyingGlassImage
														image={imageEl}
														width={imageWidth * ratio}
														height={imageHeight * ratio}
														sharpen={this.props.enhancedImageFilter ? !image.enhanced_image_url : undefined}
														View={UiAttachedImage}
													/>
													<div className={imageShapesCssClassName}>
														<div
															className={imageShapesInnerCssClassName}
															data-image-hashname={image.hashname}
															onMouseEnter={this._handleImageMouseEnter}
															onMouseLeave={this._handleImageMouseLeave}
														>
															{this.props.notAnalyzedImages.includes(image.id) === false && (
																<>
																	<CanvasObjects
																		zoom={ratio}
																		isVisible={this._canvasApi.draggingStatus === DRAGGING_STATUS.IDLE}
																		onTouched={this._handleTouchedObject}
																	>
																		{(canvasObjectsApi) => (
																			<FmxViewerShapes
																				imageHashName={image.hashname}
																				labels={this.props.labels[image.hashname] || []}
																				visibleLabelsIds={this.props.visibleLabelsIds}
																				selectedObject={canvasObjectsApi.selectedObject}
																				closestObjectToPoint={canvasObjectsApi.closestObjectToPoint}
																				isSelectedObjectInTransformation={canvasObjectsApi.isSelectedObjectInTransformation}
																				labelColorFilterFn={this.props.labelColorFilterFn}
																				selectionController={this._selectionController}
																				highlightedLabels={this.props.highlightedLabels}
																				onSetSelectedObject={canvasObjectsApi.setSelectedObject}
																			>
																				{(shapeApi) => this._renderShape({ shapeApi, canvasObjectsApi, imageWidth, imageHeight, imageHashName: image.hashname })}
																			</FmxViewerShapes>
																		)}
																	</CanvasObjects>
																	{this.props.shouldDisplayTeethNumbers === true && (
																		<ToothActions
																			zoom={ratio}
																			imageHashName={image.hashname}
																			allowShifting={
																				this.props.allowShiftTooth && (
																					this.props.teethConflicts.length === 0 ||
																					this.props.teethConflicts.some(({ imageHashName }) => imageHashName === image.hashname)
																				)
																			}
																			allowRemoving={this.props.allowRemoveTooth}
																			onShiftTooth={this._handleMoveTeeth}
																			onRemoveTooth={this._handleRemoveTooth}
																		/>
																	)}
																</>
															)}
														</div>
													</div>
												</div>
											);
										}

										default:
											return (
												<Alignment
													horizontal={Alignment.horizontal.CENTER}
													vertical={Alignment.vertical.CENTER}
												>
													<Loading />
												</Alignment>
											);
									}
								}}
							</CanvasCollectionImageLoader>
						</div>
						{this.props.shouldDisplayTeethNumbersUnderImages === true && (Array.isArray(teeth) === true && (
							<div className={imageTeethCssClassName} style={{ height: IMAGE_TITLE_HEIGHT }}>
								{this._getTeethTitle(teeth, image)}
							</div>
						))}
					</div>
				</div>
			);
		});
	}

	_renderRotationButtons () {
		const rotate = (direction = 'left') => {
			const imageHashName = this.state.selectedImageHashName;
			let imageId = null;
			Object.values(this.props.images).forEach((arr) => {
				arr.forEach((image) => {
					if ( image.hashname === imageHashName ) {
						imageId = image.id;
					}
				})
			});

			if ( imageId !== null ) {
				this.props[direction === 'left' ? 'onRotateLeft': 'onRotateRight' ]({
					imageId,
					imageHashName,
					skipAnnotationLoading: false,
					params: {
						skip_flip_annotation: true,
						skip_reanalyze: true,
					},
				});
			}
		};

		return (
			<div
				className={rotationButtonsContainerCssClassName}
				onMouseOver={(event) => {
					this.setState({
						rotateButtonsHovered: true,
					});
				}}
				onMouseLeave={() => {
					this.setState({
						rotateButtonsHovered: false,
						selectedImageHashName: null,
					});
				}}
			>
				<FmxViewerRotationButtons
					onRotateLeft={() => rotate('left')}
					onRotateRight={() => rotate('right')}
					viewport={this._canvasApi.viewport}
				/>
			</div>
		);
	}

	/**
	 * @param {Object} options
	 * @param {Object} options.shapeApi
	 * @param {Object} options.canvasObjectsApi
	 * @return {JSX.Element|null}
	 * @private
	 */
	_renderShape ({ shapeApi, canvasObjectsApi, imageWidth, imageHeight, imageHashName }) {
		const props = {
			id: shapeApi.id,
			shape: shapeApi.shape,
			color: shapeApi.color,
			borderStyle: shapeApi.borderStyle,
			showControls: shapeApi.isSelectedLabel === true,
			isHighlighted: shapeApi.isHighlighted === true,
			allowEditing: shapeApi.isSelectedLabel === true,
			showConfirmation: shapeApi.isHighlighted === true,
			allowDeleting: shapeApi.isInteracted === true,
			zoom: canvasObjectsApi.zoom,
			imageWidth,
			imageHeight,
			isVisible: shapeApi.isVisible,
			canvasObjectsApi,
			showMask: this.props.areFindingsMasksEnabled === true,
			showHeatMap: this.props.areHeatMapsEnabled === true,
			onSetEditing: (value) => {
				if ( this._canvasApi === null ) {
					return;
				}

				this._lastSelectedImageHashName = value === true ? this.state.selectedImageHashName : null;
				this._canvasApi.canDragCanvas(!value);
			},
			onLabelChange: (shape) => {
				this.props.onUpdateLabelShape({
					labelId: shapeApi.id,
					imageHashName: this._lastSelectedImageHashName,
					data: {
						...shapeApi.shape,
						...shape,
					},
				});
			},
			onLabelRemove: () => this._removeShape({
				labelId: shapeApi.id,
				imageHashName,
			}),
			onGetHeatmapUrl: (shape) => {
				if ( urlCache.hasCache(shapeApi.id) ) {
					return urlCache.getCache(shapeApi.id);
				}

				const url = shapeApi.onGetHeatmapUrl(shape, shapeApi.onGetColor);
				urlCache.setCache(shapeApi.id, url);

				return url;
			},
		};

		let Component = null;

		switch (shapeApi.shape.type) {
			case 'box':
				Component = ImageShapeBox;
				break;

			case 'poly':
				Component = ImageShapePolygon;
				break;

			case 'named_poly':
				Component = ImageShapeBoneloss;
				break;

			default:
				return null;
		}

		if ( shapeApi.shouldShowTooltip === true ) {
			return (
				<ShapeTooltip
					key={`shape_${shapeApi.id}`}
					label={shapeApi.label}
					viewport={this._canvasApi.viewport}
					component={Component}
					componentProps={props}
				/>
			);
		}

		return (<Component {...props} key={shapeApi.id} />);
	}

	/**
	 * @param {string} toothStart
	 * @param {string} toothEnd
	 * @param {string[]} teeth
	 * @returns {string[]}
	 * @private
	 */
	_geTeethSub (toothStart, toothEnd, teeth) {
		const startIndex = teeth.indexOf(toothStart);
		const endIndex = teeth.indexOf(toothEnd);

		return teeth.slice(startIndex, endIndex + 1);
	}

	_getTeethTitle (teeth, image) {
		if ( image.image_type === IMAGE_TYPE__PAN ) {
			return 'PAN';
		}

		return teethUtils.getTeethMetaTitle({ teeth, notationType: this.props.notationType });
	}

	_renderLayouts () {
		if ( this.state.teethMoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The image tooth is shifting, please wait.',
						}}
					/>
				</Popup>
			);
		}
		else if ( this.state.findingRemoveStatus === PROGRESS_STATUS.IN_PROGRESS ) {
			return (
				<Popup>
					<Alignment horizontal={Alignment.horizontal.CENTER}>
						<Loading />
					</Alignment>
					<div
						style={{ color: '#fff' }}
						dangerouslySetInnerHTML={{
							__html: 'The finding is removing, please wait.',
						}}
					/>
				</Popup>
			);
		}

		return null;
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<SizeDetector>
					{({ width: containerWidth, height: containerHeight }) => (
						<Viewport
							canvasWidth={containerWidth}
							canvasHeight={containerHeight}
							viewportWidth={containerWidth}
							viewportHeight={containerHeight}
							onGetZoom={(zoom) => {
								if ( zoom !== null ) {
									return Math.min(
										12,
										Math.max(0.5, zoom)
									);
								}

								return 1;
							}}
						>
							{(canvasApi) => {
								if ( canvasApi.viewport === null ) {
									return null;
								}
								this._canvasApi = canvasApi;
								setCanvas(canvasApi.viewport);
								setViewportData({
									zoom: canvasApi.zoom,
									offsetX: -canvasApi.offsetX,
									offsetY: -canvasApi.offsetY,
								});
								this._calculateSize(canvasApi.canvasSize.width, canvasApi.canvasSize.height);
								this._usedImageHashes = {};
								return (
									<div className={canvasCssClassName}>
										<CanvasEditorConnector canvasApi={canvasApi} />
										<div
											className={`${rowCssClassName} ${rowCssClassName}__m-top`}
											style={{ height: this._calculatedSize[0][1] }}
										>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[0][2][0][0], height: this._calculatedSize[0][2][0][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('18', '14', teethUtils.getOrderedTopTeeth()),
														topTeethSort,
														topTeethImagesComparer
													),
													this._calculatedSize[0][2][0][0],
													this._calculatedSize[0][2][0][1],
													'bottom'
												)}
											</div>
											<div
												className={`${cellCssClassName} ${cellCssClassName}__m-main`}
												style={{ width: this._calculatedSize[0][2][1][0], height: this._calculatedSize[0][2][1][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('13', '23', teethUtils.getOrderedTopTeeth()),
														topTeethSort,
														topTeethImagesComparer
													),
													this._calculatedSize[0][2][1][0],
													this._calculatedSize[0][2][1][1],
													'bottom'
												)}
											</div>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[0][2][2][0], height: this._calculatedSize[0][2][2][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('24', '28', teethUtils.getOrderedTopTeeth()),
														topTeethSort,
														topTeethImagesComparer
													),
													this._calculatedSize[0][2][2][0],
													this._calculatedSize[0][2][2][1],
													'bottom'
												)}
											</div>
										</div>
										<div
											className={`${rowCssClassName} ${rowCssClassName}__m-middle`}
											style={{ height: this._calculatedSize[1][1] }}
										>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[1][2][0][0], height: this._calculatedSize[1][2][0][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__BITEWING,
														this._geTeethSub('18', '11', teethUtils.getOrderedTopTeeth()).concat(
															this._geTeethSub('48', '41', teethUtils.getOrderedBottomTeeth())
														),
														bw1and4hSort,
														bwImagesComparer
													),
													this._calculatedSize[1][2][0][0],
													this._calculatedSize[1][2][0][1],
													'bottom'
												)}
											</div>
											<div
												className={`${cellCssClassName} ${cellCssClassName}__m-main`}
												style={{ width: this._calculatedSize[1][2][1][0], height: this._calculatedSize[1][2][1][1] }}
											>
												{this._renderImages(this._findPan(), this._calculatedSize[1][2][1][0], this._calculatedSize[1][2][1][1], 'bottom')}
											</div>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[1][2][2][0], height: this._calculatedSize[1][2][2][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__BITEWING,
														this._geTeethSub('21', '28', teethUtils.getOrderedTopTeeth()).concat(
															this._geTeethSub('31', '38', teethUtils.getOrderedBottomTeeth())
														),
														bw2and3hSort,
														bwImagesComparer
													),
													this._calculatedSize[1][2][2][0],
													this._calculatedSize[1][2][2][1],
													'bottom'
												)}
											</div>
										</div>
										<div
											className={`${rowCssClassName} ${rowCssClassName}__m-bottom`}
											style={{ height: this._calculatedSize[2][1] }}
										>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[2][2][0][0], height: this._calculatedSize[2][2][0][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('48', '44', teethUtils.getOrderedBottomTeeth()),
														bottomTeethSort,
														bottomTeethImagesComparer,
													),
													this._calculatedSize[2][2][0][0],
													this._calculatedSize[2][2][0][1],
													'top'
												)}
											</div>
											<div
												className={`${cellCssClassName} ${cellCssClassName}__m-main`}
												style={{ width: this._calculatedSize[2][2][1][0], height: this._calculatedSize[2][2][1][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('43', '33', teethUtils.getOrderedBottomTeeth()),
														bottomTeethSort,
														bottomTeethImagesComparer
													),
													this._calculatedSize[2][2][1][0],
													this._calculatedSize[2][2][1][1],
													'top'
												)}
											</div>
											<div
												className={cellCssClassName}
												style={{ width: this._calculatedSize[2][2][2][0], height: this._calculatedSize[2][2][2][1] }}
											>
												{this._renderImages(
													this._findImages(
														IMAGE_TYPE__PERIAPICAL,
														this._geTeethSub('34', '38', teethUtils.getOrderedBottomTeeth()),
														bottomTeethSort,
														bottomTeethImagesComparer,
													),
													this._calculatedSize[2][2][2][0],
													this._calculatedSize[2][2][2][1],
													'top'
												)}
											</div>
										</div>
									</div>
								);
							}}
						</Viewport>
					)}
				</SizeDetector>
				{this._renderLayouts()}
			</div>
		);
	}
}
