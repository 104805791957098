export default {
	'dialog.remove_tooth.title': 'Delete tooth',
	'dialog.remove_tooth.confirm': 'Are you sure to permanently delete tooth',
	'dialog.remove_all.title': 'Delete all findings',
	'dialog.remove_all.confirm': 'Are you sure to permanently delete all findings?',

	'buttons.show_all': 'Show all findings',
	'buttons.show_less': 'Show less',

	'confirm_all.alt': 'Confirm all findings',
	'confirm_all.text': 'Confirm all',

	'remove_all.alt': 'Delete all findings',
	'remove_all.text': 'Delete all',

	'iso': 'ISO system',
	'uni': 'Universal Numbering System',

	'filtered-findings.label': 'Filters({n})',
	'confidence': 'Threshold',
	'confidence.tooltip': 'AI Confidence: how much AI is confident in the presence of a suspicious finding inside the bounding box.',
	
	'labels.tooth': 'Tooth',
	'labels.teeth': 'Tooth',
	'labels.caries': 'Caries',
	'labels.prob_caries': 'Prob caries',
	'labels.missing_teeth': 'Missing teeth',
	'labels.caries_approximal': 'Caries approximal',
	'labels.caries_occlusal': 'Caries occlusal',
	'labels.caries_smooth': 'Caries smooth',
	'labels.caries_root': 'Caries root',
	'labels.caries_compound': 'Caries compound',
	'labels.periodontitis': 'Apical lesion',
	'labels.crown': 'Crown',
	'labels.implant': 'Implant',
	'labels.bridge': 'Bridge',
	'labels.x': 'Unknown',
	'labels.md': 'Mesiodens',
	'labels.endodontic_treatment': 'Endodontic treatment',
	'labels.restoration': 'Filling',
	'labels.apical_perforation': 'Apical perforation',
	'labels.overfilling': 'Overfilling',
	'labels.underfilling': 'Underfilling',
	'labels.ledge': 'Ledge',
	'labels.void': 'Void',
	'labels.other': 'Other',
	'labels.caries_proximal': 'Caries Proximal',
	'labels.caries_non_proximal': 'Caries Non-proximal',
	
	'labels.apical_radiolucency_reader': 'Apical radiolucency Reader',
	'labels.caries_proximal_reader': 'Caries Proximal Reader',
	'labels.caries_non_proximal_reader': 'Caries Non-proximal Reader',
	'labels.caries_not_matched': 'Caries Unmatched',
	'labels.caries_matched': 'Caries Matched',
	'labels.tooth_bl': 'Bone Level',
	'labels.tooth_bone_loss': 'Bone loss',
	'labels.tooth_bl_mm': 'Tooth Bone Level',
	
	'labels.wear': 'Wear',
	'labels.tartar': 'Tartar',
	'labels.other2': 'Other2',
	'labels.other3': 'Other3',

	'labels.apical_radiolucency': 'Apical radiolucency',

	'labels.tags.secondary': 'Secondary',
	'labels.tags.suspected': 'Suspected',
	'labels.undercut': 'Misfit',
	'labels.abutment': 'Abutment',
	'labels.pin': 'Post',
	'labels.composite': 'Composite',
	'labels.missing_tooth': 'Missing tooth',
	'labels.furcation_involvement': 'Furcation involvement',
	'labels.overhang': 'Defective restoration',
	'labels.caries_reader': 'Caries Reader',
	'labels.super_tooth': 'Supernumerary tooth',
	'labels.burnout': 'Burnout',
	'labels.tooth_fracture': 'Tooth fracture',
	'labels.pontic': 'Pontic',
	'labels.caries_surfaces': 'Caries',
	'labels.composite_filling': 'Composite filling',
	'labels.amalgam_filling': 'Amalgam filling',
	'labels.impacted': 'Impacted',
	'labels.implant_site': 'Implant Site',
	'labels.md_ant_lesion': 'Md Ant Lesion',

	'labels.caries_yjhilove@hanmail.net': 'Caries(Annotator 1)',
	'labels.restoration_yjhilove@hanmail.net': 'Filling(Annotator 1)',
	'labels.periodontitis_yjhilove@hanmail.net': 'Apical lesion(Annotator 1)',
	'labels.caries_frontip@daum.net': 'Caries(Annotator 2)',
	'labels.restoration_frontip@daum.net': 'Filling(Annotator 2)',
	'labels.periodontitis_frontip@daum.net': 'Apical lesion(Annotator 2)',
	'labels.caries_jyh2704@gmail.com': 'Caries(Annotator 3)',
	'labels.restoration_jyh2704@gmail.com': 'Filling(Annotator 3)',
	'labels.periodontitis_jyh2704@gmail.com': 'Apical lesion(Annotator 3)',
	
};
