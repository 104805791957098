import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TfvToothAction from '../../../tfv/components/list/TfvToothActionConnector';

import './styles/ToothActions.css';


const toothWidth = 50;
const toothHeight = 24;

const baseCssClassName = 'tooth-actions';
const toothCssClassName = `${baseCssClassName}__tooth`;


export default class ToothActions extends PureComponent {
	static propTypes = {
		teeth: PropTypes.shape({
			toothKey: PropTypes.string.isRequired,
			localizedToothKey: PropTypes.string.isRequired,
			shape:PropTypes.shape({
				startX: PropTypes.number.isRequired,
				endX: PropTypes.number.isRequired,
				startY: PropTypes.number.isRequired,
				endY: PropTypes.number.isRequired,
			}),
			withConflict: PropTypes.bool.isRequired,
		}).isRequired,
		zoom: PropTypes.number.isRequired,
		imageHashName: PropTypes.string.isRequired,
		allowShifting: PropTypes.bool,
		allowRemoving: PropTypes.bool,
		onShiftTooth: PropTypes.func,
		onRemoveTooth: PropTypes.func,
	};

	static defaultProps = {
		allowShifting: true,
		allowRemoving: false,
	};

	render() {
		const isSmallFontSize = this.props.teeth.some(({ shape }) => (shape.endX - shape.startX) * this.props.zoom < toothWidth);

		return (
			<div className={baseCssClassName}>
				{this.props.teeth.map(({ toothKey, localizedToothKey, shiftedToothKey, shape, withConflict }) => (
					<div
						className={toothCssClassName}
						style={{
							left: (shape.startX + (shape.endX - shape.startX) / 2) * this.props.zoom,
							top: (shape.startY + ((shape.endY - shape.startY)) / 2) * this.props.zoom,
							width: toothWidth,
							height: toothHeight,
							margin: `${-toothHeight / 2}px 0 0 ${-toothWidth / 2}px`,
						}}
					>
						<TfvToothAction
							key={toothKey}
							withConflict={withConflict}
							toothKey={toothKey}
							localizedToothKey={localizedToothKey}
							shiftedToothKey={shiftedToothKey}
							viewportLocation={{
								left: 0,
								top: 0,
								right: window.document.body.clientWidth,
								bottom: window.document.body.clientHeight,
							}}
							fontSize={isSmallFontSize === true ? 10 : undefined}
							imageHashName={this.props.imageHashName}
							allowShifting={this.props.allowShifting}
							allowRemoving={this.props.allowRemoving}
							onShiftTooth={this.props.onShiftTooth}
							onRemoveTooth={this.props.onRemoveTooth}
						/>
					</div>
				))}
			</div>
		);
	}
}
