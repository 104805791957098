import mainConfig from '../../configs/mainConfig';
import labelsUtils from '../../appUtils/labelsUtils';
import { tagIsSurface } from '../../modules/label-tags/utils';

import labelTagsAction from '../../modules/label-tags/actions/labelTagsAction';
import editorActions from '../../actions/editorActions';
import editorSelectors from '../../selectors/editorSelectors';
import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';
import labelTagsSelectors from '../../modules/label-tags/selectors/labelTagsSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import userSelectors from "../../selectors/userSelectors";
import {LABELS_STAGE_COMPLETED} from "../../constants/labelsStageConstants";


export function processKey (store, event) {
	if ( !event.key ) {
		return;
	}

	const storeState = store.getState();
	const key = event.key.toLowerCase();
	const editorData = editorSelectors.selectEditor(storeState);
	const editorMode = editorData.editorMode;
	const selectedLabel = editorData.selectedLabel;

	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(storeState);
	const fdaLabelsStage = editorSelectors.selectLabelsStage(storeState);

	if ( !editorData.currentImageId ) {
		return;
	}

	if ( event.target !== window.document.body ) {
		return;
	}

	if (
		key === 'escape' &&
		(
			editorMode === mainConfig.EDITOR_MODE__EDIT_MODE ||
			editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
			editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ||
			(
				editorMode === mainConfig.EDITOR_MODE__SELECT_MODE && selectedLabel
			)
		)
	) {
		store.dispatch(editorActions.resetMode());
		return;
	}

	if (isFDAAnnotationEnabled && fdaLabelsStage === LABELS_STAGE_COMPLETED) {
		return;
	}

	if ( selectedLabel ) {
		const tag = labelsUtils.getLabelTagByHotKey(key, labelGetters.getLabelClassId(selectedLabel));

		if ( tag) {
			const labelId = labelGetters.getLabelId(selectedLabel);
			const availableTags = labelsSelectors.selectAvailableTagsByClassId(storeState, { classId: labelGetters.getLabelClassId(selectedLabel) });
			const usesReaderStudyModeUi = userSelectors.selectUsesReaderStudyModeUi(storeState) === true;
			for (let i = 0, l = availableTags.length; i < l; i++) {
				const labelTag = availableTags[i];
				const canAdd = (
					labelTag.canAdd === true &&
					(
						usesReaderStudyModeUi !== true ||
						tagIsSurface(labelTag.key) === false
					)
				);

				if ( labelTag.key === tag.key && true === canAdd ) {
					store.dispatch(labelTagsAction.addTag({
						labelId,
						tag: {
							key: tag.key,
							localizedName: tag.readable_name,
							hotKey: tag.hotkey,
						},
					}));
				}
			}
			
			return;
		}
	}

	const classId = labelsUtils.getLabelByKey(key);

	if ( !classId ) {
		return;
	}

	event.preventDefault();
	event.stopPropagation();

	const parentRelations = currentCollectionSelectors.selectParentClass(storeState, { classId });
	const childrenRelations = currentCollectionSelectors.selectChildrenClasses(storeState, { classId });

	if ( classId === 'tooth' ) {
		store.dispatch(editorActions.addLabel({
			classId,
			editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX,
			requiredShape: labelsUtils.isShapeRequiredForClass('tooth'),
		}));
		return;
	}

	if (
		parentRelations.length > 0 &&
		parentRelations.some((relation) => relation.relation_type === 'mandatory')
	) {
		if ( !selectedLabel ) {
			// todo show message
			return;
		}
		const selectedLabelClassId = labelGetters.getLabelClassId(selectedLabel);
		if ( parentRelations.some((relation) => relation.subject_label === selectedLabelClassId) ) {
			store.dispatch(editorActions.addLabel({
				classId,
				parentLabel: selectedLabel,
				editorMode: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
				requiredShape: labelsUtils.isShapeRequiredForClass(classId),
			}));
		}
		return;
	}

	store.dispatch(editorActions.addLabel({
		classId,
		editorMode: classId === 'tooth'
				? mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX
				: mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
		noParent: childrenRelations.length > 0,
		requiredShape: labelsUtils.isShapeRequiredForClass(classId),
	}));
}
