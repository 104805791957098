import apiActionTypes from '../actions/apiActionTypes';
import imagesActionTypes from '../actions/imagesActionTypes';


/**
 * @typedef {CollectionImageId,CollectionImage} ImagesStoreState
 */

const initialState = {};

/**
 * @param {ImagesStoreState} state
 * @param {ReduxAction} action
 * @return {ImagesStoreState}
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.images || state );

		case imagesActionTypes.ACTION_IMAGES__SET_IMAGES:
			return action.payload;

		case imagesActionTypes.ACTION_IMAGES__ADD_IMAGE:
			return {
				...state,
				[action.payload.id]: action.payload.data,
			};

		case imagesActionTypes.ACTION_IMAGES__REMOVE_IMAGE: {
			const newState = {
				...state,
			};

			delete newState[action.payload];
			
			return newState;
		}

		case imagesActionTypes.ACTION_IMAGES__UPDATE_IMAGE: {
			return {
				...state,
				[action.payload.id]: action.payload.data,
			};
		}

		default:
			return state;
	}
}
