import React, { PureComponent, Fragment } from 'react';
import statsApi from '../../api/statsApi';
import classnames from 'classnames';

import lodashGet from 'lodash/get';
import { getDictionary } from '../../appUtils/locale';

import { StatsCardHeader, StatsCard } from './StatsCard';

import { formatPrice } from './StatsUtils';

import FormSelect from '../FormSelect';
import Loading from '../Loading';
import Button from '../Button/Button';

import StatsGrid from './StatsGrid';

import './styles/Stats.css';




const i18n = getDictionary('stats');

const STAT_MODE_PATHOLOGIES = 'pathologies';
const STAT_MODE_PREVIOUS_TREATMENT = 'previous_treatment';
const STAT_MODE_OTHER  = 'other';
const STAT_MODE_EXPERIMENTAL  = 'experimental';


const AVAILABLE_TABS = [ STAT_MODE_PATHOLOGIES, STAT_MODE_PREVIOUS_TREATMENT, STAT_MODE_OTHER, STAT_MODE_EXPERIMENTAL ];

const baseCssClassName = 'stats';
const headerCssClassName = `${baseCssClassName}-header`;
const cardsCssClassName = `${baseCssClassName}-cards`;
const cardCssClassName = `${baseCssClassName}-card-item`;

const cardInfoCssClassName = `${cardCssClassName}-info`;
const cardInfoContentCssClassName = `${cardInfoCssClassName}__content`;
const cardInfoHeaderCssClassName = `${cardInfoCssClassName}__header`;
const cardInfoPriceCssClassName = `${cardInfoCssClassName}__price`;

const cardSummaryCssClassName = `${cardCssClassName}-summary`;
const cardSummaryContentCssClassName = `${cardSummaryCssClassName}__content`;
const cardSummaryHeaderCssClassName = `${cardSummaryCssClassName}__header`;
const cardSummaryPriceCssClassName = `${cardSummaryCssClassName}__price`;

const wrapperCssClassName = `${baseCssClassName}-wrapper`;

const statsCssClassName = `${baseCssClassName}-stats`;
const statsTabsCssClassName = `${statsCssClassName}__tabs`;
const statsTabCssClassName = `${statsCssClassName}__tab`;
const statsGridCssClassName = `${statsCssClassName}__grid`;


const statusCssClassName = `${baseCssClassName}-status`;
const userSelectorCssClassName = `${baseCssClassName}-user-selector`;
const contentCssClassName = `${baseCssClassName}-content`;
const errorMessageCssClassName = `${baseCssClassName}-error-message`;
const errorMessageButtonCssClassName = `${errorMessageCssClassName}__button`;


class StatsHoc extends PureComponent {
	constructor (props, context) {
		super(props, context);

		this.state = {
			isLoading: false,
			isLoaded: false,
			hasLoadingError: false,
			currentUser: null,
			users: null,
			userData: null,
			statsMode: STAT_MODE_PATHOLOGIES,
		};
	}

	componentWillMount () {
		this._loadUsers();
	}

	componentWillUnmount () {
		this.state = null;
	}

	async _loadUsers () {
		this.setState({
			isLoading: true,
			hasLoadingError: false,
		});

		try {
			const users = await(statsApi.getUsers());

			if ( !this.state ) {
				return;
			}

			const nextState = {
				isLoading: false,
				users,
			};

			const userKeys = Object.keys(users);
			if ( userKeys.length === 1 ) {
				nextState.currentUser = users[0];
			}

			this.setState(nextState, () => this._loadUserStats());
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isLoading: false,
				hasLoadingError: true,
			});
		}
	}

	async _loadUserStats () {
		if ( !this.state.currentUser ) {
			return;
		}

		this.setState({
			isLoading: true,
			isLoaded: false,
			hasLoadingError: false,
		});

		try {
			const userData = await(statsApi.getUserStats({ userId: this.state.currentUser }));

			if ( !this.state ) {
				return;
			}

			this.setState({
				isLoading: false,
				isLoaded: true,
				userData: userData[this.state.currentUser],
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isLoading: false,
				hasLoadingError: true,
			});
		}
	}

	_handleUserChange = (event) => {
		const value = event.currentTarget.value;
		this.setState({
			currentUser: value === '-1' ? null : value,
		}, () => this._loadUserStats());
	}

	_handleRetryButtonClick = () => {
		if ( !this.state.users ) {
			this._loadUsers();
		}
		else {
			this._loadUserStats();
		}
	}

	_handleTabClick = (event) => {
		const tabId = event.target.dataset.tabId;

		if ( tabId === this.state.statsMode ) {
			return;
		}

		this.setState({
			statsMode: tabId,
		});
	}

	_renderStats () {
		const userData = this.state.userData;
		const columns = [ 'collection_name', 'pan_images_count', 'xray_images_count' ].concat(userData.findings_order[this.state.statsMode]);

		return (
			<Fragment>
				<div className={cardsCssClassName}>
					<div className={cardCssClassName}>
						<div className={cardInfoCssClassName}>
							<StatsCard theme={StatsCard.THEME_DARK}>
								<div className={cardInfoContentCssClassName}>
									<div className={cardInfoHeaderCssClassName}>
										MTD NEW Image<br />Treatment Potential
									</div>
									<div className={cardInfoPriceCssClassName}>
										{formatPrice(lodashGet(userData, 'summary.total', 0))}
									</div>
								</div>
							</StatsCard>
						</div>
					</div>
					<div className={cardCssClassName}>
						<div className={cardSummaryCssClassName}>
							<StatsCard theme={StatsCard.THEME_LIGHT}>
								<div className={cardSummaryContentCssClassName}>
									<span className={cardSummaryHeaderCssClassName}>Caries<br/>Treatment Opportunities</span>
									<span className={cardSummaryPriceCssClassName}>{lodashGet(userData, 'summary.findings.caries', 0)}</span>
								</div>
							</StatsCard>
						</div>
					</div>
					<div className={cardCssClassName}>
						<div className={cardSummaryCssClassName}>
							<StatsCard theme={StatsCard.THEME_LIGHT}>
								<div className={cardSummaryContentCssClassName}>
									<span className={cardSummaryHeaderCssClassName}>Missing Teeth<br/>Treatment Opportunities</span>
									<span className={cardSummaryPriceCssClassName}>{lodashGet(userData, 'summary.findings.missing_teeth', 0)}</span>
								</div>
							</StatsCard>
						</div>
					</div>
				</div>
				<div className={statsCssClassName}>
					<div className={statsTabsCssClassName}>
						{AVAILABLE_TABS.map((tab) => (
							<div
								key={tab}
								className={classnames([
									statsTabCssClassName,
									this.state.statsMode === tab && `${statsTabCssClassName}__m-selected`,
								])}
								data-tab-id={tab}
								onClick={this._handleTabClick}
							>{i18n(`tabs.${tab}`)}</div>
						))}
					</div>
					<div className={statsGridCssClassName}>
						<StatsGrid
							mode={this.state.statsMode}
							columns={columns}
							data={Object.keys(userData.statistics).sort((a, b) => userData.names_map[a].localeCompare(userData.names_map[b])).map((collectionHash, i) => {
								const collection = userData.statistics[collectionHash];

								return {
									collectionHashName: collectionHash,
									collectionName: userData.names_map[collectionHash],
									panImages: lodashGet(collection, 'images.pan', 0),
									xrayImages: lodashGet(collection, 'images.xray', 0),
									findings: collection[this.state.statsMode],
								};
							})}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
	
	_renderErrorMessage () {
		return (
			<div className={errorMessageCssClassName}>
				<div>{i18n('statuses.error')}</div>
				<div className={errorMessageButtonCssClassName}>
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this._handleRetryButtonClick}
					>{i18n('statuses.error.button')}</Button>
				</div>
			</div>
		)
	}

	_renderContent () {
		if ( !this.state.users ) {
			return null;
		}

		return (
			<Fragment>
				{this.state.users.length > 1 && (
					<div className={headerCssClassName}>
						<div className={userSelectorCssClassName}>
							<FormSelect
								value={this.state.currentUser || '-1'}
								items={[ { value: '-1', label: '' } ].concat(this.state.users.map((user) => ({
									value: user,
									label: user,
								})))}
								block
								onChange={this._handleUserChange}
							/>
						</div>
					</div>
				)}
				<div className={contentCssClassName}>
					{this.state.isLoading && (<div className={statusCssClassName}><Loading /></div>)}
					{this.state.hasLoadingError && (<div className={statusCssClassName}>{this._renderErrorMessage()}</div>)}
					{this.state.currentUser && this.state.isLoaded && this._renderStats()}
				</div>
			</Fragment>
		);
	}

	_renderLoading () {
		if ( this.state.users || !this.state.isLoading ) {
			return null;
		}

		return (<div className={statusCssClassName}><Loading /></div>);
	}

	_renderError () {
		if ( this.state.users || !this.state.hasLoadingError ) {
			return null;
		}

		return (
			<div className={statusCssClassName}>{this._renderErrorMessage()}</div>
		);
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={wrapperCssClassName}>
					{this._renderLoading()}
					{this._renderError()}
					{this._renderContent()}
				</div>
			</div>
		);
	}
}

export default StatsHoc;
