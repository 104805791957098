import React from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';

import utils from './AlignmentUtils';
import constants from './AlignmentConstants';

import './styles/Alignment.css';


const HORIZONTAL_ALIGNMENT = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
};

const VERTICAL_ALIGNMENT = {
	TOP: 'top',
	CENTER: 'center',
	BOTTOM: 'bottom',
};

/**
 * @typedef {Object} AlignmentProps
 *
 * @property {ReactElement} children
 * @property {string} [horizontal=left]
 * @property {string} [vertical=top]
 * @property {string|number} [width]
 * @property {string|number} [height]
 */

const propTypes = {
	children: PropTypes.node.isRequired,

	horizontal: PropTypes.oneOf(lodashValues(HORIZONTAL_ALIGNMENT)),
	vertical: PropTypes.oneOf(lodashValues(VERTICAL_ALIGNMENT)),

	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),

	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

const defaultProps = {
	horizontal: constants.HORIZONTAL_ALIGNMENT.LEFT,
	vertical: constants.VERTICAL_ALIGNMENT.TOP,
};

/**
 * Component aligns the children elements in the horizontal and vertical plane.
 *
 * @param {AlignmentProps} props
 * @returns {ReactElement}
 */
function Alignment (props) {
	const {
		children,
		width,
		height,
	} = props;

	return (
		<div className={utils.buildBaseClassName(props)}>
			<div
				className={utils.buildContentClassName(props)}
				style={{
					width,
					height,
				}}
			>{children}</div>
		</div>
	);
}

Alignment.horizontal = constants.HORIZONTAL_ALIGNMENT;
Alignment.vertical = constants.VERTICAL_ALIGNMENT;

Alignment.propTypes = propTypes;
Alignment.defaultProps = defaultProps;

export default Alignment;
