import apiActionTypes from '../../../actions/apiActionTypes';
import labelTagsActionTypes  from '../actions/labelTagsActionTypes';


/**
 * @typedef {Object<LabelTagId, LabelTag>} LabelTagsStoreState
 */

/**
 *
 * @param {LabelTagsStoreState} state
 * @param {ReduxAction} action
 * @return {LabelTagsStoreState}
 */
export default function labelTagsReducer (state = {}, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return ( action.payload.labelTags || state );

		case labelTagsActionTypes.ACTION_LABEL_TAGS__ADD_TAG: {
			return {
				...state,
				[action.payload.tagId]: action.payload.data,
			}
		}

		case labelTagsActionTypes.ACTION_LABEL_TAGS__REMOVE_TAG: {
			const nextState = {
				...state,
			};

			delete nextState[action.payload];

			return nextState;
		}

		case labelTagsActionTypes.ACTION_LABEL_TAGS__REPLACE_TAG: {
			const id = action.payload.tagId;
			return {
				...state,
				[id]: {
					...state[id],
					...action.payload.data,
				},
			};
		}

		default:
			return state;
	}
}
