const surfaces = [
	'lingual/buccal',
	'mesial',
	'distal',
	'occlusal',
];


/**
 * @param {string} tagKey
 * @return {boolean}
 */
export const tagIsSurface = (tagKey) => {
	return surfaces.includes(tagKey) === true;
};

/**
 * @param {string} tagKey
 * @return {boolean}
 */
export const tagIsConfidence = (tagKey) => {
	return /^cs\d+$/.test(tagKey);
}

