import mainConfig from "../configs/mainConfig";


function getSnapshotLink ({
	sharedHashname,
	isShared,
  	imageHashName,
	collectionId,
	isTreatmentPlan = false,
}) {
	if ( isTreatmentPlan === true ) {
		return`${window.location.origin}${mainConfig.URL_BASE_PATH}collections/${collectionId}/image/${imageHashName}/treatment_plan`;
	}

	if ( !isShared ) {
		return`${window.location.origin}${mainConfig.URL_BASE_PATH}collections/${collectionId}/image/${imageHashName}`;
	}
	
	return`${window.location.origin}${mainConfig.URL_BASE_PATH}shared/${sharedHashname}`;
}

function getReportLink ({
	sharedHashname,
	isShared,
	imageHashName,
	collectionId,
	isTreatmentPlan = false,
}) {
	if ( isTreatmentPlan === true ) {
		return`${window.location.origin}${mainConfig.URL_BASE_PATH}collections/${collectionId}/image/${imageHashName}/treatment_plan/report`;
	}

	if ( !isShared ) {
		return`${window.location.origin}${mainConfig.URL_BASE_PATH}collections/${collectionId}/image/${imageHashName}/report`;
	}
	
	return`${window.location.origin}${mainConfig.URL_BASE_PATH}shared/${sharedHashname}/report`;
}

export default {
	getSnapshotLink,
	getReportLink,
}
