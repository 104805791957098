import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import userActions from '../../actions/userActions';

import { SUBMIT_STATUSES } from './SignInConstants';
import SignInForm from './SignInForm';
import {connect} from "react-redux";


class SignInHoc extends PureComponent {
	static propTypes = {
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		onSignIn: PropTypes.func.isRequired,
	};

	state = {
		username: '',
		password: '',
		from: null,
		submitStatus: SUBMIT_STATUSES.IDLE,
	};

	componentDidMount () {
		const params = (/^[?#]/.test(this.props.location.search) ? this.props.location.search.slice(1) : this.props.location.search)
			.split('&')
			.reduce((params, param) => {
				const [ key, value ] = param.split('=');
				params[key] = decodeURIComponent(value);
				return params;
			}, {});

		if ( typeof params.username === 'string' ) {
			this.setState({
				username: params.username,
			});
		}

		if ( typeof params.from === 'string' ) {
			this.setState({
				from: params.from,
			});
		}
	}

	componentWillUnmount () {
		this.state = null;
	}

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleSubmit = (event) => {
		event.preventDefault();

		this._signIn();
	};

	/**
	 * @param {string} name
	 * @param {string} value
	 * @private
	 */
	_handleFieldChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	async _signIn () {
		try {
			this.setState({
				submitStatus: SUBMIT_STATUSES.IN_PROGRESS,
			});

			await this.props.onSignIn({
				username: this.state.username,
				password: this.state.password,
			});

			if ( this.state === null ) {
				return;
			}

			// this.props.history.push(`/`);
			window.location.href = '/';
			this.setState({
				submitStatus: SUBMIT_STATUSES.SUCCESS,
			});
		}
		catch (error) {
			if ( this.state === null ) {
				return;
			}

			this.setState({
				submitStatus: SUBMIT_STATUSES.FAILED,
			});
		}
	}

	render () {
		return (
			<SignInForm
				username={this.state.username}
				password={this.state.password}
				submitStatus={this.state.submitStatus}
				from={this.state.from}
				onFieldChange={this._handleFieldChange}
				onSubmit={this._handleSubmit}
			/>
		);
	}
}

export default withRouter(connect(null, (dispatch) => ({
	onSignIn: (data) => dispatch(userActions.signIn(data)),
}))(SignInHoc));
