export default {
	'messages.wait': 'Пожалуйста, подождите',
	'messages.image_wait': 'Снимок загружается, подождите',
	'messages.loading_error': 'При загрузке возникла ошибка',
	'messages.error.with_retry': 'Возникла ошибка. Попробуйте еще раз.',
	'messages.success': 'Успех',

	'buttons.try_again': 'Попробовать еще раз',
	'buttons.change': 'Изменить',
	'buttons.ok': 'Ок',
	'buttons.agree': 'Я согласен(на)',
	'buttons.back': 'Назад',
	'buttons.delete': 'Удалить',
	'buttons.cancel': 'Отменить',
	'buttons.copy': 'Копировать',
	'buttons.close': 'Закрыть',
	'buttons.save': 'Сохранить',
	'buttons.continue': 'Продолжить',
	'buttons.download': 'Скачать',
	'buttons.sign_in': 'Вход',
	'buttons.sign_up': 'Регистрация',
	'buttons.logout': 'Выйти',
	'buttons.free_trial': 'Пробный период',
	'buttons.analyze': 'Анализ',
	'buttons.report': 'Отчет',

	'confirm.dialog.title': 'Подтвердите',
	'confirm.buttons.yes': 'Да',
	'confirm.buttons.no': 'Нет',

	'warning.only_digits': 'Используйте только цифры',

	'gender.female': 'Женский',
	'gender.male': 'Мужской',
	'gender.unknown': 'Неизвестно',

	'image_types.pan': 'Панорамный снимок',
	'image_types.xray': 'Прицельный снимок',
};
