export default {
	'analyzing.dialog.title': '信息',
	'analyzing.progress': '此软件将显示感兴趣区域ROI，以方便专家做病理分析',
	'analyzing.success': '分析当前影像',

	'login_message.dialog.title': '注意',

	'hint.select_tooth': '请从牙科图表中选中牙齿',
	'hint.draw_secondary_box': '请标记出病理位置',

	'overlay.cancel_action': '取消操作',

	'buttons.open_last_image': '打开上一张影像',
	'buttons.upload_image': '上传影像',

	'select_tooth.placeholder': '或选择牙科图表未显示的',

	'image_flipping': '翻转图像',

};
