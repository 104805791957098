import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import imageUtils from '../../appUtils/imageUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';
import imagesCollectionsSelectors from '../../selectors/imagesCollectionsSelectors';

import './styles/FdaComplete.css';
import Button from "../Button";
import userSelectors from "../../selectors/userSelectors";
import editorSelectors from "../../selectors/editorSelectors";
import {LABELS_STAGE_AI_AIDED} from "../../constants/labelsStageConstants";

const baseCssClassName = 'canvas-controls-fda-complete';


class FdaCompleteButton extends PureComponent {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	_handleClick = () => {
		imageUtils.checkHasUnsavedChanged(() => {
			this.context.router.history.push('/collection-list');
		});
	};

	render () {
		return this.props.visible && (
			<Button
				size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.LARGE}
				theme={Button.AVAILABLE_THEMES.SECONDARY}
				onClick={this._handleClick}
			>
				<span className={`${baseCssClassName}__icon-complete`}>Complete</span>
			</Button>
		);
	}
}

export default connect((state) => {
	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(state);
	const nextImage = imagesCollectionsSelectors.selectNextImageInCollection(state,
		{ preventCircularSelection: true }
	)
	const fdaLabelsStage = editorSelectors.selectLabelsStage(state);
	const visible = isFDAAnnotationEnabled && fdaLabelsStage === LABELS_STAGE_AI_AIDED && (nextImage == null);
	return {
		visible
	};
})(FdaCompleteButton);
