import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import teethConfig from '../../configs/teethConfig';
import editorActions from '../../actions/editorActions';

import Alignment from '../Alignment';
import DentalNotationDiagram from '../DentalNotationDiagram';
import ToothSelector from './ToothSelector';
import Popup from '../Popup';
import Hint from '../Hint';

import { getDictionary } from '../../appUtils/locale';

import './styles/Editor.css';


const i18n = getDictionary('editor');

const baseCssClassName = 'editor';
const selectToothCssClassName = `${baseCssClassName}-select-tooth`;
const toothSelectorNotificationCssClassName = `${baseCssClassName}-tooth-selector-notification`;
const toothSelectorNotificationTitleCssClassName = `${toothSelectorNotificationCssClassName}__title`;
const toothSelectorNotificationElementCssClassName = `${toothSelectorNotificationCssClassName}__element`;

class SelectToothEditor extends PureComponent {
	componentWillMount () {
		window.document.body.scrollTo(0, 0);
	}

	_handleToothSelected = toothKey => {
		this.props.onSelectToothKey({
			toothKey
		});
	}

	_handleSelectToothOverlayClick = () => {
		this.props.onResetMode();
	}

	render() {
		return (
			<Popup
				alignmentProps={{
					horizontal: Alignment.horizontal.RIGHT,
					height: '100%'
				}}
				onClose={this._handleSelectToothOverlayClick}>
				<div className={selectToothCssClassName}>
					<div className={`${selectToothCssClassName}__diagram`}>
						<DentalNotationDiagram />
					</div>
				</div>
				<Hint
					content={
						<div className={toothSelectorNotificationCssClassName}>
							<div className={toothSelectorNotificationTitleCssClassName}>
								{i18n('hint.select_tooth')}
							</div>
							<div className={toothSelectorNotificationElementCssClassName}>
								<ToothSelector
									items={
										[
											{
												value: '-1',
												label: i18n('select_tooth.no_tooth_key'),
											}
										]
										.concat(
											teethConfig
												.getAdditionalTeeth()
												.map(tooth => ({
													value: tooth.key,
													label: tooth.label
												}))
										)
									}
									onChange={this._handleToothSelected}
								/>
							</div>
						</div>
					}
					top={82}
					right={19}
				/>
			</Popup>
		);
	}
}

export default connect(
	null,
	dispatch => ({
		onResetMode: () => dispatch(editorActions.resetMode()),
		onSelectToothKey: data => dispatch(editorActions.selectToothKey(data))
	})
)(SelectToothEditor);
