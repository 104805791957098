import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import lodashIntersection from 'lodash/intersection';

import mainConfig from '../../../../configs/mainConfig';

import {
	LABEL_VALIDATION_ERROR__PARAMS_REQUIRED,
	LABEL_VALIDATION_ERROR__SURFACE_REQUIRED,
	LABEL_VALIDATION_ERROR__DATE_REQUIRED,
	LABEL_VALIDATION_ERROR__BOX_REQUIRED,
	LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH,
	LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS,
} from '../../../labels/constants';

import { confirm } from '../../../../services/popup';

import labelsUtils from '../../../../appUtils/labelsUtils';
import gridUtils from '../../../../appUtils/gridUtils';
import momentUtils from '../../../../appUtils/momentUtils';

import { getLocalizedSurface } from '../../utils';

import { getDictionary } from '../../../../appUtils/locale';
import { trackEvent } from '../../../../integrations/mixpanel';

import labelGetters from '../../../../modules/labels/selectors/labelGetters';


import PopupDialog  from '../../../../components/PopupDialog';
import Grid from '../../../../components/Grid';
import SurfaceEditor from '../surface-editor';
import ConfirmPopup from '../confirm-popup';
import ResolverFindingsBadgeSelector from './ResolverFindingsBadgeSelector';
import Datepicker, { getMaxDate, getMinDate } from '../../../../components/Datepicker';
import FormCustomSelect from '../../../../components/FormCustomSelect';
import Alignment from '../../../../components/Alignment';
import Overlay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import Switch from '../../../../components/Switch';
import { ControlledTooltip } from '../../../../components/Tooltip';
import ResolverFindingsValidationWrapper from './ResolverFindingsValidationWrapper';
import ResolverFindingsBadge from './ResolverFindingsBadge';
import ResolverFindingsCategory from './category/index';
import TreatmentButton from './TreatmentButton';

import './styles/ResolverFindings.css';


const getGridContentRowKey = (rowData) => labelGetters.getLabelId(rowData);

const i18nFindings = getDictionary('findings');

const baseCssClassName = 'resolver-findings';
const gridCssClassName = `${baseCssClassName}-grid`;
const gridWrapperCssClassName = `${gridCssClassName}-wrapper`;
const gridRowsWrapperCssClassName = `${gridCssClassName}-rows-wrapper`;
const gridRowsContentCssClassName = `${gridCssClassName}-rows-content`;
const toothCssClassName = `${baseCssClassName}__tooth`;
const toothKeyCssClassName = `${baseCssClassName}__tooth-key`;
const removeButtonCssClassName = `${baseCssClassName}__remove-button`;
const treatmentButtonCssClassName = `${baseCssClassName}__treatment-button`;
const selectCssClassName = `${baseCssClassName}-select`;
const selectHeaderCssClassName = `${baseCssClassName}-select__header`;
const gridSelectorCssClassName = `${baseCssClassName}-grid-selector`;
const badgesCssClassName = `${baseCssClassName}__badges`;
const badgeCssClassName = `${baseCssClassName}__badge`;
const findingCssClassName = `${baseCssClassName}__finding`;
const findingNameCssClassName = `${baseCssClassName}__finding-name`;


export default class ResolverFindings extends PureComponent {
	static propTypes = {
		teeth: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		labels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		selectedLabel: PropTypes.object,
		procedures: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		isTreatmentPlanningEnabled: PropTypes.bool,
		noHeaderRows: PropTypes.bool,
		labelColorFilterFn: PropTypes.func,
		allowEditing: PropTypes.bool,
		dataType: PropTypes.string,
		onGetLabelIdByToothKey: PropTypes.func.isRequired,
		onSelectLabel: PropTypes.func.isRequired,
		onUpdateLabelData: PropTypes.func.isRequired,
		onRemoveLabel: PropTypes.func.isRequired,
		onMoveLabel: PropTypes.func.isRequired,
		onSwapTeeth: PropTypes.func.isRequired,
		onApproveMissingTooth: PropTypes.func.isRequired,
		onFindLabelTooth: PropTypes.func,
		onGetToothChildren: PropTypes.func,
	};

	static defaultProps = {
		allowEditing: true,
		noHeaderRows: false,
		dataType: 'categories',
		labelColorFilterFn: () => true,
	};
	
	constructor (props, context) {
		super(props, context);
		
		this._surfaceEl = null;
		this._confirmPopupTargetEl = null;
		
		this._lastSelectedLabelId = null;
		this._gridContentEl = null;
		this._labelsEls = {};
		this._drawShape = false;
		this._openParamSelector = false;

		this.state = {
			selectedColumn: null,
			openProceduresEditForLabel: null,
			isSurfaceEditorOpened: false,
			isDatepickerOpened: false,
			showMissingToothResolvePopup: false,
		};
	}
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			nextProps.selectedLabel &&
			!nextProps.labels.find((label) => labelGetters.getLabelId(label) === labelGetters.getLabelId(nextProps.selectedLabel))
		) {
			this.setState({
				isSurfaceEditorOpened: false,
				isDatepickerOpened: false,
				showMissingToothResolvePopup: false,
			}, () => {
				// nextProps.onSelectLabel({
				// 	label: null,
				// })
			});
		}
		
		if ( !nextProps.selectedLabel && this.props.selectedLabel ) {
			this.setState({
				isSurfaceEditorOpened: false,
				isDatepickerOpened: false,
			});
		}
	}
	
	componentDidUpdate (prevProps) {
		let currentSelectedLabelId = null;

		if ( this.props.selectedLabel ) {
			currentSelectedLabelId = labelGetters.getLabelId(this.props.selectedLabel);
			
			if ( labelGetters.getLabelClassId(this.props.selectedLabel) === 'tooth' ) {
				currentSelectedLabelId = this._findFirstToothFinding(currentSelectedLabelId);
			}
			
			if ( this._labelSelectedFromGrid ) {
				this._lastSelectedLabelId = currentSelectedLabelId;
				this._labelSelectedFromGrid = false;
				return;
			}

			if (
				this._gridContentEl &&
				currentSelectedLabelId &&
				currentSelectedLabelId !== this._lastSelectedLabelId
			) {
				const labelEl = this._labelsEls[currentSelectedLabelId];
				
				if ( !labelEl ) {
					return;
				}
				
				this._lastSelectedLabelId = currentSelectedLabelId;
				this._gridContentEl.scrollTop = Math.max(0, labelEl.offsetTop);
				this._labelSelectedFromGrid = false;
			}
		}
		
		if ( currentSelectedLabelId === null ) {
			this._lastSelectedLabelId = null;
		}
	}

	_openProcedures (label) {
		if ( label ) {
			trackEvent('Findings List Open Procedures', { id: labelGetters.getLabelId(label) });
		}

		this.setState({
			openProceduresEditForLabel: label,
		});
	}

	_isSelectedLabel (label) {
		return (
			this.props.selectedLabel &&
			labelGetters.getLabelId(this.props.selectedLabel) === labelGetters.getLabelId(label)
		);
	}

	_isProceduresOpened (label) {
		return (
			Boolean(this.state.openProceduresEditForLabel) &&
			labelGetters.getLabelId(this.state.openProceduresEditForLabel) === labelGetters.getLabelId(label)
		);
	}
	
	_findSelectedLabel () {
		const selectedLabelId = labelGetters.getLabelId(this.props.selectedLabel);
		return this.props.labels.find((label) => labelGetters.getLabelId(label) === selectedLabelId);
	}
	
	_removeLabel (label) {
		trackEvent('Finding Remove');
		const toothLabel = this.props.onFindLabelTooth(label);
		this.props.onRemoveLabel({
			labelId: labelGetters.getLabelId(label),
		});

		if ( toothLabel === null || typeof toothLabel === 'undefined' ) {
			return;
		}

		const toothShapes = Object.values(toothLabel.shapes).filter((shape) => shape.type !== 'none');
		if ( toothShapes.length === 0 && this.props.onGetToothChildren(toothLabel).length === 0 ) {
			confirm({
				message: 'There are no more findings left on the tooth. Do you want to mark the tooth as missing?',
				yesHandler: () => {
					this.props.onRemoveLabel(toothLabel);
				},
			});
		}
	}
	
	_findFirstToothFinding (labelId) {
		for (let i = 0, l = this.props.labels.length; i < l; i++) {
			if ( this.props.labels[i].parentLabelId === labelId ) {
				return labelGetters.getLabelId(this.props.labels[i]);
			}
		}
		
		return null;
	}
	
	_getColumns () {
		const {isTreatmentPlanningEnabled} = this.props;
		return [
			gridUtils.makeGridColumn({
				key: 'tooth',
				width: 52,
				headerValue: 'Tooth',
				getValue: (label) => {
					if ( this._isSelectedLabel(label) ) {
						if ( labelsUtils.isToothRequiredForClass(labelGetters.getLabelClassId(label)) === false ) {
							return '';
						}

						return (
							<FormCustomSelect
								items={this.props.onGetAvailableTeethToMove(label.toothKey)}
								value={label.toothKey}
								disabled={labelsUtils.labelIsTooth(label)}
								initialOpen={this.state.selectedColumn && this.state.selectedColumn.key === 'tooth'}
								className={gridSelectorCssClassName}
								// onRenderHeader={this._handleRenderToothSelectorHeader}
								onChange={(newToothKey) => {
									trackEvent('Finding Change Tooth', { toothKey: label.toothKey, newToothKey });
									if ( labelGetters.getLabelClassId(label) === 'missing_tooth' ) {
										return this.props.onSwapTeeth({
											toothKey: label.toothKey,
											newToothKey,
										});
									}
									
									return this.props.onMoveLabel({
										labelId: labelGetters.getLabelId(label),
										parentLabelId: this.props.onGetLabelIdByToothKey(label.toothKey),
										newParentLabelId: this.props.onGetLabelIdByToothKey(newToothKey),
										newToothKey,
									});
								}}
							/>
						);
					}
					
					return (
						<div className={toothCssClassName}>
							<div
								className={toothKeyCssClassName}
								style={{
									borderColor: labelsUtils.getLabelColor(label, this.props.labelColorFilterFn),
									borderStyle: labelGetters.getLabelClassId(label) === 'missing_tooth' ? 'dashed' : 'solid',
								}}
							>{label.localizedToothKey || '-'}</div>
						</div>
					);
				},
			}),
			gridUtils.makeGridColumn({
				key: 'finding',
				width: ( isTreatmentPlanningEnabled ? 136: 160),
				headerValue: 'Findings',
				getValue: (label) => (
					<div className={findingCssClassName}>
						<div className={findingNameCssClassName}>
							{this._wrapError({
								label,
								errorType: LABEL_VALIDATION_ERROR__BOX_REQUIRED,
								tooltip: 'Draw a box',
								content: (<div style={{ color: labelsUtils.getLabelColor(label, this.props.labelColorFilterFn), fontWeight: 400 }}>
									{labelsUtils.getLocalizedClass(label)}
								</div>),
								onClick: ({ hasError }) => {
									if ( hasError ) {
										this._drawShape = true;
									}
								},
							})}
						</div>
						<div className={badgesCssClassName}>
							{this._renderSource(label)}
							{this._renderDate(label)}
							{this._renderParams(label)}
						</div>
					</div>
				),
			}),
			!isTreatmentPlanningEnabled && gridUtils.makeGridColumn({
				key: 'watch',
				width: 60,
				headerValue: 'Watch',
				getValue: (label) => {
					if ( labelsUtils.getLabelAvailabilityOptions(labelGetters.getLabelClassId(label)).is_watch_allowed === false ) {
						return null;
					}

					const labelId = labelGetters.getLabelId(label);
					const value = !labelGetters.getLabelIsWatch(label);

					return (
						<div style={{ width: 40 }}>
							<Switch
								checked={labelGetters.getLabelIsWatch(label)}
								onChange={() => {
									trackEvent('Finding Change Watch', { labelId, value });
									this.props.onUpdateLabelData({
										labelId,
										data: {
											is_watch: value,
										},
									});
								}}
							/>
						</div>
					);
				},
			}),
			gridUtils.makeGridColumn({
				key: 'surface',
				width: ( isTreatmentPlanningEnabled ? 58: 64),
				headerValue: 'Surfaces',
				getValue: (label) => {
					const surfaces = labelGetters.getLabelSurfaces(label);
					return (
						<div
							style={{ width: '100%' }}
							onClick={labelsUtils.isSurfaceRequiredForClass(labelGetters.getLabelClassId(label))
								? (event) => {
									this._surfaceEl = event.currentTarget;
									this.setState({
										isSurfaceEditorOpened: true,
									});
								} : () => {}
							}
						>
							{this._wrapError({
								label,
								errorType: LABEL_VALIDATION_ERROR__SURFACE_REQUIRED,
								tooltip: 'Select surfaces',
								content: surfaces && surfaces.length > 0
									? (<span style={{ textTransform: 'uppercase' }}>
										{surfaces.map((surface) => getLocalizedSurface(surface, label.toothKey)).join(', ')}
									</span>)
									: '-',
							})}
						</div>
					);
				},
			}),
			isTreatmentPlanningEnabled && gridUtils.makeGridColumn({
				key: 'treatment',
				width: 90,
				headerValue: 'Treatment',
				getValue: (label) => {
					const is_watch_allowed = labelsUtils.getLabelAvailabilityOptions(labelGetters.getLabelClassId(label)).is_watch_allowed;
					const is_watch_enabled = labelGetters.getLabelIsWatch(label);
					const procedure_codes = labelGetters.getLabelProcedureCodes(label);

					const showAddTreatmentButton = procedure_codes.length === 0 && !is_watch_enabled;
					const labelId = labelGetters.getLabelId(label);
					return <Fragment>
						{this._isProceduresOpened(label) && (
							<TreatmentButton
								is_watch_allowed={is_watch_allowed}
								is_watch_enabled={is_watch_enabled}
								procedure_codes={procedure_codes}
								procedures={this.props.procedures}
								label={label}
								onProceduresBlur={() => this._openProcedures(null)}
								onProceduresChange={(new_procedure_codes, is_watch) => {
									trackEvent('Finding Change Procedures', { labelId, procedures: new_procedure_codes, is_watch });
									this.props.onUpdateLabelData({
										labelId,
										data: {
											procedure_codes: new_procedure_codes,
											is_watch: is_watch
										},
									});
								}}
							/>
						)}
						{showAddTreatmentButton && <ControlledTooltip content={'Add treatment'}>
							<div
								className={treatmentButtonCssClassName}
								onClick={(event) => {
									event.stopPropagation();
									this._openProcedures(label);
								}}
							/>
						</ControlledTooltip>}
						{this._renderWatchLabel(label)}
						{this._renderProcedureCodes(label)}

					</Fragment>
				},
			}),
			gridUtils.makeGridColumn({
				key: 'actions',
				width: 32,
				headerValue: '',
				getValue: (label) => (
					this._wrapError({
						label,
						errorType: [ LABEL_VALIDATION_ERROR__MISSING_WITH_FINDINGS, LABEL_VALIDATION_ERROR__TOOTH_BOX_WITH_MISSING_TOOTH ],
						tooltip: 'Resolve the conflict with a missing tooth',
						content: (
							<div
								className={removeButtonCssClassName}
								onClick={(event) => {
									event.stopPropagation();

									this._removeLabel(label)
								}}
							/>
						),
						iconPosition: ResolverFindingsValidationWrapper.ICON_POSITIONS.RIGHT_NEAR,
						onClick: ({ event }) => {
							this._confirmPopupTargetEl = event.currentTarget;
							this.setState({
								showMissingToothResolvePopup: true,
							});
						},
					})
				),
			}),
		].filter(Boolean);
	}

	_hasError (label, errorType) {
		const errors = labelGetters.getLabelConflicts(label);

		return Array.isArray(errorType)
			? lodashIntersection(errors, errorType).length > 0
			: errors.includes(errorType);
	}
	
	_wrapError ({ label, errorType, tooltip,  content, onClick, iconPosition, theme = undefined }) {
		const hasError = this._hasError(label, errorType);
		
		return (
			<ResolverFindingsValidationWrapper
				tooltip={tooltip}
				iconPosition={iconPosition}
				hasError={hasError}
				theme={theme}
				onClick={onClick}
			>
				{content}
			</ResolverFindingsValidationWrapper>
		);
	}
	
	_handleParamsChange = (param) => {
		const labelId = labelGetters.getLabelId(this.props.selectedLabel);
		this.props.onUpdateLabelData({
			labelId,
			data: {
				params: [ param ],
			},
		});
		trackEvent('Finding Change Params', { labelId, params: [ param ] });
	}
	
	_handleSurfaceChange = (surfaces) => {
		const labelId = labelGetters.getLabelId(this.props.selectedLabel);
		this.props.onUpdateLabelData({
			labelId,
			data: {
				surfaces,
			},
		});
		trackEvent('Finding Change Surface', { labelId, surfaces });
	}
	
	_handleDateChange = (date) => {
		const labelId = labelGetters.getLabelId(this.props.selectedLabel);
		const dateString = momentUtils.getMomentForDate({ date }).format('YYYY-MM-DD');
		this.props.onUpdateLabelData({
			labelId,
			data: {
				date: dateString,
			},
		});
		trackEvent('Finding Change Date', { labelId, dateString });
		this.setState({
			isDatepickerOpened: false,
		});
	}
	
	_handleSurfaceClose = () => {
		this.setState({
			isSurfaceEditorOpened: false,
		});
	}
	
	_handleGetContentRowModifiers = (label) => {
		const modifiers = [];

		if ( this._isSelectedLabel(label) ) {
			modifiers.push('selected');
		}
		else if ( this.props.selectedLabel && labelGetters.getLabelId(this.props.selectedLabel) === label.parentLabelId ) {
			modifiers.push('highlighted');
		}

		return modifiers;
	}
	
	_handleContentRowClick = (label) => {
		if ( this.props.allowEditing === false ) {
			return;
		}

		if ( label ) {
			trackEvent('Findings List Finding Select', { id: labelGetters.getLabelId(label) });
		}

		this._labelSelectedFromGrid = true;
		this.props.onSelectLabel({
			label,
			editorMode: this._drawShape ? mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE : null,
		});
		this._drawShape = false;
	}
	
	_handleContentCellClick = (column) => {
		if ( this.props.allowEditing === false || this.state.selectedColumn === column ) {
			return;
		}

		trackEvent('Findings List Finding Column Select', column);

		this.setState({
			selectedColumn: column,
		});
	}
	
	_handleGridContentRef = (el) => {
		this._gridContentEl = el;
	}
	
	_handleGridCustomRender = ({ headerRows, rows }) => {
		return (
			<div className={gridWrapperCssClassName}>
				{this.props.noHeaderRows === false && headerRows}
				<div className={gridRowsWrapperCssClassName}>
					<div
						className={`${gridRowsContentCssClassName} ${mainConfig.MAIN_SCROLL_CSS_CLASS_NAME}`}
						ref={this._handleGridContentRef}
					>
						{rows}
					</div>
				</div>
			</div>
		)
	}
	
	_handleGridRowRef = (el, label) => {
		this._labelsEls[labelGetters.getLabelId(label)] = el;
	}
	
	_handleConfirmPopupAgree = () => {
		if ( this.state.showMissingToothResolvePopup === true ) {
			this.props.onApproveMissingTooth(this.props.selectedLabel);
		}
		
		this.setState({
			showMissingToothResolvePopup: false,
		});
	}
	
	_handleConfirmPopupClose = () => {
		this.setState({
			showMissingToothResolvePopup: false,
		});
	}

	_handleIdleParamClick = () => {
		this._openParamSelector = true;
	};

	_renderSource (label) {
		const source = labelGetters.getLabelSource(label) || 'ai';

		let content = null;
		if ( source === 'ai' ) {
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
			content = (
				<ControlledTooltip
					content={(
						<div style={{ width: 200 }}>{i18nFindings('confidence.tooltip')}</div>
					)}
				>{`AI${labelsUtils.shouldShowConfidenceForClass(labelGetters.getLabelClassId(label)) === true && typeof measureOfConfidence === 'number' && measureOfConfidence !== 1 ? ` (${Math.floor(measureOfConfidence * 100)}%)` : ''}`}</ControlledTooltip>
			);
		}
		else if ( source === 'manual' ) {
			content = 'Manual';
		}
		else if ( source === 'external' ) {
			content = 'External';
		}
		else if ( source === 'matched' ) {
			content = 'AI/External';
		}

		if ( content !== null ) {
			return (
				<div className={badgeCssClassName}>
					<ResolverFindingsBadge>
						{content}
					</ResolverFindingsBadge>
				</div>
			);
		}

		return null;
	}

	_renderDate (label) {
		const date = labelGetters.getLabelDate(label);
		const hasError = this._hasError(label, LABEL_VALIDATION_ERROR__DATE_REQUIRED);

		if ( !date && false === hasError ) {
			return null;
		}

		return (
			<div className={badgeCssClassName}>
				<ResolverFindingsBadge
					backgroundColor={'#CAC7C0'}
					onClick={() => {
						this.setState({
							isDatepickerOpened: true,
						});
					}}
				>
					{this._wrapError({
						label,
						errorType: LABEL_VALIDATION_ERROR__DATE_REQUIRED,
						tooltip: 'Select a date',
						content: date ? momentUtils.moment({ args: [ date ]}).format('YYYY-MM-DD') : 'Add date',
						theme: ResolverFindingsValidationWrapper.THEMES.gray,
					})}
				</ResolverFindingsBadge>
			</div>
		);
	}

	_renderWatchLabel (label) {
		return labelGetters.getLabelIsWatch(label) && <div className={badgeCssClassName}>
			<ResolverFindingsBadge
				onClick={(event) => {
					event.stopPropagation();
					this._openProcedures(label);
				}}
				backgroundColor={'#AEBAB8'}
			>
				Watch
			</ResolverFindingsBadge>
		</div>;
	}

	_renderProcedureCodes (label) {
		return labelGetters.getLabelProcedureCodes(label).map((code, i) => {
			const procedure = this.props.procedures.find(procedure => procedure.value === code) || {label: code};
			return (
				<div key={i} className={badgeCssClassName}>
					<ResolverFindingsBadge
						onClick={(event) => {
							event.stopPropagation();
							this._openProcedures(label);
						}}
						backgroundColor={'#AEBAB8'}
					>
						<ControlledTooltip content={ procedure.label }>
							{code}
						</ControlledTooltip>
					</ResolverFindingsBadge>
				</div>
			);
		})
	}

	_renderParams (label) {
		const params = labelGetters.getLabelParams(label);
		const classId = labelGetters.getLabelClassId(label);

		const availableParams = labelsUtils.getParamsByClassId(classId);

		if ( this._isSelectedLabel(label) && availableParams.length > 0 ) {
			const openParamSelector = this._openParamSelector;

			return (
				<div className={badgeCssClassName}>
					<ResolverFindingsBadgeSelector
						emptyValue={'Add param'}
						value={params.length > 0 ? params[0] : '-1'}
						items={availableParams.map((param) => ({
							value: String(param.name),
							label: param.readable_name,
						}))}
						initialOpen={openParamSelector === true}
						onChange={this._handleParamsChange}
						onInit={() => {
							if ( this._openParamSelector === true ) {
								this._openParamSelector = false;
							}
						}}
					/>
				</div>
			);
		}

		if ( params.length > 0) {
			return params.map((param, i) => (
				<div className={badgeCssClassName} key={i} onClick={this._handleIdleParamClick}>
					<ResolverFindingsBadge
						backgroundColor={'#AEBAB8'}
					>
						{labelsUtils.getLocalizedParam(classId, param)}
					</ResolverFindingsBadge>
				</div>
			));
		}

		if ( availableParams.length > 0 ) {
			return (
				<div className={badgeCssClassName} onClick={this._handleIdleParamClick}>
					<ResolverFindingsBadge
						backgroundColor={'#AEBAB8'}
					>
						{this._wrapError({
							label,
							errorType: LABEL_VALIDATION_ERROR__PARAMS_REQUIRED,
							tooltip: 'Select param',
							content: 'Add param',
							theme: ResolverFindingsValidationWrapper.THEMES.gray,
						})}
					</ResolverFindingsBadge>
				</div>
			);
		}
	}
	
	_renderDatepicker () {
		if ( !(this.state.isDatepickerOpened && this.props.selectedLabel) ) {
			return null;
		}
		
		const date = labelGetters.getLabelDate(this.props.selectedLabel);
		
		return (
			<PopupDialog
				headerProps={{
					title: 'Select date',
				}}
				content={(
					<Datepicker
						selectedDays={date ? [ momentUtils.moment({ args: [ date ]}).toDate() ] : null}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						onDayClick={this._handleDateChange}
					/>
				)}
				footerProps={{
					buttons: ({ popup: { close } }) => {
						return [
							(
								<Button
									key={'popup_cancel'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={close}
								>{'Cancel'}</Button>
							),
						];
					},
				}}
				popupProps={{
					alignmentProps: {
						horizontal: Alignment.horizontal.RIGHT,
						vertical: Alignment.vertical.CENTER,
					},
					overlayProps: {
						theme: Overlay.themes.TRANSPARENT,
					},
					onClose: () => {
						this.setState({
							isDatepickerOpened: false,
						});
					},
				}}
				style={{
					position: 'relative',
					right: 128,
				}}
			/>
		);
	}

	_renderSurface () {
		if ( !this.state.isSurfaceEditorOpened || !this.props.selectedLabel ) {
			return null;
		}

		const label = this._findSelectedLabel();

		if ( !label ) {
			return null;
		}
		
		return (
			<SurfaceEditor
				key={labelGetters.getLabelId(label)}
				toothKey={label.toothKey}
				target={this._surfaceEl}
				values={label ? labelGetters.getLabelSurfaces(label) : []}
				onChange={this._handleSurfaceChange}
				onClose={this._handleSurfaceClose}
			/>
		);
	}
	
	_renderConfirmPopup () {
		if ( !this.state.showMissingToothResolvePopup || !this.props.selectedLabel ) {
			return null;
		}
		
		return (
			<ConfirmPopup
				target={this._confirmPopupTargetEl}
				onAgree={this._handleConfirmPopupAgree}
				onClose={this._handleConfirmPopupClose}
			/>
		);
	}
	
	render () {
		return (
			<div className={baseCssClassName}>
				<Grid
					cssClassName={gridCssClassName}
					minWidth={373}
					columns={this._getColumns()}
					data={this.props.dataType === 'categories' ? this.props.categories : this.props.labels}
					dataType={this.props.dataType}
					headerRowHeight={37}
					contentRowHeight={'auto'}
					getContentRowKey={getGridContentRowKey}
					getContentRowModifiers={this._handleGetContentRowModifiers}
					onContentRowMouseOver={this._handleContentRowMouseOver}
					onContentRowMouseOut={this._handleContentRowMouseOut}
					onContentRowClick={this._handleContentRowClick}
					onContentRef={this._handleGridRowRef}
					onContentCellClick={this._handleContentCellClick}
					onCustomRender={this._handleGridCustomRender}
					onGetCategoryRenderDescriptor={(categoryData, index) => ({
						render: (rows) => {
							if ( rows.length === 0 ) {
								return null;
							}

							return (
								<ResolverFindingsCategory {...categoryData} key={index}>
									{rows}
								</ResolverFindingsCategory>
							);
						},
					})}
				/>
				{this._renderSurface()}
				{this._renderDatepicker()}
				{this._renderConfirmPopup()}
			</div>
		);
	}
}
