import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import userActions from '../../actions/userActions';

import userSelectors from '../../selectors/userSelectors';

import PopupDialog, { getFooterCloseButton } from '../PopupDialog';

import FormLabel from '../FormLabel';
import FormInput from '../FormInput';

import Button from '../Button';

import Loading from '../Loading';

import { getDictionary } from '../../appUtils/locale';

import './styles/ChangeEmail.css';


const i18n = getDictionary('change-email');
const i18nShared = getDictionary('shared');

const baseCssClassName = 'change-email';
const formCssClassName = `${baseCssClassName}__form`;
const formMainCssClassName = `${baseCssClassName}__form-main`;
const formSecondaryCssClassName = `${baseCssClassName}__form-secondary`;
const messageCssClassName = `${baseCssClassName}__message`;
const messageTextCssClassName = `${baseCssClassName}__message-text`;


class ChangeEmail extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,

		onSaveEmail: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}

	constructor (props, context) {
		super(props, context);

		this._dialogInterface = null;

		this.state = {
			isEmailInvalid: false,
			isCurrentPasswordInvalid: false,
			isDataDirty: false,
			newEmail: ( props.user.email || '' ),
			currentPassword: '',
		};
	}

	componentWillUnmount () {
		this._setCanClose(true);
	}

	_handleNewEmailChange = (event) => {
		const newValue = event.target.value;
		this.setState({
			isDataDirty: true,
			newEmail: newValue,
			isEmailInvalid: false,
		});

		if ( this.state.newEmail !== newValue ) {
			this._setCanClose(false);
		}
		else {
			this._setCanClose(true);
		}
	}

	_handleCurrentPasswordChange = (event) => {
		this.setState({
			currentPassword: event.target.value,
			isCurrentPasswordInvalid: false,
		});
	}

	_handleChangeButtonClick = () => {
		const {
			newEmail,
			currentPassword,
		} = this.state;

		const newState = {};

		if ( newEmail.length === 0 || newEmail.indexOf('@') === -1 ) {
			newState.isEmailInvalid = true;
		}

		if ( currentPassword.length === 0 ) {
			newState.isCurrentPasswordInvalid = true;
		}

		if ( Object.keys(newState).length > 0 ) {
			this.setState(newState);
			return;
		}

		this._saveNewEmail();
	}

	_handleChangeTryAgainButtonClick = () => {
		this._saveNewEmail();
	}

	_setCanClose (value) {
		if (
			this._dialogInterface &&
			this._dialogInterface.dialog &&
			this._dialogInterface.dialog.onSetCanClose
		) {
			this._dialogInterface.dialog.onSetCanClose(value);
		}
	}

	_saveNewEmail () {
		const {
			newEmail,
		} = this.state;

		this.setState({
			isSaving: true,
			hasSavingError: false,
		});

		this.props.onSaveEmail({
				email: newEmail,
			})
			.then(() => {
				this.setState({
					isSaving: false,
					isDataDirty: false,
					isSaved: true,
				});
				this._setCanClose(true);
			})
			.catch(() => {
				this.setState({
					isSaving: false,
					hasSavingError: true,
				});
			});
	}

	_renderForm () {
		const {
			newEmail,
			currentPassword,
			isEmailInvalid,
			isCurrentPasswordInvalid,
		} = this.state;

		return (
			<div className={formCssClassName}>
				<div className={formMainCssClassName}>
					<FormLabel
						element={
							(
								<FormInput
									name={'newEmail'}
									value={newEmail}
									isInvalid={isEmailInvalid}
									onChange={this._handleNewEmailChange}
								/>
							)
						}
						text={i18n('labels.new_email')}
						textPosition={FormLabel.textPosition.TOP}
					/>
					<FormLabel
						element={
							(
								<FormInput
									name={'currentPassword'}
									value={currentPassword}
									type={'password'}
									isInvalid={isCurrentPasswordInvalid}
									onChange={this._handleCurrentPasswordChange}
								/>
							)
						}
						text={i18n('labels.current_password')}
						textPosition={FormLabel.textPosition.TOP}
					/>
				</div>
				<div
					className={formSecondaryCssClassName}
					dangerouslySetInnerHTML={{
						__html: i18n('info'),
					}}
				/>
			</div>
		);
	}

	_renderContent () {
		if ( this.state.isSaving ) {
			return (
				<div className={messageCssClassName}>
					<Loading />
					<div
						className={messageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.wait'),
						}}
					/>
				</div>
			);
		}
		else if ( this.state.hasSavingError ) {
			return (
				<div className={messageCssClassName}>
					<div
						className={messageTextCssClassName}
						dangerouslySetInnerHTML={{
							__html: i18nShared('messages.loading_error'),
						}}
					/>
					<Button
						theme={Button.AVAILABLE_THEMES.PRIMARY}
						size={Button.AVAILABLE_SIZES.LARGE}
						onClick={this._handleChangeTryAgainButtonClick}
					>{i18nShared('buttons.try_again')}</Button>
				</div>
			);
		}
		else if ( this.state.isSaved ) {
			return (
				<div className={messageCssClassName}>
					<div>{i18nShared('messages.success')}</div>
				</div>
			);
		}

		return this._renderForm();
	}
	
	render () {
		const {
			isSaved,
			isSaving,
			hasSavingError,
			isDataDirty,
		} = this.state;

		return (
			<PopupDialog
				headerProps={{
					title: i18n('dialog.title'),
				}}
				content={(dialogInterface) => {
					this._dialogInterface = dialogInterface;

					return (
						<div className={baseCssClassName}>
							{this._renderContent()}
						</div>
					);
				}}
				footerProps={{
					buttons: (dialogInterface) => {
						return [
							( !isSaved && !isSaving && !hasSavingError ) && (
								<Button
									key={'save_button'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									disabled={!isDataDirty}
									onClick={this._handleChangeButtonClick}
								>{i18nShared('buttons.change')}</Button>
							),
							getFooterCloseButton(dialogInterface),
						].filter(Boolean);
					}
				}}
				popupProps={{
					onClose: this.props.onClose,
				}}
			/>	
		);
	}
}

export default connect((state) => ({
	user: userSelectors.selectUserData(state),
}), (dispatch) => ({
	onSaveEmail: (options) => dispatch(userActions.changeEmail(options)),
}))(ChangeEmail);
