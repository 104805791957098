export default {
	'labels.new_email': '新邮件',
	'labels.current_password': '当前密码',
	'info': `
		电子邮件确认信将被发送到旧邮箱地址<br />
		请按照邮件提示进行操作<br />

		<br />
		请输入目前登录密码来更改邮箱地址
	`,

	'dialog.title': '修改邮箱地址',
};
